import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode";
import type { ElementFormatType, LexicalNode, NodeKey } from "lexical";

import { AttachmentType } from "apollo/graphql.generated";
import { Spread } from "utils/types";

import TokenAttachment from "../../Attachments/Token";

import EditorActionsHandler from "./EditorActionsHandler";

type Node = TokenNode | LexicalNode | null | undefined;

export type SerializedTokenNode = Spread<
  {
    tokenId: string;
    attachmentType: AttachmentType.TOKEN;
    type: "token";
    version: 1;
  },
  SerializedDecoratorBlockNode
>;

export class TokenNode extends DecoratorBlockNode {
  __id: string;

  static getType(): string {
    return "token";
  }

  static clone(node: TokenNode): TokenNode {
    return new TokenNode(node.__id, node.__format, node.__key);
  }

  constructor(tokenID: string, format?: ElementFormatType, key?: NodeKey) {
    super(format, key);
    this.__id = tokenID;
  }

  decorate(): JSX.Element {
    const attachment = {
      id: this.__id,
      type: AttachmentType.TOKEN,
    };

    return (
      <EditorActionsHandler
        nodeKey={this.getKey()}
        nodeCheck={(n) => $isTokenNode(n as Node)}
      >
        <TokenAttachment attachment={attachment} />
      </EditorActionsHandler>
    );
  }

  isInline(): false {
    return false;
  }

  static importJSON(serializedNode: SerializedTokenNode): TokenNode {
    const node = $createTokenNode(serializedNode.tokenId);
    node.setFormat(serializedNode.format);
    return node;
  }

  exportJSON(): SerializedTokenNode {
    return {
      ...super.exportJSON(),
      tokenId: this.__id,
      attachmentType: AttachmentType.TOKEN,
      type: this.getType(),
      version: 1,
    };
  }
}

export function $createTokenNode(tokenID: string): TokenNode {
  return new TokenNode(tokenID);
}

export function $isTokenNode(
  node: TokenNode | LexicalNode | null | undefined
): node is TokenNode {
  return node instanceof TokenNode;
}
