import { css, Theme } from "@emotion/react";

export const benefitList = (theme: Theme) => css`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    &:not(:first-of-type) {
      margin-top: ${theme.verticalSpaces.large[4]};
    }
  }

  ${theme.mediaQueries.smallOnly} {
    li {
      &:not(:first-of-type) {
        margin-top: ${theme.verticalSpaces.small[4]};
      }
    }
  }
`;
