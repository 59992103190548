import React, { FC, memo } from "react";

import { useDeleteCommentMutation } from "apollo/queries";
import { layoutContext } from "apollo/reactive/layout";
import CardContainer from "components/CardContainer";
import CommunityAvatarSmall from "components/CommunityAvatarSmall";
import CommunityText from "components/_Community/Text";
import { logError } from "services/logger";

import Reactions from "../Reactions";

import * as styles from "./styles";
import { Props as CommentProps } from "./types";

const Comment: FC<CommentProps> = ({
  id,
  text,
  username,
  userAvatar,
  reactions,
  canDelete,
  afterInteraction,
}) => {
  const [deleteComment] = useDeleteCommentMutation();
  const { theme } = layoutContext();

  const handleDeleteComment = async () => {
    try {
      await deleteComment({
        variables: {
          commentId: id,
        },
      });
      await afterInteraction();
    } catch (error) {
      await logError({
        error,
        message: `[handleDeleteComment] deleting comment (id:${id}) for username (${username}) failed`,
      });
    }
  };

  return (
    <>
      <div css={styles.container}>
        <div css={styles.commenterAvatar}>
          <CommunityAvatarSmall size="xlarge" imageUrl={userAvatar} />
        </div>
        <div css={styles.middleContainer}>
          <div css={styles.commenterUsernameLabel}>
            <div css={styles.commenterUsername}>
              <CommunityText bold>{username}</CommunityText>
            </div>
            {canDelete && (
              <div>
                <a
                  css={styles.deleteComment}
                  onClick={() => handleDeleteComment()}
                >
                  {"delete"}
                </a>
              </div>
            )}
          </div>
          <CardContainer
            border
            background="primary"
            extraCss={styles.commentContainer}
          >
            <CommunityText extraCss={styles.commentText}>{text}</CommunityText>
          </CardContainer>
          <Reactions
            theme={theme ?? "system"}
            reactions={reactions
              ?.filter((commentReaction) => commentReaction.reaction)
              ?.map((commentReaction) => ({
                count: commentReaction.count || 0,
                reaction: commentReaction.reaction!,
                userHasReacted: !!commentReaction.userHasReacted,
              }))}
            communitySlug={"test"}
            commentId={id}
          />
        </div>
        <div css={styles.commenterEmptyAvatar} />
      </div>
    </>
  );
};

export default memo(Comment);
