import { css } from "@emotion/react";

export const switchContainer = css`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

/* The slider */
export const slider = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:not(:disabled) + & {
    cursor: pointer;
  }

  input:checked + & {
    background-color: #000000;
  }

  input:focus + & {
    box-shadow: 0 0 1px #000000;
  }

  input:checked + &:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
`;

/* Rounded sliders */
export const round = css`
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
`;
