import React, { memo } from "react";

import { ReactComponent as RemoveIcon } from "assets/icons/close-x-benefit.svg";

import { Option } from "../index";

import * as styles from "./styles";

export type OptionItemProps = {
  option: Option;
  handleOnRemove(option: Option): void;
};

const OptionItem = ({ option, handleOnRemove }: OptionItemProps) => {
  return (
    <div css={styles.container}>
      <div css={styles.labelTextContainer}>
        {option.icon}
        <span css={styles.labelText}>{option.name}</span>
      </div>
      <button css={styles.removeButton} onClick={() => handleOnRemove(option)}>
        <RemoveIcon css={styles.closeIcon} />
      </button>
    </div>
  );
};

export default memo(OptionItem);
