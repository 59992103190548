import { memo, FC } from "react";

import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right-default.svg";
import MetamaskFoxImage from "assets/icons/metamask.svg";
import Button from "components/Button";
import CardContainer from "components/CardContainer";
import { useAuthForm } from "hooks/useAuthForm";

import * as styles from "./styles";

/**
 * Summary: Component which lets a user choose either MetaMask or Magic
 *          as their authentication to create a new account
 */
const CreateAccountAuthChoice: FC = () => {
  const {
    state: { useMetaMask },
    dispatch,
  } = useAuthForm();
  let titleText = "";
  let leftIcon = null;
  if (useMetaMask) {
    titleText = "Create an Account Using Email";
    // TODO: magic icon?
  } else {
    titleText = "Create an Account Using MetaMask";
    leftIcon = MetamaskFoxImage;
  }

  return (
    <Button extraCss={styles.button}>
      <CardContainer
        border={true}
        borderRadius="large"
        additionalCss={styles.container}
        onClick={() => {
          dispatch({ type: "setUseMetaMask", payload: !useMetaMask });
          dispatch({ type: "clearError" });
        }}
      >
        {leftIcon ? (
          <div css={styles.leftAuthContainer}>
            <img css={styles.image} src={leftIcon} alt={"metamask icon"} />
          </div>
        ) : (
          ""
        )}
        <div css={styles.middleAuthContainer}>{titleText}</div>
        <div css={styles.rightAuthContainer}>
          <ArrowRightIcon />
        </div>
      </CardContainer>
    </Button>
  );
};

export default memo(CreateAccountAuthChoice);
