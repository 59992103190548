import React, { FC, memo, PropsWithChildren } from "react";

import { css, Interpolation, Theme } from "@emotion/react";

export type InputGroupContainerProps = {
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
};

const containerStyles = (theme: Theme) => css`
  border-radius: ${theme.textInput.border.radius};
  border-width: ${theme.textInput.border.width};
  border-style: ${theme.textInput.border.style};
  border-color: ${theme.textInput.border.color};
`;

const InputGroupContainer: FC<PropsWithChildren<InputGroupContainerProps>> = ({
  children,
  extraCss,
}) => <div css={[containerStyles, extraCss]}>{children}</div>;

export default memo(InputGroupContainer);
