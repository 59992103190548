import { FC, memo, ComponentProps } from "react";

import { Interpolation, Theme } from "@emotion/react";

import { SystemButtonColor, SystemButtonSize } from "styles/theme/theme.types";

import LoadingIcon from "../LoadingIcon";

import * as styles from "./styles";

type ButtonProps = ComponentProps<"button">;

export type SystemButtonProps = ButtonProps & {
  loading?: boolean;
  target?: string;
  color?: SystemButtonColor;
  size?: SystemButtonSize;
  hasBorder?: boolean;
  fluid?: boolean;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
  /**
   * @deprecated Use "extraCss" instead.
   */
  additionalCss?: Interpolation<Theme>;
};

const SystemButton: FC<SystemButtonProps> = ({
  onClick,
  disabled,
  loading,
  children,
  className = "",
  size = "large",
  color = "primary",
  hasBorder = true,
  fluid = true,
  extraCss,
  additionalCss,
  ...rest
}) => (
  <button
    onClick={!loading ? onClick : undefined}
    disabled={loading || disabled}
    className={className}
    css={[
      loading && styles.loading,
      styles.button(color, size, fluid, hasBorder),
      additionalCss,
      extraCss,
    ]}
    {...rest}
  >
    <span css={styles.buttonText}>{children}</span>
    {loading && <LoadingIcon css={styles.icon} />}
  </button>
);

export interface NewAppButtonProps extends SystemButtonProps {
  color: "primary" | "secondary" | "destructive";
}

export const NewAppButton = ({
  onClick,
  disabled,
  loading,
  children,
  color = "primary",
  additionalCss,
  ...rest
}: NewAppButtonProps) => (
  <button
    onClick={!loading ? onClick : undefined}
    disabled={loading || disabled}
    css={[styles.newAppButton(color, loading), additionalCss]}
    {...rest}
  >
    {children}
    {loading && <LoadingIcon />}
  </button>
);

export default memo(SystemButton);
