import React, { useMemo, useState } from "react";

import { useForm } from "react-hook-form";

import {
  Attachment as AttachmentBaseType,
  AttachmentType,
  CreatePostInput,
  MediaMetadata,
  SupportedMediaMime,
  SupportedMediaType,
} from "apollo/graphql.generated";
import { DEFAULT_ERROR_MESSAGE } from "config";
import { logError } from "services/logger";

import { TokenMappedMetadata } from "../../../CommunityV3/ContentFeed";

import {
  CreatedCommunityFeedBySlugQuery,
  CreatePostMutation,
  useCreatePostMutation,
  useGetUrlMutation,
} from "./createPosts.graphql.generated";

export type CreatePostProps = {
  slug: string;
  community: CreatedCommunityFeedBySlugQuery["createdCommunityBySlug"];
  onAdd?: (newPost?: CreatePostMutation["createPost"]) => void;
};

type FormType = {
  text: string;
  pinned: boolean;
};

const mimeFromType: Record<string, string> = {
  "audio/mpeg": SupportedMediaMime.MP3,
  "audio/wav": SupportedMediaMime.WAV,
  "image/png": SupportedMediaMime.PNG,
  "image/jpeg": SupportedMediaMime.JPEG,
  "image/jpg": SupportedMediaMime.JPEG,
  "video/webm": SupportedMediaMime.WEBM,
  "video/mp4": SupportedMediaMime.MP4,
};

const CreateAttachmentMock = (
  mediaType: SupportedMediaType,
  type: AttachmentType,
  file: File
) => {
  return {
    id: file.name,
    type,
    metadata: {
      mime: mimeFromType[file.type], //file.type,
      type: mediaType,
      url: URL.createObjectURL(file),
    },
  } as AttachmentBaseType;
};

export const useLogic = ({ community, onAdd }: CreatePostProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormType>();

  const [attachment, setAttachment] = useState<AttachmentBaseType>();
  const [file, setFile] = useState<File>();
  const [showTokenPicker, setShowTokenPicker] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [getUrl] = useGetUrlMutation();
  const [createPost] = useCreatePostMutation();

  const formatsByType: Record<SupportedMediaType, SupportedMediaMime[]> = {
    [SupportedMediaType.IMAGE]: [
      SupportedMediaMime.JPEG,
      SupportedMediaMime.PNG,
    ],
    [SupportedMediaType.AUDIO]: [
      SupportedMediaMime.MP3,
      SupportedMediaMime.WAV,
    ],
    [SupportedMediaType.VIDEO]: [
      SupportedMediaMime.MP4,
      SupportedMediaMime.WEBM,
    ],
  };
  const sizeByType: Record<SupportedMediaType, string> = {
    [SupportedMediaType.IMAGE]: "30MB",
    [SupportedMediaType.AUDIO]: "1GB",
    [SupportedMediaType.VIDEO]: "2GB",
  };

  const attachmentName = useMemo(() => {
    if (!attachment) return "";

    if (attachment.type === AttachmentType.TOKEN) return "Token";

    const type = (attachment.metadata as MediaMetadata).type.toString();
    return type.charAt(0) + type.toLowerCase().slice(1);
  }, [attachment]);

  const onSubmit = async (values: FormType) => {
    try {
      if (!values.text && !attachment) {
        setError("Please enter some text or attach content.");
        return false;
      }
      const createPostData: CreatePostInput = {
        communitySlug: community!.slug,
        pinned: values.pinned,
      };
      if (values.text && values.text.length) {
        createPostData.text = values.text;
      }

      if (file && attachment?.type === AttachmentType.MEDIA) {
        const getUrlResponse = await getUrl({
          variables: {
            data: {
              mime: (attachment.metadata as MediaMetadata).mime,
              type: (attachment.metadata as MediaMetadata).type,
              communitySlug: community!.slug,
            },
          },
        });
        const url = (
          getUrlResponse.data?.getUploadUrl.metadata as MediaMetadata
        ).url;
        const id = getUrlResponse.data?.getUploadUrl.id;
        if (!url || !id) {
          setError(DEFAULT_ERROR_MESSAGE);
          logError(
            `Failed to get upload url - No url. Got ${getUrlResponse.data?.getUploadUrl?.metadata?.__typename}`
          );
          return false;
        }
        createPostData.attachment = {
          id: id,
          type: AttachmentType.MEDIA,
          metadata: {
            type: (attachment.metadata as MediaMetadata).type,
            mime: (attachment.metadata as MediaMetadata).mime,
          },
        };

        // This can be paralellized in the future - we just need to define error handling (what to do when upload fails)
        if (!(await uploadToS3(file, url))) {
          setError("Upload failed");
          return false;
        }
      }

      if (attachment?.type === AttachmentType.TOKEN) {
        createPostData.attachment = {
          id: attachment.id,
          type: AttachmentType.TOKEN,
        };
      }

      const newPost = await createPost({
        variables: {
          data: createPostData,
        },
      });
      onAdd?.(newPost.data?.createPost);
      setError("");
      clearAttachment();
      reset();
    } catch (e) {
      setError("An unexpected error occurred, please try again.");
      await logError({
        e,
        message: "[handleCreatePost] create post failed",
      });
    }
  };

  const clearAttachment = () => {
    setAttachment(undefined);
    setFile(undefined);
  };

  const handleFileSelected =
    (mediaType: SupportedMediaType, type: AttachmentType) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) {
        clearAttachment();
        return;
      }
      const file = event.target.files[0];
      event.target.value = "";

      if (mimeFromType[file.type] === undefined) {
        setError(
          `Please attach ${formatsByType[mediaType].join(
            ", "
          )} format with max size of ${sizeByType[mediaType]}`
        );
        clearAttachment();
        return;
      }
      setError("");
      setAttachment(CreateAttachmentMock(mediaType, type, file));
      setFile(file);
    };

  const handleTokenSelected = (id: string) => {
    setFile(undefined);
    setShowTokenPicker(false);
    setAttachment({
      id,
      type: AttachmentType.TOKEN,
    });
  };

  const createTokenMap = (
    data: CreatedCommunityFeedBySlugQuery["createdCommunityBySlug"]
  ) => {
    if (!data) {
      return {};
    }
    return data.tokens.reduce<TokenMappedMetadata>((acc, token) => {
      const offer = data.offers?.find(
        (offer) =>
          (offer.tokens || []).map((t) => t.tokenId).indexOf(token.tokenId) >
            -1 && offer.active
      );
      if (!token) return acc;

      acc[token.tokenId] = {
        id: token.tokenId,
        total: token.supply ?? 0,
        available: token.creatorBalance ?? 0,
        checkoutId: offer?.id,
        price: offer?.price,
        currency: offer?.currency,
        name: token.name,
        description: token.description,
        uri: token.uri,
        type: token.tokenType,
        userHasIt: false,
        userHasPending: false,
      };

      return acc;
    }, {});
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    register,
    attachment,
    handleFileSelected,
    handleTokenSelected,
    attachmentName,
    clearAttachment,
    isSubmitting,
    showTokenPicker,
    setShowTokenPicker,
    createTokenMap,
    error,
    setError,
  };
};

export async function uploadToS3(
  file: File,
  url: string
  // TODO: remove after converting attachment type to string
  //overwriteType?: string
): Promise<boolean> {
  const formData = new FormData();
  //formData.append("Content-Type", overwriteType || file.type);
  formData.append("file", file);

  const response = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-type": file.type,
    },
  });

  if (!response.ok) {
    logError(response.statusText);
    return false;
  }

  return true;
}
