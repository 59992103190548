import * as Types from "../../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type CreateNewPostMutationVariables = Types.Exact<{
  data: Types.CreateNewPostInput;
}>;

export type CreateNewPostMutation = {
  createNewPost: { __typename: "Post"; id: string; text: string | null };
};

export type UpdatePostContentMutationVariables = Types.Exact<{
  data: Types.UpdatePostContentInput;
  updatePostContentId: Types.Scalars["String"];
}>;

export type UpdatePostContentMutation = {
  updatePostContent: { __typename: "Post"; id: string; text: string | null };
};

export type GetUrlMutationVariables = Types.Exact<{
  data: Types.GetUploadUrlRequest;
}>;

export type GetUrlMutation = {
  getUploadUrl: {
    __typename: "Attachment";
    id: string;
    type: Types.AttachmentType;
    metadata:
      | {
          __typename: "MediaMetadata";
          type: Types.SupportedMediaType;
          mime: Types.SupportedMediaMime;
          url: string | null;
          publicUrl: string | null;
        }
      | { __typename: "TokenMetadata"; id: string; claimable: string | null }
      | null;
  };
};

export type CreatePostMutationVariables = Types.Exact<{
  data: Types.CreatePostInput;
}>;

export type CreatePostMutation = {
  createPost: {
    __typename: "Post";
    id: string;
    pinned: boolean;
    text: string | null;
    attachment: {
      __typename: "Attachment";
      id: string;
      type: Types.AttachmentType;
      metadata:
        | {
            __typename: "MediaMetadata";
            mime: Types.SupportedMediaMime;
            type: Types.SupportedMediaType;
          }
        | { __typename: "TokenMetadata"; id: string; claimable: string | null }
        | null;
    } | null;
    reactions: Array<{
      __typename: "BasePostReaction";
      reaction: string | null;
      count: number | null;
      userHasReacted: boolean | null;
    }> | null;
  };
};

export type CreatedCommunityFeedBySlugQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
  type: Types.Scalars["String"];
  useChain: Types.Scalars["Boolean"];
  activeContests: Types.Scalars["Boolean"];
}>;

export type CreatedCommunityFeedBySlugQuery = {
  createdCommunityBySlug: {
    __typename: "Community";
    name: string;
    slug: string;
    id: string;
    description: string | null;
    imageUrl: string | null;
    bannerImageUrl: string | null;
    contactEmail: string | null;
    creatorAvatar: {
      __typename: "CreatorAvatar";
      name: string | null;
      imageUrl: string | null;
    };
    discordServer: {
      __typename: "DiscordServer";
      serverId: string | null;
      roleId: string | null;
    } | null;
    tokens: Array<{
      __typename: "Token";
      communityAddress: string;
      tokenId: string;
      name: string | null;
      uri: string | null;
      description: string | null;
      tokenType: Types.TokenType;
      tokenStatus: Types.TokenStatus | null;
      supply: number | null;
      creatorBalance: number | null;
      benefits: Array<{
        __typename: "TokenBenefit";
        type: Types.BenefitType;
      }> | null;
    }>;
    offers: Array<{
      __typename: "Offer";
      id: string;
      name: string;
      price: string;
      currency: string;
      active: boolean;
      onAboutPage: boolean | null;
      onPrivatePage: boolean | null;
      tokens: Array<{ __typename: "Token"; tokenId: string }> | null;
    }>;
    contests: Array<{ __typename: "Contest"; tokenIds: Array<number> }>;
  } | null;
};

export const CreateNewPostDocument = gql`
  mutation CreateNewPost($data: CreateNewPostInput!) {
    createNewPost(data: $data) {
      id
      text
    }
  }
`;
export type CreateNewPostMutationFn = Apollo.MutationFunction<
  CreateNewPostMutation,
  CreateNewPostMutationVariables
>;

/**
 * __useCreateNewPostMutation__
 *
 * To run a mutation, you first call `useCreateNewPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewPostMutation, { data, loading, error }] = useCreateNewPostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNewPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewPostMutation,
    CreateNewPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNewPostMutation,
    CreateNewPostMutationVariables
  >(CreateNewPostDocument, options);
}
export type CreateNewPostMutationHookResult = ReturnType<
  typeof useCreateNewPostMutation
>;
export type CreateNewPostMutationResult =
  Apollo.MutationResult<CreateNewPostMutation>;
export type CreateNewPostMutationOptions = Apollo.BaseMutationOptions<
  CreateNewPostMutation,
  CreateNewPostMutationVariables
>;
export const UpdatePostContentDocument = gql`
  mutation UpdatePostContent(
    $data: UpdatePostContentInput!
    $updatePostContentId: String!
  ) {
    updatePostContent(data: $data, id: $updatePostContentId) {
      id
      text
    }
  }
`;
export type UpdatePostContentMutationFn = Apollo.MutationFunction<
  UpdatePostContentMutation,
  UpdatePostContentMutationVariables
>;

/**
 * __useUpdatePostContentMutation__
 *
 * To run a mutation, you first call `useUpdatePostContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostContentMutation, { data, loading, error }] = useUpdatePostContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      updatePostContentId: // value for 'updatePostContentId'
 *   },
 * });
 */
export function useUpdatePostContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePostContentMutation,
    UpdatePostContentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePostContentMutation,
    UpdatePostContentMutationVariables
  >(UpdatePostContentDocument, options);
}
export type UpdatePostContentMutationHookResult = ReturnType<
  typeof useUpdatePostContentMutation
>;
export type UpdatePostContentMutationResult =
  Apollo.MutationResult<UpdatePostContentMutation>;
export type UpdatePostContentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostContentMutation,
  UpdatePostContentMutationVariables
>;
export const GetUrlDocument = gql`
  mutation getUrl($data: GetUploadUrlRequest!) {
    getUploadUrl(data: $data) {
      id
      type
      metadata {
        ... on MediaMetadata {
          type
          mime
          url
          publicUrl
        }
        ... on TokenMetadata {
          id
          claimable
        }
      }
    }
  }
`;
export type GetUrlMutationFn = Apollo.MutationFunction<
  GetUrlMutation,
  GetUrlMutationVariables
>;

/**
 * __useGetUrlMutation__
 *
 * To run a mutation, you first call `useGetUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUrlMutation, { data, loading, error }] = useGetUrlMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetUrlMutation,
    GetUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetUrlMutation, GetUrlMutationVariables>(
    GetUrlDocument,
    options
  );
}
export type GetUrlMutationHookResult = ReturnType<typeof useGetUrlMutation>;
export type GetUrlMutationResult = Apollo.MutationResult<GetUrlMutation>;
export type GetUrlMutationOptions = Apollo.BaseMutationOptions<
  GetUrlMutation,
  GetUrlMutationVariables
>;
export const CreatePostDocument = gql`
  mutation createPost($data: CreatePostInput!) {
    createPost(data: $data) {
      id
      pinned
      text
      attachment {
        id
        type
        metadata {
          ... on MediaMetadata {
            mime
            type
          }
          ... on TokenMetadata {
            id
            claimable
          }
        }
      }
      reactions {
        reaction
        count
        userHasReacted
      }
    }
  }
`;
export type CreatePostMutationFn = Apollo.MutationFunction<
  CreatePostMutation,
  CreatePostMutationVariables
>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostMutation,
    CreatePostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(
    CreatePostDocument,
    options
  );
}
export type CreatePostMutationHookResult = ReturnType<
  typeof useCreatePostMutation
>;
export type CreatePostMutationResult =
  Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<
  CreatePostMutation,
  CreatePostMutationVariables
>;
export const CreatedCommunityFeedBySlugDocument = gql`
  query CreatedCommunityFeedBySlug(
    $slug: String!
    $type: String!
    $useChain: Boolean!
    $activeContests: Boolean!
  ) {
    createdCommunityBySlug(slug: $slug) {
      name
      slug
      creatorAvatar {
        name
        imageUrl
      }
      id
      description
      imageUrl
      bannerImageUrl
      contactEmail
      discordServer {
        serverId
        roleId
      }
      tokens(type: $type, useChain: $useChain) {
        communityAddress
        tokenId
        name
        uri(useChain: $useChain)
        description
        tokenType
        tokenStatus
        supply
        benefits {
          type
        }
        creatorBalance
      }
      offers {
        id
        name
        price
        currency
        active
        tokens {
          tokenId
        }
        onAboutPage
        onPrivatePage
      }
      contests(active: $activeContests) {
        tokenIds
      }
    }
  }
`;

/**
 * __useCreatedCommunityFeedBySlugQuery__
 *
 * To run a query within a React component, call `useCreatedCommunityFeedBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatedCommunityFeedBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedCommunityFeedBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      type: // value for 'type'
 *      useChain: // value for 'useChain'
 *      activeContests: // value for 'activeContests'
 *   },
 * });
 */
export function useCreatedCommunityFeedBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreatedCommunityFeedBySlugQuery,
    CreatedCommunityFeedBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreatedCommunityFeedBySlugQuery,
    CreatedCommunityFeedBySlugQueryVariables
  >(CreatedCommunityFeedBySlugDocument, options);
}
export function useCreatedCommunityFeedBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreatedCommunityFeedBySlugQuery,
    CreatedCommunityFeedBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreatedCommunityFeedBySlugQuery,
    CreatedCommunityFeedBySlugQueryVariables
  >(CreatedCommunityFeedBySlugDocument, options);
}
export type CreatedCommunityFeedBySlugQueryHookResult = ReturnType<
  typeof useCreatedCommunityFeedBySlugQuery
>;
export type CreatedCommunityFeedBySlugLazyQueryHookResult = ReturnType<
  typeof useCreatedCommunityFeedBySlugLazyQuery
>;
export type CreatedCommunityFeedBySlugQueryResult = Apollo.QueryResult<
  CreatedCommunityFeedBySlugQuery,
  CreatedCommunityFeedBySlugQueryVariables
>;
