import { css, Theme } from "@emotion/react";

import { bottomMargin } from "styles/global/layout";

export const syncDiscordContainer = css`
  ${bottomMargin("5/12px")}
`;

export const smallBlueButton = (theme: Theme) => css`
  font-size: ${theme.text.sizes.small};
  color: ${theme.text.colors.primary};
  font-weight: ${theme.text.fontWeights.bold};
`;

export const smallBlueButtonRed = (theme: Theme) => css`
  color: ${theme.colorMap.destructive.default};
`;

/** Fields */

export const fields = (theme: Theme) => css`
  list-style: none;
  margin: 40px 0 0 0;
  padding: 0;

  li {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    label {
      display: block;
      margin-bottom: 8px;
    }

    > div:not(#updateProfileImage) {
      display: block;
      padding: 20px 16px;
      background-color: ${theme.textInput.backgroundColor};
      color: ${theme.textInput.textColor};
      border: ${theme.textInput.border.width} ${theme.textInput.border.style}
        ${theme.textInput.border.color};
      border-radius: ${theme.textInput.border.radius};
      position: relative;

      p {
        line-height: 1.2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;
        color: inherit;
      }

      button,
      a {
        position: absolute;
        top: calc(50%);
        transform: translateY(-50%);
        right: 20px;
      }
    }
  }
`;

export const fieldExportPrivateKey = css`
  margin-top: -30px;
`;
