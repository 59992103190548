import { css, Theme } from "@emotion/react";

export const reactionList =
  (divider: boolean, alternate: boolean) => (theme: Theme) =>
    css`
      ${divider &&
      `
        a {
          border-bottom: 1px solid ${theme.background.colors.secondary}
        }
        a:last-child {
          border-bottom: none;
        }
      `};
      ${alternate &&
      `
        a:nth-child(2n-1) {
          background-color: ${theme.background.colors.secondary};
          border-radius: 10px;
        }
      `}
    `;

export const link = (theme: Theme) => css`
  display: flex;
  padding: 20px;
  background: ${theme.background.colors.primary};
  margin: 7px;
`;

export const tokenInfo = css`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
`;

export const tokenStatus = css`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 22px;
`;

export const tokenImage = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80px;
  height: 80px;
`;

export const tokenDescription = css`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const arrow = css`
  width: 16px;
  height: 16px;
`;
