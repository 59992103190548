import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  background-color: #fff;
  border-radius: ${theme.verticalSpaces.large[5]};
  padding: ${theme.verticalSpaces.large[6]};
  margin-bottom: ${theme.verticalSpaces.large[8]};
`;

export const link = css`
  font-weight: bold;
  color: inherit;
`;
