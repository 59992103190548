import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode";
import type { ElementFormatType, LexicalNode, NodeKey } from "lexical";

import {
  AttachmentType,
  MediaMetadata,
  SupportedMediaMime,
  SupportedMediaType,
} from "apollo/graphql.generated";
import { Spread } from "utils/types";

import ImageAttachment from "../../Attachments/Image";

import EditorActionsHandler from "./EditorActionsHandler";

type Node = ImageNode | LexicalNode | null | undefined;

export type SerializedImageNode = Spread<
  {
    mediaMime: SupportedMediaMime;
    mediaType: SupportedMediaType;
    mediaUrl: string;
    attachmentType: AttachmentType.MEDIA;
    attachmentName?: string;
    type: "image";
    version: 1;
  },
  SerializedDecoratorBlockNode
>;

export class ImageNode extends DecoratorBlockNode {
  __attachment: MediaMetadata;
  __attachmentFileName?: string;

  static getType(): string {
    return "image";
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.__attachment,
      node.__attachmentFileName,
      node.__format,
      node.__key
    );
  }

  constructor(
    attachment: MediaMetadata,
    fileName?: string,
    format?: ElementFormatType,
    key?: NodeKey
  ) {
    super(format, key);
    this.__attachment = attachment;
    this.__attachmentFileName = fileName;
  }

  decorate(): JSX.Element {
    return (
      <EditorActionsHandler
        nodeKey={this.getKey()}
        nodeCheck={(n) => $isImageNode(n as Node)}
      >
        <ImageAttachment attachment={this.__attachment} />
      </EditorActionsHandler>
    );
  }

  isInline(): false {
    return false;
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const node = $createImageNode(
      {
        mime: serializedNode.mediaMime,
        type: serializedNode.mediaType,
        url: serializedNode.mediaUrl,
      },
      serializedNode.attachmentName
    );
    node.setFormat(serializedNode.format);
    return node;
  }

  exportJSON(): SerializedImageNode {
    return {
      ...super.exportJSON(),
      mediaMime: this.__attachment.mime,
      mediaType: this.__attachment.type,
      mediaUrl: this.__attachment.url ?? "",
      attachmentType: AttachmentType.MEDIA,
      attachmentName: this.__attachmentFileName,
      type: this.getType(),
      version: 1,
    };
  }
}

export function $createImageNode(
  attachment: MediaMetadata,
  fileName?: string
): ImageNode {
  return new ImageNode(attachment, fileName);
}

export function $isImageNode(node: Node): node is ImageNode {
  return node instanceof ImageNode;
}
