import React, { useMemo } from "react";

import {
  AttachmentType,
  SupportedMediaType,
  TokenStatus,
} from "apollo/graphql.generated";
import { ReactComponent as AudioIcon } from "assets/icons/audio.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image-landscape.svg";
import { ReactComponent as TokenIcon } from "assets/icons/token.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as VideoIcon } from "assets/icons/video.svg";
import { NewAppButton } from "components/Button";
import { newAppButton } from "components/Button/styles";
import ButtonLink from "components/ButtonLink";
import CardContainer from "components/CardContainer";
import { MessageBanner } from "components/MessageBanner";
import modalBaseStyles from "components/Modals/ModalBody/styles.module.scss";
import TokenList from "components/TokenList";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { Attachment, CreatorTitle } from "containers/CommunityV3/ContentFeed";
import { TokenMapProvider } from "containers/CommunityV3/ContentFeed/Post/TokenMapContext";

import { APP_SUBDOMAIN, APP_URL } from "../../../../config";
import useAppLocation from "../../../../hooks/useAppLocation";

import { TokenListModal } from "./TokenListModal";
import { styles } from "./styles";
import { CreatePostProps, useLogic } from "./useLogic";

export const CreatePost = ({ community, onAdd, slug }: CreatePostProps) => {
  const {
    handleSubmit,
    register,
    attachment,
    handleFileSelected,
    handleTokenSelected,
    attachmentName,
    clearAttachment,
    isSubmitting,
    showTokenPicker,
    setShowTokenPicker,
    createTokenMap,
    setError,
    error,
  } = useLogic({ community, onAdd, slug });
  const { getEditCommunityURL } = useAppLocation();

  const tokenMap = useMemo(() => {
    if (community) {
      return createTokenMap(community);
    }
    return {};
  }, [community]);

  if (!community) {
    return null;
  }

  const MintNewTokenButton = () => (
    <ButtonLink
      additionalCss={styles.mintNewTokenButton}
      to={getEditCommunityURL(
        slug ?? APP_SUBDOMAIN,
        APP_URL.editCommunity.mintTokens
      )}
    >
      Mint Token
    </ButtonLink>
  );

  const noToken = (
    <CardContainer
      background="secondary"
      additionalCss={styles.noTokensContainer}
    >
      <Title additionalCss={styles.noTokensTitle} size="large">
        You Haven&apos;t Minted Any Tokens Yet
      </Title>
      <Text>
        Get started by minting a membership token for your community. This is
        what people will need to access your community.
      </Text>
      <div css={styles.buttonContainer}>
        <MintNewTokenButton />
      </div>
    </CardContainer>
  );

  const tokens = community.tokens
    .filter((token) => token.tokenStatus === TokenStatus.Minted)
    .map((token) => {
      return {
        id: token.tokenId,
        type: token.tokenType,
        image: token.uri ?? "",
        name: token.name ?? "",
        link: "#",
        supply: token.supply ?? 0,
        remaining: token.creatorBalance ?? 0,
      };
    });

  return (
    <TokenMapProvider value={tokenMap}>
      <div css={styles.container}>
        <form onSubmit={handleSubmit} id="newPostForm">
          <div css={styles.header}>
            <CreatorTitle
              avatar={community.creatorAvatar}
              showVerified={false}
            />
            <div className="pin">
              <input type="checkbox" id="pinPostCB" {...register("pinned")} />
              <label htmlFor="pinPostCB">
                <Text size="small">Pin this post</Text>
              </label>
            </div>
          </div>
          <textarea
            rows={4}
            placeholder="Write something to your community..."
            required={!attachment}
            {...register("text")}
          />
        </form>
        {attachment && <Attachment attachment={attachment} />}
        <div css={styles.header}>
          <input
            type="file"
            accept="image/*"
            id="imageFileUpload"
            onChange={handleFileSelected(
              SupportedMediaType.IMAGE,
              AttachmentType.MEDIA
            )}
          />
          <input
            type="file"
            accept="audio/*"
            id="audioFileUpload"
            onChange={handleFileSelected(
              SupportedMediaType.AUDIO,
              AttachmentType.MEDIA
            )}
          />
          <input
            type="file"
            accept="video/*"
            id="videoFileUpload"
            onChange={handleFileSelected(
              SupportedMediaType.VIDEO,
              AttachmentType.MEDIA
            )}
          />
          {!attachment && (
            <>
              <label htmlFor="imageFileUpload" css={newAppButton("secondary")}>
                <ImageIcon />
                Image
              </label>
              <label htmlFor="audioFileUpload" css={newAppButton("secondary")}>
                <AudioIcon />
                Audio
              </label>
              <label htmlFor="videoFileUpload" css={newAppButton("secondary")}>
                <VideoIcon />
                Video
              </label>
              <NewAppButton
                color="secondary"
                onClick={() => setShowTokenPicker(true)}
              >
                <TokenIcon />
                Token
              </NewAppButton>
            </>
          )}
          {attachment && (
            <NewAppButton color="destructive" onClick={clearAttachment}>
              <TrashIcon />
              Remove {attachmentName}
            </NewAppButton>
          )}
          <div css={styles.spacer} />
          <NewAppButton
            loading={isSubmitting}
            color="primary"
            type="submit"
            form="newPostForm"
          >
            Post
          </NewAppButton>
        </div>

        <MessageBanner onClose={() => setError("")} message={error} />

        <TokenListModal
          id="token-picker-modal"
          onClose={() => setShowTokenPicker(false)}
          className={`${modalBaseStyles.modal} ${modalBaseStyles.modalLarge}`}
          overlayClassName={modalBaseStyles.modalOverlay}
          isOpen={showTokenPicker}
          closeTimeoutMS={800}
          ariaHideApp={false}
        >
          <div css={styles.tokenListHeader}>
            <Text bold size="xlarge">
              Choose a Token
            </Text>
            <Text>
              Add a token to your post for people to buy or claim for free
            </Text>
          </div>
          {!tokens.length && noToken}
          {!!tokens.length && (
            <TokenList
              tokens={tokens}
              divider={true}
              onClick={handleTokenSelected}
              alternateShade={false}
            />
          )}
        </TokenListModal>
      </div>
    </TokenMapProvider>
  );
};
