import React, { FC, memo } from "react";

import { ReactComponent as AlertIcon } from "assets/icons/error.svg";
import ButtonLink from "components/ButtonLink";
import CardContainer from "components/CardContainer";
import { CommunitySEO } from "components/Layout/SEO";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { COMMUNITY_PAGE_TITLE } from "config";

import * as styles from "./styles";

type Props = {
  redirectUrl: string;
};

const FailedStatus: FC<Props> = (props: Props) => {
  const { redirectUrl } = props;
  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.CHECKOUT_PAYMENT_FAILED} />

      <div css={styles.container}>
        <CardContainer additionalCss={styles.CardContainer}>
          <AlertIcon />
          <Title size="small">Payment Failed</Title>
          <Text as="p">
            We’re sorry, there was an error processing your payment. Please try
            again with a different payment method.
          </Text>
          <ButtonLink to={redirectUrl}>Go Back</ButtonLink>
        </CardContainer>
      </div>
    </>
  );
};

export default memo(FailedStatus);
