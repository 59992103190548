import { css, Theme } from "@emotion/react";

import { BackgroundStyle } from "./GlobalStatusBanner";

export const banner = (backgroundStyle: BackgroundStyle) => (theme: Theme) =>
  css`
    display: flex;
    padding: ${theme.verticalSpaces.large[4]};
    background-color: ${backgroundStyle === "light"
      ? theme.background.colors.secondary
      : theme.invertedBackground.colors.primary};
  `;

export const close = (backgroundStyle: BackgroundStyle) => (theme: Theme) =>
  css`
    circle {
      fill: ${backgroundStyle === "light"
        ? theme.invertedText.colors.primary
        : theme.text.colors.primary};
    }
    path {
      fill: ${backgroundStyle === "light"
        ? theme.text.colors.primary
        : theme.invertedText.colors.primary};
    }
  `;

export const warning = (backgroundStyle: BackgroundStyle) => (theme: Theme) =>
  css`
    min-width: 16px;
    margin-right: 8px;

    path {
      fill: ${backgroundStyle === "light"
        ? theme.text.colors.primary
        : theme.invertedText.colors.primary};
    }
  `;

export const messagesContainer = css`
  flex: 1;
`;

export const messages = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const message = css`
  display: flex;
  align-items: baseline;
`;
