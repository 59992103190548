import React, { memo } from "react";

import BackgroundImage from "assets/images/background-gradient-no-grain.jpg";
import Layout from "components/Layout";
import { AppSEO } from "components/Layout/SEO";
import { SYSTEM_PAGE_TITLE, FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";

import GetStarted from "./GetStarted";
import * as styles from "./styles";

const UserDashboard = () => {
  const showCreatorDashboard = useFeatureFlag(
    FEATURE_FLAG.ENABLE_CREATOR_DASHBOARD
  );

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.CREATE_COMMUNITY} />

      <Layout
        navLayout="createNewCommunity"
        css={styles.container}
        backgroundShow={true}
        backgroundColor="secondary"
        backgroundImageUrl={BackgroundImage}
      >
        <div css={styles.content}>{showCreatorDashboard && <GetStarted />}</div>
      </Layout>
    </>
  );
};

export default memo(UserDashboard);
