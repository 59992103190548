import { css, Theme } from "@emotion/react";

export const container = css`
  position: relative;
  display: inline-block;

  path {
    fill: currentColor;
  }
`;

export const label = (theme: Theme) => css`
  transition: opacity 0.6s ${theme.animations.easeOutQuart};

  display: flex;
  flex-direction: row;
  align-content: center;
  width: 100%;
  align-items: center;
  text-align: left;

  span {
    flex-grow: 1;
    padding-right: 4px;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const menu = css`
  overflow: hidden;
  position: absolute;
  z-index: 1;
  right: 0;
  min-width: 102px;
`;

export const dropdownGap = (height: number) => css`
  top: ${height}px;
`;

export const arrowWrapper = css`
  display: flex;
`;
export const arrow = css`
  height: 8px;
  width: 8px;
`;
