import * as React from "react";
import { FC, memo } from "react";

import { TokenBenefit } from "apollo/graphql.generated";
import BenefitDescription from "components/BenefitDescription/BenefitDescription";

import CommunityText from "../../_Community/Text";

import * as styles from "./styles";

type DistributionBenefitListProps = {
  tokenBenefits: TokenBenefit[];
};

const DistributionBenefitList: FC<DistributionBenefitListProps> = ({
  tokenBenefits,
}) => {
  return (
    <ul css={styles.benefitList}>
      {tokenBenefits.map((benefit, index) => (
        <li key={index}>
          <CommunityText color="onPrimaryBackgroundPrimaryColor" size="small">
            <BenefitDescription benefit={benefit} />
          </CommunityText>
        </li>
      ))}
    </ul>
  );
};

export default memo(DistributionBenefitList);
