import { css, Theme } from "@emotion/react";

import { textMediumStyles } from "styles/global/typography";

import { topMargin } from "../../styles/global/layout";

export const baseFieldGroup = css`
  margin-bottom: 32px;
`;

export const baseInputLabel = css`
  display: block;
`;

export const baseInputDescription = css`
  display: block;
`;

export const baseInputContainer =
  (error: string | undefined, padding = true) =>
  (theme: Theme) =>
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      min-height: 64px;
      outline: none;
      ${padding &&
      css`
        padding: 8px 16px;
      `}

      margin: 0;
      ${topMargin("4/8px")}
      ${textMediumStyles(theme)}
      border-radius: ${theme.textInput.border.radius};
      border-width: ${error ? "2px" : `${theme.textInput.border.width}`};
      border-style: ${error ? "solid" : `${theme.textInput.border.style}`};
      border-color: ${error
        ? `${theme.colorMap.destructive.default}`
        : `${theme.textInput.border.color}`};
      background-color: ${theme.textInput.backgroundColor};

      &::placeholder {
        opacity: initial; //Remove if we stop using sanitize css
        color: ${theme.textInput.placeholderColor};
      }
    `;

export const baseInput = (theme: Theme) =>
  css`
    color: ${theme.textInput.textColor};
    font-family: ${theme.text.fontFamily.primary};
    width: 100%;
    appearance: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;

    ${textMediumStyles(theme)}
    &::placeholder {
      opacity: initial; //Remove if we stop using sanitize css
      color: ${theme.textInput.placeholderColor};
    }
  `;

export const baseInputErrorText = (theme: Theme) => css`
  ${textMediumStyles(theme)}
  color: ${theme.colorMap.destructive.default};
  padding: 0;
  margin: 8px 0 0 0;
`;
