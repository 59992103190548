import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { string as yupString } from "yup";

import { useUpdateGeneralInfoMutation } from "apollo/queries";
import TextField from "components/Inputs/TextField";
import LoadingIcon from "components/LoadingIcon";
import { logError } from "services/logger";

export interface Props {
  communityId: string;
  creatorNameValue: string;
}

const MAX_LENGTH = 40;

const CreatorNameUpdate: FC<Props> = ({ communityId, creatorNameValue }) => {
  const [doUpdateGeneralInfoMutation] = useUpdateGeneralInfoMutation();

  const [creatorName, setCreatorName] = useState<string>(
    creatorNameValue || ""
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCreatorName(creatorNameValue);
  }, [creatorNameValue]);

  const validationSchema = yupString()
    .trim()
    .max(MAX_LENGTH)
    .required("Creator Name is required.");

  const update = async (
    creatorNameToUpdate: string,
    communityId: string,
    creatorName: string
  ) => {
    setError("");
    if (creatorNameToUpdate === creatorName) return;

    try {
      creatorNameToUpdate = await validationSchema.validate(
        creatorNameToUpdate
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
      if (inputRef?.current) {
        inputRef.current.value = creatorName;
      }
      return;
    }

    try {
      setLoading(true);
      const res = await doUpdateGeneralInfoMutation({
        variables: {
          data: {
            creatorName: creatorNameToUpdate,
          },
          updateGeneralInfoId: communityId,
        },
      });
      const communityResponse = res.data?.updateGeneralInfo;
      setCreatorName(communityResponse?.creatorAvatar?.name || "");
    } catch (error) {
      setError("Update failed");
      await logError({
        error,
        message: "[handleCreatorNameOnBlur] update community failed",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreatorNameOnBlur = useCallback(
    async (event: React.FocusEvent<HTMLInputElement>) =>
      update(event.target.value, communityId, creatorName),
    [communityId, creatorName]
  );

  const handleOnEnter = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) =>
      update(event.currentTarget.value, communityId, creatorName),
    [communityId, creatorName]
  );

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <TextField
      ref={inputRef}
      label="Creator Name"
      labelType="title"
      className=""
      placeholder="Enter creator name"
      maxLength={MAX_LENGTH}
      id="creatorName"
      defaultValue={creatorName}
      onBlur={handleCreatorNameOnBlur}
      error={error}
      onChange={() => setError("")}
      onKeyPress={(e) => e.key === "Enter" && handleOnEnter(e)}
      onClick={() => setError("")}
    />
  );
};

export default memo(CreatorNameUpdate);
