import { css, Theme } from "@emotion/react";

import {
  baseBackgroundTextColor,
  baseTextColor,
} from "styles/global/typography";
import { OnBackgroundTextColorsKeys } from "styles/theme";

import { SystemTitleProps } from "./index";

export const base = (theme: Theme) =>
  css`
    font-family: ${theme.title.fontFamily};
    font-weight: ${theme.title.fontWeight};
    text-transform: ${theme.title.textTransform};
    font-feature-settings: "ss02" on; // Single story "g"
  `;

export const bySize =
  (size: NonNullable<SystemTitleProps["size"]>) => (theme: Theme) =>
    css`
      font-size: ${theme.title.sizes[size]};
      line-height: ${theme.title.lineHeights[size]};
      letter-spacing: ${theme.title.letterSpacing[size]};

      ${theme.mediaQueries.smallOnly} {
        font-size: ${theme.title.sizes.smallScreen[size]};
        line-height: ${theme.title.lineHeights.smallScreen[size]};
      }
    `;

export const byColor = (
  color: NonNullable<SystemTitleProps["color"]>,
  inverted: boolean
) => baseTextColor(color, inverted);

export const byBackgroundTextColor = (color: OnBackgroundTextColorsKeys) =>
  baseBackgroundTextColor(color);
