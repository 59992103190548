import React, { FC, memo, PropsWithChildren, MouseEventHandler } from "react";

import { Interpolation, Theme } from "@emotion/react";

import { ColorKey, ComponentColors, ShadeColorKey } from "styles/theme";
import {
  CardContainerBorderRadiusSizesKeys,
  CardContainerPaddingSizesKeys,
  ShadowsSizes,
} from "styles/theme/theme.types";

import { borderStyle, containerStyles } from "./styles";

export type Background = keyof Pick<ComponentColors, "primary" | "secondary">;

export type SystemCardContainerProps = {
  backgroundShade?: {
    color: ColorKey;
    shade: ShadeColorKey;
  };
  background?: Background;
  /**
   * Use this property for a default border. Should not be used if other border properties are used.
   */
  border?: boolean;
  borderRadius?: CardContainerBorderRadiusSizesKeys;
  borderColor?: ColorKey | "divider";
  borderWidth?: number;
  padding?: CardContainerPaddingSizesKeys;
  flushHorizontal?: boolean;
  centerText?: boolean;
  shadow?: ShadowsSizes;
  as?: Pick<JSX.IntrinsicElements, "div" | "article">;
  onClick?: MouseEventHandler<HTMLDivElement>;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
  /**
   * @deprecated Use "extraCss" instead.
   */
  additionalCss?: Interpolation<Theme>;
  role?: React.AriaRole;
};

const SystemCardContainer: FC<PropsWithChildren<SystemCardContainerProps>> = ({
  backgroundShade,
  background = "primary",
  as = "div",
  border = false,
  borderRadius = "medium",
  borderColor,
  borderWidth,
  padding = "medium",
  flushHorizontal = false,
  shadow,
  children,
  extraCss,
  additionalCss,
  onClick,
  ...rest
}) => {
  const Tag = as as keyof NonNullable<SystemCardContainerProps["as"]>;

  return (
    <Tag
      onClick={onClick}
      css={[
        containerStyles(
          background,
          backgroundShade,
          padding,
          flushHorizontal,
          shadow
        ),
        borderStyle(border, borderRadius, borderColor, borderWidth),
        additionalCss,
        extraCss,
      ]}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default memo(SystemCardContainer);
