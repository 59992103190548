import { css, Theme } from "@emotion/react";

import { SystemCardContainerProps } from "components/CardContainer";
import { textWithLink } from "styles/global/typography";

export const layout = (theme: Theme) => css`
  display: grid;
  grid-row-gap: ${theme.verticalSpaces.large[8]};
  grid-column-gap: ${theme.layout.gridColumnWidth};
  grid-template-columns: ${theme.layout.gridColumns(6, 6)};
  grid-template-areas:
    "token title"
    "token creator"
    "token description"
    "token detail1"
    "token detail2"
    "token detail3";
  margin: 0 auto;

  ${theme.mediaQueries.mediumOnly} {
    grid-template-columns: ${theme.layout.gridColumns(5, 7)};
  }

  ${theme.mediaQueries.smallOnly} {
    grid-template-columns: ${theme.layout.gridColumns(12)};
    grid-template-areas:
      "title"
      "token"
      "creator"
      "description"
      "detail1"
      "detail2"
      "detail3";
  }
`;

export const loadingIcon = (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.verticalSpaces.large[9]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[9]};
  }
`;

export const description = css`
  grid-area: description;

  ${textWithLink}
`;

export const detail1 = css`
  grid-area: detail1;
`;

export const detail2 = css`
  grid-area: detail2;
`;

export const detail3 = css`
  grid-area: detail3;
`;

export const tokenImage = css`
  grid-area: token;
`;

export const claimTokenButton = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.small[8]};
  /**
  TODO: Hack to get rid of extra space on token detail claim page 
  without affecting the EnterContestForm in other places
   */
  ${theme.mediaQueries.smallOnly} {
    margin-bottom: -${theme.verticalSpaces.small[9]};
  }
`;

export const titleContainer = css`
  grid-area: title;
`;

export const creatorContainerLayout = (theme: Theme) =>
  css`
    grid-area: creator;
    display: grid;
    grid-row-gap: ${theme.verticalSpaces.large[8]};
    grid-column-gap: ${theme.layout.gridColumnWidth};
    grid-template-columns: ${theme.layout.gridColumns(6, 6)};
    justify-content: center;
    margin-top: ${theme.verticalSpaces.large[8]} 0 0 0;

    ${theme.mediaQueries.mediumOnly} {
      grid-template-columns: ${theme.layout.gridColumns(12)};
    }
    ${theme.mediaQueries.smallOnly} {
      grid-template-columns: ${theme.layout.gridColumns(12)};
    }
  `;

export const creator = (theme: Theme) =>
  css`
    display: flex;
    align-items: center;
    margin-top: ${theme.verticalSpaces.large[3]};

    .creatorAvatar {
      width: 32px;
      height: 32px;
      margin-right: 6px;
    }

    .creatorName {
      margin-right: 4px;
    }
  `;

export const form = (theme: Theme) => css`
  width: 100%;
  max-width: 484px;
  margin-top: 0;
  margin-bottom: ${theme.verticalSpaces.large[9]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.small[9]};
  }
`;

export const button = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[6]};
  text-transform: none;
`;

// override button's contentWrapper styling of centering content
export const communityButtonContainer = css`
  button div {
    justify-content: left;
  }
`;

export const communityCardContainer = css`
  padding: 0;
`;

// match the child component's background-color behavior
// if update this file's "styles.communityCardContainer" background-color,
// will need to override here as well.
export const transparentButton =
  (background: NonNullable<SystemCardContainerProps["background"]>) =>
  (theme: Theme) =>
    css`
      text-transform: none;
      text-align: left;
      background-color: ${background === "secondary"
        ? theme.background.colors.secondary
        : "white"};
      padding: 0;
      justify-content: left;
    `;
