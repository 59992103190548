import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) =>
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid ${theme.divider};
  `;

export const labelTextContainer = css`
  display: flex;
  align-items: center;

  > svg {
    min-width: 20px;
    min-height: 20px;
  }
`;

export const labelText = css`
  margin-left: 12px;
`;

export const removeButton = css`
  padding: 8px 8px 8px 12px;
  margin: -8px -8px -8px 0;
`;

export const closeIcon = (theme: Theme) => css`
  width: 12px;
  height: 12px;
  fill: ${theme.text.colors.primary};
`;
