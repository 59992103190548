import { css, Theme } from "@emotion/react";

import { textWithLink } from "styles/global/typography";
import { variables } from "styles/variables";

export const body = css`
  body {
    background-color: ${variables.colors.gray1};
  }
`;

export const container = css``;

export const header = (theme: Theme) => css`
  margin-bottom: 64px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    margin-bottom: 18px;
    max-height: 32px;
    path {
      fill: black;
    }
  }

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: 48px;
  }
`;

export const content = (theme: Theme) => css`
  display: grid;
  grid-template: "details orderPreview";
  grid-template-columns: 1fr 396px;
  gap: 44px;

  ${theme.mediaQueries.mediumDown} {
    grid-template:
      "orderPreview"
      "details";
    grid-template-columns: 1fr;
    gap: 64px;
  }

  ${theme.mediaQueries.smallOnly} {
    gap: 48px;
  }
`;

export const orderPreview = css`
  grid-area: orderPreview;
`;

export const details = css`
  grid-area: details;

  h3 {
    margin-bottom: 16px;
  }

  div {
    ${textWithLink}
  }

  a {
    margin-top: 32px;
  }
`;
