import { css, Theme } from "@emotion/react";

export const designLayout = (theme: Theme) =>
  css`
    display: grid;
    grid-column-gap: ${theme.layout.gridColumnWidth};
    grid-template-columns: ${theme.layout.gridColumns(9, 3)};
    justify-content: center;
    margin: 0 auto;
  `;

export const title = (theme: Theme) => css`
  margin: ${theme.verticalSpaces.large[12]};
  text-align: center;
`;

export const rightContainer = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[12]};
`;

export const errorContainer = (theme: Theme) => css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${theme.verticalSpaces.large[4]};
`;
