import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[8]};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const copyButton = (theme: Theme) => css`
  margin-left: auto;
  background: ${theme.buttons.tertiary.backgroundColor};
  border-radius: ${theme.buttons.tertiary.border.radius};
  padding: 8px 16px;
`;

export const linkContainer = (theme: Theme) => css`
  display: grid;
  grid-column-gap: ${theme.layout.gridColumnWidth};
  grid-template-columns: ${theme.layout.gridColumns(9, 3)};
  margin-top: ${theme.verticalSpaces.large[8]};
  width: 100%;
  align-items: center;
`;
