import React, { forwardRef, memo, ComponentPropsWithRef } from "react";

import _uniqueId from "lodash/uniqueId";
import { UseFormSetValue } from "react-hook-form";

import { WebsiteThemeFragment } from "apollo/queries";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down-bold.svg";
import Label from "components/Typography/Label";
import { Maybe } from "utils/types";

import Text from "../../Typography/Text";
import * as sharedStyles from "../sharedStyles";

import * as styles from "./styles";

type SelectInputProps = ComponentPropsWithRef<"select">;

export type SelectFontWeightFieldProps = SelectInputProps & {
  value: string;
  label: string;
  options: Array<Record<string, string>>;
  description?: Maybe<string>;
  error?: string | undefined;
  setValue: UseFormSetValue<WebsiteThemeFragment>;
  formName:
    | "title.fontWeight"
    | "text.fontWeight"
    | "text.boldFontWeight"
    | "label.fontWeight"
    | "title.fontWeight";
};

const SelectFontWeightField = forwardRef<
  HTMLSelectElement,
  SelectFontWeightFieldProps
>(
  (
    {
      value,
      error,
      id,
      label,
      options,
      description,
      setValue,
      formName,
    }: SelectFontWeightFieldProps,
    ref
  ) => {
    const htmlFor = _uniqueId("field-");

    const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setValue(formName, e.currentTarget.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    };

    return (
      <div css={sharedStyles.baseFieldGroup}>
        {label && (
          <Label size="small" extraCss={sharedStyles.baseInputLabel}>
            {label}
          </Label>
        )}

        {description && (
          <Text size="small" extraCss={sharedStyles.baseInputDescription}>
            {description}
          </Text>
        )}

        <div
          css={[
            sharedStyles.baseInputContainer(error, false),
            styles.selectContainer,
          ]}
        >
          <select
            ref={ref}
            id={id ?? htmlFor}
            value={value}
            css={[sharedStyles.baseInput, styles.select]}
            onChange={handleInputChange}
            onBlur={handleInputChange}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <ArrowIcon css={styles.arrowIcon} />
        </div>

        {error && <p css={sharedStyles.baseInputErrorText}>{error}</p>}
      </div>
    );
  }
);

export default memo(SelectFontWeightField);
