import { FC, memo, MouseEvent } from "react";

import * as styles from "../BaseButtonLink/styles";
import { AnchorProps } from "../BaseButtonLink/types";

const SystemButtonExternalLink: FC<AnchorProps> = ({
  children,
  className = "",
  size = "large",
  color = "primary",
  hasBorder = true,
  fluid = true,
  disable = false,
  extraCss,
  additionalCss,
  ...rest
}) => {
  const onClickHandler = (event: MouseEvent<HTMLAnchorElement>) => {
    if (disable) event.preventDefault();
  };

  return (
    <a
      className={className}
      css={[
        styles.button(color, size, fluid, hasBorder),
        disable ? styles.disabled : "",
        additionalCss,
        extraCss,
      ]}
      onClick={onClickHandler}
      {...rest}
    >
      {children}
    </a>
  );
};

export default memo(SystemButtonExternalLink);
