import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const headerContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 520px;
`;

export const loadingIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const tokenListContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${topMargin("14/80px")}
`;

export const wallContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const wallDescriptionContainer = css`
  width: 100%;
  max-width: 660px;
  ${topMargin("14/80px")}
`;

export const wallTitle = css`
  text-align: center;
`;

export const wallDescription = css`
  max-width: 720px;
  text-align: center;
  ${topMargin("4/8px")}
`;

export const joinConvoCtaContainer = (theme: Theme) => css`
  ${topMargin("10/40px")}
  padding: ${theme.verticalSpaces.large[9]};
`;

export const commentIcon = css`
  text-align: center;
  svg path {
    fill: black;
  }
`;

export const joinConvoCtaDescription = css`
  text-align: center;
  color: black;
  ${topMargin("9/32px")}
`;

export const linkContainer = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  ${topMargin("9/32px")}
`;

export const navLink = css`
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const distributionCardsContainer = css`
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  ${topMargin("12/46px")}
`;
