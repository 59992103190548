import React, {
  FC,
  memo,
  useState,
  MouseEvent as ReactMouseEvent,
} from "react";

import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Button from "components/Button";
import LoadingIcon from "components/LoadingIcon";
import Text from "components/Typography/Text";
import { COMMUNITY_URL, DEFAULT_ERROR_MESSAGE } from "config";
import useAppLocation from "hooks/useAppLocation";
import { logError } from "services/logger";

import * as styles from "./styles";

type CheckoutFormProps = {
  onSubmit: () => Promise<boolean>;
};

const CheckoutForm: FC<CheckoutFormProps> = ({ onSubmit }) => {
  const { appDomain } = useAppLocation();
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isFormSubmitLoading, setIsFormSubmitLoading] = useState(false);
  const [isStripeElementsLoading, setIsStripeElementsLoading] = useState(true);

  const handleSubmit = async (event: ReactMouseEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setErrorMessage("");
    setIsFormSubmitLoading(true);
    // check inventory and user balance
    if (!(await onSubmit())) {
      setIsFormSubmitLoading(false);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${appDomain}${COMMUNITY_URL.checkoutComplete}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`.
    logError({ error, message: "[Stripe][Checkout Form] Error." });

    if (error.message) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }

    setIsFormSubmitLoading(false);
  };

  return (
    <>
      {isStripeElementsLoading && <LoadingIcon />}

      <form
        onSubmit={handleSubmit}
        css={styles.container(isStripeElementsLoading)}
      >
        <PaymentElement onReady={() => setIsStripeElementsLoading(false)} />

        {!isStripeElementsLoading && (
          <Button
            type="submit"
            size="xl"
            disabled={isFormSubmitLoading || !stripe || !elements}
            loading={isFormSubmitLoading}
          >
            Pay now
          </Button>
        )}
        {errorMessage && (
          <Text size="medium" additionalCss={styles.errorMessage}>
            {errorMessage}
          </Text>
        )}
      </form>
    </>
  );
};

export default memo(CheckoutForm);
