import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { ApolloError } from "@apollo/client";
import { string as yupString } from "yup";

import { useUpdateGeneralInfoMutation } from "apollo/queries";
import TextField from "components/Inputs/TextField";
import LoadingIcon from "components/LoadingIcon";
import { logError } from "services/logger";

export interface Props {
  communityId: string;
  communityNameValue: string;
}

const isCommunityNameDuplicateError = (error: Error) => {
  return (
    error instanceof ApolloError &&
    error.graphQLErrors.find((errorItem) =>
      errorItem.message.match(/^.*E11000.*$/)
    )
  );
};

const MAX_LENGTH = 40;
const MIN_LENGTH = 3;

const CommunityNameUpdate: FC<Props> = ({
  communityId,
  communityNameValue,
}) => {
  const [doUpdateGeneralInfoMutation] = useUpdateGeneralInfoMutation();

  const [communityName, setCommunityName] =
    useState<string>(communityNameValue);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCommunityName(communityNameValue);
  }, [communityNameValue]);

  const validationSchema = yupString()
    .trim()
    .min(MIN_LENGTH)
    .max(MAX_LENGTH)
    .required("Name is required.");

  const update = async (
    nameToUpdateValue: string,
    communityId: string,
    name: string
  ) => {
    setError("");
    if (nameToUpdateValue === communityName) return;

    try {
      nameToUpdateValue = await validationSchema.validate(nameToUpdateValue);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
      if (inputRef?.current) {
        inputRef.current.value = name;
      }
      return;
    }

    try {
      setLoading(true);
      const res = await doUpdateGeneralInfoMutation({
        variables: {
          data: {
            name: nameToUpdateValue,
          },
          updateGeneralInfoId: communityId,
        },
      });
      const communityResponse = res.data?.updateGeneralInfo;
      setCommunityName(communityResponse?.name || "");
    } catch (error) {
      if (isCommunityNameDuplicateError(error as Error)) {
        setError(`Name ${nameToUpdateValue} already exists`);
      } else {
        setError("Update failed");
        await logError({
          error,
          message: "[handleCommunityNameOnBlur] update community failed",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCommunityNameOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) =>
      update(event.currentTarget.value, communityId, communityName),
    [communityId, communityName]
  );

  const handleOnEnter = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) =>
      update(event.currentTarget.value, communityId, communityName),
    [communityId, communityName]
  );

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <TextField
      ref={inputRef}
      label="Community Name"
      labelType="title"
      className=""
      id="communityName"
      placeholder="Enter community name"
      maxLength={MAX_LENGTH}
      minLength={MIN_LENGTH}
      defaultValue={communityName}
      onBlur={handleCommunityNameOnBlur}
      error={error}
      onKeyPress={(e) => e.key === "Enter" && handleOnEnter(e)}
      onChange={() => setError("")}
      onClick={() => setError("")}
    />
  );
};

export default memo(CommunityNameUpdate);
