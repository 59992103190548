import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  from,
  ApolloLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { createClient } from "graphql-ws";
import Cookies from "js-cookie";

import { initVar } from "apollo/reactive/init";
import { ModalType, updateModalVar } from "apollo/reactive/modal";
import {
  API_GRAPHQL_URL,
  API_GRAPHQL_WS_URL,
  APOLLO_CLIENT_ERRORS,
  CLIENT_VERSION,
  IN_DEVELOPMENT,
  STORAGE_KEYS,
} from "config";
import { resetSession } from "hooks/useSession";
import { InvalidAccessTokenErrorCode } from "services/auth";

export const createApolloClient = ({
  initialState,
}: {
  initialState?: NormalizedCacheObject;
}) => {
  IN_DEVELOPMENT && console.log(`🟢 Apollo Client Inited.`);

  const isSSR = typeof window === "undefined";
  const cache = new InMemoryCache();

  if (initialState) {
    cache.restore(initialState);
  }

  const authLink = setContext((graphQLRequest, { headers }) => {
    const newHeaders = {
      headers: {
        ...headers,
        "X-Client-Version": CLIENT_VERSION,
        Accept: "application/json",
      },
    };

    const accessToken = Cookies.get(STORAGE_KEYS.jwtAccessToken);
    if (accessToken) {
      newHeaders.headers["X-Access-Token"] = accessToken;
    }

    return newHeaders;
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const { extensions } = error;

        // avoid crashing frontend from fatal error on web-graphql
        if (!extensions) {
          return;
        }
        switch (extensions.code) {
          case InvalidAccessTokenErrorCode:
            if (initVar()) {
              // only show a modal if the app has loaded and then something went wrong
              updateModalVar({ showModal: ModalType.EXPIRED_AUTH_SESSION });
            }
            resetSession();
            break;
          case APOLLO_CLIENT_ERRORS.CLIENT_VERSION_DONT_MATCH:
            initVar(true);
            updateModalVar({ showModal: ModalType.OUTDATED_CLIENT_VERSION });
            break;
          default:
          // Nothing to do here...
        }
      });
    }
  });

  const httpLink = createUploadLink({
    uri: API_GRAPHQL_URL,
    credentials: "include",
  }) as unknown as ApolloLink;

  const wsLink = new GraphQLWsLink(
    createClient({
      url: API_GRAPHQL_WS_URL || "",
    })
  );

  //Source: https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const additiveLinks = from([authLink, errorLink, splitLink]);

  return new ApolloClient({
    ssrMode: isSSR,
    credentials: "same-origin",
    link: additiveLinks,
    connectToDevTools: IN_DEVELOPMENT,
    cache,
  });
};

export const apolloClient = createApolloClient({});
export type CreateApolloClient = ReturnType<typeof createApolloClient>;
