import React, { memo } from "react";

import ButtonLink from "components/ButtonLink";
import Layout from "components/Layout";
import { AppSEO } from "components/Layout/SEO";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE, COMMUNITY_URL, EXTERNAL_URL } from "config";

import * as styles from "./styles";

const ServerError = () => (
  <>
    <AppSEO title={SYSTEM_PAGE_TITLE.SERVER_ERROR} />

    <Layout css={styles.container}>
      <div css={styles.content}>
        <Title size="large" extraCss={styles.title}>
          Something Went Wrong
        </Title>
        <Text as="p">
          Try again later or visit the{" "}
          <a href={EXTERNAL_URL.SUPPORT}>Help Center</a>
        </Text>
        <ButtonLink to={COMMUNITY_URL.home}>Go Back</ButtonLink>
      </div>
    </Layout>
  </>
);

export default memo(ServerError);
