import React, { FC, useState } from "react";

import { css, Theme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { object as yupObject, string as yupString } from "yup";

import {
  CreatedCommunityBySlugDocument,
  useUpdateShopifyCollectionBuyButtonMutation,
} from "apollo/queries";
import Button from "components/Button";
import TextField from "components/Inputs/TextField";
import ReactModalBase from "components/Modals/ReactModalBase";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SUPPORT_ARTICLES } from "config";
import { logError } from "services/logger";
import { bottomMargin } from "styles/global/layout";

import { ShopifyConfiguration } from "../types";

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  communityId: string;
  shopifyConfiguration?: ShopifyConfiguration;
};

type FormFields = {
  collectionComponentDivId: string;
  domain: string;
  storefrontAccessToken: string;
  collectionId: string;
};

const EditStoreModal: FC<Props> = ({
  isOpen,
  onClose,
  communityId,
  shopifyConfiguration,
}) => {
  const [saveError, setSaveError] = useState("");

  const [updateShopifyCollectionBuyButton, { loading }] =
    useUpdateShopifyCollectionBuyButtonMutation({
      refetchQueries: [CreatedCommunityBySlugDocument],
    });

  const validationSchema = yupObject({
    collectionComponentDivId: yupString()
      .trim()
      .required("All fields are required"),
    domain: yupString().trim().required("All fields are required"),
    storefrontAccessToken: yupString()
      .trim()
      .required("All fields are required"),
    collectionId: yupString().trim().required("All fields are required"),
  }).required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<FormFields> = async (input) => {
    try {
      await updateShopifyCollectionBuyButton({
        variables: {
          communityId,
          input,
        },
      });
      onClose();
    } catch (error) {
      setSaveError("Error saving Shopify Store. Please try again later.");
      logError({
        error,
        message: "error saving Shopify/EditStoreModal",
      });
    }
  };

  const centerText = css`
    text-align: center;
  `;
  const errorText = (theme: Theme) => css`
    color: ${theme.colorMap.destructive.default};
  `;
  return (
    <ReactModalBase
      id="shopify-edit-store-modal"
      isOpen={isOpen}
      onAfterClose={clearErrors}
      onClose={onClose}
    >
      <Title extraCss={[bottomMargin("4/8px"), centerText]}>
        Add a Shopify Store
      </Title>
      <Text extraCss={[bottomMargin("9/32px"), centerText]}>
        Adding a Shopify Store to your community lets you offer exclusive or
        discounted products to members of your community.{" "}
        <a
          href={SUPPORT_ARTICLES.LINK_SHOPIFY_WITH_BUY_BUTTON}
          target="_blank"
          rel="noreferrer"
        >
          Learn more about the info you’ll need
        </a>{" "}
        to set this up. Once you’re done, you can add Shop access as a benefit
        to new or existing membership tokens.{" "}
      </Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Text bold>Div ID</Text>
        <Text>Generated in your Shopify Buy Button</Text>
        <TextField
          placeholder={"collection-component-#############"}
          defaultValue={
            shopifyConfiguration?.collectionBuyButton?.collectionComponentDivId
          }
          {...register("collectionComponentDivId")}
          error={errors.collectionComponentDivId?.message}
        />

        <Text bold>Domain</Text>
        <Text>Generated in your Shopify Buy Button</Text>
        <TextField
          placeholder={"yourshop.myshopify.com"}
          defaultValue={shopifyConfiguration?.collectionBuyButton?.domain}
          {...register("domain")}
          error={errors.domain?.message}
        />

        <Text bold>Storefront Access Token</Text>
        <Text>Generated in your Shopify Buy Button</Text>
        <TextField
          key=""
          placeholder={"32-Digit Alphanumeric Code"}
          defaultValue={
            shopifyConfiguration?.collectionBuyButton?.storefrontAccessToken
          }
          {...register("storefrontAccessToken")}
          error={errors.storefrontAccessToken?.message}
        />

        <Text bold>Shopify Collection ID</Text>
        <Text>Generated in your Shopify Buy Button</Text>
        <TextField
          placeholder={"12-Digit Number"}
          defaultValue={shopifyConfiguration?.collectionBuyButton?.collectionId}
          {...register("collectionId")}
          error={errors.collectionId?.message}
        />

        {saveError && (
          <Text
            size="medium"
            extraCss={[bottomMargin("4/8px"), centerText, errorText]}
          >
            {saveError}
          </Text>
        )}

        <Button type="submit" loading={loading}>
          Save
        </Button>
      </form>
    </ReactModalBase>
  );
};

export default EditStoreModal;
