import { FC, memo, useEffect, useState } from "react";

import { DateTime } from "luxon";

import CommunityText from "components/_Community/Text";

import * as styles from "./styles";
import { Props as CountdownTimerProps } from "./types";

type TimeDiff = {
  days: number | null;
  hours: number | null;
  minutes: number | null;
  seconds: number | null;
};

const CountdownTimer: FC<CountdownTimerProps> = ({ time }) => {
  const [diff, setDiff] = useState<TimeDiff>({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  });
  const releaseTime = DateTime.fromJSDate(time);

  const updateTimeAndGetWaitTime = () => {
    // update the displayed countdown time
    const now = DateTime.now();
    // need one extra unit than needed to avoid the last time unit
    // from having a decimal/remainder
    const diff = releaseTime.diff(now, [
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds",
    ]);
    setDiff({
      days: diff.days,
      hours: diff.hours,
      minutes: diff.minutes,
      seconds: diff.seconds,
    });
    // find next min
    const temp = now.plus({ seconds: 1 });
    const nextSecObj = temp.toObject();
    nextSecObj.second = 0;
    nextSecObj.millisecond = 0;

    // find how long to wait for a refresh after one second has passed
    const nextMin = DateTime.fromObject(nextSecObj);
    const x = nextMin.diff(now).milliseconds;
    return x;
  };

  const waitTilNextMinute = () => {
    const ms = updateTimeAndGetWaitTime();
    setTimeout(() => waitTilNextMinute(), ms);
  };

  useEffect(() => {
    const diff = updateTimeAndGetWaitTime();
    setTimeout(() => waitTilNextMinute(), diff);
  }, []);

  return (
    <div css={styles.countdownSection}>
      <div css={styles.container}>
        <CommunityText size="xlarge" extraCss={styles.countdownTimeValue}>
          {diff.days}
        </CommunityText>
        <CommunityText size="small" extraCss={styles.countdownUnits}>
          {"DAYS"}
        </CommunityText>
      </div>
      <div css={styles.container}>
        <CommunityText size="xlarge" extraCss={styles.countdownTimeValue}>
          {diff.hours}
        </CommunityText>
        <CommunityText size="small" extraCss={styles.countdownUnits}>
          {"HOURS"}
        </CommunityText>
      </div>
      <div css={styles.container}>
        <CommunityText size="xlarge" extraCss={styles.countdownTimeValue}>
          {diff.minutes}
        </CommunityText>
        <CommunityText size="small" extraCss={styles.countdownUnits}>
          {"MINUTES"}
        </CommunityText>
      </div>
      <div css={styles.container}>
        <CommunityText size="xlarge" extraCss={styles.countdownTimeValue}>
          {diff.seconds}
        </CommunityText>
        <CommunityText size="small" extraCss={styles.countdownUnits}>
          {"SECONDS"}
        </CommunityText>
      </div>
    </div>
  );
};

export default memo(CountdownTimer);
