import { template } from "lodash";

export const CLIENT_VERSION = 1;

export const APP_SUBDOMAIN = "app";

const getRequiredEnvVar = (name: string) => {
  const value = process.env[name];
  if (!value) {
    console.error(`env var ${name} is not set`);
  }
  return value as string;
};

export const envConfig = {
  polygon: {
    networkUrl: getRequiredEnvVar("REACT_APP_POLYGON_NETWORK_URL"),
    chainId: parseInt(getRequiredEnvVar("REACT_APP_POLYGON_CHAIN_ID")),
  },
};

export const APP_URL = Object.freeze({
  lobby: "/",
  account: {
    base: "/account",
    settings: "settings",
    wallet: "wallet",
    transactionHistory: "transaction-history",
    emailPreferences: "email-preferences",
  },
  editCommunity: {
    mintTokens: "/edit-community/:communitySlug/tokens/mint",
    manageMintedToken: "/edit-community/:communitySlug/tokens/minted/:tokenId",
    managePendingToken: "/edit-community/:communitySlug/tokens/pending/:taskId",
    sendTokens: "/edit-community/:communitySlug/tokens/:tokenId/send",
    base: "/edit-community/:communitySlug",
    home: "home",
    tokens: "tokens",
    posts: "posts",
    theme: "theme",
    members: "members",
    income: "income",
  },
  newCommunity: "/create-community",
  local: {
    setSlug: "/set/:communitySlug",
  },
});

export const COMMUNITY_URL = Object.freeze({
  home: "/",
  notFound: "/404",
  internalError: "/500",
  checkout: "/checkout",
  checkoutComplete: "/checkout/complete",
  callback: "/callback",
  community: "/community",
  signout: "/signout",
  signin: "/signin",
  contest: "/contest",
  claim: "/claim",
  purchase: "/purchase",
  discordLinkCallback: "/discord/callback",
  about: "/about",
  posts: "/posts",
  discord: "/discord",
  shop: "/shop",
  tokenDetail: "/token/:tokenId/detail",
  releaseParty: "/releases",
  helpSection: "#help-section",
  contactUs: "#contact-us",
});

export enum EXTERNAL_URL {
  SUPPORT = "https://support.highlight.xyz",
  SUPPORT_STRIPE_FORM = "https://support.highlight.xyz/hc/en-us/requests/new?ticket_form_id=360003573211",
  SUPPORT_EMAIL = "support@highlight.xyz",
  TERMS_AND_CONDITIONS = "https://highlight.xyz/terms-of-service/",
  PRIVACY_POLICY = "https://highlight.xyz/privacy-policy/",
  CREATOR_TERMS_AND_CONDITIONS = "https://highlight.xyz/creator-terms-of-service/",
  POLYGONSCAN = "https://polygonscan.com/address",
  RULES_CONTEST = "https://highlight.xyz/rules/contest",

  // permissions: https://discordapi.com/permissions.html#268435457
  ADD_HIGHLIGHT_BOT = "https://discord.com/api/oauth2/authorize?client_id={{CLIENT_ID}}&permissions=268435457&scope=bot",
  DISCORD_SETUP = "https://support.highlight.xyz/hc/en-us/articles/5738858071572-How-do-I-set-up-my-own-Discord-server",

  OPENSEA = "https://opensea.io/assets",
  OPENSEA_TESTNET = "https://testnets.opensea.io/assets",
  HIGHLIGHT_CLUB = "https://club.highlight.xyz",
}

export const SUPPORT_ARTICLES = {
  LINK_SHOPIFY_WITH_BUY_BUTTON:
    "https://support.highlight.xyz/hc/en-us/articles/5890674046228-Integrate-your-Shopify-store",
  CONTENT_FEED_POST_REQUIREMENTS:
    "https://support.highlight.xyz/hc/en-us/articles/5856891069588-Content-Feed-Post-Requirements",
  OPENSEA_CONFIGURATION:
    "https://support.highlight.xyz/hc/en-us/articles/6164144089236-OpenSea-configuration",
  LEARN_MORE_CONTRACTS:
    "https://support.highlight.xyz/hc/en-us/articles/6330760508820",
};

export enum FEATURE_FLAG {
  BECOME_CREATOR_CTA = `scaffoldBecomeCreatorCta`,
  ENABLE_CREATOR_DASHBOARD = `scaffoldEnableCreatorDashboard`,
  ENABLE_ACCOUNT_EMAIL_PREFERENCES_PAGE = `scaffoldEnableAccountEmailPreferencesPage`,
  ENABLE_ACCOUNT_TRANSACTION_HISTORY_PAGE = `scaffoldEnableAccountTransactionHistoryPage`,
  ENABLE_ACCOUNT_WALLET_PAGE = `scaffoldEnableAccountWalletPage`,
  MAGICLINK_PRIVATE_KEY_EXPORT = `scaffoldEnableMagiclinkPrivateKeyExport`,
  STRIPE_CONNECT_ONBOARDING = `paymentsStripeConnectOnboarding`,
  ENABLE_CRYPTO_BYO_WALLET = `cryptoByoWalletAuth`,
  CREATE_ONE_COMMUNITY_PER_USER = `creatorDashboardCreateOneCommunityPerUser`,
  DISABLE_CREATE_COMMUNITY = `creatorDashboardDisableCreateCommunity`,
  REQUIRE_PHONE_VALIDATION = `creatorDashboardRequirePhoneValidation`,
  ENABLE_CREATOR_DASHBOARD_MEMBERS_TAB = `creatorDashboardMembersTab`,
  ENABLE_CREATOR_DASHBOARD_THEME_TAB = `creatorDashboardThemeTab`,

  ENABLE_CREATOR_DASHBOARD_INCOME_TAB = `creatorDashboardIncomeTab`,
  ENABLE_INCOME_WITHDRAWAL = `incomeAllowWithdraw`,
  SHOW_INCOME_WETH_EARNINGS = `incomeShowWEthEarnings`,
  SHOW_INCOME_USD_EARNINGS = `incomeShowUsdEarnings`,
  ENABLE_CRYPTO_PAYMENTS = `enableCryptoPayments`,

  ENABLE_ADD_CUSTOM_BENEFITS = `creatorDashboardCustomBenefitTokens`,
  ENABLE_MINT_TOKEN_LIMIT = `creatorDashboardEnableMintTokenLimit`,
  ENABLE_VIDEO_ASSET_ON_TOKEN = `creatorDashboardVideoAssetOnToken`,
  ENABLE_OPTION_TO_CUSTOMIZE_DISCORD_SERVER = `creatorDashboardShowDiscordServerConfigPainel`,
  EMERGENCY_STOP_CLAIM_TOKEN = `emergencyStopClaimToken`,
  EMERGENCY_STOP_DEPLOY_CONTRACT = `emergencyStopDeployContract`,
  EMERGENCY_STOP_MINT_NEW_TOKENS = `emergencyStopMintNewTokens`,
  EMERGENCY_STOP_SEND_TOKENS_TO_ALL = `emergencyStopSendTokensToAll`,
  CONTENT_FEED_ENABLE_NEW_POST_EDITOR = `contentFeedEnableNewPostEditor`,
  EMERGENCY_ALERT = `emergencyAlertFrontendMessage`,
  RELEASE_PARTY_FEATURE_AVAILABLE = `releasePartyAccessFeature`,
  RELEASE_PARTY_CAN_UPDATE_USER_PROFILE = `releasePartyUpdateUserProfile`,
  DISCORD_SYNC_BENEFITS_BUTTON = `discordSyncBenefitsButton`,
  RESIZED_IMAGES = `enableResizedImages`,
  ENABLE_MINTING_NON_TRANSFERABLE = `enableMintingNonTransferable`,
}

export enum TYPEFORM {
  BECOME_A_CREATOR = `gMSnsjwV`,
}

export const STORAGE_KEYS = Object.freeze({
  cart: "highlight.cart",
  checkoutCompleteOrderPreview: "highlight.checkoutCompleteOrderPreview",
  deprecatedDidToken: "highlight.auth.idtoken",
  jwtAccessToken: "highlight.jwtAccessToken",
  defaultSlug: "highlight.ci.slug",
});

export const SARDINE_CLIENT_ID = process.env.REACT_APP_SARDINE_CLIENT_ID;
export const API_GRAPHQL_URL = process.env.REACT_APP_WEB_GRAPHQL_URL;
export const API_GRAPHQL_WS_URL = process.env.REACT_APP_WEB_GRAPHQL_WS_URL;
export const IN_DEVELOPMENT = process.env.REACT_APP_ENV !== "production";
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY;
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const IFRAMELY_HASHED_API_KEY =
  process.env.REACT_APP_IFRAMELY_HASHED_API_KEY;
export const GOOGLE_ANALYTICS_ID =
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "";
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const DEV_COMMUNITY_SLUG = process.env.REACT_APP_DEV_COMMUNITY_SLUG;

export enum LOGIN_ERRORS {
  GENERIC_TITLE = "An Error Occurred",
  GENERIC_DESCRIPTION = "Unfortunately, something went wrong. Please go back and try again.",
}

export enum LOGIN_WITH_MAGICLINK_ERRORS {
  GENERIC_TITLE = "An Error Occurred",
  EXPIRED_AUTH_SESSION_TITLE = "Your Session Has Expired",
  GENERIC_DESCRIPTION = "Unfortunately, something went wrong. Please go back and try again. If this error persists, contact us at support@highlight.xyz with details.",
  LINK_EXPIRED_DESCRIPTION = "The link you clicked has expired. Please go back and try again.",
  UPDATE_EMAIL_FAILED_DESCRIPTION = "We were unable to update your email address. The new email address may already be in use.",
}

export const DEFAULT_ERROR_MESSAGE =
  "A server error occurred, please try again. If this error persists, contact us at support@highlight.xyz with details.";

export const TERMS = Object.freeze({
  family: "highlight_user",
  version: 1,
});

export const CREATOR_TERMS = Object.freeze({
  family: "highlight_creator",
  version: 1,
});

export const DO_NOT_SHOW_MODAL_TERMS_IN = [
  COMMUNITY_URL.notFound,
  COMMUNITY_URL.internalError,
];

export const MEMBERSHIP_TOKEN_ALT = "Membership Token";

export enum ANALYTICS_EVENT {
  USER_SIGN_OUT = "User Sign Out",
  POLYGONSCAN_LINK_OPEN = "Polygonscan Link Open",
  TOKEN_DETAILS_VIEWED = "Token Details Viewed",
  CART_ADDED_FIATOFFER = "Fiat Offer Added To Cart",
  CHECKOUT_COMPLETED_SUCCESSFULLY = "Checkout Completed Successfully ",
  CHECKOUT_COMPLETED_FAILURE = "Checkout Completed With Failure",
  OPENSEA_LINK_OPEN = "OpenSea Link Open",
  OUTDATED_FRONTEND_VERSION = "Outdated Frontend Version",
  ACCOUNT_SIGN_OUT = "Account Sign Out",
  ACCOUNT_CONNECT_WALLET = "Connect Wallet",
}

export const LOGGER_API = process.env.REACT_APP_LOGGER_API || null;

export const MAGIC_LINK_AUTH_SESSION_LENGTH = 7; // in days
export const JWT_EXPIRATION_DAYS = 10; // in days

export const HIGHLIGHT_FEE_PERCENTAGE = 0.03;
export const STRIPE_FEE_PERCENTAGE = 0.029;
export const STRIPE_FLAT_FEE_CENTS = 30;
export const MAXIMUM_TOKEN_LIMIT = 10;

export enum MAGIC_EXPORT_PRIVATE_KEY_LINK {
  STAGING = `https://reveal.magic.link/test/highlight`,
  PRODUCTION = `https://reveal.magic.link/highlight`,
}

/* SEO Configs */
export const HIGHLIGHT_PAGE_TITLE_SIGNATURE = " | Highlight";

export enum SYSTEM_PAGE_TITLE {
  DASHBOARD = `Dashboard`,
  SERVER_ERROR = `An error occurred`,
  SIGN_IN = `Sign In`,
  CREATE_ACCOUNT = `Create Account`,
  PAGE_NOT_FOUND = `Page Not Found`,
  COMMUNITY_NOT_FOUND = `Community Not Found`,
  // Account
  ACCOUNT_SETTINGS = `Account: Settings`,
  ACCOUNT_WALLET = `Account: Wallet`,
  ACCOUNT_TRANSACTION_HISTORY = `Account: Transaction History`,
  ACCOUNT_EMAIL_PREFERENCES = `Account: Email Preferences`,
  // Edit Community
  CREATE_COMMUNITY = `Create Community`,
  EDITCOMMUNITY_HOME = `Edit Community`,
  EDITCOMMUNITY_TOKENS = `Edit Community: Tokens`,
  EDITCOMMUNITY_TOKENS_MINT = `Edit Community: Mint a Token`,
  EDITCOMMUNITY_TOKENS_SEND = `Edit Community: Send Token`,
  EDITCOMMUNITY_TOKEN_MANAGE = `Edit Community: Token Manage`,
  EDITCOMMUNITY_POSTS = `Edit Community: Posts`,
  EDITCOMMUNITY_THEME = `Edit Community: Theme`,
  EDITCOMMUNITY_MEMBERS = `Edit Community: Members`,
  EDITCOMMUNITY_INCOME = `Edit Community: Income`,
}

export enum COMMUNITY_PAGE_TITLE {
  HOME = ``,
  ABOUT = ``,
  POSTS = `Posts`,
  DISCORD = `Discord`,
  SHOP = `Shop`,
  CONTEST = `Contest`,
  TOKEN_DETAIL = `Token Detail`,
  CHECKOUT = `Checkout`,
  CHECKOUT_COMPLETE = `Checkout: Complete`,
  CHECKOUT_ORDER_COMPLETE = `Checkout: Order Complete`,
  CHECKOUT_PAYMENT_FAILED = `Checkout: Payment Failed`,
  CHECKOUT_EMPTY_CART = `Checkout: Empty Cart`,
  CHECKOUT_ERROR = `Checkout: Error`,
  RELEASE_PARTY = `Release`,
}

export const DEFAULT_COLORPICKER_COLORS = [
  "#ffffff",
  "#000000",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#9900EF",
];

export enum APOLLO_CLIENT_ERRORS {
  CLIENT_VERSION_DONT_MATCH = "ClientVersionDontMatch",
}

export enum MimeTypes {
  JPEG = "image/jpeg",
  PNG = "image/png",
  GIF = "image/gif",
  MP3 = "audio/mpeg",
  WAV = "audio/wav",
  MP4 = "video/mp4",
  WEBM = "video/webm",
}

export const CAN_USE_DOM: boolean =
  typeof window !== "undefined" &&
  typeof window.document !== "undefined" &&
  typeof window.document.createElement !== "undefined";

export const IS_APPLE: boolean =
  CAN_USE_DOM && /Mac|iPod|iPhone|iPad/.test(navigator.platform);

export const COMMUNITY_REPORT = Object.freeze({
  mail: "report@highlight.xyz",
  body: template(
    "Describe the reason you would like to report this community. For example, objectionable content, copyright infringement, etc.\n\nPlease do not modify the content below as it helps Highlight respond to this report.\nCommunity: <%= communityName %>\nDate: <%= today %>"
  ),
  subject: "Community Report",
});
