import { css, Theme } from "@emotion/react";

import { SystemButtonProps } from "components/Button";
import {
  button as baseButton,
  buttonText as baseButtonText,
} from "components/Button/styles";

export const button =
  (
    buttonColor: NonNullable<SystemButtonProps["color"]>,
    size: NonNullable<SystemButtonProps["size"]>,
    fluid: NonNullable<SystemButtonProps["fluid"]>,
    hasBorder: NonNullable<SystemButtonProps["hasBorder"]>
  ) =>
  (theme: Theme) =>
    css`
      ${baseButton(buttonColor, size, fluid, hasBorder)(theme)}
      display: block;
    `;

export const disabled = css`
  opacity: 0.25;
  cursor: not-allowed;
`;

export const buttonText = baseButtonText;
