import { ApolloError, ServerParseError, ServerError } from "@apollo/client";
import { GraphQLError } from "graphql/error";

import {
  BadUserInputErrorCode,
  DbCreateOfferErrorCode,
  InternalServerError,
  RequiredDataMissingErrorCode,
  ServerDownMessage,
} from "./knownErrors";

export enum ServerErrorType {
  NONE = "None",
  GENERIC_BACKEND_ERROR = "An Error Occurred",
  SERVICE_DOWN_ERROR = "Server Unresponsive",
  BAD_INPUT_ERROR = "Bad User Input",
}

export enum ErrorType {
  NONE = "None",
  GRAPHQL_ERROR = "GraphQL Errors",
  NETWORK_ERROR = "Network Error",
  CLIENT_ERROR = "Client Errors",
}

const DEFAULT_MESSAGE = "A server error occured, please try again.";
const BAD_INPUT_MESSAGE =
  "Check that all input fields are filled out. Refresh the page, and try again if everything looks good.";

export type ApolloServerError = {
  type: ServerErrorType;
  message: string;
  errorType: ErrorType;
  gqlErrors?: GraphQLError[];
  networkErrors?: Error | ServerParseError | ServerError | null;
  clientErrors?: Error[];
};

export const EmptyApolloServerError: ApolloServerError = {
  type: ServerErrorType.NONE,
  message: "",
  errorType: ErrorType.NONE,
};

export const DefaultApolloServerError: ApolloServerError = {
  type: ServerErrorType.GENERIC_BACKEND_ERROR,
  message: DEFAULT_MESSAGE,
  errorType: ErrorType.NONE,
};

const toGraphQLError = (
  type: ServerErrorType,
  message: string,
  errors: readonly GraphQLError[]
) => {
  const error = EmptyApolloServerError;
  error.type = type;
  error.message = message;
  error.errorType = ErrorType.GRAPHQL_ERROR;
  error.gqlErrors = [...errors];
  return error;
};

const toNetworkError = (
  type: ServerErrorType,
  message: string,
  networkError: Error | ServerParseError | ServerError
) => {
  const error = EmptyApolloServerError;
  error.type = type;
  error.message = message;
  error.errorType = ErrorType.NETWORK_ERROR;
  error.networkErrors = networkError;
  return error;
};

/*
const toClientError = (
  type: ServerErrorType,
  message: string,
  errors: readonly Error[]
) => {
  const error = EmptyApolloServerError;
  error.type = type;
  error.message = message;
  error.errorType = ErrorType.CLIENT_ERROR;
  error.clientErrors = [...errors];
  return error;
};
*/

export const genericError = (e: ApolloError): ApolloServerError => {
  let error = DefaultApolloServerError;
  if (hasGraphqlErrors(e)) {
    error = parseGraphqlErrors(e.graphQLErrors, e.message);
  } else if (hasNetworkErrors(e)) {
    error = parseNetworkErrors(e.networkError, e.message);
  } else if (hasClientErrors(e)) {
    error = parseClientErrors(e.clientErrors, e.message);
  }

  return error;
};

export const hasGraphqlErrors = (error: ApolloError): boolean => {
  return error.graphQLErrors && error.graphQLErrors.length > 0;
};

export const hasNetworkErrors = (error: ApolloError): boolean => {
  return error.networkError !== null;
};

export const hasClientErrors = (error: ApolloError): boolean => {
  return error.clientErrors && error.clientErrors.length > 0;
};

export const parseApolloClientError = (e: ApolloError): ApolloServerError => {
  let error = DefaultApolloServerError;
  if (hasGraphqlErrors(e)) {
    error = parseGraphqlErrors(e.graphQLErrors, e.message);
  } else if (hasNetworkErrors(e)) {
    error = parseNetworkErrors(e.networkError, e.message);
  } else if (hasClientErrors(e)) {
    error = parseClientErrors(e.clientErrors, e.message);
  }

  return error;
};

// TODO: better parsing
const parseGraphqlErrors = (
  graphQLErrors: readonly GraphQLError[],
  message: string | undefined
): ApolloServerError => {
  if (!graphQLErrors || !message) {
    return DefaultApolloServerError;
  }

  const badInputUserErrors = graphQLErrors.find(
    ({ extensions }) => extensions.code === RequiredDataMissingErrorCode
  );
  if (badInputUserErrors) {
    return toGraphQLError(
      ServerErrorType.BAD_INPUT_ERROR,
      BAD_INPUT_MESSAGE,
      graphQLErrors
    );
  }

  const createOfferFailed = graphQLErrors.find(
    ({ extensions }) => extensions.code === DbCreateOfferErrorCode
  );
  if (createOfferFailed) {
    return toGraphQLError(
      ServerErrorType.GENERIC_BACKEND_ERROR,
      createOfferFailed.message,
      graphQLErrors
    );
  }

  const internalServerError = graphQLErrors.find(
    ({ extensions }) => extensions.code === InternalServerError
  );
  if (internalServerError) {
    return toGraphQLError(
      ServerErrorType.GENERIC_BACKEND_ERROR,
      internalServerError.message,
      graphQLErrors
    );
  }
  return DefaultApolloServerError;
};

type NetworkResultError = {
  extensions: {
    code: string;
  };
  message: string;
};

const parseNetworkErrors = (
  networkError: Error | ServerParseError | ServerError | null,
  message: string | undefined
): ApolloServerError => {
  if (networkError == null) {
    return DefaultApolloServerError;
  }
  /**
   * error:
   *   message: "Failed to fetch"
   *   networkError:
   *     message: "Failed to fetch"
   *     stack: "TypeError: Failed to fetch\n  ...."
   */
  if (message === ServerDownMessage) {
    return toNetworkError(
      ServerErrorType.SERVICE_DOWN_ERROR,
      message,
      networkError
    );
  }

  if (networkError?.name == "ServerError") {
    const error = networkError as ServerError;
    const networkErrors = error.result.errors as NetworkResultError[];
    const firstBadInputError = networkErrors.find(
      ({ extensions }) => extensions.code === BadUserInputErrorCode
    );
    if (firstBadInputError) {
      return toNetworkError(
        ServerErrorType.BAD_INPUT_ERROR,
        BAD_INPUT_MESSAGE,
        networkError
      );
    }
  }
  return DefaultApolloServerError;
};

const parseClientErrors = (
  clientErrors: readonly Error[],
  message: string | undefined
): ApolloServerError => {
  if (!clientErrors || !message) {
    return DefaultApolloServerError;
  }
  return DefaultApolloServerError;
};
