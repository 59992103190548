import React, { memo, useState, FC, Dispatch, SetStateAction } from "react";

import AuthErrorView from "components/AuthErrorView";
import Button from "components/Button";
import EmailInput, { EMAIL_ERROR_MESSAGE } from "components/Inputs/EmailInput";
import LoadingIcon from "components/LoadingIcon";
import MetaMaskSignIn from "components/MetaMaskSignIn";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import AuthOrDivider from "containers/SignIn/AuthOrDivider";
import SwitchBetweenLoginAndCreateAccount from "containers/SignIn/SwitchBetweenLoginAndCreateAccount";
import { useAuthForm } from "hooks/useAuthForm";
import useSession from "hooks/useSession";

import useLogic from "./logic";
import * as styles from "./styles";

export type Props = {
  isModal?: boolean;
  viewSignIn: boolean;
  setViewSignIn: Dispatch<SetStateAction<boolean>>;
};

const SignInAccount: FC<Props> = ({
  isModal,
  viewSignIn = true,
  setViewSignIn,
}) => {
  const [usedEmail, setUsedEmail] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [knownError, setKnownError] = useState<boolean>(false);
  const {
    state: { isLoading, useMetaMask, authError },
    dispatch,
  } = useAuthForm();
  const { magicClear } = useSession();
  const { onSubmit } = useLogic();

  const onSubmitClick = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!validEmail) {
      return;
    }
    if (!usedEmail) {
      setKnownError(true);
      setValidEmail(false);
      return;
    }
    // cannot refresh a reactive var within a hook - useLogic.onSubmit()
    dispatch({ type: "setUseMetaMask", payload: false });
    await onSubmit(usedEmail);
  };

  // takes argument to avoid race condition between component state and calling function
  const onValidatedSubmitEnter = async (email: string) => {
    dispatch({ type: "setUseMetaMask", payload: false });
    await onSubmit(email);
  };

  const goCreateAccountFirst = () => {
    dispatch({ type: "clearError" });
    dispatch({ type: "isLoading", payload: false });
    setViewSignIn(false);
    // if user starts sign-in from magic, but then switches to create account
    // useMagicLink() in useSession will lock
    magicClear();
  };

  return (
    <>
      {isLoading && useMetaMask && (
        <>
          <Title size="small" additionalCss={styles.title}>
            Authenticating through MetaMask
          </Title>

          <LoadingIcon css={styles.loading} />
        </>
      )}
      {isLoading && !useMetaMask && (
        <>
          <Title size="medium" additionalCss={styles.checkEmailTitle}>
            Check Your Email
          </Title>
          <Text as="p" additionalCss={styles.description}>
            We emailed a link to <strong>{usedEmail}</strong>.
            <br />
            Click the link to sign in.
          </Text>

          <LoadingIcon overwriteTheme="system" css={styles.loading} />
        </>
      )}
      {!isLoading && (
        <>
          <Title size="small" additionalCss={styles.title}>
            Sign In
          </Title>

          <form id="sign-in-modal-form">
            <div css={styles.emailInputContainer}>
              <EmailInput
                extraCss={styles.emailInput}
                max={70}
                value={usedEmail}
                setValue={setUsedEmail}
                setValid={setValidEmail}
                placeholder="Email Address"
                useOnChange={!validEmail}
                onValidatedSubmit={onValidatedSubmitEnter}
                marginBottom={false}
                knownError={!usedEmail && knownError ? EMAIL_ERROR_MESSAGE : ""}
              />
              {authError ? (
                <AuthErrorView
                  onNoSuchUserResolve={goCreateAccountFirst}
                  additionalCss={styles.errorContainer}
                />
              ) : null}
            </div>
            <Button
              size="xl"
              type="submit"
              onClick={onSubmitClick}
              additionalCss={styles.button}
            >
              Sign In
            </Button>
          </form>
          <AuthOrDivider />
          <MetaMaskSignIn />
          <SwitchBetweenLoginAndCreateAccount
            viewSignIn={viewSignIn}
            onSwitch={
              isModal
                ? () => goCreateAccountFirst()
                : () => {
                    if (setViewSignIn) setViewSignIn(false);
                  }
            }
          />
        </>
      )}
    </>
  );
};

export default memo(SignInAccount);
