import React, { FC } from "react";

import { useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";

import { useOfferQuery } from "apollo/queries/offers.graphql.generated";
import { communityVar } from "apollo/reactive";
import FullscreenLoader from "components/FullscreenLoader";
import TokenDetail from "containers/CommunityV3/TokenDetail/TokenDetail";
import NotFound from "containers/Error/NotFound";

const PurchaseToken: FC = () => {
  const { id } = useReactiveVar(communityVar);
  const { offerId } = useParams();
  const { data, loading } = useOfferQuery({
    variables: {
      offerId: offerId ?? "",
      onPrivatePage: true,
    },
  });

  if (loading) {
    return <FullscreenLoader />;
  }

  if (
    !offerId ||
    (!loading && (!data?.offer.communityId || data?.offer.communityId != id))
  ) {
    return <NotFound />;
  }

  return (
    <TokenDetail offer={data?.offer} knownTokenId={data?.offer?.tokenIds[0]} />
  );
};

export default PurchaseToken;
