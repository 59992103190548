import { css } from "@emotion/react";

import { CommunityAvatarSmallSize } from "./index";

export const getImageWidth = (size: CommunityAvatarSmallSize) => {
  const width = configuration[size]?.size.replace("px", "") ?? "56";
  return parseInt(width);
};

const configuration = {
  small: {
    size: "16px",
    svgSize: "11px",
    lineHeight: 1,
  },
  medium: {
    size: "24px",
    svgSize: "13px",
    lineHeight: undefined,
  },
  xlarge: {
    size: "56px",
    svgSize: "13px",
    lineHeight: undefined,
  },
};

export const container = css`
  display: flex;
  align-items: center;
`;

export const image = (size: CommunityAvatarSmallSize) => css`
  width: ${configuration[size].size};
  min-width: ${configuration[size].size};
  height: ${configuration[size].size};
  min-height: ${configuration[size].size};
  margin-right: 6px;
  border-radius: 50%;
  object-fit: cover;
`;

export const name = (size: CommunityAvatarSmallSize) => css`
  display: flex;
  align-items: center;
  line-height: ${configuration[size].lineHeight};
  letter-spacing: 0;
`;

export const icon = (size: CommunityAvatarSmallSize) => css`
  display: inline-block;
  margin-left: 4px;
  max-width: ${configuration[size]?.svgSize};

  path {
    fill: currentColor;
  }
`;

/*
 * Storybook
 */
export const storybookDecorators = css`
  display: flex;
  justify-content: center;
  height: 100%;
`;
