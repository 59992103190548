export const STORAGE_KEYS = Object.freeze({
    auth: "highlight.auth",
    analyticsDevice: "highlight.analytics.device",
    quickStartPreference: "highlight.quickstart.preference",
    highlightTipsActivity: "highlight.tips.activity",
    clientVersionLastFetch: "highlight.client.version",
});
export const JWT_EXPIRATION_DAYS = 10 * 365 * 10; // 10 years (no expiration)
export const IN_DEVELOPMENT = process.env.REACT_APP_ENV !== "production";
export var ApolloClientErrors;
(function (ApolloClientErrors) {
    ApolloClientErrors["CLIENT_VERSION_DONT_MATCH"] = "ClientVersionDontMatch";
})(ApolloClientErrors || (ApolloClientErrors = {}));
const getRequiredEnvVar = (name) => {
    const value = process.env[name];
    if (!value) {
        console.error(`env var ${name} is not set`);
    }
    return value;
};
export const envConfig = {
    graphql: {
        httpUrl: getRequiredEnvVar("REACT_APP_WEB_GRAPHQL_URL"),
    },
    wagmiProviders: {
        alchemy_api_key: getRequiredEnvVar("REACT_APP_ALCHEMY_API_KEY"),
        infura_api_key: getRequiredEnvVar("REACT_APP_INFURA_API_KEY"),
    },
    isDevelopment: process.env.REACT_APP_ENV !== "production",
};
export const LOGGER_API = process.env.REACT_APP_LOGGER_API || null;
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || null;
/* SEO Configs */
export const HIGHLIGHT_PAGE_TITLE_SIGNATURE = " | Highlight";
export var SystemPageTitle;
(function (SystemPageTitle) {
    SystemPageTitle["PAGE_NOT_FOUND"] = "Page Not Found";
    SystemPageTitle["SERVER_ERROR"] = "An error occurred";
})(SystemPageTitle || (SystemPageTitle = {}));
export var SystemPageDescription;
(function (SystemPageDescription) {
    SystemPageDescription["PAGE_NOT_FOUND"] = "Sorry, we couldn't find that page.";
    SystemPageDescription["SERVER_ERROR"] = "An error occurred";
})(SystemPageDescription || (SystemPageDescription = {}));
export const EXTERNAL_URL = Object.freeze({
    SUPPORT: "https://support.highlight.xyz",
    OPENSEA_BASE: "https://opensea.io",
    OPENSEA: "https://opensea.io/assets",
    OPENSEA_TESTNET: "https://testnets.opensea.io/assets",
    POLYGONSCAN: "https://polygonscan.com",
    MUMBAISCAN: "https://mumbai.polygonscan.com/",
    ETHERSCAN: "https://etherscan.io",
    GOERLISCAN: "https://goerli.etherscan.io/",
    HL_TWITTER: "https://twitter.com/Highlight_xyz",
    HL_EMAIL: "gm@highlight.xyz",
});
export const DOMAINS = Object.freeze({
    GATE: { name: "gate", devPort: "3001" },
    MINT: { name: "mint", devPort: "3003" },
    TOOLS: { name: "tools", devPort: "3002" },
});
