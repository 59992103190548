import { css, Theme } from "@emotion/react";

export const tokenDetailsList = (theme: Theme) => css`
  color: inherit;

  ul {
    list-style: none;
    margin: ${theme.verticalSpaces.large[3]} 0 0 0;
    padding: 0;

    li {
      display: flex;
      padding: 20px 0;

      box-shadow: 0px -1px 0px 0px #0000001a inset;

      span {
        &:nth-of-type(1) {
          flex-grow: 1;
        }
      }
    }
  }
`;

export const arrow = (theme: Theme) => css`
  margin: 0 0 0 ${theme.verticalSpaces.large[4]};
`;

export const arrowIcon = (isDisabled: boolean) => (theme: Theme) =>
  css`
    path {
      fill: ${isDisabled
        ? theme.colorMap.accent.secondary
        : theme.colorMap.accent.default};
    }
  `;

export const link = (isDisabled: boolean) => (theme: Theme) =>
  css`
    color: ${isDisabled
      ? theme.colorMap.accent.secondary
      : theme.colorMap.accent.default};
  `;
