import React, { memo } from "react";

import { Link } from "react-router-dom";

import { trySignIn } from "apollo/reactive";
import Layout from "components/Layout";
import { AppSEO } from "components/Layout/SEO";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE, COMMUNITY_URL } from "config";
import useSession from "hooks/useSession";

import * as styles from "./styles";

const NotFound = () => {
  const { user } = useSession();

  const onLinkClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    trySignIn(true);
  };

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.COMMUNITY_NOT_FOUND} />

      <Layout css={styles.container} showHeader={false}>
        <div css={styles.content}>
          <Title size="large" extraCss={styles.title}>
            We Couldn’t Find the Community You’re Looking For
          </Title>
          {user ? (
            ""
          ) : (
            <Text as="p">
              If you expect to see something here, try signing in{" "}
              <Link to={COMMUNITY_URL.signin} onClick={onLinkClick}>
                here
              </Link>
              .
            </Text>
          )}
          <Text as="p">
            If you need help, try checking the{" "}
            <Link to={COMMUNITY_URL.helpSection}>help section</Link> or{" "}
            <Link to={COMMUNITY_URL.helpSection}>contact us</Link>.
          </Text>
        </div>
      </Layout>
    </>
  );
};

export default memo(NotFound);
