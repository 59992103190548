import React, { FC, memo, useCallback, useEffect, useState } from "react";

import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left-default.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-default.svg";
import Button from "components/Button";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import { formatNumber } from "utils/formatting/number";

import * as styles from "./styles";

type Props = {
  resultsPerPage: number;
  selectedPage: number;
  totalResults: number;
  onPageChange: (page: number) => void;
  ellipsis?: string;
  boundary?: number;
  slots?: number;
};

const Pagination: FC<Props> = ({
  resultsPerPage,
  selectedPage,
  totalResults,
  onPageChange,
  ellipsis = "...",
  boundary = 3,
  slots = 7,
}) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [showingResultsFrom, setShowingResultsFrom] = useState<number>(0);
  const [showingResultsTo, setShowingResultsTo] = useState<number>(0);

  useEffect(() => {
    let showingResultsFrom = resultsPerPage * (selectedPage - 1);
    let showingResultsTo = showingResultsFrom + resultsPerPage;
    if (showingResultsFrom === 0) showingResultsFrom = 1;
    if (totalResults === 0) showingResultsFrom = 0;
    if (showingResultsTo > totalResults) showingResultsTo = totalResults;
    setShowingResultsFrom(showingResultsFrom);
    setShowingResultsTo(showingResultsTo);
    setTotalPages(Math.ceil(totalResults / resultsPerPage));
  }, [resultsPerPage, selectedPage, totalResults]);

  const handleOnClickPage = (page: string) => () => {
    if (page === ellipsis) return false;
    onPageChange(+page);
  };
  const handleOnClickNextPage = useCallback(() => {
    onPageChange(selectedPage + 1);
  }, [selectedPage]);
  const handleOnClickBackPage = useCallback(() => {
    onPageChange(selectedPage - 1);
  }, [selectedPage]);

  return (
    <CardContainer
      padding="medium"
      background="primary"
      extraCss={styles.pagination}
      border
    >
      <Text
        as="span"
        size="medium"
        color="secondary"
        extraCss={styles.paginationText}
      >
        Showing {formatNumber(showingResultsFrom)}-
        {formatNumber(showingResultsTo)} of {formatNumber(totalResults)}
      </Text>
      <div css={styles.pages}>
        <Button
          disabled={selectedPage === 1}
          size="small"
          extraCss={styles.iconPage}
          onClick={handleOnClickBackPage}
        >
          <ArrowLeft />
        </Button>
        {getPages(totalPages, selectedPage, ellipsis, boundary, slots).map(
          (page, i) => {
            return (
              <Button
                size="small"
                key={i}
                extraCss={styles.page(page === selectedPage.toString())}
                onClick={handleOnClickPage(page)}
              >
                <Text extraCss={styles.pageText}>
                  {page === ellipsis ? page : formatNumber(+page)}
                </Text>
              </Button>
            );
          }
        )}
        <Button
          size="small"
          extraCss={styles.iconPage}
          onClick={handleOnClickNextPage}
          disabled={selectedPage === totalPages}
        >
          <ArrowRight />
        </Button>
      </div>
    </CardContainer>
  );
};

const getNumbers = (from: number, to: number) => {
  return Array.from({ length: to - from + 1 }, (page, i) => `${from + i}`);
};

const getPages = (
  totalPages: number,
  selectedPage: number,
  ellipsis: string,
  boundary = 3,
  slots = 7
): string[] => {
  if (totalPages <= slots) return getNumbers(1, totalPages);

  if (selectedPage === 1 || selectedPage === totalPages)
    return [
      ...getNumbers(1, boundary),
      ellipsis,
      ...getNumbers(totalPages - boundary + 1, totalPages),
    ];
  if (selectedPage > 1 && selectedPage <= boundary + 1)
    return [
      ...getNumbers(1, selectedPage + 1),
      ellipsis,
      ...getNumbers(
        totalPages - (slots - (selectedPage + boundary)),
        totalPages
      ),
    ];
  if (selectedPage >= totalPages - boundary && selectedPage < totalPages)
    return [
      ...getNumbers(1, slots - (totalPages - selectedPage + boundary)),
      ellipsis,
      ...getNumbers(selectedPage - 1, totalPages),
    ];

  return [
    "1",
    ellipsis,
    `${selectedPage - 1} `,
    `${selectedPage}`,
    `${selectedPage + 1}`,
    ellipsis,
    `${totalPages}`,
  ];
};

export default memo(Pagination);
