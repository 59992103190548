import { FC, memo } from "react";

import CommunityText from "../../_Community/Text";

import * as styles from "./styles";

type IncludedTokenProps = {
  title: string;
  imgUrl: string;
  description: string;
};

const IncludedToken: FC<IncludedTokenProps> = ({
  title,
  imgUrl,
  description,
}) => {
  return (
    <div css={styles.container}>
      <img css={styles.image} src={imgUrl} alt={undefined} />
      <div css={styles.descriptionContainer}>
        <CommunityText color="onPrimaryBackgroundPrimaryColor" bold>
          {title}
        </CommunityText>
        <CommunityText
          color="onPrimaryBackgroundPrimaryColor"
          size="small"
          extraCss={styles.description}
        >
          {description}
        </CommunityText>
      </div>
    </div>
  );
};

export default memo(IncludedToken);
