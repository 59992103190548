import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  FC,
  memo,
  useState,
  useEffect,
} from "react";

import { string as yupString } from "yup";

import TextField from "components/Inputs/TextField";

import * as styles from "./styles";
import { Props } from "./types";

export const EMAIL_ERROR_MESSAGE = "Enter a valid email address.";

const EmailInput: FC<Props> = ({
  placeholder,
  value,
  setValue,
  setValid,
  onValidatedSubmit,
  useOnChange = false,
  marginBottom = true,
  knownError = "",
  extraCss,
}) => {
  const [error, setError] = useState<string>("");
  const emailSchema = yupString()
    .required(EMAIL_ERROR_MESSAGE)
    .email(EMAIL_ERROR_MESSAGE);

  const validateEmailInput = async (email: string) => {
    try {
      await emailSchema.validate(email);
      setValid(true);
      setError("");
      return true;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      setValid(false);
      setError(error?.message ?? EMAIL_ERROR_MESSAGE);
      return false;
    }
  };

  // onBlur - when user navigates away from the input field
  const handleOnBlur = async (
    event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const sanitized = event.target.value.trim().toLowerCase();
    event.target.value = sanitized;
    setValue(sanitized);
    validateEmailInput(sanitized);
  };

  const handleEmailChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const sanitized = event.target.value.trim().toLowerCase();
    event.target.value = sanitized;
    setValue(sanitized);
    validateEmailInput(sanitized);
  };

  const handleEmailKeyPress = async (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const sanitized = event.currentTarget.value.trim().toLowerCase();
      event.currentTarget.value = sanitized;
      setValue(sanitized);
      if (await validateEmailInput(sanitized)) {
        onValidatedSubmit(sanitized);
      }
    }
  };

  useEffect(() => {
    setError(knownError);
  }, [knownError]);

  return (
    <div css={[styles.container(marginBottom)]}>
      <TextField
        className=""
        placeholder={placeholder}
        defaultValue={value as string}
        onBlur={handleOnBlur}
        error={error}
        onChange={useOnChange ? handleEmailChange : undefined}
        onKeyPress={handleEmailKeyPress}
        extraCss={extraCss}
      />
    </div>
  );
};

export default memo(EmailInput);
