import { Interpolation, Theme } from "@emotion/react";

import CommunityButtonLink from "components/_Community/ButtonLink";
import CommunityCardContainer from "components/_Community/CardContainer";
import CommunityText from "components/_Community/Text";
import CommunityTitle from "components/_Community/Title";
import { COMMUNITY_URL } from "config";

import * as styles from "./styles";

export type Props = {
  communityName?: string;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
};

const AlreadyMemberCard = ({ communityName, extraCss }: Props) => {
  return (
    <CommunityCardContainer shadow="lg" extraCss={[styles.container, extraCss]}>
      <div css={styles.title}>
        <CommunityTitle size="small" color="onPrimaryBackgroundPrimaryColor">
          You’re Already a Member of This Community
        </CommunityTitle>
      </div>

      <div css={styles.description}>
        <CommunityText color="onPrimaryBackgroundPrimaryColor">
          Since you&apos;re already a member of {communityName}, you can&apos;t
          enter this contest.
        </CommunityText>
      </div>
      <CommunityButtonLink
        to={COMMUNITY_URL.home}
        color="onPrimaryBackgroundBackgroundColor"
      >
        Visit {communityName}
      </CommunityButtonLink>
    </CommunityCardContainer>
  );
};

export default AlreadyMemberCard;
