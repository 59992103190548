import { memo, PropsWithChildren } from "react";

import { Link } from "react-router-dom";

import { CreatedCommunitiesFieldsFragment } from "apollo/queries";
import { ReactComponent as ArrowRightDefault } from "assets/icons/arrow-right-default.svg";
import DefaultCommunityImage from "assets/images/default-community-avatar.png";
import Text from "components/Typography/Text";
import { APP_URL } from "config";
import useAppLocation from "hooks/useAppLocation";

import { AvatarImage } from "../UserCommunityCardLink";

import * as styles from "./styles";

export type CommunityCardProps = {
  community: CreatedCommunitiesFieldsFragment["createdCommunities"][number];
  size?: "small" | "regular";
};

type SmartLinkProps = {
  to: string;
  size?: "small" | "regular";
};

const SmartLink = ({ children, to, size }: PropsWithChildren<SmartLinkProps>) =>
  /^https?:\/\//.test(to) ? (
    <a css={styles.container(size)} href={to}>
      {children}
    </a>
  ) : (
    <Link to={to} css={styles.container(size)}>
      {children}
    </Link>
  );

const ManageCommunityCardLink = ({
  community,
  size = "regular",
}: CommunityCardProps) => {
  const { getEditCommunityURL } = useAppLocation();

  return (
    <SmartLink
      to={getEditCommunityURL(
        community?.slug,
        `${APP_URL.editCommunity.base}/${APP_URL.editCommunity.home}`
      )}
      size={size}
    >
      <div css={styles.media(size)}>
        <AvatarImage
          src={community?.creatorAvatar?.imageUrl || DefaultCommunityImage}
          size={size}
        />
      </div>

      <div css={styles.content(size)}>
        <Text as="p" size={size === "regular" ? "medium" : "small"}>
          Manage Your Community
        </Text>
        <Text as="p" size={size === "regular" ? "large" : "medium"} bold={true}>
          {community.name}
        </Text>
        <span css={styles.icon(size)}>
          <ArrowRightDefault />
        </span>
      </div>
    </SmartLink>
  );
};

export default memo(ManageCommunityCardLink);
