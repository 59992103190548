import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const titleRow = css`
  width: 100%;
  display: flex;
`;

export const launchCommunityButton = (theme: Theme) => css`
  margin-left: auto;
  justify-content: center;
  align-items: center;
  padding: 0px ${theme.verticalSpaces.large[6]};
  width: 191px;
  background: ${theme.buttons.tertiary.backgroundColor};
  border-radius: ${theme.buttons.tertiary.border.radius};

  ${theme.mediaQueries.smallOnly} {
    padding: 0 ${theme.verticalSpaces.large[2]};
  }
`;

export const launchCommunityButtonLarge = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[7]};
  padding: ${theme.verticalSpaces.large[6]} 0px;
  background: ${theme.buttons.tertiary.backgroundColor};
  border-radius: ${theme.buttons.tertiary.border.radius};
`;

export const subTitles = css`
  ${topMargin("13/64px")}
`;

export const errorMessage = (theme: Theme) => css`
  text-align: center;
  color: ${theme.colorMap.destructive.default};
  margin-top: ${theme.verticalSpaces.large[4]};
`;

export const loadingIcon = (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.verticalSpaces.large[9]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[9]};
  }
`;
