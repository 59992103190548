import { css, Theme } from "@emotion/react";

export const button = () => css`
  padding: 0;
  background-color: transparent;
`;

export const container = () => css`
  padding: 16px 16px;
  border-radius: 99px;
  max-width: 576px;
  height: 100%;
  width: 100%;
  background-color: transparent;
  shadow: 200;
  display: flex;
  align-items: center;
`;

export const rightAuthContainer = css`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  color: black;
`;

export const leftAuthContainer = css`
  width: 32px;
  height: 32px;
`;

export const middleAuthContainer = (theme: Theme) => css`
  color: ${theme.text.colors.onBackground["primary"]};
  padding: 0 0 0 16px;
  font-weight: bold;
  align-items: center;
  text-align: left;
  display: flex;
  flex: 9;

  ${theme.mediaQueries.smallOnly} {
    text-align: center;
  }
`;

export const rightAuthIcon = css`
  flex: 1;
`;

export const image = css`
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  width: 32px;
  height: 32px;
`;
