import { Theme } from "@emotion/react";
import chroma from "chroma-js";
import _merge from "lodash/merge";

import { WebsiteThemeFragment } from "../../apollo/queries";
import { fontFamilyMap, fontFamilyWeightMap } from "../global/fonts";

import {
  CommunityTheme,
  CommunityThemeBorderType,
} from "./communityTheme.types";
import { borderTypes, defaultTheme } from "./theme";

export const borderTypeOptions = [
  { value: "Straight", label: "Straight" },
  { value: "Rounded", label: "Rounded" },
  { value: "Pill", label: "Pill" },
];

export const cardBorderTypeOptions = [
  { value: "Straight", label: "Straight" },
  { value: "Rounded", label: "Rounded" },
];

export const textTransformOptions = [
  { value: "none", label: "Default" },
  { value: "uppercase", label: "Uppercase" },
  { value: "lowercase", label: "Lowercase" },
];

export const fontWeightSelectOptions = [
  { value: "100", label: "100" },
  { value: "200", label: "200" },
  { value: "300", label: "300" },
  { value: "400", label: "400" },
  { value: "500", label: "500" },
  { value: "600", label: "600" },
  { value: "700", label: "700" },
];

export const borderWithOptions = [
  { value: `0`, label: "No Border" },
  { value: `1px`, label: "1px" },
  { value: `2px`, label: "2px" },
  { value: `3px`, label: "3px" },
  { value: `4px`, label: "4px" },
];

const isColorLight = (color: chroma.Color) => color.get("lab.l") >= 70;
const isColorDark = (color: chroma.Color) => color.get("lab.l") < 70;

export const parseBorderType = (
  borderType?: CommunityThemeBorderType,
  onTextarea = false
) => {
  switch (borderType) {
    case "Pill":
      return !onTextarea ? borderTypes.pill : `35px`;
    case "Rounded":
      return borderTypes.rounded;
    case "Straight":
      return borderTypes.straight;
  }
};

/**
 * This is a temporary hack to avoid issues during deployment, once the
 * DB migration is executed we can remove it.
 */
const fallbackForOldFonts: Record<string, string> = {
  "'Inter', 'Helvetica Neue', sans-serif": "Inter",
  "'Work Sans', sans-serif": "Work+Sans",
  "'Rakkas', serif": "Rakkas",
  "'Canela'": "Canela",
};

export const updateCommunityOnDefaultTheme = (
  openCommunityTheme: WebsiteThemeFragment
) => {
  if (!openCommunityTheme) {
    return defaultTheme;
  }

  const newTheme: Theme = {
    ...defaultTheme,
    community: _merge(
      defaultTheme.community,
      openCommunityTheme as unknown as Partial<CommunityTheme>
    ),
  };

  /**
   * Update colors
   */
  // Backgrounds > secondaryBackground
  const primaryBackgroundColor = chroma(
    newTheme.community.primaryBackground.backgroundColor
  );

  newTheme.community.secondaryBackground.backgroundColor = isColorLight(
    primaryBackgroundColor
  )
    ? primaryBackgroundColor.darken(0.6).hex()
    : primaryBackgroundColor.brighten(0.6).hex();

  // Text > onPrimaryBackgroundSecondaryColor
  newTheme.community.text.onPrimaryBackgroundSecondaryColor = chroma(
    newTheme.community.text.onPrimaryBackgroundPrimaryColor
  )
    .alpha(0.6)
    .hex("rgb");

  // TextInput > placeholderTextColor
  newTheme.community.textInput.placeholderTextColor = chroma(
    newTheme.community.textInput.primaryTextColor
  )
    .alpha(0.6)
    .hex("rgb");

  // global > onPageBackgroundTint
  newTheme.community.global.onPageBackgroundTint = isColorDark(
    chroma(newTheme.community.pageBackground.backgroundColor)
  )
    ? "rgba(255, 255, 255, 0.15)"
    : "rgba(0, 0, 0, 0.15)";

  // global > onPrimaryBackgroundTint
  newTheme.community.global.onPrimaryBackgroundTint = isColorDark(
    chroma(newTheme.community.primaryBackground.backgroundColor)
  )
    ? "rgba(255, 255, 255, 0.15)"
    : "rgba(0, 0, 0, 0.15)";

  /**
   * Update fonts
   */

  const convertFontFamily = (font: string) => {
    if (fallbackForOldFonts[font]) {
      return font;
    } else {
      return fontFamilyMap[font] ?? `'Inter', sans-serif`;
    }
  };

  newTheme.community.button.textFontFamily = convertFontFamily(
    newTheme.community.button.textFontFamily
  );
  newTheme.community.text.fontFamily = convertFontFamily(
    newTheme.community.text.fontFamily
  );
  newTheme.community.title.fontFamily = convertFontFamily(
    newTheme.community.title.fontFamily
  );

  return newTheme;
};

export const getFontFamilyWeightList = (font: string) => {
  const validatedFont =
    fontFamilyWeightMap[font] ?? fontFamilyWeightMap["Inter"];
  return validatedFont.map((weight) => ({ value: weight, label: weight }));
};

/**
 * This is a temporary hack to avoid issues during deployment, once the
 * DB migration is executed we can remove it.
 */
export const updateOldFontData = (defaultTheme: WebsiteThemeFragment) => {
  const newTheme: WebsiteThemeFragment = {
    ...defaultTheme,
    button: {
      ...defaultTheme.button,
      textFontFamily:
        fallbackForOldFonts[defaultTheme.button.textFontFamily] ??
        defaultTheme.button.textFontFamily,
    },
    text: {
      ...defaultTheme.text,
      fontFamily:
        fallbackForOldFonts[defaultTheme.text.fontFamily] ??
        defaultTheme.text.fontFamily,
    },
    title: {
      ...defaultTheme.title,
      fontFamily:
        fallbackForOldFonts[defaultTheme.title.fontFamily] ??
        defaultTheme.title.fontFamily,
    },
  };

  return newTheme;
};
