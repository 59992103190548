import React from "react";

import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { EXTERNAL_URL } from "config";

import * as styles from "./styles";

const TokenPending = () => {
  return (
    <div css={styles.container}>
      <Title size="small">Your Token Is on the Way</Title>
      <Text>
        It should appear in your wallet in a few minutes. Once it does, you will
        be able to access this community. If you have any issues,{" "}
        <a
          href={EXTERNAL_URL.SUPPORT}
          target="_blank"
          rel="noreferrer"
          css={styles.link}
        >
          let us know
        </a>
        .
      </Text>
    </div>
  );
};

export default TokenPending;
