import React, { FC } from "react";

import { useReactiveVar } from "@apollo/client";
import { Outlet } from "react-router-dom";

import { BenefitType, TokenType } from "apollo/graphql.generated";
import { communityVar } from "apollo/reactive";
import { getLatestIssuedToken } from "apollo/utils/community";
import { ReactComponent as ReportIcon } from "assets/icons/report.svg";
import CardContainer from "components/CardContainer";
import MembershipTokenTooltip from "components/MembershipTokenTooltip";
import Text from "components/Typography/Text";
import DropdownMenu from "components/_Community/DropdownMenu";
import CommunityLabel from "components/_Community/Label";
import {
  COMMUNITY_REPORT,
  COMMUNITY_URL,
  EXTERNAL_URL,
  IN_DEVELOPMENT,
} from "config";
import CommunityHeader from "containers/CommunityV3/CommunityHeader";
import CommunityNav from "containers/CommunityV3/CommunityNav";
import useCommunityFeatureFlag from "hooks/useCommunityFeatureFlag";

import * as styles from "./styles";
import { JoinedCommunityProps } from "./types";

const JoinedCommunity: FC<JoinedCommunityProps> = ({
  benefitMap,
  userTokens,
  contractAddress,
}) => {
  const hideMembershipTokenReferences = useCommunityFeatureFlag(
    "hideMembershipTokenReferences"
  );
  const { name } = useReactiveVar(communityVar);
  const userMembershipToken = getLatestIssuedToken(
    userTokens,
    TokenType.Membership
  );

  const openSeaBaseUrl = IN_DEVELOPMENT
    ? EXTERNAL_URL.OPENSEA_TESTNET
    : EXTERNAL_URL.OPENSEA;

  const reportLinkMailContent = encodeURI(
    `mailto:${COMMUNITY_REPORT.mail}?subject=${
      COMMUNITY_REPORT.subject
    }&body=${COMMUNITY_REPORT.body({
      communityName: name,
      today: new Date().toDateString(),
    })}`
  );

  return (
    <>
      <div css={styles.layout}>
        <div css={styles.communityHeader}>
          <CommunityHeader />
        </div>
        <div css={styles.tokenInfo}>
          {userMembershipToken &&
            userMembershipToken.balance &&
            !hideMembershipTokenReferences && (
              <>
                <MembershipTokenTooltip
                  communityName={name}
                  tokenName={userMembershipToken.name}
                  tokenId={userMembershipToken.tokenId}
                  imageUrl={userMembershipToken.imageUrl}
                >
                  <CommunityLabel
                    color="onPageBackgroundPrimaryColor"
                    size="xsmall"
                  >
                    YOUR MEMBERSHIP
                  </CommunityLabel>
                </MembershipTokenTooltip>
              </>
            )}

          {userMembershipToken?.balance && contractAddress && (
            <i css={styles.communityLabelSeparator}></i>
          )}

          {contractAddress && (
            <a
              href={`${openSeaBaseUrl}?search%5Bquery%5D=${
                contractAddress ?? ""
              }`}
              target="_blank"
              rel="noreferrer"
            >
              <CommunityLabel
                color="onPageBackgroundPrimaryColor"
                size="xsmall"
              >
                VIEW ON OPENSEA
              </CommunityLabel>
            </a>
          )}
          {!hideMembershipTokenReferences && (
            <>
              <i css={styles.communityLabelSeparator}></i>
              <DropdownMenu label="MORE">
                <CardContainer extraCss={styles.report} border>
                  <a
                    href={reportLinkMailContent}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ReportIcon />
                    <Text>Report</Text>
                  </a>
                </CardContainer>
              </DropdownMenu>
            </>
          )}
        </div>
        <div css={styles.communityNav}>
          <CommunityNav.Container>
            <CommunityNav.Link to={COMMUNITY_URL.about}>
              About
            </CommunityNav.Link>
            {benefitMap[BenefitType.CONTENT_FEED].hasBenefit && (
              <CommunityNav.Link to={COMMUNITY_URL.posts}>
                Posts
              </CommunityNav.Link>
            )}
            {benefitMap[BenefitType.DISCORD].hasBenefit && (
              <CommunityNav.Link to={COMMUNITY_URL.discord}>
                Discord
              </CommunityNav.Link>
            )}
            {benefitMap[BenefitType.SHOPIFY].hasBenefit && (
              <CommunityNav.Link to={COMMUNITY_URL.shop}>
                Shop
              </CommunityNav.Link>
            )}
          </CommunityNav.Container>
        </div>
      </div>
      <div css={styles.page}>
        <Outlet />
      </div>
    </>
  );
};

export default JoinedCommunity;
