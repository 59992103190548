import React, { FC, memo } from "react";

import { css } from "@emotion/react";

import Anchor from "components/Anchor";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SUPPORT_ARTICLES } from "config";
import { topMargin } from "styles/global/layout";
import { buildOpenSeaSearchUrl, trackOpenSeaLinkOpen } from "utils/opensea";

import type { CreatorCommunity } from "../../EditCommunityHome";
import * as integrationsStyles from "../styles";

interface OpenSeaIntegrationProps {
  community: NonNullable<CreatorCommunity>;
}

export const titleStyles = css`
  ${topMargin("9/32px")}
`;

const OpenSeaIntegration: FC<OpenSeaIntegrationProps> = ({ community }) => {
  const contractAddress = community.polygonContract?.contractAddress;
  const openSeaSearchUrl = contractAddress
    ? buildOpenSeaSearchUrl(contractAddress)
    : undefined;

  const viewOpenSeaCollectionOnClickHandler = contractAddress
    ? () => trackOpenSeaLinkOpen(contractAddress)
    : undefined;

  return (
    <>
      <Title size="xsmall" extraCss={titleStyles}>
        OpenSea Collection
      </Title>
      <Text size="small">
        Your community tokens will appear as a collection on OpenSea, the
        leading NFT marketplace.
      </Text>
      <Text size="small">
        To learn more,{" "}
        <Anchor
          href={SUPPORT_ARTICLES.OPENSEA_CONFIGURATION}
          color="accent"
          target="_blank"
          rel="noreferrer"
        >
          click here
        </Anchor>
        .
      </Text>

      <CardContainer
        border
        background={"primary"}
        extraCss={integrationsStyles.fieldCard}
      >
        {community.tokens.length && openSeaSearchUrl ? (
          <Text>
            <Anchor
              href={openSeaSearchUrl}
              color="accent"
              onClick={viewOpenSeaCollectionOnClickHandler}
              target="_blank"
              rel="noreferrer"
            >
              View Collection on OpenSea
            </Anchor>
          </Text>
        ) : (
          <Text color="secondary">You haven’t minted any tokens yet.</Text>
        )}
      </CardContainer>
    </>
  );
};

export default memo(OpenSeaIntegration);
