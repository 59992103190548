import { css, Theme } from "@emotion/react";

import { textMediumStyles } from "styles/global/typography";

export const fieldGroup = css`
  margin-bottom: 32px;
`;

export const label = css`
  display: block;
  margin-bottom: 8px;
`;

export const common = (theme: Theme) =>
  css`
    border-radius: ${theme.textInput.border.radius};
    background-color: ${theme.textInput.backgroundColor};
  `;

export const inputWrapper = (error: string | undefined) => (theme: Theme) =>
  css`
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    border-width: ${error ? "2px" : `${theme.textInput.border.width}`};
    border-style: ${error ? "solid" : `${theme.textInput.border.style}`};
    border-color: ${error
      ? `${theme.colorMap.destructive.default}`
      : `${theme.textInput.border.color}`};
    ${common(theme)}
  `;

export const prefix = css`
  flex-shrink: 0;
  padding-left: 16px;
`;

export const postfix = css`
  flex-shrink: 0;
  padding-right: 16px;
`;

export const input =
  (error: string | undefined, prefix: boolean) => (theme: Theme) =>
    css`
      display: block;
      appearance: none;
      width: 100%;
      outline: none;
      margin: 0;
      resize: none;
      ${textMediumStyles(theme)}
      color: ${theme.textInput.textColor};
      font-family: ${theme.text.fontFamily.primary};
      padding: ${prefix ? "16px 16px 16px 0" : "16px"};

      ${common(theme)}

      &::placeholder {
        opacity: initial; //Remove if we stop using sanitize css
        color: ${theme.textInput.placeholderColor};
      }

      ${theme.mediaQueries.mediumUp} {
        padding: ${prefix ? "20px 16px 20px 0" : "20px 16px"};
      }
    `;

export const counter = css`
  margin: 0;
  padding: 0;
  position: absolute;
  right: 16px;
  bottom: 12px;
  z-index: 1;
  pointer-events: none;
`;

export const error = (theme: Theme) => css`
  ${textMediumStyles(theme)}
  color: ${theme.colorMap.destructive.default};
  padding: 0;
  margin: 8px 0 0 0;
`;
