import React, { useCallback, useEffect, useState } from "react";

import { css, Theme } from "@emotion/react";

import {
  useWithdrawRoyaltiesMutation,
  usePendingWithdrawalsQuery,
  PendingWithdrawalsDocument,
} from "apollo/queries/communities.graphql.generated";
import Button from "components/Button";
import Text from "components/Typography/Text";
import { Dollars } from "components/money";
import { currencyCode, roundupAmount } from "utils/formatting/currency";

const styles = {
  row: (theme: Theme) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid ${theme.divider};

    :last-child {
      border: none;
    }
  `,
  amountColumn: css`
    text-align: left;
  `,
  buttonColumn: css`
    text-align: right;
  `,
};

type WithdrawableItem = {
  coin: string;
  amount: string;
  inUSD: number | null;
};

type WithdrawProps = {
  items: WithdrawableItem[];
  communityId: string;
  refetchIncomeData: () => void;
};

type WithdrawRowProps = WithdrawableItem & {
  communityId: string;
  isProcessing: boolean;
};

const WithdrawRow: React.FC<WithdrawRowProps> = ({
  amount,
  coin,
  inUSD,
  communityId,
  isProcessing,
}) => {
  const [isLocalLoading, setLocalLoading] = useState(false);
  console.assert(communityId);
  const [withdraw] = useWithdrawRoyaltiesMutation({
    refetchQueries: [PendingWithdrawalsDocument],
  });

  const handleWithdraw = useCallback(() => {
    setLocalLoading(true);
    setTimeout(() => {
      // clear local loading state as it should be controlled externally now
      // external state relies on fetching data, so there is a delay
      setLocalLoading(false);
    }, 5 * 1000);
    withdraw({
      variables: {
        communityId,
        currency: coin,
      },
    });
  }, [withdraw, communityId, coin]);

  return (
    <div css={styles.row}>
      <div css={styles.amountColumn}>
        <Text bold as="div">
          {roundupAmount(amount)} {currencyCode(coin)}
        </Text>
        {inUSD ? (
          <Text color="secondary" as="div">
            ≈ <Dollars amount={inUSD} />
          </Text>
        ) : null}
      </div>
      <div css={styles.buttonColumn}>
        <Button
          onClick={handleWithdraw}
          loading={isProcessing || isLocalLoading}
        >
          Withdraw to Wallet
        </Button>
      </div>
    </div>
  );
};

export const WithdrawRoyaltiesSection: React.FC<WithdrawProps> = ({
  items,
  communityId,
  refetchIncomeData,
}) => {
  const { data, refetch } = usePendingWithdrawalsQuery({
    fetchPolicy: "network-only",
    variables: {
      communityId,
    },
  });

  const processingRows: Record<string, boolean> = {};
  data?.pendingWithdrawals.forEach((item) => {
    processingRows[item.currency] = true;
  });
  const hasPendingWithdrawals = data?.pendingWithdrawals?.length;
  const [isPending, setPending] = useState(hasPendingWithdrawals);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (hasPendingWithdrawals) {
      interval = setInterval(() => refetch(), 15 * 1000);
    } else if (isPending) {
      // refetch income page the moment task completes
      refetchIncomeData();
    }
    setPending(hasPendingWithdrawals);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [hasPendingWithdrawals, refetch]);

  const rows = items.map((item) => (
    <WithdrawRow
      key={item.coin}
      {...item}
      communityId={communityId}
      isProcessing={processingRows[item.coin]}
    />
  ));
  return <>{rows}</>;
};
