import React, { memo } from "react";

import { useParams } from "react-router-dom";

import Text from "components/Typography/Text";

import { setSlugCookie } from "./../../utils/cookie";

const LocalDefaultSlug = () => {
  const params = useParams();
  setSlugCookie(params.communitySlug as string);

  return (
    <Text as="p">
      For CI/local, updated the default slug to &quot;{params.communitySlug}
      &quot;.
    </Text>
  );
};

export default memo(LocalDefaultSlug);
