import { css, Theme } from "@emotion/react";

import { parseBorderType } from "../../styles/theme/communityTheme";

import { TooltipProps } from ".";

const paddingMap = {
  large: "24px",
  small: "16px",
  xsmall: "6px 12px",
};

export const container =
  (padding: NonNullable<TooltipProps["padding"]>) => (theme: Theme) =>
    css`
      box-shadow: ${theme.shadows["sm"]};
      border-radius: 8px;
      overflow: hidden;
      pointer-events: auto;

      .tippy-content {
        padding: ${paddingMap[padding]};
      }

      .tippy-arrow {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
    `;

const stylesBySkin = {
  dark: css`
    .tippy-content {
      background: rgba(0, 0, 0, 0.92);
      color: white;

      > div > * {
        color: inherit;
      }
    }

    .tippy-arrow {
      border-top: 9px solid rgba(0, 0, 0, 0.92);
    }
  `,
  light: css`
    .tippy-content {
      background: white;
    }

    .tippy-arrow {
      border-top: 12px solid white;
    }
  `,
  community: (theme: Theme) => css`
    border-radius: ${parseBorderType(
      theme.community.global.containerBorderType
    )};

    .tippy-content {
      background: ${theme.community.pageBackground.backgroundColor};
    }

    .tippy-arrow {
      border-top: 12px solid ${theme.community.pageBackground.backgroundColor};
    }
  `,
};

export const bySkin = (skin: NonNullable<TooltipProps["skin"]>) =>
  stylesBySkin[skin];
