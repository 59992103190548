import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import { AllCommunityFlagVariationsFragmentDoc } from "./flagVariations.graphql.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type CommunityBenefitFragment = {
  __typename: "Benefit";
  hasBenefit: boolean;
  hasAccess: boolean;
  unlockedBy: Array<{
    __typename: "UserToken";
    tokenId: number;
    name: string;
    imageUrl: string;
    balance: number | null;
    tokenType: Types.TokenType;
  }> | null;
  unlockableBy: Array<{
    __typename: "Token";
    tokenId: string;
    name: string | null;
    uri: string | null;
    supply: number | null;
    communityAddress: string;
    tokenType: Types.TokenType;
  }> | null;
};

export type WebsiteThemeFragment = {
  __typename: "WebsiteTheme";
  pageBackground: {
    __typename: "ThemePageBackground";
    backgroundColor: string;
  };
  primaryBackground: {
    __typename: "ThemePrimaryBackground";
    backgroundColor: string;
  };
  text: {
    __typename: "ThemeText";
    onPageBackgroundPrimaryColor: string;
    onPrimaryBackgroundPrimaryColor: string;
    fontFamily: string;
    fontWeight: string;
    letterSpacing: string;
    textTransform: string;
    boldFontWeight: string;
  };
  title: {
    __typename: "ThemeTitle";
    fontFamily: string;
    fontWeight: string;
    letterSpacing: string;
    textTransform: string;
  };
  label: {
    __typename: "ThemeLabel";
    letterSpacing: string;
    fontWeight: string;
  };
  textInput: {
    __typename: "ThemeTextInput";
    backgroundColor: string;
    primaryTextColor: string;
    borderColor: string;
    borderWidth: string;
    borderType: Types.BorderType;
  };
  button: {
    __typename: "ThemeButton";
    onPageBackgroundBackgroundColor: string;
    onPageBackgroundTextColor: string;
    onPageBackgroundBorderColor: string;
    onPageBackgroundBorderWidth: string;
    onPrimaryBackgroundBackgroundColor: string;
    onPrimaryBackgroundTextColor: string;
    onPrimaryBackgroundBorderColor: string;
    onPrimaryBackgroundBorderWidth: string;
    textFontFamily: string;
    textFontWeight: string;
    textLetterSpacing: string;
    textTextTransform: string;
    borderType: Types.BorderType;
  };
  global: { __typename: "ThemeGlobal"; containerBorderType: string };
};

export type CollectionBuyButtonFieldsFragment = {
  __typename: "CollectionBuyButton";
  collectionComponentDivId: string;
  domain: string;
  storefrontAccessToken: string;
  collectionId: string;
};

export type CreatorShopifyDataFragment = {
  __typename: "Community";
  shop: {
    __typename: "ShopifyData";
    buyButtons: Array<{ __typename: "BuyButton"; id: string }>;
    collectionBuyButton: {
      __typename: "CollectionBuyButton";
      collectionComponentDivId: string;
      domain: string;
      storefrontAccessToken: string;
      collectionId: string;
    } | null;
  };
};

export type CreatorDiscordDataFragment = {
  __typename: "Community";
  discordServer: {
    __typename: "DiscordServer";
    serverId: string | null;
    roleId: string | null;
    clientId: string | null;
  } | null;
};

export type CommunityQueryVariables = Types.Exact<{
  slug: Types.Maybe<Types.Scalars["String"]>;
}>;

export type CommunityQuery = {
  community: {
    __typename: "Community";
    id: string;
    name: string;
    slug: string;
    imageUrl: string | null;
    description: string | null;
    bannerImageUrl: string | null;
    creatorAvatar: {
      __typename: "CreatorAvatar";
      name: string | null;
      imageUrl: string | null;
    };
    discordServer: {
      __typename: "DiscordServer";
      serverId: string | null;
      roleId: string | null;
    } | null;
    websiteTheme: {
      __typename: "WebsiteTheme";
      pageBackground: {
        __typename: "ThemePageBackground";
        backgroundColor: string;
      };
      primaryBackground: {
        __typename: "ThemePrimaryBackground";
        backgroundColor: string;
      };
      text: {
        __typename: "ThemeText";
        onPageBackgroundPrimaryColor: string;
        onPrimaryBackgroundPrimaryColor: string;
        fontFamily: string;
        fontWeight: string;
        letterSpacing: string;
        textTransform: string;
        boldFontWeight: string;
      };
      title: {
        __typename: "ThemeTitle";
        fontFamily: string;
        fontWeight: string;
        letterSpacing: string;
        textTransform: string;
      };
      label: {
        __typename: "ThemeLabel";
        letterSpacing: string;
        fontWeight: string;
      };
      textInput: {
        __typename: "ThemeTextInput";
        backgroundColor: string;
        primaryTextColor: string;
        borderColor: string;
        borderWidth: string;
        borderType: Types.BorderType;
      };
      button: {
        __typename: "ThemeButton";
        onPageBackgroundBackgroundColor: string;
        onPageBackgroundTextColor: string;
        onPageBackgroundBorderColor: string;
        onPageBackgroundBorderWidth: string;
        onPrimaryBackgroundBackgroundColor: string;
        onPrimaryBackgroundTextColor: string;
        onPrimaryBackgroundBorderColor: string;
        onPrimaryBackgroundBorderWidth: string;
        textFontFamily: string;
        textFontWeight: string;
        textLetterSpacing: string;
        textTextTransform: string;
        borderType: Types.BorderType;
      };
      global: { __typename: "ThemeGlobal"; containerBorderType: string };
    };
    flagVariations: {
      __typename: "CommunityFlagVariations";
      hideMembershipTokenReferences: boolean;
    };
  } | null;
};

export type CommunityDetailsV3QueryVariables = Types.Exact<{
  slug: Types.Maybe<Types.Scalars["String"]>;
  useChain: Types.Scalars["Boolean"];
}>;

export type CommunityDetailsV3Query = {
  community: {
    __typename: "Community";
    id: string;
    polygonContract: {
      __typename: "Contract";
      contractAddress: string | null;
    } | null;
    contentFeed: {
      __typename: "ContentFeed";
      benefit: {
        __typename: "Benefit";
        hasBenefit: boolean;
        hasAccess: boolean;
        unlockedBy: Array<{
          __typename: "UserToken";
          tokenId: number;
          name: string;
          imageUrl: string;
          balance: number | null;
          tokenType: Types.TokenType;
        }> | null;
        unlockableBy: Array<{
          __typename: "Token";
          tokenId: string;
          name: string | null;
          uri: string | null;
          supply: number | null;
          communityAddress: string;
          tokenType: Types.TokenType;
        }> | null;
      };
    };
    discord: {
      __typename: "DiscordData";
      benefit: {
        __typename: "Benefit";
        hasBenefit: boolean;
        hasAccess: boolean;
        unlockedBy: Array<{
          __typename: "UserToken";
          tokenId: number;
          name: string;
          imageUrl: string;
          balance: number | null;
          tokenType: Types.TokenType;
        }> | null;
        unlockableBy: Array<{
          __typename: "Token";
          tokenId: string;
          name: string | null;
          uri: string | null;
          supply: number | null;
          communityAddress: string;
          tokenType: Types.TokenType;
        }> | null;
      };
    };
    shop: {
      __typename: "ShopifyData";
      benefit: {
        __typename: "Benefit";
        hasBenefit: boolean;
        hasAccess: boolean;
        unlockedBy: Array<{
          __typename: "UserToken";
          tokenId: number;
          name: string;
          imageUrl: string;
          balance: number | null;
          tokenType: Types.TokenType;
        }> | null;
        unlockableBy: Array<{
          __typename: "Token";
          tokenId: string;
          name: string | null;
          uri: string | null;
          supply: number | null;
          communityAddress: string;
          tokenType: Types.TokenType;
        }> | null;
      };
    };
  } | null;
};

export type CommunityOffersQueryVariables = Types.Exact<{
  slug: Types.Maybe<Types.Scalars["String"]>;
}>;

export type CommunityOffersQuery = {
  community: {
    __typename: "Community";
    offers: Array<{
      __typename: "Offer";
      id: string;
      name: string;
      tokenId: number | null;
      price: string;
      currency: string;
      active: boolean;
      onAboutPage: boolean | null;
      onPrivatePage: boolean | null;
      tokens: Array<{
        __typename: "Token";
        tokenId: string;
        name: string | null;
        communityAddress: string;
        tokenType: Types.TokenType;
        description: string | null;
        supply: number | null;
        creatorBalance: number | null;
        uri: string | null;
      }> | null;
    }>;
  } | null;
};

export type UploadCreatorAvatarImageMutationVariables = Types.Exact<{
  mime: Types.Scalars["String"];
  asset: Types.Scalars["Upload"];
  communityId: Types.Scalars["String"];
}>;

export type UploadCreatorAvatarImageMutation = {
  uploadCreatorAvatarImage: {
    __typename: "Community";
    creatorAvatar: { __typename: "CreatorAvatar"; imageUrl: string | null };
  };
};

export type UploadCommunityLogoImageMutationVariables = Types.Exact<{
  mime: Types.Scalars["String"];
  asset: Types.Scalars["Upload"];
  communityId: Types.Scalars["String"];
}>;

export type UploadCommunityLogoImageMutation = {
  uploadCommunityLogoImage: {
    __typename: "Community";
    imageUrl: string | null;
  };
};

export type UploadCommunityBannerImageMutationVariables = Types.Exact<{
  mime: Types.Scalars["String"];
  asset: Types.Scalars["Upload"];
  communityId: Types.Scalars["String"];
}>;

export type UploadCommunityBannerImageMutation = {
  uploadCommunityBannerImage: {
    __typename: "Community";
    bannerImageUrl: string | null;
  };
};

export type DeleteCreatorAvatarImageMutationVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
}>;

export type DeleteCreatorAvatarImageMutation = {
  deleteCreatorAvatarImage: {
    __typename: "Community";
    creatorAvatar: { __typename: "CreatorAvatar"; imageUrl: string | null };
  };
};

export type DeleteCommunityLogoImageMutationVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
}>;

export type DeleteCommunityLogoImageMutation = {
  deleteCommunityLogoImage: {
    __typename: "Community";
    imageUrl: string | null;
  };
};

export type DeleteCommunityBannerImageMutationVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
}>;

export type DeleteCommunityBannerImageMutation = {
  deleteCommunityBannerImage: {
    __typename: "Community";
    bannerImageUrl: string | null;
  };
};

export type CreateCommunityMutationVariables = Types.Exact<{
  data: Types.CreateCommunityInput;
}>;

export type CreateCommunityMutation = {
  createCommunity: { __typename: "Community"; id: string; slug: string };
};

export type LaunchCommunityMutationVariables = Types.Exact<{
  launchCommunityId: Types.Scalars["String"];
}>;

export type LaunchCommunityMutation = {
  launchCommunity: { __typename: "Community"; id: string; slug: string };
};

export type PrepareForMintMutationVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
}>;

export type PrepareForMintMutation = {
  prepareForMint: {
    __typename: "BlockchainTaskRefNullable";
    taskId: string | null;
  };
};

export type UpdateCreatedCommunityThemeMutationVariables = Types.Exact<{
  theme: Types.InputWebsiteTheme;
  communityId: Types.Scalars["String"];
}>;

export type UpdateCreatedCommunityThemeMutation = {
  updateCreatedCommunityTheme: {
    __typename: "WebsiteTheme";
    pageBackground: {
      __typename: "ThemePageBackground";
      backgroundColor: string;
    };
    primaryBackground: {
      __typename: "ThemePrimaryBackground";
      backgroundColor: string;
    };
    text: {
      __typename: "ThemeText";
      onPageBackgroundPrimaryColor: string;
      onPrimaryBackgroundPrimaryColor: string;
      fontFamily: string;
      fontWeight: string;
      letterSpacing: string;
      textTransform: string;
      boldFontWeight: string;
    };
    title: {
      __typename: "ThemeTitle";
      fontFamily: string;
      fontWeight: string;
      letterSpacing: string;
      textTransform: string;
    };
    label: {
      __typename: "ThemeLabel";
      letterSpacing: string;
      fontWeight: string;
    };
    textInput: {
      __typename: "ThemeTextInput";
      backgroundColor: string;
      primaryTextColor: string;
      borderColor: string;
      borderWidth: string;
      borderType: Types.BorderType;
    };
    button: {
      __typename: "ThemeButton";
      onPageBackgroundBackgroundColor: string;
      onPageBackgroundTextColor: string;
      onPageBackgroundBorderColor: string;
      onPageBackgroundBorderWidth: string;
      onPrimaryBackgroundBackgroundColor: string;
      onPrimaryBackgroundTextColor: string;
      onPrimaryBackgroundBorderColor: string;
      onPrimaryBackgroundBorderWidth: string;
      textFontFamily: string;
      textFontWeight: string;
      textLetterSpacing: string;
      textTextTransform: string;
      borderType: Types.BorderType;
    };
    global: { __typename: "ThemeGlobal"; containerBorderType: string };
  };
};

export type UpdateGeneralInfoMutationVariables = Types.Exact<{
  data: Types.UpdateGeneralInfoInput;
  updateGeneralInfoId: Types.Scalars["String"];
}>;

export type UpdateGeneralInfoMutation = {
  updateGeneralInfo: {
    __typename: "Community";
    name: string;
    slug: string;
    description: string | null;
    imageUrl: string | null;
    bannerImageUrl: string | null;
    contactEmail: string | null;
    creatorAvatar: {
      __typename: "CreatorAvatar";
      name: string | null;
      imageUrl: string | null;
    };
  };
};

export type UpdateShopifyCollectionBuyButtonMutationVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
  input: Types.UpdateCollectionBuyButtonInput;
}>;

export type UpdateShopifyCollectionBuyButtonMutation = {
  updateShopifyCollectionBuyButton: {
    __typename: "UpdateCollectionBuyButtonResult";
    communityId: string;
    collectionComponentDivId: string;
    domain: string;
    storefrontAccessToken: string;
    collectionId: string;
  };
};

export type DeleteShopifyCollectionBuyButtonMutationVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
}>;

export type DeleteShopifyCollectionBuyButtonMutation = {
  deleteShopifyCollectionBuyButton: {
    __typename: "DeleteCollectionBuyButtonResult";
    communityId: string;
    success: boolean;
  };
};

export type CreatedCommunityBySlugQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
  type: Types.Scalars["String"];
  useChain: Types.Scalars["Boolean"];
  active: Types.Scalars["Boolean"];
}>;

export type CreatedCommunityBySlugQuery = {
  createdCommunityBySlug: {
    __typename: "Community";
    id: string;
    name: string;
    slug: string;
    status: Types.CommunityStatus | null;
    description: string | null;
    imageUrl: string | null;
    bannerImageUrl: string | null;
    royaltySplitAddress: string | null;
    contactEmail: string | null;
    polygonContract: {
      __typename: "Contract";
      contractAddress: string | null;
    } | null;
    creatorAvatar: {
      __typename: "CreatorAvatar";
      name: string | null;
      imageUrl: string | null;
    };
    tokens: Array<{
      __typename: "Token";
      tokenId: string;
      tokenStatus: Types.TokenStatus | null;
    }>;
    offers: Array<{
      __typename: "Offer";
      id: string;
      active: boolean;
      tokens: Array<{
        __typename: "Token";
        tokenId: string;
        tokenType: Types.TokenType;
      }> | null;
    }>;
    shop: {
      __typename: "ShopifyData";
      buyButtons: Array<{ __typename: "BuyButton"; id: string }>;
      collectionBuyButton: {
        __typename: "CollectionBuyButton";
        collectionComponentDivId: string;
        domain: string;
        storefrontAccessToken: string;
        collectionId: string;
      } | null;
    };
    discordServer: {
      __typename: "DiscordServer";
      serverId: string | null;
      roleId: string | null;
      clientId: string | null;
    } | null;
  } | null;
};

export type OnCommunityContractDeployedSubscriptionVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
}>;

export type OnCommunityContractDeployedSubscription = {
  community: {
    __typename: "CommunityNotification";
    communityId: string;
    type: Types.CommunityNotificationType;
    status: Types.NotificationStatus;
  };
};

export type CreatedCommunityThemeBySlugQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
}>;

export type CreatedCommunityThemeBySlugQuery = {
  createdCommunityBySlug: {
    __typename: "Community";
    id: string;
    websiteTheme: {
      __typename: "WebsiteTheme";
      pageBackground: {
        __typename: "ThemePageBackground";
        backgroundColor: string;
      };
      primaryBackground: {
        __typename: "ThemePrimaryBackground";
        backgroundColor: string;
      };
      text: {
        __typename: "ThemeText";
        onPageBackgroundPrimaryColor: string;
        onPrimaryBackgroundPrimaryColor: string;
        fontFamily: string;
        fontWeight: string;
        letterSpacing: string;
        textTransform: string;
        boldFontWeight: string;
      };
      title: {
        __typename: "ThemeTitle";
        fontFamily: string;
        fontWeight: string;
        letterSpacing: string;
        textTransform: string;
      };
      label: {
        __typename: "ThemeLabel";
        letterSpacing: string;
        fontWeight: string;
      };
      textInput: {
        __typename: "ThemeTextInput";
        backgroundColor: string;
        primaryTextColor: string;
        borderColor: string;
        borderWidth: string;
        borderType: Types.BorderType;
      };
      button: {
        __typename: "ThemeButton";
        onPageBackgroundBackgroundColor: string;
        onPageBackgroundTextColor: string;
        onPageBackgroundBorderColor: string;
        onPageBackgroundBorderWidth: string;
        onPrimaryBackgroundBackgroundColor: string;
        onPrimaryBackgroundTextColor: string;
        onPrimaryBackgroundBorderColor: string;
        onPrimaryBackgroundBorderWidth: string;
        textFontFamily: string;
        textFontWeight: string;
        textLetterSpacing: string;
        textTextTransform: string;
        borderType: Types.BorderType;
      };
      global: { __typename: "ThemeGlobal"; containerBorderType: string };
    };
  } | null;
};

export type CreatedCommunityIncomeQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
}>;

export type CreatedCommunityIncomeQuery = {
  createdCommunityBySlug: {
    __typename: "Community";
    id: string;
    creatorWethBalance: {
      __typename: "CryptoCurrency";
      coin: string;
      value: string;
      inUSD: number | null;
    } | null;
    secondaryEarnings: Array<{
      __typename: "CryptoCurrency";
      coin: string;
      value: string;
      inUSD: number | null;
    }>;
    royalties: Array<{
      __typename: "Royalty";
      allowToWithdraw: boolean;
      balance: {
        __typename: "CryptoCurrency";
        coin: string;
        value: string;
        inUSD: number | null;
      };
    }>;
    creatorStripeBalance: {
      __typename: "StripeBalance";
      currency: string;
      total: number;
      processing: number;
    } | null;
  } | null;
};

export type WithdrawRoyaltiesMutationVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
  currency: Types.Scalars["String"];
}>;

export type WithdrawRoyaltiesMutation = {
  withdrawCreatorRoyalties: { __typename: "BlockchainTaskRef"; taskId: string };
};

export type PendingWithdrawalsQueryVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
}>;

export type PendingWithdrawalsQuery = {
  pendingWithdrawals: Array<{
    __typename: "PendingWithdrawal";
    taskId: string;
    currency: string;
  }>;
};

export type CreatedCommunityTokensBySlugQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
  type: Types.Scalars["String"];
  useChain: Types.Scalars["Boolean"];
}>;

export type CreatedCommunityTokensBySlugQuery = {
  createdCommunityBySlug: {
    __typename: "Community";
    id: string;
    tokens: Array<{
      __typename: "Token";
      communityAddress: string;
      tokenId: string;
      tokenStatus: Types.TokenStatus | null;
      name: string | null;
      uri: string | null;
      description: string | null;
      tokenType: Types.TokenType;
      supply: number | null;
      creatorBalance: number | null;
      taskId: string | null;
      benefits: Array<{
        __typename: "TokenBenefit";
        type: Types.BenefitType;
      }> | null;
    }>;
    offers: Array<{
      __typename: "Offer";
      id: string;
      communityId: string;
      tokenIds: Array<number>;
      name: string;
      price: string;
      currency: string;
      active: boolean;
      onAboutPage: boolean | null;
      onPrivatePage: boolean | null;
    }>;
  } | null;
};

export type TokenDetailsQueryVariables = Types.Exact<{
  slug: Types.Maybe<Types.Scalars["String"]>;
  useChain: Types.Scalars["Boolean"];
  tokenId: Types.Scalars["Int"];
}>;

export type TokenDetailsQuery = {
  community: {
    __typename: "Community";
    id: string;
    polygonContract: {
      __typename: "Contract";
      contractAddress: string | null;
    } | null;
    creatorAvatar: { __typename: "CreatorAvatar"; name: string | null };
    token: {
      __typename: "Token";
      tokenId: string;
      name: string | null;
      uri: string | null;
      animationUri: string | null;
      description: string | null;
      tokenType: Types.TokenType;
      supply: number | null;
      creatorBalance: number | null;
      benefits: Array<{
        __typename: "TokenBenefit";
        type: Types.BenefitType;
        customName: string | null;
      }> | null;
    };
  } | null;
};

export type CreatorCommunityMembersQueryVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
  page: Types.Scalars["Int"];
  pageSize: Types.Scalars["Int"];
  sort: Types.CreatorCommunityMembersSort;
  email: Types.Maybe<Types.Scalars["String"]>;
}>;

export type CreatorCommunityMembersQuery = {
  creatorCommunityMembers: {
    __typename: "CreatorCommunityMembers";
    amount: number;
    currentMonthAmount: number;
    resultsCount: number;
    members: Array<{
      __typename: "CreatorCommunityMember";
      email: string;
      dateJoined: number | null;
      discordUserName: string | null;
      tokensHeldNames: Array<string> | null;
      blockchainAddress: string | null;
    }>;
  };
};

export type PendingTokenDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
  taskId: Types.Scalars["String"];
}>;

export type PendingTokenDetailsQuery = {
  createdCommunityBySlug: {
    __typename: "Community";
    id: string;
    blockchainTaskToken: {
      __typename: "BlockchainTaskToken";
      tokenId: string | null;
      tokenStatus: Types.TokenStatus;
      communityAddress: string;
      taskId: string;
      name: string | null;
      uri: string | null;
      animationUri: string | null;
      description: string | null;
      tokenType: Types.TokenType;
      supply: number | null;
      creatorBalance: number | null;
      mintedAsNonTransferable: boolean;
      benefits: Array<{
        __typename: "TokenBenefit";
        type: Types.BenefitType;
        customName: string | null;
      }> | null;
    };
  } | null;
};

export type MintedTokenDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
  useChain: Types.Scalars["Boolean"];
  tokenId: Types.Scalars["Int"];
}>;

export type MintedTokenDetailsQuery = {
  createdCommunityBySlug: {
    __typename: "Community";
    id: string;
    claimPages: Array<{
      __typename: "Contest";
      id: string;
      active: boolean;
      tokensMetadata: {
        __typename: "TokensMetadata";
        quantity: number;
        maxQuantity: number | null;
        totalGrantedQuantity: number | null;
      };
      contestPage: { __typename: "ContestPage"; title: string };
    }>;
    token: {
      __typename: "Token";
      tokenStatus: Types.TokenStatus | null;
      communityAddress: string;
      tokenId: string;
      name: string | null;
      uri: string | null;
      animationUri: string | null;
      description: string | null;
      tokenType: Types.TokenType;
      supply: number | null;
      creatorBalance: number | null;
      benefits: Array<{
        __typename: "TokenBenefit";
        type: Types.BenefitType;
        customName: string | null;
      }> | null;
    };
    offers: Array<{
      __typename: "Offer";
      id: string;
      communityId: string;
      tokenIds: Array<number>;
      name: string;
      price: string;
      currency: string;
      active: boolean;
      onAboutPage: boolean | null;
      onPrivatePage: boolean | null;
      crypto: { __typename: "CryptoCurrency"; inUSD: number | null } | null;
    }>;
  } | null;
};

export type SyncUserBenefitsMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SyncUserBenefitsMutation = {
  syncUserBenefits: {
    __typename: "SyncBenefitsResponse";
    successful: number;
    failed: number;
    total: number;
  };
};

export const CommunityBenefitFragmentDoc = gql`
  fragment CommunityBenefit on Benefit {
    hasBenefit
    hasAccess
    unlockedBy {
      tokenId
      name
      imageUrl
      balance
      tokenType
    }
    unlockableBy {
      tokenId
      name
      uri(useChain: $useChain)
      supply
      communityAddress
      tokenType
    }
  }
`;
export const WebsiteThemeFragmentDoc = gql`
  fragment WebsiteTheme on WebsiteTheme {
    pageBackground {
      backgroundColor
    }
    primaryBackground {
      backgroundColor
    }
    text {
      onPageBackgroundPrimaryColor
      onPrimaryBackgroundPrimaryColor
      fontFamily
      fontWeight
      letterSpacing
      textTransform
      boldFontWeight
    }
    title {
      fontFamily
      fontWeight
      letterSpacing
      textTransform
    }
    label {
      letterSpacing
      fontWeight
    }
    textInput {
      backgroundColor
      primaryTextColor
      borderColor
      borderWidth
      borderType
    }
    button {
      onPageBackgroundBackgroundColor
      onPageBackgroundTextColor
      onPageBackgroundBorderColor
      onPageBackgroundBorderWidth
      onPrimaryBackgroundBackgroundColor
      onPrimaryBackgroundTextColor
      onPrimaryBackgroundBorderColor
      onPrimaryBackgroundBorderWidth
      textFontFamily
      textFontWeight
      textLetterSpacing
      textTextTransform
      borderType
    }
    global {
      containerBorderType
    }
  }
`;
export const CollectionBuyButtonFieldsFragmentDoc = gql`
  fragment CollectionBuyButtonFields on CollectionBuyButton {
    collectionComponentDivId
    domain
    storefrontAccessToken
    collectionId
  }
`;
export const CreatorShopifyDataFragmentDoc = gql`
  fragment CreatorShopifyData on Community {
    shop {
      buyButtons {
        id
      }
      collectionBuyButton {
        ...CollectionBuyButtonFields
      }
    }
  }
  ${CollectionBuyButtonFieldsFragmentDoc}
`;
export const CreatorDiscordDataFragmentDoc = gql`
  fragment CreatorDiscordData on Community {
    discordServer {
      serverId
      roleId
      clientId
    }
  }
`;
export const CommunityDocument = gql`
  query Community($slug: String) {
    community(slug: $slug) {
      id
      name
      slug
      imageUrl
      description
      bannerImageUrl
      creatorAvatar {
        name
        imageUrl
      }
      discordServer {
        serverId
        roleId
      }
      websiteTheme {
        ...WebsiteTheme
      }
      flagVariations {
        ...AllCommunityFlagVariations
      }
    }
  }
  ${WebsiteThemeFragmentDoc}
  ${AllCommunityFlagVariationsFragmentDoc}
`;

/**
 * __useCommunityQuery__
 *
 * To run a query within a React component, call `useCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCommunityQuery(
  baseOptions?: Apollo.QueryHookOptions<CommunityQuery, CommunityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommunityQuery, CommunityQueryVariables>(
    CommunityDocument,
    options
  );
}
export function useCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommunityQuery,
    CommunityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommunityQuery, CommunityQueryVariables>(
    CommunityDocument,
    options
  );
}
export type CommunityQueryHookResult = ReturnType<typeof useCommunityQuery>;
export type CommunityLazyQueryHookResult = ReturnType<
  typeof useCommunityLazyQuery
>;
export type CommunityQueryResult = Apollo.QueryResult<
  CommunityQuery,
  CommunityQueryVariables
>;
export const CommunityDetailsV3Document = gql`
  query CommunityDetailsV3($slug: String, $useChain: Boolean!) {
    community(slug: $slug) {
      id
      polygonContract {
        contractAddress
      }
      contentFeed {
        benefit {
          ...CommunityBenefit
        }
      }
      discord {
        benefit {
          ...CommunityBenefit
        }
      }
      shop {
        benefit {
          ...CommunityBenefit
        }
      }
    }
  }
  ${CommunityBenefitFragmentDoc}
`;

/**
 * __useCommunityDetailsV3Query__
 *
 * To run a query within a React component, call `useCommunityDetailsV3Query` and pass it any options that fit your needs.
 * When your component renders, `useCommunityDetailsV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityDetailsV3Query({
 *   variables: {
 *      slug: // value for 'slug'
 *      useChain: // value for 'useChain'
 *   },
 * });
 */
export function useCommunityDetailsV3Query(
  baseOptions: Apollo.QueryHookOptions<
    CommunityDetailsV3Query,
    CommunityDetailsV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CommunityDetailsV3Query,
    CommunityDetailsV3QueryVariables
  >(CommunityDetailsV3Document, options);
}
export function useCommunityDetailsV3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommunityDetailsV3Query,
    CommunityDetailsV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CommunityDetailsV3Query,
    CommunityDetailsV3QueryVariables
  >(CommunityDetailsV3Document, options);
}
export type CommunityDetailsV3QueryHookResult = ReturnType<
  typeof useCommunityDetailsV3Query
>;
export type CommunityDetailsV3LazyQueryHookResult = ReturnType<
  typeof useCommunityDetailsV3LazyQuery
>;
export type CommunityDetailsV3QueryResult = Apollo.QueryResult<
  CommunityDetailsV3Query,
  CommunityDetailsV3QueryVariables
>;
export const CommunityOffersDocument = gql`
  query CommunityOffers($slug: String) {
    community(slug: $slug) {
      offers(active: true) {
        id
        name
        tokenId
        price
        currency
        active
        onAboutPage
        onPrivatePage
        tokens {
          tokenId
          name
          communityAddress
          tokenType
          description
          supply
          creatorBalance
          uri(useChain: false)
        }
      }
    }
  }
`;

/**
 * __useCommunityOffersQuery__
 *
 * To run a query within a React component, call `useCommunityOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityOffersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCommunityOffersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CommunityOffersQuery,
    CommunityOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommunityOffersQuery, CommunityOffersQueryVariables>(
    CommunityOffersDocument,
    options
  );
}
export function useCommunityOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommunityOffersQuery,
    CommunityOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CommunityOffersQuery,
    CommunityOffersQueryVariables
  >(CommunityOffersDocument, options);
}
export type CommunityOffersQueryHookResult = ReturnType<
  typeof useCommunityOffersQuery
>;
export type CommunityOffersLazyQueryHookResult = ReturnType<
  typeof useCommunityOffersLazyQuery
>;
export type CommunityOffersQueryResult = Apollo.QueryResult<
  CommunityOffersQuery,
  CommunityOffersQueryVariables
>;
export const UploadCreatorAvatarImageDocument = gql`
  mutation UploadCreatorAvatarImage(
    $mime: String!
    $asset: Upload!
    $communityId: String!
  ) {
    uploadCreatorAvatarImage(mime: $mime, asset: $asset, id: $communityId) {
      creatorAvatar {
        imageUrl
      }
    }
  }
`;
export type UploadCreatorAvatarImageMutationFn = Apollo.MutationFunction<
  UploadCreatorAvatarImageMutation,
  UploadCreatorAvatarImageMutationVariables
>;

/**
 * __useUploadCreatorAvatarImageMutation__
 *
 * To run a mutation, you first call `useUploadCreatorAvatarImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCreatorAvatarImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCreatorAvatarImageMutation, { data, loading, error }] = useUploadCreatorAvatarImageMutation({
 *   variables: {
 *      mime: // value for 'mime'
 *      asset: // value for 'asset'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useUploadCreatorAvatarImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCreatorAvatarImageMutation,
    UploadCreatorAvatarImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadCreatorAvatarImageMutation,
    UploadCreatorAvatarImageMutationVariables
  >(UploadCreatorAvatarImageDocument, options);
}
export type UploadCreatorAvatarImageMutationHookResult = ReturnType<
  typeof useUploadCreatorAvatarImageMutation
>;
export type UploadCreatorAvatarImageMutationResult =
  Apollo.MutationResult<UploadCreatorAvatarImageMutation>;
export type UploadCreatorAvatarImageMutationOptions =
  Apollo.BaseMutationOptions<
    UploadCreatorAvatarImageMutation,
    UploadCreatorAvatarImageMutationVariables
  >;
export const UploadCommunityLogoImageDocument = gql`
  mutation UploadCommunityLogoImage(
    $mime: String!
    $asset: Upload!
    $communityId: String!
  ) {
    uploadCommunityLogoImage(mime: $mime, asset: $asset, id: $communityId) {
      imageUrl
    }
  }
`;
export type UploadCommunityLogoImageMutationFn = Apollo.MutationFunction<
  UploadCommunityLogoImageMutation,
  UploadCommunityLogoImageMutationVariables
>;

/**
 * __useUploadCommunityLogoImageMutation__
 *
 * To run a mutation, you first call `useUploadCommunityLogoImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCommunityLogoImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCommunityLogoImageMutation, { data, loading, error }] = useUploadCommunityLogoImageMutation({
 *   variables: {
 *      mime: // value for 'mime'
 *      asset: // value for 'asset'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useUploadCommunityLogoImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCommunityLogoImageMutation,
    UploadCommunityLogoImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadCommunityLogoImageMutation,
    UploadCommunityLogoImageMutationVariables
  >(UploadCommunityLogoImageDocument, options);
}
export type UploadCommunityLogoImageMutationHookResult = ReturnType<
  typeof useUploadCommunityLogoImageMutation
>;
export type UploadCommunityLogoImageMutationResult =
  Apollo.MutationResult<UploadCommunityLogoImageMutation>;
export type UploadCommunityLogoImageMutationOptions =
  Apollo.BaseMutationOptions<
    UploadCommunityLogoImageMutation,
    UploadCommunityLogoImageMutationVariables
  >;
export const UploadCommunityBannerImageDocument = gql`
  mutation UploadCommunityBannerImage(
    $mime: String!
    $asset: Upload!
    $communityId: String!
  ) {
    uploadCommunityBannerImage(mime: $mime, asset: $asset, id: $communityId) {
      bannerImageUrl
    }
  }
`;
export type UploadCommunityBannerImageMutationFn = Apollo.MutationFunction<
  UploadCommunityBannerImageMutation,
  UploadCommunityBannerImageMutationVariables
>;

/**
 * __useUploadCommunityBannerImageMutation__
 *
 * To run a mutation, you first call `useUploadCommunityBannerImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCommunityBannerImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCommunityBannerImageMutation, { data, loading, error }] = useUploadCommunityBannerImageMutation({
 *   variables: {
 *      mime: // value for 'mime'
 *      asset: // value for 'asset'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useUploadCommunityBannerImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCommunityBannerImageMutation,
    UploadCommunityBannerImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadCommunityBannerImageMutation,
    UploadCommunityBannerImageMutationVariables
  >(UploadCommunityBannerImageDocument, options);
}
export type UploadCommunityBannerImageMutationHookResult = ReturnType<
  typeof useUploadCommunityBannerImageMutation
>;
export type UploadCommunityBannerImageMutationResult =
  Apollo.MutationResult<UploadCommunityBannerImageMutation>;
export type UploadCommunityBannerImageMutationOptions =
  Apollo.BaseMutationOptions<
    UploadCommunityBannerImageMutation,
    UploadCommunityBannerImageMutationVariables
  >;
export const DeleteCreatorAvatarImageDocument = gql`
  mutation DeleteCreatorAvatarImage($communityId: String!) {
    deleteCreatorAvatarImage(id: $communityId) {
      creatorAvatar {
        imageUrl
      }
    }
  }
`;
export type DeleteCreatorAvatarImageMutationFn = Apollo.MutationFunction<
  DeleteCreatorAvatarImageMutation,
  DeleteCreatorAvatarImageMutationVariables
>;

/**
 * __useDeleteCreatorAvatarImageMutation__
 *
 * To run a mutation, you first call `useDeleteCreatorAvatarImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreatorAvatarImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreatorAvatarImageMutation, { data, loading, error }] = useDeleteCreatorAvatarImageMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useDeleteCreatorAvatarImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCreatorAvatarImageMutation,
    DeleteCreatorAvatarImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCreatorAvatarImageMutation,
    DeleteCreatorAvatarImageMutationVariables
  >(DeleteCreatorAvatarImageDocument, options);
}
export type DeleteCreatorAvatarImageMutationHookResult = ReturnType<
  typeof useDeleteCreatorAvatarImageMutation
>;
export type DeleteCreatorAvatarImageMutationResult =
  Apollo.MutationResult<DeleteCreatorAvatarImageMutation>;
export type DeleteCreatorAvatarImageMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCreatorAvatarImageMutation,
    DeleteCreatorAvatarImageMutationVariables
  >;
export const DeleteCommunityLogoImageDocument = gql`
  mutation DeleteCommunityLogoImage($communityId: String!) {
    deleteCommunityLogoImage(id: $communityId) {
      imageUrl
    }
  }
`;
export type DeleteCommunityLogoImageMutationFn = Apollo.MutationFunction<
  DeleteCommunityLogoImageMutation,
  DeleteCommunityLogoImageMutationVariables
>;

/**
 * __useDeleteCommunityLogoImageMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityLogoImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityLogoImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityLogoImageMutation, { data, loading, error }] = useDeleteCommunityLogoImageMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useDeleteCommunityLogoImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommunityLogoImageMutation,
    DeleteCommunityLogoImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCommunityLogoImageMutation,
    DeleteCommunityLogoImageMutationVariables
  >(DeleteCommunityLogoImageDocument, options);
}
export type DeleteCommunityLogoImageMutationHookResult = ReturnType<
  typeof useDeleteCommunityLogoImageMutation
>;
export type DeleteCommunityLogoImageMutationResult =
  Apollo.MutationResult<DeleteCommunityLogoImageMutation>;
export type DeleteCommunityLogoImageMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCommunityLogoImageMutation,
    DeleteCommunityLogoImageMutationVariables
  >;
export const DeleteCommunityBannerImageDocument = gql`
  mutation DeleteCommunityBannerImage($communityId: String!) {
    deleteCommunityBannerImage(id: $communityId) {
      bannerImageUrl
    }
  }
`;
export type DeleteCommunityBannerImageMutationFn = Apollo.MutationFunction<
  DeleteCommunityBannerImageMutation,
  DeleteCommunityBannerImageMutationVariables
>;

/**
 * __useDeleteCommunityBannerImageMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityBannerImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityBannerImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityBannerImageMutation, { data, loading, error }] = useDeleteCommunityBannerImageMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useDeleteCommunityBannerImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommunityBannerImageMutation,
    DeleteCommunityBannerImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCommunityBannerImageMutation,
    DeleteCommunityBannerImageMutationVariables
  >(DeleteCommunityBannerImageDocument, options);
}
export type DeleteCommunityBannerImageMutationHookResult = ReturnType<
  typeof useDeleteCommunityBannerImageMutation
>;
export type DeleteCommunityBannerImageMutationResult =
  Apollo.MutationResult<DeleteCommunityBannerImageMutation>;
export type DeleteCommunityBannerImageMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCommunityBannerImageMutation,
    DeleteCommunityBannerImageMutationVariables
  >;
export const CreateCommunityDocument = gql`
  mutation CreateCommunity($data: CreateCommunityInput!) {
    createCommunity(data: $data) {
      id
      slug
    }
  }
`;
export type CreateCommunityMutationFn = Apollo.MutationFunction<
  CreateCommunityMutation,
  CreateCommunityMutationVariables
>;

/**
 * __useCreateCommunityMutation__
 *
 * To run a mutation, you first call `useCreateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityMutation, { data, loading, error }] = useCreateCommunityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommunityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommunityMutation,
    CreateCommunityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCommunityMutation,
    CreateCommunityMutationVariables
  >(CreateCommunityDocument, options);
}
export type CreateCommunityMutationHookResult = ReturnType<
  typeof useCreateCommunityMutation
>;
export type CreateCommunityMutationResult =
  Apollo.MutationResult<CreateCommunityMutation>;
export type CreateCommunityMutationOptions = Apollo.BaseMutationOptions<
  CreateCommunityMutation,
  CreateCommunityMutationVariables
>;
export const LaunchCommunityDocument = gql`
  mutation LaunchCommunity($launchCommunityId: String!) {
    launchCommunity(id: $launchCommunityId) {
      id
      slug
    }
  }
`;
export type LaunchCommunityMutationFn = Apollo.MutationFunction<
  LaunchCommunityMutation,
  LaunchCommunityMutationVariables
>;

/**
 * __useLaunchCommunityMutation__
 *
 * To run a mutation, you first call `useLaunchCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchCommunityMutation, { data, loading, error }] = useLaunchCommunityMutation({
 *   variables: {
 *      launchCommunityId: // value for 'launchCommunityId'
 *   },
 * });
 */
export function useLaunchCommunityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LaunchCommunityMutation,
    LaunchCommunityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LaunchCommunityMutation,
    LaunchCommunityMutationVariables
  >(LaunchCommunityDocument, options);
}
export type LaunchCommunityMutationHookResult = ReturnType<
  typeof useLaunchCommunityMutation
>;
export type LaunchCommunityMutationResult =
  Apollo.MutationResult<LaunchCommunityMutation>;
export type LaunchCommunityMutationOptions = Apollo.BaseMutationOptions<
  LaunchCommunityMutation,
  LaunchCommunityMutationVariables
>;
export const PrepareForMintDocument = gql`
  mutation PrepareForMint($communityId: String!) {
    prepareForMint(id: $communityId) {
      taskId
    }
  }
`;
export type PrepareForMintMutationFn = Apollo.MutationFunction<
  PrepareForMintMutation,
  PrepareForMintMutationVariables
>;

/**
 * __usePrepareForMintMutation__
 *
 * To run a mutation, you first call `usePrepareForMintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareForMintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareForMintMutation, { data, loading, error }] = usePrepareForMintMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function usePrepareForMintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PrepareForMintMutation,
    PrepareForMintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PrepareForMintMutation,
    PrepareForMintMutationVariables
  >(PrepareForMintDocument, options);
}
export type PrepareForMintMutationHookResult = ReturnType<
  typeof usePrepareForMintMutation
>;
export type PrepareForMintMutationResult =
  Apollo.MutationResult<PrepareForMintMutation>;
export type PrepareForMintMutationOptions = Apollo.BaseMutationOptions<
  PrepareForMintMutation,
  PrepareForMintMutationVariables
>;
export const UpdateCreatedCommunityThemeDocument = gql`
  mutation UpdateCreatedCommunityTheme(
    $theme: InputWebsiteTheme!
    $communityId: String!
  ) {
    updateCreatedCommunityTheme(theme: $theme, communityId: $communityId) {
      ...WebsiteTheme
    }
  }
  ${WebsiteThemeFragmentDoc}
`;
export type UpdateCreatedCommunityThemeMutationFn = Apollo.MutationFunction<
  UpdateCreatedCommunityThemeMutation,
  UpdateCreatedCommunityThemeMutationVariables
>;

/**
 * __useUpdateCreatedCommunityThemeMutation__
 *
 * To run a mutation, you first call `useUpdateCreatedCommunityThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreatedCommunityThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreatedCommunityThemeMutation, { data, loading, error }] = useUpdateCreatedCommunityThemeMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useUpdateCreatedCommunityThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCreatedCommunityThemeMutation,
    UpdateCreatedCommunityThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCreatedCommunityThemeMutation,
    UpdateCreatedCommunityThemeMutationVariables
  >(UpdateCreatedCommunityThemeDocument, options);
}
export type UpdateCreatedCommunityThemeMutationHookResult = ReturnType<
  typeof useUpdateCreatedCommunityThemeMutation
>;
export type UpdateCreatedCommunityThemeMutationResult =
  Apollo.MutationResult<UpdateCreatedCommunityThemeMutation>;
export type UpdateCreatedCommunityThemeMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCreatedCommunityThemeMutation,
    UpdateCreatedCommunityThemeMutationVariables
  >;
export const UpdateGeneralInfoDocument = gql`
  mutation UpdateGeneralInfo(
    $data: UpdateGeneralInfoInput!
    $updateGeneralInfoId: String!
  ) {
    updateGeneralInfo(data: $data, id: $updateGeneralInfoId) {
      name
      slug
      creatorAvatar {
        name
        imageUrl
      }
      description
      imageUrl
      bannerImageUrl
      contactEmail
    }
  }
`;
export type UpdateGeneralInfoMutationFn = Apollo.MutationFunction<
  UpdateGeneralInfoMutation,
  UpdateGeneralInfoMutationVariables
>;

/**
 * __useUpdateGeneralInfoMutation__
 *
 * To run a mutation, you first call `useUpdateGeneralInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGeneralInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGeneralInfoMutation, { data, loading, error }] = useUpdateGeneralInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *      updateGeneralInfoId: // value for 'updateGeneralInfoId'
 *   },
 * });
 */
export function useUpdateGeneralInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGeneralInfoMutation,
    UpdateGeneralInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGeneralInfoMutation,
    UpdateGeneralInfoMutationVariables
  >(UpdateGeneralInfoDocument, options);
}
export type UpdateGeneralInfoMutationHookResult = ReturnType<
  typeof useUpdateGeneralInfoMutation
>;
export type UpdateGeneralInfoMutationResult =
  Apollo.MutationResult<UpdateGeneralInfoMutation>;
export type UpdateGeneralInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateGeneralInfoMutation,
  UpdateGeneralInfoMutationVariables
>;
export const UpdateShopifyCollectionBuyButtonDocument = gql`
  mutation UpdateShopifyCollectionBuyButton(
    $communityId: String!
    $input: UpdateCollectionBuyButtonInput!
  ) {
    updateShopifyCollectionBuyButton(communityId: $communityId, input: $input) {
      communityId
      collectionComponentDivId
      domain
      storefrontAccessToken
      collectionId
    }
  }
`;
export type UpdateShopifyCollectionBuyButtonMutationFn =
  Apollo.MutationFunction<
    UpdateShopifyCollectionBuyButtonMutation,
    UpdateShopifyCollectionBuyButtonMutationVariables
  >;

/**
 * __useUpdateShopifyCollectionBuyButtonMutation__
 *
 * To run a mutation, you first call `useUpdateShopifyCollectionBuyButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopifyCollectionBuyButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopifyCollectionBuyButtonMutation, { data, loading, error }] = useUpdateShopifyCollectionBuyButtonMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopifyCollectionBuyButtonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShopifyCollectionBuyButtonMutation,
    UpdateShopifyCollectionBuyButtonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShopifyCollectionBuyButtonMutation,
    UpdateShopifyCollectionBuyButtonMutationVariables
  >(UpdateShopifyCollectionBuyButtonDocument, options);
}
export type UpdateShopifyCollectionBuyButtonMutationHookResult = ReturnType<
  typeof useUpdateShopifyCollectionBuyButtonMutation
>;
export type UpdateShopifyCollectionBuyButtonMutationResult =
  Apollo.MutationResult<UpdateShopifyCollectionBuyButtonMutation>;
export type UpdateShopifyCollectionBuyButtonMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateShopifyCollectionBuyButtonMutation,
    UpdateShopifyCollectionBuyButtonMutationVariables
  >;
export const DeleteShopifyCollectionBuyButtonDocument = gql`
  mutation DeleteShopifyCollectionBuyButton($communityId: String!) {
    deleteShopifyCollectionBuyButton(communityId: $communityId) {
      communityId
      success
    }
  }
`;
export type DeleteShopifyCollectionBuyButtonMutationFn =
  Apollo.MutationFunction<
    DeleteShopifyCollectionBuyButtonMutation,
    DeleteShopifyCollectionBuyButtonMutationVariables
  >;

/**
 * __useDeleteShopifyCollectionBuyButtonMutation__
 *
 * To run a mutation, you first call `useDeleteShopifyCollectionBuyButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopifyCollectionBuyButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopifyCollectionBuyButtonMutation, { data, loading, error }] = useDeleteShopifyCollectionBuyButtonMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useDeleteShopifyCollectionBuyButtonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShopifyCollectionBuyButtonMutation,
    DeleteShopifyCollectionBuyButtonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteShopifyCollectionBuyButtonMutation,
    DeleteShopifyCollectionBuyButtonMutationVariables
  >(DeleteShopifyCollectionBuyButtonDocument, options);
}
export type DeleteShopifyCollectionBuyButtonMutationHookResult = ReturnType<
  typeof useDeleteShopifyCollectionBuyButtonMutation
>;
export type DeleteShopifyCollectionBuyButtonMutationResult =
  Apollo.MutationResult<DeleteShopifyCollectionBuyButtonMutation>;
export type DeleteShopifyCollectionBuyButtonMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteShopifyCollectionBuyButtonMutation,
    DeleteShopifyCollectionBuyButtonMutationVariables
  >;
export const CreatedCommunityBySlugDocument = gql`
  query CreatedCommunityBySlug(
    $slug: String!
    $type: String!
    $useChain: Boolean!
    $active: Boolean!
  ) {
    createdCommunityBySlug(slug: $slug) {
      id
      name
      slug
      status
      polygonContract {
        contractAddress
      }
      creatorAvatar {
        name
        imageUrl
      }
      description
      imageUrl
      bannerImageUrl
      royaltySplitAddress
      contactEmail
      tokens(type: $type, useChain: $useChain) {
        tokenId
        tokenStatus
      }
      offers(active: $active) {
        id
        active
        tokens {
          tokenId
          tokenType
        }
      }
      ...CreatorShopifyData
      ...CreatorDiscordData
    }
  }
  ${CreatorShopifyDataFragmentDoc}
  ${CreatorDiscordDataFragmentDoc}
`;

/**
 * __useCreatedCommunityBySlugQuery__
 *
 * To run a query within a React component, call `useCreatedCommunityBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatedCommunityBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedCommunityBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      type: // value for 'type'
 *      useChain: // value for 'useChain'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useCreatedCommunityBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreatedCommunityBySlugQuery,
    CreatedCommunityBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreatedCommunityBySlugQuery,
    CreatedCommunityBySlugQueryVariables
  >(CreatedCommunityBySlugDocument, options);
}
export function useCreatedCommunityBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreatedCommunityBySlugQuery,
    CreatedCommunityBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreatedCommunityBySlugQuery,
    CreatedCommunityBySlugQueryVariables
  >(CreatedCommunityBySlugDocument, options);
}
export type CreatedCommunityBySlugQueryHookResult = ReturnType<
  typeof useCreatedCommunityBySlugQuery
>;
export type CreatedCommunityBySlugLazyQueryHookResult = ReturnType<
  typeof useCreatedCommunityBySlugLazyQuery
>;
export type CreatedCommunityBySlugQueryResult = Apollo.QueryResult<
  CreatedCommunityBySlugQuery,
  CreatedCommunityBySlugQueryVariables
>;
export const OnCommunityContractDeployedDocument = gql`
  subscription OnCommunityContractDeployed($communityId: String!) {
    community(communityId: $communityId, type: CONTRACT) {
      communityId
      type
      status
    }
  }
`;

/**
 * __useOnCommunityContractDeployedSubscription__
 *
 * To run a query within a React component, call `useOnCommunityContractDeployedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCommunityContractDeployedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCommunityContractDeployedSubscription({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useOnCommunityContractDeployedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnCommunityContractDeployedSubscription,
    OnCommunityContractDeployedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnCommunityContractDeployedSubscription,
    OnCommunityContractDeployedSubscriptionVariables
  >(OnCommunityContractDeployedDocument, options);
}
export type OnCommunityContractDeployedSubscriptionHookResult = ReturnType<
  typeof useOnCommunityContractDeployedSubscription
>;
export type OnCommunityContractDeployedSubscriptionResult =
  Apollo.SubscriptionResult<OnCommunityContractDeployedSubscription>;
export const CreatedCommunityThemeBySlugDocument = gql`
  query CreatedCommunityThemeBySlug($slug: String!) {
    createdCommunityBySlug(slug: $slug) {
      id
      websiteTheme {
        ...WebsiteTheme
      }
    }
  }
  ${WebsiteThemeFragmentDoc}
`;

/**
 * __useCreatedCommunityThemeBySlugQuery__
 *
 * To run a query within a React component, call `useCreatedCommunityThemeBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatedCommunityThemeBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedCommunityThemeBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCreatedCommunityThemeBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreatedCommunityThemeBySlugQuery,
    CreatedCommunityThemeBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreatedCommunityThemeBySlugQuery,
    CreatedCommunityThemeBySlugQueryVariables
  >(CreatedCommunityThemeBySlugDocument, options);
}
export function useCreatedCommunityThemeBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreatedCommunityThemeBySlugQuery,
    CreatedCommunityThemeBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreatedCommunityThemeBySlugQuery,
    CreatedCommunityThemeBySlugQueryVariables
  >(CreatedCommunityThemeBySlugDocument, options);
}
export type CreatedCommunityThemeBySlugQueryHookResult = ReturnType<
  typeof useCreatedCommunityThemeBySlugQuery
>;
export type CreatedCommunityThemeBySlugLazyQueryHookResult = ReturnType<
  typeof useCreatedCommunityThemeBySlugLazyQuery
>;
export type CreatedCommunityThemeBySlugQueryResult = Apollo.QueryResult<
  CreatedCommunityThemeBySlugQuery,
  CreatedCommunityThemeBySlugQueryVariables
>;
export const CreatedCommunityIncomeDocument = gql`
  query CreatedCommunityIncome($slug: String!) {
    createdCommunityBySlug(slug: $slug) {
      id
      creatorWethBalance {
        coin
        value
        inUSD
      }
      secondaryEarnings {
        coin
        value
        inUSD
      }
      royalties {
        balance {
          coin
          value
          inUSD
        }
        allowToWithdraw
      }
      creatorStripeBalance {
        currency
        total
        processing
      }
    }
  }
`;

/**
 * __useCreatedCommunityIncomeQuery__
 *
 * To run a query within a React component, call `useCreatedCommunityIncomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatedCommunityIncomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedCommunityIncomeQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCreatedCommunityIncomeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreatedCommunityIncomeQuery,
    CreatedCommunityIncomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreatedCommunityIncomeQuery,
    CreatedCommunityIncomeQueryVariables
  >(CreatedCommunityIncomeDocument, options);
}
export function useCreatedCommunityIncomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreatedCommunityIncomeQuery,
    CreatedCommunityIncomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreatedCommunityIncomeQuery,
    CreatedCommunityIncomeQueryVariables
  >(CreatedCommunityIncomeDocument, options);
}
export type CreatedCommunityIncomeQueryHookResult = ReturnType<
  typeof useCreatedCommunityIncomeQuery
>;
export type CreatedCommunityIncomeLazyQueryHookResult = ReturnType<
  typeof useCreatedCommunityIncomeLazyQuery
>;
export type CreatedCommunityIncomeQueryResult = Apollo.QueryResult<
  CreatedCommunityIncomeQuery,
  CreatedCommunityIncomeQueryVariables
>;
export const WithdrawRoyaltiesDocument = gql`
  mutation WithdrawRoyalties($communityId: String!, $currency: String!) {
    withdrawCreatorRoyalties(communityId: $communityId, currency: $currency) {
      taskId
    }
  }
`;
export type WithdrawRoyaltiesMutationFn = Apollo.MutationFunction<
  WithdrawRoyaltiesMutation,
  WithdrawRoyaltiesMutationVariables
>;

/**
 * __useWithdrawRoyaltiesMutation__
 *
 * To run a mutation, you first call `useWithdrawRoyaltiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawRoyaltiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawRoyaltiesMutation, { data, loading, error }] = useWithdrawRoyaltiesMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useWithdrawRoyaltiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawRoyaltiesMutation,
    WithdrawRoyaltiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WithdrawRoyaltiesMutation,
    WithdrawRoyaltiesMutationVariables
  >(WithdrawRoyaltiesDocument, options);
}
export type WithdrawRoyaltiesMutationHookResult = ReturnType<
  typeof useWithdrawRoyaltiesMutation
>;
export type WithdrawRoyaltiesMutationResult =
  Apollo.MutationResult<WithdrawRoyaltiesMutation>;
export type WithdrawRoyaltiesMutationOptions = Apollo.BaseMutationOptions<
  WithdrawRoyaltiesMutation,
  WithdrawRoyaltiesMutationVariables
>;
export const PendingWithdrawalsDocument = gql`
  query PendingWithdrawals($communityId: String!) {
    pendingWithdrawals(communityId: $communityId) {
      taskId
      currency
    }
  }
`;

/**
 * __usePendingWithdrawalsQuery__
 *
 * To run a query within a React component, call `usePendingWithdrawalsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingWithdrawalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingWithdrawalsQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function usePendingWithdrawalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PendingWithdrawalsQuery,
    PendingWithdrawalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PendingWithdrawalsQuery,
    PendingWithdrawalsQueryVariables
  >(PendingWithdrawalsDocument, options);
}
export function usePendingWithdrawalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingWithdrawalsQuery,
    PendingWithdrawalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PendingWithdrawalsQuery,
    PendingWithdrawalsQueryVariables
  >(PendingWithdrawalsDocument, options);
}
export type PendingWithdrawalsQueryHookResult = ReturnType<
  typeof usePendingWithdrawalsQuery
>;
export type PendingWithdrawalsLazyQueryHookResult = ReturnType<
  typeof usePendingWithdrawalsLazyQuery
>;
export type PendingWithdrawalsQueryResult = Apollo.QueryResult<
  PendingWithdrawalsQuery,
  PendingWithdrawalsQueryVariables
>;
export const CreatedCommunityTokensBySlugDocument = gql`
  query CreatedCommunityTokensBySlug(
    $slug: String!
    $type: String!
    $useChain: Boolean!
  ) {
    createdCommunityBySlug(slug: $slug) {
      id
      tokens(type: $type, useChain: $useChain) {
        communityAddress
        tokenId
        tokenStatus
        name
        uri(useChain: $useChain)
        description
        tokenType
        supply
        benefits {
          type
        }
        creatorBalance
        taskId
      }
      offers {
        id
        communityId
        tokenIds
        name
        price
        currency
        active
        onAboutPage
        onPrivatePage
      }
    }
  }
`;

/**
 * __useCreatedCommunityTokensBySlugQuery__
 *
 * To run a query within a React component, call `useCreatedCommunityTokensBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatedCommunityTokensBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedCommunityTokensBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      type: // value for 'type'
 *      useChain: // value for 'useChain'
 *   },
 * });
 */
export function useCreatedCommunityTokensBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreatedCommunityTokensBySlugQuery,
    CreatedCommunityTokensBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreatedCommunityTokensBySlugQuery,
    CreatedCommunityTokensBySlugQueryVariables
  >(CreatedCommunityTokensBySlugDocument, options);
}
export function useCreatedCommunityTokensBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreatedCommunityTokensBySlugQuery,
    CreatedCommunityTokensBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreatedCommunityTokensBySlugQuery,
    CreatedCommunityTokensBySlugQueryVariables
  >(CreatedCommunityTokensBySlugDocument, options);
}
export type CreatedCommunityTokensBySlugQueryHookResult = ReturnType<
  typeof useCreatedCommunityTokensBySlugQuery
>;
export type CreatedCommunityTokensBySlugLazyQueryHookResult = ReturnType<
  typeof useCreatedCommunityTokensBySlugLazyQuery
>;
export type CreatedCommunityTokensBySlugQueryResult = Apollo.QueryResult<
  CreatedCommunityTokensBySlugQuery,
  CreatedCommunityTokensBySlugQueryVariables
>;
export const TokenDetailsDocument = gql`
  query TokenDetails($slug: String, $useChain: Boolean!, $tokenId: Int!) {
    community(slug: $slug) {
      id
      polygonContract {
        contractAddress
      }
      creatorAvatar {
        name
      }
      token(useChain: $useChain, tokenId: $tokenId) {
        tokenId
        name
        uri(useChain: $useChain)
        animationUri
        description
        tokenType
        supply
        creatorBalance
        benefits {
          type
          customName
        }
        creatorBalance
      }
    }
  }
`;

/**
 * __useTokenDetailsQuery__
 *
 * To run a query within a React component, call `useTokenDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      useChain: // value for 'useChain'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useTokenDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TokenDetailsQuery,
    TokenDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TokenDetailsQuery, TokenDetailsQueryVariables>(
    TokenDetailsDocument,
    options
  );
}
export function useTokenDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TokenDetailsQuery,
    TokenDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TokenDetailsQuery, TokenDetailsQueryVariables>(
    TokenDetailsDocument,
    options
  );
}
export type TokenDetailsQueryHookResult = ReturnType<
  typeof useTokenDetailsQuery
>;
export type TokenDetailsLazyQueryHookResult = ReturnType<
  typeof useTokenDetailsLazyQuery
>;
export type TokenDetailsQueryResult = Apollo.QueryResult<
  TokenDetailsQuery,
  TokenDetailsQueryVariables
>;
export const CreatorCommunityMembersDocument = gql`
  query CreatorCommunityMembers(
    $communityId: String!
    $page: Int!
    $pageSize: Int!
    $sort: CreatorCommunityMembersSort!
    $email: String
  ) {
    creatorCommunityMembers(
      communityId: $communityId
      page: $page
      pageSize: $pageSize
      sort: $sort
      email: $email
    ) {
      amount
      currentMonthAmount
      resultsCount
      members {
        email
        dateJoined
        discordUserName
        tokensHeldNames
        blockchainAddress
      }
    }
  }
`;

/**
 * __useCreatorCommunityMembersQuery__
 *
 * To run a query within a React component, call `useCreatorCommunityMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorCommunityMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorCommunityMembersQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      sort: // value for 'sort'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreatorCommunityMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreatorCommunityMembersQuery,
    CreatorCommunityMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreatorCommunityMembersQuery,
    CreatorCommunityMembersQueryVariables
  >(CreatorCommunityMembersDocument, options);
}
export function useCreatorCommunityMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreatorCommunityMembersQuery,
    CreatorCommunityMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreatorCommunityMembersQuery,
    CreatorCommunityMembersQueryVariables
  >(CreatorCommunityMembersDocument, options);
}
export type CreatorCommunityMembersQueryHookResult = ReturnType<
  typeof useCreatorCommunityMembersQuery
>;
export type CreatorCommunityMembersLazyQueryHookResult = ReturnType<
  typeof useCreatorCommunityMembersLazyQuery
>;
export type CreatorCommunityMembersQueryResult = Apollo.QueryResult<
  CreatorCommunityMembersQuery,
  CreatorCommunityMembersQueryVariables
>;
export const PendingTokenDetailsDocument = gql`
  query PendingTokenDetails($slug: String!, $taskId: String!) {
    createdCommunityBySlug(slug: $slug) {
      id
      blockchainTaskToken(taskId: $taskId) {
        tokenId
        tokenStatus
        communityAddress
        taskId
        name
        uri
        animationUri
        description
        tokenType
        supply
        benefits {
          type
          customName
        }
        creatorBalance
        mintedAsNonTransferable
      }
    }
  }
`;

/**
 * __usePendingTokenDetailsQuery__
 *
 * To run a query within a React component, call `usePendingTokenDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingTokenDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingTokenDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function usePendingTokenDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PendingTokenDetailsQuery,
    PendingTokenDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PendingTokenDetailsQuery,
    PendingTokenDetailsQueryVariables
  >(PendingTokenDetailsDocument, options);
}
export function usePendingTokenDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingTokenDetailsQuery,
    PendingTokenDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PendingTokenDetailsQuery,
    PendingTokenDetailsQueryVariables
  >(PendingTokenDetailsDocument, options);
}
export type PendingTokenDetailsQueryHookResult = ReturnType<
  typeof usePendingTokenDetailsQuery
>;
export type PendingTokenDetailsLazyQueryHookResult = ReturnType<
  typeof usePendingTokenDetailsLazyQuery
>;
export type PendingTokenDetailsQueryResult = Apollo.QueryResult<
  PendingTokenDetailsQuery,
  PendingTokenDetailsQueryVariables
>;
export const MintedTokenDetailsDocument = gql`
  query MintedTokenDetails(
    $slug: String!
    $useChain: Boolean!
    $tokenId: Int!
  ) {
    createdCommunityBySlug(slug: $slug) {
      id
      claimPages(tokenId: $tokenId) {
        id
        active
        tokensMetadata {
          quantity
          maxQuantity
          totalGrantedQuantity
        }
        contestPage {
          title
        }
      }
      token(useChain: $useChain, tokenId: $tokenId) {
        tokenStatus
        communityAddress
        tokenId
        name
        uri(useChain: $useChain)
        animationUri
        description
        tokenType
        supply
        benefits {
          type
          customName
        }
        creatorBalance
      }
      offers {
        id
        communityId
        tokenIds
        name
        price
        currency
        active
        onAboutPage
        onPrivatePage
        crypto {
          inUSD
        }
      }
    }
  }
`;

/**
 * __useMintedTokenDetailsQuery__
 *
 * To run a query within a React component, call `useMintedTokenDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMintedTokenDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMintedTokenDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      useChain: // value for 'useChain'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useMintedTokenDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MintedTokenDetailsQuery,
    MintedTokenDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MintedTokenDetailsQuery,
    MintedTokenDetailsQueryVariables
  >(MintedTokenDetailsDocument, options);
}
export function useMintedTokenDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MintedTokenDetailsQuery,
    MintedTokenDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MintedTokenDetailsQuery,
    MintedTokenDetailsQueryVariables
  >(MintedTokenDetailsDocument, options);
}
export type MintedTokenDetailsQueryHookResult = ReturnType<
  typeof useMintedTokenDetailsQuery
>;
export type MintedTokenDetailsLazyQueryHookResult = ReturnType<
  typeof useMintedTokenDetailsLazyQuery
>;
export type MintedTokenDetailsQueryResult = Apollo.QueryResult<
  MintedTokenDetailsQuery,
  MintedTokenDetailsQueryVariables
>;
export const SyncUserBenefitsDocument = gql`
  mutation SyncUserBenefits {
    syncUserBenefits {
      successful
      failed
      total
    }
  }
`;
export type SyncUserBenefitsMutationFn = Apollo.MutationFunction<
  SyncUserBenefitsMutation,
  SyncUserBenefitsMutationVariables
>;

/**
 * __useSyncUserBenefitsMutation__
 *
 * To run a mutation, you first call `useSyncUserBenefitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUserBenefitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUserBenefitsMutation, { data, loading, error }] = useSyncUserBenefitsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncUserBenefitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncUserBenefitsMutation,
    SyncUserBenefitsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncUserBenefitsMutation,
    SyncUserBenefitsMutationVariables
  >(SyncUserBenefitsDocument, options);
}
export type SyncUserBenefitsMutationHookResult = ReturnType<
  typeof useSyncUserBenefitsMutation
>;
export type SyncUserBenefitsMutationResult =
  Apollo.MutationResult<SyncUserBenefitsMutation>;
export type SyncUserBenefitsMutationOptions = Apollo.BaseMutationOptions<
  SyncUserBenefitsMutation,
  SyncUserBenefitsMutationVariables
>;
