import React, { FC, memo } from "react";

import Anchor from "components/Anchor";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SUPPORT_ARTICLES } from "config";

import WithContract from "./WithContract/WithContract";
import WithNoContract from "./WithNoContract/WithNoContract";
import * as styles from "./styles";

export interface Props {
  contractAddress?: string | null;
  royaltySplitAddress?: string | null;
}

const Blockchain: FC<Props> = ({ contractAddress, royaltySplitAddress }) => {
  return (
    <div css={styles.container}>
      <Title size="medium" extraCss={styles.title}>
        Blockchain
      </Title>
      <div>
        <Title size="xsmall">Contract Address</Title>
        <Text size="small" extraCss={styles.description}>
          <Anchor
            href={SUPPORT_ARTICLES.LEARN_MORE_CONTRACTS}
            color="accent"
            target="_blank"
            rel="noreferrer"
          >
            Learn more contracts
          </Anchor>
          , including how to verify your contract and view it on PolygonScan.
        </Text>
        {!contractAddress && <WithNoContract />}
        {contractAddress && <WithContract contractAddress={contractAddress} />}
      </div>
      {royaltySplitAddress && (
        <div css={styles.section}>
          <Title size="xsmall" extraCss={styles.description}>
            Royalty Address
          </Title>
          <WithContract contractAddress={royaltySplitAddress} />
        </div>
      )}
    </div>
  );
};

export default memo(Blockchain);
