import React, { memo, ReactNode, useEffect } from "react";

import {
  NavLink,
  Outlet,
  useNavigate,
  useOutlet,
  useParams,
} from "react-router-dom";

import { NotificationStatus } from "apollo/graphql.generated";
import useCreatedCommunity from "apollo/hooks/useCreatedCommunity";
import {
  CreatedCommunityBySlugQuery,
  useOnCommunityContractDeployedSubscription,
} from "apollo/queries";
import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
import { ReactComponent as IncomeIcon } from "assets/icons/income.svg";
import { ReactComponent as MembersIcon } from "assets/icons/members.svg";
import { ReactComponent as PostsIcon } from "assets/icons/posts.svg";
import { ReactComponent as QuestionMarkIcon } from "assets/icons/question-mark-icon.svg";
import { ReactComponent as ThemeIcon } from "assets/icons/theme.svg";
import { ReactComponent as TokensIcon } from "assets/icons/tokens.svg";
import { ReactComponent as ViewIcon } from "assets/icons/view.svg";
import GlobalStatusBanner from "components/Banners/GlobalStatusBanner";
import Layout from "components/Layout";
import GenericError from "components/Modals/GenericError";
import SidebarNav from "components/SidebarNav";
import { APP_URL, EXTERNAL_URL, FEATURE_FLAG } from "config";
import NotFound from "containers/Error/NotFound";
import useAppLocation from "hooks/useAppLocation";
import useFeatureFlag from "hooks/useFeatureFlag";

import * as styles from "./styles";

export type OutletContextType = {
  communityData?: CreatedCommunityBySlugQuery["createdCommunityBySlug"];
  communitySlug?: string;
};

export type Props = {
  children: ReactNode;
};

const checkActive = ({ isActive }: { isActive: boolean }) =>
  isActive ? "active" : "";

const DeployingContract = () => (
  <GlobalStatusBanner
    background="light"
    messages={[
      {
        text: "Your community contract is currently being deployed. This usually takes just a couple minutes.",
      },
    ]}
  />
);

const useLogic = (communitySlug: string | undefined) => {
  const {
    isDeployingContract,
    error,
    refetch,
    loading,
    data: communityData,
  } = useCreatedCommunity(communitySlug);

  const isError = !!error;
  const isNotFound = !loading && communityData?.createdCommunityBySlug === null;

  useOnCommunityContractDeployedSubscription({
    variables: {
      communityId: communityData?.createdCommunityBySlug?.id || "",
    },
    // Skip subscription if community id is not fetched yet or contract is already deployed.
    skip: !communityData?.createdCommunityBySlug?.id || !isDeployingContract,
    onSubscriptionData: (dataCallback) => {
      if (
        dataCallback.subscriptionData.data?.community.status ===
        NotificationStatus.SUCCESS
      ) {
        refetch();
      }
    },
  });

  return {
    isError,
    isDeployingContract,
    isNotFound,
    isLoading: loading,
    communityData: communityData?.createdCommunityBySlug,
  };
};

const EditCommunityLayout = () => {
  const { getEditCommunityURL, getCommunityURLBySlug } = useAppLocation();
  const { communitySlug } = useParams();
  const outlet = useOutlet();
  const navigate = useNavigate();

  const showPages = useFeatureFlag(FEATURE_FLAG.ENABLE_CREATOR_DASHBOARD);
  const enableCreatorDashboardMembersTab = useFeatureFlag(
    FEATURE_FLAG.ENABLE_CREATOR_DASHBOARD_MEMBERS_TAB
  );
  const enableCreatorDashboardThemeTab = useFeatureFlag(
    FEATURE_FLAG.ENABLE_CREATOR_DASHBOARD_THEME_TAB
  );
  const enableCreatorDashboardIncomeTab = useFeatureFlag(
    FEATURE_FLAG.ENABLE_CREATOR_DASHBOARD_INCOME_TAB
  );

  // Force general url open if user access base url
  useEffect(() => {
    if (!outlet) {
      navigate(APP_URL.editCommunity.home, { replace: true });
      return;
    }
  }, [outlet]);

  // Protect route if feature flag is off.
  useEffect(() => {
    if (showPages) return;
    navigate("/", { replace: true });
  }, [showPages]);

  const { isError, isDeployingContract, isNotFound, communityData } =
    useLogic(communitySlug);

  if (isError) {
    return <GenericError />;
  }

  if (!communitySlug || isNotFound) {
    return <NotFound />;
  }

  return (
    <>
      {isDeployingContract && <DeployingContract />}
      <Layout
        css={styles.container}
        backgroundShow={true}
        backgroundColor="primary"
        navLayout="creatorCommunity"
      >
        <div css={styles.sidebar}>
          <SidebarNav>
            <NavLink
              to={getEditCommunityURL(
                communitySlug,
                APP_URL.editCommunity.home
              )}
              className={checkActive}
              end
            >
              <HomeIcon /> Home
            </NavLink>
            <NavLink
              to={getEditCommunityURL(
                communitySlug,
                APP_URL.editCommunity.tokens
              )}
              className={checkActive}
            >
              <TokensIcon />
              Tokens
            </NavLink>
            <NavLink
              to={getEditCommunityURL(
                communitySlug,
                APP_URL.editCommunity.posts
              )}
              className={checkActive}
            >
              <PostsIcon />
              Posts
            </NavLink>
            {enableCreatorDashboardThemeTab && (
              <NavLink
                to={getEditCommunityURL(
                  communitySlug,
                  APP_URL.editCommunity.theme
                )}
                className={checkActive}
              >
                <ThemeIcon />
                Theme
              </NavLink>
            )}
            {enableCreatorDashboardMembersTab && (
              <NavLink
                to={getEditCommunityURL(
                  communitySlug,
                  APP_URL.editCommunity.members
                )}
                className={checkActive}
              >
                <MembersIcon />
                Members
              </NavLink>
            )}
            {enableCreatorDashboardIncomeTab && (
              <NavLink
                to={getEditCommunityURL(
                  communitySlug,
                  APP_URL.editCommunity.income
                )}
                className={checkActive}
              >
                <IncomeIcon />
                Income
              </NavLink>
            )}
            <a
              href={getCommunityURLBySlug(communitySlug)}
              target="_blank"
              rel="noreferrer"
            >
              <ViewIcon />
              View Community
            </a>
            <a href={EXTERNAL_URL.SUPPORT} target="_blank" rel="noreferrer">
              <QuestionMarkIcon />
              Help Center
            </a>
          </SidebarNav>
        </div>

        <div css={styles.layout}>
          <div css={styles.layoutWrapper}>
            {communityData && (
              <Outlet context={{ communityData, communitySlug }} />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default memo(EditCommunityLayout);
