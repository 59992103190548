const datesDifference = (startTs: number, endTs: number) => {
  let diff = endTs - startTs;

  const MINUTES_MS = 60 * 1000;
  const HOURS_MS = 60 * MINUTES_MS;
  const DAYS_MS = 24 * HOURS_MS;
  const days = Math.floor(diff / DAYS_MS);
  diff -= days * DAYS_MS;
  const hours = Math.floor(diff / HOURS_MS);
  diff -= hours * HOURS_MS;
  const minutes = Math.floor(diff / MINUTES_MS);

  return { days, hours, minutes };
};

export const formatFromNow = (endAt: string) => {
  const now = Date.now();
  const endDate = new Date(endAt).getTime();
  const diff = datesDifference(now, endDate);

  const units = [
    { val: diff.days, label: "d" },
    { val: diff.hours, label: "h" },
    { val: diff.minutes, label: "m" },
  ];

  const result = [];
  let prevZeros = true;
  for (const unit of units) {
    if (!unit.val && prevZeros) {
      continue;
    }
    prevZeros = false;
    result.push(`${unit.val}${unit.label}`);
  }
  return result.join(" ");
};

export const timeSince = (date: Date) => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + "y";
  }

  interval = seconds / 2592000;

  if (interval > 1) {
    return Math.floor(interval) + "mo";
  }

  interval = seconds / 86400;

  if (interval > 1) {
    return Math.floor(interval) + "d";
  }

  interval = seconds / 3600;

  if (interval > 1) {
    return Math.floor(interval) + "h";
  }

  interval = seconds / 60;

  if (interval > 1) {
    return Math.floor(interval) + "m";
  }

  return Math.floor(seconds) + "s";
};

export const formatDateToUserLocale = (timestampMs: number | null) => {
  if (!timestampMs) return;
  let locale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  if (!locale) {
    locale = "default";
  }
  const date = new Date(timestampMs);
  const month = date.toLocaleString(locale, { month: "long" });
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};
