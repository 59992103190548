import * as Types from "../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import { OfferDataFragmentDoc } from "../../apollo/queries/commonFragments.graphql.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type AboutDistributionMethodsQueryVariables = Types.Exact<{
  slug: Types.Maybe<Types.Scalars["String"]>;
}>;

export type AboutDistributionMethodsQuery = {
  community: {
    __typename: "Community";
    id: string;
    polygonContract: {
      __typename: "Contract";
      contractAddress: string | null;
    } | null;
    offers: Array<{
      __typename: "Offer";
      id: string;
      name: string;
      tokenId: number | null;
      price: string;
      currency: string;
      onAboutPage: boolean | null;
      crypto: {
        __typename: "CryptoCurrency";
        coin: string;
        value: string;
        inUSD: number | null;
      } | null;
      tokens: Array<{
        __typename: "Token";
        tokenId: string;
        name: string | null;
        description: string | null;
        uri: string | null;
        animationUri: string | null;
        tokenType: Types.TokenType;
        supply: number | null;
        creatorBalance: number | null;
        benefits: Array<{
          __typename: "TokenBenefit";
          type: Types.BenefitType;
          customName: string | null;
          releasePartyId: string | null;
        }> | null;
      }> | null;
    }>;
  } | null;
};

export type ClaimFreeOfferMutationVariables = Types.Exact<{
  offerId: Types.Scalars["String"];
}>;

export type ClaimFreeOfferMutation = {
  claimFreeOffer: { __typename: "BlockchainTaskRef"; taskId: string };
};

export type OnTokenTransferredSubscriptionVariables = Types.Exact<{
  tokenIds: Types.Maybe<Array<Types.Scalars["Float"]> | Types.Scalars["Float"]>;
  communityId: Types.Scalars["String"];
}>;

export type OnTokenTransferredSubscription = {
  token: {
    __typename: "TokenNotification";
    communityId: string;
    tokenIds: Array<number>;
    taskId: string | null;
    type: Types.TokenNotificationType;
    status: Types.NotificationStatus;
  };
};

export const AboutDistributionMethodsDocument = gql`
  query AboutDistributionMethods($slug: String) {
    community(slug: $slug) {
      id
      polygonContract {
        contractAddress
      }
      offers(onAboutPage: true) {
        ...offerData
      }
    }
  }
  ${OfferDataFragmentDoc}
`;

/**
 * __useAboutDistributionMethodsQuery__
 *
 * To run a query within a React component, call `useAboutDistributionMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutDistributionMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutDistributionMethodsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAboutDistributionMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutDistributionMethodsQuery,
    AboutDistributionMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AboutDistributionMethodsQuery,
    AboutDistributionMethodsQueryVariables
  >(AboutDistributionMethodsDocument, options);
}
export function useAboutDistributionMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutDistributionMethodsQuery,
    AboutDistributionMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AboutDistributionMethodsQuery,
    AboutDistributionMethodsQueryVariables
  >(AboutDistributionMethodsDocument, options);
}
export type AboutDistributionMethodsQueryHookResult = ReturnType<
  typeof useAboutDistributionMethodsQuery
>;
export type AboutDistributionMethodsLazyQueryHookResult = ReturnType<
  typeof useAboutDistributionMethodsLazyQuery
>;
export type AboutDistributionMethodsQueryResult = Apollo.QueryResult<
  AboutDistributionMethodsQuery,
  AboutDistributionMethodsQueryVariables
>;
export const ClaimFreeOfferDocument = gql`
  mutation ClaimFreeOffer($offerId: String!) {
    claimFreeOffer(offerId: $offerId) {
      taskId
    }
  }
`;
export type ClaimFreeOfferMutationFn = Apollo.MutationFunction<
  ClaimFreeOfferMutation,
  ClaimFreeOfferMutationVariables
>;

/**
 * __useClaimFreeOfferMutation__
 *
 * To run a mutation, you first call `useClaimFreeOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimFreeOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimFreeOfferMutation, { data, loading, error }] = useClaimFreeOfferMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useClaimFreeOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimFreeOfferMutation,
    ClaimFreeOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClaimFreeOfferMutation,
    ClaimFreeOfferMutationVariables
  >(ClaimFreeOfferDocument, options);
}
export type ClaimFreeOfferMutationHookResult = ReturnType<
  typeof useClaimFreeOfferMutation
>;
export type ClaimFreeOfferMutationResult =
  Apollo.MutationResult<ClaimFreeOfferMutation>;
export type ClaimFreeOfferMutationOptions = Apollo.BaseMutationOptions<
  ClaimFreeOfferMutation,
  ClaimFreeOfferMutationVariables
>;
export const OnTokenTransferredDocument = gql`
  subscription OnTokenTransferred($tokenIds: [Float!], $communityId: String!) {
    token(type: TRANSFER, tokenIds: $tokenIds, communityId: $communityId) {
      communityId
      tokenIds
      taskId
      type
      status
    }
  }
`;

/**
 * __useOnTokenTransferredSubscription__
 *
 * To run a query within a React component, call `useOnTokenTransferredSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTokenTransferredSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTokenTransferredSubscription({
 *   variables: {
 *      tokenIds: // value for 'tokenIds'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useOnTokenTransferredSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnTokenTransferredSubscription,
    OnTokenTransferredSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnTokenTransferredSubscription,
    OnTokenTransferredSubscriptionVariables
  >(OnTokenTransferredDocument, options);
}
export type OnTokenTransferredSubscriptionHookResult = ReturnType<
  typeof useOnTokenTransferredSubscription
>;
export type OnTokenTransferredSubscriptionResult =
  Apollo.SubscriptionResult<OnTokenTransferredSubscription>;
