import { css, Theme } from "@emotion/react";

import { variables } from "styles/variables";

export const checkoutInitiateGlobalStyles = css`
  body {
    background-color: ${variables.colors.gray1};
  }
`;

export const container = (theme: Theme) => css`
  ${theme.mediaQueries.mediumDown} {
    margin-bottom: 40px;
  }

  ${theme.mediaQueries.smallOnly} {
    max-width: 450px;
  }
`;

export const header = (theme: Theme) => css`
  margin-bottom: 40px;

  h1 {
    margin-top: 4px;
  }

  ${theme.mediaQueries.mediumOnly} {
    margin-bottom: 32px;
  }

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: 20px;
  }
`;

export const content = (theme: Theme) => css`
  display: grid;
  grid-template: "checkout order";
  grid-template-columns: 1fr 396px;
  gap: 44px;

  ${theme.mediaQueries.mediumDown} {
    grid-template:
      "order"
      "checkout";
    grid-template-columns: 1fr;
    gap: 32px;
  }

  ${theme.mediaQueries.smallOnly} {
    gap: 24px;
  }
`;

export const order = () => css`
  grid-area: order;
`;

export const checkout = () => css`
  grid-area: checkout;
`;
