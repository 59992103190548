import { css, Theme } from "@emotion/react";

import { button as baseButton } from "components/Button/styles";

/** Main Component */

export const container = (theme: Theme) => css`
  padding: 24px 40px 32px;
  max-width: 576px;
  width: 100%;

  ${theme.mediaQueries.smallOnly} {
    padding: 12px 20px 20px;
  }

  button {
    width: 100%;
  }
`;

export const title = css`
  margin-bottom: 8px;
`;

export const text = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[8]};
`;

export const PopupButton = (theme: Theme) => css`
  ${baseButton("primary", "large", true, true)(theme)}
  display: block;
  width: 100%;
  font-weight: ${theme.buttons.text.fontWeight};
`;
