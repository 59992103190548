import { css, Theme } from "@emotion/react";

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const layout = (theme: Theme) =>
  css`
    display: grid;
    grid-template-columns: ${theme.layout.gridColumns(5, 7)};
    justify-content: center;
    margin: 0 auto;
    ${theme.mediaQueries.mediumOnly} {
      grid-template-columns: ${theme.layout.gridColumns(4, 7)};
    }
    ${theme.mediaQueries.smallOnly} {
      grid-template-columns: ${theme.layout.gridColumns(5, 8)};
    }
  `;

export const title = (theme: Theme) => css`
  margin: ${theme.verticalSpaces.large[12]};
  text-align: center;
`;

export const textContainer = css`
  margin-left: 44px;
`;

export const doneButtonContainer = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[8]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const doneButton = (theme: Theme) => css`
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  padding: 0px 20px;
  min-width: 160px;
  max-width: 320px;
  height: 64px;
  background: ${theme.buttons.tertiary.backgroundColor};
  border-radius: ${theme.buttons.tertiary.border.radius};
`;
