import React, {
  FC,
  lazy,
  memo,
  PropsWithChildren,
  Suspense,
  useEffect,
  useLayoutEffect,
} from "react";

import { useReactiveVar } from "@apollo/client";
import useGaTracker from "@hl/shared-features/lib/hooks/useGaTracker";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";

import {
  communityVar,
  initVar,
  modalVar,
  ModalType,
  trySignIn,
} from "apollo/reactive";
import FullscreenLoader from "components/FullscreenLoader";
import { useUserProfileDropdownLogic } from "components/UserProfileDropdown/logic";
import { GOOGLE_ANALYTICS_ID } from "config";
import NotFoundCommunity from "containers/Error/NotFoundCommunity";
import SignIn from "containers/SignIn";
import useAppLocation from "hooks/useAppLocation";
import useEmergencyFlags from "hooks/useEmergencyFlags";
import useSession from "hooks/useSession";

import {
  layoutContext,
  updateLayoutContext,
} from "../../apollo/reactive/layout";
import ConfirmDeleteClaimPageModal from "../../components/Modals/ConfirmDeleteClaimPageModal";
import { ThemeType } from "../../styles/theme/theme.types";

const ProfileSidebar = lazy(
  () => import("../../components/UserProfileDropdown")
);
const LinkDiscordAccountModal = lazy(
  () => import("../../components/Modals/LinkDiscordAccount")
);
const UpdateEmailAddressModal = lazy(
  () => import("../../components/Modals/UpdateEmailAddress")
);
const UpdateUsernameModal = lazy(
  () => import("../../components/Modals/UpdateUsername")
);
const SignInOrCreateAccount = lazy(
  () => import("../../components/Modals/SignInOrCreateAccount")
);
const GenericErrorModal = lazy(
  () => import("../../components/Modals/GenericError")
);
const UnlinkDiscordAccountModal = lazy(
  () => import("../../components/Modals/UnlinkDiscordAccount")
);
const ExpiredAuthSessionModal = lazy(
  () => import("../../components/Modals/ExpiredAuthSession")
);
const ChooseTokenBenefitsModal = lazy(
  () => import("../../components/Modals/ChooseTokenBenefits")
);
const AddCustomBenefitsModal = lazy(
  () => import("../../components/Modals/AddCustomBenefits")
);
const CreateOrUpdateOfferModal = lazy(
  () => import("../../components/Modals/CreateOrUpdateOffer")
);
const ConfirmDeletePostModal = lazy(
  () => import("../../components/Modals/ConfirmDeletePost")
);
const CreateClaimPageModal = lazy(
  () => import("../../components/Modals/CreateClaimPageModal")
);
const ConfirmExitDesignTokenModal = lazy(
  () => import("../../components/Modals/ConfirmExitDesignToken")
);
const SendTokensToMembersModal = lazy(
  () => import("../../components/Modals/SendTokenToMembersModal")
);
const MintingInProgressModal = lazy(
  () => import("../../components/Modals/MintingInProgress")
);
const FinishedMintModal = lazy(
  () => import("../../components/Modals/FinishedMint")
);
const CryptoPaymentModal = lazy(
  () => import("../../components/Modals/CryptoPayment")
);
const UpdatePhoneNumberModal = lazy(
  () => import("../../components/Modals/UpdatePhoneNumber")
);
const CommunityLaunchedModal = lazy(
  () => import("../../components/Modals/CommunityLaunchedModal")
);
const SyncUserDiscordModal = lazy(
  () => import("../../components/Modals/SyncUserDiscord")
);

type PageContainerProps = {
  appIsReady: boolean;
  attemptSignIn: boolean;
  requiredAuth: boolean;
  slug: string;
};

const PageContainer = ({
  children,
  appIsReady,
  attemptSignIn,
  requiredAuth,
  slug,
}: PropsWithChildren<PageContainerProps>) => {
  const { isAppSlug } = useAppLocation();
  const { user } = useSession();

  if (!appIsReady) return null;

  if (attemptSignIn && !user) {
    return <SignIn />;
  }

  if (requiredAuth && !user) {
    return <SignIn />;
  }

  if (!slug && !isAppSlug) return <NotFoundCommunity />;

  return <>{children}</>;
};

export type RootProps = {
  requiredAuth?: boolean;
  theme?: ThemeType;
};

const Root: FC<PropsWithChildren<RootProps>> = ({
  children,
  requiredAuth = false,
  theme = "system",
}) => {
  const { slug } = useReactiveVar(communityVar);
  const appIsReady = useReactiveVar(initVar);
  const { theme: layoutTheme } = layoutContext();
  const attemptSignIn = useReactiveVar(trySignIn);
  const { isSidebarOpen, handleDropDownClose } = useUserProfileDropdownLogic();
  const { showModal } = useReactiveVar(modalVar);
  const { startGoogleAnalytics } = useGaTracker(GOOGLE_ANALYTICS_ID);
  const { showGlobalBanner } = useEmergencyFlags();

  const location = useLocation();

  useLayoutEffect(() => {
    updateLayoutContext({ theme });
  }, [theme]);

  useEffect(() => {
    if (!isSidebarOpen) return;

    handleDropDownClose();
  }, [location]);

  useEffect(() => {
    startGoogleAnalytics();
  }, []);

  useEffect(() => {
    if (!appIsReady) return;

    showGlobalBanner();
  }, [appIsReady]);

  return (
    <>
      <PageContainer
        appIsReady={appIsReady}
        attemptSignIn={attemptSignIn}
        slug={slug}
        requiredAuth={requiredAuth}
      >
        {children}
      </PageContainer>

      <Suspense fallback={""}>
        {showModal === ModalType.LINK_DISCORD_ACCOUNT && (
          <LinkDiscordAccountModal />
        )}
        {showModal === ModalType.UNLINK_DISCORD_ACCOUNT && (
          <UnlinkDiscordAccountModal />
        )}
        {showModal === ModalType.UPDATE_EMAIL_ADDRESS && (
          <UpdateEmailAddressModal />
        )}
        {showModal === ModalType.UPDATE_USERNAME && <UpdateUsernameModal />}
        {(showModal === ModalType.SIGN_IN_ACCOUNT ||
          showModal === ModalType.CREATE_ACCOUNT) && <SignInOrCreateAccount />}
        {showModal === ModalType.CHOOSE_TOKEN_BENEFITS && (
          <ChooseTokenBenefitsModal />
        )}
        {showModal === ModalType.CHOOSE_CUSTOM_BENEFITS && (
          <AddCustomBenefitsModal />
        )}
        {showModal === ModalType.MAKE_OFFER && <CreateOrUpdateOfferModal />}
        {showModal === ModalType.EDIT_OFFER && <CreateOrUpdateOfferModal />}
        {showModal === ModalType.GENERIC_ERROR && <GenericErrorModal />}
        {showModal === ModalType.CONFIRM_DELETE_POST && (
          <ConfirmDeletePostModal />
        )}
        {showModal === ModalType.EXPIRED_AUTH_SESSION && (
          <ExpiredAuthSessionModal />
        )}

        {showModal === ModalType.CREATE_CLAIM_PAGE && <CreateClaimPageModal />}
        {showModal === ModalType.CONFIRM_DELETE_CLAIM_PAGE && (
          <ConfirmDeleteClaimPageModal />
        )}
        {showModal === ModalType.CONFIRM_EXIT_TOKEN_DESIGN_PAGE && (
          <ConfirmExitDesignTokenModal />
        )}
        {showModal === ModalType.SEND_TOKEN_TO_MEMBERS && (
          <SendTokensToMembersModal />
        )}
        {showModal === ModalType.MINTING_IN_PROGRESS && (
          <MintingInProgressModal />
        )}
        {showModal === ModalType.FINISHED_MINT && <FinishedMintModal />}
        {showModal === ModalType.CRYPTO_PAYMENT && <CryptoPaymentModal />}
        {showModal === ModalType.UPDATE_PHONE_NUMBER && (
          <UpdatePhoneNumberModal />
        )}
        {showModal === ModalType.COMMUNITY_LAUNCHED && (
          <CommunityLaunchedModal />
        )}
        {showModal === ModalType.SYNC_USER_DISCORD && <SyncUserDiscordModal />}
        <AnimatePresence>
          <motion.div
            key={appIsReady && layoutTheme ? "loaded" : "loading"}
            initial={false}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.25 }}
          >
            {!appIsReady || !layoutTheme ? <FullscreenLoader /> : null}
          </motion.div>
          {isSidebarOpen && <ProfileSidebar />}
        </AnimatePresence>
      </Suspense>
    </>
  );
};

export default memo(Root);
