import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import type {
  KeyboardEvent as ReactKeyboardEvent,
  TouchEvent as ReactTouchEvent,
} from "react";

import _uniqueId from "lodash/uniqueId";

import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import * as styles from "./styles";
import { Props } from "./types";

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      error,
      id,
      label = "",
      labelType = "text",
      labelOnBackgroundColor,
      rows = 5,
      initialLength = 0,
      placeholderPrefix,
      placeholderPostfix,
      additionalCss,
      ...rest
    }: Props,
    ref
  ) => {
    const htmlFor = _uniqueId("field-");
    const [lengthCount, setLengthCount] = useState<number>(0);

    useEffect(() => {
      setLengthCount(initialLength);
    }, [initialLength]);

    const handleLengthCount = useCallback(
      (
        e:
          | ReactKeyboardEvent<HTMLTextAreaElement>
          | ReactTouchEvent<HTMLTextAreaElement>
      ) => {
        setLengthCount(e.currentTarget.value.length);
      },
      []
    );

    const { maxLength } = rest;

    if (maxLength) {
      rest.onKeyUp = handleLengthCount;
    }

    const renderTitle = () => {
      switch (labelType) {
        case "text":
          return (
            <Text size="medium" bold onBackgroundColor={labelOnBackgroundColor}>
              {label}
            </Text>
          );
        case "title":
          return (
            <Title size="xsmall" onBackgroundColor={labelOnBackgroundColor}>
              {label}
            </Title>
          );
      }
    };

    return (
      <div css={[styles.fieldGroup, additionalCss]}>
        <label htmlFor={id ?? htmlFor} css={styles.label}>
          {renderTitle()}
        </label>
        <div css={styles.inputWrapper(error)}>
          {placeholderPrefix && (
            <span css={styles.prefix}>{placeholderPrefix}</span>
          )}
          <textarea
            ref={ref}
            id={id ?? htmlFor}
            css={styles.input(error, !!placeholderPrefix)}
            rows={rows}
            {...rest}
          />
          {placeholderPostfix && (
            <span css={styles.postfix}>{placeholderPostfix}</span>
          )}
          {maxLength && (
            <p css={styles.counter}>
              <Text as="span" size="small" color="secondary">
                {lengthCount}/{maxLength}
              </Text>
            </p>
          )}
        </div>
        {error && <p css={styles.error}>{error}</p>}
      </div>
    );
  }
);

export default memo(TextArea);
