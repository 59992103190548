import React, { memo } from "react";

import { useReactiveVar } from "@apollo/client";
import { css, Theme } from "@emotion/react";

import {
  resetModalVar,
  ModalPostEditorTokenSelect,
  ModalType,
  modalVar,
} from "apollo/reactive/modal";
import { CreatedCommunityFeedBySlugQuery } from "containers/App/EditCommunity/Posts/createPosts.graphql.generated";

import { TokenStatus } from "../../../../apollo/graphql.generated";
import { APP_SUBDOMAIN, APP_URL } from "../../../../config";
import useAppLocation from "../../../../hooks/useAppLocation";
import { bottomMargin, topMargin } from "../../../../styles/global/layout";
import ButtonLink from "../../../ButtonLink";
import CardContainer from "../../../CardContainer";
import ReactModalBase from "../../../Modals/ReactModalBase";
import TokenList from "../../../TokenList";
import Text from "../../../Typography/Text";
import Title from "../../../Typography/Title";
import { ContentFeedEditorProps } from "../index";

/**
 * Token Button Component
 */
const tokenButtonStyles = {
  tokenButton: (theme: Theme) => css`
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 160px;
    max-width: 190px;
    height: 48px;
    background: ${theme.buttons.tertiary.backgroundColor};
    border-radius: ${theme.buttons.tertiary.border.radius};
  `,
};

const MintNewTokenButton = ({ communitySlug }: NoTokensProps) => {
  const { getEditCommunityURL } = useAppLocation();

  return (
    <ButtonLink
      additionalCss={tokenButtonStyles.tokenButton}
      to={getEditCommunityURL(
        communitySlug ?? APP_SUBDOMAIN,
        APP_URL.editCommunity.mintTokens
      )}
    >
      Mint Token
    </ButtonLink>
  );
};

const GoToTokensButton = ({ communitySlug }: NoTokensProps) => {
  const { getEditCommunityURL } = useAppLocation();

  return (
    <ButtonLink
      additionalCss={tokenButtonStyles.tokenButton}
      to={getEditCommunityURL(
        communitySlug ?? APP_SUBDOMAIN,
        `${APP_URL.editCommunity.base}/${APP_URL.editCommunity.tokens}`
      )}
      onClick={resetModalVar}
    >
      Token List
    </ButtonLink>
  );
};

/**
 * NoToken Component
 */
const noTokenStyles = {
  tokenListHeader: css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  noTokensTitle: css`
    ${bottomMargin("4/8px")}
    text-align: center;
  `,
  noTokensContainer: css`
    ${topMargin("10/40px")}
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  `,
  buttonContainer: css`
    ${bottomMargin("8/24px")}
    padding: 20px;
    display: flex;
    align-items: center;
  `,
};

type NoTokensProps = {
  communitySlug?: string;
};

const NoToken = ({ communitySlug }: NoTokensProps) => (
  <CardContainer
    background="secondary"
    extraCss={noTokenStyles.noTokensContainer}
  >
    <Title as="h3" extraCss={noTokenStyles.noTokensTitle} size="medium">
      You Haven&apos;t Minted Any Tokens Yet
    </Title>
    <Text>
      Get started by minting a membership token for your community. This is what
      people will need to access your community.
    </Text>
    <div css={noTokenStyles.buttonContainer}>
      <MintNewTokenButton communitySlug={communitySlug} />
    </div>
  </CardContainer>
);

const NoAvailableTokens = ({ communitySlug }: NoTokensProps) => (
  <CardContainer
    background="secondary"
    extraCss={noTokenStyles.noTokensContainer}
  >
    <Title as="h3" extraCss={noTokenStyles.noTokensTitle} size="medium">
      You Haven&apos;t Made Available Any Tokens Yet
    </Title>
    <Text>Allow people to buy or claim at least one of your tokens.</Text>
    <div css={noTokenStyles.buttonContainer}>
      <GoToTokensButton communitySlug={communitySlug} />
    </div>
  </CardContainer>
);

/**
 * Modal Component
 */

const modalStyles = {
  modalBody: css``,
  tokenListHeader: css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
};

export type TextEditorTokenSelectModalProps = {
  tokens: NonNullable<ContentFeedEditorProps["tokens"]>;
  offers: NonNullable<
    CreatedCommunityFeedBySlugQuery["createdCommunityBySlug"]
  >["offers"];
  claimPages: NonNullable<
    CreatedCommunityFeedBySlugQuery["createdCommunityBySlug"]
  >["contests"];
  communitySlug?: string;
};

const TextEditorTokenSelectModal = ({
  tokens,
  offers,
  claimPages,
  communitySlug,
}: TextEditorTokenSelectModalProps) => {
  const { showModal, data } = useReactiveVar(
    modalVar as ModalPostEditorTokenSelect
  );

  const tokenHasOfferAvailable = (tokenId: string) => {
    return offers.some(
      (offer) =>
        offer.active &&
        (!!offer.onAboutPage || !!offer.onPrivatePage) &&
        offer.tokens &&
        offer.tokens.some((fiatToken) => fiatToken.tokenId === tokenId)
    );
  };

  const tokenHasClaimPageAvailable = (tokenId: string) => {
    return claimPages.some((contest) => contest.tokenIds.includes(+tokenId));
  };

  const availableTokens = tokens
    .filter((token) => token.tokenStatus === TokenStatus.Minted)
    .filter(
      (token) =>
        tokenHasOfferAvailable(token.tokenId) ||
        tokenHasClaimPageAvailable(token.tokenId)
    )
    .map((token) => {
      return {
        id: token.tokenId,
        type: token.tokenType,
        image: token.uri ?? "",
        name: token.name ?? "",
        link: "#",
        supply: token.supply ?? 0,
        remaining: token.creatorBalance ?? 0,
      };
    });

  return (
    <ReactModalBase
      id="post-editor-token-select-modal"
      isOpen={showModal === ModalType.POST_EDITOR_TOKEN_SELECT}
      shouldReturnFocusAfterClose={false}
    >
      <div css={modalStyles.modalBody}>
        <div css={modalStyles.tokenListHeader}>
          <Title as="h2">Choose a Token</Title>
          <Text>
            Add a token to your post for people to buy or claim for free
          </Text>
        </div>

        {!tokens.length ? (
          <NoToken communitySlug={communitySlug} />
        ) : (
          !availableTokens.length && (
            <NoAvailableTokens communitySlug={communitySlug} />
          )
        )}

        {!!availableTokens.length && (
          <TokenList
            tokens={availableTokens}
            divider={true}
            onClick={data?.insertToken}
            alternateShade={false}
          />
        )}
      </div>
    </ReactModalBase>
  );
};

export default memo(TextEditorTokenSelectModal);
