import { useCommunityQuery } from "apollo/queries";
import useAppLocation from "hooks/useAppLocation";

const useCommunity = () => {
  const { currentCommunitySlug, isAppSlug } = useAppLocation();

  const { ...rest } = useCommunityQuery({
    skip: isAppSlug,
    variables: {
      slug: currentCommunitySlug,
    },
  });

  return {
    ...rest,
  };
};

export default useCommunity;
export type UseCommunity = ReturnType<typeof useCommunity>;
