import { css, Theme } from "@emotion/react";

import { textMediumStyles } from "styles/global/typography";

import { ImageBorder } from "./types";

export const input = css`
  display: none;
`;

export const inputLabel = css`
  display: block;
  margin-bottom: 8px;
`;

export const secondaryText = css`
  word-break: break-word;
  white-space: normal;
`;

export const noImage = (imageBorder: ImageBorder) => css`
  width: 96px;
  height: 96px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: ${imageBorder === "round" ? "50%" : "10px"};
  position: relative;
`;

export const image = (imageBorder: ImageBorder) => css`
  width: 96px;
  height: 96px;
  border-radius: ${imageBorder === "round" ? "50%" : "10px"};
  object-fit: cover;
`;

export const noImageIcon = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const imageLabel =
  (error: string | undefined, imageUrl: string) => (theme: Theme) =>
    css`
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      align-content: center;
      gap: 16px;
      padding: 16px;

      background: ${theme.textInput.backgroundColor};

      border-width: ${error ? "2px" : `${theme.textInput.border.width}`};
      border-style: ${error ? "solid" : `${theme.textInput.border.style}`};
      border-color: ${error
        ? `${theme.colorMap.destructive.default}`
        : `${theme.textInput.border.color}`};

      box-sizing: border-box;
      border-radius: 10px;
      cursor: ${!imageUrl ? "pointer" : "auto"};
    `;

export const rightButtons = css`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const button = (theme: Theme) => css`
  font-weight: ${theme.text.fontWeights.bold};
  background-color: transparent;
  border: none;
  white-space: nowrap;
  text-align: center;
  padding: 0;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const removeButton = (theme: Theme) => css`
  ${button(theme)};
  color: ${theme.colorMap.destructive.default};
`;

export const changeButton = (theme: Theme) => css`
  ${button(theme)};
  color: ${theme.text.colors.primary};
  margin-left: 16px;
`;

export const error = (theme: Theme) => css`
  ${textMediumStyles(theme)}
  color: ${theme.colorMap.destructive.default};
  padding: 0;
  margin: 8px 0 0 0;
`;
