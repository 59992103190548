import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type MintNewTokenMutationVariables = Types.Exact<{
  data: Types.MintNewTokenInput;
  asset: Types.Scalars["Upload"];
  animation: Types.Maybe<Types.Scalars["Upload"]>;
  communityId: Types.Scalars["String"];
}>;

export type MintNewTokenMutation = {
  mintNewToken: { __typename: "BlockchainTaskRef"; taskId: string };
};

export type GenerateClaimTokenContestMutationVariables = Types.Exact<{
  data: Types.ClaimTokenInput;
  communityId: Types.Scalars["String"];
  tokenId: Types.Scalars["String"];
}>;

export type GenerateClaimTokenContestMutation = {
  generateClaimTokenContest: { __typename: "ClaimToken"; contestId: string };
};

export type DisableClaimTokenContestMutationVariables = Types.Exact<{
  contestId: Types.Scalars["String"];
  communityId: Types.Scalars["String"];
}>;

export type DisableClaimTokenContestMutation = {
  disableClaimTokenContest: boolean;
};

export type CreateOfferMutationVariables = Types.Exact<{
  data: Types.CreateOfferInput;
}>;

export type CreateOfferMutation = {
  createOffer: {
    __typename: "Offer";
    id: string;
    name: string;
    price: string;
    currency: string;
    active: boolean;
    tokenIds: Array<number>;
    onAboutPage: boolean | null;
  };
};

export type UpdateOfferMutationVariables = Types.Exact<{
  data: Types.UpdateOfferInput;
  communityId: Types.Scalars["String"];
  offerId: Types.Scalars["String"];
}>;

export type UpdateOfferMutation = {
  updateOffer: {
    __typename: "Offer";
    id: string;
    name: string;
    price: string;
    currency: string;
    active: boolean;
    tokenIds: Array<number>;
    onAboutPage: boolean | null;
    onPrivatePage: boolean | null;
  };
};

export type UpdateDiscordInfoMutationVariables = Types.Exact<{
  data: Types.UpdateDiscordInfoInput;
  communityId: Types.Scalars["String"];
}>;

export type UpdateDiscordInfoMutation = {
  updateDiscordInfo: {
    __typename: "Community";
    id: string;
    discordServer: {
      __typename: "DiscordServer";
      serverId: string | null;
      roleId: string | null;
    } | null;
  };
};

export type DeleteDiscordInfoMutationVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
}>;

export type DeleteDiscordInfoMutation = {
  deleteDiscordInfo: {
    __typename: "Community";
    id: string;
    discordServer: {
      __typename: "DiscordServer";
      serverId: string | null;
      roleId: string | null;
    } | null;
  };
};

export type OnTokenChangeSubscriptionVariables = Types.Exact<{
  type: Types.TokenNotificationType;
  taskId: Types.Maybe<Types.Scalars["String"]>;
  communityId: Types.Scalars["String"];
}>;

export type OnTokenChangeSubscription = {
  token: {
    __typename: "TokenNotification";
    communityId: string;
    tokenIds: Array<number>;
    taskId: string | null;
    type: Types.TokenNotificationType;
    status: Types.NotificationStatus;
  };
};

export const MintNewTokenDocument = gql`
  mutation MintNewToken(
    $data: MintNewTokenInput!
    $asset: Upload!
    $animation: Upload
    $communityId: String!
  ) {
    mintNewToken(
      asset: $asset
      animation: $animation
      id: $communityId
      data: $data
    ) {
      taskId
    }
  }
`;
export type MintNewTokenMutationFn = Apollo.MutationFunction<
  MintNewTokenMutation,
  MintNewTokenMutationVariables
>;

/**
 * __useMintNewTokenMutation__
 *
 * To run a mutation, you first call `useMintNewTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMintNewTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mintNewTokenMutation, { data, loading, error }] = useMintNewTokenMutation({
 *   variables: {
 *      data: // value for 'data'
 *      asset: // value for 'asset'
 *      animation: // value for 'animation'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useMintNewTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MintNewTokenMutation,
    MintNewTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MintNewTokenMutation,
    MintNewTokenMutationVariables
  >(MintNewTokenDocument, options);
}
export type MintNewTokenMutationHookResult = ReturnType<
  typeof useMintNewTokenMutation
>;
export type MintNewTokenMutationResult =
  Apollo.MutationResult<MintNewTokenMutation>;
export type MintNewTokenMutationOptions = Apollo.BaseMutationOptions<
  MintNewTokenMutation,
  MintNewTokenMutationVariables
>;
export const GenerateClaimTokenContestDocument = gql`
  mutation GenerateClaimTokenContest(
    $data: ClaimTokenInput!
    $communityId: String!
    $tokenId: String!
  ) {
    generateClaimTokenContest(
      data: $data
      communityId: $communityId
      id: $tokenId
    ) {
      contestId
    }
  }
`;
export type GenerateClaimTokenContestMutationFn = Apollo.MutationFunction<
  GenerateClaimTokenContestMutation,
  GenerateClaimTokenContestMutationVariables
>;

/**
 * __useGenerateClaimTokenContestMutation__
 *
 * To run a mutation, you first call `useGenerateClaimTokenContestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateClaimTokenContestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateClaimTokenContestMutation, { data, loading, error }] = useGenerateClaimTokenContestMutation({
 *   variables: {
 *      data: // value for 'data'
 *      communityId: // value for 'communityId'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useGenerateClaimTokenContestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateClaimTokenContestMutation,
    GenerateClaimTokenContestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateClaimTokenContestMutation,
    GenerateClaimTokenContestMutationVariables
  >(GenerateClaimTokenContestDocument, options);
}
export type GenerateClaimTokenContestMutationHookResult = ReturnType<
  typeof useGenerateClaimTokenContestMutation
>;
export type GenerateClaimTokenContestMutationResult =
  Apollo.MutationResult<GenerateClaimTokenContestMutation>;
export type GenerateClaimTokenContestMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateClaimTokenContestMutation,
    GenerateClaimTokenContestMutationVariables
  >;
export const DisableClaimTokenContestDocument = gql`
  mutation disableClaimTokenContest(
    $contestId: String!
    $communityId: String!
  ) {
    disableClaimTokenContest(contestId: $contestId, id: $communityId)
  }
`;
export type DisableClaimTokenContestMutationFn = Apollo.MutationFunction<
  DisableClaimTokenContestMutation,
  DisableClaimTokenContestMutationVariables
>;

/**
 * __useDisableClaimTokenContestMutation__
 *
 * To run a mutation, you first call `useDisableClaimTokenContestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableClaimTokenContestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableClaimTokenContestMutation, { data, loading, error }] = useDisableClaimTokenContestMutation({
 *   variables: {
 *      contestId: // value for 'contestId'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useDisableClaimTokenContestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableClaimTokenContestMutation,
    DisableClaimTokenContestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableClaimTokenContestMutation,
    DisableClaimTokenContestMutationVariables
  >(DisableClaimTokenContestDocument, options);
}
export type DisableClaimTokenContestMutationHookResult = ReturnType<
  typeof useDisableClaimTokenContestMutation
>;
export type DisableClaimTokenContestMutationResult =
  Apollo.MutationResult<DisableClaimTokenContestMutation>;
export type DisableClaimTokenContestMutationOptions =
  Apollo.BaseMutationOptions<
    DisableClaimTokenContestMutation,
    DisableClaimTokenContestMutationVariables
  >;
export const CreateOfferDocument = gql`
  mutation CreateOffer($data: CreateOfferInput!) {
    createOffer(data: $data) {
      id
      name
      price
      currency
      active
      tokenIds
      onAboutPage
    }
  }
`;
export type CreateOfferMutationFn = Apollo.MutationFunction<
  CreateOfferMutation,
  CreateOfferMutationVariables
>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOfferMutation,
    CreateOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOfferMutation, CreateOfferMutationVariables>(
    CreateOfferDocument,
    options
  );
}
export type CreateOfferMutationHookResult = ReturnType<
  typeof useCreateOfferMutation
>;
export type CreateOfferMutationResult =
  Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<
  CreateOfferMutation,
  CreateOfferMutationVariables
>;
export const UpdateOfferDocument = gql`
  mutation UpdateOffer(
    $data: UpdateOfferInput!
    $communityId: String!
    $offerId: String!
  ) {
    updateOffer(data: $data, id: $offerId, communityId: $communityId) {
      id
      name
      price
      currency
      active
      tokenIds
      onAboutPage
      onPrivatePage
    }
  }
`;
export type UpdateOfferMutationFn = Apollo.MutationFunction<
  UpdateOfferMutation,
  UpdateOfferMutationVariables
>;

/**
 * __useUpdateOfferMutation__
 *
 * To run a mutation, you first call `useUpdateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferMutation, { data, loading, error }] = useUpdateOfferMutation({
 *   variables: {
 *      data: // value for 'data'
 *      communityId: // value for 'communityId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useUpdateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOfferMutation,
    UpdateOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOfferMutation, UpdateOfferMutationVariables>(
    UpdateOfferDocument,
    options
  );
}
export type UpdateOfferMutationHookResult = ReturnType<
  typeof useUpdateOfferMutation
>;
export type UpdateOfferMutationResult =
  Apollo.MutationResult<UpdateOfferMutation>;
export type UpdateOfferMutationOptions = Apollo.BaseMutationOptions<
  UpdateOfferMutation,
  UpdateOfferMutationVariables
>;
export const UpdateDiscordInfoDocument = gql`
  mutation UpdateDiscordInfo(
    $data: UpdateDiscordInfoInput!
    $communityId: String!
  ) {
    updateDiscordInfo(data: $data, id: $communityId) {
      id
      discordServer {
        serverId
        roleId
      }
    }
  }
`;
export type UpdateDiscordInfoMutationFn = Apollo.MutationFunction<
  UpdateDiscordInfoMutation,
  UpdateDiscordInfoMutationVariables
>;

/**
 * __useUpdateDiscordInfoMutation__
 *
 * To run a mutation, you first call `useUpdateDiscordInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscordInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscordInfoMutation, { data, loading, error }] = useUpdateDiscordInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useUpdateDiscordInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDiscordInfoMutation,
    UpdateDiscordInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDiscordInfoMutation,
    UpdateDiscordInfoMutationVariables
  >(UpdateDiscordInfoDocument, options);
}
export type UpdateDiscordInfoMutationHookResult = ReturnType<
  typeof useUpdateDiscordInfoMutation
>;
export type UpdateDiscordInfoMutationResult =
  Apollo.MutationResult<UpdateDiscordInfoMutation>;
export type UpdateDiscordInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateDiscordInfoMutation,
  UpdateDiscordInfoMutationVariables
>;
export const DeleteDiscordInfoDocument = gql`
  mutation DeleteDiscordInfo($communityId: String!) {
    deleteDiscordInfo(id: $communityId) {
      id
      discordServer {
        serverId
        roleId
      }
    }
  }
`;
export type DeleteDiscordInfoMutationFn = Apollo.MutationFunction<
  DeleteDiscordInfoMutation,
  DeleteDiscordInfoMutationVariables
>;

/**
 * __useDeleteDiscordInfoMutation__
 *
 * To run a mutation, you first call `useDeleteDiscordInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscordInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscordInfoMutation, { data, loading, error }] = useDeleteDiscordInfoMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useDeleteDiscordInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDiscordInfoMutation,
    DeleteDiscordInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDiscordInfoMutation,
    DeleteDiscordInfoMutationVariables
  >(DeleteDiscordInfoDocument, options);
}
export type DeleteDiscordInfoMutationHookResult = ReturnType<
  typeof useDeleteDiscordInfoMutation
>;
export type DeleteDiscordInfoMutationResult =
  Apollo.MutationResult<DeleteDiscordInfoMutation>;
export type DeleteDiscordInfoMutationOptions = Apollo.BaseMutationOptions<
  DeleteDiscordInfoMutation,
  DeleteDiscordInfoMutationVariables
>;
export const OnTokenChangeDocument = gql`
  subscription OnTokenChange(
    $type: TokenNotificationType!
    $taskId: String
    $communityId: String!
  ) {
    token(type: $type, taskId: $taskId, communityId: $communityId) {
      communityId
      tokenIds
      taskId
      type
      status
    }
  }
`;

/**
 * __useOnTokenChangeSubscription__
 *
 * To run a query within a React component, call `useOnTokenChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTokenChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTokenChangeSubscription({
 *   variables: {
 *      type: // value for 'type'
 *      taskId: // value for 'taskId'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useOnTokenChangeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnTokenChangeSubscription,
    OnTokenChangeSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnTokenChangeSubscription,
    OnTokenChangeSubscriptionVariables
  >(OnTokenChangeDocument, options);
}
export type OnTokenChangeSubscriptionHookResult = ReturnType<
  typeof useOnTokenChangeSubscription
>;
export type OnTokenChangeSubscriptionResult =
  Apollo.SubscriptionResult<OnTokenChangeSubscription>;
