import React from "react";

import { useReactiveVar } from "@apollo/client";
import { css, Theme } from "@emotion/react";

import {
  Attachment as BaseAttachmentType,
  AttachmentMetadata,
  AttachmentType,
  MediaMetadata,
  SupportedMediaType,
} from "apollo/graphql.generated";
import useCommunityDetailsV3 from "apollo/hooks/useCommunityDetailsV3";
import { communityVar } from "apollo/reactive";
import { layoutContext } from "apollo/reactive/layout";
import DistributionButton from "components/DistributionButton/DistributionButton";
import Label from "components/Typography/Label";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import CommunityLabel from "components/_Community/Label";
import CommunityText from "components/_Community/Text";
import CommunityTitle from "components/_Community/Title";
import { ThemeType } from "styles/theme/theme.types";
import {
  DistributableToken,
  OfferMethod,
} from "utils/distribution/distributionMethod";

import { useTokenMap } from "./TokenMapContext";

export type AttachmentProps = {
  attachment: BaseAttachmentType;
};

enum MimeTypes {
  JPEG = "image/jpeg",
  PNG = "image/png",
  GIF = "image/gif",
  MP3 = "audio/mpeg",
  WAV = "audio/wav",
  MP4 = "video/mp4",
  WEBM = "video/webm",
}

const isMediaMetadata = (
  toBeDefined?: AttachmentMetadata | null
): toBeDefined is MediaMetadata => {
  if (!toBeDefined) return false;
  return (toBeDefined as MediaMetadata).mime !== undefined;
};

const ImageAttachment = ({ attachment }: AttachmentProps) => {
  if (!isMediaMetadata(attachment.metadata) || !attachment.metadata.url) {
    return null;
  }
  return <img src={attachment.metadata?.url} alt={attachment.id} />;
};

const VideoAttachment = ({ attachment }: AttachmentProps) => {
  if (!isMediaMetadata(attachment.metadata) || !attachment.metadata.url) {
    return null;
  }
  return (
    <video controls controlsList="nodownload">
      <source
        src={attachment.metadata?.url}
        type={MimeTypes[attachment.metadata.mime]}
      />
      Your browser does not support video.
    </video>
  );
};

const AudioAttachment = ({ attachment }: AttachmentProps) => {
  if (!isMediaMetadata(attachment.metadata) || !attachment.metadata.url) {
    return null;
  }
  return (
    <audio controls controlsList="nodownload">
      <source
        src={attachment.metadata.url}
        type={MimeTypes[attachment.metadata.mime]}
      />
      Your browser does not support audio.
    </audio>
  );
};

const tokenStyle = (pageTheme: ThemeType) => (theme: Theme) =>
  css`
    padding: 20px;
    background: ${pageTheme !== "community"
      ? theme.background.colors.tertiary
      : theme.community.secondaryBackground.backgroundColor};
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    > div {
      display: flex;

      img {
        max-width: 140px;
        max-height: 140px;
        margin-right: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 7px;
      }

      p {
        margin: 0.5em 0 0.25em;
      }

      > div {
        display: flex;
        flex-direction: column;
      }
    }

    button {
      margin-top: 20px;
    }
  `;

const TokenAttachment = ({ attachment }: AttachmentProps) => {
  const { theme } = layoutContext();
  const tokenMap = useTokenMap();
  const tokenMetadata = tokenMap[attachment.id];

  const { name: communityName } = useReactiveVar(communityVar);
  const { data: communityData } = useCommunityDetailsV3();

  const { contractAddress } = communityData?.community?.polygonContract ?? {};

  if (!tokenMetadata || isMediaMetadata(attachment.metadata)) {
    return null;
  }

  const token = tokenMetadata as DistributableToken;
  const distribution = tokenMetadata.checkoutId
    ? {
        distributionMethod: {
          id: tokenMetadata.checkoutId,
          price: tokenMetadata.price,
          currency: tokenMetadata.currency!,
          kind: "Offer",
        } as OfferMethod,
        tokens: [token],
      }
    : null;

  const subTitle = (
    <>
      {tokenMetadata.type || "BENEFIT"} Token · {tokenMetadata.available}
      {" / "}
      {tokenMetadata.total} remaining
    </>
  );

  return (
    <div css={tokenStyle(theme ?? "system")}>
      <div>
        {tokenMetadata.uri && <img src={tokenMetadata.uri} alt="Token Image" />}
        <div>
          {theme === "system" ? (
            <Label size="small" as="span">
              {subTitle}
            </Label>
          ) : (
            <CommunityLabel
              size="small"
              as="span"
              color="onPrimaryBackgroundPrimaryColor"
            >
              {subTitle}
            </CommunityLabel>
          )}
          {theme === "system" ? (
            <Title size="small">{tokenMetadata.name}</Title>
          ) : (
            <CommunityTitle
              size="small"
              color="onPrimaryBackgroundPrimaryColor"
            >
              {tokenMetadata.name}
            </CommunityTitle>
          )}
          {theme === "system" ? (
            <Text size="medium">{tokenMetadata.description}</Text>
          ) : (
            <CommunityText
              size="medium"
              color="onPrimaryBackgroundPrimaryColor"
            >
              {tokenMetadata.description}
            </CommunityText>
          )}
        </div>
      </div>
      {distribution && (
        <DistributionButton
          distribution={distribution}
          communityName={communityName}
          contractAddress={contractAddress}
        />
      )}
    </div>
  );
};

const MediaAttachment = ({ attachment }: AttachmentProps) => {
  if (!isMediaMetadata(attachment.metadata)) {
    return null;
  }

  const Component =
    attachment.metadata?.type &&
    mediaTypeComponentMap[attachment.metadata.type];
  return Component ? <Component attachment={attachment} /> : null;
};

const attachmentComponentMap: {
  [key in AttachmentType]: React.FC<AttachmentProps>;
} = {
  MEDIA: MediaAttachment,
  TOKEN: TokenAttachment,
};
const mediaTypeComponentMap: {
  [key in SupportedMediaType]: React.FC<AttachmentProps>;
} = {
  IMAGE: ImageAttachment,
  VIDEO: VideoAttachment,
  AUDIO: AudioAttachment,
};

export const Attachment = ({ attachment }: AttachmentProps) => {
  const Component = attachment.type && attachmentComponentMap[attachment.type];
  return Component ? (
    <div
      css={css`
        width: 100%;
        margin: 20px 0;

        video,
        audio {
          width: 100%;
        }

        img {
          max-width: 100%;
          max-height: 80vh;
        }
      `}
    >
      <Component attachment={attachment} />
    </div>
  ) : null;
};
