import React from "react";

import { useReactiveVar } from "@apollo/client";
import { css, Theme } from "@emotion/react";

import { Attachment as BaseAttachmentType } from "apollo/graphql.generated";
import useCommunityDetailsV3 from "apollo/hooks/useCommunityDetailsV3";
import { communityVar } from "apollo/reactive";
import { layoutContext } from "apollo/reactive/layout";
import DistributionButton from "components/DistributionButton/DistributionButton";
import Label from "components/Typography/Label";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import CommunityLabel from "components/_Community/Label";
import CommunityText from "components/_Community/Text";
import CommunityTitle from "components/_Community/Title";
import { useTokenMap } from "containers/CommunityV3/ContentFeed";
import { ThemeType } from "styles/theme/theme.types";
import {
  DistributableToken,
  OfferMethod,
} from "utils/distribution/distributionMethod";

/**
 * Types
 */
export type TokenAttachmentProps = {
  attachment: BaseAttachmentType;
};

/**
 * Styles
 */
const styles = {
  container: (pageTheme: ThemeType) => (theme: Theme) =>
    css`
      padding: 20px;
      background: ${pageTheme !== "community"
        ? theme.background.colors.tertiary
        : theme.community.secondaryBackground.backgroundColor};
      border-radius: 10px;
      display: flex;
      flex-direction: column;
    `,
  content: css`
    display: flex;
    margin-bottom: 20px;

    img {
      max-width: 140px;
      max-height: 140px;
      margin-right: 20px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 7px;
    }

    p {
      margin: 0.5em 0 0.25em;
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    button {
      margin-top: 20px;
    }
  `,
};

/**
 * Main Component
 */
const TokenAttachment = ({ attachment }: TokenAttachmentProps) => {
  const { theme } = layoutContext();
  const tokenMap = useTokenMap();
  const tokenMetadata = tokenMap[attachment.id];
  const { name: communityName } = useReactiveVar(communityVar);
  const { data: communityData } = useCommunityDetailsV3();

  const { contractAddress } = communityData?.community?.polygonContract ?? {};

  if (!tokenMetadata) {
    return null;
  }
  const token = tokenMetadata as DistributableToken;
  const distribution = tokenMetadata.checkoutId
    ? {
        distributionMethod: {
          id: tokenMetadata.checkoutId,
          price: tokenMetadata.price,
          currency: tokenMetadata.currency!,
          crypto: tokenMetadata.crypto,
          kind: "Offer",
        } as OfferMethod,
        tokens: [token],
      }
    : null;

  const subTitle = (
    <>
      {tokenMetadata.type || "BENEFIT"} Token · {tokenMetadata.available}
      {" / "}
      {tokenMetadata.total} remaining
    </>
  );

  return (
    <div css={styles.container(theme ?? "system")}>
      <div css={styles.content}>
        {tokenMetadata.uri && <img src={tokenMetadata.uri} alt="Token Image" />}
        <div>
          {theme === "system" ? (
            <Label size="small" as="span">
              {subTitle}
            </Label>
          ) : (
            <CommunityLabel
              size="small"
              as="span"
              color="onPrimaryBackgroundPrimaryColor"
            >
              {subTitle}
            </CommunityLabel>
          )}
          {theme === "system" ? (
            <Title size="small">{tokenMetadata.name}</Title>
          ) : (
            <CommunityTitle
              size="small"
              color="onPrimaryBackgroundPrimaryColor"
            >
              {tokenMetadata.name}
            </CommunityTitle>
          )}
          {theme === "system" ? (
            <Text size="medium">{tokenMetadata.description}</Text>
          ) : (
            <CommunityText
              size="medium"
              color="onPrimaryBackgroundPrimaryColor"
            >
              {tokenMetadata.description}
            </CommunityText>
          )}
        </div>
      </div>
      {distribution && (
        <DistributionButton
          distribution={distribution}
          communityName={communityName}
          contractAddress={contractAddress}
        />
      )}
    </div>
  );
};

export default TokenAttachment;
