import React, { useCallback, useEffect, useState } from "react";

import { isApolloError, ApolloError } from "@apollo/client/errors";
import { $isLinkNode } from "@lexical/link";
import { $isListNode, ListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $isAtNodeEnd } from "@lexical/selection";
import { $getNearestNodeOfType } from "@lexical/utils";
import type { LexicalNode } from "lexical";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  ElementNode,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
  TextNode,
} from "lexical";

import {
  Attachment,
  AttachmentInput,
  AttachmentType,
  CreateNewPostInput,
  MediaMetadata,
  SupportedMediaMime,
  SupportedMediaType,
} from "apollo/graphql.generated";
import {
  CreatePostMutation,
  useCreateNewPostMutation,
  useGetUrlMutation,
} from "containers/App/EditCommunity/Posts/createPosts.graphql.generated";
import { logError } from "services/logger";

import { DEFAULT_ERROR_MESSAGE } from "../../../config";

import { $createAudioNode } from "./nodes/AudioNode";
import { $createImageNode } from "./nodes/ImageNode";
import { $createTokenNode } from "./nodes/TokenNode";
import { $createVideoNode } from "./nodes/VideoNode";

const mimeFromType: Record<string, string> = {
  "audio/mpeg": SupportedMediaMime.MP3,
  "audio/wav": SupportedMediaMime.WAV,
  "image/png": SupportedMediaMime.PNG,
  "image/gif": SupportedMediaMime.GIF,
  "image/jpeg": SupportedMediaMime.JPEG,
  "image/jpg": SupportedMediaMime.JPEG,
  "video/webm": SupportedMediaMime.WEBM,
  "video/mp4": SupportedMediaMime.MP4,
};

const formatsByType: Record<SupportedMediaType, SupportedMediaMime[]> = {
  [SupportedMediaType.IMAGE]: [
    SupportedMediaMime.JPEG,
    SupportedMediaMime.PNG,
    SupportedMediaMime.GIF,
  ],
  [SupportedMediaType.AUDIO]: [SupportedMediaMime.MP3, SupportedMediaMime.WAV],
  [SupportedMediaType.VIDEO]: [SupportedMediaMime.MP4, SupportedMediaMime.WEBM],
};

const sizeByType: Record<SupportedMediaType, string> = {
  [SupportedMediaType.IMAGE]: "30MB",
  [SupportedMediaType.AUDIO]: "1GB",
  [SupportedMediaType.VIDEO]: "2GB",
};
export type EditorNodes = LexicalNode & {
  tokenId: string;
  attachmentType: AttachmentType.TOKEN | AttachmentType.MEDIA;
  attachmentName?: string;
  type: string;
  version: string;
  mediaMime: SupportedMediaMime;
  mediaType: SupportedMediaType;
  mediaUrl?: string;
};

const CreateAttachmentMock = (
  mediaType: SupportedMediaType,
  type: AttachmentType,
  file: File
) => {
  return {
    id: file.name,
    type,
    metadata: {
      mime: mimeFromType[file.type], //file.type,
      type: mediaType,
      url: URL.createObjectURL(file),
    },
  } as Attachment;
};

export async function uploadToS3(
  file: File,
  url: string
  // TODO: remove after converting attachment type to string
  //overwriteType?: string
): Promise<boolean> {
  const formData = new FormData();
  //formData.append("Content-Type", overwriteType || file.type);
  formData.append("file", file);

  const response = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-type": file.type,
    },
  });

  if (!response.ok) {
    logError(response.statusText);
    return false;
  }

  return true;
}

export function convertOldEditorContent(
  text: string,
  attachment: Attachment | null
) {
  return function () {
    if (!text) return;

    const root = $getRoot();

    if (root.getFirstChild() === null) {
      const paragraph = $createParagraphNode();
      paragraph.append($createTextNode(text));
      root.append(paragraph);

      let node = null;

      if (attachment && attachment.type === AttachmentType.MEDIA) {
        const metadata = attachment.metadata as MediaMetadata;
        if (metadata && metadata.type === SupportedMediaType.IMAGE) {
          node = $createImageNode({
            mime: metadata.mime,
            type: metadata.type,
            url: metadata.url,
          });
        }
        if (metadata && metadata.type === SupportedMediaType.VIDEO) {
          node = $createVideoNode({
            mime: metadata.mime,
            type: metadata.type,
            url: metadata.url,
          });
        }
        if (metadata && metadata.type === SupportedMediaType.AUDIO) {
          node = $createAudioNode({
            mime: metadata.mime,
            type: metadata.type,
            url: metadata.url,
          });
        }
      }

      if (attachment && attachment.type === AttachmentType.TOKEN) {
        node = $createTokenNode(attachment.id);
      }

      if (node) root.append(node);
    }
  };
}

const useContentFeedEditorLogic = ({
  initialIsEditable,
  communitySlug = null,
}: {
  initialIsEditable: boolean;
  communitySlug?: string | null;
}) => {
  const [getUrl] = useGetUrlMutation();
  const [editor] = useLexicalComposerContext();
  const [createPost] = useCreateNewPostMutation();

  const [error, setError] = useState<string>("");

  const [notifyMembersAction, setNotifyMembersAction] = useState(false);
  const [pinPostAction, setPinPostAction] = useState(false);

  const [blockType, setBlockType] = useState("paragraph");
  const [isEditable, setIsEditable] = useState(initialIsEditable);

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filesForUpload, setFilesForUpload] = useState<Array<File>>([]);
  const [, setSelectedElementKey] = useState<string | null>(null);

  /* Functions */

  const resetEditor = () => {
    setIsSubmitting(false);
    setPinPostAction(false);
    setNotifyMembersAction(false);
    setIsBold(false);
    setIsItalic(false);
    setIsLink(false);
    //setFilesForUpload([]);
  };

  const publishPost = async () => {
    setError("");
    resetEditor();

    if (!communitySlug) return;

    const state = editor.getEditorState();
    const stateObject = state.toJSON();

    if (
      stateObject.root.children.length === 1 &&
      // @ts-ignore TODO: Fix types
      stateObject.root.children[0].children.length === 0
    ) {
      setError("Please enter some text or attach content.");
      return;
    }

    setIsSubmitting(true);

    try {
      const attachments: Array<AttachmentInput> = [];
      for (const key in stateObject.root.children) {
        // @ts-ignore TODO: Fix types
        const node: EditorNodes = stateObject.root.children[key];

        if (node?.attachmentType === AttachmentType.MEDIA) {
          // Handle file upload
          const file = filesForUpload.find(
            (f) => f.name === node.attachmentName
          );

          if (file) {
            const getUrlResponse = await getUrl({
              variables: {
                data: {
                  mime: node.mediaMime,
                  type: node.mediaType,
                  communitySlug: communitySlug,
                },
              },
            });

            const mediaMetadata = getUrlResponse.data?.getUploadUrl
              .metadata as MediaMetadata;

            const publicUrl = mediaMetadata.publicUrl;
            const url = mediaMetadata.url;

            const mediaID = getUrlResponse.data?.getUploadUrl.id;

            if (!url || !mediaID) {
              setError(DEFAULT_ERROR_MESSAGE);
              logError(
                `Failed to get upload url - No url. Got ${getUrlResponse.data?.getUploadUrl?.metadata?.__typename}`
              );
              return false;
            }

            // This can be paralellized in the future - we just need to define error handling (what to do when upload fails)
            if (!(await uploadToS3(file, url))) {
              setError("Upload failed");
              return false;
            }

            // Handle attachments field
            node.mediaUrl = publicUrl ?? "";
            attachments.push({
              id: mediaID,
              type: AttachmentType.MEDIA,
              metadata: {
                type: node.mediaType,
                mime: node.mediaMime,
              },
            });
          }
        }

        if (node?.attachmentType === AttachmentType.TOKEN) {
          attachments.push({
            id: node.tokenId,
            type: AttachmentType.TOKEN,
          });
        }
      }

      const createPostData: CreateNewPostInput = {
        communitySlug,
        notifyMembers: notifyMembersAction,
        pinned: pinPostAction,
        text: JSON.stringify(stateObject),
        attachments,
      };

      await createPost({
        variables: {
          data: createPostData,
        },
      });

      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      editor.focus();

      document.dispatchEvent(
        new CustomEvent<CreatePostMutation["createPost"]>("post:newPostAdded")
      );
    } catch (e) {
      let errMsg = null;
      if (isApolloError(e as Error)) {
        const apolloError = e as ApolloError;
        const TOO_MANY_REQUESTS = 429;
        if (
          apolloError.graphQLErrors[0]?.extensions.status === TOO_MANY_REQUESTS
        ) {
          errMsg = apolloError.message;
        }
      }
      setError(errMsg ?? "An unexpected error occurred, please try again.");

      await logError({
        e,
        message: "[handleCreatePost] create post failed",
      });
    }
    resetEditor();
  };

  const toggleNotifyMembersAction = () => {
    setNotifyMembersAction(!notifyMembersAction);
  };

  const togglePinPostAction = () => {
    setPinPostAction(!pinPostAction);
  };

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
    }
  }, [editor]);

  const clearAttachment = () => null;

  const handleFileSelected =
    (
      mediaType: SupportedMediaType,
      type: AttachmentType,
      callBack: (attachment: Attachment, fileName: string) => void
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) {
        clearAttachment();
        return;
      }

      const file = event.target.files[0];
      event.target.value = "";

      if (mimeFromType[file.type] === undefined) {
        setError(
          `Please attach ${formatsByType[mediaType].join(
            ", "
          )} format with max size of ${sizeByType[mediaType]}`
        );
        clearAttachment();
        return;
      }

      setError("");
      const attachment = CreateAttachmentMock(mediaType, type, file);
      callBack(attachment, file.name);
      setFilesForUpload([...filesForUpload, file]);
    };

  /* Effects */

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, updateToolbar, isEditable]);

  useEffect(() => {
    editor.setEditable(isEditable);
  }, [editor, isEditable]);

  /* Helpers */
  const getSelectedNode = (
    selection: RangeSelection
  ): TextNode | ElementNode => {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
      return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
      return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
  };

  /* Return */
  return {
    getSelectedNode,
    activeEditor: editor,
    blockType,
    isEditable,
    setIsEditable,
    selectedElement: {
      isBold,
      isItalic,
      isLink,
    },
    handleFileSelected,
    error,
    setError,
    publishPost,
    notifyMembers: {
      toggle: toggleNotifyMembersAction,
      isSelected: notifyMembersAction,
    },
    pinPost: {
      toggle: togglePinPostAction,
      isSelected: pinPostAction,
    },
    isSubmitting,
  };
};

export type UseContentFeedEditorLogic = ReturnType<
  typeof useContentFeedEditorLogic
>;
export default useContentFeedEditorLogic;
