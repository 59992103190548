import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  max-width: 100%;
  width: calc(100% - 40px);

  ${theme.mediaQueries.mediumUp} {
    display: grid;
    grid-template-columns: 220px 1fr;
    gap: 20px;
  }

  ${theme.mediaQueries.smallOnly} {
    grid-template-columns: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

/** Sidebar */

export const sidebar = (theme: Theme) => css`
  ${theme.mediaQueries.smallOnly} {
    width: 100%;
    margin-bottom: 30px;
  }
`;

/** Content */

export const content = css`
  width: 100%;
`;

export const contentWrapper = (theme: Theme) => css`
  max-width: 540px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;

  ${theme.mediaQueries.smallOnly} {
    max-width: 100%;
    padding-top: 0;
  }
`;
