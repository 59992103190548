import { loadStripe } from "@stripe/stripe-js";

import { STRIPE_PUBLISHABLE_KEY } from "config";

import { logError } from "./logger";

if (!STRIPE_PUBLISHABLE_KEY) {
  const errorMsg = `[Stripe][Config Issue] "STRIPE_PUBLISHABLE_KEY" env var not set.`;
  logError({
    error: new Error(errorMsg),
    message: errorMsg,
  });
}

export const stripeConstructor = STRIPE_PUBLISHABLE_KEY
  ? loadStripe(STRIPE_PUBLISHABLE_KEY, { locale: "en" })
  : null;
