import { css, Theme } from "@emotion/react";

export const container = css`
  display: grid;
  place-items: center;
`;

export const form = (theme: Theme) => css`
  align-self: center;
  padding: ${theme.verticalSpaces.large[7]} ${theme.verticalSpaces.large[9]};
  background: ${theme.background.colors.primary};
  box-shadow: ${theme.shadows["sm"]};
  border-radius: 10px;
  width: 100%;
  max-width: 572px;
`;

export const fields = (theme: Theme) => css`
  width: 100%;
  list-style: none;
  padding: 0;

  li {
    &:not(:last-child) {
      margin-bottom: ${theme.verticalSpaces.large[8]};
    }
  }
`;

export const button = (theme: Theme) => css`
  background-color: ${theme.buttons.tertiary.backgroundColor};
  border-radius: ${theme.buttons.tertiary.border.radius};
`;

export const title = (theme: Theme) => css`
  text-align: center;
  color: ${theme.invertedText.colors.primary};
  margin-bottom: ${theme.verticalSpaces.large[12]};
`;

export const errorMessage = (theme: Theme) => css`
  text-align: center;
  color: ${theme.colorMap.destructive.default};
  margin-top: ${theme.verticalSpaces.large[4]};
`;

export const termsDisclaimer = css`
  text-align: center;
`;

export const verifyPhoneContainer = (theme: Theme) => css`
  margin-top: -${theme.verticalSpaces.large[6]};
  margin-bottom: ${theme.verticalSpaces.large[8]};
`;

export const verifyPhone = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const errorText = (theme: Theme) => css`
  color: ${theme.colorMap.accent.primary};
`;

export const errorTextUrl = (theme: Theme) => css`
  color: ${theme.colorMap.accent.primary};
  font-weight: bold;

  @media (hover: hover) {
    cursor: pointer;
  }
`;
