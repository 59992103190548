import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  padding: ${theme.modal.padding.medium};
  text-align: center;

  ${theme.mediaQueries.smallOnly} {
    padding: ${theme.modal.padding.small};
  }
`;

export const title = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[3]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.small[3]};
  }
`;

export const description = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[8]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.small[8]};
  }
`;
