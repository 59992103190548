import React, { FC, memo, useCallback, useEffect, useState } from "react";

import {
  useDeleteUserProfileImageMutation,
  useUploadUserProfileImageMutation,
} from "apollo/queries";
import ImageUpload from "components/CommunityCreator/ImageUpload";
import LoadingIcon from "components/LoadingIcon";
import { logError } from "services/logger";

export interface Props {
  profileImageValue: string;
}

const UpdateProfileImage: FC<Props> = ({ profileImageValue }) => {
  const [doUploadUserProfileImage] = useUploadUserProfileImageMutation();
  const [doDeleteUserProfileImage] = useDeleteUserProfileImageMutation();
  const [profileImage, setProfileImage] = useState<string>(profileImageValue);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setProfileImage(profileImageValue);
  }, [profileImageValue]);

  const handleFileAccept = useCallback(async (file: File) => {
    try {
      setLoading(true);
      setError("");
      const response = await doUploadUserProfileImage({
        variables: {
          asset: file,
          mime: file.type,
        },
      });
      const profileImageUrl =
        response.data?.uploadProfileImage.profile?.profileImageUrl || "";
      setProfileImage(profileImageUrl);
    } catch (error) {
      const message = "Upload failed";
      setError(message);
      await logError({
        error,
        message: `[handleProfileImageChange] ${message}`,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleFileReject = useCallback(async (error: string) => {
    setLoading(false);
    setError(error);
  }, []);

  const onRemove = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      await doDeleteUserProfileImage();
      setProfileImage("");
    } catch (error) {
      const message = "Delete failed";
      setError(message);
      await logError({
        error,
        message: `[handleProfileImageRemove] ${message}`,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <ImageUpload
      error={error}
      id="profileImageUpload"
      labelType="title"
      dragActiveDescription="Drop here ..."
      primaryDescription="Drag and Drop your Profile Image here"
      secondaryDescription={`This image represents you. \nUse a PNG or JPG file, recommended dimensions are 400px by 400px.`}
      onFileAccept={handleFileAccept}
      onFileReject={handleFileReject}
      hasRemove={!!profileImageValue}
      onRemove={onRemove}
      imageUrl={profileImage}
      maxSize={2}
    />
  );
};

export default memo(UpdateProfileImage);
