import { ANALYTICS_EVENT, EXTERNAL_URL, IN_DEVELOPMENT } from "../config";
import { trackEvent } from "../services/segment";

const buildOpenSeaUrl = (hasPolygonPath: boolean) =>
  IN_DEVELOPMENT
    ? EXTERNAL_URL.OPENSEA_TESTNET + (hasPolygonPath ? "/mumbai" : "")
    : EXTERNAL_URL.OPENSEA + (hasPolygonPath ? "/matic" : "");

const buildOpenSeaSearchUrl = (contractAddress: string) =>
  `${buildOpenSeaUrl(false)}?search%5Bquery%5D=${contractAddress ?? ""}`;

/**
 * Return an OpenSea URL for a given contract address / token id.
 * Cannot view a local-dev community (local hardhat network) on OpenSea default to testnet since local-dev frontend work often points to the staging env.
 */
const buildOpenSeaTokenUrl = (contractAddress: string, tokenId: string) =>
  `${buildOpenSeaUrl(true)}/${contractAddress}/${tokenId}`;

const trackOpenSeaLinkOpen = (contractAddress: string, tokenId?: string) => {
  const properties = {
    blockchain_public_address: contractAddress,
    ...(tokenId && { token_id: tokenId }),
  };

  trackEvent(ANALYTICS_EVENT.OPENSEA_LINK_OPEN, properties);
};

export { buildOpenSeaTokenUrl, buildOpenSeaSearchUrl, trackOpenSeaLinkOpen };
