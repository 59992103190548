import { css, Theme } from "@emotion/react";

export const mintButtonContainer = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[8]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const mintButton = (theme: Theme) => css`
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  padding: 0 20px;
  min-width: 160px;
  height: 64px;
  background: ${theme.buttons.tertiary.backgroundColor};
  border-radius: ${theme.buttons.tertiary.border.radius};
`;

export const designLayout = (theme: Theme) =>
  css`
    display: grid;
    grid-column-gap: ${theme.layout.gridColumnWidth};
    grid-template-columns: ${theme.layout.gridColumns(7, 5)};
    justify-content: center;
    margin: 0 auto;

    ${theme.mediaQueries.mediumOnly} {
      grid-template-columns: ${theme.layout.gridColumns(10)};
    }
    ${theme.mediaQueries.smallOnly} {
      grid-template-columns: ${theme.layout.gridColumns(12)};
    }
  `;
