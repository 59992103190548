import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { isEmpty } from "lodash";
import { object as yupObject, string as yupString } from "yup";

import {
  MeDocument,
  useContestQuery,
  useInsertCurrentUserToContestMutation,
} from "apollo/queries";
import { ModalType, updateModalVar } from "apollo/reactive";
import useSession from "hooks/useSession";
import { logError } from "services/logger";

import { Form } from "./types";

const textAreaMaxLength = 240;

const questionValidation = yupString()
  .required("This field is required.")
  .max(textAreaMaxLength, `Field limited to ${textAreaMaxLength} characters`);

const useLogic = ({
  contestId,
  showQuestion,
  setOnSubmitLoading,
}: {
  contestId?: string;
  showQuestion?: boolean | null;
  setOnSubmitLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const [addMeToContest, addMeToContestMutation] =
    useInsertCurrentUserToContestMutation();
  const [userEntryData, setUserEntryData] = useState<Form | undefined>(
    undefined
  );
  const { user } = useSession();

  const { refetch } = useContestQuery({
    fetchPolicy: "network-only",
    variables: {
      contestId: contestId ?? "",
    },
  });

  const onSubmit = useCallback(
    async (data: Form) => {
      // cannot be anonymous
      if (!user) {
        updateModalVar({
          showModal: ModalType.SIGN_IN_ACCOUNT,
          showView: "check",
          onClose: () => {
            // save contest entry answer, after modal closes (minimize # of useEffect() runs)
            setUserEntryData(data);
          },
        });
      } else {
        // save contest entry answer, if needed
        setUserEntryData(data);
      }
    },
    [user]
  );

  // if user is authenticated AND (if exists) the contest entry answer is filled out
  // attempt to submit contest entry
  const completeSubmission = useCallback(
    async (formData: Form | undefined): Promise<boolean> => {
      if (!user || !contestId) {
        return false;
      }

      updateModalVar({
        showModal: ModalType.MINTING_IN_PROGRESS,
        showView: "check",
      });

      setOnSubmitLoading(true);
      const { data } = await refetch();
      // prevent duplicate entries, handle if contest sold out or timed out
      if (!data?.contest?.active || data?.contest?.hasUserApplied) {
        return false;
      }

      await addMeToContest({
        variables: {
          contestEntry: {
            formFieldAnswer: formData?.question,
          },
          contestId: contestId,
        },
        refetchQueries: [MeDocument],
      });
      return true;
    },
    [user]
  );

  useEffect(() => {
    if (
      userEntryData === undefined ||
      (showQuestion && isEmpty(userEntryData))
    ) {
      setOnSubmitLoading(false);
      return;
    }

    if (!user) {
      setOnSubmitLoading(false);
      return;
    }

    setOnSubmitLoading(true);
    completeSubmission(userEntryData)
      .catch((error) => {
        logError({ error, message: "complete submission failed" });
        updateModalVar({ showModal: ModalType.GENERIC_ERROR });
      })
      .finally(() => {
        // prevent button spamming
        setTimeout(() => {
          setUserEntryData(undefined);
          setOnSubmitLoading(false);
        }, 1200);
      });
  }, [userEntryData, user, completeSubmission]);

  let validationSchema;
  if (showQuestion === true || showQuestion == null) {
    validationSchema = yupObject({
      question: questionValidation,
    }).required();
  } else {
    validationSchema = yupObject({}).required();
  }

  return {
    onSubmit,
    validationSchema,
    textAreaMaxLength,
    addMeToContestMutation,
  };
};

export type UseProfileSidebarLogic = ReturnType<typeof useLogic>;
export default useLogic;
