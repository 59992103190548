import React, { memo, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { WebsiteThemeFragment } from "apollo/queries";
import { ModalType, updateModalVar } from "apollo/reactive";
import ColorPicker from "components/Inputs/ColorPicker";
import RangeSliderField from "components/Inputs/RangeSliderField";
import SelectField from "components/Inputs/SelectField";
import SelectFontWeightField from "components/Inputs/SelectFontWeightField";
import { AppSEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE } from "config";
import { fontFamilySelectOptionsMap } from "styles/global/fonts";
import {
  borderTypeOptions,
  borderWithOptions,
  cardBorderTypeOptions,
  updateOldFontData,
  getFontFamilyWeightList,
  textTransformOptions,
} from "styles/theme/communityTheme";

import * as baseStyles from "../styles";

import SaveFormChangesCard from "./SaveFormChangesCard";
import { useFormLogic, usePageLogic } from "./logic";
import * as styles from "./styles";

const EditCommunityThemeContainer = () => {
  const navigate = useNavigate();

  const {
    isPageBlockedByFeatureFlag,
    isError,
    isLoading,
    communityStyles,
    communityId,
  } = usePageLogic();

  // Protect route if feature flag is off.
  useEffect(() => {
    if (!isPageBlockedByFeatureFlag) return;
    navigate("/", { replace: true });
  }, [isPageBlockedByFeatureFlag]);

  const ContentSwitch = () => {
    if (isLoading) {
      return (
        <div css={baseStyles.loadingIconContainerCentered}>
          <LoadingIcon />
        </div>
      );
    }

    if (isError || !communityStyles || !communityId) {
      updateModalVar({
        showModal: ModalType.GENERIC_ERROR,
        showView: null,
      });

      return (
        <Text size="large">
          Unfortunately, something went wrong. Please go back and try again.
        </Text>
      );
    }

    return (
      <Form
        communityId={communityId}
        defaultValues={updateOldFontData(communityStyles)}
      />
    );
  };

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.EDITCOMMUNITY_THEME} />

      <div css={baseStyles.layoutHeader}>
        <Title size="large">Theme</Title>
      </div>

      <ContentSwitch />
    </>
  );
};
export type EditCommunityThemeForm = {
  communityId: string;
  defaultValues: NonNullable<WebsiteThemeFragment>;
};

const letterSpacingMinMax = {
  min: -0.5,
  max: 0.5,
};

const Form = ({ communityId, defaultValues }: EditCommunityThemeForm) => {
  const { handleSubmit, watch, register, setValue, formState, showSaveCard } =
    useFormLogic(communityId, defaultValues);

  const { errors, isSubmitting } = formState;

  return (
    <form onSubmit={handleSubmit} css={styles.form}>
      <div css={styles.fieldsList(isSubmitting)}>
        <Title size="medium" extraCss={styles.pageContentTitle}>
          Backgrounds
        </Title>
        <ColorPicker
          label="Page Background Color"
          description="The color of the full page background."
          setValue={setValue}
          formName={"pageBackground.backgroundColor"}
          value={watch("pageBackground.backgroundColor")}
          error={errors.pageBackground?.backgroundColor?.message}
        />
        <ColorPicker
          label="Card Background Color"
          description="The color of content cards that sit on top of the page background."
          setValue={setValue}
          formName={"primaryBackground.backgroundColor"}
          value={watch("primaryBackground.backgroundColor")}
          error={errors.primaryBackground?.backgroundColor?.message}
        />
        <SelectField
          label="Card Shape"
          description="The shape of content cards that sit on top of the page background."
          options={cardBorderTypeOptions}
          error={errors.global?.containerBorderType?.message}
          {...register("global.containerBorderType")}
        />
        <Title size="medium" extraCss={styles.pageContentTitle}>
          Text Colors
        </Title>
        <ColorPicker
          label="Text On Page Color"
          description="The color of text that appears directly on the page background."
          setValue={setValue}
          formName={"text.onPageBackgroundPrimaryColor"}
          value={watch("text.onPageBackgroundPrimaryColor")}
          error={errors.text?.onPageBackgroundPrimaryColor?.message}
        />
        <ColorPicker
          label="Text On card Color"
          description="The color of text that appears on content cards."
          setValue={setValue}
          formName={"text.onPrimaryBackgroundPrimaryColor"}
          value={watch("text.onPrimaryBackgroundPrimaryColor")}
          error={errors.text?.onPrimaryBackgroundPrimaryColor?.message}
        />
        <Title size="medium" extraCss={styles.pageContentTitle}>
          Title Text
        </Title>
        <SelectField
          label="Title Font Family"
          options={fontFamilySelectOptionsMap}
          {...register("title.fontFamily")}
          error={errors.title?.fontFamily?.message}
        />
        <SelectFontWeightField
          label="Title Font Weight"
          options={getFontFamilyWeightList(watch("title.fontFamily"))}
          setValue={setValue}
          value={watch("title.fontWeight")}
          formName={"title.fontWeight"}
          error={errors.title?.fontWeight?.message}
        />
        <RangeSliderField
          label="Title Letter Spacing"
          min={letterSpacingMinMax.min}
          max={letterSpacingMinMax.max}
          registerName={"title.letterSpacing"}
          value={watch("title.letterSpacing")}
          unit="em"
          setValue={setValue}
          error={errors.title?.letterSpacing?.message}
        />
        <SelectField
          label="Title Text Case"
          options={textTransformOptions}
          {...register("title.textTransform")}
          error={errors.title?.fontWeight?.message}
        />
        <Title size="medium" extraCss={styles.pageContentTitle}>
          Body Text
        </Title>
        <SelectField
          label="Body Font Family"
          options={fontFamilySelectOptionsMap}
          {...register("text.fontFamily")}
          error={errors.text?.fontFamily?.message}
        />
        <SelectFontWeightField
          label="Body Regular Font Weight"
          options={getFontFamilyWeightList(watch("text.fontFamily"))}
          setValue={setValue}
          value={watch("text.fontWeight")}
          formName={"text.fontWeight"}
          error={errors.text?.fontWeight?.message}
        />
        <SelectFontWeightField
          label="Body Bold Font Weight"
          options={getFontFamilyWeightList(watch("text.fontFamily"))}
          setValue={setValue}
          value={watch("text.boldFontWeight")}
          formName={"text.boldFontWeight"}
          error={errors.text?.boldFontWeight?.message}
        />
        <RangeSliderField
          label="Body Letter Spacing"
          min={letterSpacingMinMax.min}
          max={letterSpacingMinMax.max}
          registerName={"text.letterSpacing"}
          value={watch("text.letterSpacing")}
          unit="em"
          setValue={setValue}
          error={errors.text?.letterSpacing?.message}
        />
        <SelectField
          label="Body Text Case"
          options={textTransformOptions}
          {...register("text.textTransform")}
          error={errors.text?.fontWeight?.message}
        />
        <Title size="medium" extraCss={styles.pageContentTitle}>
          Label Text
        </Title>
        <SelectFontWeightField
          label="Label Font Weight"
          options={getFontFamilyWeightList(watch("text.fontFamily"))}
          setValue={setValue}
          value={watch("label.fontWeight")}
          formName={"label.fontWeight"}
          error={errors.label?.fontWeight?.message}
        />
        <RangeSliderField
          label="Label Letter Spacing"
          min={letterSpacingMinMax.min}
          max={letterSpacingMinMax.max}
          registerName={"label.letterSpacing"}
          value={watch("label.letterSpacing")}
          unit="em"
          setValue={setValue}
          error={errors.label?.letterSpacing?.message}
        />
        <Title size="medium" extraCss={styles.pageContentTitle}>
          Buttons
        </Title>
        <SelectField
          label="Button Font Family"
          options={fontFamilySelectOptionsMap}
          {...register("button.textFontFamily")}
          error={errors.button?.textFontFamily?.message}
        />
        <SelectFontWeightField
          label="Button Text Font Weight"
          options={getFontFamilyWeightList(watch("button.textFontFamily"))}
          setValue={setValue}
          value={watch("button.textFontWeight")}
          formName={"title.fontWeight"}
          error={errors.button?.textFontWeight?.message}
        />
        <RangeSliderField
          label="Button Text Letter Spacing"
          min={letterSpacingMinMax.min}
          max={letterSpacingMinMax.max}
          registerName={"button.textLetterSpacing"}
          value={watch("button.textLetterSpacing")}
          unit="em"
          setValue={setValue}
          error={errors.button?.textLetterSpacing?.message}
        />
        <SelectField
          label="Button Text Case"
          options={textTransformOptions}
          {...register("button.textTextTransform")}
          error={errors.button?.textTextTransform?.message}
        />
        <SelectField
          label="Button Shape"
          options={borderTypeOptions}
          {...register("button.borderType")}
          error={errors.button?.borderType?.message}
        />
        <ColorPicker
          label="Button On Page Background Color"
          setValue={setValue}
          formName={"button.onPageBackgroundBackgroundColor"}
          value={watch("button.onPageBackgroundBackgroundColor")}
          error={errors.button?.onPageBackgroundBackgroundColor?.message}
        />
        <ColorPicker
          label="Button On Page Text Color"
          setValue={setValue}
          formName={"button.onPageBackgroundTextColor"}
          value={watch("button.onPageBackgroundTextColor")}
          error={errors.button?.onPageBackgroundTextColor?.message}
        />
        <SelectField
          label="Button On Page Border Width"
          options={borderWithOptions}
          {...register("button.onPageBackgroundBorderWidth")}
          error={errors.button?.onPageBackgroundBorderWidth?.message}
        />
        <ColorPicker
          label="Button On Page Border Color"
          setValue={setValue}
          formName={"button.onPageBackgroundBorderColor"}
          value={watch("button.onPageBackgroundBorderColor")}
          error={errors.button?.onPageBackgroundBorderColor?.message}
        />
        <ColorPicker
          label="Button On Card Background Color"
          setValue={setValue}
          formName={"button.onPrimaryBackgroundBackgroundColor"}
          value={watch("button.onPrimaryBackgroundBackgroundColor")}
          error={errors.button?.onPrimaryBackgroundBackgroundColor?.message}
        />
        <ColorPicker
          label="Button On Card Text Color"
          setValue={setValue}
          formName={"button.onPrimaryBackgroundTextColor"}
          value={watch("button.onPrimaryBackgroundTextColor")}
          error={errors.button?.onPrimaryBackgroundTextColor?.message}
        />
        <SelectField
          label="Button On Card Border Width"
          options={borderWithOptions}
          {...register("button.onPrimaryBackgroundBorderWidth")}
          error={errors.button?.onPrimaryBackgroundBorderWidth?.message}
        />
        <ColorPicker
          label="Button On Card Border Color"
          setValue={setValue}
          formName={"button.onPrimaryBackgroundBorderColor"}
          value={watch("button.onPrimaryBackgroundBorderColor")}
          error={errors.button?.onPrimaryBackgroundBorderColor?.message}
        />
      </div>

      {showSaveCard && Object.keys(errors).length === 0 ? (
        <SaveFormChangesCard isSubmitting={isSubmitting} />
      ) : null}
    </form>
  );
};

export default memo(EditCommunityThemeContainer);
