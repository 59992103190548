import { css } from "@emotion/react";

export const container = css`
  display: flex;
  align-items: center;
  line-height: 1;

  svg {
    margin-right: 6px;
    margin-top: -1px;
  }
`;
