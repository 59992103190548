import React, { FC, memo, PropsWithChildren } from "react";

import { css, Theme } from "@emotion/react";

const containerStyles = (sticky: boolean) => (theme: Theme) =>
  css`
    position: ${sticky ? "sticky" : "unset"};
    top: 0;
    z-index: ${theme.zLayers.navBar};
  `;

const LayoutBar: FC<PropsWithChildren<{ sticky?: boolean }>> = ({
  children,
  sticky = true,
}) => {
  return <div css={containerStyles(sticky)}>{children}</div>;
};

export default memo(LayoutBar);
