import React, { memo, useEffect } from "react";

import { useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";

import { useContestQuery } from "apollo/queries";
import { communityVar, ModalType, updateModalVar } from "apollo/reactive";
import CommunityAvatar from "components/CommunityAvatar";
import Layout from "components/Layout";
import { CommunitySEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import CheckYourEmailModal from "components/Modals/CheckYourEmail";
import { SafeInnerHTML } from "components/SafeInnerHTML";
import CommunityText from "components/_Community/Text";
import { COMMUNITY_PAGE_TITLE } from "config";
import NotFound from "containers/Error/NotFound";
import useSession from "hooks/useSession";

import { updateLayoutContext } from "../../apollo/reactive/layout";

import AlreadyMemberCard from "./AlreadyMemberCard";
import AlreadyReceivedCard from "./AlreadyReceivedCard";
import ClosedContestCard from "./ClosedContestCard";
import EnterContestForm from "./EnterContestForm";
import ReceivedCard from "./ReceivedCard";
import useLogic from "./logic";
import * as styles from "./styles";

const ContestEntry = () => {
  const params = useParams();

  const { user, isCommunityMember } = useSession();
  const communityData = useReactiveVar(communityVar);
  const isMember = isCommunityMember(communityData.id);

  const { data, loading, refetch } = useContestQuery({
    fetchPolicy: "network-only",
    variables: {
      contestId: params.contestId as string,
    },
  });

  const { addMeToContestMutation } = useLogic({
    contestId: params.contestId,
    showQuestion: data?.contest?.showQuestion,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setOnSubmitLoading: () => {},
  });

  useEffect(() => {
    if (!addMeToContestMutation?.data) {
      return;
    }

    refetch();
  }, [addMeToContestMutation.data]);

  useEffect(() => {
    if (!addMeToContestMutation?.error) {
      return;
    }
    // @todo: Add more detailed error message.
    //        We don't have a clean message to use for errors coming from REST,
    //        so I'm adding a generic message here.

    updateModalVar({
      showModal: ModalType.GENERIC_ERROR,
      showView: null,
    });
  }, [addMeToContestMutation.error]);

  useEffect(() => {
    refetch();
  }, [user]);

  if (
    !params.contestId ||
    (!loading && !data) ||
    !data?.contest.communityId ||
    data?.contest.communityId !== communityData.id
  ) {
    updateLayoutContext({ theme: "system" });
    return <NotFound />;
  }

  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.CONTEST} />

      <Layout
        backgroundColor="community"
        showHeader={false}
        backgroundShow={true}
      >
        <div css={styles.container}>
          <div css={styles.avatarContainer}>
            <CommunityAvatar />
          </div>

          {loading && (
            <div css={styles.loadingWrapper}>
              <LoadingIcon />
            </div>
          )}

          {!loading && (
            <>
              {data?.contest?.contestPage?.description && (
                <CommunityText as="div" innerHTML={true}>
                  <SafeInnerHTML
                    css={styles.introText}
                    html={data?.contest?.contestPage?.description}
                  />
                </CommunityText>
              )}
              {isMember && (
                <AlreadyMemberCard communityName={communityData.name} />
              )}
              {(!isMember &&
                !data?.contest?.active &&
                !data?.contest?.hasUserApplied && <ClosedContestCard />) ||
                null}

              {!isMember &&
                data?.contest?.active &&
                !data?.contest?.hasUserApplied && (
                  <EnterContestForm
                    contestId={params.contestId ?? ""}
                    placeholder={data?.contest?.contestPage?.placeholder}
                    showQuestion={data?.contest?.showQuestion}
                    formFieldLabel={data?.contest?.contestPage?.formFieldLabel}
                    autoWin={data?.contest?.autoWin}
                    showTerms={true}
                  />
                )}

              {!isMember &&
                data?.contest?.active &&
                data?.contest?.hasUserApplied &&
                addMeToContestMutation.called && (
                  <ReceivedCard
                    email={user?.personalInformation?.email || ""}
                  />
                )}
              {!isMember &&
                data?.contest?.active &&
                data?.contest?.hasUserApplied &&
                !addMeToContestMutation.called && (
                  <AlreadyReceivedCard
                    email={user?.personalInformation?.email || ""}
                  />
                )}
            </>
          )}
        </div>

        <CheckYourEmailModal logInError={null} />
      </Layout>
    </>
  );
};

export default memo(ContestEntry);
