import React, { memo } from "react";

import Layout from "components/Layout";
import { AppSEO } from "components/Layout/SEO";
import { SYSTEM_PAGE_TITLE, FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";
import useSession from "hooks/useSession";

import BecomeCreator from "./BecomeCreator";
import ManageCommunities from "./ManageCommunities";
import SeeAnExample from "./SeeAnExample";
import UserCommunities from "./UserCommunities";
import UserWithoutCommunities from "./UserWithoutCommunities";
import * as styles from "./styles";

const UserDashboard = () => {
  const { user } = useSession();

  const disableCreateCommunity = useFeatureFlag(
    FEATURE_FLAG.DISABLE_CREATE_COMMUNITY
  );
  const showBecomeCreatorCta = useFeatureFlag(FEATURE_FLAG.BECOME_CREATOR_CTA);
  const showCreatorDashboard = useFeatureFlag(
    FEATURE_FLAG.ENABLE_CREATOR_DASHBOARD
  );

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.DASHBOARD} />

      <Layout
        css={styles.container}
        backgroundShow={true}
        backgroundColor="primary"
      >
        <div css={styles.content}>
          {showCreatorDashboard && user?.createdCommunities?.length !== 0 && (
            <ManageCommunities communities={user?.createdCommunities ?? []} />
          )}

          {user?.tokens?.length ? (
            <UserCommunities tokens={user?.tokens} />
          ) : (
            <UserWithoutCommunities />
          )}

          {user?.createdCommunities?.length === 0 && <SeeAnExample />}

          {showBecomeCreatorCta && !disableCreateCommunity && <BecomeCreator />}
        </div>
      </Layout>
    </>
  );
};

export default memo(UserDashboard);
