import "emoji-mart/css/emoji-mart.css";
import React, { FC, memo, useCallback, useRef, useState } from "react";

import { CSSInterpolation } from "@emotion/serialize";
import { BaseEmoji, Emoji, Picker } from "emoji-mart";

import { UpdateActionType } from "apollo/graphql.generated";
import { useUpdateCommentReactionMutation } from "apollo/queries";
import { ReactComponent as AddReactionIcon } from "assets/icons/add-reaction.svg";
import useSession from "hooks/useSession";
import { logError } from "services/logger";
import useOnClickOutside from "utils/hooks/useOnClickOutside";

import * as styles from "./styles";
import { BasePostReaction, ReactionsProp, SingleReactionProps } from "./types";

const SingleReaction = ({
  theme,
  reaction,
  hasUserReacted,
  onClick,
}: SingleReactionProps) => {
  return (
    <button css={styles.reactionStyle(hasUserReacted, theme)} onClick={onClick}>
      <Emoji emoji={reaction.reaction} size={22} />
      <span>{reaction.count}</span>
    </button>
  );
};

const Reactions: FC<ReactionsProp> = (props: ReactionsProp) => {
  const { user } = useSession();
  type User = typeof user;
  const ref = useRef(null);
  const [pickerCss, setPickerCss] = useState<CSSInterpolation>(null);

  const closePicker = () => {
    setPickerCss(null);
  };

  useOnClickOutside(ref, closePicker);
  const [updateCommentReaction] = useUpdateCommentReactionMutation();
  const [reactions, setReactions] = useState<BasePostReaction[]>(
    [...(props.reactions || [])].sort((a, b) =>
      a.reaction.localeCompare(b.reaction)
    )
  );

  const handleReactionAdd = useCallback(async (data: BaseEmoji, user: User) => {
    await handleReactionUpdate(data.id, UpdateActionType.ADD, user);
  }, []);

  const getUpdatedReactions = (
    prevReactions: BasePostReaction[],
    id: string,
    type: UpdateActionType,
    user: User
  ): BasePostReaction[] => {
    if (!user) return prevReactions;

    const existingReaction = prevReactions.find(
      (reaction) => reaction.reaction === id
    );

    const newReactions = prevReactions.filter(
      (reaction) => reaction !== existingReaction
    );

    if (type === UpdateActionType.ADD) {
      newReactions.push({
        reaction: id,
        count: (existingReaction?.count || 0) + 1,
        userHasReacted: true,
      });
    }

    if (type === UpdateActionType.REMOVE) {
      if (existingReaction && existingReaction.count > 1) {
        newReactions.push({
          reaction: id,
          count: existingReaction.count - 1,
          userHasReacted: false,
        });
      }
    }

    return newReactions.sort((a, b) => a.reaction.localeCompare(b.reaction));
  };

  const handleReactionClick = useCallback(
    async (reaction: BasePostReaction, user: User) => {
      if (reaction.userHasReacted) {
        await handleReactionUpdate(
          reaction.reaction,
          UpdateActionType.REMOVE,
          user
        );
      } else {
        await handleReactionUpdate(
          reaction.reaction,
          UpdateActionType.ADD,
          user
        );
      }
    },
    []
  );

  const handleReactionUpdate = async (
    id: string,
    type: UpdateActionType,
    user: User
  ) => {
    setReactions((prevState) => getUpdatedReactions(prevState, id, type, user));
    closePicker();
    try {
      await updateCommentReaction({
        variables: {
          data: {
            communitySlug: props.communitySlug,
            reaction: id,
            type: type,
          },
          commentId: props.commentId,
        },
      });
    } catch (e) {
      await logError({
        e,
        message: "[handleUpdatePostReaction] update reaction failed",
      });
    }
  };

  const toggleReactionPicker = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (pickerCss) {
      closePicker();
      return;
    }

    if (visualViewport) {
      const onSecondHalfY = event.clientY > visualViewport.height / 2;
      const onSecondHalfX = event.clientX > visualViewport.width / 2;

      setPickerCss(styles.getPickerCss(onSecondHalfY, onSecondHalfX));
    }
  };

  return (
    <>
      <ul css={styles.reactionListStyle}>
        {reactions &&
          reactions
            .filter((reaction) => reaction.count > 0)
            .map((reaction) => (
              <li key={`${props.commentId}${reaction.reaction}`}>
                <SingleReaction
                  onClick={() => handleReactionClick(reaction, user)}
                  theme={props.theme ?? "system"}
                  hasUserReacted={!!user && reaction.userHasReacted}
                  reaction={reaction}
                  key={reaction.reaction}
                />
              </li>
            ))}
        <li>
          <button
            css={styles.reactionButton(props.theme)}
            onClick={toggleReactionPicker}
          >
            <AddReactionIcon />
          </button>
          {pickerCss && (
            <div ref={ref} css={pickerCss}>
              <Picker
                showPreview={false}
                showSkinTones={false}
                title={""}
                emoji={""}
                onClick={(data: BaseEmoji) => handleReactionAdd(data, user)}
              />
            </div>
          )}
        </li>
      </ul>
    </>
  );
};

export default memo(Reactions);
