import {
  Error as ErrorIcon,
  InformationIcon as InfoIcon,
  RegularRoundedClose as CloseIcon,
} from "assets/icons.generated";
import Text from "components/Typography/Text";

import * as styles from "./styles";

export type MessageBannerProps = {
  type?: styles.MessageBannerType;
  title?: string;
  learnMore?: () => void;
  onClose?: () => void;
  message?: string;
  margin?: boolean;
  small?: boolean;
};

const typeIconMap = {
  error: <ErrorIcon />,
  info: <InfoIcon />,
};

export const MessageBanner = ({
  type = "error",
  title,
  learnMore,
  onClose,
  message,
  margin = true,
  small = false,
}: MessageBannerProps) => {
  if (!message) return null;

  return (
    <div css={styles.container(type, margin, small)}>
      {typeIconMap[type]}
      <div>
        {title && (
          <Text size="large" bold>
            {title}
          </Text>
        )}
        <Text size={small ? "small" : "medium"}>{message}</Text>
        {learnMore && (
          <div css={styles.learnMore} onClick={learnMore}>
            <Text bold>Learn More →</Text>
          </div>
        )}
      </div>
      {onClose && <CloseIcon onClick={onClose} />}
    </div>
  );
};
