import React from "react";

import { css, Theme } from "@emotion/react";

import { Pinned as PinendIcon } from "assets/icons.generated";

const pinnedStyle = (theme: Theme) => css`
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;

    ${theme.mediaQueries.smallOnly} {
      display: none;
    }
  }
`;

export const Pinned = () => (
  <div css={pinnedStyle}>
    <PinendIcon />
    <span> Pinned</span>
  </div>
);
