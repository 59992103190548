import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";
import { textMediumStyles } from "styles/global/typography";

export const container = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px 0 20px;
  width: 100%;
  min-width: 600px;

  ${topMargin("9/32px")}
`;

export const middleContainer = css`
  max-width: 684px;
  width: 684px;
  margin-left: 20px;
`;

export const commentContainer = css`
  flex: 1;
  width: 100%;
  ${topMargin("4/8px")}
`;

export const commenterAvatarContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const commenterAvatar = css`
  display: block;
  width: 56px;
  ${topMargin("9/32px")}
`;

export const commenterEmptyAvatar = css`
  width: 56px;
`;

export const textarea = (theme: Theme) => css`
  border: none;
  /* TODO: use theme after its refactored */
  font-family: ${theme.text.fontFamily.primary};
  font-size: ${theme.text.sizes.medium};
  resize: none;
  width: 100%;
  &:focus-visible {
    outline: none;
  }
`;

export const buttonContainer = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${topMargin("7/20px")}
`;

export const error = (theme: Theme) => css`
  ${textMediumStyles(theme)}
  color: ${theme.colorMap.destructive.default};
  padding: 0;
  margin: 8px 0 0 0;
`;
