import { FC, memo, ReactNode } from "react";

import Tippy, { TippyProps } from "@tippyjs/react";

import * as styles from "./styles";

export type TooltipProps = TippyProps & {
  content: ReactNode;
  maxWidth?: number;
  skin?: "dark" | "light" | "community";
  padding?: "xsmall" | "small" | "large";
};

const Tooltip: FC<TooltipProps> = ({
  content,
  maxWidth = 260,
  padding = "small",
  skin = "dark",
  children,
  ...rest
}) => {
  return (
    <Tippy
      maxWidth={`${maxWidth}px`}
      css={[styles.container(padding), styles.bySkin(skin)]}
      content={content}
      {...rest}
    >
      {children}
    </Tippy>
  );
};

export default memo(Tooltip);
