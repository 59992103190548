import React, { FC, memo } from "react";

import { ModalType, updateModalVar } from "apollo/reactive";
import Button from "components/Button";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";

import * as editCommunityStyles from "../../../styles";
import * as integrationsStyles from "../styles";

export interface Props {
  discordServerId?: string | null;
}

const Discord: FC<Props> = ({ discordServerId }) => {
  const showOption = useFeatureFlag(
    FEATURE_FLAG.ENABLE_OPTION_TO_CUSTOMIZE_DISCORD_SERVER
  );

  if (!showOption) return null;

  const showLinkModal = () => {
    updateModalVar({
      showModal: ModalType.LINK_DISCORD_SERVER,
    });
  };
  const showUnlinkModal = () => {
    updateModalVar({
      showModal: ModalType.UNLINK_DISCORD_SERVER,
    });
  };

  const Linked = () => (
    <>
      <i css={integrationsStyles.dot(!!discordServerId)} />
      <Text
        as="p"
        size="medium"
        extraCss={integrationsStyles.fieldContainerText}
      >
        Server {discordServerId!.length > 4 ? "..." : ""}
        {discordServerId!.slice(-5)} Linked
      </Text>
      <div css={integrationsStyles.buttonsGroup}>
        <button
          css={editCommunityStyles.textButton("default")}
          onClick={showLinkModal}
        >
          Edit
        </button>
        <button
          css={editCommunityStyles.textButton("destructive")}
          onClick={showUnlinkModal}
        >
          Unlink
        </button>
      </div>
    </>
  );

  const NotLinked = () => (
    <>
      <i css={integrationsStyles.dot(!!discordServerId)} />
      <Text
        as="p"
        size="medium"
        extraCss={integrationsStyles.fieldContainerText}
      >
        No Discord server linked
      </Text>
      <Button
        size="small"
        extraCss={integrationsStyles.button}
        onClick={showLinkModal}
      >
        Link Discord
      </Button>
    </>
  );

  return (
    <>
      <Title size="xsmall" extraCss={integrationsStyles.title}>
        Discord Server
      </Title>
      <Text size="small">
        Linking a Discord server lets you grant access to that server to members
        of your community or people who own a specific token.
      </Text>
      <CardContainer
        border={true}
        background={"primary"}
        extraCss={integrationsStyles.fieldCard}
      >
        {discordServerId ? <Linked /> : <NotLinked />}
      </CardContainer>
    </>
  );
};

export default memo(Discord);
