import { css } from "@emotion/react";

export const main = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

export const container = css`
  width: 100%;
  max-width: 484px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const CardContainer = css``;
