import { useState } from "react";

import { useOutletContext } from "react-router-dom";
import { string as yupString } from "yup";

import { CreatorCommunityMembersSort } from "apollo/graphql.generated";
import { useCreatorCommunityMembersQuery } from "apollo/queries";
import { FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";

import { OutletContextType } from "../EditCommunityLayout";

export const usePageLogic = () => {
  const [sort, setSort] = useState<CreatorCommunityMembersSort>(
    CreatorCommunityMembersSort.BY_DATE_REVERSE
  );
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(20);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const isFeatureFlagEnable = useFeatureFlag(
    FEATURE_FLAG.ENABLE_CREATOR_DASHBOARD_MEMBERS_TAB
  );
  const { communityData } = useOutletContext<OutletContextType>();

  const { loading, data } = useCreatorCommunityMembersQuery({
    variables: {
      communityId: communityData?.id ?? "",
      page,
      pageSize,
      sort,
      email,
    },
    skip: !communityData,
    fetchPolicy: "network-only",
  });

  const handleOnPageChange = (page: number) => {
    setError("");
    setPage(page);
  };

  const handleOnOrderChange = (sortValue: string) => {
    setError("");
    setSort(
      CreatorCommunityMembersSort[
        sortValue as keyof typeof CreatorCommunityMembersSort
      ]
    );
  };

  const emailValidation = yupString().email().trim();
  const handleOnSearch = async (email?: string) => {
    try {
      setError("");
      email = await emailValidation.validate(email);
      setPage(1);
      setEmail(email || "");
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
      return false;
    }
  };

  return {
    page,
    pageSize,
    sort,
    handleOnPageChange,
    handleOnOrderChange,
    handleOnSearch,
    isPageBlockedByFeatureFlag: !isFeatureFlagEnable,
    isLoading: loading,
    membersAmount: data?.creatorCommunityMembers.amount,
    currentMonthMembersAmount: data?.creatorCommunityMembers.currentMonthAmount,
    members: data?.creatorCommunityMembers.members,
    resultsCount: data?.creatorCommunityMembers.resultsCount,
    lastSearchedEmail: email,
    error,
    setError,
  };
};

// Types

export type UsePageLogic = ReturnType<typeof usePageLogic>;
