import { css, Theme } from "@emotion/react";

export const container = css`
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CardContainer = (theme: Theme) => css`
  text-align: center;
  padding: 33px 40px;
  max-width: 576px;

  svg {
    margin-bottom: ${theme.verticalSpaces.small[6]};
  }
  h3 {
    margin-bottom: ${theme.verticalSpaces.small[6]};
  }
  p {
    margin-bottom: ${theme.verticalSpaces.small[9]};
  }

  ${theme.mediaQueries.smallOnly} {
    padding: 24px 25px;
  }
`;
