import { css, Theme } from "@emotion/react";

export const checklistLeft = (theme: Theme) => css`
  align-self: stretch;
  margin-right: ${theme.verticalSpaces.large[6]};
  color: ${theme.text.colors.primary};
  flex: 1 0;
`;

export const checklistRight = (theme: Theme) => css`
  margin-left: auto;
  align-self: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  color: ${theme.text.colors.primary};
  flex: 0 0 16px;
`;

export const check = (theme: Theme) => css`
  margin-left: auto;
  align-self: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: ${theme.text.colors.primary};
  flex: 0 0 24px;
`;

export const link = (theme: Theme) => css`
  display: flex;
  align-items: flex-start;
  padding: ${theme.verticalSpaces.large[5]};
  margin: 0;
  background: ${theme.background.colors.secondary};
  border-radius: 10px;
`;
