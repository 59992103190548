import { css, Theme } from "@emotion/react";

export const benefitList = (theme: Theme) => css`
  margin: ${theme.verticalSpaces.large[4]} 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: left;
  gap: 12px;
`;
