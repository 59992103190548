import React, { memo, useCallback } from "react";

import { ModalType, updateModalVar } from "apollo/reactive";
import AnchorButton from "components/Anchor/AnchorButton";
import CopyToClipboard from "components/CopyToClipboard";
import { AppSEO } from "components/Layout/SEO";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import {
  SYSTEM_PAGE_TITLE,
  FEATURE_FLAG,
  IN_DEVELOPMENT,
  MAGIC_EXPORT_PRIVATE_KEY_LINK,
} from "config";
import useFeatureFlag from "hooks/useFeatureFlag";
import useSession from "hooks/useSession";
import { maskAddress } from "utils/content";

import UpdateProfileImage from "./UpdateProfileImage";
import * as styles from "./styles";

const MyAccountSettings = () => {
  const EnableMagiclinkPrivateKeyExport = useFeatureFlag(
    FEATURE_FLAG.MAGICLINK_PRIVATE_KEY_EXPORT
  );
  const EnableUpdateUserProfile = useFeatureFlag(
    FEATURE_FLAG.RELEASE_PARTY_CAN_UPDATE_USER_PROFILE
  );
  const EnableSyncBenefitsButton = useFeatureFlag(
    FEATURE_FLAG.DISCORD_SYNC_BENEFITS_BUTTON
  );

  const { user } = useSession();
  const { personalInformation, blockchainAccount, discord, profile } =
    user ?? {};
  const phoneNumber =
    personalInformation &&
    personalInformation.phone &&
    personalInformation.phone.isVerified &&
    personalInformation.phone.number;

  const openUpdateEmailAddress = useCallback(() => {
    updateModalVar({ showModal: ModalType.UPDATE_EMAIL_ADDRESS });
  }, []);

  const openUpdateUsername = useCallback(() => {
    updateModalVar({ showModal: ModalType.UPDATE_USERNAME });
  }, []);

  const openLinkDiscordAccountModal = useCallback(() => {
    updateModalVar({ showModal: ModalType.LINK_DISCORD_ACCOUNT });
  }, []);

  const openUpdatePhoneNumberModal = useCallback(() => {
    updateModalVar({ showModal: ModalType.UPDATE_PHONE_NUMBER });
  }, []);

  const openUnlinkDiscordAccountModal = useCallback(() => {
    updateModalVar({ showModal: ModalType.UNLINK_DISCORD_ACCOUNT });
  }, []);

  const openSyncUserDiscordModal = useCallback(() => {
    updateModalVar({ showModal: ModalType.SYNC_USER_DISCORD });
  }, []);

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.ACCOUNT_SETTINGS} />

      <Title size="large">Account Settings</Title>

      <ul css={styles.fields}>
        <li>
          <Text size="medium" bold as="label">
            Email Address
          </Text>
          <div>
            <Text as="p">{personalInformation?.email}</Text>

            <button
              css={styles.smallBlueButton}
              onClick={openUpdateEmailAddress}
            >
              Edit
            </button>
          </div>
        </li>

        {EnableUpdateUserProfile && (
          <>
            <li>
              <Text size="medium" bold as="label">
                Username
              </Text>
              <div>
                <Text as="p">{profile?.username}</Text>

                <button
                  css={styles.smallBlueButton}
                  onClick={openUpdateUsername}
                >
                  Edit
                </button>
              </div>
            </li>

            <li>
              <>
                <Text size="medium" bold as="label">
                  Profile Picture
                </Text>
                <div id="updateProfileImage">
                  <UpdateProfileImage
                    profileImageValue={user?.profile?.profileImageUrl || ""}
                  />
                </div>
              </>
            </li>
          </>
        )}
        <li>
          <Text size="medium" bold as="label">
            Phone Number
          </Text>
          <div>
            <Text as="p">
              {phoneNumber ? phoneNumber : "No Phone Number Linked"}
            </Text>

            <button
              css={[
                styles.smallBlueButton,
                phoneNumber && styles.smallBlueButtonRed,
              ]}
              onClick={openUpdatePhoneNumberModal}
            >
              {!phoneNumber ? `Add Number` : `Edit`}
            </button>
          </div>
        </li>

        <li>
          <Text size="medium" bold as="label">
            Public Wallet Address
          </Text>
          <div>
            <Text as="p">{maskAddress(blockchainAccount?.publicAddress)}</Text>

            <CopyToClipboard
              extraCss={styles.smallBlueButton}
              toCopy={blockchainAccount?.publicAddress ?? ""}
            />
          </div>
        </li>

        {EnableMagiclinkPrivateKeyExport && user?.authMethod === "MAGIC_LINK" && (
          <li css={styles.fieldExportPrivateKey}>
            <div>
              <Text as="p">Export Private Key</Text>

              <a
                href={
                  IN_DEVELOPMENT
                    ? MAGIC_EXPORT_PRIVATE_KEY_LINK.STAGING
                    : MAGIC_EXPORT_PRIVATE_KEY_LINK.PRODUCTION
                }
                target="_blank"
                css={styles.smallBlueButton}
                rel="noreferrer"
              >
                Export
              </a>
            </div>
          </li>
        )}

        <li>
          <Text size="medium" bold as="label">
            Discord Account
          </Text>
          {EnableSyncBenefitsButton && discord && (
            <Text size="small" extraCss={styles.syncDiscordContainer}>
              If you’re having issues accessing a Discord server,{" "}
              <AnchorButton color="accent" onClick={openSyncUserDiscordModal}>
                click here
              </AnchorButton>{" "}
              to sync your account.
            </Text>
          )}
          <div>
            <Text as="p">
              {!discord
                ? `No Account Linked`
                : `${discord.user?.username}#${discord?.user?.discriminator}`}
            </Text>

            <button
              css={[
                styles.smallBlueButton,
                discord && styles.smallBlueButtonRed,
              ]}
              onClick={
                !discord
                  ? openLinkDiscordAccountModal
                  : openUnlinkDiscordAccountModal
              }
            >
              {!discord ? `Link Account` : `Unlink`}
            </button>
          </div>
        </li>
      </ul>
    </>
  );
};

export default memo(MyAccountSettings);
