import React, { FC, memo, PropsWithChildren } from "react";

import { Interpolation, Theme } from "@emotion/react";

import { TextSizesKeys } from "styles/theme";
import { CommunityThemeTextColorsKeys } from "styles/theme/communityTheme.types";
import { TextTag } from "styles/theme/theme.types";

import * as communityStyles from "./styles";

export type CommunityTextProps = {
  color?: CommunityThemeTextColorsKeys;
  size?: TextSizesKeys;
  as?: TextTag;
  bold?: boolean;
  uppercase?: boolean;
  innerHTML?: boolean;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
};

const CommunityText: FC<PropsWithChildren<CommunityTextProps>> = ({
  extraCss,
  size = "medium",
  color = "onPageBackgroundPrimaryColor",
  as = "p",
  bold = false,
  innerHTML = false,
  uppercase = false,
  children,
}) => {
  const Tag = as as keyof JSX.IntrinsicElements;

  return (
    <Tag
      css={[
        communityStyles.base(bold, uppercase),
        communityStyles.bySize(size),
        communityStyles.byColor(color),
        innerHTML && communityStyles.byInnerHTML,
        extraCss,
      ]}
    >
      {children}
    </Tag>
  );
};

export default memo(CommunityText);
