import { css, Theme } from "@emotion/react";

import CheckWhiteSmall from "assets/icons/check-white-small.svg";
import { textMediumStyles } from "styles/global/typography";

export const styles = {
  container: (theme: Theme) => css`
    display: flex;
    padding: 20px;
    flex-direction: column;
    margin: 4em 0;
    background: ${theme.background.colors.primary};
    /* TODO: use theme after its refactored */
    border: 1px solid rgba(2, 2, 2, 0.1);
    border-radius: 10px;
    textarea {
      border: none;
      /* TODO: use theme after its refactored */
      margin: 1em 0 1.25em;
      font-family: ${theme.text.fontFamily.primary};
      font-size: ${theme.text.sizes.medium};
      resize: none;
      width: 100%;
      &:focus-visible {
        outline: none;
      }
    }
    input[type="file"] {
      display: none;
    }
  `,
  header: (theme: Theme) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 1em 0.5em;
    input {
      margin-right: 8px;
    }
    .pin {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: flex-end;

      input[type="checkbox"] {
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 4px;
        border: none;
        background-color: ${theme.background.colors.secondary};
      }

      input[type="checkbox"]:checked {
        background-color: ${theme.text.colors.primary};

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          background-image: url(${CheckWhiteSmall});
          background-size: 12px 12px;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }
  `,
  spacer: css`
    flex-grow: 1;
  `,
  textInput: css`
    margin: 20px 0;
  `,
  tokenListHeader: css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  noTokensContainer: (theme: Theme) => css`
    margin-top: ${theme.verticalSpaces.large[10]};
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  `,
  noTokensTitle: (theme: Theme) => css`
    margin-bottom: ${theme.verticalSpaces.large[4]};
    text-align: center;
  `,
  buttonContainer: (theme: Theme) => css`
    margin-top: ${theme.verticalSpaces.large[8]};
    padding: 20px;
    display: flex;
    align-items: center;
  `,

  mintNewTokenButton: (theme: Theme) => css`
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    min-width: 160px;
    max-width: 190px;
    height: 48px;
    background: ${theme.buttons.tertiary.backgroundColor};
    border-radius: ${theme.buttons.tertiary.border.radius};
  `,
  error: (theme: Theme) => css`
    ${textMediumStyles(theme)}
    color: ${theme.colorMap.destructive.default};
    padding: 0;
    margin: 8px 0 0 0;
  `,
};
