import { FC, memo } from "react";

import useAppLocation from "hooks/useAppLocation";

import SamSmithContestEntry from "./_Custom/samsmith";

import ContestEntry from "./index";

const ContestSwitch: FC = () => {
  const { currentCommunitySlug } = useAppLocation();

  if (
    currentCommunitySlug === "samsmithdemo" ||
    currentCommunitySlug === "pinkhouse"
  ) {
    return <SamSmithContestEntry />;
  } else {
    return <ContestEntry />;
  }
};

export default memo(ContestSwitch);
