import { css, Theme } from "@emotion/react";

export const icon = css`
  margin-left: 4px;

  path {
    fill: currentColor;
  }
`;

export const communityNav = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[8]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[8]};
  }
`;

export const layout = (theme: Theme) =>
  css`
    display: grid;
    grid-template-columns: ${theme.layout.gridColumns(10)};
    justify-content: center;
    margin: 0 auto;

    ${theme.mediaQueries.smallOnly} {
      grid-template-columns: ${theme.layout.gridColumns(12)};
    }
  `;

export const page = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[10]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[9]};
  }
`;

export const communityHeader = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const creatorAvatarContainer = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[8]};
`;

export const creatorAvatarTitle = css`
  text-align: center;
`;

export const tokenInfo = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: ${theme.verticalSpaces.large[2]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[2]};
  }
`;

export const benefit = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[9]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[9]};
  }
`;

export const creator = () => (theme: Theme) =>
  css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.verticalSpaces.large[1]};

    .creatorAvatar {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

    .creatorName {
      margin-right: 4px;
    }
  `;

export const communityLabelSeparator = (theme: Theme) => css`
  &:after {
    content: "·";
  }
  margin-left: 8px;
  margin-right: 8px;
  color: ${theme.community.text.onPageBackgroundPrimaryColor};
`;

export const report = (theme: Theme) => css`
  padding: 0;
  a {
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-content: center;
    width: 100%;
    align-items: center;
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${theme.background.colors.secondary};
    }
  }
`;
