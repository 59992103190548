import React, { memo, useState, FC } from "react";

import ReCAPTCHAV2 from "react-google-recaptcha";

import MetamaskFoxImage from "assets/icons/metamask.svg";
import PolygonMaticImage from "assets/icons/polygon-matic-logo.svg";
import AgreeToPrivacyAndToS from "components/AgreeToPrivacyAndToS";
import AuthErrorView from "components/AuthErrorView";
import Button from "components/Button";
import CardContainer from "components/CardContainer";
import CreateAccountAuthChoice from "components/CreateAccountAuthChoice";
import EmailInput, { EMAIL_ERROR_MESSAGE } from "components/Inputs/EmailInput";
import LoadingIcon from "components/LoadingIcon";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { CAPTCHA_SITE_KEY } from "config";
import AuthOrDivider from "containers/SignIn/AuthOrDivider";
import SwitchBetweenLoginAndCreateAccount from "containers/SignIn/SwitchBetweenLoginAndCreateAccount";
import { useAuthForm } from "hooks/useAuthForm";
import useFeatureFlag from "hooks/useFeatureFlag";
import useSession from "hooks/useSession";
import { maskAddress } from "utils/content";
import { isMetaMaskError } from "utils/errors/authErrors";

import useLogic from "./logic";
import * as styles from "./styles";
import { Props as CreateAccountProps } from "./types";

const CreateAccount: FC<CreateAccountProps> = ({
  isModal = false,
  viewSignIn,
  setViewSignIn,
}) => {
  const [polygonWalletAddress, setPolygonWalletAddress] = useState<string>("");
  const [usedEmail, setUsedEmail] = useState<string>("");
  const [knownError, setKnownError] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const {
    state: { isLoading, reCaptchaCompleted, useMetaMask, authError },
    dispatch,
  } = useAuthForm();
  const { onSelectWallet, onSubmit } = useLogic({
    polygonWalletAddress,
    setPolygonWalletAddress,
  });
  const { magicClear } = useSession();

  const onSubmitClick = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!validEmail) {
      return;
    }
    if (!usedEmail) {
      setKnownError(true);
      setValidEmail(false);
      return;
    }
    setKnownError(false);
    // cannot refresh a reactive var within a hook - useLogic.onSubmit()
    dispatch({ type: "setUseMetaMask", payload: useMetaMask });
    await onSubmit(usedEmail, useMetaMask);
  };

  const onReCaptchaAttempt = async () => {
    dispatch({ type: "reCaptchaCompleted", payload: true });
  };

  // takes argument to avoid race condition between component state and calling function
  const onValidatedSubmitEnter = async (email: string) => {
    setKnownError(false);
    dispatch({ type: "setUseMetaMask", payload: useMetaMask });
    await onSubmit(email, useMetaMask);
  };

  const goSignIntoAccount = () => {
    setKnownError(false);
    dispatch({ type: "clearError" });
    dispatch({ type: "isLoading", payload: false });
    setViewSignIn(true);
    // if user starts sign-in from magic, but then switches to create account
    // useMagicLink() in useSession will lock
    magicClear();
  };

  const MetaMaskConnectWallet = () => {
    return (
      <>
        <Text size="small" additionalCss={styles.subtitle}>
          with MetaMask
        </Text>
        <CardContainer additionalCss={styles.connectWalletContainer}>
          <div css={styles.metaConnect}>
            {polygonWalletAddress ? (
              <div css={styles.rightMetaConnectContainer}>
                <img
                  css={styles.polygonMaticImage}
                  src={PolygonMaticImage}
                  alt={"polygon matic icon"}
                />
              </div>
            ) : (
              ""
            )}
            <div css={styles.middleMetaConnectContainer}>
              <div>
                <Text bold>
                  {polygonWalletAddress ? "Polygon" : "No wallet connected"}
                </Text>
                <Text size="small">
                  {polygonWalletAddress
                    ? maskAddress(polygonWalletAddress)
                    : "Connect a wallet to continue"}
                </Text>
              </div>
            </div>
            <div css={styles.rightMetaConnectContainer}>
              <Button
                size="small"
                type="submit"
                additionalCss={styles.metaConnectButton}
                onClick={onSelectWallet}
              >
                <img
                  css={styles.metamaskImage}
                  src={MetamaskFoxImage}
                  alt={"metamask icon"}
                />
                Connect MetaMask
              </Button>
            </div>
          </div>
        </CardContainer>
      </>
    );
  };

  const accountsRequireCaptchaForCreation = useFeatureFlag(
    "accountsRequireCaptchaForCreation"
  );

  // if not a MetaMask error, then AuthErrorView is showing a graphql error
  return (
    <>
      {isLoading &&
        ((accountsRequireCaptchaForCreation && reCaptchaCompleted) ||
          !accountsRequireCaptchaForCreation) &&
        !useMetaMask && (
          <>
            <Title size="medium" additionalCss={styles.title}>
              Check Your Email
            </Title>
            <Text as="p" additionalCss={styles.description}>
              We emailed a link to <strong>{usedEmail}</strong>
              <br />
              Click the link to finish creating your account.
            </Text>

            <LoadingIcon overwriteTheme="system" css={styles.loading} />
          </>
        )}
      {isLoading &&
        ((accountsRequireCaptchaForCreation && reCaptchaCompleted) ||
          !accountsRequireCaptchaForCreation) &&
        useMetaMask && (
          <>
            <Title size="medium" additionalCss={styles.title}>
              Creating Account
            </Title>

            <LoadingIcon overwriteTheme="system" css={styles.loading} />
          </>
        )}
      {isLoading && accountsRequireCaptchaForCreation && !reCaptchaCompleted && (
        <>
          <ReCAPTCHAV2
            sitekey={CAPTCHA_SITE_KEY as string}
            onChange={onReCaptchaAttempt}
          />
          <AuthOrDivider />
          <CreateAccountAuthChoice />
          <AgreeToPrivacyAndToS />
          <SwitchBetweenLoginAndCreateAccount
            viewSignIn={viewSignIn}
            onSwitch={
              isModal
                ? () => goSignIntoAccount()
                : () => {
                    if (setViewSignIn) setViewSignIn(true);
                  }
            }
          />
        </>
      )}
      {!isLoading && (
        <>
          <Title size="small" additionalCss={styles.formTitle}>
            Create an Account
          </Title>
          {!useMetaMask ? "" : <MetaMaskConnectWallet />}
          {!!authError && isMetaMaskError(authError.type) ? (
            <AuthErrorView />
          ) : null}
          <form
            id="sign-in-modal-form"
            css={useMetaMask ? styles.metaForm : styles.form}
          >
            <div css={styles.emailInputContainer}>
              <EmailInput
                extraCss={styles.emailInput}
                max={70}
                value={usedEmail}
                setValue={setUsedEmail}
                setValid={setValidEmail}
                placeholder="Email Address"
                useOnChange={!validEmail}
                onValidatedSubmit={onValidatedSubmitEnter}
                knownError={!usedEmail && knownError ? EMAIL_ERROR_MESSAGE : ""}
              />
              {!!authError && !isMetaMaskError(authError.type) ? (
                <AuthErrorView onExistingUserResolve={goSignIntoAccount} />
              ) : null}
            </div>
            <Button
              size="xl"
              type="submit"
              disabled={useMetaMask && !polygonWalletAddress}
              onClick={onSubmitClick}
              additionalCss={styles.button}
            >
              Create Account
            </Button>
          </form>
          <AuthOrDivider />
          <CreateAccountAuthChoice />
          <AgreeToPrivacyAndToS />
          <SwitchBetweenLoginAndCreateAccount
            viewSignIn={viewSignIn}
            onSwitch={
              isModal
                ? () => goSignIntoAccount()
                : () => {
                    if (setViewSignIn) setViewSignIn(true);
                  }
            }
          />
        </>
      )}
    </>
  );
};

export default memo(CreateAccount);
