import React from "react";

import { useReactiveVar } from "@apollo/client";

import { communityVar } from "apollo/reactive";
import { ContentFeed } from "containers/CommunityV3/ContentFeed/Feed";

import * as styles from "./styles";

const ContentFeedPage = () => {
  const { slug } = useReactiveVar(communityVar);

  return (
    <div css={styles.layout}>
      <ContentFeed communitySlug={slug} />
    </div>
  );
};

export default ContentFeedPage;
