import { css } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const fieldCard = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;

  ${topMargin("5/12px")}
`;

export const fieldContainerText = css`
  margin-right: 16px;
  flex-grow: 1;
`;
