import { FC, memo } from "react";

import { css, Theme } from "@emotion/react";
import { motion } from "framer-motion";

import { layoutContext } from "apollo/reactive/layout";
import { LoadingIcon as LoadingIconSVG } from "assets/icons.generated";
import { ThemeType } from "styles/theme/theme.types";

export type LoadingIconProps = {
  overwriteTheme?: ThemeType;
  inheritColor?: boolean;
  className?: string;
};

const applyColor =
  (inheritColor: boolean, communityTheme: ThemeType) => (theme: Theme) =>
    css`
      path {
        stroke: ${inheritColor
          ? "currentColor"
          : communityTheme === "system"
          ? theme.text.colors.primary
          : theme.community.text["onPageBackgroundPrimaryColor"]};
      }
    `;

const MotionLoadingIcon = motion(LoadingIconSVG, { forwardMotionProps: true });

const LoadingIcon: FC<LoadingIconProps> = ({
  inheritColor = false,
  overwriteTheme,
  className,
}) => {
  const layout = layoutContext();
  const theme = overwriteTheme ?? layout.theme;

  return (
    <MotionLoadingIcon
      className={className}
      css={applyColor(inheritColor, theme ?? "system")}
      animate={{ rotate: 360 }}
      transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
    />
  );
};

export default memo(LoadingIcon);
