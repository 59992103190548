import { css, Theme } from "@emotion/react";

import { textMediumStyles } from "styles/global/typography";

export const fieldGroup = css`
  margin-bottom: 32px;
  display: block;
`;

export const label = css`
  display: block;
  margin-bottom: 8px;
`;

export const inputContainer = (error: string | undefined) => (theme: Theme) =>
  css`
    ${textMediumStyles(theme)}
    border-radius: ${theme.textInput.border.radius};
    border-width: ${error ? "2px" : `${theme.textInput.border.width}`};
    border-style: ${error ? "solid" : `${theme.textInput.border.style}`};
    border-color: ${error
      ? `${theme.colorMap.destructive.default}`
      : `${theme.textInput.border.color}`};
    background-color: ${theme.textInput.backgroundColor};
    display: flex;
  `;

export const input = (theme: Theme) =>
  css`
    background-color: transparent;
    border: none;
    position: relative;
    appearance: none;
    outline: none;
    margin: 0;
    ${textMediumStyles(theme)}
    font-family: ${theme.text.fontFamily.primary};
    color: ${theme.textInput.textColor};

    &::placeholder {
      opacity: initial;
      color: ${theme.textInput.placeholderColor};
    }
    ${theme.mediaQueries.mediumUp} {
      padding: 20px 16px;
    }
  `;

export const error = (theme: Theme) => css`
  color: ${theme.colorMap.destructive.default};
  padding: 0;
  margin: 8px 0 0 0;
`;

export const suffix = css`
  display: inline;
  margin-left: auto;
  padding: 20px 16px;
`;

export const suffixInner = (theme: Theme) => css`
  display: inline;
  padding: 8px 16px;
  background: ${theme.background.colors.secondary};
  border-radius: 999px;
`;
