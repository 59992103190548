import React, { FC, memo } from "react";

import { TokenBenefit } from "apollo/graphql.generated";
import {
  getBenefitCommunityLink,
  getBenefitShortTitle,
  getIcon,
} from "containers/App/EditCommunity/Tokens/Shared/BenefitsOptions";
import { Maybe } from "utils/types";

import Benefit, { BenefitProps } from "./Benefit";
import * as styles from "./styles";

export type Props = {
  benefits?: Maybe<TokenBenefit[]>;
};

const TokenDetails: FC<Props> = ({ benefits }) => {
  const benefitsToShow: BenefitProps[] =
    benefits?.map((benefit) => ({
      icon: getIcon(benefit.type),
      description: getBenefitShortTitle(benefit.type, benefit.customName),
      link: getBenefitCommunityLink(benefit.type),
    })) || [];

  if (benefitsToShow.length === 0) return null;
  return (
    <div css={styles.benefitList}>
      {benefitsToShow.map((benefit, index) => {
        return (
          <Benefit
            key={index}
            description={benefit.description}
            icon={benefit.icon}
            link={benefit.link}
          />
        );
      })}
    </div>
  );
};

export default memo(TokenDetails);
