import { makeVar } from "@apollo/client";
import { Theme } from "@emotion/react";

import {
  CommunityFlagVariations,
  CreatorAvatar,
  DiscordServer,
  TokenType,
} from "apollo/graphql.generated";
import { CommunityQuery } from "apollo/queries";
import { defaultTheme } from "styles/theme";
import { Maybe } from "utils/types";

export type Community = {
  networkName: string;
  bannerImageUrl: Maybe<string>;
  id: string;
  name: string;
  slug: string;
  imageUrl?: Maybe<string>;
  description?: Maybe<string>;
  creatorAvatar?: Maybe<CreatorAvatar>;
  discordServer: Maybe<DiscordServer>;
  theme: Theme;
  flagVariations?: Maybe<CommunityFlagVariations>;
};

export type Contract = {
  contractAddress: Maybe<string>;
};

export type Token = {
  tokenId: string;
  name: Maybe<string>;
  description: Maybe<string>;
  uri: Maybe<string>;
  tokenType: Maybe<TokenType>;
  supply: Maybe<number>;
  creatorBalance: Maybe<number>;
};

// @todo: Because we have developed without having completed API I had to mock some params. As soon as possible remove mock and use types from generator.
const emptyCommunityPage: Community = {
  id: "",
  name: "",
  slug: "",
  imageUrl: "",
  bannerImageUrl: "",
  description: "",
  networkName: "Polygon",
  discordServer: {
    serverId: "",
    roleId: "",
  },
  creatorAvatar: {
    name: "--avatarName--",
    imageUrl: "https://fakeimg.pl/200/?text=background",
  },
  theme: defaultTheme,
};

const normalizeCommunity = (
  baseCommunity: Community,
  data: Partial<CommunityQuery>
) => {
  return { ...baseCommunity, ...data.community };
};

export const communityVar = makeVar<Community>(emptyCommunityPage);

export const resetCommunityVar = () => {
  communityVar(emptyCommunityPage);
};

export const setCommunityVar = (data: Partial<CommunityQuery>) => {
  communityVar(normalizeCommunity(emptyCommunityPage, data));
};

export const updateCommunityVar = (data: Partial<CommunityQuery>) => {
  communityVar(normalizeCommunity(communityVar(), data));
};
