import React, { useRef, useState, useCallback, useEffect } from "react";

import { css, Theme } from "@emotion/react";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isAtNodeEnd } from "@lexical/selection";
import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  ElementNode,
  GridSelection,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
  TextNode,
} from "lexical";

import { PostEditorLinkEdit as LinkEdit } from "assets/icons.generated";

import CardContainer from "../../../CardContainer";

/**
 * Styles
 */
const styles = {
  linkEditor: css`
    position: absolute;
    z-index: 100;
    top: -10000px;
    left: -10000px;
    margin-top: -5px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s;
  `,
  cardContainer: (theme: Theme) => css`
    padding: 10px;
    border: 0.5px solid ${theme.colorMap.utility.divider};
  `,
  linkInput: (theme: Theme) => css`
    display: block;
    border-radius: 15px;
    background-color: ${theme.background.colors.secondary};
    color: ${theme.text.colors.primary};
    border: 0;
    outline: 0;
    appearance: none;
    position: relative;
    padding: 8px 15px;
    font-family: ${theme.text.fontFamily.primary};
    font-size: ${theme.text.sizes.small};
    width: 100%;
    line-height: 1.3;
    letter-spacing: 0;
    font-weight: bold;

    a {
      color: ${theme.colorMap.accent.primary};
      text-decoration: none;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 25px;
      text-overflow: ellipsis;
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  `,
  linkEdit: css`
    position: absolute;
    top: 7px;
    right: 13px;
    cursor: pointer;
  `,
};

const LowPriority = 1;

function positionEditorElement(editor: HTMLElement, rect: ClientRect | null) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    }px`;
  }
}

function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

function FloatingLinkEditor({ editor }: { editor: LexicalEditor }) {
  const editorRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState<
    RangeSelection | NodeSelection | GridSelection | null
  >(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }

    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();

    if (
      selection !== null &&
      nativeSelection?.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;

      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild as HTMLElement;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      positionEditorElement(editorElem, rect);
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className="link-editor" css={styles.linkEditor}>
      <CardContainer shadow="xl" extraCss={styles.cardContainer}>
        {isEditMode ? (
          <input
            ref={inputRef}
            className="link-input"
            css={styles.linkInput}
            value={linkUrl}
            onChange={(event) => {
              setLinkUrl(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                if (lastSelection !== null) {
                  if (linkUrl !== "") {
                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                  }
                  setEditMode(false);
                }
              } else if (event.key === "Escape") {
                event.preventDefault();
                setEditMode(false);
              }
            }}
          />
        ) : (
          <>
            <div className="link-input" css={styles.linkInput}>
              <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                {linkUrl}
              </a>
              <div
                className="link-edit"
                css={styles.linkEdit}
                role="button"
                tabIndex={0}
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => setEditMode(true)}
              >
                <LinkEdit />
              </div>
            </div>
          </>
        )}
      </CardContainer>
    </div>
  );
}

export default FloatingLinkEditor;
