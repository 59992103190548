/**
 * This function is used to mask the wallet address.
 */
export const maskAddress = (
  string: string | undefined,
  beginningLength = 10,
  endingLength = 5
) => {
  return string
    ? string.substring(0, beginningLength) +
        "..." +
        string.substring(string.length - endingLength)
    : "";
};
