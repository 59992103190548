import React, { forwardRef, memo } from "react";

import { useDropzone } from "react-dropzone";

import { ReactComponent as NoImage } from "assets/icons/no-image.svg";
import Button from "components/Button";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { fileHasExtensions, getImageResolution } from "utils/fileImage";

import * as styles from "./styles";
import { Props } from "./types";

const BannerImageUpload = forwardRef<HTMLInputElement, Props>(
  (
    {
      error,
      label = "",
      labelType = "text",
      primaryDescription = "",
      secondaryDescription = "",
      dragActiveDescription = "",
      onFileAccept,
      onFileReject,
      onRemove,
      imageUrl = "",
      minWidth,
      minHeight,
      maxSize,
      acceptedExtensions = ["jpg", "jpeg", "png"],
      ...rest
    }: Props,
    ref
  ) => {
    const handleUpload = async ([file]: File[]) => {
      if (!fileHasExtensions(file.name, acceptedExtensions)) {
        return onFileReject("Type not accepted");
      }

      if (minWidth && minHeight) {
        const { width, height } = await getImageResolution(file);
        if (width < minWidth || height < minHeight) {
          const errorMsg = `The resolution must be at least ${minWidth}x${minHeight}. Current resolution is ${width}x${height}.`;
          return onFileReject(errorMsg);
        }
      }

      if (maxSize) {
        const maxSizeInBytes = maxSize * 1000000;
        if (file.size > maxSizeInBytes) {
          const errorMsg = `The file size cannot be greater than ${maxSize}MB.`;
          return onFileReject(errorMsg);
        }
      }

      return onFileAccept(file);
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDrop: handleUpload,
      noClick: !!imageUrl,
      noKeyboard: !!imageUrl,
      maxFiles: 1,
      noDrag: !!imageUrl,
    });

    const handleChange = () => open();
    const handleRemove = async () => onRemove && onRemove();

    const renderTitle = () => {
      switch (labelType) {
        case "text":
          return (
            <Text size="medium" bold extraCss={styles.inputLabel}>
              {label}
            </Text>
          );
        case "title":
          return (
            <Title size="xsmall" extraCss={styles.inputLabel}>
              {label}
            </Title>
          );
      }
    };

    return (
      <div {...getRootProps()}>
        {renderTitle()}

        <div
          className={rest.className}
          css={styles.imageLabel(error, imageUrl)}
        >
          {!imageUrl && (
            <>
              <div css={styles.top}>
                <div css={styles.noImage}>
                  <NoImage css={styles.noImageIcon} />
                </div>
              </div>
              <div css={styles.bottom}>
                <Text as="p" size="medium" bold={true}>
                  {!isDragActive ? primaryDescription : dragActiveDescription}
                </Text>
                <Text
                  as="p"
                  size="medium"
                  additionalCss={styles.secondaryText}
                  color="secondary"
                >
                  {secondaryDescription}
                </Text>
              </div>
            </>
          )}
          {imageUrl && (
            <>
              <div css={styles.top}>
                <img css={styles.image} src={imageUrl} alt="Image" />
              </div>
              <div css={styles.buttons}>
                {onRemove && (
                  <Button css={styles.removeButton} onClick={handleRemove}>
                    Remove
                  </Button>
                )}
                <Button css={styles.changeButton} onClick={handleChange}>
                  Change
                </Button>
              </div>
            </>
          )}
        </div>
        <input ref={ref} css={styles.input} {...rest} {...getInputProps()} />
        {error && <p css={styles.error}>{error}</p>}
      </div>
    );
  }
);

export default memo(BannerImageUpload);
