import { css } from "@emotion/react";

import ArchivoBlack from "../../assets/fonts/Archivo/Archivo-Black.woff";
import ArchivoBlack2 from "../../assets/fonts/Archivo/Archivo-Black.woff2";
import ArchivoBold from "../../assets/fonts/Archivo/Archivo-Bold.woff";
import ArchivoBold2 from "../../assets/fonts/Archivo/Archivo-Bold.woff2";
import ArchivoLight from "../../assets/fonts/Archivo/Archivo-Light.woff";
import ArchivoLight2 from "../../assets/fonts/Archivo/Archivo-Light.woff2";
import ArchivoRegular from "../../assets/fonts/Archivo/Archivo-Regular.woff";
import ArchivoRegular2 from "../../assets/fonts/Archivo/Archivo-Regular.woff2";
import ArchivoSemiBold from "../../assets/fonts/Archivo/Archivo-SemiBold.woff";
import ArchivoSemiBold2 from "../../assets/fonts/Archivo/Archivo-SemiBold.woff2";
import ArvoBold from "../../assets/fonts/Arvo/Arvo-Bold.woff";
import ArvoBold2 from "../../assets/fonts/Arvo/Arvo-Bold.woff2";
import ArvoRegular from "../../assets/fonts/Arvo/Arvo-Regular.woff";
import ArvoRegular2 from "../../assets/fonts/Arvo/Arvo-Regular.woff2";
import CanelaTextRegular from "../../assets/fonts/CanelaText/CanelaText-Regular.woff";
import CanelaTextRegular2 from "../../assets/fonts/CanelaText/CanelaText-Regular.woff2";
import CanelaTextThin from "../../assets/fonts/CanelaText/CanelaText-Thin.woff";
import CanelaTextThin2 from "../../assets/fonts/CanelaText/CanelaText-Thin.woff2";
import DegularDisplayMedium from "../../assets/fonts/Degular/Degular-Display-Medium.woff";
import DegularDisplayMedium2 from "../../assets/fonts/Degular/Degular-Display-Medium.woff2";
import DegularMedium from "../../assets/fonts/Degular/Degular-Medium.woff";
import DegularMedium2 from "../../assets/fonts/Degular/Degular-Medium.woff2";
import FrauncesBlack from "../../assets/fonts/Fraunces/Fraunces-Black.woff";
import FrauncesBlack2 from "../../assets/fonts/Fraunces/Fraunces-Black.woff2";
import FrauncesBold from "../../assets/fonts/Fraunces/Fraunces-Bold.woff";
import FrauncesBold2 from "../../assets/fonts/Fraunces/Fraunces-Bold.woff2";
import FrauncesLight from "../../assets/fonts/Fraunces/Fraunces-Light.woff";
import FrauncesLight2 from "../../assets/fonts/Fraunces/Fraunces-Light.woff2";
import FrauncesRegular from "../../assets/fonts/Fraunces/Fraunces-Regular.woff";
import FrauncesRegular2 from "../../assets/fonts/Fraunces/Fraunces-Regular.woff2";
import FrauncesSemiBold from "../../assets/fonts/Fraunces/Fraunces-SemiBold.woff";
import FrauncesSemiBold2 from "../../assets/fonts/Fraunces/Fraunces-SemiBold.woff2";
import InterBlack from "../../assets/fonts/Inter/Inter-Black.woff";
import InterBlack2 from "../../assets/fonts/Inter/Inter-Black.woff2";
import InterBold from "../../assets/fonts/Inter/Inter-Bold.woff";
import InterBold2 from "../../assets/fonts/Inter/Inter-Bold.woff2";
import InterLight from "../../assets/fonts/Inter/Inter-Light.woff";
import InterLight2 from "../../assets/fonts/Inter/Inter-Light.woff2";
import InterRegular from "../../assets/fonts/Inter/Inter-Regular.woff";
import InterRegular2 from "../../assets/fonts/Inter/Inter-Regular.woff2";
import InterSemiBold from "../../assets/fonts/Inter/Inter-SemiBold.woff";
import InterSemiBold2 from "../../assets/fonts/Inter/Inter-SemiBold.woff2";
import MerriweatherBlack from "../../assets/fonts/Merriweather/Merriweather-Black.woff";
import MerriweatherBlack2 from "../../assets/fonts/Merriweather/Merriweather-Black.woff2";
import MerriweatherBold from "../../assets/fonts/Merriweather/Merriweather-Bold.woff";
import MerriweatherBold2 from "../../assets/fonts/Merriweather/Merriweather-Bold.woff2";
import MerriweatherLight from "../../assets/fonts/Merriweather/Merriweather-Light.woff";
import MerriweatherLight2 from "../../assets/fonts/Merriweather/Merriweather-Light.woff2";
import MerriweatherRegular from "../../assets/fonts/Merriweather/Merriweather-Regular.woff";
import MerriweatherRegular2 from "../../assets/fonts/Merriweather/Merriweather-Regular.woff2";
import MontserratBlack from "../../assets/fonts/Montserrat/Montserrat-Black.woff";
import MontserratBlack2 from "../../assets/fonts/Montserrat/Montserrat-Black.woff2";
import MontserratBold from "../../assets/fonts/Montserrat/Montserrat-Bold.woff";
import MontserratBold2 from "../../assets/fonts/Montserrat/Montserrat-Bold.woff2";
import MontserratLight from "../../assets/fonts/Montserrat/Montserrat-Light.woff";
import MontserratLight2 from "../../assets/fonts/Montserrat/Montserrat-Light.woff2";
import MontserratRegular from "../../assets/fonts/Montserrat/Montserrat-Regular.woff";
import MontserratRegular2 from "../../assets/fonts/Montserrat/Montserrat-Regular.woff2";
import MontserratSemiBold from "../../assets/fonts/Montserrat/Montserrat-SemiBold.woff";
import MontserratSemiBold2 from "../../assets/fonts/Montserrat/Montserrat-SemiBold.woff2";
import NunitoBlack from "../../assets/fonts/Nunito/Nunito-Black.woff";
import NunitoBlack2 from "../../assets/fonts/Nunito/Nunito-Black.woff2";
import NunitoBold from "../../assets/fonts/Nunito/Nunito-Bold.woff";
import NunitoBold2 from "../../assets/fonts/Nunito/Nunito-Bold.woff2";
import NunitoLight from "../../assets/fonts/Nunito/Nunito-Light.woff";
import NunitoLight2 from "../../assets/fonts/Nunito/Nunito-Light.woff2";
import NunitoRegular from "../../assets/fonts/Nunito/Nunito-Regular.woff";
import NunitoRegular2 from "../../assets/fonts/Nunito/Nunito-Regular.woff2";
import NunitoSemiBold from "../../assets/fonts/Nunito/Nunito-SemiBold.woff";
import NunitoSemiBold2 from "../../assets/fonts/Nunito/Nunito-SemiBold.woff2";
import OswaldBold from "../../assets/fonts/Oswald/Oswald-Bold.woff";
import OswaldBold2 from "../../assets/fonts/Oswald/Oswald-Bold.woff2";
import OswaldLight from "../../assets/fonts/Oswald/Oswald-Light.woff";
import OswaldLight2 from "../../assets/fonts/Oswald/Oswald-Light.woff2";
import OswaldRegular from "../../assets/fonts/Oswald/Oswald-Regular.woff";
import OswaldRegular2 from "../../assets/fonts/Oswald/Oswald-Regular.woff2";
import OswaldSemiBold from "../../assets/fonts/Oswald/Oswald-SemiBold.woff";
import OswaldSemiBold2 from "../../assets/fonts/Oswald/Oswald-SemiBold.woff2";
import OutfitBlack from "../../assets/fonts/Outfit/Outfit-Black.woff";
import OutfitBlack2 from "../../assets/fonts/Outfit/Outfit-Black.woff2";
import OutfitBold from "../../assets/fonts/Outfit/Outfit-Bold.woff";
import OutfitBold2 from "../../assets/fonts/Outfit/Outfit-Bold.woff2";
import OutfitLight from "../../assets/fonts/Outfit/Outfit-Light.woff";
import OutfitLight2 from "../../assets/fonts/Outfit/Outfit-Light.woff2";
import OutfitRegular from "../../assets/fonts/Outfit/Outfit-Regular.woff";
import OutfitRegular2 from "../../assets/fonts/Outfit/Outfit-Regular.woff2";
import OutfitSemiBold from "../../assets/fonts/Outfit/Outfit-SemiBold.woff";
import OutfitSemiBold2 from "../../assets/fonts/Outfit/Outfit-SemiBold.woff2";
import PoppinsBlack from "../../assets/fonts/Poppins/Poppins-Black.woff";
import PoppinsBlack2 from "../../assets/fonts/Poppins/Poppins-Black.woff2";
import PoppinsBold from "../../assets/fonts/Poppins/Poppins-Bold.woff";
import PoppinsBold2 from "../../assets/fonts/Poppins/Poppins-Bold.woff2";
import PoppinsLight from "../../assets/fonts/Poppins/Poppins-Light.woff";
import PoppinsLight2 from "../../assets/fonts/Poppins/Poppins-Light.woff2";
import PoppinsMedium from "../../assets/fonts/Poppins/Poppins-Medium.woff";
import PoppinsMedium2 from "../../assets/fonts/Poppins/Poppins-Medium.woff2";
import PoppinsRegular from "../../assets/fonts/Poppins/Poppins-Regular.woff";
import PoppinsRegular2 from "../../assets/fonts/Poppins/Poppins-Regular.woff2";
import RakkasNormal from "../../assets/fonts/Rakkas/rakkas-v12-latin-regular.woff";
import RakkasNormal2 from "../../assets/fonts/Rakkas/rakkas-v12-latin-regular.woff2";
import SourceCodeProBlack from "../../assets/fonts/SourceCodePro/SourceCodePro-Black.woff";
import SourceCodeProBlack2 from "../../assets/fonts/SourceCodePro/SourceCodePro-Black.woff2";
import SourceCodeProBold from "../../assets/fonts/SourceCodePro/SourceCodePro-Bold.woff";
import SourceCodeProBold2 from "../../assets/fonts/SourceCodePro/SourceCodePro-Bold.woff2";
import SourceCodeProLight from "../../assets/fonts/SourceCodePro/SourceCodePro-Light.woff";
import SourceCodeProLight2 from "../../assets/fonts/SourceCodePro/SourceCodePro-Light.woff2";
import SourceCodeProRegular from "../../assets/fonts/SourceCodePro/SourceCodePro-Regular.woff";
import SourceCodeProRegular2 from "../../assets/fonts/SourceCodePro/SourceCodePro-Regular.woff2";
import SourceCodeProSemiBold from "../../assets/fonts/SourceCodePro/SourceCodePro-SemiBold.woff";
import SourceCodeProSemiBold2 from "../../assets/fonts/SourceCodePro/SourceCodePro-SemiBold.woff2";
import SourceSansProBlack from "../../assets/fonts/SourceSansPro/SourceSansPro-Black.woff";
import SourceSansProBlack2 from "../../assets/fonts/SourceSansPro/SourceSansPro-Black.woff2";
import SourceSansProBold from "../../assets/fonts/SourceSansPro/SourceSansPro-Bold.woff";
import SourceSansProBold2 from "../../assets/fonts/SourceSansPro/SourceSansPro-Bold.woff2";
import SourceSansProLight from "../../assets/fonts/SourceSansPro/SourceSansPro-Light.woff";
import SourceSansProLight2 from "../../assets/fonts/SourceSansPro/SourceSansPro-Light.woff2";
import SourceSansProRegular from "../../assets/fonts/SourceSansPro/SourceSansPro-Regular.woff";
import SourceSansProRegular2 from "../../assets/fonts/SourceSansPro/SourceSansPro-Regular.woff2";
import SourceSansProSemiBold from "../../assets/fonts/SourceSansPro/SourceSansPro-SemiBold.woff";
import SourceSansProSemiBold2 from "../../assets/fonts/SourceSansPro/SourceSansPro-SemiBold.woff2";
import SourceSerifProBlack from "../../assets/fonts/SourceSerifPro/SourceSerifPro-Black.woff";
import SourceSerifProBlack2 from "../../assets/fonts/SourceSerifPro/SourceSerifPro-Black.woff2";
import SourceSerifProBold from "../../assets/fonts/SourceSerifPro/SourceSerifPro-Bold.woff";
import SourceSerifProBold2 from "../../assets/fonts/SourceSerifPro/SourceSerifPro-Bold.woff2";
import SourceSerifProLight from "../../assets/fonts/SourceSerifPro/SourceSerifPro-Light.woff";
import SourceSerifProLight2 from "../../assets/fonts/SourceSerifPro/SourceSerifPro-Light.woff2";
import SourceSerifProRegular from "../../assets/fonts/SourceSerifPro/SourceSerifPro-Regular.woff";
import SourceSerifProRegular2 from "../../assets/fonts/SourceSerifPro/SourceSerifPro-Regular.woff2";
import SourceSerifProSemiBold from "../../assets/fonts/SourceSerifPro/SourceSerifPro-SemiBold.woff";
import SourceSerifProSemiBold2 from "../../assets/fonts/SourceSerifPro/SourceSerifPro-SemiBold.woff2";
import SpaceGroteskBold from "../../assets/fonts/SpaceGrotesk/SpaceGrotesk-Bold.woff";
import SpaceGroteskBold2 from "../../assets/fonts/SpaceGrotesk/SpaceGrotesk-Bold.woff2";
import SpaceGroteskLight from "../../assets/fonts/SpaceGrotesk/SpaceGrotesk-Light.woff";
import SpaceGroteskLight2 from "../../assets/fonts/SpaceGrotesk/SpaceGrotesk-Light.woff2";
import SpaceGroteskRegular from "../../assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular.woff";
import SpaceGroteskRegular2 from "../../assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular.woff2";
import SpaceGroteskSemiBold from "../../assets/fonts/SpaceGrotesk/SpaceGrotesk-SemiBold.woff";
import SpaceGroteskSemiBold2 from "../../assets/fonts/SpaceGrotesk/SpaceGrotesk-SemiBold.woff2";
import SpaceMonoBold from "../../assets/fonts/SpaceMono/SpaceMono-Bold.woff";
import SpaceMonoBold2 from "../../assets/fonts/SpaceMono/SpaceMono-Bold.woff2";
import SpaceMonoRegular from "../../assets/fonts/SpaceMono/SpaceMono-Regular.woff";
import SpaceMonoRegular2 from "../../assets/fonts/SpaceMono/SpaceMono-Regular.woff2";
import SpectralBold from "../../assets/fonts/Spectral/Spectral-Bold.woff";
import SpectralBold2 from "../../assets/fonts/Spectral/Spectral-Bold.woff2";
import SpectralExtraBold from "../../assets/fonts/Spectral/Spectral-ExtraBold.woff";
import SpectralExtraBold2 from "../../assets/fonts/Spectral/Spectral-ExtraBold.woff2";
import SpectralLight from "../../assets/fonts/Spectral/Spectral-Light.woff";
import SpectralLight2 from "../../assets/fonts/Spectral/Spectral-Light.woff2";
import SpectralRegular from "../../assets/fonts/Spectral/Spectral-Regular.woff";
import SpectralRegular2 from "../../assets/fonts/Spectral/Spectral-Regular.woff2";
import SpectralSemiBold from "../../assets/fonts/Spectral/Spectral-SemiBold.woff";
import SpectralSemiBold2 from "../../assets/fonts/Spectral/Spectral-SemiBold.woff2";
import TTNormsProBd from "../../assets/fonts/TTNormsPro/TTNormsProBd.woff";
import TTNormsProBd2 from "../../assets/fonts/TTNormsPro/TTNormsProBd.woff2";
import TTNormsProBdIt from "../../assets/fonts/TTNormsPro/TTNormsProBdIt.woff";
import TTNormsProBdIt2 from "../../assets/fonts/TTNormsPro/TTNormsProBdIt.woff2";
import TTNormsProDmBd from "../../assets/fonts/TTNormsPro/TTNormsProDmBd.woff";
import TTNormsProDmBd2 from "../../assets/fonts/TTNormsPro/TTNormsProDmBd.woff2";
import TTNormsProDmBdIt from "../../assets/fonts/TTNormsPro/TTNormsProDmBdIt.woff";
import TTNormsProDmBdIt2 from "../../assets/fonts/TTNormsPro/TTNormsProDmBdIt.woff2";
import TTNormsProMd from "../../assets/fonts/TTNormsPro/TTNormsProMd.woff";
import TTNormsProMd2 from "../../assets/fonts/TTNormsPro/TTNormsProMd.woff2";
import TTNormsProMdIt from "../../assets/fonts/TTNormsPro/TTNormsProMdIt.woff";
import TTNormsProMdIt2 from "../../assets/fonts/TTNormsPro/TTNormsProMdIt.woff2";
import TTNormsProNormal from "../../assets/fonts/TTNormsPro/TTNormsProNormal.woff";
import TTNormsProNormal2 from "../../assets/fonts/TTNormsPro/TTNormsProNormal.woff2";
import TTNormsProNormalIt from "../../assets/fonts/TTNormsPro/TTNormsProNormalIt.woff";
import TTNormsProNormalIt2 from "../../assets/fonts/TTNormsPro/TTNormsProNormalIt.woff2";
import WorkSansMd from "../../assets/fonts/WorkSans/work-sans-v13-latin-500.woff";
import WorkSansMd2 from "../../assets/fonts/WorkSans/work-sans-v13-latin-500.woff2";
import WorkSansMdIt from "../../assets/fonts/WorkSans/work-sans-v13-latin-500italic.woff";
import WorkSansMdIt2 from "../../assets/fonts/WorkSans/work-sans-v13-latin-500italic.woff2";
import WorkSansDmBd from "../../assets/fonts/WorkSans/work-sans-v13-latin-600.woff";
import WorkSansDmBd2 from "../../assets/fonts/WorkSans/work-sans-v13-latin-600.woff2";
import WorkSansDmBdIt from "../../assets/fonts/WorkSans/work-sans-v13-latin-600italic.woff";
import WorkSansDmBdIt2 from "../../assets/fonts/WorkSans/work-sans-v13-latin-600italic.woff2";
import WorkSansBd from "../../assets/fonts/WorkSans/work-sans-v13-latin-700.woff";
import WorkSansBd2 from "../../assets/fonts/WorkSans/work-sans-v13-latin-700.woff2";
import WorkSansBdIt from "../../assets/fonts/WorkSans/work-sans-v13-latin-700italic.woff";
import WorkSansBdIt2 from "../../assets/fonts/WorkSans/work-sans-v13-latin-700italic.woff2";
import WorkSansNormalIt from "../../assets/fonts/WorkSans/work-sans-v13-latin-italic.woff";
import WorkSansNormalIt2 from "../../assets/fonts/WorkSans/work-sans-v13-latin-italic.woff2";
import WorkSansNormal from "../../assets/fonts/WorkSans/work-sans-v13-latin-regular.woff";
import WorkSansNormal2 from "../../assets/fonts/WorkSans/work-sans-v13-latin-regular.woff2";

// Helper

export type FontFamily = {
  name: string;
  normal: FontWeights;
  italic?: FontWeights;
};

export type FontWeights = Record<string, string[]>;

export const createFontFaces = (family: FontFamily) => {
  let styles = ``;

  const face = (
    weight: string,
    formats: string[],
    style: "normal" | "italic"
  ) => {
    const woff = formats[0];
    const woff2 = formats[1];

    return `
      @font-face {
        font-family: '${family.name}';
        src: url(${woff2}) format('woff2'),
            url(${woff}) format('woff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: auto;
      }
    `;
  };

  for (const [weight, formats] of Object.entries(family.normal)) {
    styles += face(weight, formats, "normal");
  }

  if (family?.italic) {
    for (const [weight, formats] of Object.entries(family.italic)) {
      styles += face(weight, formats, "italic");
    }
  }

  return styles;
};

/** Source Sans Pro */
const SourceCodeProFont = createFontFaces({
  name: `Source Code Pro`,
  normal: {
    300: [SourceCodeProLight, SourceCodeProLight2],
    400: [SourceCodeProRegular, SourceCodeProRegular2],
    600: [SourceCodeProSemiBold, SourceCodeProSemiBold2],
    700: [SourceCodeProBold, SourceCodeProBold2],
    900: [SourceCodeProBlack, SourceCodeProBlack2],
  },
});

/** Space Mono */
const SpaceMonoFont = createFontFaces({
  name: `Space Mono`,
  normal: {
    400: [SpaceMonoRegular, SpaceMonoRegular2],
    700: [SpaceMonoBold, SpaceMonoBold2],
  },
});

/** Fraunces */
const FrauncesFont = createFontFaces({
  name: `Fraunces`,
  normal: {
    300: [FrauncesLight, FrauncesLight2],
    400: [FrauncesRegular, FrauncesRegular2],
    600: [FrauncesSemiBold, FrauncesSemiBold2],
    700: [FrauncesBold, FrauncesBold2],
    900: [FrauncesBlack, FrauncesBlack2],
  },
});

/** Spectral */
const SpectralFont = createFontFaces({
  name: `Spectral`,
  normal: {
    300: [SpectralLight, SpectralLight2],
    400: [SpectralRegular, SpectralRegular2],
    600: [SpectralSemiBold, SpectralSemiBold2],
    700: [SpectralBold, SpectralBold2],
    800: [SpectralExtraBold, SpectralExtraBold2],
  },
});

/** Arvo */
const ArvoFont = createFontFaces({
  name: `Arvo`,
  normal: {
    400: [ArvoRegular, ArvoRegular2],
    700: [ArvoBold, ArvoBold2],
  },
});

/** Source Serif Pro */
const SourceSerifProFont = createFontFaces({
  name: `Source Serif Pro`,
  normal: {
    300: [SourceSerifProLight, SourceSerifProLight2],
    400: [SourceSerifProRegular, SourceSerifProRegular2],
    600: [SourceSerifProSemiBold, SourceSerifProSemiBold2],
    700: [SourceSerifProBold, SourceSerifProBold2],
    900: [SourceSerifProBlack, SourceSerifProBlack2],
  },
});

/** Merriweather */
const MerriweatherFont = createFontFaces({
  name: `Merriweather`,
  normal: {
    300: [MerriweatherLight, MerriweatherLight2],
    400: [MerriweatherRegular, MerriweatherRegular2],
    700: [MerriweatherBold, MerriweatherBold2],
    900: [MerriweatherBlack, MerriweatherBlack2],
  },
});

/** Outfit */
const OutfitFont = createFontFaces({
  name: `Outfit`,
  normal: {
    300: [OutfitLight, OutfitLight2],
    400: [OutfitRegular, OutfitRegular2],
    600: [OutfitSemiBold, OutfitSemiBold2],
    700: [OutfitBold, OutfitBold2],
    900: [OutfitBlack, OutfitBlack2],
  },
});

/** Oswald */
const OswaldFont = createFontFaces({
  name: `Oswald`,
  normal: {
    300: [OswaldLight, OswaldLight2],
    400: [OswaldRegular, OswaldRegular2],
    600: [OswaldSemiBold, OswaldSemiBold2],
    700: [OswaldBold, OswaldBold2],
  },
});

/** Nunito */
const NunitoFont = createFontFaces({
  name: `Nunito`,
  normal: {
    300: [NunitoLight, NunitoLight2],
    400: [NunitoRegular, NunitoRegular2],
    600: [NunitoSemiBold, NunitoSemiBold2],
    700: [NunitoBold, NunitoBold2],
    900: [NunitoBlack, NunitoBlack2],
  },
});

/** Source Sans Pro */
const SourceSansProFont = createFontFaces({
  name: `Source Sans Pro`,
  normal: {
    300: [SourceSansProLight, SourceSansProLight2],
    400: [SourceSansProRegular, SourceSansProRegular2],
    600: [SourceSansProSemiBold, SourceSansProSemiBold2],
    700: [SourceSansProBold, SourceSansProBold2],
    900: [SourceSansProBlack, SourceSansProBlack2],
  },
});

/** Space Grotesk */
const SpaceGroteskFont = createFontFaces({
  name: `Space Grotesk`,
  normal: {
    300: [SpaceGroteskLight, SpaceGroteskLight2],
    400: [SpaceGroteskRegular, SpaceGroteskRegular2],
    600: [SpaceGroteskSemiBold, SpaceGroteskSemiBold2],
    700: [SpaceGroteskBold, SpaceGroteskBold2],
  },
});

/** Archivo */
const ArchivoFont = createFontFaces({
  name: `Archivo`,
  normal: {
    300: [ArchivoLight, ArchivoLight2],
    400: [ArchivoRegular, ArchivoRegular2],
    600: [ArchivoSemiBold, ArchivoSemiBold2],
    700: [ArchivoBold, ArchivoBold2],
    900: [ArchivoBlack, ArchivoBlack2],
  },
});

/** Poppins */
const PoppinsFont = createFontFaces({
  name: `Poppins`,
  normal: {
    300: [PoppinsLight, PoppinsLight2],
    400: [PoppinsRegular, PoppinsRegular2],
    500: [PoppinsMedium, PoppinsMedium2],
    700: [PoppinsBold, PoppinsBold2],
    900: [PoppinsBlack, PoppinsBlack2],
  },
});

/** Montserrat */
const MontserratFont = createFontFaces({
  name: `Montserrat`,
  normal: {
    300: [MontserratLight, MontserratLight2],
    400: [MontserratRegular, MontserratRegular2],
    600: [MontserratSemiBold, MontserratSemiBold2],
    700: [MontserratBold, MontserratBold2],
    900: [MontserratBlack, MontserratBlack2],
  },
});

/** Canela Text */
const CanelaText = createFontFaces({
  name: `Canela`,
  normal: {
    100: [CanelaTextThin, CanelaTextThin2],
    400: [CanelaTextRegular, CanelaTextRegular2],
  },
});

/** Rakkas */
const RakkasFont = createFontFaces({
  name: `Rakkas`,
  normal: {
    400: [RakkasNormal, RakkasNormal2],
  },
});

/** TT Norms Pro */
const TTNormsProFont = createFontFaces({
  name: `TT Norms Pro`,
  normal: {
    400: [TTNormsProNormal, TTNormsProNormal2],
    500: [TTNormsProMd, TTNormsProMd2],
    600: [TTNormsProDmBd, TTNormsProDmBd2],
    700: [TTNormsProBd, TTNormsProBd2],
  },
  italic: {
    400: [TTNormsProNormalIt, TTNormsProNormalIt2],
    500: [TTNormsProMdIt, TTNormsProMdIt2],
    600: [TTNormsProDmBdIt, TTNormsProDmBdIt2],
    700: [TTNormsProBdIt, TTNormsProBdIt2],
  },
});

/** Work Sans */
const WorkSansFont = createFontFaces({
  name: `Work Sans`,
  normal: {
    400: [WorkSansNormal, WorkSansNormal2],
    500: [WorkSansMd, WorkSansMd2],
    600: [WorkSansDmBd, WorkSansDmBd2],
    700: [WorkSansBd, WorkSansBd2],
  },
  italic: {
    400: [WorkSansNormalIt, WorkSansNormalIt2],
    500: [WorkSansMdIt, WorkSansMdIt2],
    600: [WorkSansDmBdIt, WorkSansDmBdIt2],
    700: [WorkSansBdIt, WorkSansBdIt2],
  },
});

/** Inter */
const InterFont = createFontFaces({
  name: `Inter`,
  normal: {
    300: [InterLight, InterLight2],
    400: [InterRegular, InterRegular2],
    600: [InterSemiBold, InterSemiBold2],
    700: [InterBold, InterBold2],
    900: [InterBlack, InterBlack2],
  },
});

/** Degular */
const DegularFont = createFontFaces({
  name: `Degular`,
  normal: {
    500: [DegularMedium, DegularMedium2],
  },
});
const DegularDisplayFont = createFontFaces({
  name: `Degular Display`,
  normal: {
    500: [DegularDisplayMedium, DegularDisplayMedium2],
  },
});

/*
 * Font name translator for params
 * NOTE: Update this lists every time you add a new font.
 * */
export const fontFamilyMap: Record<string, string> = {
  Inter: `'Inter', sans-serif`,
  Montserrat: `'Montserrat', sans-serif`,
  "Work+Sans": `'Work Sans', sans-serif`,
  Poppins: `'Poppins', sans-serif`,
  Archivo: `'Archivo', sans-serif`,
  "Space+Grotesk": `'Space Grotesk', sans-serif`,
  "Source+Sans+Pro": `'Source Sans Pro', sans-serif`,
  Nunito: `'Nunito', sans-serif`,
  Oswald: `'Oswald', sans-serif`,
  Outfit: `'Outfit', sans-serif`,
  Merriweather: `'Merriweather', serif`,
  "Source+Serif+Pro": `'Source Serif Pro', serif`,
  Arvo: `'Arvo', serif`,
  Spectral: `'Spectral', serif`,
  Fraunces: `'Fraunces', serif`,
  Rakkas: `'Rakkas', cursive`,
  "Space+Mono": `'Space Mono', monospace`,
  "Source+Code+Pro": `'Source Code Pro', monospace`,
};

export const fontFamilySelectOptionsMap = [
  { value: "Archivo", label: "Archivo" },
  { value: "Arvo", label: "Arvo" },
  { value: "Fraunces", label: "Fraunces" },
  { value: "Inter", label: "Inter" },
  { value: "Merriweather", label: "Merriweather" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Nunito", label: "Nunito" },
  { value: "Oswald", label: "Oswald" },
  { value: "Outfit", label: "Outfit" },
  { value: "Poppins", label: "Poppins" },
  { value: "Rakkas", label: "Rakkas" },
  { value: "Source+Code+Pro", label: "Source Code Pro" },
  { value: "Source+Sans+Pro", label: "Source Sans Pro" },
  { value: "Source+Serif+Pro", label: "Source Serif Pro" },
  { value: "Space+Grotesk", label: "Space Grotesk" },
  { value: "Space+Mono", label: "Space Mono" },
  { value: "Spectral", label: "Spectral" },
  { value: "Work+Sans", label: "Work Sans" },
];

export const fontFamilyWeightMap: Record<string, string[]> = {
  Inter: ["300", "400", "600", "700", "900"],
  Montserrat: ["300", "400", "600", "700", "900"],
  "Work+Sans": ["400", "500", "600", "700"],
  Poppins: ["300", "400", "500", "700", "900"],
  Archivo: ["300", "400", "600", "700", "900"],
  "Space+Grotesk": ["300", "400", "600", "700"],
  "Source+Sans+Pro": ["300", "400", "600", "700", "900"],
  Nunito: ["300", "400", "600", "700", "900"],
  Oswald: ["300", "400", "600", "700"],
  Outfit: ["300", "400", "600", "700", "900"],
  Merriweather: ["300", "400", "700", "900"],
  "Source+Serif+Pro": ["300", "400", "600", "700", "900"],
  Arvo: ["400", "700"],
  Spectral: ["300", "400", "600", "700", "800"],
  Fraunces: ["300", "400", "600", "700", "900"],
  Rakkas: ["400"],
  "Space+Mono": ["400", "700"],
  "Source+Code+Pro": ["300", "400", "600", "700", "900"],
};

/*
 * All fonts that can be loaded
 */
export const fontImports = css`
  ${CanelaText}
  ${TTNormsProFont}
  ${DegularFont}
  ${DegularDisplayFont}
  ${InterFont}
  ${MontserratFont}
  ${WorkSansFont}
  ${PoppinsFont}
  ${ArchivoFont}
  ${SpaceGroteskFont}
  ${SourceSansProFont}
  ${NunitoFont}
  ${OswaldFont}
  ${OutfitFont}
  ${MerriweatherFont}
  ${SourceSerifProFont}
  ${ArvoFont}
  ${SpectralFont}
  ${FrauncesFont}
  ${RakkasFont}
  ${SpaceMonoFont}
  ${SourceCodeProFont}
`;

export default fontImports;
