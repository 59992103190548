import React, { FC, memo } from "react";

import { HashLink } from "react-router-hash-link";

import Button from "components/Button";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { APP_URL } from "config";
import useAppLocation from "hooks/useAppLocation";
import { scrollWithOffset } from "utils/links";

import * as styles from "./styles";

type Props = {
  communitySlug: string;
};

const MissingCommunityInformation: FC<Props> = ({ communitySlug }) => {
  const { getEditCommunityURL } = useAppLocation();
  return (
    <CardContainer background="secondary" extraCss={styles.noTokensContainer}>
      <Title extraCss={styles.noTokensTitle} size="large">
        Add Your Community Details Before Minting a Token
      </Title>
      <Text extraCss={styles.noTokensTitle}>
        From the Home tab, add a Creator Avatar, Community Logo, and Community
        Description.
      </Text>
      <HashLink
        to={getEditCommunityURL(
          communitySlug,
          `${APP_URL.editCommunity.base}/${APP_URL.editCommunity.home}#communityInformation`
        )}
        scroll={scrollWithOffset(75)}
      >
        <Button size="medium" extraCss={styles.bodyMintNewTokenButton}>
          Add Community Details
        </Button>
      </HashLink>
    </CardContainer>
  );
};

export default memo(MissingCommunityInformation);
