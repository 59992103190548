import { css } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const spacing = (spaced: boolean) => css`
  ${spaced &&
  css`
    ${topMargin("4/8px")}
  `}
`;
