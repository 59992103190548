import { ethers } from "ethers";

import { ethersProvider as magicEthersProvider } from "../services/magicClient";

export const getMetamaskEthClient = () => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  return (window as any).ethereum;
};

export const getWeb3Provider = (authMethod?: string) => {
  const isMagic = authMethod === "MAGIC_LINK";
  if (isMagic) {
    return magicEthersProvider;
  }
  // TODO: ensure that chainId matches expected
  return new ethers.providers.Web3Provider(getMetamaskEthClient());
};
