import React, { FC, useMemo } from "react";

import { useReactiveVar } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { BenefitType } from "apollo/graphql.generated";
import useCommunityDetailsV3 from "apollo/hooks/useCommunityDetailsV3";
import { communityVar } from "apollo/reactive";
import FullscreenLoader from "components/FullscreenLoader";
import Layout from "components/Layout";
import { CommunitySEO } from "components/Layout/SEO";
import { COMMUNITY_PAGE_TITLE, COMMUNITY_URL } from "config";
import JoinedCommunity from "containers/CommunityV3/JoinedCommunity";
import NotFound from "containers/Error/NotFound";
import ServerError from "containers/Error/ServerError";
import SignIn from "containers/SignIn";
import useSession from "hooks/useSession";

const CommunityV3: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    user,
    error: userError,
    userQuery: { loading: meLoading },
  } = useSession();

  const communityData = useReactiveVar(communityVar);

  const {
    data: communityDetailsData,
    loading: communityDetailsLoading,
    error: communityDetailsError,
  } = useCommunityDetailsV3();

  const communityDetails = communityDetailsData?.community;

  const userTokens = (user?.tokens ?? []).filter(
    (token) => token.community.slug === communityData?.slug
  );

  const benefitMap = useMemo(
    () => ({
      [BenefitType.CONTENT_FEED]: {
        route: COMMUNITY_URL.posts,
        hasBenefit: !!communityDetails?.contentFeed?.benefit.hasBenefit,
        hasAccess: !!communityDetails?.contentFeed?.benefit.hasAccess,
      },
      [BenefitType.DISCORD]: {
        route: COMMUNITY_URL.discord,
        hasBenefit:
          !!communityDetails?.discord?.benefit.hasBenefit &&
          !!communityData.discordServer,
      },
      [BenefitType.SHOPIFY]: {
        route: COMMUNITY_URL.shop,
        hasBenefit: !!communityDetails?.shop?.benefit.hasBenefit,
      },
      [BenefitType.RELEASE_PARTY]: {
        route: "",
        hasBenefit: false,
      },
      [BenefitType.CUSTOM]: {
        route: "",
        hasBenefit: false,
      },
      [BenefitType.RELEASE_PARTY]: {
        route: "",
        hasBenefit: false,
      },
    }),
    [communityDetails]
  );

  const isNotFoundBenefit = useMemo(
    () =>
      Object.values(benefitMap).some(
        (benefitData) =>
          benefitData.route === pathname && !benefitData.hasBenefit
      ),
    [benefitMap, pathname]
  );

  if (!communityData) {
    if (userError || communityDetailsError) {
      return <ServerError />;
    }

    if (!user) {
      return <SignIn />;
    }

    return <NotFound />;
  }

  if (meLoading || communityDetailsLoading) {
    return <FullscreenLoader />;
  }

  if (isNotFoundBenefit) {
    return <NotFound />;
  }

  const communityHasContentFeedBenefit =
    benefitMap[BenefitType.CONTENT_FEED].hasBenefit;
  const userHasContentFeedBenefit =
    benefitMap[BenefitType.CONTENT_FEED].hasAccess;
  const isHome = pathname === COMMUNITY_URL.home;
  if (isHome) {
    if (communityHasContentFeedBenefit && userHasContentFeedBenefit) {
      navigate(benefitMap[BenefitType.CONTENT_FEED].route);
    } else {
      navigate(COMMUNITY_URL.about);
    }
  }

  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.HOME} />

      <Layout
        navLayout="community"
        backgroundShow={true}
        backgroundColor="community"
      >
        <JoinedCommunity
          benefitMap={benefitMap}
          userTokens={userTokens}
          contractAddress={
            communityDetailsData?.community?.polygonContract?.contractAddress ??
            ""
          }
        />
      </Layout>
    </>
  );
};

export default CommunityV3;
