import React, { FC, ReactElement, useState } from "react";

import { ReactComponent as CloseX } from "assets/icons/close-x-within-circle.svg";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import * as styles from "./styles";

export interface Props {
  title: string;
  text: string;
  icon: ReactElement;
  hasClose: boolean;
}

const StatusBox: FC<Props> = ({ title, text, icon, hasClose }) => {
  const [hideStatusBox, setHideStatusBox] = useState<boolean>(false);

  if (hideStatusBox) return <></>;

  return (
    <CardContainer extraCss={styles.container} border borderRadius="large">
      <div css={styles.topRow}>
        <div css={styles.icon}>{icon}</div>
        {hasClose && (
          <CloseX
            onClick={() => setHideStatusBox(true)}
            css={styles.closeIcon}
          />
        )}
      </div>
      <Title extraCss={styles.textColorAccentPrimary} size="small">
        {title}
      </Title>
      <Text extraCss={styles.textColorAccentPrimary}>{text}</Text>
    </CardContainer>
  );
};

export default StatusBox;
