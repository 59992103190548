import { makeVar } from "@apollo/client";

import {
  DistributableToken,
  OfferMethod,
} from "utils/distribution/distributionMethod";

type OfferPack = {
  offer: OfferMethod;
  tokens: DistributableToken[];
};

export const offerVar = makeVar<OfferPack | null>(null);

export const setOfferVar = (pack: OfferPack | null) => {
  offerVar(pack);
};
