import React, { FC, memo, useState } from "react";

import Button from "components/Button";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { EXTERNAL_URL } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";

import * as editCommunityStyles from "../../../styles";
import type { CreatorCommunity } from "../../EditCommunityHome";
import * as integrationsStyles from "../styles";

import EditStoreModal from "./Modals/EditStore";
import UnlinkStoreModal from "./Modals/UnlinkStore";
import * as styles from "./styles";

export interface Props {
  community: NonNullable<CreatorCommunity>;
}

const Shopify: FC<Props> = ({ community }) => {
  const allowSelfOnboard = useFeatureFlag("shopifyOnboardWithBuyButton");
  const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const buyButtons = community.shop?.buyButtons;
  const hasBuyButtons = !!buyButtons && buyButtons.length > 0;
  const isLinked = hasBuyButtons;

  // LINKED AND UNLINKED STATES

  const Linked = () => (
    <>
      <i css={integrationsStyles.dot(isLinked)} />
      <Text extraCss={integrationsStyles.fieldContainerText}>
        Shopify Store Linked
      </Text>
      {allowSelfOnboard && (
        <div css={integrationsStyles.buttonsGroup}>
          <button
            css={editCommunityStyles.textButton("default")}
            onClick={() => setIsEditModalOpen(true)}
          >
            Edit
          </button>
          <button
            css={editCommunityStyles.textButton("destructive")}
            onClick={() => setIsUnlinkModalOpen(true)}
          >
            Unlink
          </button>
        </div>
      )}
    </>
  );

  const NotLinked = () => (
    <>
      <i css={integrationsStyles.dot(isLinked)} />
      <Text
        as="p"
        size="medium"
        extraCss={integrationsStyles.fieldContainerText}
      >
        No Shopify store linked
      </Text>
      <>
        {allowSelfOnboard ? (
          <Button
            size="small"
            extraCss={integrationsStyles.button}
            onClick={() => setIsEditModalOpen(true)}
          >
            Add Store
          </Button>
        ) : (
          <Button
            size="small"
            extraCss={integrationsStyles.button}
            onClick={() => (location.href = EXTERNAL_URL.SUPPORT)}
          >
            Contact Us
          </Button>
        )}
      </>
    </>
  );

  return (
    <>
      <Title size="xsmall" additionalCss={styles.title}>
        Shopify Store
      </Title>
      <Text size="small">
        Linking a Shopify store lets you grant access to that store to members
        of your community or people who own a specific token.
      </Text>
      <CardContainer
        border
        background={"primary"}
        extraCss={integrationsStyles.fieldCard}
      >
        {isLinked ? <Linked /> : <NotLinked />}
      </CardContainer>
      <EditStoreModal
        communityId={community.id}
        shopifyConfiguration={community.shop}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
      />
      <UnlinkStoreModal
        communityId={community.id}
        isOpen={isUnlinkModalOpen}
        onClose={() => setIsUnlinkModalOpen(false)}
      />
    </>
  );
};

export default memo(Shopify);
