import { css, Theme } from "@emotion/react";

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const content = (theme: Theme) => css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  padding: 0 0 64px 0;
  text-align: center;

  h1 {
    margin-bottom: ${theme.verticalSpaces.medium[6]};
  }

  p {
    margin-bottom: ${theme.verticalSpaces.large[10]};
    a {
      transition: color 0.6s ${theme.animations.easeOutQuart};
      color: ${theme.colorMap.accent.default};
      font-weight: ${theme.text.fontWeights.bold};

      @media (hover: hover) {
        &:hover {
          color: ${theme.colorMap.accent[600]};
        }
      }
    }
  }

  > a {
    max-width: 307px;
  }

  ${theme.mediaQueries.smallOnly} {
    padding: 0 0 40px 0;
  }

  ${theme.mediaQueries.smallOnly} {
    h1 {
      margin-bottom: ${theme.verticalSpaces.small[6]};
    }
  }
`;

export const illustration = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[10]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.small[9]};
  }
`;

export const title = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[8]};
`;
