import React, { FC, useEffect } from "react";

import { ModalType, updateModalVar } from "apollo/reactive";
import { resolveBenefitToken } from "apollo/utils/community";
import { ReactComponent as DiscordIcon } from "assets/icons/discord_logo.svg";
import LoadingIcon from "components/LoadingIcon";
import CommunityButton from "components/_Community/Button";
import CommunityTitle from "components/_Community/Title";
import BenefitCard from "containers/CommunityV2/Benefits/BenefitCard";
import DiscordStatusContainer from "containers/CommunityV2/DiscordStatusContainer";
import {
  DiscordDetailsDocument,
  useDiscordDetailsQuery,
} from "containers/CommunityV3/DiscordDetails/query.graphql.generated";
import useSession from "hooks/useSession";
import { apolloClient } from "services/apolloClient";
import { buildDiscordUrls, handleOpenDiscord } from "utils/discord";

import * as styles from "./styles";
import { Props } from "./types";

const DiscordDetails: FC<Props> = ({ communitySlug, discordServer }) => {
  const { user } = useSession();
  const { data, loading } = useDiscordDetailsQuery({
    variables: { slug: communitySlug },
  });

  useEffect(() => {
    if (user) {
      apolloClient.refetchQueries({
        include: [DiscordDetailsDocument],
      });
    }
  }, [user]);

  if (loading) {
    return (
      <div css={styles.loadingIcon}>
        <LoadingIcon />
      </div>
    );
  }

  const userHasDiscordLinked = user?.discord?.user?.id;

  const discordStatistics = data?.community?.discordStatistics;
  const discordBenefit = data?.community?.discord.benefit;

  const { discordWebUrl, discordAppUrl } = buildDiscordUrls(discordServer);
  const discordBenefitToken = resolveBenefitToken(discordBenefit);

  const handleLinkDiscord = () => {
    updateModalVar({ showModal: ModalType.LINK_DISCORD_ACCOUNT });
  };
  const openDiscord = () => {
    handleOpenDiscord(discordAppUrl, discordWebUrl);
  };

  return (
    <div>
      <BenefitCard
        unlocked={!!discordBenefit?.hasAccess}
        unlockedByToken={discordBenefitToken}
        communityContractAddress={
          data?.community?.polygonContract?.contractAddress
        }
        header={
          <>
            <CommunityTitle
              color="onPrimaryBackgroundPrimaryColor"
              size="medium"
            >
              Discord
            </CommunityTitle>
            {discordStatistics && (
              <DiscordStatusContainer
                members={discordStatistics?.members}
                activeNow={discordStatistics?.activeCount}
              />
            )}
          </>
        }
      >
        <div css={styles.discordButton}>
          <CommunityButton
            color="onPrimaryBackgroundBackgroundColor"
            onClick={userHasDiscordLinked ? openDiscord : handleLinkDiscord}
            disabled={!discordBenefit?.hasAccess}
          >
            <DiscordIcon />{" "}
            {userHasDiscordLinked || !discordBenefit?.hasAccess
              ? "Visit Discord"
              : "Link Discord Account"}
          </CommunityButton>
        </div>
      </BenefitCard>
    </div>
  );
};

export default DiscordDetails;
