import React, { memo } from "react";

import { css, Theme } from "@emotion/react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { Pencil as EditIcon } from "assets/icons.generated";

import AttachmentFloatingButton from "./AttachmentFloatingButton";

interface AttachmentEditButtonProps {
  onEditClickHandler?(): void;
}

const AttachmentEditButton = ({
  onEditClickHandler,
}: AttachmentEditButtonProps) => {
  const [editor] = useLexicalComposerContext();

  if (!editor.isEditable()) return null;

  return (
    <AttachmentFloatingButton
      icon={
        <EditIcon
          css={(theme: Theme) =>
            css`
              path {
                stroke: ${theme.colorMap.accent.default};
              }
            `
          }
        />
      }
      onClick={onEditClickHandler}
    >
      Edit
    </AttachmentFloatingButton>
  );
};

export default memo(AttachmentEditButton);
