import { useEffect } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import type { LexicalCommand } from "lexical";
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";

import { $createTokenNode, TokenNode } from "../nodes/TokenNode";

export const INSERT_TOKEN_COMMAND: LexicalCommand<string> = createCommand();

export default function TokenAttachmentPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([TokenNode])) {
      throw new Error("TokenPlugin: TokenNode not registered on editor");
    }

    return editor.registerCommand<string>(
      INSERT_TOKEN_COMMAND,
      (payload) => {
        const tokenNode = $createTokenNode(payload);

        $insertNodeToNearestRoot(tokenNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
