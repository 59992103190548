import { StrictMode } from "react";

import { ApolloProvider } from "@apollo/client";
import { render } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import App from "app";
import { apolloClient } from "services/apolloClient";

render(
  <StrictMode>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </ApolloProvider>
  </StrictMode>,
  document.getElementById("root")
);
