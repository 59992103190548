import React, { FC, memo } from "react";

import Title from "components/Typography/Title";

import { CreatorCommunity } from "../EditCommunityHome";

import Discord from "./Discord";
import OpenSea from "./OpenSea";
import Shopify from "./Shopify";

export interface Props {
  community: NonNullable<CreatorCommunity>;
}

const Integrations: FC<Props> = ({ community }) => {
  const discordServerId = community.discordServer?.serverId;

  return (
    <>
      <Title size="medium">Integrations</Title>
      <Discord discordServerId={discordServerId} />
      <Shopify community={community} />
      <OpenSea community={community} />
    </>
  );
};

export default memo(Integrations);
