import { css } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const container = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px 0 20px;
  width: 100%;

  ${topMargin("9/32px")}
`;

export const middleContainer = css`
  max-width: 684px;
  width: 684px;
  margin-left: 20px;
`;

export const commentContainer = css`
  flex: 1;
  width: 100%;
  ${topMargin("4/8px")}
`;

export const commenterUsernameLabel = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
`;

export const commenterUsername = css``;

export const deleteComment = css`
  margin-left: 8px;
  color: red;
  font-weight: bold;
`;

export const commenterAvatar = css`
  width: 56px;
  ${topMargin("9/32px")}
`;

export const commenterEmptyAvatar = css`
  width: 56px;
`;

export const commentText = css`
  color: black;
`;
