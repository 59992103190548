import { memo } from "react";

import { Interpolation, Theme } from "@emotion/react";

import { TextTag } from "styles/theme/theme.types";

export type UnSafeInnerHTMLProps = {
  html: string;
  as?: TextTag;
  css?: Interpolation<Theme>;
};

export const UnSafeInnerHTML = memo(
  ({ html, as = "div", ...props }: UnSafeInnerHTMLProps) => {
    const Tag = as as keyof JSX.IntrinsicElements;
    // TODO: need custom sanitization to verify it's a known iframe from youtube, vimeo, etc.
    return <Tag {...props} dangerouslySetInnerHTML={{ __html: html }} />;
  }
);
