import { css } from "@emotion/react";

export const statusContainer = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > span {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;
