import React from "react";

import { css } from "@emotion/react";

import { EtherSymbolWhite as EthSvg } from "assets/icons.generated";
import { currencyCode } from "utils/formatting/currency";

const iconCss = css`
  svg {
    height: 16px;
    width: 10px;
    margin: 0;
    position: relative;
    top: -1px;
  }
`;

const EthIcon = () => (
  <span css={iconCss}>
    <EthSvg />
  </span>
);

type Props = {
  price?: string | null;
  currency?: string | null;
};

export const CryptoPrice: React.FC<Props> = ({ price, currency }) => {
  const icon = currency === "WETH" ? <EthIcon /> : null;
  return (
    <>
      {icon} {price} {currencyCode(currency)}
    </>
  );
};
