import { css, Theme } from "@emotion/react";

import { ThemeType } from "styles/theme/theme.types";

import { LayoutType, NavBarProps } from ".";

/** Layout */

export const container =
  (onScroll: boolean, pageTheme: ThemeType, layout: LayoutType) =>
  (theme: Theme) =>
    css`
      transition-property: background, box-shadow;
      transition-duration: 0.6s;
      transition-timing-function: ${theme.animations.easeOutQuart};

      padding: 0 12px 0 24px;
      margin-bottom: ${theme.verticalSpaces.large[6]};

      display: flex;
      flex-direction: row;
      align-items: center;

      min-height: 64px;

      ${theme.mediaQueries.smallOnly} {
        min-height: 56px;
        padding: 0 12px 0 20px;
        margin-bottom: ${theme.verticalSpaces.large[6]};
      }

      ${onScroll &&
      css`
        box-shadow: 0 1px 12px rgb(0 0 0 / 3%), 0 1px 8px rgb(0 0 0 / 3%),
          0 0 0 0.5px rgb(0 0 0 / 5%);
        background: ${theme.background.colors.primary};
      `}

      ${onScroll &&
      (layout === "createNewCommunity" || layout === "signin") &&
      css`
        box-shadow: 0 1px 12px rgb(0 0 0 / 3%), 0 1px 8px rgb(0 0 0 / 3%),
          0 0 0 0.5px rgb(0 0 0 / 5%);
        background: ${theme.invertedBackground.colors.primary};
      `}

      ${onScroll &&
      layout === "account" &&
      css`
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
      `}

      ${onScroll && pageTheme === "community" && layout !== "tokenDetail"
        ? css`
            box-shadow: 0 1px 12px rgb(0 0 0 / 3%), 0 1px 8px rgb(0 0 0 / 3%),
              0 0 0 0.5px rgb(0 0 0 / 5%);
            background: ${theme.community.pageBackground.backgroundColor};
          `
        : undefined}
    `;

const stylesByLayout = {
  default: css`
    justify-content: space-between;
  `,
  community: css`
    justify-content: flex-end;
  `,
  creatorCommunity: css`
    justify-content: space-between;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  `,
  action: css`
    justify-content: flex-end;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  `,
  account: css`
    justify-content: space-between;
  `,
  signin: css`
    justify-content: center;
  `,
  createNewCommunity: css`
    justify-content: space-between;
  `,
  tokenDetail: css`
    justify-content: space-between;
  `,
};

/*
 */
export const containerLayout = (layout: NavBarProps["layout"]) =>
  stylesByLayout[layout];

/** Action */
export const layoutTextColor =
  (layout: NavBarProps["layout"]) => (theme: Theme) => {
    if (layout == "action" || layout == "creatorCommunity") {
      return css`
        color: ${theme.invertedText.colors.primary};
      `;
    }
    return css``;
  };

export const layoutColor =
  (layout: NavBarProps["layout"]) => (theme: Theme) => {
    if (layout == "creatorCommunity") {
      return css`
        background-color: ${theme.invertedBackground.colors.primary};
      `;
    }

    return css``;
  };

export const actionTitleContainer = css`
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

export const actionTitle = (theme: Theme) => css`
  padding-left: 12px;
  flex: 1;
  text-align: center;

  p {
    display: block;
    font-weight: ${theme.text.fontWeights.bold};

    small {
      display: block;
    }
  }
`;

export const cancelButtonContainer = css`
  height: 100%;
  min-width: 16px;
  display: flex;
  padding: 8px;
`;

export const cancelButton = css`
  padding: 0;
  background-color: transparent;
`;

export const cancelIcon = (theme: Theme) => css`
  flex: 1;
  display: flex;
  color: ${theme.buttons.secondary.textColor};

  path {
    fill: currentColor;
  }
`;

/** Community */
export const creatorCommunity = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

export const creatorCommunityMedia = css`
  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const creatorCommunityDetails = (theme: Theme) => css`
  padding-left: 12px;
  flex: 1;

  p {
    display: block;

    small {
      display: block;
    }
  }

  ${theme.mediaQueries.smallOnly} {
    display: none;
  }
`;

/** Brand */

export const brand = css``;

export const logoLarge = (theme: Theme) => css`
  ${theme.mediaQueries.smallOnly} {
    display: none;
  }
`;

export const logoSmall = (theme: Theme) => css`
  ${theme.mediaQueries.mediumUp} {
    display: none;
  }
`;

/** Nav */

export const nav = css`
  display: flex;
  align-items: center;
  flex-direction: row;

  > * {
    margin-right: 4px;
    margin-top: 4px;
    margin-bottom: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const myAccountButton = (theme: Theme) => css`
  padding: 7px 12px;
  line-height: 1;
  width: auto;

  span {
    font-size: 12px;
    line-height: 18px;

    ${theme.mediaQueries.smallOnly} {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
`;
