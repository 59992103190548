import { css, Theme } from "@emotion/react";

import { textWithLink, systemTextLink } from "styles/global/typography";

/** Error Wrapper */

export const errorWrapper = (theme: Theme) => css`
  max-width: 400px;

  h3 {
    margin-bottom: ${theme.verticalSpaces.small[6]};
  }

  ${textWithLink}
  p {
    a,
    button {
      ${systemTextLink}
    }
  }

  > a,
  > button {
    margin-top: ${theme.verticalSpaces.small[9]};
  }
`;
