import React from "react";

import { useReactiveVar } from "@apollo/client";

import { communityVar } from "apollo/reactive";
import ShopDetails from "containers/CommunityV3/ShopDetails/ShopDetails";

import * as styles from "./styles";

const ShopPage = () => {
  const { slug } = useReactiveVar(communityVar);

  return (
    <div css={styles.layout}>
      <ShopDetails communitySlug={slug} />
    </div>
  );
};

export default ShopPage;
