import React, { FC, memo, ComponentProps, ReactNode } from "react";

import { useReactiveVar } from "@apollo/client";
import cx from "classnames";

import { bannerVar } from "apollo/reactive/banner";
import Background, {
  BackgroundProps as BackgroundProps,
} from "components/Background";
import GlobalStatusBanner from "components/Banners/GlobalStatusBanner";
import Footer from "components/Footer";
import LayoutBar from "components/LayoutBar";
import NavBar, { NavBarProps } from "components/NavBar";

import styles from "./style.module.scss";

type MainProps = ComponentProps<"main">;

export type LayoutProps = MainProps & {
  children: ReactNode;
  className?: string;
  showHeader?: boolean;
  showFooter?: boolean;
  navLayout?: NavBarProps["layout"];
  title?: string;
  subtitle?: string;
  cancelRedirect?: string;
  cancelCallback?(): void;
  page?: number;
  prevCallback?: () => void;
  backgroundShow?: BackgroundProps["show"];
  backgroundColor?: BackgroundProps["color"];
  backgroundImageUrl?: BackgroundProps["imageUrl"];
  lightNavBarContent?: boolean;
};

const Layout: FC<LayoutProps> = ({
  showHeader = true,
  showFooter = true,
  navLayout = "default",
  className,
  title,
  subtitle,
  cancelRedirect,
  cancelCallback,
  page,
  prevCallback,
  backgroundShow = false,
  backgroundColor = "primary",
  backgroundImageUrl,
  children,
  ...rest
}) => {
  const { show: showBanner, messages: bannerMessages } =
    useReactiveVar(bannerVar);

  return (
    <Background
      show={backgroundShow}
      color={backgroundColor}
      imageUrl={backgroundImageUrl}
    >
      <LayoutBar>
        {showBanner && (
          <GlobalStatusBanner background="light" messages={bannerMessages} />
        )}
        {showHeader && (
          <NavBar
            layout={navLayout}
            title={title}
            subtitle={subtitle}
            cancelRedirect={cancelRedirect}
            cancelCallback={cancelCallback}
            page={page}
            prevCallback={prevCallback}
          />
        )}
      </LayoutBar>
      <main className={cx(styles.main, className)} {...rest}>
        {children}
      </main>
      {showFooter && <Footer />}
    </Background>
  );
};

export default memo(Layout);
