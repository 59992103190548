import React from "react";

import { css } from "@emotion/react";

import { ReactComponent as EthSvg } from "assets/icons/ether-symbol.svg";
import Text from "components/Typography/Text";
import { currencyCode } from "utils/formatting/currency";

import { Dollars } from "./Dollars";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,
  crypto: css`
    display: flex;
    flex-direction: row;
    gap: 5px;
  `,
};

const EthIcon = () => <EthSvg />;

type Props = {
  price: string;
  currency: string;
  inUSD?: number | null;
};

export const CryptoAmount: React.FC<Props> = ({ price, currency, inUSD }) => (
  <div css={styles.container}>
    <div css={styles.crypto}>
      {currency === "WETH" && <EthIcon />}
      <Text bold>{price}</Text> {currencyCode(currency)}
    </div>
    {inUSD && (
      <Text color="secondary" as="div">
        ≈ <Dollars amount={inUSD} />
      </Text>
    )}
  </div>
);
