import React, { FC, memo, PropsWithChildren } from "react";

import { Interpolation, Theme } from "@emotion/react";

import Title from "components/Typography/Title";

import * as styles from "./styles";

export interface TokenFieldProps {
  title: string;
  bold?: boolean;
  spaced?: boolean;
  extraCss?: Interpolation<Theme>;
}

const TokenField: FC<PropsWithChildren<TokenFieldProps>> = ({
  title,
  spaced = false,
  extraCss,
  children,
}) => (
  <div css={extraCss}>
    <Title size="xsmall">{title}</Title>
    <div css={styles.spacing(spaced)}>{children}</div>
  </div>
);

export default memo(TokenField);
