import { css, Theme } from "@emotion/react";

import { parseBorderType } from "styles/theme/communityTheme";

import { CommunityButtonProps } from "./index";

export const base = (theme: Theme) =>
  css`
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ${theme.animations.easeOutQuart};
    position: relative;
    text-align: center;
    width: 100%;
    border-style: solid;

    font-family: ${theme.community.button.textFontFamily};
    font-weight: ${theme.community.button.textFontWeight};
    text-transform: ${theme.community.button.textTextTransform};
    letter-spacing: ${theme.community.button.textTextTransform};
    border-radius: ${parseBorderType(theme.community.button.borderType)};

    &:active {
      transform: scale(0.97);
    }

    &:disabled {
      opacity: 0.25;
    }

    @media (hover: hover) {
      &:hover {
        transform: scale(1.02);
      }
    }
  `;

export const bySize =
  (size: NonNullable<CommunityButtonProps["size"]>, hasHeader: boolean) =>
  (theme: Theme) =>
    css`
      padding: ${hasHeader && (size === "xl" || size === "large")
        ? theme.buttons.sizes[size].headerPadding
        : theme.buttons.sizes[size].padding};
      font-size: ${theme.buttons.sizes[size].fontSize};
    `;

export const byColor =
  (color: NonNullable<CommunityButtonProps["color"]>) => (theme: Theme) => {
    switch (color) {
      case "onPrimaryBackgroundBackgroundColor":
        return css`
          background-color: ${theme.community.button
            .onPrimaryBackgroundBackgroundColor};
          color: ${theme.community.button.onPrimaryBackgroundTextColor};
          border-color: ${theme.community.button
            .onPrimaryBackgroundBorderColor};
          border-width: ${theme.community.button
            .onPrimaryBackgroundBorderWidth};
        `;
      case "onPageBackgroundBackgroundColor":
      default:
        return css`
          background-color: ${theme.community.button
            .onPageBackgroundBackgroundColor};
          color: ${theme.community.button.onPageBackgroundTextColor};
          border-color: ${theme.community.button.onPageBackgroundBorderColor};
          border-width: ${theme.community.button.onPageBackgroundBorderWidth};
        `;
    }
  };

export const loading = (loadingType: CommunityButtonProps["loadingType"]) =>
  loadingType === "replace"
    ? css`
        span {
          opacity: 0;
        }
      `
    : css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      `;

export const icon = (loadingType: CommunityButtonProps["loadingType"]) =>
  loadingType === "replace"
    ? css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `
    : null;

export const contentWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const textWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const buttonText = css`
  width: 100%;
  svg path {
    fill: currentColor;
  }
`;

export const headerText = (theme: Theme) => css`
  width: 100%;
  font-size: ${theme.text.sizes.xsmall};

  svg path {
    fill: currentColor;
  }
`;
