import { css } from "@emotion/react";

import { bottomMargin } from "styles/global/layout";

export const fieldPropertyContainer = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  ${bottomMargin("10/40px")};
`;
