// import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";

import { logError } from "services/logger";

import { envConfig } from "../config";

import { getMetamaskEthClient } from "./web3";

const prodChainId = "0x89"; // 137 in hex
const stagingChainId = "0x13881"; // 80001 in hex
const localChainId = "0x539"; // 1337 in hex
const { polygon } = envConfig;

async function requestAccount() {
  return await getMetamaskEthClient().request({
    method: "eth_requestAccounts",
  });
}

export const existsMetaMaskPlugin = (): boolean => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  return typeof (window as any).ethereum !== "undefined";
};

export const isSignedIntoMetaMask = async () => {
  let signedIn = false;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  if (typeof (window as any).ethereum !== "undefined") {
    try {
      await requestAccount();
      signedIn = true;
    } catch (error) {
      logError(error);
    }
  }
  return signedIn;
};

const isProd = () => polygon.chainId === parseInt(prodChainId, 16);
const isLocal = () => polygon.chainId === parseInt(localChainId, 16);
export enum NetworkType {
  "LOCAL",
  "TESTNET",
  "POLYGON",
}
export const onCorrectNetwork = async () => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const networkName = isProd()
    ? NetworkType.POLYGON
    : isLocal()
    ? NetworkType.LOCAL
    : NetworkType.TESTNET;
  if (typeof (window as any).ethereum !== "undefined") {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    const network = await provider.getNetwork();
    return {
      ok: network.chainId === envConfig.polygon.chainId,
      network: networkName,
    };
  }
  return { ok: false, network: networkName };
};

const polygonMainnetData = {
  chainId: prodChainId,
  chainName: "Polygon Mainnet",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: [polygon.networkUrl],
  blockExplorerUrls: ["https://polygonscan.com/"],
};

const polygonTestnetData = {
  chainId: stagingChainId,
  chainName: "Mumbai Testnet",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: [polygon.networkUrl],
  blockExplorerUrls: ["https://mumbai.polygonscan.com"],
};

const localhostData = {
  chainId: localChainId,
  chainName: "Localhost 8545",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: [polygon.networkUrl],
};

const addChainNetwork = async () => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  if (typeof (window as any).ethereum !== "undefined") {
    const addData = isProd()
      ? polygonMainnetData
      : isLocal()
      ? localhostData
      : polygonTestnetData;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const tx = await (window as any).ethereum
      .request({ method: "wallet_addEthereumChain", params: [addData] })
      .catch();
    // transaction is null or undefined on success
    return !tx;
  }
  return false;
};

export const switchToCorrectChainNetwork = async () => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  if (typeof (window as any).ethereum !== "undefined") {
    const data = [
      {
        chainId: isProd()
          ? prodChainId
          : isLocal()
          ? localChainId
          : stagingChainId,
      },
    ];
    try {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      const tx = await (window as any).ethereum.request({
        method: "wallet_switchEthereumChain",
        params: data,
      });
      // transaction is null or undefined on success
      return !tx;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          return await addChainNetwork();
        } catch (addError) {
          return false;
        }
      }
      // handle other "switch" errors
    }
  }
  return false;
};

export const getMetamaskAddress = async () => {
  let addr = "";
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  if (typeof (window as any).ethereum !== "undefined") {
    try {
      const addressRes = await requestAccount();
      addr = addressRes[0];
    } catch (error) {
      logError(error);
    }
  }
  return addr;
};

export const getMetamaskSignature = async () => {
  let signature = "";
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  if (typeof (window as any).ethereum !== "undefined") {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    const signer = provider.getSigner(); // Your current metamask account;
    try {
      signature = await signer.signMessage(Date.now().toString());
    } catch (error) {
      logError(error);
    }
  }
  return signature;
};

export const connectMetamask = async () => {
  // A Web3Provider wraps a standard Web3 provider, which is
  // what MetaMask injects as window.ethereum into each page
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);

  // The MetaMask plugin also allows signing transactions to
  // send ether and pay to change state within the blockchain.
  // For this, you need the account signer...
  return provider.getSigner();
};
