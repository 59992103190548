import { memo } from "react";
import type { FC } from "react";

/* Styles */
import LoadingIcon from "components/LoadingIcon";
import { ThemeType } from "styles/theme/theme.types";

import styles from "./style.module.scss";

/* Components */
export type FullscreenLoaderProps = {
  overwriteTheme?: ThemeType;
  inheritColor?: boolean;
};

const FullscreenLoader: FC<FullscreenLoaderProps> = ({
  inheritColor,
  overwriteTheme,
}) => (
  <div className={styles.container}>
    <LoadingIcon inheritColor={inheritColor} overwriteTheme={overwriteTheme} />
  </div>
);

export default memo(FullscreenLoader);
