import { css } from "@emotion/react";

export const orDividerContainer = css`
  margin: 32px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const dividerText = css`
  text-align: center;
  font-weight: bold;
  padding: 0 10px;
  position: relative;
`;

export const dividerBox = css`
  display: flex;
  flex: 1;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
`;
