import React, { FC, memo, useCallback, useEffect, useState } from "react";

import { css } from "@emotion/react";
import { useParams } from "react-router-dom";

import { Contest } from "apollo/graphql.generated";
import { ModalType, updateModalVar } from "apollo/reactive";
import { ReactComponent as Plus } from "assets/icons/plus-sign.svg";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import useAppLocation from "hooks/useAppLocation";
import { topMargin } from "styles/global/layout";

import { Token } from "../types";

import AddedClaimPage from "./AddedClaimPage";
import * as styles from "./styles";

export interface ClaimPage {
  id: string;
  name: string;
  link: string;
  claimsRemaining: number;
  qrCodeRef?: HTMLCanvasElement;
  linkCopied: boolean;
  communityId: string;
}

export interface Props {
  readonly: boolean;
  token?: Token;
  communityId?: string;
  contests?: Partial<Contest>[];
  handleCloseModal?: () => void;
}

const containerStyle = css`
  ${topMargin("10/40px")}
`;

const ClaimPages: FC<Props> = ({
  readonly,
  token,
  communityId,
  contests,
  handleCloseModal,
}) => {
  const [claimPages, setClaimPages] = useState<ClaimPage[]>([]);
  const [linkCopiedIndex, setLinkCopiedIndex] = useState<number>(-1);
  const { getClaimAbsoluteURL } = useAppLocation();

  const { communitySlug = "", tokenId = "1" } = useParams();

  const showCreateClaimPageModal = useCallback(() => {
    if (readonly) return;

    const dataMap: Record<string, string> = {
      communityId: communityId || "",
      communitySlug,
      tokenId,
    };

    updateModalVar({
      showModal: ModalType.CREATE_CLAIM_PAGE,
      data: dataMap,
      onClose: handleCloseModal,
    });
  }, [tokenId, communityId, token, readonly]);

  useEffect(() => {
    if (!contests) return;
    setClaimPages(
      contests?.map((contest) => ({
        id: contest.id || "",
        link: getClaimAbsoluteURL(communitySlug, contest.id || ""),
        claimsRemaining:
          (contest.tokensMetadata?.maxQuantity || 0) -
          (contest.tokensMetadata?.totalGrantedQuantity || 0) *
            (contest.tokensMetadata?.quantity || 0),
        name: contest.contestPage?.title || "",
        linkCopied: false,
        communityId: communityId || "",
      }))
    );
  }, [contests, communitySlug, communityId]);

  return (
    <div css={containerStyle}>
      <Title size="xsmall">Private Claim Pages</Title>
      <Text>
        Create private pages where people can claim this token for free using a
        link or QR code you give them.
      </Text>
      <CardContainer border extraCss={styles.statusContainer}>
        {claimPages.map((claimPage: ClaimPage, index) => (
          <AddedClaimPage
            key={claimPage.id}
            claimPage={claimPage}
            index={index}
            linkCopied={index === linkCopiedIndex}
            onCopyLink={(index) => setLinkCopiedIndex(index)}
          />
        ))}
        <div onClick={showCreateClaimPageModal} css={styles.addNewClaimPage}>
          <div css={styles.claimPageIconContainer}>
            <Plus css={styles.plusIcon(readonly)} />
          </div>
          <div css={styles.claimPageText(readonly)}>
            <Text extraCss={styles.claimPageText(readonly)}>
              Create a Private Claim Page
            </Text>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default memo(ClaimPages);
