import { Cart } from "apollo/reactive";
import { STORAGE_KEYS } from "config";

export const setBrowserStorageCart = (items: Cart) => {
  localStorage.setItem(STORAGE_KEYS.cart, JSON.stringify(items));
};

export const getBrowserStorageCart = () => {
  const data = localStorage.getItem(STORAGE_KEYS.cart);
  return data ? JSON.parse(data) : null;
};

export const removeBrowserStorageCart = () => {
  localStorage.removeItem(STORAGE_KEYS.cart);
};
