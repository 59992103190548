import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const benefit = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

export const spacedFields = css`
  ${topMargin("10/40px")};
`;

export const priceCardContainer = css`
  text-align: left;
`;

export const priceContainer = css`
  display: flex;
  justify-content: space-between;
`;

export const currency = css`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const editOfferButton = css`
  padding: 4px;
  margin: -4px;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline currentColor;
    }
  }
`;

export const createOfferButton = css`
  width: 100%;
  ${topMargin("4/8px")};
`;

export const createOfferCardContainer = css`
  text-align: left;
`;

export const createOffer = (theme: Theme) => css`
  color: ${theme.colorMap.accent.primary};
`;

export const publicCardContainer = css`
  ${topMargin("5/12px")}
`;

export const makeAvailableButton = css`
  text-align: left;
  width: 100%;
`;

export const createSaleCardContainer = css`
  ${topMargin("5/12px")}
`;

export const createSaleButton = css`
  width: 100%;
`;

export const purchaseDivider = css`
  margin-top: 16px;
`;

export const purchaseDetails = (theme: Theme) => css`
  display: flex;
  gap: 16px;
  margin-top: 16px;

  ${theme.mediaQueries.smallOnly} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const purchaseUrl = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  flex-grow: 1;
`;

export const purchaseActions = css`
  display: flex;
  flex-shrink: 0;
`;

export const purchaseClipboard = css`
  flex-shrink: 0;
  margin-right: 16px;
`;

export const purchaseQrCode = css`
  flex-shrink: 0;
`;
