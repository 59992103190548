import { css, Theme } from "@emotion/react";

export const container = css``;

export const tag = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[1]};
  line-height: 100%;

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[1]};
  }
`;
