import "sanitize.css";
import { css, Theme } from "@emotion/react";

import { fontImports } from "./global/fonts";
import { typographyStyles } from "./global/typography";

// TODO: move input, button and textarea styles to their own component

export const globalStyles = (theme: Theme) => css`
  ${fontImports}
  ${typographyStyles}
  html {
    height: 100%;
  }

  body {
    font-family: ${theme.text.fontFamily.primary};
    font-weight: ${theme.text.fontWeights.normal};
    font-size: ${theme.rootFontSize};
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  input {
    border: none;
  }

  textarea {
    font-family: inherit;
    border: none;
  }

  button {
    border: 0;
    padding: 0;
    background-color: transparent;
    font: inherit;
    color: inherit;
    cursor: pointer;
    margin: 0;
  }
`;
