import { css } from "@emotion/react";

export const container = css`
  display: inline-flex;
  overflow: hidden;
`;

export const tokenTagNumber = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const tokenTagDescription = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
`;
