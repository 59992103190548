import React, { memo, useState } from "react";

import { ApolloError } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { object as yupObject, string as yupString } from "yup";

import { useCreateCommunityMutation } from "apollo/queries";
import Button from "components/Button";
import TextFieldWithSuffix from "components/CommunityCreator/TextFieldWithSuffix";
import ErrorLinkContainer from "components/ErrorLinkContainer";
import TextField from "components/Inputs/TextField";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { APP_URL, EXTERNAL_URL } from "config";
import useAllowCreateCommunity from "hooks/useAllowCreateCommunity";
import { logError } from "services/logger";
import useScrollToTop from "utils/hooks/useScrollToTop";

import * as styles from "./styles";

export type GetStartedForm = {
  creatorName: string;
  communityName: string;
  communitySlug: string;
};

const isCommunityNameDuplicateError = (error: Error) => {
  return (
    error instanceof ApolloError &&
    error.graphQLErrors.find((errorItem) =>
      errorItem.message.match(/^.*E11000.*name.*$/)
    )
  );
};

const isCommunitySlugDuplicateError = (error: Error) => {
  return (
    error instanceof ApolloError &&
    error.graphQLErrors.find(
      (errorItem) =>
        errorItem.message.match(/^.*E11000.*slug.*$/) ||
        errorItem.message.includes("slug reserved")
    )
  );
};

const MAX_LENGTH = 40;
const MIN_LENGTH = 3;

const GetStarted = () => {
  const [doCreateCommunity] = useCreateCommunityMutation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { allowCreateCommunity, requirePhoneValidation } =
    useAllowCreateCommunity();

  useScrollToTop();

  const validationSchema = yupObject({
    communityName: yupString()
      .trim()
      .required("Community Name is required")
      .min(MIN_LENGTH)
      .max(MAX_LENGTH),
    creatorName: yupString()
      .trim()
      .required("Creator Name is required")
      .max(MAX_LENGTH),
    communitySlug: yupString()
      .trim()
      .min(MIN_LENGTH)
      .max(MAX_LENGTH)
      .matches(
        /^[a-z0-9]+(-[a-z0-9]+)*$/,
        "Only lowercase letters (a-z) and numbers (0-9) are allowed, hyphens (-) can be used for spacing."
      )
      .required("Community Url is required"),
  }).required();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setError,
  } = useForm<GetStartedForm>({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit: SubmitHandler<GetStartedForm> = async (data) => {
    try {
      setErrorMessage("");
      setIsLoading(true);
      await doCreateCommunity({
        variables: {
          data: {
            name: data.communityName,
            creatorName: data.creatorName,
            slug: data.communitySlug,
          },
        },
      });
      navigate(`/edit-community/${data.communitySlug}/home`);
    } catch (e) {
      if (isCommunitySlugDuplicateError(e as Error)) {
        setError("communitySlug", {
          type: "manual",
          message: "URL unavailable",
        });
        return;
      }
      if (isCommunityNameDuplicateError(e as Error)) {
        setError("communityName", {
          type: "manual",
          message: "Name already exists",
        });
        return;
      }
      setErrorMessage("Error creating the community");
      await logError({
        e,
        message: "[getStarted] create community failed",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = () => {
    const anyValuesNotSet =
      !getValues().communitySlug ||
      !getValues().communityName ||
      !getValues().creatorName;
    setButtonDisabled(anyValuesNotSet);
  };

  const goToAccountSettings = () => {
    navigate(`${APP_URL.account.base}/${APP_URL.account.settings}`);
  };

  return (
    <div css={styles.container}>
      <Title size="xlarge" extraCss={styles.title}>
        Create Your Community
      </Title>
      <form
        onSubmit={handleSubmit(onSubmit)}
        css={styles.form}
        onChange={handleChange}
      >
        <ul css={styles.fields}>
          <li>
            <TextField
              maxLength={MAX_LENGTH}
              label="Creator Name"
              className=""
              placeholder="Your name, band's name, organization's name"
              {...register("creatorName")}
              error={errors.creatorName?.message && errors.creatorName?.message}
            />
          </li>

          <li>
            <TextField
              maxLength={MAX_LENGTH}
              minLength={MIN_LENGTH}
              label="Community Name"
              className=""
              placeholder="Explorer's Club, Plant Fam, Beauty Squad"
              {...register("communityName")}
              error={
                errors.communityName?.message && errors.communityName?.message
              }
            />
          </li>
          <li>
            <TextFieldWithSuffix
              minLength={MIN_LENGTH}
              maxLength={MAX_LENGTH}
              suffix="highlight.xyz"
              label="Community Url"
              className=""
              placeholder="yourcommunity"
              {...register("communitySlug")}
              error={
                errors.communitySlug?.message && errors.communitySlug?.message
              }
            />
          </li>
          {!allowCreateCommunity && requirePhoneValidation && (
            <ErrorLinkContainer
              level={"info"}
              canClose={false}
              additionalCss={styles.verifyPhoneContainer}
            >
              <div css={styles.verifyPhone}>
                <Text extraCss={styles.errorText}>
                  To create another community, you must first verify your phone
                  number in account setting page.
                </Text>
                <div onClick={goToAccountSettings}>
                  <Text extraCss={styles.errorTextUrl}>
                    Verify Phone Number →
                  </Text>
                </div>
              </div>
            </ErrorLinkContainer>
          )}
          <li>
            <Button
              css={styles.button}
              type="submit"
              size="xl"
              disabled={buttonDisabled || !allowCreateCommunity}
              loading={isLoading}
            >
              Get Started
            </Button>
            {errorMessage && (
              <Text size="medium" extraCss={styles.errorMessage}>
                {errorMessage}
              </Text>
            )}
          </li>
          <li>
            <Text
              size="small"
              innerHTML={true}
              extraCss={styles.termsDisclaimer}
            >
              By creating a community, you agree to the{" "}
              <a
                href={EXTERNAL_URL.CREATOR_TERMS_AND_CONDITIONS}
                target={"_blank"}
                rel="noreferrer"
              >
                Creator Terms of Service
              </a>
              .
            </Text>
          </li>
        </ul>
      </form>
    </div>
  );
};

export default memo(GetStarted);
