import { FC, memo } from "react";

import { TokenBenefit } from "apollo/graphql.generated";
import {
  getBenefitShortTitle,
  getIcon,
} from "containers/App/EditCommunity/Tokens/Shared/BenefitsOptions";

import * as styles from "./styles";

type BenefitDescriptionProps = {
  benefit: TokenBenefit;
};

const BenefitDescription: FC<BenefitDescriptionProps> = ({ benefit }) => {
  const icon = getIcon(benefit.type);
  const description = getBenefitShortTitle(benefit.type, benefit.customName);
  return (
    <span css={styles.benefitDescription}>
      <span>{icon}</span>
      <span>{description}</span>
    </span>
  );
};

export default memo(BenefitDescription);
