import React, { FC, memo } from "react";

import * as styles from "./styles";
import { Props } from "./types";

const Divider: FC<Props> = ({ width = "90" }) => {
  return (
    <div css={styles.dividerContainer}>
      <div css={styles.divider(width)} />
    </div>
  );
};

export default memo(Divider);
