import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type SendTokensMutationVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
  tokenId: Types.Scalars["String"];
}>;

export type SendTokensMutation = {
  sendTokens: {
    __typename: "SendToken";
    communityId: string;
    tokenId: string;
    communityMembersCount: number;
    availableTokenBalance: number;
    sent: boolean;
  };
};

export const SendTokensDocument = gql`
  mutation sendTokens($communityId: String!, $tokenId: String!) {
    sendTokens(communityId: $communityId, tokenId: $tokenId) {
      communityId
      tokenId
      communityMembersCount
      availableTokenBalance
      sent
    }
  }
`;
export type SendTokensMutationFn = Apollo.MutationFunction<
  SendTokensMutation,
  SendTokensMutationVariables
>;

/**
 * __useSendTokensMutation__
 *
 * To run a mutation, you first call `useSendTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTokensMutation, { data, loading, error }] = useSendTokensMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useSendTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendTokensMutation,
    SendTokensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendTokensMutation, SendTokensMutationVariables>(
    SendTokensDocument,
    options
  );
}
export type SendTokensMutationHookResult = ReturnType<
  typeof useSendTokensMutation
>;
export type SendTokensMutationResult =
  Apollo.MutationResult<SendTokensMutation>;
export type SendTokensMutationOptions = Apollo.BaseMutationOptions<
  SendTokensMutation,
  SendTokensMutationVariables
>;
