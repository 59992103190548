import React, { FC, memo, PropsWithChildren } from "react";

import { useLocation } from "react-router-dom";

import Text from "components/Typography/Text";
import { EXTERNAL_URL } from "config";
import useAllowCreateCommunity from "hooks/useAllowCreateCommunity";
import useSession from "hooks/useSession";
import { ThemeType } from "styles/theme/theme.types";

import { layoutContext } from "../../apollo/reactive/layout";
import CommunityText from "../_Community/Text";

import * as styles from "./styles";

type ThemeSwitchProps = {
  theme: ThemeType;
};

const invertColorOnPage = (page: string, userSignedIn: boolean) => {
  const pagesToWatch = ["/create-community"];
  return pagesToWatch.includes(page) || (page === "/" && !userSignedIn);
};

const ThemeSwitch: FC<PropsWithChildren<ThemeSwitchProps>> = ({
  children,
  theme = "system",
}) => {
  const { pathname } = useLocation();
  const userSignedIn = useSession().user !== undefined;

  if (theme === "system") {
    return (
      <Text
        as="div"
        color="primary"
        inverted={invertColorOnPage(pathname, userSignedIn)}
        extraCss={styles.container}
      >
        {children}
      </Text>
    );
  }

  return (
    <CommunityText as="div" extraCss={styles.container}>
      {children}
    </CommunityText>
  );
};

const Footer: FC = () => {
  const { theme } = layoutContext();
  const { allowCreateCommunity, createCommunityAbsoluteUrl } =
    useAllowCreateCommunity();

  return (
    <footer css={styles.containerWrapper}>
      <ThemeSwitch theme={theme ?? "system"}>
        <div css={styles.copyright}>
          <p>
            Powered by <strong>Highlight</strong>
          </p>
        </div>

        <nav css={styles.nav} role="navigation" aria-label="Footer">
          {allowCreateCommunity && (
            <a
              href={createCommunityAbsoluteUrl}
              target="_blank"
              css={styles.navLink}
              rel="noreferrer"
            >
              Create a Community
            </a>
          )}
          <a
            href={EXTERNAL_URL.TERMS_AND_CONDITIONS}
            target="_blank"
            css={styles.navLink}
            rel="noreferrer"
          >
            Terms of Service
          </a>
          <a
            href={EXTERNAL_URL.PRIVACY_POLICY}
            target="_blank"
            css={styles.navLink}
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href={EXTERNAL_URL.SUPPORT}
            target="_blank"
            css={styles.navLink}
            rel="noreferrer"
          >
            Support
          </a>
        </nav>
      </ThemeSwitch>
    </footer>
  );
};

export default memo(Footer);
