import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
export type AllUserFlagVariationsFragment = {
  __typename: "UserFlagVariations";
  paymentsEnableFiatOffers: boolean;
  cryptoByoWalletAuth: boolean;
  onboardingSquadCreatorTermsOfService: boolean;
  scaffoldBecomeCreatorCta: boolean;
  scaffoldEnableCreatorDashboard: boolean;
  scaffoldEnableAccountEmailPreferencesPage: boolean;
  scaffoldEnableAccountTransactionHistoryPage: boolean;
  scaffoldEnableAccountWalletPage: boolean;
  scaffoldEnableMagiclinkPrivateKeyExport: boolean;
  paymentsStripeConnectOnboarding: boolean;
  creatorDashboardCreateOneCommunityPerUser: boolean;
  creatorDashboardDisableCreateCommunity: boolean;
  creatorDashboardRequirePhoneValidation: boolean;
  creatorDashboardMembersTab: boolean;
  creatorDashboardThemeTab: boolean;
  creatorDashboardIncomeTab: boolean;
  incomeAllowWithdraw: boolean;
  incomeShowWEthEarnings: boolean;
  incomeShowUsdEarnings: boolean;
  enableCryptoPayments: boolean;
  creatorDashboardCustomBenefitTokens: boolean;
  creatorDashboardEnableMintTokenLimit: boolean;
  creatorDashboardVideoAssetOnToken: boolean;
  shopifyOnboardWithBuyButton: boolean;
  creatorDashboardShowDiscordServerConfigPainel: boolean;
  enableDeployDetectionAndForcePageReload: boolean;
  emergencyStopClaimToken: boolean;
  emergencyStopDeployContract: boolean;
  emergencyStopShowContentFeedPosts: boolean;
  emergencyStopNewContentFeedPosts: boolean;
  emergencyStopMintNewTokens: boolean;
  emergencyStopSendTokensToAll: boolean;
  accountsRequireCaptchaForCreation: boolean;
  contentFeedEnableNewPostEditor: boolean;
  emergencyAlertFrontendMessage: string;
  releasePartyAccessFeature: string;
  releasePartyUpdateUserProfile: string;
  discordSyncBenefitsButton: boolean;
  enableResizedImages: boolean;
  segmentEnableInCommunities: boolean;
  enableMintingNonTransferable: boolean;
};

export type AllCommunityFlagVariationsFragment = {
  __typename: "CommunityFlagVariations";
  hideMembershipTokenReferences: boolean;
};

export const AllUserFlagVariationsFragmentDoc = gql`
  fragment AllUserFlagVariations on UserFlagVariations {
    paymentsEnableFiatOffers
    cryptoByoWalletAuth
    onboardingSquadCreatorTermsOfService
    scaffoldBecomeCreatorCta
    scaffoldEnableCreatorDashboard
    scaffoldEnableAccountEmailPreferencesPage
    scaffoldEnableAccountTransactionHistoryPage
    scaffoldEnableAccountWalletPage
    scaffoldEnableMagiclinkPrivateKeyExport
    paymentsStripeConnectOnboarding
    creatorDashboardCreateOneCommunityPerUser
    creatorDashboardDisableCreateCommunity
    creatorDashboardRequirePhoneValidation
    creatorDashboardMembersTab
    creatorDashboardThemeTab
    creatorDashboardIncomeTab
    incomeAllowWithdraw
    incomeShowWEthEarnings
    incomeShowUsdEarnings
    enableCryptoPayments
    creatorDashboardCustomBenefitTokens
    creatorDashboardEnableMintTokenLimit
    creatorDashboardVideoAssetOnToken
    shopifyOnboardWithBuyButton
    creatorDashboardShowDiscordServerConfigPainel
    enableDeployDetectionAndForcePageReload
    emergencyStopClaimToken
    emergencyStopDeployContract
    emergencyStopShowContentFeedPosts
    emergencyStopNewContentFeedPosts
    emergencyStopMintNewTokens
    emergencyStopSendTokensToAll
    accountsRequireCaptchaForCreation
    contentFeedEnableNewPostEditor
    emergencyAlertFrontendMessage
    releasePartyAccessFeature
    releasePartyUpdateUserProfile
    discordSyncBenefitsButton
    enableResizedImages
    segmentEnableInCommunities
    enableMintingNonTransferable
  }
`;
export const AllCommunityFlagVariationsFragmentDoc = gql`
  fragment AllCommunityFlagVariations on CommunityFlagVariations {
    hideMembershipTokenReferences
  }
`;
