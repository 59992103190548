import { FC, memo, MouseEvent } from "react";

import { Link } from "react-router-dom";

import * as styles from "./BaseButtonLink/styles";
import { RouterLinkProps } from "./BaseButtonLink/types";

const SystemButtonLink: FC<RouterLinkProps> = ({
  children,
  className = "",
  size = "large",
  color = "primary",
  hasBorder = true,
  fluid = true,
  disable = false,
  extraCss,
  additionalCss,
  to = "#",
  ...rest
}) => {
  const onClickHandler = (event: MouseEvent<HTMLAnchorElement>) => {
    if (disable) event.preventDefault();
  };

  return (
    <Link
      className={className}
      css={[
        styles.button(color, size, fluid, hasBorder),
        disable ? styles.disabled : "",
        additionalCss,
        extraCss,
      ]}
      onClick={onClickHandler}
      to={to}
      {...rest}
    >
      <span css={[styles.buttonText]}>{children}</span>
    </Link>
  );
};

export default memo(SystemButtonLink);
