import { makeVar } from "@apollo/client";

import { CheckoutItemsInput } from "apollo/graphql.generated";
import {
  getBrowserStorageCart,
  removeBrowserStorageCart,
  setBrowserStorageCart,
} from "utils/browserStorage";

export type CartItem = CheckoutItemsInput;

export type Cart = {
  items: CartItem[];
  paymentIntent: string | null;
  redirectUrl: string | null;
};

const emptyCartStore = {
  items: [],
  paymentIntent: null,
  redirectUrl: null,
};

const initCartStore = getBrowserStorageCart() || emptyCartStore;

export const cartStore = makeVar<Cart>(initCartStore);

export const resetCartVar = () => {
  cartStore(emptyCartStore);
  removeBrowserStorageCart();
};

export const addCartItemsVar = (items: CartItem) => {
  const newCart = cartStore({ ...initCartStore, items: [items] });
  setBrowserStorageCart(newCart);
};

export const updateCartPaymentIntentVar = (
  paymentIntent: Cart["paymentIntent"]
) => {
  const newCart = cartStore({ ...cartStore(), paymentIntent: paymentIntent });
  setBrowserStorageCart(newCart);
};

export const updateRedirectUrlVar = (
  redirectUrl: string | null | undefined
) => {
  if (!redirectUrl) return;
  const newCart = cartStore({ ...cartStore(), redirectUrl: redirectUrl });
  setBrowserStorageCart(newCart);
};
