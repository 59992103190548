import Cookies from "js-cookie";

import { STORAGE_KEYS } from "config";

export const getDomain = () => {
  const url = window.location.href;
  const domain = new URL(url);

  return domain?.hostname?.match(/\w*\.(.*)/)?.[1] || "";
};

/** Auth Cookies */

export const removeDeprecatedDidToken = () => {
  Cookies.remove(STORAGE_KEYS.deprecatedDidToken, {
    domain: getDomain(),
  });
};

export const getDeprecatedDidToken = () => {
  return Cookies.get(STORAGE_KEYS.deprecatedDidToken);
};

/** Localhost Slug Cookies */
export const removeSlugCookie = () => {
  Cookies.remove(STORAGE_KEYS.defaultSlug, {
    domain: getDomain(),
  });
};

export const setSlugCookie = (defaultSlug: string | null) => {
  if (!defaultSlug) return;

  return Cookies.set(STORAGE_KEYS.defaultSlug, defaultSlug, {
    expires: 7, // days
    domain: getDomain(),
  });
};

export const getSlugCookie = () => {
  return Cookies.get(STORAGE_KEYS.defaultSlug) ?? "";
};
