import { memo } from "react";

import { ReactComponent as Error } from "assets/icons/error.svg";
import CommunityCardContainer from "components/_Community/CardContainer";
import CommunityText from "components/_Community/Text";
import CommunityTitle from "components/_Community/Title";

import * as styles from "./styles";

const ClosedContestCard = () => {
  return (
    <CommunityCardContainer shadow="lg" extraCss={styles.container}>
      <div css={styles.title}>
        <CommunityTitle size="small" color="onPrimaryBackgroundPrimaryColor">
          <Error css={styles.icon} />
          Token Unavailable
        </CommunityTitle>
      </div>
      <div css={styles.text}>
        <CommunityText color="onPrimaryBackgroundPrimaryColor">
          We&apos;re sorry, but this token is no longer available. Please check
          back soon for updates.
        </CommunityText>
      </div>
    </CommunityCardContainer>
  );
};

export default memo(ClosedContestCard);
