import React, { forwardRef, memo, ComponentPropsWithRef } from "react";

import _uniqueId from "lodash/uniqueId";
import { UseFormSetValue } from "react-hook-form";

import { WebsiteThemeFragment } from "apollo/queries";
import Label from "components/Typography/Label";
import { Maybe } from "utils/types";

import Text from "../../Typography/Text";
import * as sharedStyles from "../sharedStyles";

import * as styles from "./styles";

type InputProps = ComponentPropsWithRef<"input">;

export type RangeSliderFieldProps = InputProps & {
  registerName:
    | "title.letterSpacing"
    | "text.letterSpacing"
    | "label.letterSpacing"
    | "button.textLetterSpacing";
  value: number | string;
  label: string;
  description?: Maybe<string>;
  unit?: string;
  min?: number;
  max?: number;
  step?: number;
  error?: string | undefined;
  setValue: UseFormSetValue<WebsiteThemeFragment>;
};

const RangeSliderField = forwardRef<HTMLInputElement, RangeSliderFieldProps>(
  (
    {
      registerName,
      value,
      error,
      id,
      label,
      description,
      unit = "em",
      min = 0,
      max = 2,
      step = 0.01,
      setValue,
    }: RangeSliderFieldProps,
    ref
  ) => {
    const cleanValue: number =
      typeof value === "number" ? value : Number(value.replace(/[^-.\d]/g, ""));

    const htmlFor = _uniqueId("field-");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = `${e.currentTarget.value}${unit}`;
      setValue(registerName, newValue, { shouldDirty: true });
    };

    return (
      <div css={sharedStyles.baseFieldGroup}>
        {label && (
          <Label size="small" extraCss={sharedStyles.baseInputLabel}>
            {label}
          </Label>
        )}

        {description && (
          <Text size="small" extraCss={sharedStyles.baseInputDescription}>
            {description}
          </Text>
        )}

        <div
          css={[sharedStyles.baseInputContainer(error), styles.inputContainer]}
        >
          <input
            css={styles.input(((cleanValue - min) / (max - min)) * 100)}
            ref={ref}
            type="range"
            id={id ?? htmlFor}
            min={min}
            max={max}
            step={step}
            value={cleanValue}
            onChange={handleInputChange}
            onBlur={handleInputChange}
          />
          <Text extraCss={styles.selectedValue}>
            {cleanValue}
            {unit}
          </Text>
        </div>

        {error && <p css={sharedStyles.baseInputErrorText}>{error}</p>}
      </div>
    );
  }
);

export default memo(RangeSliderField);
