import { css, Theme } from "@emotion/react";

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const tokenImageWrapper = css`
  position: relative;
`;

export const media = css`
  width: 100%;
  //FIXME: Drop shadow is compiled with wrong values causing iOS to make the element invisible.
  //filter: drop-shadow(0px 8px 96px rgba(0, 0, 0, 0.06));
`;

export const emptyToken = (theme: Theme) => css`
  width: 100%;
  max-width: 484px;
  max-height: 484px;

  background-color: transparent;
  border: 1px dashed #000000;
  border-radius: 8px;

  text-align: center;

  ${theme.mediaQueries.smallOnly} {
    margin: 0 auto;
  }
`;

export const emptyTokenText = css`
  padding: 50% 0;
`;
