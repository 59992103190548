import { memo } from "react";

import CardContainer from "components/CardContainer";
import Title from "components/Typography/Title";
import UserCommunityCardLink, {
  UserCommunityCardLinkProps,
} from "components/UserCommunityCardLink";
import { CurrentUser } from "hooks/useSession";

import * as styles from "./styles";

type YourCommunitiesProps = {
  tokens: CurrentUser["tokens"];
};

const UserCommunities = ({ tokens }: YourCommunitiesProps) => {
  if (!tokens.length) return null;

  const communities = tokens.reduce((acc, token) => {
    acc[token.community.slug] = token.community;
    return acc;
  }, {} as { [key: string]: UserCommunityCardLinkProps["community"] });

  const orderedKeys = Object.keys(communities).sort();

  return (
    <CardContainer border={true} additionalCss={styles.container}>
      <Title additionalCss={styles.containerTitle} size="small">
        Your Communities
      </Title>
      <div css={styles.list}>
        {orderedKeys.map((slug, key) => (
          <UserCommunityCardLink community={communities[slug]} key={key} />
        ))}
      </div>
    </CardContainer>
  );
};

export default memo(UserCommunities);
