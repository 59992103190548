const gridLargeMaxWidth = 1012;
const gridMediumWidth = 768;

export const variables = {
  layout: {
    mobilePageGap: "20px",
    desktopPageGap: "32px",
    defaultWidth: "90%",

    cornerRadiusSmall: "4px",
    cornerRadiusMedium: "8px",
    cornerRadiusLarge: "16px",

    boxShadow:
      "0px 0px 10px rgba(0, 0, 0, 0.05), 0px 0px 80px rgba(0, 0, 0, 0.1)",
    modalBodyPaddings: "32px 40px 40px 40px",
    modalMediumSizePaddings: "32px 40px 40px 40px",
    modalSmallSizePaddings: "24px",
  },
  colors: {
    hlBlue: "#005aeb",
    blue: "#005aeb",
    blueDarker: "#0051d4",

    red: "#df0000",
    redDarker: "#df0000",

    white: "#fff",
    white60: "rgba(255, 255, 255, 0.6)",
    gray1: "#f5f5f5",
    gray3: "#f5f5f5",
    gray3Darker: "#efebeb",

    black: "#000",
    black50: "rgba(0, 0, 0, 0.5)",
    black60: "rgba(0, 0, 0, 0.6)",
    black10: "#e5e5e5",

    twitter: "#4999e9",
    facebook: "#1877f2",
  },

  zIndex: {
    layersLoadingScreen: "100",
    layersModals: "20",
    layersProfileSidebar: "10",
  },
  fontSize: {
    small: "12px",
  },

  grid: {
    largeMaxWidth: `${gridLargeMaxWidth}px`,
    largeColWidth: `${gridLargeMaxWidth / 23}px`,

    mediumWidth: `${gridMediumWidth}px`,
    mediumColWidth: `${gridMediumWidth / 23}px`,
  },
  verticalSpacing: {
    default1: "2px",
    default2: "4px",
    default3: "6px",
    default4: "8px",
    default5: "12px",
    default6: "16px",
    default7: "20px",
    default8: "24px",
    default9: "32px",
    default10: "40px",
    default11: "48px",
    default12: "56px",
    default13: "64px",
    default14: "80px",
    default15: "96px",
    default16: "120px",

    small1: "2px",
    small2: "4px",
    small3: "6px",
    small4: "8px",
    small5: "12px",
    small6: "16px",
    small7: "16px",
    small8: "18px",
    small9: "24px",
    small10: "30px",
    small11: "36px",
    small12: "42px",
    small13: "48px",
    small14: "60px",
    small15: "72px",
    small16: "90px",
  },
  breakpoints: {
    phoneSmall: "374px",
    phonePortrait: "569px",
    phone: "767px",
    tabletPortrait: "768px",
    tablet: "1024px",
    laptops: "1600px",
    desktops: "1800px",

    sizeSmallMax: "767px",
    sizeMediumMax: "959px",
  },
  animations: {
    easeOutQuart: "cubicBezier(0.25, 1, 0.5, 1)",
  },
};
