import { useEffect } from "react";

import { useReactiveVar } from "@apollo/client";

import { initVar } from "apollo/reactive";
import FullscreenLoader from "components/FullscreenLoader";
import { COMMUNITY_URL } from "config";
import useSession from "hooks/useSession";

const SignOut = () => {
  const init = useReactiveVar(initVar);
  const { logout } = useSession();

  useEffect(() => {
    if (init) {
      logout(COMMUNITY_URL.home);
    }
  }, [init, logout]);

  return <FullscreenLoader />;
};

export default SignOut;
