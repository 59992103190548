import React, { FC, useState, useEffect } from "react";

import { useReactiveVar } from "@apollo/client";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

import { BenefitType } from "apollo/graphql.generated";
import { useReleasePartyQuery } from "apollo/queries";
import { communityVar } from "apollo/reactive";
import { ReactComponent as CommentIcon } from "assets/icons/text-bubble.svg";
import CardContainer from "components/CardContainer";
import DistributionCard from "components/DistributionCard/DistributionCard";
import FullscreenLoader from "components/FullscreenLoader";
import Layout from "components/Layout";
import { CommunitySEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import CommunityText from "components/_Community/Text";
import CommunityTitle from "components/_Community/Title";
import { COMMUNITY_PAGE_TITLE } from "config";
import NotFound from "containers/Error/NotFound";
import useSession from "hooks/useSession";
import { toOfferDistribution } from "utils/distribution/distributionMethod";
import { simpleDateTime } from "utils/formatting/time";

import Comment from "./Comment";
import CommentEditor from "./CommentEditor";
import ReleasePartyHeader from "./ReleasePartyHeader";
import * as styles from "./styles";
import { PartyState } from "./types";

const ONE_DAY_MS = 1000 * 60 * 60 * 24;
const ReleaseParty: FC = () => {
  const community = useReactiveVar(communityVar);
  const { releaseSlug } = useParams();
  const [getAllComments, setGetAllComments] = useState<boolean>(false);
  const [partyState, setPartyState] = useState<PartyState>(
    PartyState.PRE_PARTY
  );
  const { user } = useSession();
  const { data, loading, refetch } = useReleasePartyQuery({
    variables: { slug: releaseSlug ?? "", communityId: community?.id },
  });

  const userOwnsToken = (user?.tokens ?? []).some((token) =>
    (token.benefits ?? []).some(
      (tokenBenefit) =>
        token.community.id === community.id &&
        tokenBenefit.type === BenefitType.RELEASE_PARTY &&
        tokenBenefit.releasePartyId === data?.releaseParty.id
    )
  );

  const startTime = new Date(data?.releaseParty.start ?? "");
  const endTime = new Date(data?.releaseParty.end ?? "");
  // TODO: review how we handle all comments
  const alreadyWroteComment = data?.releaseParty.comments.some(
    (comment) => comment.userId === user?.id
  );
  const comments = getAllComments
    ? data?.releaseParty.comments ?? []
    : (data?.releaseParty.comments ?? []).slice(0, 3);
  const offers =
    data?.releaseParty.offers.filter((offer) => offer.tokens?.length) ?? [];
  const distributions = [
    ...toOfferDistribution(
      offers,
      user,
      community?.id,
      {
        start: startTime,
        end: endTime,
      },
      window.location.pathname
    ),
  ];

  useEffect(() => {
    const now = DateTime.now();
    if (!data) {
      return;
    }
    const waitStartMs = DateTime.fromJSDate(startTime).diff(now).milliseconds;
    const waitEndMs = DateTime.fromJSDate(endTime).diff(now).milliseconds;
    if (waitStartMs > 0) {
      setTimeout(() => {
        setPartyState(PartyState.PARTY);
        refetch();
      }, waitStartMs);
    } else if (waitEndMs > 0) {
      setPartyState(PartyState.PARTY);
      // NOTE: setTimeout will fail for too large values,
      // only wait to refresh the page if the release party
      // has less than a day of time left
      if (waitEndMs < ONE_DAY_MS) {
        setTimeout(() => {
          setPartyState(PartyState.AFTER_PARTY);
          refetch();
        }, waitEndMs);
      }
    } else {
      setPartyState(PartyState.AFTER_PARTY);
    }
  }, [data, loading]);

  if (loading) {
    return <FullscreenLoader inheritColor={false} overwriteTheme="system" />;
  }
  if (!data) {
    return <NotFound />;
  }
  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.RELEASE_PARTY} />

      <Layout navLayout="community" backgroundShow backgroundColor="community">
        <div css={styles.headerContainer}>
          <ReleasePartyHeader
            partyImageUrl={data?.releaseParty.baseImageUrl}
            partyName={data?.releaseParty.name}
            partySlug={community.slug}
            partyId={data?.releaseParty.id ?? ""}
            startTime={startTime}
            description={data?.releaseParty.description ?? ""}
            partyState={partyState}
            hasReleaseTokenBenefit={userOwnsToken}
            embeddedContent={data?.releaseParty.embeddedContent ?? ""}
            hasSecretMessage={data?.releaseParty.hasSecretMessage ?? false}
          />
        </div>
        <div css={styles.tokenListContainer}>
          <CommunityTitle
            size="xlarge"
            color="onPageBackgroundPrimaryColor"
            extraCss={styles.wallTitle}
          >
            {"Collectible Tokens"}
          </CommunityTitle>

          {partyState === PartyState.PRE_PARTY && (
            <CommunityText extraCss={styles.wallDescription}>
              {" "}
              {
                "During the Release Party, you’ll be able to mint a collectible token to prove your attendance."
              }{" "}
              {`Minting available from  ${simpleDateTime(
                startTime,
                false
              )} to ${simpleDateTime(endTime, false)}.`}
            </CommunityText>
          )}
          {partyState === PartyState.PARTY && (
            <CommunityText extraCss={styles.wallDescription}>
              {" "}
              {`Minting available from  ${simpleDateTime(
                startTime,
                false
              )} to ${simpleDateTime(endTime, false)}.`}
            </CommunityText>
          )}
          {partyState === PartyState.AFTER_PARTY && (
            <CommunityText extraCss={styles.wallDescription}>
              {" "}
              {` Minting ended at ${simpleDateTime(endTime, false)}.`}
            </CommunityText>
          )}

          {loading && (
            <div css={styles.loadingIcon}>
              <LoadingIcon />
            </div>
          )}
          <div css={styles.distributionCardsContainer}>
            {distributions.map((distribution) => (
              <DistributionCard
                key={distribution.distributionMethod.id}
                distribution={distribution}
                communityName={"Testing"}
                contractAddress={
                  data?.releaseParty?.community?.polygonContract
                    ?.contractAddress
                }
                viewType="image-first"
              />
            ))}
          </div>
        </div>
        <div css={styles.wallContainer}>
          {partyState === PartyState.PRE_PARTY && (
            <>
              <div css={styles.wallDescriptionContainer}>
                <CommunityTitle
                  size="large"
                  color="onPageBackgroundPrimaryColor"
                  extraCss={styles.wallTitle}
                >
                  {"Join the Conversation"}
                </CommunityTitle>

                <CommunityText extraCss={styles.wallDescription}>
                  {" "}
                  {`Fans who hold a token with the party benefit can leave a comment from ${simpleDateTime(
                    startTime,
                    false
                  )} to ${simpleDateTime(endTime, false)}.`}
                </CommunityText>
                <CardContainer border extraCss={styles.joinConvoCtaContainer}>
                  <div css={styles.commentIcon}>
                    <CommentIcon />
                  </div>
                  <CommunityText extraCss={styles.joinConvoCtaDescription}>
                    {`Starting ${simpleDateTime(
                      startTime,
                      false,
                      "at"
                    )}, fans who own a party token will be able to leave a comment here. If they sell or give away their token, the new owner will be able to leave a comment.`}
                  </CommunityText>
                </CardContainer>
              </div>
            </>
          )}
          {(partyState === PartyState.PARTY ||
            partyState === PartyState.AFTER_PARTY) && (
            <>
              <div css={styles.wallDescriptionContainer}>
                <CommunityTitle
                  size="xlarge"
                  color="onPageBackgroundPrimaryColor"
                  extraCss={styles.wallTitle}
                >
                  {"The Wall"}
                </CommunityTitle>
                {partyState === PartyState.PARTY && (
                  <CommunityText extraCss={styles.wallDescription}>
                    {" "}
                    {`Minting available from  ${simpleDateTime(
                      startTime,
                      false
                    )} to ${simpleDateTime(endTime, false)}.`}
                  </CommunityText>
                )}
                {partyState === PartyState.AFTER_PARTY && (
                  <CommunityText extraCss={styles.wallDescription}>
                    {" "}
                    {`Comments closed at ${simpleDateTime(endTime, false)}.`}
                  </CommunityText>
                )}
              </div>
              {partyState === PartyState.PARTY &&
                user &&
                userOwnsToken &&
                !alreadyWroteComment && (
                  <CommentEditor
                    communityId={data?.releaseParty?.communityId}
                    releasePartyId={data?.releaseParty?.id}
                    afterSubmit={async () => {
                      await refetch();
                      return;
                    }}
                  />
                )}
              {comments.length === 0 && (
                <div css={styles.wallDescriptionContainer}>
                  <CardContainer border extraCss={styles.joinConvoCtaContainer}>
                    <div css={styles.commentIcon}>
                      <CommentIcon />
                    </div>
                    <CommunityText extraCss={styles.joinConvoCtaDescription}>
                      {"No one has left a comment yet."}
                    </CommunityText>
                  </CardContainer>
                </div>
              )}
              {comments.map((comment) => {
                return (
                  <Comment
                    key={comment.id}
                    id={comment.id.toString()}
                    canDelete={user?.id === comment.userId}
                    afterInteraction={async () => {
                      await refetch();
                      return;
                    }}
                    text={comment?.text ?? ""}
                    username={comment.username ?? ""}
                    userAvatar={comment.profileImageUrl ?? ""}
                    reactions={comment.reactions ?? []}
                  />
                );
              })}
              {comments.length > 0 && !getAllComments && (
                <div css={styles.linkContainer}>
                  <a rel="noreferrer" onClick={() => setGetAllComments(true)}>
                    <CommunityText extraCss={styles.navLink}>
                      {"See All Comments"}
                    </CommunityText>
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

export default ReleaseParty;
