import { memo, FC } from "react";

import { Interpolation, Theme } from "@emotion/react";

import { ReactComponent as CheckWithinCircle } from "assets/icons/check-within-circle.svg";
import CommunityCardContainer from "components/_Community/CardContainer";
import CommunityText from "components/_Community/Text";
import CommunityTitle from "components/_Community/Title";

import * as styles from "./styles";

export type Props = {
  email: string;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
};

const ReceivedCard: FC<Props> = ({ email, extraCss }) => (
  <CommunityCardContainer shadow="lg" extraCss={[styles.container, extraCss]}>
    <div css={styles.title}>
      <CommunityTitle size="small" color="onPrimaryBackgroundPrimaryColor">
        <CheckWithinCircle css={styles.icon} />
        We Received Your Entry
      </CommunityTitle>
    </div>
    <div css={styles.text}>
      <CommunityText color="onPrimaryBackgroundPrimaryColor">
        Thanks for entering. If you’re selected, we’ll email a link to {` `}
        <strong>{email}</strong> that you can use to claim your prize.
      </CommunityText>
    </div>
  </CommunityCardContainer>
);

export default memo(ReceivedCard);
