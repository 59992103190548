import React, { FC } from "react";

import { useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";

import { useContestQuery } from "apollo/queries";
import { communityVar } from "apollo/reactive";
import LoadingIcon from "components/LoadingIcon";
import TokenDetail from "containers/CommunityV3/TokenDetail/TokenDetail";
import NotFound from "containers/Error/NotFound";

import * as styles from "./styles";

const ClaimToken: FC = () => {
  const { id } = useReactiveVar(communityVar);
  const { claimId = "" } = useParams();
  const { data, loading } = useContestQuery({
    fetchPolicy: "network-only",
    variables: {
      contestId: claimId as string,
    },
  });
  // don't show claim page if claim-offer does not exist
  // show claim page with 'claim', 'already applied', or 'sold out' state
  if (
    !claimId ||
    (!loading &&
      (!data?.contest.communityId || data?.contest.communityId != id))
  ) {
    return <NotFound />;
  } else if (loading) {
    return (
      <div css={styles.loadingWrapper}>
        <LoadingIcon />
      </div>
    );
  } else {
    return (
      <TokenDetail
        claimId={claimId}
        knownTokenId={data?.contest?.tokenIds[0]}
        hasUserApplied={!!data?.contest?.hasUserApplied}
        soldOut={!data?.contest?.active}
      />
    );
  }
};

export default ClaimToken;
