import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { ApolloError } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { string as yupString } from "yup";

import { useUpdateGeneralInfoMutation } from "apollo/queries";
import TextFieldWithSuffix from "components/CommunityCreator/TextFieldWithSuffix";
import LoadingIcon from "components/LoadingIcon";
import { logError } from "services/logger";

export interface Props {
  communityId: string;
  communitySlugValue: string;
}

const isSlugDuplicateError = (error: Error) => {
  return (
    error instanceof ApolloError &&
    error.graphQLErrors.find(
      (errorItem) =>
        errorItem.message.includes("duplicate key error") ||
        errorItem.message.includes("slug reserved")
    )
  );
};

const MAX_LENGTH = 40;
const MIN_LENGTH = 3;

const CommunitySlugUpdate: FC<Props> = ({
  communityId,
  communitySlugValue,
}) => {
  const [doUpdateGeneralInfoMutation] = useUpdateGeneralInfoMutation();
  const navigate = useNavigate();

  const [communitySlug, setCommunitySlug] =
    useState<string>(communitySlugValue);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCommunitySlug(communitySlugValue);
  }, [communitySlugValue]);

  const validationSchema = yupString()
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      "Only lowercase letters (a-z) and numbers (0-9) are allowed, hyphens (-) can be used for spacing."
    )
    .trim()
    .max(MAX_LENGTH)
    .min(MIN_LENGTH)
    .required("Url is required");

  const update = async (
    slugToUpdate: string,
    communityId: string,
    slug: string
  ) => {
    setError("");
    if (slugToUpdate === slug) return;

    try {
      slugToUpdate = await validationSchema.validate(slugToUpdate);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
      if (inputRef?.current) {
        inputRef.current.value = slug;
      }
      return;
    }

    try {
      setLoading(true);
      const res = await doUpdateGeneralInfoMutation({
        variables: {
          data: {
            slug: slugToUpdate,
          },
          updateGeneralInfoId: communityId,
        },
      });
      const communityResponse = res.data?.updateGeneralInfo;
      const newSlug = communityResponse?.slug as string;
      setCommunitySlug(newSlug);
      navigate(`/edit-community/${newSlug}/home`);
    } catch (error) {
      if (isSlugDuplicateError(error as Error)) {
        setError(`URL ${slugToUpdate} unavailable`);
      } else {
        setError("Update failed");
        await logError({
          error,
          message: "[handleCommunitySlugOnBlur] update community failed",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCommunitySlugOnBlur = useCallback(
    async (event: React.FocusEvent<HTMLInputElement>) =>
      update(event.target.value, communityId, communitySlug),
    [communityId, communitySlug]
  );

  const handleOnEnter = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) =>
      update(event.currentTarget.value, communityId, communitySlug),
    [communityId, communitySlug]
  );

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <TextFieldWithSuffix
      ref={inputRef}
      maxLength={MAX_LENGTH}
      minLength={MIN_LENGTH}
      suffix=".highlight.xyz"
      label="Community Url"
      labelType="title"
      id="communitySlugForm"
      className=""
      placeholder="Enter community url"
      defaultValue={communitySlug}
      onBlur={handleCommunitySlugOnBlur}
      error={error}
      onChange={() => setError("")}
      onKeyPress={(e) => e.key === "Enter" && handleOnEnter(e)}
      onClick={() => setError("")}
    />
  );
};

export default memo(CommunitySlugUpdate);
