import { makeVar } from "@apollo/client";

type BannerMessage = {
  text: string;
  warning?: boolean;
};

export type Banner = {
  show: boolean;
  canClose?: boolean;
  messages: BannerMessage[];
};

const emptyBanner: Banner = {
  show: false,
  canClose: true,
  messages: [],
};

const bannerVar = makeVar<Banner>(emptyBanner);

const resetBannerVar = () => {
  bannerVar(emptyBanner);
};

const updateBannerVar = (data: Partial<Banner>) => {
  bannerVar({ ...bannerVar(), ...data });
};

/**
 * Shows the global banner with the message.
 * @param message text to be shown
 * @param canClose if the banner can be closed
 * @param warning if warning icon should appear
 * @param overrideMessage if message should override other messages already present
 */
const showBannerMessage = (
  message: string,
  canClose?: boolean,
  warning?: boolean,
  overrideMessage?: boolean
) => {
  if (overrideMessage) {
    updateBannerVar({
      show: true,
      canClose,
      messages: [{ text: message, warning }],
    });
  } else {
    const hasDuplicate = bannerVar().messages.some((m) => m.text === message);
    if (hasDuplicate) return;
    updateBannerVar({
      show: true,
      canClose,
      messages: [...bannerVar().messages, { text: message, warning }],
    });
  }
};

/**
 * Hide a specific banner message. It is necessary in case more than one message is being shown
 * and the client don't want to force close the global banner.
 * @param message text to be hidden from the banner
 */
const hideBannerMessage = (message: string) => {
  const messagesIndex = bannerVar().messages.findIndex(
    (bannerMessage) => bannerMessage.text === message
  );

  const filteredMessages = [...bannerVar().messages];

  if (messagesIndex > -1) {
    filteredMessages.splice(messagesIndex, 1);
  }

  if (filteredMessages.length === 0) {
    resetBannerVar();
  } else {
    updateBannerVar({
      show: true,
      messages: filteredMessages,
    });
  }
};

export {
  bannerVar,
  resetBannerVar,
  updateBannerVar,
  showBannerMessage,
  hideBannerMessage,
};
