import React, { FC, memo, useState } from "react";

import { css, Theme } from "@emotion/react";

import {
  CreatedCommunityBySlugDocument,
  useDeleteShopifyCollectionBuyButtonMutation,
} from "apollo/queries";
import Button from "components/Button";
import ReactModalBase from "components/Modals/ReactModalBase";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { logError } from "services/logger";
import { bottomMargin } from "styles/global/layout";

export interface Props {
  communityId: string;
  isOpen: boolean;
  onClose: VoidFunction;
}

const UnlinkStoreModal: FC<Props> = ({ communityId, isOpen, onClose }) => {
  const [unlinkError, setUnlinkError] = useState("");

  const [deleteShopifyCollectionBuyButton, { loading: isUnlinking }] =
    useDeleteShopifyCollectionBuyButtonMutation({
      refetchQueries: [CreatedCommunityBySlugDocument],
      variables: { communityId },
    });

  const handleUnlink = async () => {
    try {
      const result = await deleteShopifyCollectionBuyButton();
      if (!result.data?.deleteShopifyCollectionBuyButton.success) {
        throw new Error(
          "deleteShopifyCollectionBuyButton returned success=false"
        );
      }
      onClose();
    } catch (error) {
      setUnlinkError("Error unlinking Shopify Store. Please try again later.");
      logError({
        error,
        message: "error in Shopify/handleUnlink",
      });
    }
  };

  const centerText = css`
    text-align: center;
  `;
  const errorText = (theme: Theme) => css`
    color: ${theme.colorMap.destructive.default};
  `;
  return (
    <ReactModalBase
      id="shopify-unlink-store-modal"
      isOpen={isOpen}
      canClose={false}
      onAfterClose={() => setUnlinkError("")}
    >
      <Title extraCss={[bottomMargin("4/8px"), centerText]} size="medium">
        Remove Shopify Store?
      </Title>
      <Text extraCss={[bottomMargin("9/32px"), centerText]}>
        If you remove your Shopify Store, your community members will no longer
        be able to purchase from it.
      </Text>

      {unlinkError && (
        <Text
          size="medium"
          extraCss={[bottomMargin("4/8px"), centerText, errorText]}
        >
          {unlinkError}
        </Text>
      )}

      <div
        css={css`
          display: flex;
          gap: 20px;
        `}
      >
        <Button onClick={() => onClose()} disabled={isUnlinking}>
          Cancel
        </Button>
        <Button
          color={"destructive"}
          onClick={() => handleUnlink()}
          loading={isUnlinking}
        >
          Remove
        </Button>
      </div>
    </ReactModalBase>
  );
};

export default memo(UnlinkStoreModal);
