import React, { useState } from "react";

import { useDisableClaimTokenContestMutation } from "apollo/queries";
import Button from "components/Button";
import ErrorLinkContainer from "components/ErrorLinkContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { logError } from "services/logger";

import * as styles from "./styles";

export interface ConfirmDeleteClaimPageProps {
  contestId: string;
  communityId: string;
  claimPageName: string;
  handleClose: () => void;
}

const DeleteClaimPage = ({
  contestId,
  communityId,
  claimPageName,
  handleClose,
}: ConfirmDeleteClaimPageProps) => {
  const [error, setError] = useState<string>("");
  const [disableClaimPage, { loading }] = useDisableClaimTokenContestMutation();
  const handleDelete = async () => {
    try {
      await disableClaimPage({
        variables: {
          communityId,
          contestId,
        },
      });
      document.dispatchEvent(
        new CustomEvent<string>("manageMintedToken:claimPageDeleted", {
          detail: contestId,
        })
      );
      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
      await logError({
        e,
        message: "[handleDisableClaimPage] disable claim page failed",
      });
    }
  };
  return (
    <div css={styles.confirmDeleteModal}>
      <div css={styles.modalHeader}>
        <Title size="medium">Delete &quot;{claimPageName}&quot;?</Title>
        <Text size="medium">
          If you delete this Claim Page, people will no longer be able to claim
          a token from it, even if they have the link or QR code.
        </Text>
      </div>
      <div css={styles.modalButtons}>
        <Button color="secondary" onClick={handleClose}>
          <Text bold>Cancel</Text>
        </Button>
        <Button
          color="destructive"
          additionalCss={styles.deleteConfirmBtn}
          onClick={handleDelete}
          loading={loading}
        >
          <Text bold additionalCss={styles.deleteConfirmText}>
            Delete Claim Page
          </Text>
        </Button>
      </div>
      {error && (
        <ErrorLinkContainer
          additionalCss={styles.errorContainer}
          onClose={() => setError("")}
        >
          <div css={styles.errorMessage}>
            <Text>{error}</Text>
          </div>
        </ErrorLinkContainer>
      )}
    </div>
  );
};

export default DeleteClaimPage;
