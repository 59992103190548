import React, { FC, memo } from "react";

import { BenefitType, Maybe } from "apollo/graphql.generated";
import Text from "components/Typography/Text";

import {
  getBenefitShortTitle,
  getIcon,
} from "../../../../Shared/BenefitsOptions";

import * as styles from "./styles";

export interface Props {
  type: BenefitType;
  customBenefitName?: Maybe<string>;
}
const AddedBenefit: FC<Props> = ({ type, customBenefitName }) => {
  return (
    <div key={type + customBenefitName} id={type} css={styles.benefit}>
      <div css={styles.benefitIconContainer}>{getIcon(type)}</div>
      <Text>{getBenefitShortTitle(type, customBenefitName)}</Text>
    </div>
  );
};

export default memo(AddedBenefit);
