import { makeVar } from "@apollo/client";

import { ThemeType } from "styles/theme/theme.types";
import { Maybe } from "utils/types";

export type LayoutContext = {
  theme?: Maybe<ThemeType>;
};

const defaultLayoutContext: LayoutContext = {
  theme: undefined,
};

export const layoutContext = makeVar<LayoutContext>(defaultLayoutContext);

export const resetLayoutContextVar = () => {
  layoutContext(defaultLayoutContext);
};

export const updateLayoutContext = (data: Partial<LayoutContext>) => {
  layoutContext({ ...layoutContext(), ...data });
};
