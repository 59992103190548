import React, { FC, memo, useCallback } from "react";

import { useParams } from "react-router-dom";

import { Offer, Contest } from "apollo/graphql.generated";
import { ModalType, updateModalVar } from "apollo/reactive";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow-within-circle-tilted.svg";
import { ReactComponent as CheckCircle } from "assets/icons/check-within-circle.svg";
import Button from "components/Button";
import Layout from "components/Layout";
import { AppSEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import TokenDetails from "components/Token/TokenDetails";
import Label from "components/Typography/Label";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE, APP_URL } from "config";
import NotFound from "containers/Error/NotFound";
import useAppLocation from "hooks/useAppLocation";

import FieldProperty from "../../Shared/FieldProperty";
import TokenPreview from "../../Shared/TokenPreview";
import * as sharedStyles from "../../Shared/styles";

import Benefits from "./Benefits";
import ClaimPages from "./ClaimPages";
import ManageTokenStatus from "./ManageTokenStatus";
import StatusBox from "./StatusBox";
import * as styles from "./styles";
import { Token } from "./types";

export interface Props {
  isPending: boolean;
  loading: boolean;
  showCompleteModal?: boolean;
  token?: Token;
  offer?: Partial<Offer>;
  communityId?: string;
  contests?: Partial<Contest>[];
}
const ManageToken: FC<Props> = ({
  isPending,
  loading,
  token,
  offer,
  communityId,
  contests,
  showCompleteModal = false,
}) => {
  const { getEditCommunityAbsoluteURL } = useAppLocation();
  const { communitySlug = "" } = useParams();
  const redirectUrl = getEditCommunityAbsoluteURL(
    communitySlug ?? "app",
    APP_URL.editCommunity.tokens
  );

  const showSendTokenToMembersModal = useCallback(() => {
    const dataMap: Record<string, string | boolean> = {
      communityId: communityId as string,
      tokenId: token?.tokenId as string,
      tokenName: token?.name as string,
    };

    updateModalVar({
      showModal: ModalType.SEND_TOKEN_TO_MEMBERS,
      data: dataMap,
    });
  }, [communityId, token]);

  const nonTransferable = () => {
    return <>&#183; Non-transferable</>;
  };

  const isCollectibleToken = !token?.benefits || token.benefits.length === 0;
  let tokenTypeTitle = `${loading ? "Loading" : token?.tokenType} Token`;
  if (!loading && isCollectibleToken) {
    tokenTypeTitle = "Collectible Token";
  }

  if (!token && !loading) {
    return <NotFound />;
  }

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.EDITCOMMUNITY_TOKEN_MANAGE} />

      <Layout
        css={styles.designLayout}
        backgroundShow={true}
        backgroundColor="primary"
        navLayout="action"
        title={tokenTypeTitle}
        subtitle=""
        page={0}
        cancelRedirect={redirectUrl}
      >
        {loading && (
          <div css={styles.loadingIcon}>
            <LoadingIcon />
          </div>
        )}
        {!loading && (
          <>
            {showCompleteModal && (
              <StatusBox
                title="Minting Completed"
                text="Your token has finished minting. You can now make this token
                  available for people to buy or claim, or create a private
                  claim page where they can claim it for free."
                icon={<CheckCircle css={styles.statusBoxIcon} />}
                hasClose
              />
            )}
            {isPending && (
              <StatusBox
                title="Minting in Progress..."
                text="Minting a token on the blockchain usually takes about 15 seconds, but can take longer depending on network conditions."
                icon={<LoadingIcon inheritColor css={styles.statusBoxIcon} />}
                hasClose={false}
              />
            )}
            <div css={sharedStyles.designLayout}>
              <div css={styles.leftContainer}>
                <Label size="medium">{tokenTypeTitle}</Label>
                <Title size="large">{token?.name}</Title>
                <Text extraCss={styles.spacedFields}>
                  {!!token?.creatorBalance && !!token?.supply
                    ? `${token?.creatorBalance} / ${token?.supply} Remaining`
                    : ""}{" "}
                  {token?.mintedAsNonTransferable && nonTransferable()}
                </Text>
                <ManageTokenStatus
                  readonly={isPending}
                  token={token}
                  offer={offer}
                  communityId={communityId}
                />
                <ClaimPages
                  readonly={isPending}
                  contests={contests}
                  token={token}
                  communityId={communityId}
                />
                <FieldProperty
                  boldTitle
                  title="Description"
                  value={token?.description ?? ""}
                  additionalCss={styles.spacedFields}
                />

                <Benefits token={token} />
                <TokenDetails
                  showTitle={true}
                  disableOpenSeaLink={isPending}
                  contractAddress={token?.communityAddress}
                  tokenId={token?.tokenId}
                />
              </div>

              <div css={styles.rightContainer}>
                <TokenPreview
                  showTitle={false}
                  animationUri={token?.animationUri ?? ""}
                  imageUri={token?.uri ?? ""}
                />
                <Button
                  extraCss={styles.sendToken}
                  onClick={showSendTokenToMembersModal}
                  disabled={isPending}
                >
                  <ArrowIcon css={styles.icon} />
                  Send to Community Members
                </Button>
              </div>
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

export default memo(ManageToken);
