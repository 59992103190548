import { css, Theme } from "@emotion/react";

export const fieldGroup = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[8]};
`;

export const container = (theme: Theme) => css`
  display: flex;
  align-items: flex-start;
  gap: ${theme.verticalSpaces.large[6]};
`;

export const titleLabel = (theme: Theme) => css`
  display: block;
  margin-bottom: ${theme.verticalSpaces.large[3]};
`;

export const label = (theme: Theme) => css`
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  border: 1px solid ${theme.textInput.border.color};
  padding: 24px 16px;
`;

export const inputIcon = (theme: Theme) => css`
  transition-property: border, background-color;
  transition-duration: 0.4s;
  transition-timing-function: ${theme.animations.easeOutQuart};

  width: 20px;
  height: 20px;
  margin-left: ${theme.verticalSpaces.large[6]};
  background-color: ${theme.textInput.border.color};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  svg {
    transition: opacity 0.6s ${theme.animations.easeOutQuart};
    opacity: 0;
  }
`;

export const defaultInput = css`
  display:none;
  &:checked + label{
    svg {
      opacity: 1;
    }
`;
