import { css, Theme } from "@emotion/react";

export const benefit = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border: 1px solid ${theme.divider};
  border-radius: 10px;

  ${theme.mediaQueries.smallOnly} {
    justify-content: flex-start;
    width: 100%;
  }
`;

export const benefitIconContainer = (theme: Theme) => css`
  margin-right: ${theme.verticalSpaces.large[4]};
`;
