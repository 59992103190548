import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type GetDiscordLinkQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetDiscordLinkQuery = {
  getDiscordLinkUrl: {
    __typename: "GetDiscordLinkUrlResponse";
    linkUrl: string;
  };
};

export type LinkDiscordAccountMutationVariables = Types.Exact<{
  authCode: Types.Scalars["String"];
}>;

export type LinkDiscordAccountMutation = {
  linkDiscordUser: {
    __typename: "LinkDiscordAccountResponse";
    id: string;
    syncSuccessful: boolean | null;
    syncDetails: {
      __typename: "SyncBenefitsResponse";
      successful: number;
      failed: number;
      total: number;
    } | null;
    discord: {
      __typename: "DiscordLinkedAccountData";
      user: {
        __typename: "DiscordLinkedAccountUserInfo";
        username: string | null;
        id: string | null;
        avatar: string | null;
        discriminator: string | null;
      } | null;
    } | null;
  };
};

export type UnlinkDiscordAccountMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type UnlinkDiscordAccountMutation = {
  unlinkDiscordUser: { __typename: "UnlinkDiscordAccountResponse"; id: string };
};

export const GetDiscordLinkDocument = gql`
  query getDiscordLink {
    getDiscordLinkUrl {
      linkUrl
    }
  }
`;

/**
 * __useGetDiscordLinkQuery__
 *
 * To run a query within a React component, call `useGetDiscordLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscordLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscordLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDiscordLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDiscordLinkQuery,
    GetDiscordLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDiscordLinkQuery, GetDiscordLinkQueryVariables>(
    GetDiscordLinkDocument,
    options
  );
}
export function useGetDiscordLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscordLinkQuery,
    GetDiscordLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDiscordLinkQuery, GetDiscordLinkQueryVariables>(
    GetDiscordLinkDocument,
    options
  );
}
export type GetDiscordLinkQueryHookResult = ReturnType<
  typeof useGetDiscordLinkQuery
>;
export type GetDiscordLinkLazyQueryHookResult = ReturnType<
  typeof useGetDiscordLinkLazyQuery
>;
export type GetDiscordLinkQueryResult = Apollo.QueryResult<
  GetDiscordLinkQuery,
  GetDiscordLinkQueryVariables
>;
export const LinkDiscordAccountDocument = gql`
  mutation linkDiscordAccount($authCode: String!) {
    linkDiscordUser(input: { authCode: $authCode, joinServers: true }) {
      id
      syncSuccessful
      syncDetails {
        successful
        failed
        total
      }
      discord {
        user {
          username
          id
          avatar
          discriminator
        }
      }
    }
  }
`;
export type LinkDiscordAccountMutationFn = Apollo.MutationFunction<
  LinkDiscordAccountMutation,
  LinkDiscordAccountMutationVariables
>;

/**
 * __useLinkDiscordAccountMutation__
 *
 * To run a mutation, you first call `useLinkDiscordAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkDiscordAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkDiscordAccountMutation, { data, loading, error }] = useLinkDiscordAccountMutation({
 *   variables: {
 *      authCode: // value for 'authCode'
 *   },
 * });
 */
export function useLinkDiscordAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkDiscordAccountMutation,
    LinkDiscordAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkDiscordAccountMutation,
    LinkDiscordAccountMutationVariables
  >(LinkDiscordAccountDocument, options);
}
export type LinkDiscordAccountMutationHookResult = ReturnType<
  typeof useLinkDiscordAccountMutation
>;
export type LinkDiscordAccountMutationResult =
  Apollo.MutationResult<LinkDiscordAccountMutation>;
export type LinkDiscordAccountMutationOptions = Apollo.BaseMutationOptions<
  LinkDiscordAccountMutation,
  LinkDiscordAccountMutationVariables
>;
export const UnlinkDiscordAccountDocument = gql`
  mutation unlinkDiscordAccount {
    unlinkDiscordUser {
      id
    }
  }
`;
export type UnlinkDiscordAccountMutationFn = Apollo.MutationFunction<
  UnlinkDiscordAccountMutation,
  UnlinkDiscordAccountMutationVariables
>;

/**
 * __useUnlinkDiscordAccountMutation__
 *
 * To run a mutation, you first call `useUnlinkDiscordAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkDiscordAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkDiscordAccountMutation, { data, loading, error }] = useUnlinkDiscordAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnlinkDiscordAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkDiscordAccountMutation,
    UnlinkDiscordAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlinkDiscordAccountMutation,
    UnlinkDiscordAccountMutationVariables
  >(UnlinkDiscordAccountDocument, options);
}
export type UnlinkDiscordAccountMutationHookResult = ReturnType<
  typeof useUnlinkDiscordAccountMutation
>;
export type UnlinkDiscordAccountMutationResult =
  Apollo.MutationResult<UnlinkDiscordAccountMutation>;
export type UnlinkDiscordAccountMutationOptions = Apollo.BaseMutationOptions<
  UnlinkDiscordAccountMutation,
  UnlinkDiscordAccountMutationVariables
>;
