import {
  ChangeEvent,
  KeyboardEvent,
  FC,
  FocusEvent,
  memo,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { string as yupString } from "yup";

import { BenefitType, TokenBenefit } from "apollo/graphql.generated";
import { ModalType, updateModalVar } from "apollo/reactive";
import ImageUpload from "components/CommunityCreator/ImageUpload";
import CheckboxList, { CheckboxItem } from "components/Inputs/CheckboxList";
import OptionsList from "components/Inputs/OptionsList";
import SwitchField from "components/Inputs/SwitchField";
import TextArea from "components/Inputs/TextArea";
import TextField from "components/Inputs/TextField";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { Currency } from "utils/currency";

import { getBenefitShortTitle, getIcon } from "../BenefitsOptions";

import * as styles from "./styles";
import { Props } from "./types";

// TODO: Break up this component into smaller ones based on the type (currency-field, number-field, etc...)
// We can have a parent component that wrap those smaller ones and adds the different title options above it.
const InputComponent: FC<Props> = ({
  type,
  primaryDescription,
  secondaryDescription,
  title,
  titleDisplay = "title",
  description,
  advancedOptionsDescription,
  maxLen,
  placeholder,
  placeholderPrefix,
  placeholderPostfix,
  value,
  setValue,
  onErrorHandler,
  onSubmit,
  hasRemove = true,
  marginBottom = true,
  min,
  max,
  currency,
  extraCss,
}) => {
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setError("");
  }, [currency]);

  useLayoutEffect(() => {
    onErrorHandler?.(error);
  }, [error]);

  const handleOnBlur = async (
    event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setValue(event.target.value);

  const handleNumberFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const quantity = +event.target.value;
    if ((min && quantity < min) || (max && quantity > max)) {
      setValue(null);
      setError(`Enter a value between ${min}-${max}`);
      return;
    } else {
      setError("");
    }
    setValue(quantity);
  };

  const handleFileAccept = async (file: File) => {
    if (file.size > 2 * 1024 * 1024) {
      setError("File must be equal to or less than 2 MB");
    } else if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
      setError("File must be either a jpeg or png file");
    } else {
      setError("");
      setValue(file);
    }
  };

  const handleFileReject = async (error: string) => {
    setValue(null);
    setError(error);
  };

  const handleRemove = async () => {
    setValue(null);
  };

  /**
   * Gets WETH parsed BigNumber and returns <code>undefined</code> if it's invalid, like
   * an underflowed value.
   * @param value
   */
  const getParsedWethValue = (
    value: string | undefined
  ): BigNumber | undefined => {
    if (!value) {
      return undefined;
    }
    let parsedWeth;
    try {
      parsedWeth = parseEther(value);
    } catch (e) {
      return undefined;
    }
    return parsedWeth;
  };

  const wethCurrencySchema = yupString()
    .trim()
    // At least one digit before the "dot" and zero or more digit after it.
    .matches(/^(\d+([.]\d*)?|[.]\d+)$/, "Price should be a valid number.")
    .test(
      "invalid",
      () => "Price should be a valid ETH value",
      (value) => !value || !!getParsedWethValue(value)
    )
    .test(
      "max",
      () => "Maximum price allowed is 100000000000000 ETH",
      (value) => {
        const parsedWethValue = getParsedWethValue(value);
        return (
          !parsedWethValue || parsedWethValue.lte(parseEther("100000000000000"))
        );
      }
    )
    .test(
      "min",
      () => "Minimum price allowed is 0.0001 ETH or 0",
      (value) => {
        const parsedWethValue = getParsedWethValue(value);
        return (
          !parsedWethValue ||
          parsedWethValue.eq(parseEther("0")) ||
          parsedWethValue.gte(parseEther("0.0001"))
        );
      }
    )
    .required("Price is required");

  const usdCurrencySchema = yupString()
    .trim()
    .matches(/^\d*$/, "Price should have digits only")
    .test(
      "max",
      () => "Maximum price allowed is 999999999 USD",
      (value) => !value || parseInt(value) <= 999999999
    )
    .required("Price is required");

  const handleCurrencyChange = async (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    try {
      setError("");
      const value = event.target.value;
      setValue(value);
      if (currency === Currency.USD) {
        await usdCurrencySchema.validate(value);
      } else {
        await wethCurrencySchema.validate(value);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      event.preventDefault();
      setError(e.message);
      return;
    }
  };
  const handleOnEnterCurrency = async (
    event: KeyboardEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    if (!error) {
      if (onSubmit) onSubmit(value);
    }
  };

  const rowCount = type == "text-field" ? 1 : 5;

  type FixedBenefit =
    | BenefitType.CONTENT_FEED
    | BenefitType.DISCORD
    | BenefitType.SHOPIFY;

  const fixedBenefitsMap: Record<FixedBenefit, CheckboxItem> = {
    [BenefitType.CONTENT_FEED]: {
      name: getBenefitShortTitle(BenefitType.CONTENT_FEED)!,
      icon: getIcon(BenefitType.CONTENT_FEED),
      permanentItemText: "Always Included",
    },
    [BenefitType.DISCORD]: {
      name: getBenefitShortTitle(BenefitType.DISCORD)!,
      icon: getIcon(BenefitType.DISCORD),
    },
    [BenefitType.SHOPIFY]: {
      name: getBenefitShortTitle(BenefitType.SHOPIFY)!,
      icon: getIcon(BenefitType.SHOPIFY),
    },
  };

  const handleOnBenefitListChange = (items: CheckboxItem[]) => {
    const benefits: TokenBenefit[] = [];
    items.forEach((item) => {
      const type = (Object.keys(fixedBenefitsMap) as FixedBenefit[]).find(
        (type) => {
          return fixedBenefitsMap[type].name === item.name;
        }
      );
      if (type) {
        benefits.push({ type });
      }
    });

    setValue(benefits);
  };

  const updateBenefit = (benefitToAdd: TokenBenefit, add: boolean) => {
    const customBenefits = value as Array<TokenBenefit>;

    if (add) {
      const benefitAlreadyAdded = customBenefits.find(
        (benefit) => benefit.customName === benefitToAdd.customName
      );

      if (benefitAlreadyAdded) return false;

      setValue([...customBenefits, benefitToAdd]);

      return true;
    }

    setValue(
      [...customBenefits].filter(
        (benefit) => benefit.customName !== benefitToAdd.customName
      )
    );

    return false;
  };

  const showModal = () => {
    updateModalVar({
      showModal: ModalType.CHOOSE_CUSTOM_BENEFITS,
      updateBenefit,
    });
  };

  const renderTitle = () => {
    switch (titleDisplay) {
      case "text":
        return (
          <Text size="medium" bold>
            {title}
          </Text>
        );
      case "title":
        return <Title size="xsmall">{title}</Title>;
    }
  };

  return (
    <div css={[styles.container(marginBottom)]}>
      {renderTitle()}
      {description && (
        <Text as="div" size="small" extraCss={styles.description}>
          {description}
        </Text>
      )}
      {type == "text-field" || type == "text-area" ? (
        <TextArea
          rows={rowCount}
          maxLength={maxLen}
          className=""
          placeholder={placeholder}
          defaultValue={value as string}
          onBlur={handleOnBlur}
          error={error}
          onChange={() => setError("")}
          onClick={() => setError("")}
          additionalCss={extraCss}
        />
      ) : null}
      {type == "number-field" ? (
        <TextField
          type="number"
          placeholder={placeholder}
          defaultValue={value as number}
          onChange={handleNumberFieldOnChange}
          onWheel={(event) => event.currentTarget.blur()}
          error={error}
          min={min}
          max={max}
          extraCss={extraCss}
        />
      ) : null}
      {type == "currency-field" ? (
        <TextArea
          rows={1}
          value={value as string}
          error={error}
          onChange={handleCurrencyChange}
          onKeyPress={(e) => e.key === "Enter" && handleOnEnterCurrency(e)}
          placeholderPrefix={placeholderPrefix}
          placeholderPostfix={placeholderPostfix}
          placeholder={placeholder}
          additionalCss={extraCss}
        />
      ) : null}
      {type == "file-drop" ? (
        <ImageUpload
          dragActiveDescription="Drop here ..."
          primaryDescription={primaryDescription}
          secondaryDescription={secondaryDescription}
          onFileAccept={handleFileAccept}
          onFileReject={handleFileReject}
          hasRemove={hasRemove}
          onRemove={handleRemove}
          error={error}
          imageUrl={value as string}
          minHeight={400}
          minWidth={400}
        />
      ) : null}
      {type == "checkbox-list" ? (
        <CheckboxList
          handleOnChange={handleOnBenefitListChange}
          items={Object.values(fixedBenefitsMap)}
          initialSelectedNames={(value as TokenBenefit[]).map(
            (benefit) => fixedBenefitsMap[benefit.type as FixedBenefit].name
          )}
        />
      ) : null}
      {type == "options-list" ? (
        <OptionsList
          addButtonText="Add a Custom Benefit"
          options={(value as Array<TokenBenefit>).map((benefit) => ({
            name: benefit.customName || "",
            icon: getIcon(BenefitType.CUSTOM),
          }))}
          addBenefitHandler={() => {
            showModal();
          }}
          removeBenefitHandler={(option) => {
            updateBenefit(
              {
                type: BenefitType.CUSTOM,
                customName: option.name,
              },
              false
            );
          }}
        />
      ) : null}
      {type == "switch-field" ? (
        <SwitchField
          description={advancedOptionsDescription}
          value={value as boolean}
          setValue={setValue}
        />
      ) : null}
    </div>
  );
};

export default memo(InputComponent);
