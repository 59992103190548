import React, { FC, memo } from "react";

import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import * as styles from "./styles";
import { Props } from "./types";

const FieldProperty: FC<Props> = ({
  title,
  value,
  textColor = "primary",
  additionalCss,
}) => {
  return (
    <div css={[styles.fieldPropertyContainer, additionalCss]}>
      <Title size="xsmall">{title}</Title>
      <Text color={textColor}>{value}</Text>
    </div>
  );
};

export default memo(FieldProperty);
