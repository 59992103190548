import { useRef } from "react";

import { css } from "@emotion/react";

import { AttachmentMetadata, MediaMetadata } from "apollo/graphql.generated";

/**
 * Styles
 */
const styles = {
  nodeWrapper: css`
    position: relative;
  `,
  image: css`
    max-width: 100%;
  `,
};

/**
 * Helpers
 */
const isMediaMetadata = (
  toBeDefined?: AttachmentMetadata | null
): toBeDefined is MediaMetadata => {
  if (!toBeDefined) return false;
  return (toBeDefined as MediaMetadata).mime !== undefined;
};

/**
 * Main Component
 */
export interface ImageAttachmentProps {
  attachment: AttachmentMetadata;
}

const ImageAttachment = ({ attachment }: ImageAttachmentProps) => {
  const ref = useRef<HTMLDivElement>(null);

  if (!isMediaMetadata(attachment) || !attachment.url) {
    return null;
  }

  return (
    <div css={styles.nodeWrapper} ref={ref}>
      <img css={styles.image} src={attachment?.url} alt={undefined} />
    </div>
  );
};

export default ImageAttachment;
