import { FC, useCallback } from "react";

import { resetBannerVar } from "apollo/reactive/banner";
import { ReactComponent as CloseIcon } from "assets/icons/regular-rounded-close.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import Text from "components/Typography/Text";

import * as styles from "./styles";

export type BackgroundStyle = "light" | "dark";

type Message = {
  text: string;
  warning?: boolean;
};

type GlobalStatusBannerProps = {
  background: BackgroundStyle;
  messages: Message[];
  canClose?: boolean;
};

const GlobalStatusBanner: FC<GlobalStatusBannerProps> = ({
  background,
  messages,
  canClose,
}) => {
  const handleClose = useCallback(() => {
    resetBannerVar();
  }, []);

  return (
    <div css={styles.banner(background)}>
      <div css={styles.messagesContainer}>
        <div css={styles.messages}>
          {messages.map((message, index) => (
            <span css={styles.message} key={index}>
              {message.warning && (
                <WarningIcon css={styles.warning(background)} />
              )}
              <Text inverted={background === "dark"}>{message.text}</Text>
            </span>
          ))}
        </div>
      </div>
      {canClose && (
        <button onClick={handleClose}>
          <CloseIcon css={styles.close(background)} />
        </button>
      )}
    </div>
  );
};

export default GlobalStatusBanner;
