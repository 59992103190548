import { useTokenDetailsQuery } from "apollo/queries";
import useAppLocation from "hooks/useAppLocation";

const useTokenDetails = (tokenId: string | number) => {
  const { currentCommunitySlug, editingCommunitySlug, isAppSlug } =
    useAppLocation();

  const communitySlug = isAppSlug ? editingCommunitySlug : currentCommunitySlug;
  const { ...rest } = useTokenDetailsQuery({
    variables: {
      slug: communitySlug,
      useChain: false,
      tokenId: +tokenId,
    },
    fetchPolicy: "network-only",
  });

  return {
    ...rest,
  };
};

export default useTokenDetails;
export type UseTokenDetails = ReturnType<typeof useTokenDetails>;
