import React, { FC, memo } from "react";

import Label from "components/Typography/Label";

import * as styles from "./styles";

const AuthOrDivider: FC = () => {
  return (
    <div css={styles.orDividerContainer}>
      <div css={styles.dividerBox} />
      <Label size="medium" extraCss={styles.dividerText}>
        Or
      </Label>
      <div css={styles.dividerBox} />
    </div>
  );
};

export default memo(AuthOrDivider);
