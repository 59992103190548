import React, { FC, memo, useEffect } from "react";

import { useReactiveVar } from "@apollo/client";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";

import { useContestQuery } from "apollo/queries";
import { communityVar, ModalType, updateModalVar } from "apollo/reactive";
import SamSmithSig from "assets/images/samsmith-pages/sam-smith-sig.png";
import Layout from "components/Layout";
import { CommunitySEO } from "components/Layout/SEO";
import CheckYourEmailModal from "components/Modals/CheckYourEmail";
import { SafeInnerHTML } from "components/SafeInnerHTML";
import CommunityText from "components/_Community/Text";
import { COMMUNITY_PAGE_TITLE } from "config";
import NotFound from "containers/Error/NotFound";
import useSession from "hooks/useSession";

import { updateLayoutContext } from "../../../apollo/reactive/layout";
import AlreadyMemberCard from "../AlreadyMemberCard";
import AlreadyReceivedCard from "../AlreadyReceivedCard";
import ClosedContestCard from "../ClosedContestCard";
import EnterContestForm from "../EnterContestForm/index";
import ReceivedCard from "../ReceivedCard";
import useLogic from "../logic";

import * as styles from "./samsmith.styles";

const SamSmithContestEntry: FC = () => {
  const params = useParams();

  const { user, isCommunityMember } = useSession();
  const communityData = useReactiveVar(communityVar);
  const isMember = isCommunityMember(communityData.id);

  const { data, loading, refetch } = useContestQuery({
    fetchPolicy: "network-only",
    variables: {
      contestId: params.contestId as string,
    },
  });

  const { addMeToContestMutation } = useLogic({
    contestId: params.contestId,
    showQuestion: data?.contest?.showQuestion,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setOnSubmitLoading: () => {},
  });

  useEffect(() => {
    if (!addMeToContestMutation?.data) {
      return;
    }

    refetch();
  }, [addMeToContestMutation.data]);

  useEffect(() => {
    if (!addMeToContestMutation?.error) {
      return;
    }

    updateModalVar({
      showModal: ModalType.GENERIC_ERROR,
      showView: null,
    });
  }, [addMeToContestMutation.error]);

  useEffect(() => {
    refetch();
  }, [user]);

  if (!params.contestId || (!loading && !data)) {
    updateLayoutContext({ theme: "system" });
    return <NotFound />;
  }

  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.CONTEST} />

      <Layout
        backgroundColor="community"
        showHeader={false}
        backgroundShow={true}
        css={styles.layout}
      >
        <div css={styles.container}>
          {!loading && (
            <>
              <motion.div
                css={styles.banner}
                variants={{
                  hidden: { opacity: 0 },
                  show: { opacity: 1, transition: { delay: 0.2 } },
                }}
                initial="hidden"
                animate="show"
              >
                <img src={SamSmithSig} alt={undefined} />
              </motion.div>

              <motion.div
                css={styles.content}
                variants={{
                  hidden: { opacity: 0 },
                  show: { opacity: 1, transition: { delay: 0.4 } },
                }}
                initial="hidden"
                animate="show"
              >
                <div css={styles.contentWrapper}>
                  {data?.contest?.contestPage?.description && (
                    <CommunityText as="div" innerHTML={true}>
                      <SafeInnerHTML
                        css={styles.introText}
                        html={data?.contest?.contestPage?.description}
                      />
                    </CommunityText>
                  )}

                  {isMember && (
                    <AlreadyMemberCard communityName={communityData.name} />
                  )}

                  {(!isMember &&
                    !data?.contest?.active &&
                    !data?.contest?.hasUserApplied && <ClosedContestCard />) ||
                    null}

                  {!isMember &&
                    data?.contest?.active &&
                    !data?.contest?.hasUserApplied && (
                      <EnterContestForm
                        contestId={params.contestId ?? ""}
                        placeholder={data?.contest?.contestPage?.placeholder}
                        showQuestion={data?.contest?.showQuestion}
                        formFieldLabel={
                          data?.contest?.contestPage?.formFieldLabel
                        }
                        autoWin={data?.contest?.autoWin}
                        showTerms={true}
                      />
                    )}

                  {!isMember &&
                    data?.contest?.active &&
                    data?.contest?.hasUserApplied &&
                    addMeToContestMutation.called && (
                      <ReceivedCard
                        email={user?.personalInformation?.email || ""}
                        extraCss={styles.receivedCard}
                      />
                    )}

                  {!isMember &&
                    data?.contest?.active &&
                    data?.contest?.hasUserApplied &&
                    !addMeToContestMutation.called && (
                      <AlreadyReceivedCard
                        email={user?.personalInformation?.email || ""}
                        extraCss={styles.receivedCard}
                      />
                    )}
                </div>
              </motion.div>
            </>
          )}

          <CheckYourEmailModal logInError={null} />
        </div>
      </Layout>
    </>
  );
};

export default memo(SamSmithContestEntry);
