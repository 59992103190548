import React, { FC, memo } from "react";

import TokenAnimation from "components/TokenAnimation";
import Text from "components/Typography/Text";
import { Maybe } from "utils/types";

import * as styles from "./styles";

export type Props = {
  animationUrl?: Maybe<string>;
  imageUrl?: Maybe<string>;
  alt?: string;
};

const TokenView: FC<Props> = ({
  animationUrl,
  imageUrl,
  alt = "Token view",
}) => (
  <div css={styles.container}>
    {animationUrl ? (
      <TokenAnimation
        posterUri={imageUrl || ""}
        sourceUri={animationUrl}
        extraCss={styles.media}
        autoPlay
      />
    ) : imageUrl ? (
      <div css={styles.tokenImageWrapper}>
        <img css={styles.media} src={imageUrl} alt={alt} />
      </div>
    ) : (
      <div css={styles.emptyToken}>
        <Text size="small" bold extraCss={styles.emptyTokenText}>
          Token
        </Text>
      </div>
    )}
  </div>
);

export default memo(TokenView);
