import { useMemo } from "react";

import {
  APP_SUBDOMAIN,
  DEV_COMMUNITY_SLUG,
  APP_URL,
  COMMUNITY_URL,
} from "config";
import { getSlugCookie, setSlugCookie } from "utils/cookie";

const useAppLocation = () => {
  const appDomain = document.location.origin;

  const isLocalDev = useMemo(() => {
    return (
      location.hostname === "localhost" || location.hostname === "127.0.0.1"
    );
  }, []);

  const isLocalDevUrl = (hostname: string) => {
    // startWith to handle various ports
    return hostname.startsWith("localhost") || hostname.startsWith("127.0.0.1");
  };

  const currentCommunitySlug = useMemo(() => {
    /**
     * allow devs to change the local community
     * by going to "http://localhost:3000/ci/COMMUNITY_SLUG"
     */
    if (isLocalDev) {
      // if missing, use the config default community
      if (!getSlugCookie()) {
        setSlugCookie(DEV_COMMUNITY_SLUG ?? null);
      }

      return getSlugCookie();
    } else {
      return window.location.host.split(".")[0];
    }
  }, []);

  const isAppSlug = useMemo(() => {
    return currentCommunitySlug === APP_SUBDOMAIN;
  }, []);

  const editingCommunitySlug = useMemo(() => {
    if (!isAppSlug) return "";
    const splits = window.location.href.split("/edit-community/");
    if (splits && splits.length > 1) {
      const slug = splits[1].split("/token")[0];
      return slug;
    }
    return "";
  }, []);

  const getCommunityURLBySlug = (subdomain: string) => {
    return appDomain.replace(
      /^(https?:\/\/)(www\.)?([^.])*/,
      `$1$2${subdomain}`
    );
  };

  const getEditCommunityURL = (communitySlug: string, route: string) => {
    const url = route.replace(":communitySlug", communitySlug);

    if (isAppSlug) {
      return url;
    } else {
      return `${getCommunityURLBySlug("app")}${url}`;
    }
  };

  const getEditCommunityAbsoluteURL = (
    communitySlug: string,
    route: string
  ) => {
    const routeSuffix = route.startsWith("/") ? route : "/" + route;
    const url =
      APP_URL.editCommunity.base.replace(":communitySlug", communitySlug) +
      routeSuffix;
    if (isAppSlug) {
      return url;
    } else {
      return `${getCommunityURLBySlug("app")}${url}`;
    }
  };

  const getTokenDetailAbsoluteURL = (
    communitySlug: string,
    tokenId: string
  ) => {
    const tokenDetailPath = COMMUNITY_URL.tokenDetail.replace(
      ":tokenId",
      tokenId
    );
    const baseUrl = getCommunityURLBySlug(communitySlug);
    return `${baseUrl}${tokenDetailPath}`;
  };

  const getContestAbsoluteURL = (communitySlug: string, contestId: string) => {
    const contestPath = `${COMMUNITY_URL.contest}/${contestId}`;
    const baseUrl = getCommunityURLBySlug(communitySlug);
    return `${baseUrl}${contestPath}`;
  };

  // currently using drops(contests) as base behind claims
  const getClaimAbsoluteURL = (communitySlug: string, claimId: string) => {
    const claimPath = `${COMMUNITY_URL.claim}/${claimId}`;
    const baseUrl = getCommunityURLBySlug(communitySlug);
    return `${baseUrl}${claimPath}`;
  };

  const getPurchaseAbsoluteURL = (communitySlug: string, offerId: string) => {
    const path = `${COMMUNITY_URL.purchase}/${offerId}`;
    const baseUrl = getCommunityURLBySlug(communitySlug);
    return `${baseUrl}${path}`;
  };

  const isAppUrl = (hostWithProtocol: string) => {
    const host = hostWithProtocol.replace(/(^\w+:|^)\/\//, "");
    if (isLocalDevUrl(host)) {
      // if missing, use the config default community
      if (!getSlugCookie()) {
        setSlugCookie(DEV_COMMUNITY_SLUG ?? null);
      }

      return getSlugCookie() === APP_SUBDOMAIN;
    } else {
      return host.split(".")[0] === APP_SUBDOMAIN;
    }
  };

  return {
    appDomain,
    currentCommunitySlug,
    editingCommunitySlug,
    getCommunityURLBySlug,
    getEditCommunityURL,
    getEditCommunityAbsoluteURL,
    isAppSlug,
    isAppUrl,
    isCommunitySlug: !isAppSlug,
    getTokenDetailAbsoluteURL,
    getContestAbsoluteURL,
    getClaimAbsoluteURL,
    getPurchaseAbsoluteURL,
  };
};

export default useAppLocation;
export type UseAppLocation = ReturnType<typeof useAppLocation>;
