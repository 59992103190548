import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  font-family: TT Norms Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  display: inline-flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  span {
    color: ${theme.colorMap.accent.default};
  }

  @media (hover: hover) {
    &:hover span {
      text-decoration: underline;
    }
  }
`;
