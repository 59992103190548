import { css, Theme } from "@emotion/react";

import { UserCommunityCardLinkProps } from ".";

export const container =
  (size: UserCommunityCardLinkProps["size"]) => (theme: Theme) =>
    css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      position: relative;
      border-radius: ${theme.cardContainer.borderRadius.medium};

      ${size === "regular" &&
      css`
        padding: 20px;
        ${theme.mediaQueries.smallOnly} {
          padding: 16px 10px;
        }
      `}

      ${size === "small" &&
      css`
        padding: 12px;
      `}

      @media (hover: hover) {
        &:hover {
          background-color: ${theme.background.colors.secondary};
          span {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    `;

export const media =
  (size: UserCommunityCardLinkProps["size"]) => (theme: Theme) =>
    css`
      img {
        display: block;
        border-radius: 50%;
        object-fit: cover;

        ${size === "small" &&
        css`
          height: 56px;
          width: 56px;
        `}

        ${size === "regular" &&
        css`
          height: 88px;
          width: 88px;

          ${theme.mediaQueries.smallOnly} {
            height: 60px;
            width: 60px;
          }
        `}
      }
    `;

export const content =
  (size: UserCommunityCardLinkProps["size"]) => (theme: Theme) =>
    css`
      display: block;

      ${size === "regular" &&
      css`
        margin-left: 20px;
        padding-right: 15px;

        ${theme.mediaQueries.smallOnly} {
          padding-right: 0;
        }
      `}

      ${size === "small" &&
      css`
        margin-left: 12px;
      `}
    `;

export const icon =
  (size: UserCommunityCardLinkProps["size"]) => (theme: Theme) =>
    css`
      transition: all 0.6s ${theme.animations.easeOutQuart};

      position: absolute;
      right: 20px;
      top: calc(50% - 24px / 2);
      opacity: 0;
      transform: translateX(-15px);

      ${size === "small" &&
      css`
        display: none;
      `}

      ${size === "regular" &&
      css`
        ${theme.mediaQueries.smallOnly} {
          display: none;
        }
      `}
    `;
