import { css, Theme } from "@emotion/react";

import { bottomMargin, topMargin } from "styles/global/layout";

export const noTokensContainer = css`
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const noTokensTitle = css`
  ${bottomMargin("4/8px")};
`;

export const titleRow = (theme: Theme) => css`
  max-height: ${theme.verticalSpaces.large[12]};
  display: flex;
  flex-wrap: wrap;
`;

export const titleButtonRow = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const title = css`
  flex: 10 0 auto;
`;

export const buttonContainer = css`
  ${topMargin("8/24px")}
`;

export const bodyMintNewTokenButton = css`
  min-width: 240px;
`;

export const list = css`
  width: 100%;
  text-align: center;
`;

export const loadingIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
  ${topMargin("9/32px")}
`;

export const limitReachText = css`
  margin-left: auto;
`;

export const limitReachTextContainer = css`
  display: flex;
  flex-wrap: wrap;
  ${topMargin("3/6px")}
  width: 100%;
`;
