import { css, Theme } from "@emotion/react";

export const lockStateFooter = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${theme.verticalSpaces.large[4]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[4]};
  }
`;
