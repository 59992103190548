import { css, Theme } from "@emotion/react";

export const container = css``;

export const modalContainer = css`
  padding: 0 40px 40px 40px;
`;

export const icon = css`
  width: 40px;
  height: auto;
  margin: 0 auto;
  display: block;
  path {
    fill: black;
  }
`;

export const title = css`
  text-align: center;
  margin: 0 0 8px 0;
`;

export const formTitle = css`
  text-align: center;
`;

export const subtitle = css`
  text-align: center;
  margin: 2px 0 0 0;
`;

export const form = css`
  margin: 32px 0 0 0;
`;

export const metaForm = css`
  margin: 12px 0 0 0;
`;

export const description = css`
  text-align: center;
  margin: 0 0 32px 0;
  line-height: 24px;

  strong {
    font-weight: 600;
  }
`;

export const loading = (theme: Theme) => css`
  margin: 0 auto;
  margin-bottom: ${theme.verticalSpaces.large[5]};
  display: block;
`;

export const emailInputContainer = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[7]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.large[5]};
  }
`;

export const emailInput = css``;

export const button = css`
  border-radius: 99px;
`;

export const metaConnectButton = css`
  border-radius: 99px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: black;
  background-color: white;
`;

export const connectWalletContainer = () => css`
  background-color: transparent;

  margin: 32px 0 12px 0;
  max-width: 576px;
  width: 100%;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
`;

export const metaMaskErrorContainer = () => css`
  background-color: #fce9e9;

  max-width: 576px;
  width: 100%;

  border: 2px solid #b21a1a;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const metaConnect = (theme: Theme) => css`
  display: flex;

  ${theme.mediaQueries.smallOnly} {
    flex-direction: column;
  }
`;

export const rightMetaConnectContainer = (theme: Theme) => css`
  align-items: center;
  display: flex;

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.large[4]};
  }
`;

export const metaMaskErrorText = css`
  color: #b21a1a;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }
`;

export const middleMetaConnectContainer = css`
  flex: 5;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const leftMetaConnectContainer = css`
  flex: 5;
  display: flex;
  align-items: center;
  vertical-align: middle;
`;

export const metamaskImage = css`
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
`;

export const polygonMaticImage = css`
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin: 0 16px 0 0;
`;

export const errorIcon = css`
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 8px 0 0;
  path {
    fill: #b21a1a;
  }
`;
