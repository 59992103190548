import React, { Dispatch, memo, SetStateAction } from "react";

import { NormalizedToken } from "apollo/utils/community";
import {
  Locked as LockedIcon,
  Unlocked as UnlockedIcon,
} from "assets/icons.generated";
import TokenTooltip from "components/TokenTooltip";
import { Maybe } from "utils/types";

import CommunityText from "../_Community/Text";

import * as styles from "./styles";

type UnlockedByPopupProps = {
  unlocked: boolean;
  unlockedByToken: Maybe<NormalizedToken>;
  setShowTokenDetailsModal: Dispatch<SetStateAction<boolean>>;
};

const UnlockedByPopup = ({
  unlocked,
  unlockedByToken,
  setShowTokenDetailsModal,
}: UnlockedByPopupProps) => {
  if (!unlockedByToken) return null;

  return (
    <CommunityText
      color="onPrimaryBackgroundPrimaryColor"
      size="small"
      extraCss={styles.container}
    >
      <span>{unlocked ? <UnlockedIcon /> : <LockedIcon />}</span>
      <span>
        {unlocked ? `Unlocked by your` : `You must own the`}
        <TokenTooltip
          name={unlockedByToken.name ?? ""}
          imageUrl={unlockedByToken.uri}
          supply={unlockedByToken.supply ?? 0}
          setShowTokenDetailsModal={setShowTokenDetailsModal}
          showDetails={unlocked}
        >
          <strong> {unlockedByToken.name} </strong>
        </TokenTooltip>
        {unlocked ? `token ` : `token to view this`}
      </span>
    </CommunityText>
  );
};

export default memo(UnlockedByPopup);
