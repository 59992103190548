import React, { memo } from "react";

import { ReactComponent as EmptyCartIllustration } from "assets/illustrations/empty-cart.svg";
import ButtonLink from "components/ButtonLink";
import Layout from "components/Layout";
import { CommunitySEO } from "components/Layout/SEO";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { COMMUNITY_PAGE_TITLE, COMMUNITY_URL, EXTERNAL_URL } from "config";

import * as styles from "./styles";

const EmptyCart = () => (
  <>
    <CommunitySEO title={COMMUNITY_PAGE_TITLE.CHECKOUT_EMPTY_CART} />

    <Layout css={styles.main}>
      <div css={styles.container}>
        <EmptyCartIllustration />

        <Title size="large">It Looks Like You’re Trying to Check Out</Title>
        <Text as="div" size="medium">
          <p>
            But we’re not sure what you’re trying to purchase. Please go back
            and select the item you’d like to purchase.
          </p>
          <p>
            If you need help,{" "}
            <a href={EXTERNAL_URL.SUPPORT} target="_blank" rel="noreferrer">
              let us know
            </a>
            .
          </p>
        </Text>
        <ButtonLink to={COMMUNITY_URL.home}>Go Back</ButtonLink>
      </div>
    </Layout>
  </>
);

export default memo(EmptyCart);
