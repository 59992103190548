import { css, Theme } from "@emotion/react";

export const title = (theme: Theme) => css`
  text-align: center;

  margin-top: ${theme.verticalSpaces.large[1]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[1]};
  }
`;

export const communityAvatar = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[5]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[5]};
  }
`;
export const bannerImage = (theme: Theme) =>
  css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 260px;
    z-index: 0;
    background-color: ${theme.community.primaryBackground.backgroundColor};

    img {
      object-position: center;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    ${theme.mediaQueries.smallOnly} {
      height: 120px;
    }
  `;
export const header = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
`;

export const image = (theme: Theme) => css`
  border-radius: 50%;
  object-fit: cover;
  margin-top: 76px;
  z-index: 1;
  overflow: hidden;
  width: 200px;
  height: 200px;
  box-shadow: inset 0 0 0 0.5px ${theme.divider},
    0 0 0 4px ${theme.community.pageBackground.backgroundColor};

  ${theme.mediaQueries.smallOnly} {
    width: 120px;
    height: 120px;
    margin-top: -24px;
  }
`;
