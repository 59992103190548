import React, {
  lazy,
  memo,
  useCallback,
  useEffect,
  useState,
  Suspense,
} from "react";

import { useApolloClient, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";

import { TokenStatus, TokenType } from "apollo/graphql.generated";
import useCreatedCommunity from "apollo/hooks/useCreatedCommunity";
import {
  CreatedCommunityBySlugQuery,
  CreatedCommunityBySlugDocument,
  useLaunchCommunityMutation,
} from "apollo/queries";
import { ModalType, modalVar, updateModalVar } from "apollo/reactive";
import Button from "components/Button";
import { AppSEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE } from "config";
import { logError } from "services/logger";

import * as baseStyles from "../styles";

import Blockchain from "./Blockchain/Blockchain";
import CommunityInformation from "./CommunityInformation/CommunityInformation";
import Integrations from "./Integrations";
import LaunchChecklist from "./LaunchChecklist/LaunchChecklist";
import ShareCommunity from "./ShareCommunity/ShareCommunity";
import * as styles from "./styles";

const LinkDiscordServerModal = lazy(() => import("./Models/LinkDiscordServer"));
const UnlinkDiscordServerModal = lazy(
  () => import("./Models/UnlinkDiscordServer")
);

export type CreatorCommunity =
  CreatedCommunityBySlugQuery["createdCommunityBySlug"];

const EditCommunityHome = () => {
  const { communitySlug } = useParams();
  const [doLaunchCommunity, { loading }] = useLaunchCommunityMutation();
  const apolloClient = useApolloClient();
  const { showModal } = useReactiveVar(modalVar);

  const [isDescriptionDone, setDescriptionDone] = useState(false);
  const [isLogoDone, setLogoDone] = useState(false);
  const [isAvatarDone, setAvatarDone] = useState(false);
  const [isCreateTokenDone, setCreateTokenDone] = useState(false);
  const [isAvailableTokenDone, setAvailableTokenDone] = useState(false);
  const [isReadyToLaunch, setReadyToLaunch] = useState(false);
  const [isLaunched, setLaunched] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setReadyToLaunch(
      isDescriptionDone &&
        isLogoDone &&
        isAvatarDone &&
        isCreateTokenDone &&
        isAvailableTokenDone
    );
  }, [
    isDescriptionDone,
    isLogoDone,
    isAvatarDone,
    isCreateTokenDone,
    isAvailableTokenDone,
  ]);

  const { data, loading: queryLoading } = useCreatedCommunity(communitySlug, {
    networkOnly: true,
  });

  const community = data?.createdCommunityBySlug;

  useEffect(() => {
    const tokens = community?.tokens || [];
    const membershipMintedTokens = tokens.filter(
      (token) => token.tokenStatus === TokenStatus.Minted
    );

    const activeOffers = community?.offers || [];
    const membershipTokenActiveOffer = activeOffers.filter((offer) =>
      (offer.tokens || []).some(
        (token) => token.tokenType === TokenType.Membership
      )
    );

    setCreateTokenDone(membershipMintedTokens.length > 0);
    setAvailableTokenDone(membershipTokenActiveOffer.length > 0);
    setLaunched(community?.status === "PUBLIC");
  }, [community]);

  const handleOnClick = useCallback(async () => {
    try {
      setErrorMessage("");
      await doLaunchCommunity({
        variables: {
          launchCommunityId: community?.id as string,
        },
      });

      apolloClient.refetchQueries({
        include: [CreatedCommunityBySlugDocument],
      });

      const dataMap: Record<string, string | boolean> = {
        communitySlug: community?.slug as string,
      };

      updateModalVar({
        showModal: ModalType.COMMUNITY_LAUNCHED,
        data: dataMap,
      });
    } catch (e) {
      setErrorMessage("Error launching the community");
      await logError({
        e,
        message: "[editCommunity] launching community failed",
      });
    }
  }, [community]);

  if (queryLoading)
    return (
      <div css={styles.loadingIcon}>
        <LoadingIcon />
      </div>
    );

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.EDITCOMMUNITY_HOME} />

      <Suspense fallback={""}>
        {showModal === ModalType.LINK_DISCORD_SERVER && (
          <LinkDiscordServerModal />
        )}
        {showModal === ModalType.UNLINK_DISCORD_SERVER && (
          <UnlinkDiscordServerModal />
        )}
      </Suspense>

      <div css={[styles.titleRow, baseStyles.layoutHeader]}>
        <Title size="large">Home</Title>
        {!isLaunched && (
          <Button
            extraCss={styles.launchCommunityButton}
            type="submit"
            size="xl"
            disabled={!isReadyToLaunch}
            loading={loading}
            onClick={handleOnClick}
          >
            Launch Community
          </Button>
        )}
      </div>

      <div css={styles.subTitles}>
        {!isLaunched && (
          <LaunchChecklist
            communitySlug={community?.slug as string}
            isDescriptionDone={isDescriptionDone}
            isLogoDone={isLogoDone}
            isAvatarDone={isAvatarDone}
            isCreateTokenDone={isCreateTokenDone}
            isAvailableTokenDone={isAvailableTokenDone}
          />
        )}
        {isLaunched && (
          <ShareCommunity communitySlug={community?.slug as string} />
        )}
      </div>

      {!isLaunched && (
        <Button
          css={styles.launchCommunityButtonLarge}
          type="submit"
          size="xl"
          disabled={!isReadyToLaunch}
          loading={loading}
          onClick={handleOnClick}
        >
          Launch Community
        </Button>
      )}
      {errorMessage && (
        <Text size="medium" extraCss={styles.errorMessage}>
          {errorMessage}
        </Text>
      )}

      <div css={styles.subTitles}>
        <CommunityInformation
          communityQuery={data}
          onSetDescription={(description) => setDescriptionDone(!!description)}
          onSetAvatar={(avatar) => setAvatarDone(!!avatar)}
          onSetLogo={(logo) => setLogoDone(!!logo)}
        />
      </div>

      {data?.createdCommunityBySlug && (
        <div css={styles.subTitles}>
          <Integrations community={data.createdCommunityBySlug!} />
        </div>
      )}
      <div css={styles.subTitles}>
        <Blockchain
          contractAddress={community?.polygonContract?.contractAddress}
          royaltySplitAddress={community?.royaltySplitAddress}
        />
      </div>
    </>
  );
};

export default memo(EditCommunityHome);
