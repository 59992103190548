import React, { FC, memo, useCallback, useEffect, useState } from "react";

import Button from "components/Button";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import useAppLocation from "hooks/useAppLocation";

import * as styles from "./styles";

export interface Props {
  contestId: string;
  communitySlug: string;
}

const ClaimPage: FC<Props> = ({ communitySlug, contestId }) => {
  const [link, setLink] = useState<string>("");
  const [isTextCopied, setTextCopied] = useState(false);
  const { getContestAbsoluteURL } = useAppLocation();

  useEffect(() => {
    setLink(getContestAbsoluteURL(communitySlug, contestId));
  }, [contestId, communitySlug]);

  const handleCopyLinkClick = useCallback(() => {
    navigator.clipboard.writeText(link);
    setTextCopied(true);
  }, [link]);

  return (
    <div css={styles.container}>
      <Text as="p" size="medium">
        We&apos;ve generated a page where people can claim this token for free.
        Send this link to people you want to be able to claim this token. Note
        that anyone with this link will be able to claim the token, so only send
        it to people you want to receive the token.
      </Text>
      <CardContainer
        border={true}
        background={"primary"}
        additionalCss={styles.linkContainer}
      >
        <Text as="p" size="medium">
          {link}
        </Text>
        <Button css={styles.copyButton} onClick={handleCopyLinkClick}>
          {isTextCopied ? "Copied!" : "Copy Link"}
        </Button>
      </CardContainer>
    </div>
  );
};

export default memo(ClaimPage);
