import React, { FC, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import UnlockedByPopup from "components/UnlockedByPopup/";
import TokenDetailsModal from "containers/Community/TokenDetailsModal";

import * as styles from "./styles";
import { Props } from "./types";

const fadeAnimation = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const BenefitBase: FC<React.PropsWithChildren<Props>> = ({
  header,
  unlocked,
  unlockedByToken,
  communityContractAddress,
  additionalCss,
  children,
}) => {
  const [showTokenDetailsModal, setShowTokenDetailsModal] =
    useState<boolean>(false);

  return (
    <div css={additionalCss}>
      <div>{header}</div>
      <div>{children}</div>
      <div css={styles.lockStateFooter} data-cy="lock-state-footer">
        <UnlockedByPopup
          unlocked={unlocked}
          unlockedByToken={unlockedByToken}
          setShowTokenDetailsModal={setShowTokenDetailsModal}
        />
      </div>
      <AnimatePresence>
        {showTokenDetailsModal && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={fadeAnimation}
          >
            <TokenDetailsModal
              name={unlockedByToken?.name}
              imageUrl={unlockedByToken?.uri}
              supply={unlockedByToken?.supply}
              tokenId={unlockedByToken?.tokenId}
              contractAddress={communityContractAddress}
              setShowTokenDetailsModal={setShowTokenDetailsModal}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BenefitBase;
