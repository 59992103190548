import React, { FC, memo } from "react";

import Button from "components/Button";
import CardContainer from "components/CardContainer";
import CopyToClipboard from "components/CopyToClipboard";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import useAppLocation from "hooks/useAppLocation";

import * as styles from "./styles";

export interface Props {
  communitySlug: string;
}

const ShareCommunity: FC<Props> = ({ communitySlug }) => {
  const { getCommunityURLBySlug } = useAppLocation();

  const url = getCommunityURLBySlug(communitySlug);
  return (
    <>
      <Title size="medium">Share Your Community</Title>
      <Text as="p" size="medium">
        Let your biggest fans know about your community.
      </Text>
      <CardContainer
        border={true}
        background={"primary"}
        additionalCss={styles.container}
      >
        <div>
          <Button
            additionalCss={styles.url}
            onClick={() => (location.href = url)}
          >
            {url}
          </Button>
        </div>
        <CopyToClipboard extraCss={styles.copyButton} toCopy={url} />
      </CardContainer>
    </>
  );
};

export default memo(ShareCommunity);
