import React, { memo, PropsWithChildren } from "react";

import { useReactiveVar } from "@apollo/client";
import { Helmet } from "react-helmet-async";

import { communityVar } from "apollo/reactive";
import {
  SYSTEM_PAGE_TITLE,
  COMMUNITY_PAGE_TITLE,
  HIGHLIGHT_PAGE_TITLE_SIGNATURE,
} from "config";

export type AppSEOProps = {
  title: SYSTEM_PAGE_TITLE;
};

export type CommunitySEOProps = {
  title: COMMUNITY_PAGE_TITLE;
};

export const AppSEO = memo(
  ({ title, children }: PropsWithChildren<AppSEOProps>) => {
    const finalTitleArray = [title, HIGHLIGHT_PAGE_TITLE_SIGNATURE];
    const finalTitle = finalTitleArray.join(" ");

    return (
      <Helmet>
        <title>{finalTitle}</title>
        <meta name="author" content="Highlight" />

        {/* Facebook */}
        <meta property="og:title" content={finalTitle} />
        {/*<meta property="og:description" content={} /> //@todo: To add when we have the required copy */}
        {/*<meta property="og:image" content={} /> //@todo: To add when we have the required copy */}

        {/* Twitter */}
        <meta name="twitter:title" content={finalTitle} />
        {/*<meta name="twitter:description" content={} /> //@todo: To add when we have the required copy */}
        {/*<meta name="twitter:image" content={} /> //@todo: To add when we have the required copy*/}

        {children}
      </Helmet>
    );
  }
);

export const CommunitySEO = memo(
  ({ title, children }: PropsWithChildren<CommunitySEOProps>) => {
    const { name, creatorAvatar, description, imageUrl } =
      useReactiveVar(communityVar);

    const finalTitleArray = [name];
    !!title && finalTitleArray.push(title);
    finalTitleArray.push(HIGHLIGHT_PAGE_TITLE_SIGNATURE);
    const finalTitle = finalTitleArray.join(" ");

    return (
      <Helmet>
        <title>{finalTitle}</title>
        {creatorAvatar?.name && (
          <>
            <meta name="author" content={creatorAvatar.name} />
            <meta
              name="keywords"
              content={`${creatorAvatar.name} community”`}
            />
          </>
        )}
        {description && <meta name="description" content={description} />}

        {/* Facebook */}
        <meta property="og:title" content={finalTitle} />
        <meta property="og:type" content="website" />
        {description && (
          <meta property="og:description" content={description} />
        )}
        {imageUrl && <meta property="og:image" content={imageUrl} />}

        {/* Twitter */}
        <meta name="twitter:title" content={finalTitle} />
        {description && (
          <meta name="twitter:description" content={description} />
        )}
        {imageUrl && (
          <>
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:card" content="summary_large_image" />
          </>
        )}

        {children}
      </Helmet>
    );
  }
);
