import { css } from "@emotion/react";

export const checklist = () => css`
  margin-top: 24px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    margin: 5px;
  }
`;
