import React, { FC, useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";

import { BenefitType } from "apollo/graphql.generated";
import useTokenDetails from "apollo/hooks/useTokenDetails";
import { communityVar, ModalType, updateModalVar } from "apollo/reactive";
import Button from "components/Button";
import CardContainer from "components/CardContainer";
import CommunityAvatarSmall from "components/CommunityAvatarSmall";
import DistributionButton from "components/DistributionButton/DistributionButton";
import Layout from "components/Layout";
import { CommunitySEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import TokenDetails from "components/Token/TokenDetails";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import CommunityButton from "components/_Community/Button";
import {
  COMMUNITY_PAGE_TITLE,
  COMMUNITY_URL,
  MEMBERSHIP_TOKEN_ALT,
} from "config";
import TokenPending from "containers/Community/TokenPending";
import EnterContestForm from "containers/ContestEntry/EnterContestForm";
import NotFound from "containers/Error/NotFound";
import ServerError from "containers/Error/ServerError";
import useCommunityFeatureFlag from "hooks/useCommunityFeatureFlag";
import useSession from "hooks/useSession";
import {
  DistributableToken,
  OfferMethod,
} from "utils/distribution/distributionMethod";

import TokenBenefits from "./TokenBenefits";
import TokenDescription from "./TokenDescription";
import TokenTitle from "./TokenTitle";
import TokenView from "./TokenView";
import * as styles from "./styles";
import { Props as TokenDetailProps } from "./types";

enum TokenPendingState {
  INITIAL,
  PENDING,
  FINISHED,
}

const TokenDetail: FC<TokenDetailProps> = ({
  claimId,
  offer,
  knownTokenId = 1,
  hasUserApplied = false,
  soldOut = false,
}) => {
  const { tokenId = knownTokenId } = useParams();
  const { user, userQuery, refetchUser } = useSession();
  const [wasPending, setWasPending] = useState<TokenPendingState>(
    TokenPendingState.INITIAL
  );
  const navigate = useNavigate();

  const hideMembershipTokenReferences = useCommunityFeatureFlag(
    "hideMembershipTokenReferences"
  );
  const handleOnComunityClick = () => {
    navigate(COMMUNITY_URL.home);
  };
  const { loading: meLoading } = userQuery;

  const communityData = useReactiveVar(communityVar);

  const {
    data: tokenDetailsData,
    loading: tokenDetailsLoading,
    error: tokenDetailsError,
  } = useTokenDetails(tokenId);

  const isTokenPending =
    user?.pendingTokens?.some((token) => token.tokenId === +tokenId) ?? false;

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isTokenPending) {
      interval = setInterval(() => refetchUser(), 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isTokenPending]);

  const token = tokenDetailsData?.community?.token;
  const userOwnsToken = (tokenId: number) =>
    (user?.tokens ?? []).some(
      (token) =>
        token.tokenId === tokenId && token.community.id === communityData.id
    );
  const userHasToken = token ? userOwnsToken(+token.tokenId) : false;
  const isTokenAvailable = token && (token.creatorBalance ?? 0) > 0;

  const distributableToken: DistributableToken | null = token
    ? {
        ...token,
        id: token.tokenId,
        benefits: token.benefits ?? [],
        type: token.tokenType,
        total: token?.supply ?? NaN,
        available: token.creatorBalance!,
        userHasIt: userHasToken,
        userHasPending: false,
      }
    : null;

  const distribution =
    offer && distributableToken
      ? {
          distributionMethod: {
            ...offer,
            kind: "Offer",
          } as OfferMethod,
          tokens: [distributableToken],
        }
      : null;
  const contractAddress =
    tokenDetailsData?.community?.polygonContract?.contractAddress;

  const customTokens =
    token?.benefits?.filter((benefit) => benefit.type === BenefitType.CUSTOM) ??
    [];
  const membershipTokens =
    token?.benefits?.filter((benefit) => benefit.type !== BenefitType.CUSTOM) ??
    [];

  const TokenDetailLabel = (label: string) => {
    return (
      <Title size="xsmall" color="primary">
        {label}
      </Title>
    );
  };

  useEffect(() => {
    if (isTokenPending) {
      updateModalVar({
        showModal: ModalType.MINTING_IN_PROGRESS,
        showView: "check",
      });
      if (wasPending === TokenPendingState.INITIAL) {
        setWasPending(TokenPendingState.PENDING);
      }
    } else if (wasPending === 1 && !isTokenPending) {
      const isCollectibleToken =
        !token?.benefits || token.benefits?.length === 0;
      updateModalVar({
        showModal: ModalType.FINISHED_MINT,
        showView: "check",
        data: {
          communityId: tokenDetailsData?.community?.id ?? "",
          communityName: communityData?.name ?? "",
          communitySlug: communityData?.slug ?? "",
          creatorName: tokenDetailsData?.community?.creatorAvatar.name ?? "",
          tokenName: tokenDetailsData?.community?.token.name ?? "",
          tokenType: isCollectibleToken ? "COLLECTIBLE" : "MEMBERSHIP",
        },
      });
      setWasPending(TokenPendingState.FINISHED);
    }
  }, [isTokenPending, wasPending, token, tokenDetailsData, communityData]);

  if (tokenDetailsError && !tokenDetailsLoading) return <ServerError />;
  if (!token && !tokenDetailsLoading) return <NotFound />;

  const communityBackground = "primary";
  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.TOKEN_DETAIL} />

      <Layout backgroundShow={true} navLayout="tokenDetail">
        {tokenDetailsLoading && (
          <div css={styles.loadingIcon}>
            <LoadingIcon />
          </div>
        )}
        {!tokenDetailsLoading && (
          <div css={styles.layout}>
            <div css={styles.tokenImage}>
              <TokenView
                animationUrl={token?.animationUri}
                imageUrl={token?.uri}
                alt={MEMBERSHIP_TOKEN_ALT}
              />
              {claimId &&
                !soldOut &&
                !userHasToken &&
                !isTokenPending &&
                !hasUserApplied && (
                  <div css={styles.claimTokenButton}>
                    <EnterContestForm
                      contestId={claimId}
                      autoWin={false}
                      showQuestion={false}
                      showTerms={false}
                      buttonText="Claim Token"
                    />
                  </div>
                )}
              {claimId && userHasToken && !isTokenPending && (
                <Button
                  type="submit"
                  loading={false}
                  disabled={true}
                  extraCss={styles.button}
                >
                  Claimed
                </Button>
              )}
              {claimId && !userHasToken && !isTokenPending && soldOut && (
                <Button
                  type="submit"
                  loading={false}
                  disabled={true}
                  extraCss={styles.button}
                >
                  Sold Out
                </Button>
              )}
              {offer &&
                distribution &&
                isTokenAvailable &&
                !userHasToken &&
                !isTokenPending && (
                  <div css={styles.claimTokenButton}>
                    <DistributionButton
                      distribution={distribution}
                      communityName={communityData.name}
                      contractAddress={contractAddress}
                    />
                  </div>
                )}
              {offer && !userHasToken && !isTokenPending && !isTokenAvailable && (
                <div css={styles.claimTokenButton}>
                  <Button disabled extraCss={styles.button}>
                    Sold Out
                  </Button>
                </div>
              )}
              {offer && userHasToken && !isTokenPending && (
                <Button type="submit" loading={false} extraCss={styles.button}>
                  Claimed
                </Button>
              )}
            </div>

            <div css={styles.titleContainer}>
              {isTokenPending && <TokenPending />}
              {!meLoading && (
                <TokenTitle token={token} isMember={userHasToken} />
              )}
              {meLoading && (
                <div css={styles.loadingIcon}>
                  <LoadingIcon />
                </div>
              )}
            </div>
            <div css={styles.creatorContainerLayout}>
              <div>
                {TokenDetailLabel("Created By")}
                <CommunityAvatarSmall
                  overwriteTheme="system"
                  showVerified={false}
                  bold={false}
                  extraCss={styles.creator}
                  imageUrl={communityData?.creatorAvatar?.imageUrl}
                  name={communityData?.creatorAvatar?.name}
                />
              </div>
              <div css={styles.communityButtonContainer}>
                <CommunityButton
                  onClick={handleOnComunityClick}
                  extraCss={styles.transparentButton(communityBackground)}
                >
                  <CardContainer
                    background={communityBackground}
                    extraCss={styles.communityCardContainer}
                  >
                    {TokenDetailLabel("Community")}
                    <CommunityAvatarSmall
                      overwriteTheme="system"
                      showVerified={false}
                      bold={false}
                      extraCss={styles.creator}
                      imageUrl={communityData?.imageUrl}
                      name={communityData?.name}
                    />
                  </CardContainer>
                </CommunityButton>
              </div>
            </div>
            <div css={styles.description}>
              {TokenDetailLabel("Description")}
              <TokenDescription token={token} />
            </div>
            {!hideMembershipTokenReferences && (
              <>
                <div css={styles.detail1}>
                  {TokenDetailLabel("Community Access")}
                  <Text as="p" size="small">
                    People who own this token can access these sections of{" "}
                    {communityData?.name}.
                  </Text>
                  <TokenBenefits benefits={membershipTokens} />
                </div>
                {customTokens?.length > 0 && (
                  <div css={styles.detail2}>
                    {TokenDetailLabel("Additional Benefits")}
                    <TokenBenefits benefits={customTokens} />
                  </div>
                )}
              </>
            )}
            <div
              css={customTokens?.length == 0 ? styles.detail2 : styles.detail3}
            >
              <TokenDetails
                contractAddress={
                  tokenDetailsData?.community?.polygonContract?.contractAddress
                }
                tokenId={token?.tokenId}
              />
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default TokenDetail;
