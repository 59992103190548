import { FC, memo } from "react";

import { Interpolation, Theme } from "@emotion/react";

import Label from "components/Typography/Label";

import * as styles from "./styles";

export type Props = {
  additionalCss?: Interpolation<Theme>;
  supply: number | null;
};

const TokenTag: FC<Props> = ({ additionalCss, supply }) => {
  return (
    <div css={[[styles.container, additionalCss]]}>
      <div css={styles.tokenTagNumber}>
        <Label size="small">EDITION OF {supply} &#183; OWNED BY YOU</Label>
      </div>
    </div>
  );
};

export default memo(TokenTag);
