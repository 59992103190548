import { css, Theme } from "@emotion/react";

import { parseBorderType } from "styles/theme/communityTheme";

import { SystemCardContainerProps } from "../../CardContainer";

import { CommunityCardContainerProps } from "./index";

export const container =
  (
    background: NonNullable<CommunityCardContainerProps["background"]>,
    borderRadius: NonNullable<SystemCardContainerProps["borderRadius"]>,
    padding: NonNullable<SystemCardContainerProps["padding"]>,
    shadow?: CommunityCardContainerProps["shadow"]
  ) =>
  (theme: Theme) =>
    css`
      padding: ${typeof padding === "number"
        ? `${padding}px`
        : theme.cardContainer.padding[padding]};
      border-radius: ${theme.cardContainer.padding[borderRadius]};
      border-radius: ${parseBorderType(
        theme.community.global.containerBorderType
      )};
      background-color: ${theme.community[background].backgroundColor};
      ${shadow && `box-shadow: ${theme.shadows[shadow]};`};
    `;
