import { memo } from "react";

import ButtonLink from "components/ButtonLink";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import * as styles from "./styles";

const BecomeCreator = () => {
  return (
    <CardContainer border={true} additionalCss={styles.container}>
      <Title additionalCss={styles.title} size="small">
        Become a Creator
      </Title>
      <Text additionalCss={styles.text}>
        Create your own community and build a direct and lasting connection with
        your top fans.
      </Text>

      <ButtonLink to="/create-community">Create Your Own Community</ButtonLink>
    </CardContainer>
  );
};

export default memo(BecomeCreator);
