import { css, Theme } from "@emotion/react";

export const container = (loading: boolean) => (theme: Theme) =>
  css`
    transition: opacity 0.8s ${theme.animations.easeOutQuart} 0.4s;
    opacity: ${loading ? 0 : 1};

    button {
      margin-top: 32px;
    }

    ${theme.mediaQueries.smallOnly} {
      button {
        margin-top: 24px;
      }
    }
  `;

export const errorMessage = (theme: Theme) => css`
  text-align: center;
  color: ${theme.colorMap.destructive.default};
  margin-top: 12px;
`;
