import { css, Theme } from "@emotion/react";

export const container = (marginBottom: boolean) => (theme: Theme) =>
  css`
    ${marginBottom && `margin-bottom: ${theme.verticalSpaces.large[9]}`};
  `;

export const description = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[4]};
`;
