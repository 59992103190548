import React, { ComponentProps, memo, ReactNode } from "react";

import { css, Theme } from "@emotion/react";

import { ColorKey } from "../../../../styles/theme";
import Text from "../../../Typography/Text";

const styles = {
  button: (color: ButtonColors) => (theme: Theme) =>
    css`
      background: white;
      box-shadow: ${theme.shadows["sm"]};
      border-radius: 99px;
      padding: 4px 12px;
      color: ${theme.colorMap[color].default};

      span {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: auto;
        color: inherit;
        margin-left: 5px;
      }
    `,
};

type ButtonColors = Extract<ColorKey, "accent" | "destructive">;

type AttachmentFloatingButtonProps = {
  icon: ReactNode;
  color?: ButtonColors;
} & ComponentProps<"button">;

const AttachmentFloatingButton = ({
  icon,
  color = "accent",
  children,
  ...rest
}: AttachmentFloatingButtonProps) => (
  <button css={styles.button(color)} {...rest}>
    {icon}
    <Text as="span" bold size="small">
      {children}
    </Text>
  </button>
);

export default memo(AttachmentFloatingButton);
