import { css, Theme } from "@emotion/react";

export const header = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const headerLink = css`
  flex: 2;
`;

export const productWrapper = (theme: Theme) => css`
  margin: ${theme.verticalSpaces.large[5]} 0 ${theme.verticalSpaces.large[6]};
  padding: ${theme.verticalSpaces.large[6]};
  display: flex;
  align-items: center;
  background: #fff;
  div {
    display: flex;
    flex-direction: column;
  }
  img {
    max-width: 168px;
    max-height: 168px;
    margin-right: ${theme.verticalSpaces.large[6]};
  }
  p {
    margin-top: ${theme.verticalSpaces.large[3]};
  }
`;

export const productWrapper2 = (theme: Theme) => css`
  margin: ${theme.verticalSpaces.large[5]} 0 ${theme.verticalSpaces.large[6]};
  padding: ${theme.verticalSpaces.large[6]} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.community.primaryBackground.backgroundColor};
`;

export const tokenWrapper = css`
  text-align: center;
  svg {
    margin-right: 4px;
  }
`;

export const buttonWrapper = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[4]};
`;
