import { memo } from "react";

import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { EXTERNAL_URL } from "config";

import * as styles from "./styles";

const SeeAnExample = () => {
  return (
    <CardContainer border={true} additionalCss={styles.container}>
      <Title additionalCss={styles.title} size="small">
        Check Out Highlight Club
      </Title>
      <Text additionalCss={styles.text}>
        Curious how communities work? Check out Highlight Club, a community we
        made that you can join and explore for free.
      </Text>

      <a
        css={styles.button}
        href={EXTERNAL_URL.HIGHLIGHT_CLUB}
        target={"_blank"}
        rel="noreferrer"
      >
        Go to Highlight Club
      </a>
    </CardContainer>
  );
};

export default memo(SeeAnExample);
