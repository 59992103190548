import { FC, memo, PropsWithChildren, useCallback } from "react";

import ReactModal from "react-modal";

import { resetModalVar } from "apollo/reactive";
import modalStyles from "components/Modals/ModalBody/styles.module.scss";

import ModalBody from "../ModalBody";

import { Props } from "./types";

// TODO(gates-app): use emotion
// const modalStyles = {
//   modal: "",
//   modalScroll: "",
//   modalLarge: "",
//   modalOverlay: "",
// };

const DEFAULT_REACT_MODAL_PROPS = {
  closeTimeoutMS: 800,
  ariaHideApp: false,
  className: `${modalStyles.modal} ${modalStyles.modalScroll} ${modalStyles.modalLarge}`,
  overlayClassName: modalStyles.modalOverlay,
};

const ReactModalBase: FC<PropsWithChildren<Props>> = ({
  children,
  canClose = true,
  onClose,
  ...reactModalProps
}) => {
  const handleClose = useCallback(() => {
    if (onClose) onClose();
    resetModalVar();
  }, []);

  return (
    <ReactModal
      onRequestClose={canClose ? handleClose : undefined}
      {...DEFAULT_REACT_MODAL_PROPS}
      {...reactModalProps}
    >
      <ModalBody canClose={canClose} handleClose={handleClose}>
        {children}
      </ModalBody>
    </ReactModal>
  );
};
export default memo(ReactModalBase);
