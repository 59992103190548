import { memo, FC, useState, useCallback } from "react";

import { useReactiveVar } from "@apollo/client";
import { AnimatePresence, motion } from "framer-motion";

import { communityVar } from "apollo/reactive";
import { ReactComponent as ArrowDown } from "assets/icons/arrow_down.svg";
import CardContainer from "components/CardContainer";
import CreatorAvatarSmall from "components/CommunityAvatarSmall";
import HelpIconTooltip from "components/HelpIconTooltip";
import LoadingIcon from "components/LoadingIcon";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import {
  HIGHLIGHT_FEE_PERCENTAGE,
  STRIPE_FLAT_FEE_CENTS,
  STRIPE_FEE_PERCENTAGE,
} from "config";
import { Offer } from "containers/Checkout/types";
import { formatCurrency } from "utils/formatting/currency";

import * as styles from "./styles";

const animation = {
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.3,
    },
  },
};

export type Props =
  | { loading: true }
  | {
      loading: false;
      offer: Offer;
      title?: string;
    };

const OrderPreview: FC<Props> = (props) => {
  const { loading } = props;

  const { creatorAvatar } = useReactiveVar(communityVar);
  const [isOpen, setOpen] = useState<boolean>(false);
  const toggleOpen = useCallback(() => setOpen(!isOpen), [isOpen]);

  if (loading) {
    return (
      <CardContainer
        shadow="sm"
        extraCss={[styles.container, styles.containerLoading]}
      >
        <LoadingIcon />
      </CardContainer>
    );
  }

  const {
    offer: { tokens: offerTokens, price: offerPrice, currency },
    title,
  } = props;

  const amount = parseInt(offerPrice);
  const tokens = offerTokens || [];

  const total = Math.ceil(
    (amount + STRIPE_FLAT_FEE_CENTS) / (1 - STRIPE_FEE_PERCENTAGE)
  );
  const highlightFeeInCents = Math.floor(total * HIGHLIGHT_FEE_PERCENTAGE);
  const creatorCutInCents = total - highlightFeeInCents;
  // This represents "processing fees" the difference between the fiat offer amount and the total paid
  const fees = highlightFeeInCents + creatorCutInCents - amount;

  const subtotal = amount;
  const splitAmount = { total, fees, subtotal };

  return (
    <CardContainer shadow="sm" extraCss={styles.container}>
      {title && (
        <Text size="large" bold={true} extraCss={styles.title}>
          {title}
        </Text>
      )}

      <div>
        {tokens.map((token) => (
          <div key={token.tokenId} css={styles.token}>
            {token?.uri && (
              <div css={styles.tokenImage}>
                <img src={token?.uri} alt={token?.name ?? ""} />
              </div>
            )}

            <div css={styles.tokenDetails}>
              <CreatorAvatarSmall
                imageUrl={creatorAvatar?.imageUrl}
                name={creatorAvatar?.name}
              />
              <Title size="small">{token?.name}</Title>
              <Text size="small">Edition of {token?.supply}</Text>
            </div>
          </div>
        ))}
      </div>

      <div css={styles.totals}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              css={styles.totalsDetails}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={animation}
            >
              <Text size="medium" as="ul">
                <li>
                  <span>Subtotal</span>
                  <span>{formatCurrency(splitAmount.subtotal, currency)}</span>
                </li>
                <li>
                  <span>
                    Processing fees
                    <HelpIconTooltip content="This fee helps us process your purchase and operate Highlight." />
                  </span>
                  <span>{formatCurrency(splitAmount.fees, currency)}</span>
                </li>
              </Text>
            </motion.div>
          )}
        </AnimatePresence>

        <div css={styles.totalsPreview}>
          <button onClick={toggleOpen}>
            <Text size="medium" bold={true} as="span">
              Total
            </Text>
            <motion.div animate={{ rotate: isOpen ? 180 : 0 }}>
              <ArrowDown />
            </motion.div>
          </button>
          <Text size="large" bold={true} as="p">
            <small>{currency}</small>{" "}
            {formatCurrency(splitAmount.total, currency)}
          </Text>
        </div>
      </div>
    </CardContainer>
  );
};

export default memo(OrderPreview);
