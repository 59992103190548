import { css, Theme } from "@emotion/react";

import { bottomMargin } from "styles/global/layout";

export const titleRow = () => css`
  width: 100%;
  display: flex;
`;

export const container = (theme: Theme) => css`
  max-width: 100%;
  width: calc(100% - 40px);

  ${theme.mediaQueries.mediumUp} {
    display: grid;
    grid-template-columns: 220px 1fr;
    gap: 20px;
  }

  ${theme.mediaQueries.smallOnly} {
    grid-template-columns: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

/** Sidebar */

export const sidebar = (theme: Theme) => css`
  ${theme.mediaQueries.smallOnly} {
    width: 100%;
    margin-bottom: 50px;
  }
`;

/** Content */

export const layout = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const layoutWrapper = css`
  max-width: 636px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

export const layoutHeader = css`
  ${bottomMargin("10/40px")}
`;

export const smallBlueButton = (theme: Theme) => css`
  display: block;
  font-size: ${theme.text.sizes.small};
  color: ${theme.colorMap.accent.default};
  font-weight: ${theme.text.fontWeights.bold};

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const loadingIconContainerCentered = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

export const textButton =
  (color: "default" | "destructive") => (theme: Theme) =>
    css`
      font-size: ${theme.text.sizes.small};
      font-weight: ${theme.text.fontWeights.bold};

      color: ${color === "destructive"
        ? theme.colorMap.destructive.default
        : theme.text.colors.primary};

      &:disabled {
        color: ${theme.text.colors.secondary};
        pointer-events: none;
      }

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    `;

/*
 * At the moment I've only seen this button used in one place so I created a
 * specific style for it instead of a component, if anyone else needs it then
 * we should create a component.
 */
export const largeWhiteButtonWithGrayBorder = (theme: Theme) => css`
  background: transparent;
  border: 1px solid ${theme.textInput.border.color};
  padding: ${theme.cardContainer.padding.medium};
  color: ${theme.colorMap.accent.default};
  text-decoration: none;
  width: 100%;
  border-radius: ${theme.cardContainer.borderRadius.medium};
  display: block;
  text-align: center;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
