import React, { memo, MouseEventHandler } from "react";

import {
  CheckWhiteSmall as CheckWhiteSmallSVG,
  CloseXBenefit as CloseXBenefitSVG,
  ForwardArrow as ForwardArrowSVG,
} from "assets/icons.generated";
import Button from "components/Button";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import * as styles from "./styles";

export type NewBoxLinkContainerProps = {
  title: string;
  description?: string;
  footer: {
    check: boolean;
    text: string;
  }[];
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const NewBoxLinkContainer = ({
  title = "",
  description,
  footer = [],
  onClick,
}: NewBoxLinkContainerProps) => {
  return (
    <Button onClick={onClick} extraCss={styles.button}>
      <CardContainer shadow="sm" extraCss={styles.container}>
        <div css={styles.title}>
          <Title size="small">{title}</Title>
          <ForwardArrowSVG css={styles.forwardArrowIcon} />
        </div>
        {description && (
          <div css={styles.description}>
            <Text as="p" size="medium">
              {description}
            </Text>
          </div>
        )}
        <Text
          as="ul"
          size="small"
          color="secondary"
          extraCss={styles.benefitList}
        >
          {footer.map((item, index) => (
            <li key={index}>
              {item.check ? (
                <CheckWhiteSmallSVG css={styles.checkIcon} />
              ) : (
                <CloseXBenefitSVG css={styles.xIcon} />
              )}
              {item.text}
            </li>
          ))}
        </Text>
      </CardContainer>
    </Button>
  );
};

export default memo(NewBoxLinkContainer);
