import React, { memo, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import {
  NotificationStatus,
  TokenNotificationType,
  TokenStatus,
} from "apollo/graphql.generated";
import {
  useOnTokenChangeSubscription,
  usePendingTokenDetailsQuery,
} from "apollo/queries";
import { APP_URL } from "config";

import ManageToken from "../ManageToken";

const ManagePendingToken = () => {
  const { communitySlug = "", taskId = "" } = useParams();
  const navigate = useNavigate();
  const { data: tokenDetailsData, loading } = usePendingTokenDetailsQuery({
    variables: {
      slug: communitySlug,
      taskId: taskId,
    },
    fetchPolicy: "network-only",
  });

  const token = tokenDetailsData?.createdCommunityBySlug?.blockchainTaskToken;
  const isMinted =
    token?.tokenStatus === TokenStatus.Minted && !!token?.tokenId;

  const { data: tokenSubscriptionData } = useOnTokenChangeSubscription({
    variables: {
      communityId: tokenDetailsData?.createdCommunityBySlug?.id || "",
      taskId,
      type: TokenNotificationType.MINT,
    },
    // Skip subscription if community id is not fetched yet or token is already minted
    skip: !tokenDetailsData?.createdCommunityBySlug?.id || isMinted,
  });

  const finishedMinting =
    tokenSubscriptionData?.token.status === NotificationStatus.SUCCESS;

  useEffect(() => {
    if (isMinted || finishedMinting) {
      const manageTokenUrl = APP_URL.editCommunity.manageMintedToken
        .replace(":communitySlug", communitySlug)
        // use both query and subscription data in case the user enters the pending page with the token already minted
        .replace(
          ":tokenId",
          token?.tokenId ||
            tokenSubscriptionData?.token.tokenIds[0].toString() ||
            ""
        );
      navigate(manageTokenUrl + "?complete");
    }
  }, [isMinted, finishedMinting, communitySlug, token]);

  return <ManageToken isPending={true} loading={loading} token={token} />;
};

export default memo(ManagePendingToken);
