import React, { FC } from "react";

import CommunityV3 from "containers/CommunityV3";
import Root from "containers/Root";

const CommunitySwitch: FC = () => {
  return (
    <Root theme="community">
      <CommunityV3 />
    </Root>
  );
};

export default CommunitySwitch;
