import React, {
  forwardRef,
  memo,
  ComponentPropsWithRef,
  useState,
  ChangeEventHandler,
} from "react";

import _uniqueId from "lodash/uniqueId";

import checkCircle from "assets/icons/check-within-circle.svg";
import Text from "components/Typography/Text";

import { CheckboxItem } from "../index";

import * as styles from "./styles";

type InputProps = ComponentPropsWithRef<"input">;

export type CheckBoxProps = InputProps & {
  item: CheckboxItem;
  handleOnChange: ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  grouped: boolean;
};

const CheckBoxItem = forwardRef<HTMLInputElement, CheckBoxProps>(
  (
    { item, handleOnChange, checked, grouped, id, ...rest }: CheckBoxProps,
    ref
  ) => {
    const [htmlFor] = useState(_uniqueId("field-"));
    const disabled = !!item.permanentItemText;

    return (
      <label htmlFor={id ?? htmlFor} css={styles.label(grouped, disabled)}>
        <div css={styles.labelTextContainer}>
          {item.icon}
          <span css={styles.labelText}>{item.name}</span>
        </div>
        {item.permanentItemText ? (
          <Text size="small" color="secondary">
            {item.permanentItemText}
          </Text>
        ) : (
          <input
            type="checkbox"
            id={id ?? htmlFor}
            ref={ref}
            checked={checked}
            disabled={disabled}
            onChange={handleOnChange}
            css={styles.input(checkCircle)}
            {...rest}
          />
        )}
      </label>
    );
  }
);

export default memo(CheckBoxItem);
