import React from "react";

import { UpdateActionType } from "apollo/graphql.generated";
import { ModalType, updateModalVar } from "apollo/reactive";
import {
  Pinned as PinnedIcon,
  ThreeDots,
  Trash as DeleteIcon,
} from "assets/icons.generated";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import { useComponentVisible } from "hooks/useComponentVisible";
import { logError } from "services/logger";

import {
  UpdatePostPinMutation,
  useUpdatePostPinMutation,
} from "../query.graphql.generated";

import * as styles from "./styles";
import { EditPostProps } from "./types";

export const Edit = ({ id, communitySlug, pinned }: EditPostProps) => {
  const [updatePostPinMutation] = useUpdatePostPinMutation();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleUpdatePin = async (id: string, type: UpdateActionType) => {
    try {
      const pinResponse = await updatePostPinMutation({
        variables: {
          updatePostPinId: id,
          data: {
            type,
            communitySlug: communitySlug,
          },
        },
      });
      document.dispatchEvent(
        new CustomEvent<UpdatePostPinMutation["updatePostPin"]>("post:pinned", {
          detail: pinResponse.data?.updatePostPin,
        })
      );
      setIsComponentVisible(false);
    } catch (e) {
      await logError({
        e,
        message: "[handleUpdatePostPin] update failed",
      });
    }
  };

  const showConfirmationModal = () => {
    updateModalVar({
      showModal: ModalType.CONFIRM_DELETE_POST,
      data: {
        communitySlug: communitySlug,
        postId: id,
      },
    });
    setIsComponentVisible(false);
  };

  return (
    <>
      <button
        css={styles.editStyles}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <ThreeDots />
      </button>
      <div ref={ref} css={styles.optionsContainer}>
        {isComponentVisible && (
          <CardContainer shadow="lg" additionalCss={styles.optionsCard}>
            <button
              css={styles.optionButton}
              onClick={() =>
                handleUpdatePin(
                  id,
                  pinned ? UpdateActionType.REMOVE : UpdateActionType.ADD
                )
              }
            >
              <PinnedIcon />
              {pinned && <Text size="small">Unpin This Post</Text>}
              {!pinned && <Text size="small">Pin This Post</Text>}
            </button>

            <button
              css={[styles.optionButton, styles.dangerButton]}
              onClick={showConfirmationModal}
            >
              <DeleteIcon />
              <Text size="small">Delete Post</Text>
            </button>
          </CardContainer>
        )}
      </div>
    </>
  );
};
