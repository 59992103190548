import React, { useState, ReactNode } from "react";

import { css } from "@emotion/react";

import Tooltip from "components/Tooltip";
import { TextTag } from "styles/theme/theme.types";

import TokenTooltipContent from "./TokenTooltipContent";

export type TokenTooltipProps = {
  communityName: string;
  tokenName: string;
  imageUrl: string;
  tokenId: number;
  as?: TextTag;
  children: ReactNode;
};

const clickableContentStyle = css`
  cursor: help;
`;

const MembershipTokenTooltip = ({
  communityName,
  tokenName,
  imageUrl,
  tokenId,
  as = "span",
  children,
}: TokenTooltipProps) => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const Tag = as as keyof JSX.IntrinsicElements;

  return (
    <Tooltip
      skin="community"
      visible={visible}
      onClickOutside={hide}
      maxWidth={450}
      padding="large"
      interactive
      content={
        <TokenTooltipContent
          communityName={communityName}
          tokenName={tokenName}
          imageUrl={imageUrl}
          tokenId={tokenId}
        />
      }
    >
      <Tag css={clickableContentStyle} onClick={visible ? hide : show}>
        {children}
      </Tag>
    </Tooltip>
  );
};

export default MembershipTokenTooltip;
