import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[8]};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: ${theme.verticalSpaces.large[8]};
`;

export const buttonContainer = css`
  width: 100%;
`;

export const errorMessage = (theme: Theme) => css`
  text-align: center;
  color: ${theme.colorMap.destructive.default};
`;
