import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const pagination = css`
  ${topMargin("7/20px")};
  display: flex;
  justify-items: center;
  overflow: scroll;
`;

export const noMembers = css`
  ${topMargin("7/20px")};
  display: flex;
  justify-items: center;
  justify-content: center;
`;

export const paginationText = css`
  padding: 6px;
  width: auto;
  white-space: nowrap;
`;

export const pages = css`
  background: transparent;
  width: auto;
  white-space: nowrap;
`;

export const pageText = css`
  color: inherit;
`;

export const page =
  (selected = false) =>
  (theme: Theme) =>
    css`
      width: auto;
      background-color: ${selected
        ? theme.colorMap.accent.tertiary
        : "transparent"};
      padding: 6px 14px;
      color: ${selected
        ? theme.colorMap.accent.primary
        : theme.text.colors.primary};
      border-radius: 10px;
    `;
export const iconPage = css`
  width: auto;
  background: transparent;
  color: black;
  padding: 6px 14px;
  height: 12px;

  &:disabled {
    opacity: 0.25;
  }
`;
