import { Theme } from "@emotion/react";

import { CommunityTheme } from "./communityTheme.types";
import {
  Background,
  Colors,
  Text,
  InvertedText,
  Breakpoint,
  Layout,
  MediaQuery,
  Grid,
} from "./theme.types";

const rootFontSize = 16;

// Layout
const gridColumnWidth = (1 / 23) * 100;

const largeVerticalSpaces = [
  `${2 / rootFontSize}rem`,
  `${4 / rootFontSize}rem`,
  `${6 / rootFontSize}rem`,
  `${8 / rootFontSize}rem`,
  `${12 / rootFontSize}rem`,
  `${16 / rootFontSize}rem`,
  `${20 / rootFontSize}rem`,
  `${24 / rootFontSize}rem`,
  `${32 / rootFontSize}rem`,
  `${40 / rootFontSize}rem`,
  `${48 / rootFontSize}rem`,
  `${56 / rootFontSize}rem`,
  `${64 / rootFontSize}rem`,
  `${80 / rootFontSize}rem`,
  `${96 / rootFontSize}rem`,
  `${120 / rootFontSize}rem`,
  `${160 / rootFontSize}rem`,
];

const smallVerticalSpaces = [
  `${2 / rootFontSize}rem`,
  `${4 / rootFontSize}rem`,
  `${6 / rootFontSize}rem`,
  `${8 / rootFontSize}rem`,
  `${12 / rootFontSize}rem`,
  `${16 / rootFontSize}rem`,
  `${16 / rootFontSize}rem`,
  `${18 / rootFontSize}rem`,
  `${24 / rootFontSize}rem`,
  `${30 / rootFontSize}rem`,
  `${36 / rootFontSize}rem`,
  `${42 / rootFontSize}rem`,
  `${48 / rootFontSize}rem`,
  `${60 / rootFontSize}rem`,
  `${72 / rootFontSize}rem`,
  `${90 / rootFontSize}rem`,
  `${120 / rootFontSize}rem`,
];

export const breakpoints: Breakpoint = {
  sm: 768,
  md: 960,
  xl: 1440,
};

export const grid: Grid = {
  maxWidth: "1012px",
  smallScreen: {
    gap: `16px`,
    margin: `20px`,
  },
  mediumScreen: {
    gap: `30px`,
    margin: `43px`,
  },
  largeScreen: {
    gap: `44px`,
    margin: `43px`,
  },
};

const layout: Layout = {
  largeMaxWidth: grid.maxWidth,
  grid,
  /**
   * The relative size in percentage of the HDS Grid System's column/gutter.
   * 12 columns + gutter in between them = 23 columns.
   */
  gridColumnWidth: `${gridColumnWidth}%`,
  /**
   * Creates the minmax CSS function for each Grid System's column quantity passed, to be used with grid-template-columns,
   * based on the 12 columns + gutters of equal width of the Grid System (https://www.figma.com/file/zp9wEJQpvFD6MJ2vpnUBfb/HDS?node-id=325%3A1659)
   * IMPORTANT: The sum of columnQuantities should be 12, otherwise the layout won't be correct.
   *
   * Example of layout with 2 columns of equal width:
   *   display: grid;
   *   grid-column-gap: ${theme.layout.gridColumnWidth};
   *   grid-template-columns: ${theme.layout.gridColumns(6, 6)};
   *
   * To change to an unequal proportion, just change the following:
   *   grid-template-columns: ${theme.layout.gridColumns(5, 7)};
   * @param columnQuantities number of grid system columns for each css grid column
   */
  gridColumns: (...columnQuantities) =>
    columnQuantities.reduce(
      (prev, curr) => prev + `minmax(0, ${(curr * 2 - 1) * gridColumnWidth}%)`,
      ""
    ),
};

const mediaQueries: MediaQuery = {
  smallOnly: `@media screen and (max-width: ${
    // max-width is inclusive (as "less than or equal"), so we need to subtract 1px
    breakpoints.sm - 1
  }px)`,
  mediumUp: `@media screen and (min-width: ${breakpoints.sm}px)`,
  mediumDown: `@media screen and (max-width: ${breakpoints.md - 1}px)`,
  mediumOnly: `@media screen and (min-width: ${
    breakpoints.sm
  }px) and (max-width: ${breakpoints.md - 1}px)`,
  largeUp: `@media screen and (min-width: ${breakpoints.md}px)`,
};

const background: Background = {
  colors: {
    primary: "#ffffff",
    secondary: "#F8F7F6",
    tertiary: "#E2E1E0",
    quaternary: "#C5C3C1",
  },
  imageUrl: undefined as undefined | string,
};

const invertedBackground: Background = {
  colors: {
    primary: "#0d0d0d",
    secondary: "#2b2b2b",
    tertiary: "#454545",
    quaternary: "#5e5e5e",
  },
};
const colorMap: Colors = {
  utility: {
    divider: "rgba(2, 2, 2, 0.1)",
  },
  vendor: {
    twitterBlue: "#4999E9",
    facebookBlue: "#1877F2",
  },
  accent: {
    primary: "#4442CA",
    secondary: "#9FA3FF",
    tertiary: "#F3F4FF",
    dark: "#222183",
    50: "#EBEBFF",
    100: "#BFBDFF",
    200: "#8D8AFF",
    300: "#5C57FF",
    500: "#2B24FF",
    600: "#0700D6",
    default: "",
  },
  destructive: {
    50: "#FCE9E9",
    100: "#F9D2D2",
    200: "#F2A6A6",
    300: "#EC7979",
    500: "#DF2020",
    600: "#B21A1A",
    default: "",
  },
  affirmative: {
    primary: "#00C855",
    secondary: "#7FFF9D",
    tertiary: "#D5FFDB",
    dark: "#007E33",
    50: "#ECF9EF",
    100: "#D9F2E0",
    200: "#B3E5C1",
    300: "#8CD9A2",
    500: "#40BF63",
    600: "#339950",
    default: "",
  },
};

colorMap.accent.default = colorMap.accent[500];
colorMap.destructive.default = colorMap.destructive[500];
colorMap.affirmative.default = colorMap.affirmative[500];

const text: Text = {
  colors: {
    primary: "#0d0d0d",
    secondary: "hsla(0, 0%, 0%, .6)",
    tertiary: "hsla(0, 0%, 0%, .3)",
    onBackground: {
      primary: "#0d0d0d",
      secondary: "hsla(0, 0%, 0%, .6)",
    },
    onSecondaryBackground: {
      primary: "#0d0d0d",
      secondary: "hsla(0, 0%, 0%, .6)",
    },
  },
  lineHeights: {
    xsmall: "1.5",
    small: "1.5",
    medium: "1.5",
    large: "1.4",
    xlarge: "1.333",
  },
  fontFamily: {
    primary: "Inter, 'Helvetica Neue', sans-serif",
    secondary: "Inter, 'Helvetica Neue', sans-serif",
  },
  sizes: {
    xsmall: "0.75rem",
    small: "0.875rem",
    medium: "1rem",
    large: "1.25rem",
    xlarge: "1.5rem",
  },
  fontWeights: { normal: 400, bold: 600 },
  letterSpacing: {
    xsmall: "normal",
    small: "normal",
    medium: "normal",
    large: "-0.01em",
    xlarge: "-0.01em",
  },
  textTransform: "none",
};

const invertedText: InvertedText = {
  colors: {
    primary: "#ffffff",
    secondary: "hsla(0, 0%, 100%, .6)",
    tertiary: "hsla(0, 0%, 100%, .3)",
  },
};

/**
 * Default community style
 */
const community: CommunityTheme = {
  pageBackground: {
    backgroundColor: "#FFFFFF",
  },
  primaryBackground: {
    backgroundColor: "#F8F7F6",
  },
  secondaryBackground: {
    backgroundColor: "--code-generated--",
  },
  text: {
    onPageBackgroundPrimaryColor: "#000000",
    onPrimaryBackgroundPrimaryColor: "#000000",
    onPrimaryBackgroundSecondaryColor: "--code-generated--",
    fontFamily: "Inter, 'Helvetica Neue', sans-serif",
    fontWeight: "400",
    letterSpacing: "0",
    textTransform: "none",
    boldFontWeight: "600",
  },
  title: {
    fontFamily: "Inter, 'Helvetica Neue', sans-serif",
    fontWeight: "700",
    letterSpacing: "0",
    textTransform: "none",
  },
  label: {
    letterSpacing: "0.06em",
    fontWeight: "600",
  },
  textInput: {
    backgroundColor: "#FFFFFF",
    primaryTextColor: "#000000",
    placeholderTextColor: "rgba(0, 0, 0, 0.45)",
    borderColor: "#FFFFFF",
    borderWidth: "1px",
    borderType: "Rounded",
  },
  button: {
    onPageBackgroundBackgroundColor: "#000000",
    onPageBackgroundTextColor: "#FFFFFF",
    onPageBackgroundBorderColor: "#000000",
    onPageBackgroundBorderWidth: "0",
    onPrimaryBackgroundBackgroundColor: "#000000",
    onPrimaryBackgroundTextColor: "#FFFFFF",
    onPrimaryBackgroundBorderColor: "#000000",
    onPrimaryBackgroundBorderWidth: "0",
    textFontFamily: "Inter, 'Helvetica Neue', sans-serif",
    textFontWeight: "600",
    textLetterSpacing: "0",
    textTextTransform: "none",
    borderType: "Pill",
  },
  global: {
    containerBorderType: "Rounded",
    whiteColor: "#FFFFFF",
    blackColor: "#000000",
    onPageBackgroundTint: "--code-generated--",
    onPrimaryBackgroundTint: "--code-generated--",
  },
};

/**
 * Default site style
 */
export const defaultTheme: Theme = {
  community,
  background,
  invertedBackground,
  overlay: "hsla(0, 0%, 0%, .8)",
  divider: "hsla(0, 0%, 0%, .1)",
  colorMap,
  rootFontSize: `${rootFontSize}px`,
  text,
  invertedText,
  modal: {
    borderRadius: "20px",
    padding: {
      small: "24px",
      medium: "32px 40px 40px 40px",
    },
  },
  cardContainer: {
    borderRadius: {
      medium: "10px",
      large: "20px",
    },
    padding: {
      medium: "16px",
      large: "20px",
    },
  },
  label: {
    fontFamily: "'Degular', Inter, sans-serif",
    letterSpacing: "0.1em",
    fontWeight: "500",
    textTransform: "uppercase",
  },
  title: {
    sizes: {
      xsmall: "1.5rem",
      small: "2rem",
      medium: "2.5rem",
      large: "3.0rem",
      xlarge: "3.5rem",
      smallScreen: {
        xsmall: "1.5rem",
        small: "2rem",
        medium: "2.25rem",
        large: "2.5rem",
        xlarge: "2.5rem",
      },
    },
    lineHeights: {
      xsmall: "1.333",
      small: "1.3125",
      medium: "1.2",
      large: "1.167",
      xlarge: "1.143",
      smallScreen: {
        xsmall: "1.333",
        small: "1.3125",
        medium: "1.2222",
        large: "1.2",
        xlarge: "1.2",
      },
    },
    fontWeight: 500,
    fontFamily: "'Degular Display', Inter, sans-serif",
    letterSpacing: {
      xsmall: "0.02em",
      small: "0",
      medium: "0",
      large: "0",
      xlarge: "0",
    },
    textTransform: "none",
  },
  textInput: {
    backgroundColor: background.colors.primary,
    textColor: text.colors.primary,
    placeholderColor: text.colors.secondary,
    border: {
      color: "rgba(0, 0, 0, 0.1)",
      style: "solid",
      width: "1px",
      radius: "10px",
    },
  },
  buttons: {
    primary: {
      backgroundColor: text.colors.primary,
      textColor: "#FFFFFF",
      border: {
        color: text.colors.primary,
        style: "solid",
        width: "0px",
        radius: "50px",
      },
    },
    secondary: {
      backgroundColor: "white",
      textColor: text.colors.primary,
      border: {
        color: "#E7E7E7",
        style: "solid",
        width: "1px",
        radius: "50px",
      },
    },
    tertiary: {
      backgroundColor: invertedBackground.colors.primary,
      textColor: invertedText.colors.primary,
      border: {
        color: "#000000",
        style: "solid",
        width: "0px",
        radius: "50px",
      },
    },
    text: {
      fontFamily: text.fontFamily.primary,
      fontWeight: text.fontWeights.bold,
      letterSpacing: text.letterSpacing.medium,
      textTransform: text.textTransform,
    },
    sizes: {
      xl: {
        padding: "20px 30px",
        headerPadding: "11px 30px",
        fontSize: "16px",
      },
      large: {
        padding: "16px 30px",
        headerPadding: "7px 30px",
        fontSize: "16px",
      },
      medium: {
        padding: "12px 30px",
        fontSize: "16px",
      },
      small: {
        padding: "8px 16px",
        fontSize: "14px",
      },
    },
  },
  verticalSpaces: {
    large: largeVerticalSpaces,
    medium: largeVerticalSpaces,
    small: smallVerticalSpaces,
  },
  breakpoints,
  layout,
  shadows: {
    xs: "0px 0px 6px rgba(0, 0, 0, 0.03), 0px 0px 4px rgba(0, 0, 0, 0.03), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.05)",
    sm: "0px 1px 12px rgba(0, 0, 0, 0.03), 0px 1px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.05)",
    md: "0px 2px 24px rgba(0, 0, 0, 0.04), 0px 2px 16px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.05)",
    // 400: "0px 4px 48px rgba(0, 0, 0, 0.05), 0px 4px 32px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.05)",
    lg: "0px 8px 96px rgba(0, 0, 0, 0.06), 0px 8px 64px rgba(0, 0, 0, 0.06), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.05)",
    xl: "0px 16px 192px rgba(0, 0, 0, 0.07), 0px 16px 128px rgba(0, 0, 0, 0.07), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.05)",
  },
  dividers: {
    top: "inset 0px 0.5px 0px rgba(0, 0, 0, 0.1)",
    right: "inset -0.5px 0px 0px rgba(0, 0, 0, 0.1)",
    bottom: "inset 0px -0.5px 0px rgba(0, 0, 0, 0.1)",
    left: "inset 0.5px 0px 0px rgba(0, 0, 0, 0.1)",
    vertical:
      "inset 0px 0.5px 0px rgba(0, 0, 0, 0.1), inset 0px -0.5px 0px rgba(0, 0, 0, 0.1)",
    horizontal:
      "inset 0.5px 0px 0px rgba(0, 0, 0, 0.1), inset -0.5px 0px 0px rgba(0, 0, 0, 0.1)",
  },
  mediaQueries,
  animations: {
    easeOutQuart: "cubic-bezier(0.22, 1, 0.36, 1)",
  },
  zLayers: {
    loading: 100,
    tokenDetails: 30,
    modal: 20,
    sidebar: 10,
    navBar: 9,
  },
};

export const borderTypes = {
  straight: "0",
  rounded: "8px",
  pill: "99px",
};
