import { css, Theme } from "@emotion/react";

import { textMediumStyles } from "styles/global/typography";

import { NewAppButtonProps, SystemButtonProps } from ".";

const buttonConfig = (theme: Theme) => ({
  primary: {
    color: theme.buttons.primary.textColor,
    background: theme.buttons.primary.background,
    backgroundColor: theme.buttons.primary.backgroundColor,
    border: theme.buttons.primary.border,
  },
  secondary: {
    color: theme.buttons.secondary.textColor,
    background: theme.buttons.secondary.background,
    backgroundColor: theme.buttons.secondary.backgroundColor,
    border: theme.buttons.secondary.border,
  },
  destructive: {
    background: undefined,
    color: theme.invertedText.colors.primary,
    backgroundColor: theme.colorMap.destructive.default,
    border: theme.buttons.primary.border,
  },
});

const buttonPaddings = {
  small: "6px 16px",
  medium: "12px 30px",
  large: "18px 30px",
  xl: "20px 30px",
};

const buttonSizeMedia = (theme: Theme) => ({
  xl: css`
    ${theme.mediaQueries.smallOnly} {
      padding: ${buttonPaddings["large"]};
    }
  `,
});

export const button =
  (
    buttonColor: NonNullable<SystemButtonProps["color"]>,
    size: NonNullable<SystemButtonProps["size"]>,
    fluid: NonNullable<SystemButtonProps["fluid"]>,
    hasBorder: NonNullable<SystemButtonProps["hasBorder"]>
  ) =>
  (theme: Theme) => {
    const config = buttonConfig(theme)[buttonColor];
    const background = config.background
      ? `background: ${config.background}`
      : `background-color: ${config.backgroundColor}`;
    return css`
      transition-property: transform;
      transition-duration: 0.4s;
      transition-timing-function: ${theme.animations.easeOutQuart};
      position: relative;
      text-align: center;
      width: ${fluid ? `100%` : `auto`};
      ${hasBorder
        ? `border: ${config.border.width} ${config.border.style}
        ${config.border.color}`
        : undefined};
      border-radius: ${config.border.radius};
      ${background};
      color: ${config.color};
      padding: ${buttonPaddings[size]};

      &:active {
        transform: scale(0.97);
      }

      &:disabled {
        opacity: 0.25;
      }

      @media (hover: hover) {
        &:hover {
          transform: scale(1.02);
        }
      }

      ${size === "xl" && buttonSizeMedia(theme)[size]}
    `;
  };

export const loading = css`
  background-color: transparent !important;

  span {
    opacity: 0;
  }
`;

export const buttonText = (theme: Theme) => css`
  ${textMediumStyles(theme)}
  font-family: ${theme.buttons.text.fontFamily};
  font-weight: ${theme.buttons.text.fontWeight};
  letter-spacing: ${theme.buttons.text.letterSpacing};
  text-transform: ${theme.buttons.text.textTransform};

  svg path {
    fill: currentColor;
  }
`;

export const icon = css`
  position: absolute;
  top: calc(50% - 32px / 2);
  left: calc(50% - 32px / 2);
`;

// TODO: Improve typescript types when theme is ready and we have a v3 button
const newAppColorProps = (theme: Theme) => ({
  primary: `
    min-width: 115px;
    font-size: ${theme.text.sizes.medium};
    font-weight: ${theme.text.fontWeights.bold};
    color: ${theme.invertedText.colors.primary};
    background: ${theme.text.colors.primary};
  `,
  secondary: `
    min-width: 94px;
    font-size: ${theme.text.sizes.small};
    font-weight: ${theme.text.fontWeights.normal};
    color: ${theme.text.colors.primary};
    background: ${theme.background.colors.primary};
`,
  destructive: `
    min-width: 94px;
    font-size: ${theme.text.sizes.medium};
    font-weight: ${theme.text.fontWeights.bold};
    color: ${
      "#CC0F0F"
      // theme.colorMap.destructive.primary
    };
    background: ${
      "#FCDDDD;" //theme.colorMap.destructive.tertiary
    };
`,
});

export const newAppButton =
  (color: NewAppButtonProps["color"], loading = false) =>
  (theme: Theme) =>
    css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1em;
        height: 48px;
        cursor: pointer; /* this style is used by labels elements */
        ${newAppColorProps(theme)[color]}
        border-radius: ${theme.buttons.tertiary.border.radius};
        /* TODO: replace by theme when it is ready */
        border: 1px solid rgba(2, 2, 2, 0.1);

        svg {
          margin-right: ${loading ? 0 : "0.5em"};
          ${
            loading &&
            `
          position: absolute;
          top: 50%;
          left: 50%;
          height: 60%;
          transform: translate(-50%, -50%);
        `
          }
        }

        ${
          loading &&
          `
          position: relative;
          background: transparent;
        `
        }
      }
      `;
