import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type CheckoutInitiatedQueryVariables = Types.Exact<{
  items: Array<Types.CheckoutItemsInput> | Types.CheckoutItemsInput;
  sessionKey: Types.Scalars["String"];
}>;

export type CheckoutInitiatedQuery = {
  checkoutInitiated: {
    __typename: "CheckoutInitiateResponse";
    level: string | null;
    stripe: { __typename: "CheckoutStripe"; paymentIntent: string | null };
  };
};

export type CheckoutCompletedMutationVariables = Types.Exact<{
  redirectStatus: Types.Scalars["String"];
  paymentIntentClientSecret: Types.Scalars["String"];
  paymentIntent: Types.Scalars["String"];
}>;

export type CheckoutCompletedMutation = { checkoutCompleted: boolean | null };

export const CheckoutInitiatedDocument = gql`
  query checkoutInitiated(
    $items: [CheckoutItemsInput!]!
    $sessionKey: String!
  ) {
    checkoutInitiated(items: $items, sessionKey: $sessionKey) {
      level
      stripe {
        paymentIntent
      }
    }
  }
`;

/**
 * __useCheckoutInitiatedQuery__
 *
 * To run a query within a React component, call `useCheckoutInitiatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutInitiatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutInitiatedQuery({
 *   variables: {
 *      items: // value for 'items'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useCheckoutInitiatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckoutInitiatedQuery,
    CheckoutInitiatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckoutInitiatedQuery,
    CheckoutInitiatedQueryVariables
  >(CheckoutInitiatedDocument, options);
}
export function useCheckoutInitiatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckoutInitiatedQuery,
    CheckoutInitiatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckoutInitiatedQuery,
    CheckoutInitiatedQueryVariables
  >(CheckoutInitiatedDocument, options);
}
export type CheckoutInitiatedQueryHookResult = ReturnType<
  typeof useCheckoutInitiatedQuery
>;
export type CheckoutInitiatedLazyQueryHookResult = ReturnType<
  typeof useCheckoutInitiatedLazyQuery
>;
export type CheckoutInitiatedQueryResult = Apollo.QueryResult<
  CheckoutInitiatedQuery,
  CheckoutInitiatedQueryVariables
>;
export const CheckoutCompletedDocument = gql`
  mutation CheckoutCompleted(
    $redirectStatus: String!
    $paymentIntentClientSecret: String!
    $paymentIntent: String!
  ) {
    checkoutCompleted(
      redirectStatus: $redirectStatus
      paymentIntentClientSecret: $paymentIntentClientSecret
      paymentIntent: $paymentIntent
    )
  }
`;
export type CheckoutCompletedMutationFn = Apollo.MutationFunction<
  CheckoutCompletedMutation,
  CheckoutCompletedMutationVariables
>;

/**
 * __useCheckoutCompletedMutation__
 *
 * To run a mutation, you first call `useCheckoutCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCompletedMutation, { data, loading, error }] = useCheckoutCompletedMutation({
 *   variables: {
 *      redirectStatus: // value for 'redirectStatus'
 *      paymentIntentClientSecret: // value for 'paymentIntentClientSecret'
 *      paymentIntent: // value for 'paymentIntent'
 *   },
 * });
 */
export function useCheckoutCompletedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckoutCompletedMutation,
    CheckoutCompletedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckoutCompletedMutation,
    CheckoutCompletedMutationVariables
  >(CheckoutCompletedDocument, options);
}
export type CheckoutCompletedMutationHookResult = ReturnType<
  typeof useCheckoutCompletedMutation
>;
export type CheckoutCompletedMutationResult =
  Apollo.MutationResult<CheckoutCompletedMutation>;
export type CheckoutCompletedMutationOptions = Apollo.BaseMutationOptions<
  CheckoutCompletedMutation,
  CheckoutCompletedMutationVariables
>;
