import { FC, memo } from "react";

import { RegularRoundedClose as CloseIcon } from "assets/icons.generated";

import styles from "./styles.module.scss";
import { Props } from "./types";

// TODO(gates-app): use emotion
// const styles = {
//   button: "",
// };

const ButtonModalClose: FC<Props> = ({ className = "", ...rest }) => (
  <button className={`${className} ${styles.button}`} {...rest}>
    <CloseIcon />
  </button>
);

export default memo(ButtonModalClose);
