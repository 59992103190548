import React, { FC, PropsWithChildren } from "react";

import ReactModal from "react-modal";

import ButtonModalClose from "components/ButtonModalClose";

type TokenListModalProps = {
  onClose: () => void;
} & ReactModal.Props;

export const TokenListModal: FC<PropsWithChildren<TokenListModalProps>> = ({
  children,
  onClose,
  ...reactModalProps
}) => {
  return (
    <ReactModal onRequestClose={onClose} {...reactModalProps}>
      <ModalBody handleClose={onClose}>{children}</ModalBody>
    </ReactModal>
  );
};

type ModalBodyProps = {
  handleClose?: () => void;
};

const ModalBody: React.FC<PropsWithChildren<ModalBodyProps>> = ({
  children,
  handleClose,
}) => {
  return (
    <div>
      <ButtonModalClose onClick={handleClose} />
      {children}
    </div>
  );
};
