import React, { ImgHTMLAttributes } from "react";

import useFeatureFlag from "hooks/useFeatureFlag";

import { FEATURE_FLAG } from "../../config";

type ResizedImageProps = {
  width: number;
  height?: number;
} & ImgHTMLAttributes<HTMLImageElement>;

export const ResizedImage: React.FC<ResizedImageProps> = ({
  src,
  width,
  height,
  ...props
}) => {
  const enableResizedImages = useFeatureFlag(FEATURE_FLAG.RESIZED_IMAGES);
  if (!src || !enableResizedImages || !/^http/.test(src)) {
    return <img {...props} src={src} />;
  }

  const w = width;
  const h = height ?? width;
  const dimension1x = `${w}x${h}`;
  const dimension2x = `${w * 2}x${h * 2}`;
  const srcset = `${src}?d=${dimension1x}, ${src}?d=${dimension2x} 2x`;
  return <img {...props} srcSet={srcset} />;
};
