import { css, Theme } from "@emotion/react";

export const inputContainer = css``;

export const input = (traveled: number) => (theme: Theme) =>
  css`
    transition: opacity 0.2s;
    appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background: linear-gradient(
      to right,
      black 0%,
      black ${traveled}%,
      ${theme.background.colors.tertiary} ${traveled}%,
      ${theme.background.colors.tertiary} 100%
    );
    outline: none;

    &::-webkit-slider-thumb {
      transition: transform 0.6s ${theme.animations.easeOutQuart};
      appearance: none;
      width: 32px;
      height: 32px;
      background: white;
      border: 1px solid ${theme.background.colors.tertiary};
      cursor: pointer;
      border-radius: 100%;

      @media (hover: hover) {
        &:hover {
          transform: scale3d(1.1, 1.1, 1.1);
        }
      }
    }

    &::-moz-range-thumb {
      transition: transform 0.6s ${theme.animations.easeOutQuart};
      width: 32px;
      height: 32px;
      background: white;
      border: 1px solid ${theme.background.colors.tertiary};
      cursor: pointer;
      border-radius: 100%;

      @media (hover: hover) {
        &:hover {
          transform: scale3d(1.1, 1.1, 1.1);
        }
      }
    }
  `;

export const selectedValue = css`
  flex-shrink: 0;
  min-width: 70px;
  text-align: right;
  font-variant-numeric: tabular-nums;
`;
