import React, { forwardRef, memo } from "react";

import { ReactComponent as NoImage } from "assets/icons/no-image.svg";
import Button from "components/Button";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import useLogic from "./logic";
import * as styles from "./styles";
import { Props } from "./types";

const ImageUpload = forwardRef<HTMLInputElement, Props>(
  (
    {
      error,
      label = "",
      labelType = "text",
      primaryDescription = "",
      secondaryDescription = "",
      dragActiveDescription = "",
      onFileAccept,
      onFileReject,
      onRemove,
      hasRemove = true,
      imageUrl = "",
      minWidth,
      minHeight,
      maxSize,
      acceptedExtensions,
      imageBorder = "round",
      extraCss,
      ...rest
    }: Props,
    ref
  ) => {
    const {
      dropZoneState: { getRootProps, getInputProps, isDragActive },
      handleChange,
      handleRemove,
    } = useLogic({
      imageUrl,
      onFileAccept,
      onFileReject,
      onRemove,
      minWidth,
      minHeight,
      maxSize,
      acceptedExtensions,
    });

    const renderTitle = () => {
      switch (labelType) {
        case "text":
          return (
            <Text size="medium" bold extraCss={styles.inputLabel}>
              {label}
            </Text>
          );
        case "title":
          return (
            <Title size="xsmall" extraCss={styles.inputLabel}>
              {label}
            </Title>
          );
      }
    };

    return (
      <div css={extraCss} {...getRootProps()}>
        {renderTitle()}

        <div
          className={rest.className}
          css={styles.imageLabel(error, imageUrl)}
        >
          {!imageUrl && (
            <>
              <div>
                <div css={styles.noImage(imageBorder)}>
                  <NoImage css={styles.noImageIcon} />
                </div>
              </div>
              <div>
                <Text as="p" bold={true}>
                  {!isDragActive ? primaryDescription : dragActiveDescription}
                </Text>
                <Text as="p" extraCss={styles.secondaryText} color="secondary">
                  {secondaryDescription}
                </Text>
              </div>
            </>
          )}
          {imageUrl && (
            <>
              <div>
                <img
                  css={styles.image(imageBorder)}
                  src={imageUrl}
                  alt="Image"
                />
              </div>
              <div css={styles.rightButtons}>
                {hasRemove && (
                  <Button css={styles.removeButton} onClick={handleRemove}>
                    Remove
                  </Button>
                )}
                <Button css={styles.changeButton} onClick={handleChange}>
                  Change
                </Button>
              </div>
            </>
          )}
        </div>
        <input ref={ref} css={styles.input} {...rest} {...getInputProps()} />
        {error && <p css={styles.error}>{error}</p>}
      </div>
    );
  }
);

export default memo(ImageUpload);
