import { css, Theme } from "@emotion/react";

import { ImageBorder } from "./index";

export const imagePreviewContainer = css`
  display: flex;
`;

export const imageContainer = (theme: Theme) =>
  css`
    padding: 16px;

    background: ${theme.textInput.backgroundColor};

    border-width: ${theme.textInput.border.width};
    border-style: ${theme.textInput.border.style};
    border-color: ${theme.textInput.border.color};
    border-radius: 10px;
  `;

export const image = (imageBorder: ImageBorder) => css`
  width: 144px;
  height: 144px;
  border-radius: ${imageBorder === "round" ? "50%" : "10px"};
  object-fit: contain;
`;
