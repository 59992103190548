import { useDropzone } from "react-dropzone";

const useLogic = ({
  sourceUrl,
  onFileAccept,
  onRemove,
}: {
  sourceUrl?: string;
  onFileAccept: (acceptedFile: File) => Promise<void>;
  onRemove?: () => Promise<void>;
}) => {
  const handleUpload = async ([file]: File[]) => onFileAccept(file);

  const dropZoneState = useDropzone({
    onDrop: handleUpload,
    noClick: !!sourceUrl,
    noKeyboard: !!sourceUrl,
    maxFiles: 1,
    noDrag: !!sourceUrl,
  });

  const handleChange = () => dropZoneState.open();
  const handleRemove = async () => onRemove && onRemove();

  return {
    dropZoneState,
    handleChange,
    handleRemove,
  };
};

export type UseLogic = ReturnType<typeof useLogic>;
export default useLogic;
