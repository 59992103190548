import React, { useState } from "react";

import { isApolloError } from "@apollo/client";

import { TokenType } from "apollo/graphql.generated";
import { MintNewTokenMutation, useMintNewTokenMutation } from "apollo/queries";
import Button from "components/Button";
import ErrorLinkContainer from "components/ErrorLinkContainer";
import Layout from "components/Layout";
import LoadingIcon from "components/LoadingIcon";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";
import { logError } from "services/logger";
import {
  ApolloServerError,
  DefaultApolloServerError,
  parseApolloClientError,
} from "utils/errors/apolloErrors";

import AddedBenefit from "../../NewManageToken/ManageToken/Benefits/AddedBenefit";
import NewTokenPreview from "../../Shared/NewTokenPreview";
import TokenField from "../../Shared/TokenField";
import VideoPreview from "../../Shared/VideoPreview";
import ImageCardPreview from "../ImageCardPreview";
import { MintForm } from "../types";
import { PAGE_TITLE_MAP } from "../utils";

import * as styles from "./styles";

export type ReviewTokenProps = {
  onTokenMintSuccess(res: MintNewTokenMutation): void;
  communityId: string;
  form: MintForm;
};

const ReviewToken = ({
  onTokenMintSuccess,
  form,
  communityId,
}: ReviewTokenProps) => {
  const [doMintNewTokenMutation, { loading: isLoading }] =
    useMintNewTokenMutation();
  const [error, setError] = useState<ApolloServerError | null>(null);
  const enableVideoAsset = useFeatureFlag(
    FEATURE_FLAG.ENABLE_VIDEO_ASSET_ON_TOKEN
  );

  const enableMintingNonTransferable = useFeatureFlag(
    FEATURE_FLAG.ENABLE_MINTING_NON_TRANSFERABLE
  );

  const nonTransferable = enableMintingNonTransferable && form.nonTransferable;

  const mintNewTokens = async () => {
    try {
      if (!form.tokenType) {
        throw new Error("token type is null");
      }
      const result = await doMintNewTokenMutation({
        variables: {
          asset: form.tokenFile,
          animation: form.animationFile,
          communityId: communityId,
          data: {
            name: form.tokenName,
            description: form.tokenDescription,
            type: form.tokenType,
            quantity: form.tokenCount,
            benefits: [...form.benefits, ...form.customBenefits],
            nonTransferable,
          },
        },
      });
      if (!result.data || result.errors) {
        throw result?.errors ?? new Error("failed to mint new tokens");
      }
      onTokenMintSuccess(result.data);
    } catch (error) {
      const message = "minting new tokens failed";
      if (error instanceof Error && isApolloError(error)) {
        setError(parseApolloClientError(error));
      } else {
        setError(DefaultApolloServerError);
      }
      await logError({
        error,
        message: `[handleMintNewTokens] ${message}`,
      });
    }
  };

  const SubmitComponent = () => {
    return (
      <div css={styles.submitContainer}>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <>
            {error ? (
              <ErrorLinkContainer onClose={() => setError(null)}>
                <div css={styles.errorContainer}>
                  <Text bold>{error.type}</Text>
                  <Text>{error.message}</Text>
                </div>
              </ErrorLinkContainer>
            ) : null}
            <Button
              onClick={() => {
                mintNewTokens();
              }}
            >
              Mint Token
            </Button>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <Title size="large" extraCss={styles.title}>
        Review and Mint
      </Title>
      <Layout
        css={styles.layout}
        backgroundShow={true}
        backgroundColor="primary"
        showHeader={false}
        showFooter={false}
      >
        <div css={styles.tokenDescriptionCol}>
          <TokenField title="Token Type" spaced>
            <Text>{form.tokenType ? PAGE_TITLE_MAP[form.tokenType] : ""}</Text>
          </TokenField>
          <TokenField title="Token Name" spaced extraCss={styles.tokenField}>
            <Text>{form.tokenName}</Text>
          </TokenField>
          <TokenField
            title="Token Description"
            spaced
            extraCss={styles.tokenField}
          >
            <Text>{form.tokenDescription}</Text>
          </TokenField>
          {form.tokenType !== TokenType.Collectible && (
            <TokenField
              title="Community Access"
              spaced
              extraCss={styles.tokenField}
            >
              <Text>
                People who own this token can access these sections of your
                community.
              </Text>
              <div css={styles.benefitsContainer}>
                {form.benefits.map((benefit, index) => (
                  <AddedBenefit key={index} type={benefit.type} />
                ))}
              </div>
            </TokenField>
          )}
          {form.tokenType !== TokenType.Collectible && (
            <TokenField
              title="Additional Benefits"
              extraCss={styles.tokenField}
            >
              {form.customBenefits.length > 0 ? (
                <div css={styles.benefitsContainer}>
                  {form.customBenefits.map((benefit, index) => (
                    <AddedBenefit
                      key={index}
                      type={benefit.type}
                      customBenefitName={benefit.customName}
                    />
                  ))}
                </div>
              ) : (
                <Text>None</Text>
              )}
            </TokenField>
          )}
          <TokenField title="Number of Tokens" extraCss={styles.tokenField}>
            <Text>{form.tokenCount.toString()}</Text>
          </TokenField>
          {nonTransferable && (
            <TokenField title="Advanced Options" extraCss={styles.tokenField}>
              <Text>Non-transferable</Text>
            </TokenField>
          )}
        </div>
        <div css={styles.tokenArtworkCol}>
          <TokenField
            title={enableVideoAsset ? "Token Image or Video" : "Token Artwork"}
            spaced
          >
            {form.animationFileUri ? (
              <VideoPreview sourceUri={form.animationFileUri} />
            ) : (
              <NewTokenPreview imageUri={form.tokenFileUri} />
            )}
          </TokenField>
          {form.animationFileUri && (
            <TokenField
              title="Preview Image"
              spaced
              extraCss={styles.tokenField}
            >
              <ImageCardPreview
                imageUrl={form.tokenFileUri}
                imageBorder="square"
              />
            </TokenField>
          )}
        </div>
        <div css={styles.submitCol}>
          <SubmitComponent />
          <Text size="small" extraCss={styles.submitWarning}>
            Double check all the information above. Once you mint a token, you
            will not be able to edit its attributes or artwork.
          </Text>
        </div>
      </Layout>
    </div>
  );
};

export default ReviewToken;
