import React, { FC } from "react";

import { Link } from "react-router-dom";

import { ArrowRightDefault as ArrowRight } from "../../assets/icons.generated";
import Text from "../Typography/Text";

import * as styles from "./styles";

export type Token = {
  id: string;
  image: string;
  type: string;
  name: string;
  link: string;
  supply: number;
  remaining: number;
  status?: string;
};

export type TokenListProps = {
  tokens: Token[];
  divider: boolean;
  alternateShade: boolean;
  onClick?: (id: string) => void;
};

const TokenList: FC<TokenListProps> = ({
  tokens,
  divider,
  alternateShade,
  onClick,
}) => {
  return (
    <div css={styles.reactionList(divider, alternateShade)}>
      {tokens.map((token) => (
        <TokenListItem key={token.id} token={token} onClick={onClick} />
      ))}
    </div>
  );
};

export type TokenListItemProp = {
  token: Token;
  onClick?: (id: string) => void;
};

const TokenListItem: FC<TokenListItemProp> = ({ token, onClick }) => {
  return (
    <>
      <Link
        onClick={onClick ? () => onClick(token.id) : undefined}
        to={token.link}
        css={styles.link}
      >
        <div css={styles.tokenInfo}>
          <img css={styles.tokenImage} src={token.image} alt="Image" />
          <div css={styles.tokenDescription}>
            <Text bold size="small" uppercase>
              {token.type} token
            </Text>
            <Text bold size="large">
              {token.name}
            </Text>
            <Text>
              {token.remaining} / {token.supply} remaining
            </Text>
          </div>
        </div>
        <div css={styles.tokenStatus}>
          {token.status && <Text color="secondary">{token.status}</Text>}
          <ArrowRight css={styles.arrow} />
        </div>
      </Link>
    </>
  );
};

export default TokenList;
