import { css, Theme } from "@emotion/react";

import {
  base as baseButton,
  bySize as baseButtonBySize,
} from "components/_Community/Button/styles";

/* Nav Styles */

export const navContainer = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 32px;

  ${theme.mediaQueries.smallOnly} {
    position: relative;
    left: -20px;
    width: 100vw;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
    padding: 0 20px;
    gap: 20px;

    &::before,
    &::after {
      content: ""; /* Insert pseudo-element */
      margin: auto; /* Make it push flex items to the center */
    }

    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      display: none;
    }
  }
`;

/* Link Styles */
export const navLinkBase = baseButton;
export const navLinkSize = baseButtonBySize("medium", false);

export const navLink = (active: boolean) => (theme: Theme) =>
  // These navLinks have a custom height not used other places
  css`
    width: unset;
    border: none;
    height: 40px;
    padding: 0;
    align-items: center;
    display: flex;

    ${theme.mediaQueries.smallOnly} {
      flex-shrink: 0 !important;
    }

    ${active &&
    css`
      padding: 0 32px;
      background-color: ${theme.community.button
        .onPageBackgroundBackgroundColor};
      color: ${theme.community.button.onPageBackgroundTextColor};
      border-color: ${theme.community.button.onPageBackgroundBorderColor};
      border-width: ${theme.community.button.onPageBackgroundBorderWidth};

      ${theme.mediaQueries.smallOnly} {
        padding: 0 24px;
      }
    `}

    ${!active &&
    css`
      color: ${theme.community.text.onPageBackgroundPrimaryColor};
    `}
  `;
