import type {
  KeyboardEvent as ReactKeyboardEvent,
  TouchEvent as ReactTouchEvent,
} from "react";
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState,
  ComponentPropsWithRef,
} from "react";

import { Interpolation, Theme } from "@emotion/react";
import _uniqueId from "lodash/uniqueId";

import CommunityLabel from "../Label";
import CommunityText from "../Text";

import * as styles from "./styles";

type TextAreaProps = ComponentPropsWithRef<"textarea">;

export type Props = TextAreaProps & {
  label?: string;
  error?: string;
  initialLength?: number;
  additionalCss?: Interpolation<Theme>;
};

const CommunityTextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      error,
      id,
      label = "",
      rows = 5,
      initialLength = 0,
      additionalCss,
      ...rest
    }: Props,
    ref
  ) => {
    const htmlFor = _uniqueId("field-");
    const [lengthCount, setLengthCount] = useState<number>(0);

    useEffect(() => {
      setLengthCount(initialLength);
    }, [initialLength]);

    const handleLengthCount = useCallback(
      (
        e:
          | ReactKeyboardEvent<HTMLTextAreaElement>
          | ReactTouchEvent<HTMLTextAreaElement>
      ) => {
        setLengthCount(e.currentTarget.value.length);
      },
      []
    );

    const { maxLength } = rest;

    if (maxLength) {
      rest.onKeyUp = handleLengthCount;
    }

    return (
      <div css={[styles.fieldGroup, additionalCss]}>
        <label htmlFor={id ?? htmlFor} css={styles.label}>
          <CommunityLabel size="small" color="onPageBackgroundPrimaryColor">
            {label}
          </CommunityLabel>
        </label>
        <div css={styles.inputWrapper}>
          <textarea
            ref={ref}
            id={id ?? htmlFor}
            css={styles.input(error)}
            rows={rows}
            {...rest}
          />
          {maxLength && (
            <p css={styles.counter}>
              {lengthCount}/{maxLength}
            </p>
          )}
        </div>
        {error && (
          <CommunityText extraCss={styles.error}>{error}</CommunityText>
        )}
      </div>
    );
  }
);

export default memo(CommunityTextArea);
