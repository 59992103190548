import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type StartLoginWithSignedMessageQueryVariables = Types.Exact<{
  publicAddress: Types.Scalars["String"];
}>;

export type StartLoginWithSignedMessageQuery = {
  startLoginWithSignedMessage: {
    __typename: "Challenge";
    messageToSign: string;
  };
};

export type CompleteLoginWithSignedMessageMutationVariables = Types.Exact<{
  publicAddress: Types.Scalars["String"];
  signature: Types.Scalars["String"];
}>;

export type CompleteLoginWithSignedMessageMutation = {
  completeLoginWithSignedMessage: { __typename: "Auth"; jwt: string };
};

export type StartCreateUserWithSignedMessageQueryVariables = Types.Exact<{
  publicAddress: Types.Scalars["String"];
}>;

export type StartCreateUserWithSignedMessageQuery = {
  startCreateUserWithSignedMessage: {
    __typename: "Challenge";
    messageToSign: string;
  };
};

export type CompleteCreateUserWithSignedMessageMutationVariables = Types.Exact<{
  publicAddress: Types.Scalars["String"];
  signature: Types.Scalars["String"];
  email: Types.Scalars["String"];
}>;

export type CompleteCreateUserWithSignedMessageMutation = {
  completeCreateUserWithSignedMessage: { __typename: "Auth"; jwt: string };
};

export type CreateUserWithMagicLinkMutationVariables = Types.Exact<{
  didToken: Types.Scalars["String"];
}>;

export type CreateUserWithMagicLinkMutation = {
  createUserWithMagicLink: { __typename: "Auth"; jwt: string };
};

export type LogInWithMagicLinkMutationVariables = Types.Exact<{
  didToken: Types.Scalars["String"];
}>;

export type LogInWithMagicLinkMutation = {
  logInWithMagicLink: { __typename: "Auth"; jwt: string };
};

export type StartMagicLinkFlowQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
}>;

export type StartMagicLinkFlowQuery = {
  startMagicLinkFlow: {
    __typename: "AllowedMethods";
    allowedMethods: Array<string>;
  };
};

export const StartLoginWithSignedMessageDocument = gql`
  query StartLoginWithSignedMessage($publicAddress: String!) {
    startLoginWithSignedMessage(publicAddress: $publicAddress) {
      messageToSign
    }
  }
`;

/**
 * __useStartLoginWithSignedMessageQuery__
 *
 * To run a query within a React component, call `useStartLoginWithSignedMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartLoginWithSignedMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartLoginWithSignedMessageQuery({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *   },
 * });
 */
export function useStartLoginWithSignedMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    StartLoginWithSignedMessageQuery,
    StartLoginWithSignedMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StartLoginWithSignedMessageQuery,
    StartLoginWithSignedMessageQueryVariables
  >(StartLoginWithSignedMessageDocument, options);
}
export function useStartLoginWithSignedMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StartLoginWithSignedMessageQuery,
    StartLoginWithSignedMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StartLoginWithSignedMessageQuery,
    StartLoginWithSignedMessageQueryVariables
  >(StartLoginWithSignedMessageDocument, options);
}
export type StartLoginWithSignedMessageQueryHookResult = ReturnType<
  typeof useStartLoginWithSignedMessageQuery
>;
export type StartLoginWithSignedMessageLazyQueryHookResult = ReturnType<
  typeof useStartLoginWithSignedMessageLazyQuery
>;
export type StartLoginWithSignedMessageQueryResult = Apollo.QueryResult<
  StartLoginWithSignedMessageQuery,
  StartLoginWithSignedMessageQueryVariables
>;
export const CompleteLoginWithSignedMessageDocument = gql`
  mutation CompleteLoginWithSignedMessage(
    $publicAddress: String!
    $signature: String!
  ) {
    completeLoginWithSignedMessage(
      publicAddress: $publicAddress
      signature: $signature
    ) {
      jwt
    }
  }
`;
export type CompleteLoginWithSignedMessageMutationFn = Apollo.MutationFunction<
  CompleteLoginWithSignedMessageMutation,
  CompleteLoginWithSignedMessageMutationVariables
>;

/**
 * __useCompleteLoginWithSignedMessageMutation__
 *
 * To run a mutation, you first call `useCompleteLoginWithSignedMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteLoginWithSignedMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeLoginWithSignedMessageMutation, { data, loading, error }] = useCompleteLoginWithSignedMessageMutation({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useCompleteLoginWithSignedMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteLoginWithSignedMessageMutation,
    CompleteLoginWithSignedMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteLoginWithSignedMessageMutation,
    CompleteLoginWithSignedMessageMutationVariables
  >(CompleteLoginWithSignedMessageDocument, options);
}
export type CompleteLoginWithSignedMessageMutationHookResult = ReturnType<
  typeof useCompleteLoginWithSignedMessageMutation
>;
export type CompleteLoginWithSignedMessageMutationResult =
  Apollo.MutationResult<CompleteLoginWithSignedMessageMutation>;
export type CompleteLoginWithSignedMessageMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteLoginWithSignedMessageMutation,
    CompleteLoginWithSignedMessageMutationVariables
  >;
export const StartCreateUserWithSignedMessageDocument = gql`
  query StartCreateUserWithSignedMessage($publicAddress: String!) {
    startCreateUserWithSignedMessage(publicAddress: $publicAddress) {
      messageToSign
    }
  }
`;

/**
 * __useStartCreateUserWithSignedMessageQuery__
 *
 * To run a query within a React component, call `useStartCreateUserWithSignedMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartCreateUserWithSignedMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartCreateUserWithSignedMessageQuery({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *   },
 * });
 */
export function useStartCreateUserWithSignedMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    StartCreateUserWithSignedMessageQuery,
    StartCreateUserWithSignedMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StartCreateUserWithSignedMessageQuery,
    StartCreateUserWithSignedMessageQueryVariables
  >(StartCreateUserWithSignedMessageDocument, options);
}
export function useStartCreateUserWithSignedMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StartCreateUserWithSignedMessageQuery,
    StartCreateUserWithSignedMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StartCreateUserWithSignedMessageQuery,
    StartCreateUserWithSignedMessageQueryVariables
  >(StartCreateUserWithSignedMessageDocument, options);
}
export type StartCreateUserWithSignedMessageQueryHookResult = ReturnType<
  typeof useStartCreateUserWithSignedMessageQuery
>;
export type StartCreateUserWithSignedMessageLazyQueryHookResult = ReturnType<
  typeof useStartCreateUserWithSignedMessageLazyQuery
>;
export type StartCreateUserWithSignedMessageQueryResult = Apollo.QueryResult<
  StartCreateUserWithSignedMessageQuery,
  StartCreateUserWithSignedMessageQueryVariables
>;
export const CompleteCreateUserWithSignedMessageDocument = gql`
  mutation CompleteCreateUserWithSignedMessage(
    $publicAddress: String!
    $signature: String!
    $email: String!
  ) {
    completeCreateUserWithSignedMessage(
      publicAddress: $publicAddress
      signature: $signature
      email: $email
    ) {
      jwt
    }
  }
`;
export type CompleteCreateUserWithSignedMessageMutationFn =
  Apollo.MutationFunction<
    CompleteCreateUserWithSignedMessageMutation,
    CompleteCreateUserWithSignedMessageMutationVariables
  >;

/**
 * __useCompleteCreateUserWithSignedMessageMutation__
 *
 * To run a mutation, you first call `useCompleteCreateUserWithSignedMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCreateUserWithSignedMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCreateUserWithSignedMessageMutation, { data, loading, error }] = useCompleteCreateUserWithSignedMessageMutation({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *      signature: // value for 'signature'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCompleteCreateUserWithSignedMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteCreateUserWithSignedMessageMutation,
    CompleteCreateUserWithSignedMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteCreateUserWithSignedMessageMutation,
    CompleteCreateUserWithSignedMessageMutationVariables
  >(CompleteCreateUserWithSignedMessageDocument, options);
}
export type CompleteCreateUserWithSignedMessageMutationHookResult = ReturnType<
  typeof useCompleteCreateUserWithSignedMessageMutation
>;
export type CompleteCreateUserWithSignedMessageMutationResult =
  Apollo.MutationResult<CompleteCreateUserWithSignedMessageMutation>;
export type CompleteCreateUserWithSignedMessageMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteCreateUserWithSignedMessageMutation,
    CompleteCreateUserWithSignedMessageMutationVariables
  >;
export const CreateUserWithMagicLinkDocument = gql`
  mutation CreateUserWithMagicLink($didToken: String!) {
    createUserWithMagicLink(didToken: $didToken) {
      jwt
    }
  }
`;
export type CreateUserWithMagicLinkMutationFn = Apollo.MutationFunction<
  CreateUserWithMagicLinkMutation,
  CreateUserWithMagicLinkMutationVariables
>;

/**
 * __useCreateUserWithMagicLinkMutation__
 *
 * To run a mutation, you first call `useCreateUserWithMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWithMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWithMagicLinkMutation, { data, loading, error }] = useCreateUserWithMagicLinkMutation({
 *   variables: {
 *      didToken: // value for 'didToken'
 *   },
 * });
 */
export function useCreateUserWithMagicLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserWithMagicLinkMutation,
    CreateUserWithMagicLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserWithMagicLinkMutation,
    CreateUserWithMagicLinkMutationVariables
  >(CreateUserWithMagicLinkDocument, options);
}
export type CreateUserWithMagicLinkMutationHookResult = ReturnType<
  typeof useCreateUserWithMagicLinkMutation
>;
export type CreateUserWithMagicLinkMutationResult =
  Apollo.MutationResult<CreateUserWithMagicLinkMutation>;
export type CreateUserWithMagicLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateUserWithMagicLinkMutation,
  CreateUserWithMagicLinkMutationVariables
>;
export const LogInWithMagicLinkDocument = gql`
  mutation logInWithMagicLink($didToken: String!) {
    logInWithMagicLink(didToken: $didToken) {
      jwt
    }
  }
`;
export type LogInWithMagicLinkMutationFn = Apollo.MutationFunction<
  LogInWithMagicLinkMutation,
  LogInWithMagicLinkMutationVariables
>;

/**
 * __useLogInWithMagicLinkMutation__
 *
 * To run a mutation, you first call `useLogInWithMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInWithMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInWithMagicLinkMutation, { data, loading, error }] = useLogInWithMagicLinkMutation({
 *   variables: {
 *      didToken: // value for 'didToken'
 *   },
 * });
 */
export function useLogInWithMagicLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogInWithMagicLinkMutation,
    LogInWithMagicLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LogInWithMagicLinkMutation,
    LogInWithMagicLinkMutationVariables
  >(LogInWithMagicLinkDocument, options);
}
export type LogInWithMagicLinkMutationHookResult = ReturnType<
  typeof useLogInWithMagicLinkMutation
>;
export type LogInWithMagicLinkMutationResult =
  Apollo.MutationResult<LogInWithMagicLinkMutation>;
export type LogInWithMagicLinkMutationOptions = Apollo.BaseMutationOptions<
  LogInWithMagicLinkMutation,
  LogInWithMagicLinkMutationVariables
>;
export const StartMagicLinkFlowDocument = gql`
  query StartMagicLinkFlow($email: String!) {
    startMagicLinkFlow(email: $email) {
      allowedMethods
    }
  }
`;

/**
 * __useStartMagicLinkFlowQuery__
 *
 * To run a query within a React component, call `useStartMagicLinkFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartMagicLinkFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartMagicLinkFlowQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useStartMagicLinkFlowQuery(
  baseOptions: Apollo.QueryHookOptions<
    StartMagicLinkFlowQuery,
    StartMagicLinkFlowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StartMagicLinkFlowQuery,
    StartMagicLinkFlowQueryVariables
  >(StartMagicLinkFlowDocument, options);
}
export function useStartMagicLinkFlowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StartMagicLinkFlowQuery,
    StartMagicLinkFlowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StartMagicLinkFlowQuery,
    StartMagicLinkFlowQueryVariables
  >(StartMagicLinkFlowDocument, options);
}
export type StartMagicLinkFlowQueryHookResult = ReturnType<
  typeof useStartMagicLinkFlowQuery
>;
export type StartMagicLinkFlowLazyQueryHookResult = ReturnType<
  typeof useStartMagicLinkFlowLazyQuery
>;
export type StartMagicLinkFlowQueryResult = Apollo.QueryResult<
  StartMagicLinkFlowQuery,
  StartMagicLinkFlowQueryVariables
>;
