import React, { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import Button from "components/Button";
import CommunityText from "components/_Community/Text";
import CommunityTextArea from "components/_Community/TextArea";
import { EXTERNAL_URL } from "config";
import useSession from "hooks/useSession";

import useLogic from "./../logic";
import { Form } from "./../types";
import * as styles from "./styles";

export type Props = {
  contestId: string;
  autoWin: boolean | null;
  showQuestion: boolean | null;
  formFieldLabel?: string | null;
  placeholder?: string | null;
  buttonText?: string;
  showTerms: boolean;
};

export const ContestTerms = ({
  hasRules,
  id,
  loggedIn,
}: {
  hasRules: boolean;
  id: string;
  loggedIn: boolean;
}) => {
  let text;
  const disclaimer = {
    loggedIn: {
      withRules: (
        <>
          By entering, you agree to the{" "}
          <a
            href={`https://highlight.xyz/rules/${id}`}
            target="_blank"
            rel="noreferrer"
          >
            Contest Rules
          </a>
          .
        </>
      ),
      withoutRules: null,
    },
    notLoggedIn: {
      withRules: (
        <>
          By entering and creating an account, you agree to the{" "}
          <a
            href={EXTERNAL_URL.TERMS_AND_CONDITIONS}
            target={"_blank"}
            rel="noreferrer"
          >
            Terms of Service
          </a>
          ,{" "}
          <a
            href={EXTERNAL_URL.PRIVACY_POLICY}
            target={"_blank"}
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href={`https://highlight.xyz/rules/${id}`}
            target="_blank"
            rel="noreferrer"
          >
            Contest Rules
          </a>
          .
        </>
      ),
      withoutRules: (
        <>
          By entering and creating an account, you agree to the{" "}
          <a
            href={EXTERNAL_URL.TERMS_AND_CONDITIONS}
            target={"_blank"}
            rel="noreferrer"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href={EXTERNAL_URL.PRIVACY_POLICY}
            target={"_blank"}
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </>
      ),
    },
  };

  if (loggedIn) {
    text = hasRules
      ? disclaimer.loggedIn.withRules
      : disclaimer.loggedIn.withoutRules;
  } else {
    text = hasRules
      ? disclaimer.notLoggedIn.withRules
      : disclaimer.notLoggedIn.withoutRules;
  }

  return <CommunityText extraCss={styles.contestRules}>{text}</CommunityText>;
};

const EnterContestForm = ({
  contestId,
  autoWin,
  showQuestion,
  formFieldLabel,
  placeholder,
  showTerms,
  buttonText = "REQUEST ACCESS",
}: Props) => {
  const [onSubmitLoading, setOnSubmitLoading] = useState<boolean>(false);
  const { user } = useSession();

  const {
    onSubmit,
    addMeToContestMutation,
    validationSchema,
    textAreaMaxLength,
  } = useLogic({
    contestId: contestId,
    showQuestion: showQuestion,
    setOnSubmitLoading,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(validationSchema),
  });

  if (addMeToContestMutation.called) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} css={styles.form}>
      {(showQuestion === true || showQuestion === null) && (
        <CommunityTextArea
          label={formFieldLabel ?? undefined}
          placeholder={placeholder || "The best answers will be selected"}
          maxLength={textAreaMaxLength}
          {...register("question")}
          error={errors.question?.message}
        />
      )}
      <Button type="submit" loading={onSubmitLoading} extraCss={styles.button}>
        {onSubmitLoading ? "" : buttonText}
      </Button>

      {showTerms && (
        <ContestTerms
          hasRules={autoWin === false}
          id={contestId}
          loggedIn={!!user}
        />
      )}
    </form>
  );
};

export default EnterContestForm;
