import * as React from "react";
import { FC, memo } from "react";

import { Link } from "react-router-dom";

import { TokenType } from "apollo/graphql.generated";
import DistributionButton from "components/DistributionButton/DistributionButton";
import DistributionBenefitList from "components/DistributionCard/DistributionBenefitList/DistributionBenefitList";
import IncludedToken from "components/DistributionCard/IncludedToken/IncludedToken";
import {
  Distribution,
  findMainToken,
} from "utils/distribution/distributionMethod";
import { simpleDateTime } from "utils/formatting/time";
import { Maybe } from "utils/types";

import { COMMUNITY_URL } from "../../config";
import TokenAnimation from "../TokenAnimation";
import CommunityCardContainer from "../_Community/CardContainer";
import CommunityLabel from "../_Community/Label";
import CommunityTitle from "../_Community/Title";

import * as styles from "./styles";

type DistributionCardProps = {
  distribution: Distribution;
  communityName: string;
  contractAddress?: Maybe<string>;
  viewType?: "title-first" | "image-first";
};

const DistributionCard: FC<DistributionCardProps> = ({
  distribution,
  communityName,
  contractAddress,
  viewType = "title-first",
}) => {
  const { tokens } = distribution;
  const isBundle = tokens.length > 1;

  const mainToken = findMainToken(tokens);
  const otherTokens = tokens.filter((token) => token.id !== mainToken.id);

  const isMainTokenMembership = mainToken.type === TokenType.Membership;
  const isOtherTokenBenefits = otherTokens.every(
    (token) => token.type === TokenType.Collectible
  );

  const tokenDetailUrl = COMMUNITY_URL.tokenDetail.replace(
    ":tokenId",
    mainToken.id
  );

  return (
    <CommunityCardContainer extraCss={styles.cardContainer(isBundle)}>
      {viewType === "title-first" && (
        <>
          <CommunityTitle
            color="onPrimaryBackgroundPrimaryColor"
            size="xsmall"
            extraCss={styles.cardTitle}
          >
            {mainToken.name}
          </CommunityTitle>
          <CommunityLabel size="xsmall" extraCss={styles.cardSubtitle}>
            {`${mainToken.available.toLocaleString(
              "en-US"
            )} / ${mainToken.total.toLocaleString("en-US")} AVAILABLE`}
          </CommunityLabel>
          <div css={styles.titleFirstCardImageContainer(isBundle)}>
            {mainToken.animationUri ? (
              <TokenAnimation
                sourceUri={mainToken.animationUri}
                posterUri={mainToken.uri ?? ""}
                extraCss={styles.cardImage}
              />
            ) : mainToken.uri ? (
              <Link to={tokenDetailUrl}>
                <img
                  css={styles.cardImage}
                  src={mainToken.uri}
                  alt={mainToken.name || "Token Image"}
                />
              </Link>
            ) : null}
          </div>
        </>
      )}
      {viewType === "image-first" && (
        <>
          <div
            css={[
              styles.cardImageContainer(isBundle),
              styles.imageFirstCardImageContainer,
            ]}
          >
            {mainToken.animationUri ? (
              <TokenAnimation
                sourceUri={mainToken.animationUri}
                posterUri={mainToken.uri ?? ""}
                extraCss={styles.cardImage}
              />
            ) : mainToken.uri ? (
              <img
                css={styles.cardImage}
                src={mainToken.uri}
                alt={mainToken.name || "Token Image"}
              />
            ) : null}
          </div>
          <CommunityTitle
            color="onPrimaryBackgroundPrimaryColor"
            size="xsmall"
            extraCss={[styles.cardTitle, styles.imageFirstCardTitle]}
          >
            {mainToken.name}
          </CommunityTitle>
          <CommunityLabel size="xsmall" extraCss={styles.cardSubtitle}>
            {`Edition of ${mainToken.total.toLocaleString("en-US")}`}
          </CommunityLabel>
        </>
      )}
      <div css={styles.benefitContainer(isBundle)}>
        {isBundle && (
          <CommunityLabel size="small">{`${
            isMainTokenMembership && "MEMBERSHIP "
          }BENEFITS`}</CommunityLabel>
        )}
        <div css={isBundle && styles.benefitListContainer}>
          <DistributionBenefitList tokenBenefits={mainToken.benefits} />
        </div>
      </div>
      {isBundle && (
        <div css={styles.includedTokensContainer}>
          <CommunityLabel size="small">{`INCLUDED ${
            isOtherTokenBenefits && "BENEFIT "
          }TOKENS`}</CommunityLabel>
          <div css={styles.includedTokens}>
            {otherTokens.map((token) => (
              <IncludedToken
                key={token.id}
                title={token.name || ""}
                description={token.description || ""}
                imgUrl={token.uri || ""}
              />
            ))}
          </div>
        </div>
      )}
      {distribution.timedAvailability &&
        distribution.timedAvailability.start > new Date() && (
          <CommunityLabel
            bold={false}
            uppercase={false}
            size="xsmall"
            extraCss={styles.availableOnLabel}
          >
            {`Available ${simpleDateTime(
              distribution.timedAvailability.start,
              false
            )}`}
          </CommunityLabel>
        )}
      {(!distribution.timedAvailability ||
        distribution.timedAvailability.start < new Date()) && (
        <DistributionButton
          extraCss={styles.distributionButton}
          distribution={distribution}
          communityName={communityName}
          contractAddress={contractAddress}
          timedAvailability={distribution.timedAvailability}
        />
      )}
    </CommunityCardContainer>
  );
};

export default memo(DistributionCard);
