import React, { memo, FC, PropsWithChildren } from "react";

import { useReactiveVar } from "@apollo/client";
import { Link } from "react-router-dom";

import { communityVar } from "apollo/reactive";
import { ReactComponent as VerifiedIcon } from "assets/icons/verified_creator.svg";
import DefaultCommunityImage from "assets/images/default-community-avatar.png";
import Text from "components/Typography/Text";
import { COMMUNITY_URL } from "config";

import { layoutContext } from "../../apollo/reactive/layout";
import { ThemeType } from "../../styles/theme/theme.types";
import CommunityText from "../_Community/Text";

import * as styles from "./styles";

export type TextByThemeProps = {
  overwriteTheme?: ThemeType;
};

const TextByTheme: FC<PropsWithChildren<TextByThemeProps>> = ({
  overwriteTheme,
  children,
}) => {
  const layout = layoutContext();
  const theme = overwriteTheme ?? layout.theme ?? "system";

  if (theme === "system") {
    return (
      <Text size="large" onBackgroundColor="primary" bold>
        {children}
      </Text>
    );
  }

  return (
    <CommunityText size="large" bold>
      {children}
    </CommunityText>
  );
};

export type CommunityAvatarProps = {
  overwriteTheme?: ThemeType;
};

const CommunityAvatar: FC<CommunityAvatarProps> = ({ overwriteTheme }) => {
  const { creatorAvatar } = useReactiveVar(communityVar);

  return (
    <Link css={styles.container} to={COMMUNITY_URL.home}>
      <img
        css={styles.image}
        src={creatorAvatar?.imageUrl || DefaultCommunityImage}
        alt={creatorAvatar?.name || ""}
      />
      <TextByTheme overwriteTheme={overwriteTheme}>
        {creatorAvatar?.name} <VerifiedIcon css={styles.icon} />
      </TextByTheme>
    </Link>
  );
};

export default memo(CommunityAvatar);
