import React, { createContext, useReducer, useContext } from "react";

import {
  LogInError,
  parseCreateUserError,
  parseLoginUserError,
} from "utils/errors/authErrors";
import { existsMetaMaskPlugin } from "utils/metamask";

// state type
export type AuthForm = {
  useMetaMask: boolean;
  isLoading: boolean;
  reCaptchaCompleted: boolean;
  authError: LogInError | null;
};

const initialState: AuthForm = {
  useMetaMask: existsMetaMaskPlugin(),
  isLoading: false,
  reCaptchaCompleted: false,
  authError: null,
};
type AppState = typeof initialState;

// dispatch type
type Action =
  | { type: "setUseMetaMask"; payload: boolean }
  | { type: "clearError" }
  | { type: "setError"; payload: LogInError }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  | { type: "setCreateError"; payload: any }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  | { type: "setLoginError"; payload: any }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  | { type: "setWeb3CreateError"; payload: any }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  | { type: "setWeb3LoginError"; payload: any }
  | { type: "isLoading"; payload: boolean }
  | { type: "reCaptchaCompleted"; payload: boolean }
  | { type: "reset" };

// Context type { state, dispatch }
type AuthFormApi = {
  state: typeof initialState;
  dispatch: React.Dispatch<Action>;
};

function reducer(state: AppState, action: Action): AppState {
  let error = null;
  switch (action.type) {
    case "reset":
      return { ...initialState };
    case "setUseMetaMask":
      return { ...state, useMetaMask: action.payload };
    case "clearError":
      return { ...state, authError: null };
    case "setError":
      return { ...state, authError: action.payload };
    case "setCreateError":
      error = parseCreateUserError(action.payload, false);
      return { ...state, authError: error };
    case "setLoginError":
      error = parseLoginUserError(action.payload, false);
      return { ...state, authError: error };
    case "setWeb3CreateError":
      error = parseCreateUserError(action.payload, true);
      return { ...state, authError: error };
    case "setWeb3LoginError":
      error = parseLoginUserError(action.payload, true);
      return { ...state, authError: error };
    case "isLoading":
      return { ...state, isLoading: action.payload };
    case "reCaptchaCompleted":
      return { ...state, reCaptchaCompleted: action.payload };
    default:
      throw new Error("unknown reducer-action type");
  }
}

const ctx = createContext<AuthFormApi>({
  state: initialState,
  dispatch: () => null,
});

function AuthFormProvider(props: React.PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer<React.Reducer<AppState, Action>>(
    reducer,
    initialState
  );
  return <ctx.Provider value={{ state, dispatch }} {...props} />;
}

function useAuthForm() {
  const context = useContext(ctx);
  if (context === undefined)
    throw new Error(`No provider for AuthFormContext given`);
  return context;
}

export { AuthFormProvider, useAuthForm };
