import { useMemo, useCallback, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import { Maybe } from "graphql/jsutils/Maybe";

import useCommunityOffers, {
  fetchCommunityOffers,
} from "apollo/hooks/useCommunityOffers";
import { cartStore, Community, communityVar } from "apollo/reactive";
import useSession from "hooks/useSession";

import { Offer, UserTokens } from "./types";

const checkUserBalance = (
  community: Community,
  offer: Offer,
  userTokens: UserTokens
) => {
  const { tokenId } = offer;
  const ownedToken = userTokens.find(
    (x) => x.tokenId === tokenId && x.community.id === community.id
  );
  if (ownedToken) {
    return `You already own token ${ownedToken.name}`;
  }

  return null;
};

const checkCreatorBalance = async (community: Community, offer: Offer) => {
  const latestOffers = await fetchCommunityOffers(community.slug);
  const freshOffer = latestOffers.find(
    (latestOffer) => latestOffer.id === offer.id
  );
  if (freshOffer) {
    for (const freshOfferToken of freshOffer.tokens || []) {
      if (freshOfferToken.creatorBalance! <= 0) {
        return "Unfortunately, the token you are trying to purchase is no longer available.";
      }
    }
  }

  return null;
};

export const useCheckoutLogic = () => {
  const cart = useReactiveVar(cartStore);

  const cartItem = cart.items[0];
  const { data: communityDetails, loading: communityDetailsLoading } =
    useCommunityOffers();

  const offers = communityDetails?.community?.offers;

  const offer = useMemo(
    (): Maybe<Offer> => offers?.find((offer) => offer.id === cartItem?.id),
    [offers, cartItem]
  );
  const isCartEmpty = useMemo(() => !cart.items.length, [cart.items]);
  const redirectUrl = cart.redirectUrl;
  return {
    offer,
    isCartEmpty,
    isOrderPreviewLoading: communityDetailsLoading,
    redirectUrl,
  };
};

export const useTokensVerify = () => {
  const { offer } = useCheckoutLogic();
  const { refetchUser } = useSession();
  const community = useReactiveVar(communityVar);
  const [errorMessage, setError] = useState("");

  const verifyTokens = useCallback(async (): Promise<boolean> => {
    if (!offer) {
      return true;
    }
    const userResult = await refetchUser();
    const userTokens = userResult.data?.currentUser?.tokens ?? [];
    const responses = await Promise.all([
      checkCreatorBalance(community, offer),
      checkUserBalance(community, offer, userTokens),
    ]);
    const errors = responses.filter((x) => !!x);

    let noErrors = true;
    if (errors.length && errors[0]) {
      setError(errors[0]);
      noErrors = false;
    }
    return noErrors;
  }, [community, offer, refetchUser]);

  return {
    verifyTokens,
    errorMessage,
  };
};

export type UseProfileSidebarLogic = ReturnType<typeof useCheckoutLogic>;

export default {};
