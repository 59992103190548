import { css, Theme } from "@emotion/react";

import { bottomMargin, topMargin } from "styles/global/layout";

export const summaryCards = (theme: Theme) => css`
  ${bottomMargin("11/48px")};
  display: grid;
  grid-template-columns: ${theme.layout.gridColumns(6, 6)};
  gap: 30px;
`;
/*
 * SummaryCard Component
 */
export const summaryCard = css`
  padding: 20px;

  label {
    ${bottomMargin("4/8px")}
  }
`;

export const loadingIcon = css`
  display: flex;
  width: 100%;
  align-self: center;
  ${topMargin("10/40px")};
`;

/*
 * MembersList Component
 */
export const membersListHeader = (theme: Theme) => css`
  padding-top: 0;
  display: grid;
  grid-template-columns: ${theme.layout.gridColumns(9, 3)};
  ${topMargin("10/40px")};
`;

export const membersListRow = (theme: Theme) => css`
  display: grid;
  grid-template-columns: ${theme.layout.gridColumns(8, 4)};
`;

export const memberInfo = css`
  display: flex;
  flex-direction: column;
`;

export const discordInfo = css`
  display: flex;
  gap: 8px;
  align-items: center;
  ${topMargin("2/4px")};
`;

export const discordIcon = css`
  height: 16px;
  width: 16px;
`;

export const tokensInfo = css`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  ${topMargin("5/12px")};
`;

export const tokenInfo = css`
  padding: 8px;
  width: auto;
`;

export const blockchainAddressText = css`
  width: auto;
  ${topMargin("2/4px")};
  ${bottomMargin("2/4px")};
`;

export const joinDate = css`
  margin-left: 10px;
`;

export const noMembers = css`
  ${topMargin("7/20px")};
  display: flex;
  justify-items: center;
  justify-content: center;
`;

export const searchField = css`
  flex-grow: 1;
  padding: 0;
  margin: 0;
  input {
    margin: 0;
  }
  label {
    margin: 0;
  }
`;

export const searchButton = css`
  width: auto;
  padding: 0px 48px;
  height: 64px;
`;

export const sort = css`
  flex-shrink: 1;
  margin: 0;
  width: 260px;
  div {
    margin-top: 0;
  }
  select {
    cursor: pointer;
  }
`;

export const searchBar = css`
  position: relative;
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const searchIcon = css`
  margin-right: 12px;
`;

export const error = css`
  ${topMargin("3/6px")};
  width: 380px;
  display: flex;
`;

export const clearButton = css`
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
`;

export const clearIcon = css`
  width: 20px;
  height: 20px;
  color: black;
`;

export const memberList = css`
  ${topMargin("9/32px")};
`;
