import { ComponentProps, FC, memo } from "react";

import { Interpolation, Theme } from "@emotion/react";

import * as styles from "../BaseAnchor/styles";
import { LinkColor } from "../BaseAnchor/styles";

export type AnchorButtonProps = {
  color?: LinkColor;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
} & ComponentProps<"button">;

const AnchorButton: FC<AnchorButtonProps> = ({
  children,
  color = "inherit",
  extraCss,
  ...rest
}) => (
  <button css={[styles.link(color), extraCss]} {...rest}>
    {children}
  </button>
);

export default memo(AnchorButton);
