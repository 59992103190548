import React, { FC, memo } from "react";

import ChecklistLink from "components/CommunityCreator/ChecklistLink";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import * as styles from "./styles";

export interface Props {
  communitySlug: string;
  isDescriptionDone: boolean;
  isLogoDone: boolean;
  isAvatarDone: boolean;
  isCreateTokenDone: boolean;
  isAvailableTokenDone: boolean;
}

const LaunchChecklist: FC<Props> = ({
  communitySlug,
  isDescriptionDone,
  isLogoDone,
  isAvatarDone,
  isCreateTokenDone,
  isAvailableTokenDone,
}) => {
  const tokensUrl = `/edit-community/${communitySlug}/tokens`;

  return (
    <>
      <Title size="medium">Get Ready to Launch Your Community</Title>
      <Text as="p" size="medium">
        Complete these steps before you can launch your community.
      </Text>
      <div css={styles.checklist}>
        <ul>
          <li>
            <ChecklistLink
              primaryText="Add a Creator Avatar"
              secondaryText="Add a photo or logo for you, your band, brand, etc."
              to="#creatorAvatar"
              checked={isAvatarDone}
              offset={75}
            />
          </li>
          <li>
            <ChecklistLink
              primaryText="Add a Community Description"
              secondaryText="Let people know what to expect from your community"
              to="#description"
              checked={isDescriptionDone}
              offset={115}
            />
          </li>
          <li>
            <ChecklistLink
              primaryText="Add a Community Logo"
              secondaryText="Add a logo that represents your community"
              to="#communityLogo"
              checked={isLogoDone}
              offset={75}
            />
          </li>
          <li>
            <ChecklistLink
              primaryText="Create a Membership Token"
              secondaryText="Create a membership tokens that gives people access to your community."
              to={tokensUrl}
              checked={isCreateTokenDone}
            />
          </li>
          <li>
            <ChecklistLink
              primaryText="Make a Token Available"
              secondaryText="Allow people to buy or claim a token from the About page of your community."
              to={tokensUrl}
              checked={isAvailableTokenDone}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default memo(LaunchChecklist);
