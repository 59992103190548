import { css, Theme } from "@emotion/react";

export const button = (theme: Theme) => css`
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  width: auto;
  border-radius: ${theme.cardContainer.borderRadius.medium};
`;

export const card = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: auto;
  padding: ${theme.verticalSpaces.large[4]} ${theme.verticalSpaces.large[6]};
  margin-right: 12px;
`;

export const description = (theme: Theme) => css`
  margin-left: ${theme.verticalSpaces.large[4]};
`;

export const icon = (theme: Theme) => css`
  align-self: center;
  justify-content: center;
  color: ${theme.text.colors.primary};
`;

export const linklessCard = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: ${theme.verticalSpaces.large[4]} ${theme.verticalSpaces.large[6]};
  margin-right: 12px;
`;
