import { DiscordServer } from "apollo/graphql.generated";

// Summary: try to open the discord app through a deep link
//      if the user takes more than "openAppTimeoutMs",
//      to open the discord app, fallback to the web url
export const handleOpenDiscord = (
  deepLinkUrl: string,
  fallbackWebUrl: string
  // openAppTimeoutMs = 5000
) => {
  window.open(fallbackWebUrl, "_blank");
  // TODO(james): re-implement deep linking in a way that doesn't break on mobile
  // const newWin = window.open(deepLinkUrl, "_blank");
  // setTimeout(() => {
  //   newWin?.location.replace(fallbackWebUrl);
  // }, openAppTimeoutMs);
};

export interface DiscordCallbackState {
  randomState: string;
  postAuthRedirectUri: string;
}

export const stateObjectToBase64 = (state: DiscordCallbackState): string =>
  btoa(JSON.stringify(state));

export const stateBase64toObject = (state: string): DiscordCallbackState =>
  JSON.parse(atob(state));

export const buildDiscordUrls = (discordServer: DiscordServer) => {
  const apiPath = `channels/${discordServer.serverId}`;
  return {
    discordAppUrl: `discord://discordapp.com/${apiPath}`,
    discordWebUrl: `https://discord.com/${apiPath}`,
  };
};
