import React, { memo, FC, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { Maybe } from "apollo/graphql.generated";
import Button from "components/Button";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";

import * as styles from "./styles";

export type BenefitProps = {
  icon: Maybe<JSX.Element>;
  description?: Maybe<string>;
  link: Maybe<string>;
};

const Benefit: FC<BenefitProps> = ({ icon, description, link }) => {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => {
    if (link) navigate(link);
  }, [navigate, link]);

  if (!link) {
    return (
      <CardContainer
        border
        background="primary"
        additionalCss={styles.linklessCard}
      >
        <div css={styles.icon}>{icon}</div>
        <Text as="p" additionalCss={styles.description}>
          {description}
        </Text>
      </CardContainer>
    );
  }
  return (
    <Button onClick={handleOnClick} additionalCss={styles.button}>
      <CardContainer border background="primary" additionalCss={styles.card}>
        <div css={styles.icon}>{icon}</div>
        <Text as="p" additionalCss={styles.description}>
          {description}
        </Text>
      </CardContainer>
    </Button>
  );
};

export default memo(Benefit);
