import React, { useEffect } from "react";

import { TokenType } from "apollo/graphql.generated";
import NewBoxLinkContainer from "components/NewBoxLinkContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import { DesignTokenType } from "../types";

import * as styles from "./styles";

export type SelectTokenTypeProps = {
  onClick(tokenType: DesignTokenType): void;
  clearForm(): void;
};

const membershipTokenTitle = "Membership Token";
const membershipTokenAccessList = [
  { check: true, text: "Grants access to your community" },
  {
    check: true,
    text: "Provides utility like content, shop, or Discord access",
  },
  { check: true, text: "Limited edition, digital collectible" },
  { check: true, text: "ERC-1155 non-fungible token on Polygon" },
];
const collectibleTokenTitle = "Collectible Token";
const collectibleTokenAccessList = [
  { check: false, text: "Does not grant access to your community" },
  { check: false, text: "Does not provide any utility" },
  { check: true, text: "Limited edition, digital collectible" },
  { check: true, text: "ERC-1155 non-fungible token on Polygon" },
];

const SelectTokenType = ({ onClick, clearForm }: SelectTokenTypeProps) => {
  useEffect(() => {
    clearForm();
  }, []);

  return (
    <div>
      <div css={styles.gridSection}>
        <Title size="large" extraCss={styles.centeredGridText}>
          Choose the Type of Token You Want to Mint
        </Title>
      </div>

      <div css={styles.gridSection}>
        <div css={styles.membershipColumn}>
          <NewBoxLinkContainer
            title={membershipTokenTitle}
            footer={membershipTokenAccessList}
            onClick={() => onClick(TokenType.Membership)}
          />
        </div>
        <div css={styles.collectibleColumn}>
          <NewBoxLinkContainer
            title={collectibleTokenTitle}
            footer={collectibleTokenAccessList}
            onClick={() => onClick(TokenType.Collectible)}
          />
        </div>
      </div>
      <div css={styles.gridSection}>
        <Text size="small" extraCss={styles.centeredGridText}>
          Find more information on token types{" "}
          <Text as="span" size="small" bold>
            <a
              href="https://support.highlight.xyz/hc/en-us/articles/5877666817812"
              target="_blank"
              rel="noreferrer"
              css={styles.supportLink}
            >
              here
            </a>
            .
          </Text>
        </Text>
      </div>
    </div>
  );
};

export default SelectTokenType;
