import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";
import { textMediumStyles } from "styles/global/typography";

export const dragContainer = css`
  ${topMargin("6/16px")}
`;

export const tokenContainer = css`
  aspect-ratio: 1;
`;

export const emptyTokenContainer = (error: boolean) => (theme: Theme) =>
  css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: ${error
      ? `2px solid ${theme.colorMap.destructive.default}`
      : "1px dashed rgba(2, 2, 2, 0.1)"};
    border-radius: 20px;
    padding: 0 40px;
    cursor: pointer;
  `;

export const noImageIcon = css`
  width: 24px;
  height: 24px;
`;

export const dragDescription = css`
  text-align: center;

  ${topMargin("6/16px")}
`;

export const uploadDescription = css`
  text-align: center;

  ${topMargin("2/4px")}
`;

export const controlButtons = css`
  display: flex;
  align-items: center;
  justify-content: center;

  ${topMargin("6/16px")}
`;

export const button = css`
  background-color: transparent;
  padding: 8px;
  width: unset;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const removeButton = (theme: Theme) => css`
  ${button};
  color: ${theme.colorMap.destructive.default};
`;

export const changeButton = (theme: Theme) => css`
  ${button};
  color: ${theme.text.colors.primary};
`;

export const input = css`
  display: none;
`;

export const error = (theme: Theme) => css`
  ${textMediumStyles(theme)}
  color: ${theme.colorMap.destructive.default};
  padding: 0;
  margin: 8px 0 0 0;
`;
