import { useDropzone } from "react-dropzone";

import { fileHasExtensions, getImageResolution } from "utils/fileImage";
import { Maybe } from "utils/types";

const useLogic = ({
  imageUrl,
  onFileAccept,
  onFileReject,
  onRemove,
  minWidth,
  minHeight,
  maxSize,
  acceptedExtensions = ["jpg", "jpeg", "png"],
}: {
  imageUrl?: string;
  onFileAccept: (acceptedFile: File) => Promise<void>;
  onFileReject: (error: string) => Promise<void>;
  onRemove?: () => Promise<void>;
  minWidth?: Maybe<number>;
  minHeight?: Maybe<number>;
  /* Use size in megabytes */
  maxSize?: Maybe<number>;
  acceptedExtensions?: string[];
}) => {
  const handleUpload = async ([file]: File[]) => {
    if (!fileHasExtensions(file.name, acceptedExtensions)) {
      return onFileReject("Type not accepted");
    }

    if (minWidth && minHeight) {
      const { width, height } = await getImageResolution(file);
      if (width < minWidth || height < minHeight) {
        const errorMsg = `The resolution must be at least ${minWidth}x${minHeight}. Current resolution is ${width}x${height}.`;
        return onFileReject(errorMsg);
      }
    }

    if (maxSize) {
      const maxSizeInBytes = maxSize * 1000000;
      if (file.size > maxSizeInBytes) {
        const errorMsg = `The file size cannot be greater than ${maxSize}MB.`;
        return onFileReject(errorMsg);
      }
    }

    return onFileAccept(file);
  };

  const dropZoneState = useDropzone({
    onDrop: handleUpload,
    noClick: !!imageUrl,
    noKeyboard: !!imageUrl,
    maxFiles: 1,
    noDrag: !!imageUrl,
  });

  const handleChange = () => dropZoneState.open();
  const handleRemove = async () => onRemove && onRemove();

  return {
    dropZoneState,
    handleChange,
    handleRemove,
  };
};

export type UseLogic = ReturnType<typeof useLogic>;
export default useLogic;
