import { isEmpty } from "lodash";

import { IN_DEVELOPMENT, LOGGER_API } from "config";

export type ErrorLogEvent = {
  message: string;
  error: unknown;
  requestId?: string;
  userId?: string;
  contestId?: string;
  communityId?: string;
  contestEntryId?: string;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const logError = async (error: any) => {
  const message = error?.message ?? "<no message>";
  if (LOGGER_API) {
    try {
      // most raw Error's are parsed as {}
      const parsedError = JSON.parse(JSON.stringify(error));
      const finalError = isEmpty(parsedError)
        ? { message, stack: error?.error?.stack }
        : parsedError;
      const payload = JSON.stringify({
        error: finalError,
        url: window.location.href,
        hlVersion: 0,
      });
      await fetch(LOGGER_API, {
        method: "post",
        body: payload,
        headers: { "Content-Type": "application/json" },
      });
      IN_DEVELOPMENT &&
        console.log("🚨 Error | sent to logger API: " + message, error);
    } catch (e) {
      IN_DEVELOPMENT &&
        console.log(
          "🚨 Error | failed to send to logger API: " + message,
          error
        );
      IN_DEVELOPMENT && console.error("logger API error", e);
    }
  } else {
    console.log("🚨 Error: " + message, error);
  }
};
