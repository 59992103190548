import { CommunityButtonProps } from "../../Button";
import {
  base as baseButton,
  bySize as baseButtonBySize,
  byColor as baseButtonByColor,
  textWrapper as baseTextWrapper,
  buttonText as baseButtonText,
  headerText as baseHeaderText,
} from "../../Button/styles";

export const base = baseButton;

export const bySize = (
  size: NonNullable<CommunityButtonProps["size"]>,
  hasHeader: boolean
) => baseButtonBySize(size, hasHeader);

export const byColor = (color: NonNullable<CommunityButtonProps["color"]>) =>
  baseButtonByColor(color);

export const textWrapper = baseTextWrapper;
export const buttonText = baseButtonText;
export const headerText = baseHeaderText;
