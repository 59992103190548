import * as Types from "../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type PostFragment = {
  __typename: "Post";
  id: string;
  pinned: boolean;
  text: string | null;
  attachment: {
    __typename: "Attachment";
    id: string;
    type: Types.AttachmentType;
    metadata:
      | {
          __typename: "MediaMetadata";
          mime: Types.SupportedMediaMime;
          url: string | null;
          type: Types.SupportedMediaType;
        }
      | { __typename: "TokenMetadata"; id: string; claimable: string | null }
      | null;
  } | null;
  reactions: Array<{
    __typename: "BasePostReaction";
    reaction: string | null;
    count: number | null;
    userHasReacted: boolean | null;
  }> | null;
};

export type TokenInfoFragment = {
  __typename: "Token";
  tokenId: string;
  name: string | null;
  description: string | null;
  uri: string | null;
  creatorBalance: number | null;
  supply: number | null;
  tokenType: Types.TokenType;
};

export type PostsByCommunityQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
}>;

export type PostsByCommunityQuery = {
  community: {
    __typename: "Community";
    id: string;
    name: string;
    benefitTokens: Array<{
      __typename: "Token";
      tokenId: string;
      name: string | null;
      description: string | null;
      uri: string | null;
      creatorBalance: number | null;
      supply: number | null;
      tokenType: Types.TokenType;
    }> | null;
    membershipTokens: Array<{
      __typename: "Token";
      tokenId: string;
      name: string | null;
      description: string | null;
      uri: string | null;
      creatorBalance: number | null;
      supply: number | null;
      tokenType: Types.TokenType;
    }> | null;
    creatorAvatar: {
      __typename: "CreatorAvatar";
      imageUrl: string | null;
      name: string | null;
    };
    offers: Array<{
      __typename: "Offer";
      tokenId: number | null;
      active: boolean;
      price: string;
      id: string;
      currency: string;
      crypto: { __typename: "CryptoCurrency"; inUSD: number | null } | null;
    }>;
  } | null;
  contentFeed: {
    __typename: "ContentFeed";
    communityId: string;
    posts: Array<{
      __typename: "Post";
      id: string;
      pinned: boolean;
      text: string | null;
      attachment: {
        __typename: "Attachment";
        id: string;
        type: Types.AttachmentType;
        metadata:
          | {
              __typename: "MediaMetadata";
              mime: Types.SupportedMediaMime;
              url: string | null;
              type: Types.SupportedMediaType;
            }
          | {
              __typename: "TokenMetadata";
              id: string;
              claimable: string | null;
            }
          | null;
      } | null;
      reactions: Array<{
        __typename: "BasePostReaction";
        reaction: string | null;
        count: number | null;
        userHasReacted: boolean | null;
      }> | null;
    }> | null;
    benefit: {
      __typename: "Benefit";
      hasBenefit: boolean;
      hasAccess: boolean;
      unlockedBy: Array<{
        __typename: "UserToken";
        tokenId: number;
        name: string;
        imageUrl: string;
        balance: number | null;
        tokenType: Types.TokenType;
      }> | null;
      unlockableBy: Array<{
        __typename: "Token";
        tokenId: string;
        name: string | null;
        uri: string | null;
        supply: number | null;
        communityAddress: string;
        tokenType: Types.TokenType;
      }> | null;
    };
  } | null;
};

export type UpdatePostReactionMutationVariables = Types.Exact<{
  data: Types.UpdatePostReactionInput;
  updatePostReactionId: Types.Scalars["String"];
}>;

export type UpdatePostReactionMutation = {
  updatePostReaction: {
    __typename: "Post";
    id: string;
    pinned: boolean;
    text: string | null;
    attachment: {
      __typename: "Attachment";
      id: string;
      type: Types.AttachmentType;
      metadata:
        | {
            __typename: "MediaMetadata";
            mime: Types.SupportedMediaMime;
            url: string | null;
            type: Types.SupportedMediaType;
          }
        | { __typename: "TokenMetadata"; id: string; claimable: string | null }
        | null;
    } | null;
    reactions: Array<{
      __typename: "BasePostReaction";
      reaction: string | null;
      count: number | null;
      userHasReacted: boolean | null;
    }> | null;
  };
};

export type UpdatePostPinMutationVariables = Types.Exact<{
  data: Types.UpdatePostPinInput;
  updatePostPinId: Types.Scalars["String"];
}>;

export type UpdatePostPinMutation = {
  updatePostPin: {
    __typename: "Post";
    id: string;
    pinned: boolean;
    text: string | null;
    attachment: {
      __typename: "Attachment";
      id: string;
      type: Types.AttachmentType;
      metadata:
        | {
            __typename: "MediaMetadata";
            mime: Types.SupportedMediaMime;
            url: string | null;
            type: Types.SupportedMediaType;
          }
        | { __typename: "TokenMetadata"; id: string; claimable: string | null }
        | null;
    } | null;
    reactions: Array<{
      __typename: "BasePostReaction";
      reaction: string | null;
      count: number | null;
      userHasReacted: boolean | null;
    }> | null;
  };
};

export type DeletePostMutationVariables = Types.Exact<{
  communitySlug: Types.Scalars["String"];
  deletePostId: Types.Scalars["String"];
}>;

export type DeletePostMutation = { deletePost: boolean };

export const PostFragmentDoc = gql`
  fragment post on Post {
    id
    pinned
    text
    attachment {
      id
      type
      metadata {
        ... on MediaMetadata {
          mime
          url
          type
        }
        ... on TokenMetadata {
          id
          claimable
        }
      }
    }
    reactions {
      reaction
      count
      userHasReacted
    }
  }
`;
export const TokenInfoFragmentDoc = gql`
  fragment tokenInfo on Token {
    tokenId
    name
    description
    uri(useChain: false)
    creatorBalance
    supply
    tokenType
  }
`;
export const PostsByCommunityDocument = gql`
  query PostsByCommunity($slug: String!) {
    community(slug: $slug) {
      id
      name
      benefitTokens {
        ...tokenInfo
      }
      membershipTokens {
        ...tokenInfo
      }
      creatorAvatar {
        imageUrl
        name
      }
      offers {
        tokenId
        active
        price
        id
        currency
        crypto {
          inUSD
        }
      }
    }
    contentFeed(communitySlug: $slug) {
      communityId
      posts {
        ...post
      }
      benefit {
        hasBenefit
        hasAccess
        unlockedBy {
          tokenId
          name
          imageUrl
          balance
          tokenType
        }
        unlockableBy {
          tokenId
          name
          uri(useChain: false)
          supply
          communityAddress
          tokenType
        }
      }
    }
  }
  ${TokenInfoFragmentDoc}
  ${PostFragmentDoc}
`;

/**
 * __usePostsByCommunityQuery__
 *
 * To run a query within a React component, call `usePostsByCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsByCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsByCommunityQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePostsByCommunityQuery(
  baseOptions: Apollo.QueryHookOptions<
    PostsByCommunityQuery,
    PostsByCommunityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PostsByCommunityQuery, PostsByCommunityQueryVariables>(
    PostsByCommunityDocument,
    options
  );
}
export function usePostsByCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostsByCommunityQuery,
    PostsByCommunityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PostsByCommunityQuery,
    PostsByCommunityQueryVariables
  >(PostsByCommunityDocument, options);
}
export type PostsByCommunityQueryHookResult = ReturnType<
  typeof usePostsByCommunityQuery
>;
export type PostsByCommunityLazyQueryHookResult = ReturnType<
  typeof usePostsByCommunityLazyQuery
>;
export type PostsByCommunityQueryResult = Apollo.QueryResult<
  PostsByCommunityQuery,
  PostsByCommunityQueryVariables
>;
export const UpdatePostReactionDocument = gql`
  mutation UpdatePostReaction(
    $data: UpdatePostReactionInput!
    $updatePostReactionId: String!
  ) {
    updatePostReaction(data: $data, id: $updatePostReactionId) {
      ...post
    }
  }
  ${PostFragmentDoc}
`;
export type UpdatePostReactionMutationFn = Apollo.MutationFunction<
  UpdatePostReactionMutation,
  UpdatePostReactionMutationVariables
>;

/**
 * __useUpdatePostReactionMutation__
 *
 * To run a mutation, you first call `useUpdatePostReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostReactionMutation, { data, loading, error }] = useUpdatePostReactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      updatePostReactionId: // value for 'updatePostReactionId'
 *   },
 * });
 */
export function useUpdatePostReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePostReactionMutation,
    UpdatePostReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePostReactionMutation,
    UpdatePostReactionMutationVariables
  >(UpdatePostReactionDocument, options);
}
export type UpdatePostReactionMutationHookResult = ReturnType<
  typeof useUpdatePostReactionMutation
>;
export type UpdatePostReactionMutationResult =
  Apollo.MutationResult<UpdatePostReactionMutation>;
export type UpdatePostReactionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostReactionMutation,
  UpdatePostReactionMutationVariables
>;
export const UpdatePostPinDocument = gql`
  mutation UpdatePostPin(
    $data: UpdatePostPinInput!
    $updatePostPinId: String!
  ) {
    updatePostPin(data: $data, id: $updatePostPinId) {
      ...post
    }
  }
  ${PostFragmentDoc}
`;
export type UpdatePostPinMutationFn = Apollo.MutationFunction<
  UpdatePostPinMutation,
  UpdatePostPinMutationVariables
>;

/**
 * __useUpdatePostPinMutation__
 *
 * To run a mutation, you first call `useUpdatePostPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostPinMutation, { data, loading, error }] = useUpdatePostPinMutation({
 *   variables: {
 *      data: // value for 'data'
 *      updatePostPinId: // value for 'updatePostPinId'
 *   },
 * });
 */
export function useUpdatePostPinMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePostPinMutation,
    UpdatePostPinMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePostPinMutation,
    UpdatePostPinMutationVariables
  >(UpdatePostPinDocument, options);
}
export type UpdatePostPinMutationHookResult = ReturnType<
  typeof useUpdatePostPinMutation
>;
export type UpdatePostPinMutationResult =
  Apollo.MutationResult<UpdatePostPinMutation>;
export type UpdatePostPinMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostPinMutation,
  UpdatePostPinMutationVariables
>;
export const DeletePostDocument = gql`
  mutation DeletePost($communitySlug: String!, $deletePostId: String!) {
    deletePost(communitySlug: $communitySlug, id: $deletePostId)
  }
`;
export type DeletePostMutationFn = Apollo.MutationFunction<
  DeletePostMutation,
  DeletePostMutationVariables
>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      communitySlug: // value for 'communitySlug'
 *      deletePostId: // value for 'deletePostId'
 *   },
 * });
 */
export function useDeletePostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePostMutation,
    DeletePostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(
    DeletePostDocument,
    options
  );
}
export type DeletePostMutationHookResult = ReturnType<
  typeof useDeletePostMutation
>;
export type DeletePostMutationResult =
  Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<
  DeletePostMutation,
  DeletePostMutationVariables
>;
