import { css } from "@emotion/react";

import { bottomMargin, topMargin } from "styles/global/layout";

export const pageContent = css``;
export const pageContentTitle = css`
  ${bottomMargin("9/32px")}
  &:not(:first-of-type) {
    ${topMargin("13/64px")}
  }
`;

export const form = css`
  position: relative;
`;

export const fieldsList = (isSubmitting: boolean) =>
  isSubmitting
    ? css`
        pointer-events: none;

        > div {
          cursor: not-allowed;
        }
      `
    : null;
