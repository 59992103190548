import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { string as yupString } from "yup";

import { useUpdateGeneralInfoMutation } from "apollo/queries";
import TextArea from "components/Inputs/TextArea";
import LoadingIcon from "components/LoadingIcon";
import { logError } from "services/logger";

import * as styles from "./styles";

export interface Props {
  communityId: string;
  communityDescriptionValue: string;
  id: string;
  onSetDescription: (description: string) => void;
}

const MAX_LENGTH = 720;

const CommunityDescriptionUpdate: FC<Props> = ({
  communityId,
  communityDescriptionValue,
  id,
  onSetDescription,
}) => {
  const [doUpdateGeneralInfoMutation] = useUpdateGeneralInfoMutation();

  const [communityDescription, setCommunityDescription] = useState<string>(
    communityDescriptionValue
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setCommunityDescription(communityDescriptionValue);
  }, [communityDescriptionValue]);

  useEffect(() => {
    onSetDescription(communityDescription);
  }, [communityDescription]);

  const validationSchema = yupString()
    .trim()
    .max(MAX_LENGTH)
    .required("Description is required.");

  const update = async (
    descriptionToUpdate: string,
    communityId: string,
    description: string
  ) => {
    setError("");
    if (descriptionToUpdate === description) return;

    try {
      descriptionToUpdate = await validationSchema.validate(
        descriptionToUpdate
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
      if (inputRef?.current) {
        inputRef.current.value = communityDescription;
      }
      return;
    }

    try {
      setLoading(true);
      const res = await doUpdateGeneralInfoMutation({
        variables: {
          data: {
            description: descriptionToUpdate,
          },
          updateGeneralInfoId: communityId,
        },
      });
      const communityResponse = res.data?.updateGeneralInfo;
      const descriptionNewValue = communityResponse?.description || "";
      setCommunityDescription(descriptionNewValue);
    } catch (error) {
      setError("Update failed");
      await logError({
        error,
        message: "[handleCommunityDescriptionOnBlur] update community failed",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCommunityDescriptionOnBlur = useCallback(
    async (event: React.FocusEvent<HTMLTextAreaElement>) =>
      update(event.currentTarget.value, communityId, communityDescription),
    [communityId, communityDescription]
  );

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <TextArea
      css={styles.textArea}
      ref={inputRef}
      initialLength={communityDescription.length}
      label="Community Description"
      labelType="title"
      id={id}
      maxLength={MAX_LENGTH}
      className=""
      placeholder="Enter your description"
      defaultValue={communityDescription}
      onBlur={handleCommunityDescriptionOnBlur}
      error={error}
      onChange={() => setError("")}
      onClick={() => setError("")}
    />
  );
};

export default memo(CommunityDescriptionUpdate);
