import React, { FC, memo } from "react";

import { ReactComponent as EmptyCartIllustration } from "assets/illustrations/empty-cart.svg";
import ButtonLink from "components/ButtonLink";
import Layout from "components/Layout";
import { CommunitySEO } from "components/Layout/SEO";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { COMMUNITY_PAGE_TITLE, COMMUNITY_URL } from "config";

import * as styles from "./styles";

type Props = {
  message: string;
};
const ErrorState: FC<Props> = ({ message }) => (
  <>
    <CommunitySEO title={COMMUNITY_PAGE_TITLE.CHECKOUT_ERROR} />

    <Layout css={styles.main}>
      <div css={styles.container}>
        <EmptyCartIllustration />

        <Title size="large">Checkout Error</Title>
        <Text as="div" size="medium">
          <p>{message}</p>
        </Text>
        <br />
        <ButtonLink to={COMMUNITY_URL.home}>Go Back</ButtonLink>
      </div>
    </Layout>
  </>
);

export default memo(ErrorState);
