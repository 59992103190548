import React, { memo, useState } from "react";

import TokenTooltipContent from "components/TokenTooltip/TokenTooltipContent";
import Tooltip from "components/Tooltip";

import * as styles from "./styles";
import { TokenTooltipProps } from "./types";

const TokenTooltip = ({
  name,
  imageUrl,
  supply,
  setShowTokenDetailsModal,
  showDetails,
  as = "span",
  children,
}: TokenTooltipProps) => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const Tag = as as keyof JSX.IntrinsicElements;

  return (
    <Tooltip
      skin="community"
      visible={visible}
      onClickOutside={hide}
      maxWidth={308}
      interactive
      content={
        <TokenTooltipContent
          name={name}
          imageUrl={imageUrl}
          supply={supply}
          setShowTokenDetailsModal={setShowTokenDetailsModal}
          showDetails={showDetails}
          viewDetailsOnClickHandler={() => setVisible(false)}
        />
      }
    >
      <Tag css={styles.content} onClick={visible ? hide : show}>
        {children}
      </Tag>
    </Tooltip>
  );
};

export default memo(TokenTooltip);
