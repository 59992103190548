import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  position: sticky;
  top: 84px;

  ${theme.mediaQueries.mediumUp} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  ${theme.mediaQueries.smallOnly} {
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 20px 15px 20px;

    margin-left: -20px;
    width: calc(100% + 40px);
  }

  a {
    transition: background-color 0.6s ${theme.animations.easeOutQuart};

    // display: block;
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: ${theme.text.sizes.medium};
    font-weight: ${theme.text.fontWeights.bold};
    color: ${theme.text.colors.primary};
    border-radius: ${theme.buttons.primary.border.radius};
    padding: 12px 20px;

    ${theme.mediaQueries.mediumUp} {
      width: 100%;
    }

    ${theme.mediaQueries.smallOnly} {
      display: inline-flex;
    }

    svg {
      width: 20px;
      height: auto;
      max-height: 20px;
    }

    &.active {
      background-color: ${theme.invertedBackground.colors.primary};
      color: ${theme.invertedText.colors.primary};

      path {
        fill: white;
      }
    }

    @media (hover: hover) {
      &:not(.active):hover {
        background-color: ${theme.background.colors.secondary};
      }
    }
  }
`;
