export const SUPPORTED_IMAGE_TYPES = ["image/jpeg", "image/png"];
export const SUPPORTED_VIDEO_TYPES = ["video/mp4", "video/webm"];
export const SUPPORTED_MEDIA_TYPES = [
  ...SUPPORTED_IMAGE_TYPES,
  ...SUPPORTED_VIDEO_TYPES,
];

export type FileImageResolution = {
  width: number;
  height: number;
};

export const getImageResolution = async (
  file: File
): Promise<FileImageResolution> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e?.target?.result as string;
      image.onload = function () {
        resolve({ width: image.width, height: image.height });
      };
    };
  });
};

export const fileHasExtensions = (fileName: string, extensions: string[]) => {
  const fileExtension = fileName.split(".").pop();
  return fileExtension && extensions.includes(fileExtension.toLowerCase());
};
