import React, { memo, useCallback, useEffect } from "react";

import { ethers } from "ethers";
import { useNavigate, useParams } from "react-router-dom";

import { useCreatedCommunityIncomeQuery } from "apollo/queries/communities.graphql.generated";
import {
  useGenerateDashboardLinkLazyQuery,
  useGenerateOnboardingLinkLazyQuery,
  useSyncStripeAccountMutation,
} from "apollo/queries/stripeAccount.graphql.generated";
import Button from "components/Button";
import ButtonExternalLink from "components/ButtonLink/ButtonExternalLink";
import CardContainer from "components/CardContainer";
import { AppSEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE, FEATURE_FLAG, EXTERNAL_URL } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";
import useSession from "hooks/useSession";
import { logError } from "services/logger";
import { currencyCode } from "utils/formatting/currency";

import * as baseStyles from "../styles";

import {
  EthIncomeSection,
  OtherCryptoIncomeSection,
  FiatIncomeRow,
} from "./IncomeSection";
import {
  EthEarningsTooltip,
  UsdEarningsTooltip,
  OtherCyptoEarningsTooltip,
} from "./IncomeTooltip";
import { WithdrawRoyaltiesSection } from "./WithdrawSection";
import * as styles from "./styles";

const shouldSyncStripe = () => {
  const params = new URLSearchParams(window.location.search);
  return !!params.get("syncStripe");
};

const useLogic = () => {
  const { communitySlug } = useParams();

  const showStripeConnectOnboarding = useFeatureFlag(
    FEATURE_FLAG.STRIPE_CONNECT_ONBOARDING
  );
  const showPage = useFeatureFlag(
    FEATURE_FLAG.ENABLE_CREATOR_DASHBOARD_INCOME_TAB
  );

  const allowToWithdraw = useFeatureFlag(FEATURE_FLAG.ENABLE_INCOME_WITHDRAWAL);
  const showWEthEarnings = useFeatureFlag(
    FEATURE_FLAG.SHOW_INCOME_WETH_EARNINGS
  );
  const showWUsdEarnings = useFeatureFlag(
    FEATURE_FLAG.SHOW_INCOME_USD_EARNINGS
  );

  const { loading, data, error, refetch } = useCreatedCommunityIncomeQuery({
    variables: {
      slug: communitySlug!,
    },
  });

  return {
    showStripeConnectOnboarding,
    showPage,
    allowToWithdraw,
    showWEthEarnings,
    showWUsdEarnings,

    isLoading: loading,
    refetchIncomeData: refetch,
    data,
    isError: !!error,
  };
};

const EditCommunityIncome = () => {
  const navigate = useNavigate();
  const {
    showStripeConnectOnboarding,
    showPage,
    allowToWithdraw,
    showWEthEarnings,
    showWUsdEarnings,

    isLoading,
    data,
    refetchIncomeData,
  } = useLogic();

  // Protect route if feature flag is off.
  useEffect(() => {
    if (showPage) return;
    navigate("/", { replace: true });
  }, [showPage]);

  const { user, refetchUser } = useSession();
  const { createdCommunities, stripeAccount } = user ?? {};
  const { communitySlug } = useParams();
  const communityId =
    createdCommunities?.find((community) => community.slug === communitySlug)
      ?.id || "";

  const [generateOnboardingLink] = useGenerateOnboardingLinkLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      window.open(data.generateOnboardingLink.url, "_blank");
    },
    variables: {
      communityId,
    },
  });

  const [generateStripeDashboardLink] = useGenerateDashboardLinkLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      window.open(data.generateDashboardLink.url, "_blank");
    },
  });

  const hasOnboardedStripeAccount =
    stripeAccount && stripeAccount.charges_enabled;
  const [syncStripeAccountMutation] = useSyncStripeAccountMutation();

  const syncStripeAccount = useCallback(() => {
    syncStripeAccountMutation()
      .then(async () => {
        await refetchUser();
      })
      .catch((error) => {
        logError(error);
      });
  }, []);

  const handleStripeConnectLinkClick = useCallback(() => {
    if (hasOnboardedStripeAccount) {
      generateStripeDashboardLink();
    } else {
      generateOnboardingLink();
    }
  }, []);

  useEffect(() => {
    if (shouldSyncStripe()) {
      syncStripeAccount();
    }
  }, []);

  const {
    royalties,
    creatorStripeBalance,
    creatorWethBalance,
    secondaryEarnings,
  } = data?.createdCommunityBySlug ?? {};
  const wethEarnings = [];

  if (creatorWethBalance?.value) {
    wethEarnings.push({
      title: "Total Primary Sales in ETH",
      coin: creatorWethBalance.coin,
      amount: creatorWethBalance.value,
      inUSD: creatorWethBalance.inUSD,
    });
  }
  const secondaryBalances = [];
  const availableToWithdraw = [];
  for (const royalty of royalties ?? []) {
    const { balance } = royalty;
    if (parseFloat(balance.value ?? "0") === 0) {
      continue;
    }
    const item = {
      coin: balance.coin,
      amount: balance.value,
      inUSD: balance.inUSD,
    };
    secondaryBalances.push(item);
    if (royalty.allowToWithdraw) {
      availableToWithdraw.push(item);
    }
  }
  if (!secondaryBalances.find((x) => x.coin === "WETH")) {
    secondaryBalances.push({
      coin: "WETH",
      amount: "0",
      inUSD: 0,
    });
  }

  const otherCryptoEarnings = [];

  for (const earning of secondaryEarnings ?? []) {
    const item = {
      coin: earning.coin,
      amount: earning.value,
      inUSD: earning.inUSD,
    };
    const existingIndex: number = secondaryBalances.findIndex(
      (x) => x.coin === item.coin
    );
    if (existingIndex === -1) {
      secondaryBalances.push(item);
      continue;
    }
    const existing: typeof item = secondaryBalances[existingIndex];
    // sum  up amounts
    const inUSD = (existing.inUSD ?? 0) + (item.inUSD ?? 0);
    const amount = ethers.utils
      .parseEther(existing.amount)
      .add(ethers.utils.parseEther(item.amount));

    secondaryBalances[existingIndex] = {
      ...existing,
      amount: ethers.utils.formatEther(amount),
      inUSD,
    };
  }

  for (const balance of secondaryBalances) {
    const item = {
      ...balance,
      title: `Total Secondary Sales Royalties in ${currencyCode(balance.coin)}`,
    };

    if (item.coin === "WETH") {
      wethEarnings.push(item);
    } else {
      otherCryptoEarnings.push(item);
    }
  }

  const LinkStripeAccountButton = () => (
    <Button onClick={handleStripeConnectLinkClick}>Link Stripe Account</Button>
  );

  if (isLoading) {
    return (
      <>
        <div css={baseStyles.titleRow}>
          <Title size="large">Income</Title>
        </div>
        <div css={baseStyles.loadingIconContainerCentered}>
          <LoadingIcon />
        </div>
      </>
    );
  }

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.EDITCOMMUNITY_INCOME} />

      <div>
        <div css={baseStyles.titleRow}>
          <Title size="large">Income</Title>
        </div>
        {(!showStripeConnectOnboarding || !hasOnboardedStripeAccount) && (
          <CardContainer background="secondary" extraCss={styles.cardContainer}>
            <Title size="small">
              Link a Stripe Account to Receive Credit Card Payments
            </Title>
            {showStripeConnectOnboarding ? (
              <>
                <Text>
                  Get started by minting a membership token for your community.
                  This is what people will need to access your community.
                </Text>
                <div css={styles.buttonContainer}>
                  <LinkStripeAccountButton />
                </div>
              </>
            ) : (
              <div css={styles.contactUsContainer}>
                <Text>Contact us to get your stripe account set up.</Text>
                <ButtonExternalLink
                  href={EXTERNAL_URL.SUPPORT_STRIPE_FORM}
                  target="_blank"
                  rel="noreferrer"
                  size="medium"
                  fluid={false}
                  extraCss={styles.contactUsButton}
                >
                  Contact Us
                </ButtonExternalLink>
              </div>
            )}
          </CardContainer>
        )}
        {allowToWithdraw && availableToWithdraw.length !== 0 && (
          <CardContainer background="secondary" extraCss={styles.cardContainer}>
            <div css={styles.titleWithTooltip}>
              <Title size="small">Secondary Royalties to Withdraw</Title>
              <span> </span>
            </div>
            <Text>
              When someone sells one of your tokens on a secondary market like
              OpenSea, you earn a percentage of that sale. These funds must be
              withdrawn to your wallet here.
            </Text>
            <WithdrawRoyaltiesSection
              items={availableToWithdraw}
              communityId={communityId}
              refetchIncomeData={refetchIncomeData}
            />
          </CardContainer>
        )}
        {showWEthEarnings && (
          <CardContainer background="secondary" extraCss={styles.cardContainer}>
            <div css={styles.titleWithTooltip}>
              <Title size="small">ETH Earnings</Title>
              <EthEarningsTooltip />
            </div>
            {!wethEarnings.length ? (
              <Text textAlign="left">
                You don’t have any earnings in ETH yet. Once you do, you a
                summary of those earnings will appear here.
              </Text>
            ) : (
              <EthIncomeSection items={wethEarnings} />
            )}
          </CardContainer>
        )}
        {showWEthEarnings && otherCryptoEarnings.length !== 0 && (
          <CardContainer background="secondary" extraCss={styles.cardContainer}>
            <div css={styles.titleWithTooltip}>
              <Title size="small">Other Crypto Earnings</Title>
              <OtherCyptoEarningsTooltip />
            </div>
            <OtherCryptoIncomeSection items={otherCryptoEarnings} />
          </CardContainer>
        )}

        {hasOnboardedStripeAccount && showWUsdEarnings && (
          <CardContainer background="secondary" extraCss={styles.cardContainer}>
            <div css={styles.titleWithTooltip}>
              <Title size="small">USD Earnings</Title>
              <UsdEarningsTooltip />
            </div>
            {creatorStripeBalance && (
              <>
                <FiatIncomeRow
                  amount={creatorStripeBalance.total}
                  currency={creatorStripeBalance.currency}
                >
                  <Text bold>Total Primary Sales in USD</Text>
                </FiatIncomeRow>
                <FiatIncomeRow
                  amount={creatorStripeBalance.processing}
                  currency={creatorStripeBalance.currency}
                >
                  <div css={styles.flexColumn}>
                    <div>Processing</div>
                    <button
                      css={styles.smallBlueButton}
                      onClick={handleStripeConnectLinkClick}
                    >
                      Check Status on Stripe
                    </button>
                  </div>
                </FiatIncomeRow>
              </>
            )}
          </CardContainer>
        )}

        {showStripeConnectOnboarding && (
          <ul css={styles.fields}>
            <li>
              <Title size="xsmall">Stripe Connect</Title>
              <div css={styles.accountLinkedInput}>
                <Text as="p">
                  {hasOnboardedStripeAccount
                    ? `Stripe Account Linked`
                    : `No Account Linked`}
                </Text>
                <button
                  css={styles.smallBlueButton}
                  onClick={handleStripeConnectLinkClick}
                >
                  {!hasOnboardedStripeAccount
                    ? `Link Account`
                    : `View Dashboard`}
                </button>
              </div>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default memo(EditCommunityIncome);
