import React, { FC, memo, PropsWithChildren } from "react";

import { Interpolation, Theme } from "@emotion/react";

import {
  StandardSizesKeys,
  OnBackgroundTextColorsKeys,
  TextColorKeys,
} from "styles/theme";
import { TitleTag } from "styles/theme/theme.types";

import * as systemStyles from "./styles";

export type SystemTitleProps = {
  color?: TextColorKeys;
  onBackgroundColor?: OnBackgroundTextColorsKeys;
  inverted?: boolean;
  centered?: boolean;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
  size?: StandardSizesKeys;
  as?: TitleTag;
  /**
   * @deprecated Use "extraCss" instead.
   */
  additionalCss?: Interpolation<Theme>;
};

const SystemTitle: FC<PropsWithChildren<SystemTitleProps>> = ({
  children,
  size = "medium",
  color = "primary",
  as = "h2",
  inverted = false,
  centered = false,
  onBackgroundColor,
  extraCss,
  additionalCss,
  ...rest
}) => {
  const Tag = as as keyof JSX.IntrinsicElements;
  return (
    <Tag
      css={[
        systemStyles.base,
        systemStyles.bySize(size),
        systemStyles.byColor(color, inverted),
        onBackgroundColor &&
          systemStyles.byBackgroundTextColor(onBackgroundColor),
        centered ? "text-align: center;" : null,
        additionalCss ? additionalCss : null,
        extraCss,
      ]}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default memo(SystemTitle);
