import { css, Theme } from "@emotion/react";

import { bottomMargin, topMargin } from "styles/global/layout";

export const benefitsContainer = (theme: Theme) => css`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: ${theme.verticalSpaces.large[4]};
  ${topMargin("5/12px")};
  ${bottomMargin("10/40px")};
  padding: 0;

  ${theme.mediaQueries.smallOnly} {
    flex-direction: column;
    gap: ${theme.verticalSpaces.large[2]};
  }
`;

export const spacedFields = css`
  ${bottomMargin("10/40px")};
`;
