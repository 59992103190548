import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const container = (theme: Theme) => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 24px;

  @media (hover: hover) {
    &:hover {
      svg {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
      }

      box-shadow: ${theme.shadows["lg"]};
    }
  }
`;

export const button = css`
  height: 100%;
  background: transparent;
  text-align: left;
  padding: 0;
`;

export const title = css`
  display: flex;
  align-items: center;
`;

export const forwardArrowIcon = (theme: Theme) => css`
  color: ${theme.text.colors.primary};
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  margin-left: auto;
  min-width: 23px;
  min-height: 21px;
`;

export const description = css`
  flex: 1;

  ${topMargin("4/8px")}
`;

export const benefitList = css`
  list-style: none;
  margin: 0;
  padding: 0;
  ${topMargin("8/24px")}

  > li:not(:first-of-type) {
    ${topMargin("4/8px")};
  }
`;

export const checkIcon = (theme: Theme) => css`
  path {
    color: ${theme.colorMap.affirmative.primary};
  }
  margin-right: 8px;
`;

export const xIcon = css`
  margin-right: 8px;
`;
