import { makeVar, ReactiveVar } from "@apollo/client";

import { TokenBenefit } from "apollo/graphql.generated";

export enum ModalType {
  LINK_DISCORD_ACCOUNT = "link-discard-account",
  UNLINK_DISCORD_ACCOUNT = "unlink-discard-account",
  UPDATE_EMAIL_ADDRESS = "update-email-address",
  UPDATE_USERNAME = "update-username",
  CHECK_YOUR_EMAIL = "check-your-email",
  OLD_SIGN_IN_OR_CREATE_ACCOUNT = "sign-in-or-create-account",
  CREATE_ACCOUNT = "create-account",
  SIGN_IN_ACCOUNT = "sign-in-account",
  CHECK_YOUR_EMAIL_MODAL = "check-your-email-modal",
  GENERIC_ERROR = "generic-error",
  EXPIRED_AUTH_SESSION = "expired-auth-session",
  CHOOSE_TOKEN_BENEFITS = "choose-token-benefits",
  CHOOSE_CUSTOM_BENEFITS = "choose-custom-benefits",
  TOKEN_QUANTITY_LEARN_MORE = "token-quantity-learn-more",
  CONFIRM_EXIT_TOKEN_DESIGN_PAGE = "confirm-exit-token-design-page",
  MAKE_OFFER = "make-offer",
  EDIT_OFFER = "edit-offer",
  CONFIRM_DELETE_POST = "confirm-delete-post",
  SHOPIFY_EDIT_STORE = "shopify-edit-store",
  SHOPIFY_UNLINK_STORE = "shopify-unlink-store",
  LINK_DISCORD_SERVER = "link-discord-server-modal",
  UNLINK_DISCORD_SERVER = "unlink-discord-server-modal",
  CREATE_CLAIM_PAGE = "create-claim-page-modal",
  CONFIRM_DELETE_CLAIM_PAGE = "delete-claim-page-modal",
  SEND_TOKEN_TO_MEMBERS = "send-token-to-members",
  MINTING_IN_PROGRESS = "minting-in-progress",
  FINISHED_MINT = "finished-mint",
  UPDATE_PHONE_NUMBER = "update-phone-number",
  VERIFY_PHONE_NUMBER = "verify-phone-number",
  OUTDATED_CLIENT_VERSION = "outdated-client-version",
  CRYPTO_PAYMENT = "crypto-payment",
  POST_EDITOR_TOKEN_SELECT = "post-editor-token-select",
  COMMUNITY_LAUNCHED = "community-launched",
  SYNC_USER_DISCORD = "sync-user-discord",
}

// TODO: token minting modals - refactor this into context provider or different state management
export type Modal<T> = {
  showModal: ModalType | null;
  showView: string | null;
  data: T;
  updateBenefit: ((benefitType: TokenBenefit, add: boolean) => boolean) | null;
  onClose?: () => void;
};

// TODO: To avoid having to edit all modals and create types for them I added any, but we should use unknown.
/* eslint-disable  @typescript-eslint/no-explicit-any */
const emptyModal: Modal<any> = {
  showModal: null,
  showView: null,
  data: null,
  updateBenefit: null,
};

// TODO: To avoid having to edit all modals and create types for them I added any, but we should use unknown.
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const modalVar = makeVar<Modal<any>>(emptyModal);

export const resetModalVar = () => {
  // TODO: update flow so the onClose() logic runs here as well
  modalVar(emptyModal);
};

export const setModalVar = <T>(data: Partial<Modal<T>>) => {
  modalVar({ ...emptyModal, ...data });
};

export const updateModalVar = <T>(data: Partial<Modal<T>>) => {
  if (modalVar().showModal != data.showModal) {
    modalVar({ ...modalVar(), ...data });
  }
};

/**
 * Modal data types
 */
export type ModalWithoutTypeData = Record<string, string | boolean | string[]>;
export type ModalWithoutType = ReactiveVar<Modal<ModalWithoutTypeData>>;

// POST_EDITOR_TOKEN_SELECT
export type ModalPostEditorTokenSelectData = {
  insertToken: (id: string) => void;
};
export type ModalPostEditorTokenSelect = ReactiveVar<
  Modal<ModalPostEditorTokenSelectData>
>;

// CHECK_YOUR_EMAIL_MODAL
export type ModalCheckYourEmailData = {
  loginEmail?: string;
};
export type ModalCheckYourEmail = ReactiveVar<Modal<ModalCheckYourEmailData>>;
