import { css, Theme } from "@emotion/react";

import { topMargin, bottomMargin } from "styles/global/layout";

// some unideal css tricks to make the absolute blurred image
// stretch across the length of the page,
// despite being a child row in the default grid of <Layout />
// Layout navbar is 76px on small media queries, else 86px
export const bannerImage = (height: number) => (theme: Theme) =>
  css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${height + 84 + 96}px;
    z-index: 0;
    background-color: ${theme.community.primaryBackground.backgroundColor};
    overflow: hidden;

    img {
      object-position: center;
      object-fit: cover;
      width: 110%;
      height: 110%;

      margin: -6px;
      padding: -6px;
    }

    ${theme.mediaQueries.smallOnly} {
      height: ${height + 76 + 96}px;
    }
  `;
export const bannerImageBlur = (height: number) => (theme: Theme) =>
  css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${height + 84 + 96}px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(100px);
    overflow: hidden;

    img {
      object-position: center;
      object-fit: cover;
      width: 110%;
      height: 110%;

      filter: blur(5px);

      margin: -6px;
      padding: -6px;
    }

    ${theme.mediaQueries.smallOnly} {
      height: ${height + 76 + 96}px;
    }
  `;

export const header = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  z-index: 1;
  ${bottomMargin("15/96px")}
`;

export const headerLayout = (theme: Theme) => css`
  display: grid;
  width: 100%;
  grid-row-gap: ${theme.verticalSpaces.large[8]};
  grid-column-gap: ${theme.layout.gridColumnWidth};
  justify-content: center;
  ${bottomMargin("6/16px")}

  ${theme.mediaQueries.mediumOnly} {
    grid-template-columns: ${theme.layout.gridColumns(12)};
  }
  ${theme.mediaQueries.smallOnly} {
    grid-template-columns: ${theme.layout.gridColumns(12)};
  }
`;

export const headerCountdownLayout = (theme: Theme) => css`
  grid-template-columns: ${theme.layout.gridColumns(6, 6)};
`;

export const headerReleasedLayout = (theme: Theme) => css`
  grid-template-columns: ${theme.layout.gridColumns(12)};
`;

export const headerContainer = css`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${bottomMargin("6/16px")}
`;

export const image = (theme: Theme) => css`
  border-radius: 2%;
  object-fit: cover;
  z-index: 1;
  overflow: hidden;
  width: 400px;
  height: 400px;

  ${theme.mediaQueries.smallOnly} {
    width: 120px;
    height: 120px;
    margin-top: -24px;
  }
`;

export const communityAvatar = css`
  z-index: 1;
  span {
    color: white;
  }
`;

export const title = css`
  text-align: center;
  color: white;

  ${topMargin("4/8px")}
`;

export const timeSection = css`
  text-align: center;
  ${topMargin("4/8px")}

  p {
    color: white;
  }
`;

export const descriptionSection = css`
  text-align: center;
  ${topMargin("9/32px")}

  span {
    color: white;
  }
  p div {
    color: white;
  }
`;

export const lockedIcon = css`
  margin-top: -2px;
  margin-right: 8px;
  svg {
    color: white;
  }
`;

export const lockedText = css`
  color: white;
`;

export const unlockedIcon = css`
  margin-top: -2px;
  margin-right: 8px;
  svg {
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const unlockedText = css`
  color: rgba(255, 255, 255, 0.6);
`;

export const secretMessageText = css`
  color: white;
  overflow-wrap: break-word;
  text-align: left;
`;

export const secretMessageSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;

  background-color: rgba(255, 255, 255, 0.1);
  ${topMargin("9/32px")}
`;

export const secretMessageRow = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
