import { css, Theme } from "@emotion/react";

import {
  defaultTheme,
  OnBackgroundTextColorsKeys,
  TextColorKeys,
  TextSizesKeys,
} from "styles/theme";

import { CommunityThemeTextColorsKeys } from "../theme/communityTheme.types";
import { ThemeType } from "../theme/theme.types";

export const typographyStyles = `
p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

a {
  text-decoration: none;
  cursor: pointer;
}

h1,
.titleLarge {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
}

h2,
.titleMedium {
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
}

h3,
.titleSmall {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

h4,
.textLarge {
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
}

h5,
.textMedium {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

h6,
.textSmall {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.labelSmall {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}
`;

export const textSmallStyles = (theme: Theme) => css`
  font-weight: ${theme.text.fontWeights.normal};
  font-size: ${theme.text.sizes.small};
  line-height: ${theme.text.lineHeights.small};
`;

export const textMediumStyles = (theme: Theme) => css`
  font-weight: ${theme.text.fontWeights.normal};
  font-size: ${theme.text.sizes.medium};
  line-height: ${theme.text.lineHeights.medium};
`;

export const textLargeStyles = (theme: Theme) => css`
  font-weight: ${theme.text.fontWeights.normal};
  font-size: ${theme.text.sizes.large};
  line-height: ${theme.text.lineHeights.large};
`;

//TODO: Remove specific styles above and reuse this one only to reduce code
export const textStyles =
  (size: TextSizesKeys, bold: boolean) => (theme: Theme) =>
    css`
      font-family: ${theme.text.fontFamily.primary};
      text-transform: ${theme.text.textTransform};
      font-size: ${theme.text.sizes[size]};
      line-height: ${theme.text.lineHeights[size]};
      letter-spacing: ${theme.text.letterSpacing[size]};
      font-weight: ${bold
        ? theme.text.fontWeights.bold
        : theme.text.fontWeights.normal};
    `;

export const baseTextColor =
  (color: TextColorKeys, inverted: boolean) => (theme: Theme) =>
    css`
      color: ${inverted
        ? theme.invertedText.colors[color]
        : theme.text.colors[color]};
    `;

export const communityTextColor =
  (color: CommunityThemeTextColorsKeys) => (theme: Theme) =>
    css`
      color: ${theme.community.text[color]};
    `;

export const baseBackgroundTextColor =
  (color: OnBackgroundTextColorsKeys) => (theme: Theme) =>
    css`
      color: ${theme.text.colors.onBackground[color]};
    `;

export const systemTextLink = css`
  color: ${defaultTheme.colorMap.accent.default};
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const communityTextLink = css`
  color: inherit;
  text-decoration: underline;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const paragraphs = css`
  display: block;
  margin-block-start: 0;

  &:not(:last-of-type) {
    margin-block-end: 0.75rem;
  }
`;

export const textWithLink = css`
  p {
    ${paragraphs};
  }

  a {
    ${systemTextLink};
  }
`;

export const innerHTML = (themeType: ThemeType) => css`
  * {
    font-family: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
  }

  p {
    ${paragraphs};
  }

  a {
    ${themeType === "system" ? systemTextLink : communityTextLink};
  }

  ul {
    list-style-type: disc;
    margin-block-start: 0.75rem;
    margin-block-end: 0.75rem;

    li {
      margin-block-start: 0.2em;

      ul {
        list-style-type: disc;
        margin-block-start: 0.3em;
        margin-block-end: 0.5em;
      }
    }
  }
`;
