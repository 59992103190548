import React, { FC, useEffect, useCallback, useMemo } from "react";

import { useReactiveVar } from "@apollo/client";

import { communityVar } from "apollo/reactive";
import ButtonModalClose from "components/ButtonModalClose";
import CommunityAvatarSmall from "components/CommunityAvatarSmall";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { ANALYTICS_EVENT, EXTERNAL_URL, MEMBERSHIP_TOKEN_ALT } from "config";
import useSegmentEvents from "hooks/useSegmentEvents";
import useSession from "hooks/useSession";
import useLockedBody from "utils/hooks/useLockedBody";

import TokenImage from "../TokenImage";
import TokenTag from "../TokenTag";

import * as styles from "./styles";
import { TokenDetailsModalProps } from "./types";

const TokenDetailsModal: FC<TokenDetailsModalProps> = ({
  name,
  imageUrl,
  supply,
  tokenId,
  contractAddress,
  setShowTokenDetailsModal,
}) => {
  const { trackEvent } = useSegmentEvents();
  const { isCommunityMember } = useSession();

  const communityData = useReactiveVar(communityVar);
  const isMember = isCommunityMember(communityData.id);

  const ContractAddress = useMemo(() => {
    if (!contractAddress) return;

    const reducedAddress =
      contractAddress.substring(0, 10) +
      "..." +
      contractAddress.substring(contractAddress.length - 5);

    const link = `${EXTERNAL_URL.POLYGONSCAN}/${contractAddress}`;
    return (
      <a
        title={contractAddress}
        href={link}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          const properties = {
            blockchain_public_address: contractAddress,
          };

          trackEvent(ANALYTICS_EVENT.POLYGONSCAN_LINK_OPEN, properties);
        }}
      >
        {reducedAddress}
      </a>
    );
  }, [contractAddress]);

  const handleTokenDetailsModalVisibility = () =>
    setShowTokenDetailsModal(false);

  const closeModalOnEscKey = useCallback((event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      setShowTokenDetailsModal(false);
    }
  }, []);

  const [, setLocked] = useLockedBody();

  useEffect(() => {
    setLocked(true);
    return () => {
      setLocked(false);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", closeModalOnEscKey);
    return () => {
      document.removeEventListener("keydown", closeModalOnEscKey);
    };
  }, [closeModalOnEscKey]);

  return (
    <div css={styles.modal}>
      <ButtonModalClose onClick={handleTokenDetailsModalVisibility} />
      <div css={styles.container}>
        <div css={styles.content}>
          <div css={styles.header}>
            <CommunityAvatarSmall
              overwriteTheme="system"
              additionalCss={styles.headerCreatorAvatar}
              imageUrl={communityData?.creatorAvatar?.imageUrl}
              name={communityData?.creatorAvatar?.name}
            />
            <Title additionalCss={styles.headerTitle} size="large">
              {name}
            </Title>

            {isMember && supply && (
              <TokenTag additionalCss={styles.headerTokenTag} supply={supply} />
            )}
          </div>

          <div css={styles.tokenImage}>
            <TokenImage
              imageUrl={imageUrl}
              alt={MEMBERSHIP_TOKEN_ALT}
              transparentBackground={false}
            />
          </div>

          <div css={styles.tokenDetails}>
            <Text
              as="p"
              size="medium"
              bold={true}
              additionalCss={styles.tokenDetailsListTitle}
            >
              Token Details
            </Text>

            <Text as="div" additionalCss={styles.tokenDetailsList}>
              <ul>
                <li>
                  <span>Contract Address</span> <span>{ContractAddress}</span>
                </li>
                <li>
                  <span>Blockchain</span>{" "}
                  <span>{communityData.networkName}</span>
                </li>
                <li>
                  <span>Edition</span> <span>{supply}</span>
                </li>
                <li>
                  <span>Token ID</span> <span>{tokenId}</span>
                </li>
              </ul>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenDetailsModal;
