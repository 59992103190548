import { FC, memo } from "react";

import * as styles from "./styles";
import { Props } from "./types";

const Switch: FC<Props> = ({ ...rest }) => (
  <label css={styles.switchContainer}>
    <input type="checkbox" {...rest} />
    <span css={[styles.slider, styles.round]} />
  </label>
);

export default memo(Switch);
