import React, { memo, useState } from "react";

import { Global } from "@emotion/react";

import BackgroundImage from "assets/images/background-gradient-no-grain.jpg";
import CardContainer from "components/CardContainer";
import CreateAccount from "components/CreateAccount";
import Layout from "components/Layout";
import { AppSEO } from "components/Layout/SEO";
import SignInAccount from "components/SignInAccount";
import { SYSTEM_PAGE_TITLE } from "config";
import { AuthFormProvider } from "hooks/useAuthForm";

import * as styles from "./styles";

const SignIn = () => {
  const [viewSignIn, setViewSignIn] = useState<boolean>(true);

  return (
    <>
      <AppSEO
        title={
          viewSignIn
            ? SYSTEM_PAGE_TITLE.SIGN_IN
            : SYSTEM_PAGE_TITLE.CREATE_ACCOUNT
        }
      />

      <Global styles={styles.signInOrCreateAccountGlobalStyles} />

      <Layout
        navLayout="signin"
        css={styles.container}
        backgroundShow={true}
        backgroundColor="secondary"
        backgroundImageUrl={BackgroundImage}
      >
        <AuthFormProvider>
          <CardContainer shadow="sm" additionalCss={styles.CardContainer}>
            {viewSignIn ? (
              <SignInAccount
                isModal={false}
                viewSignIn={viewSignIn}
                setViewSignIn={setViewSignIn}
              />
            ) : (
              <CreateAccount
                isModal={false}
                viewSignIn={viewSignIn}
                setViewSignIn={setViewSignIn}
              />
            )}
          </CardContainer>
        </AuthFormProvider>
      </Layout>
    </>
  );
};

export default memo(SignIn);
