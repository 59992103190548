import { Token, TokenType, UserToken } from "apollo/graphql.generated";
import { CommunityBenefitFragment } from "apollo/queries";
import { Maybe } from "utils/types";

//TODO: Those normalizations can be removed if both token have the same types and attribute names in their resolvers
type NormalizedToken = {
  tokenId?: number;
  uri?: Maybe<string>;
  supply?: Maybe<number>;
  name?: Maybe<string>;
  description?: Maybe<string>;
};

const normalizeUserToken = (
  userToken: Partial<UserToken>
): NormalizedToken => ({
  tokenId: userToken.tokenId,
  uri: userToken.imageUrl,
  supply: userToken.supply,
  name: userToken.name,
  description: userToken.description,
});

const normalizeCommunityToken = (
  communityToken: Partial<Token>
): NormalizedToken => ({
  tokenId: communityToken?.tokenId
    ? parseInt(communityToken?.tokenId)
    : undefined,
  uri: communityToken?.uri,
  supply: communityToken?.supply,
  name: communityToken?.name,
  description: communityToken?.description,
});

const normalizeTokenId = (tokenId: string | number) =>
  typeof tokenId === "string" ? parseInt(tokenId) : tokenId;

const getLatestIssuedToken = <T extends UserToken | Token>(
  tokens: T[],
  type: TokenType
): T | undefined =>
  tokens
    .filter((token) => token.tokenType === type)
    .sort(
      (tokenA, tokenB) =>
        normalizeTokenId(tokenB.tokenId) - normalizeTokenId(tokenA.tokenId)
    )[0];

const resolveBenefitToken = (benefit?: CommunityBenefitFragment) => {
  const unlockedByTokens = benefit?.unlockedBy;
  const unlockableByTokens = benefit?.unlockableBy;

  return unlockedByTokens?.length
    ? normalizeUserToken(unlockedByTokens[0])
    : unlockableByTokens?.length
    ? normalizeCommunityToken(unlockableByTokens[0])
    : null;
};

export { resolveBenefitToken, getLatestIssuedToken };

export type { NormalizedToken };
