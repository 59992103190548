import React, { memo, useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useUpdateEmailPreferenceMutation } from "apollo/queries";
import Button from "components/Button";
import ErrorLinkContainer from "components/ErrorLinkContainer";
import { AppSEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import Switch from "components/Switch";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE, FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";
import useSession from "hooks/useSession";

import * as styles from "./styles";

type EmailPreferenceType = {
  marketing: boolean;
  community: boolean;
};

const MyAccountEmailPreferences = () => {
  const navigate = useNavigate();
  const { user, refetchUser } = useSession();

  const [marketingLoading, setMarketingLoading] = useState<boolean>(false);
  const [communityLoading, setCommunityLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [doUpdateEmailPreferenceMutation] = useUpdateEmailPreferenceMutation();

  const handleMarketingUpdate = useCallback(
    (emailPreference: EmailPreferenceType) => () => {
      setMarketingLoading(true);
      updateEmailPreference({
        ...emailPreference,
        marketing: !emailPreference.marketing,
      }).then(() => setMarketingLoading(false));
    },
    []
  );

  const handleCommunityUpdate =
    (emailPreference: EmailPreferenceType) => () => {
      setCommunityLoading(true);
      updateEmailPreference({
        ...emailPreference,
        community: !emailPreference.community,
      }).then(() => setCommunityLoading(false));
    };

  const handleUnsubscribe = () => {
    setMarketingLoading(true);
    setCommunityLoading(true);
    updateEmailPreference({ marketing: false, community: false }).then(() => {
      setMarketingLoading(false);
      setCommunityLoading(false);
    });
  };

  const updateEmailPreference = async (
    emailPreference: EmailPreferenceType
  ) => {
    try {
      await doUpdateEmailPreferenceMutation({
        variables: {
          emailPreference: {
            marketing: emailPreference.marketing,
            community: emailPreference.community,
          },
        },
      });
      refetchUser();
    } catch (e) {
      setError("Something went wrong. Please try again.");
    }
  };

  const showPage = useFeatureFlag(
    FEATURE_FLAG.ENABLE_ACCOUNT_EMAIL_PREFERENCES_PAGE
  );

  // Protect route if feature flag is off.
  useEffect(() => {
    if (showPage) return;
    navigate(-1);
  }, [showPage]);

  if (!user) {
    return (
      <div>
        <LoadingIcon />
      </div>
    );
  }

  const { emailPreference } = user;
  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.ACCOUNT_EMAIL_PREFERENCES} />

      <Title size="large">Email Preferences</Title>

      <div css={styles.fields}>
        <Text bold>Choose Which Emails you’d like to receive</Text>
        <ul>
          <li>
            <div>
              <Text size="medium" bold as="label">
                Marketing emails from Highlight
                <Text size="small" color="secondary">
                  News and information about new features
                </Text>
              </Text>
              {marketingLoading && <LoadingIcon />}
              {!marketingLoading && (
                <Switch
                  onChange={handleMarketingUpdate(emailPreference)}
                  checked={emailPreference.marketing}
                />
              )}
            </div>
          </li>
          <li>
            <div>
              <Text size="medium" bold as="label">
                Community updates
                <Text size="small" color="secondary">
                  Notifications and updates from communities you belong to
                </Text>
              </Text>
              {communityLoading && <LoadingIcon />}
              {!communityLoading && (
                <Switch
                  onChange={handleCommunityUpdate(emailPreference)}
                  checked={emailPreference.community}
                />
              )}
            </div>
          </li>
        </ul>
        <Button
          disabled={marketingLoading || communityLoading}
          onClick={handleUnsubscribe}
        >
          Unsubscribe From All
        </Button>
        {error && (
          <ErrorLinkContainer
            additionalCss={styles.errorContainer}
            onClick={() => setError("")}
          >
            <Text size="small">Something went wrong. Please try again.</Text>
          </ErrorLinkContainer>
        )}
      </div>
    </>
  );
};

export default memo(MyAccountEmailPreferences);
