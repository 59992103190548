import React, { lazy, Suspense, useEffect } from "react";

import { useReactiveVar } from "@apollo/client";
import { Global, ThemeProvider } from "@emotion/react";
import useGaTracker from "@hl/shared-features/lib/hooks/useGaTracker";
import { Route, Routes } from "react-router-dom";
import { Metric } from "web-vitals";

import CommunitySwitch from "components/CommunitySwitch";
import LocalDefaultSlug from "components/LocalDefaultSlug";
import ReleasePartySwitch from "components/ReleasePartySwitch";
import {
  APP_URL,
  COMMUNITY_URL,
  GOOGLE_ANALYTICS_ID,
  IN_DEVELOPMENT,
} from "config";
import AccountLayout from "containers/App/Account/AccountLayout";
import AccountSettings from "containers/App/Account/AccountSettings/MyAccountSettings";
import MyAccountEmailPreferences from "containers/App/Account/EmailPreferences/MyAccountEmailPreferences";
import TransactionHistory from "containers/App/Account/TransactionHistory/MyAccountTransactionHistory";
import AccountWallet from "containers/App/Account/Wallet/AccountWallet";
import EditCommunityLayout from "containers/App/EditCommunity/EditCommunityLayout";
import EditCommunityHome from "containers/App/EditCommunity/Home/EditCommunityHome";
import MyCommunityIncome from "containers/App/EditCommunity/Income/EditCommunityIncome";
import MyCommunityMembers from "containers/App/EditCommunity/Members/EditCommunityMembers";
import MyCommunityPosts from "containers/App/EditCommunity/Posts/EditCommunityPosts";
import MyCommunityTheme from "containers/App/EditCommunity/Theme/EditCommunityTheme";
import EditCommunityTokens from "containers/App/EditCommunity/Tokens/EditCommunityTokens";
import NewCommunityLayout from "containers/App/NewCommunity/NewCommunityLayout";
import UserDashboard from "containers/App/UserDashboard/";
import Checkout from "containers/Checkout";
import CheckoutComplete from "containers/CheckoutComplete/";
import ClaimToken from "containers/ClaimToken";
import { AboutPage } from "containers/CommunityV3/AboutPage/AboutPage";
import DiscordPage from "containers/CommunityV3/DiscordPage/DiscordPage";
import ShopPage from "containers/CommunityV3/ShopPage/ShopPage";
import TokenDetail from "containers/CommunityV3/TokenDetail/TokenDetail";
import { ContentFeedPage } from "containers/ContentFeedPage";
import ContestSwitch from "containers/ContestEntry/ContestSwitch";
import DiscordLinkCallback from "containers/Discord";
import NotFound from "containers/Error/NotFound";
import ServerError from "containers/Error/ServerError";
import Root from "containers/Root";
import SignOut from "containers/SignOut";
import useBootstrap from "hooks/useBootstrap";
import { useSegmentTracker } from "hooks/useSegmentEvents";
import useUserPendingTokens from "hooks/useUserPendingTokens";
import reportWebVitals from "services/reportWebVitals";
import { setUpSardine } from "services/sardine";
import "styles/_variables.scss";
import { globalStyles } from "styles/globalStyle";
import { defaultTheme } from "styles/theme";
import { updateCommunityOnDefaultTheme } from "styles/theme/communityTheme";

import { ModalType, modalVar } from "./apollo/reactive";
import ManageMintedToken from "./containers/App/EditCommunity/Tokens/NewManageToken/ManageMintedToken";
import ManagePendingToken from "./containers/App/EditCommunity/Tokens/NewManageToken/ManagePendingToken";
import NewMintToken from "./containers/App/EditCommunity/Tokens/NewMintToken";
import SendToken from "./containers/App/EditCommunity/Tokens/SendToken";
import PurchaseToken from "./containers/PurchaseToken";

const OutdatedClientVersionModal = lazy(
  () => import("components/Modals/OutdatedClientVersion")
);

const App = () => {
  const { isMainApp, isCommunityApp, communityTheme } = useBootstrap();
  const { showModal } = useReactiveVar(modalVar);
  const { isInitialized, emitGaEvent } = useGaTracker(GOOGLE_ANALYTICS_ID);
  useEffect(() => {
    IN_DEVELOPMENT &&
      console.log(
        isMainApp ? "🟢 App Initialized." : "🟢 Community App Inited."
      );

    setUpSardine();
  }, []);

  useEffect(() => {
    if (isInitialized) {
      reportWebVitals((x: Metric) => emitGaEvent(x));
    }
  }, [isInitialized]);

  useSegmentTracker({ isCommunityApp });
  useUserPendingTokens();

  return (
    <ThemeProvider
      theme={
        communityTheme
          ? updateCommunityOnDefaultTheme(communityTheme)
          : defaultTheme
      }
    >
      <Global styles={globalStyles} />

      <Suspense fallback={""}>
        <Routes>
          {/**
           * App Routes
           * */}

          {isMainApp && (
            <>
              <Route
                path={APP_URL.lobby}
                element={
                  <Root requiredAuth={true}>
                    <UserDashboard />
                  </Root>
                }
              />
              <Route
                path={APP_URL.newCommunity}
                element={
                  <Root requiredAuth={true}>
                    <NewCommunityLayout />
                  </Root>
                }
              />
              {/* @todo: Add lazy loading for better performance */}
              <Route
                path={APP_URL.editCommunity.base}
                element={
                  <Root requiredAuth={true}>
                    <EditCommunityLayout />
                  </Root>
                }
              >
                <Route
                  path={APP_URL.editCommunity.home}
                  element={<EditCommunityHome />}
                />
                <Route
                  path={APP_URL.editCommunity.tokens}
                  element={<EditCommunityTokens />}
                />
                <Route
                  path={APP_URL.editCommunity.posts}
                  element={<MyCommunityPosts />}
                />
                <Route
                  path={APP_URL.editCommunity.theme}
                  element={<MyCommunityTheme />}
                />
                <Route
                  path={APP_URL.editCommunity.members}
                  element={<MyCommunityMembers />}
                />
                <Route
                  path={APP_URL.editCommunity.income}
                  element={<MyCommunityIncome />}
                />
              </Route>
              <Route
                path={APP_URL.editCommunity.mintTokens}
                element={
                  <Root requiredAuth={true}>
                    <NewMintToken />
                  </Root>
                }
              />
              <Route
                path={APP_URL.editCommunity.manageMintedToken}
                element={
                  <Root requiredAuth={true}>
                    <ManageMintedToken />
                  </Root>
                }
              />
              <Route
                path={APP_URL.editCommunity.managePendingToken}
                element={
                  <Root requiredAuth={true}>
                    <ManagePendingToken />
                  </Root>
                }
              />
              <Route
                path={APP_URL.editCommunity.sendTokens}
                element={
                  <Root requiredAuth={true}>
                    <SendToken />
                  </Root>
                }
              />
            </>
          )}

          {/**
           * Community Routes
           */}

          {isCommunityApp && (
            <>
              {/* Community */}
              <Route path={COMMUNITY_URL.home} element={<CommunitySwitch />}>
                <Route path={COMMUNITY_URL.about} element={<AboutPage />} />
                <Route
                  path={COMMUNITY_URL.posts}
                  element={<ContentFeedPage />}
                />
                <Route path={COMMUNITY_URL.discord} element={<DiscordPage />} />
                <Route path={COMMUNITY_URL.shop} element={<ShopPage />} />
              </Route>
              <Route
                path={COMMUNITY_URL.tokenDetail}
                element={
                  <Root requiredAuth={false} theme="system">
                    <TokenDetail />
                  </Root>
                }
              />
              <Route
                path={COMMUNITY_URL.checkout}
                element={
                  <Root requiredAuth={true}>
                    <Checkout />
                  </Root>
                }
              />
              <Route
                path={COMMUNITY_URL.checkoutComplete}
                element={
                  <Root requiredAuth={true}>
                    <CheckoutComplete />
                  </Root>
                }
              />
              <Route
                path={`${COMMUNITY_URL.contest}/:contestId`}
                element={
                  <Root theme="community">
                    <ContestSwitch />
                  </Root>
                }
              />
              <Route
                path={`${COMMUNITY_URL.claim}/:claimId`}
                element={
                  <Root theme="community">
                    <ClaimToken />
                  </Root>
                }
              />
              <Route
                path={`${COMMUNITY_URL.purchase}/:offerId`}
                element={
                  <Root theme="community">
                    <PurchaseToken />
                  </Root>
                }
              />
              <Route
                path={`${COMMUNITY_URL.releaseParty}/:releaseSlug`}
                element={
                  <Root theme="community">
                    <ReleasePartySwitch />
                  </Root>
                }
              />
            </>
          )}

          {/**
           * SharedRoutes
           */}

          <Route
            path={`${COMMUNITY_URL.discordLinkCallback}`}
            element={<DiscordLinkCallback />}
          />

          {/* My Account */}

          <Route
            path={APP_URL.account.base}
            element={
              <Root requiredAuth={true}>
                <AccountLayout />
              </Root>
            }
          >
            <Route
              path={APP_URL.account.settings}
              element={<AccountSettings />}
            />
            <Route path={APP_URL.account.wallet} element={<AccountWallet />} />
            <Route
              path={APP_URL.account.transactionHistory}
              element={<TransactionHistory />}
            />
            <Route
              path={APP_URL.account.emailPreferences}
              element={<MyAccountEmailPreferences />}
            />
          </Route>

          {/* Misc */}

          <Route path={COMMUNITY_URL.signout} element={<SignOut />} />
          {IN_DEVELOPMENT && (
            <Route
              path={APP_URL.local.setSlug}
              element={<LocalDefaultSlug />}
            />
          )}
          <Route
            path={COMMUNITY_URL.internalError}
            element={
              <Root>
                <ServerError />
              </Root>
            }
          />

          <Route
            path={"*"}
            element={
              <Root>
                <NotFound />
              </Root>
            }
          />
        </Routes>

        {showModal === ModalType.OUTDATED_CLIENT_VERSION && (
          <OutdatedClientVersionModal />
        )}
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
