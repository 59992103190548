import React, { FC, memo } from "react";

import Text, { RichText } from "components/Typography/Text";

import { TokenDetail } from "../types";

import * as styles from "./styles";

export type Props = {
  token?: TokenDetail;
};

const TokenDescription: FC<Props> = ({ token }) => {
  return (
    <Text
      as="div"
      onBackgroundColor="primary"
      additionalCss={styles.description}
    >
      <RichText text={token?.description} />
    </Text>
  );
};

export default memo(TokenDescription);
