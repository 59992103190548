import React, { FC } from "react";

import { useReactiveVar } from "@apollo/client";

import { communityVar } from "apollo/reactive";
import DistributionCard from "components/DistributionCard/DistributionCard";
import { CommunitySEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import { RichText } from "components/Typography/Text";
import CommunityText from "components/_Community/Text";
import { COMMUNITY_PAGE_TITLE } from "config";
import useSession from "hooks/useSession";
import { useAboutDistributionMethodsQuery } from "utils/distribution/distribution.graphql.generated";
import { toOfferDistribution } from "utils/distribution/distributionMethod";

import * as styles from "./styles";

export const AboutPage: FC = () => {
  const { slug, name, description } = useReactiveVar(communityVar);

  const { user } = useSession();
  const { data, loading } = useAboutDistributionMethodsQuery({
    variables: { slug },
  });

  if (loading) {
    return (
      <div css={styles.loadingIcon}>
        <LoadingIcon />
      </div>
    );
  }

  const offers =
    data?.community?.offers.filter((offer) => offer.tokens?.length) ?? [];

  const distributions = [
    ...toOfferDistribution(offers, user, data?.community?.id),
  ];

  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.ABOUT} />

      <div>
        <div
          css={[styles.layout("communityDescription"), styles.communitySection]}
        >
          <CommunityText>
            {" "}
            <RichText text={description} />
          </CommunityText>
        </div>
        <div css={styles.distributionCardsContainer}>
          {distributions.map((distribution) => (
            <DistributionCard
              key={distribution.distributionMethod.id}
              distribution={distribution}
              communityName={name}
              contractAddress={
                data?.community?.polygonContract?.contractAddress
              }
            />
          ))}
        </div>
      </div>
    </>
  );
};
