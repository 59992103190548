import React, { FC, PropsWithChildren, useState } from "react";

import { css, Interpolation, Theme } from "@emotion/react";

import { CreatorAvatar } from "apollo/graphql.generated";
import { layoutContext } from "apollo/reactive/layout";
import CardContainer from "components/CardContainer";
import CommunityAvatarSmall from "components/CommunityAvatarSmall";
import TextEditor from "components/ContentFeed/TextEditor";
import Text from "components/Typography/Text";
import CommunityCardContainer from "components/_Community/CardContainer";
import CommunityText from "components/_Community/Text";
import { FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";
import { TextSizesKeys, TextTag, ThemeType } from "styles/theme/theme.types";

import { PostFragment } from "../query.graphql.generated";

import { Attachment } from "./Attachment";
import { Edit } from "./Edit";
import { Reactions } from "./Reactions";
import * as styles from "./styles";

import { Pinned } from ".";

export type PostProps = {
  post: PostFragment;
  creatorAvatar?: CreatorAvatar;
  communitySlug: string;
  editableOption: boolean;
};

export const CardContainerByTheme: FC<
  PropsWithChildren<{ theme: ThemeType }>
> = ({ theme, children }) => {
  return theme === "system" ? (
    <CardContainer
      background="secondary"
      shadow="sm"
      extraCss={css`
        position: relative;
      `}
    >
      {children}
    </CardContainer>
  ) : (
    <CommunityCardContainer
      shadow="sm"
      extraCss={css`
        position: relative;
      `}
    >
      {children}
    </CommunityCardContainer>
  );
};

export type TextByThemeProps = {
  theme: ThemeType;
  as?: TextTag;
  size?: TextSizesKeys;
  extraCss?: Interpolation<Theme>;
};

const TextByTheme: FC<PropsWithChildren<TextByThemeProps>> = ({
  as = "p",
  size = "medium",
  extraCss,
  theme,
  children,
}) => {
  return theme === "system" ? (
    <Text size={size} as={as} extraCss={extraCss} color="primary">
      {children}
    </Text>
  ) : (
    <CommunityText
      size={size}
      as={as}
      extraCss={extraCss}
      color="onPrimaryBackgroundPrimaryColor"
    >
      {children}
    </CommunityText>
  );
};

export const Post = ({
  post,
  creatorAvatar,
  communitySlug,
  editableOption,
}: PostProps) => {
  const [editable, setEditable] = useState(false);
  const enableNewPostEditor = useFeatureFlag(
    FEATURE_FLAG.CONTENT_FEED_ENABLE_NEW_POST_EDITOR
  );
  const { theme } = layoutContext();

  const handleEditModeToggle = () => setEditable(!editable);

  return (
    <CardContainerByTheme theme={theme ?? "system"}>
      <header css={styles.header}>
        <div css={styles.headerTitle}>
          <CommunityAvatarSmall
            imageUrl={creatorAvatar?.imageUrl}
            name={creatorAvatar?.name}
            showVerified={false}
            insideCardContainer={true}
          />
        </div>
        <TextByTheme
          as="div"
          extraCss={styles.headerOptions}
          theme={theme ?? "system"}
          size="small"
        >
          {post.pinned && <Pinned />}
          {editableOption && (
            <Edit
              communitySlug={communitySlug}
              id={post.id}
              pinned={post.pinned}
              toggleEditMode={handleEditModeToggle}
            />
          )}
        </TextByTheme>
      </header>

      {enableNewPostEditor ? (
        <TextEditor
          editable={editable}
          initialEditorState={post.text}
          attachments={post.attachment}
        />
      ) : (
        <>
          <TextByTheme extraCss={styles.postText} theme={theme ?? "system"}>
            {post.text}
          </TextByTheme>
          {post.attachment && <Attachment attachment={post.attachment} />}
        </>
      )}

      <Reactions
        theme={theme ?? "system"}
        reactions={post.reactions
          ?.filter((postReaction) => postReaction.reaction)
          ?.map((postReaction) => ({
            count: postReaction.count || 0,
            reaction: postReaction.reaction!,
            userHasReacted: !!postReaction.userHasReacted,
          }))}
        communitySlug={communitySlug}
        postId={post.id}
      />
    </CardContainerByTheme>
  );
};
