import React, { memo, useRef, useState, useCallback } from "react";

import { css, Theme } from "@emotion/react";
import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { $createHeadingNode, HeadingTagType } from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
} from "lexical";
import { createPortal } from "react-dom";

import {
  ArrowDownBoldSmall as ArrowIcon,
  CheckWhiteSmall as CheckIcon,
  PostEditorBold as BoldIcon,
  PostEditorItalic as ItalicIcon,
  PostEditorLink as LinkIcon,
  PostEditorList as ListIcon,
} from "assets/icons.generated";
import Text from "components/Typography/Text";
import { bottomMargin } from "styles/global/layout";
import useOnClickOutside from "utils/hooks/useOnClickOutside";

import { IS_APPLE } from "../../../../config";
import CardContainer from "../../../CardContainer";
import Tooltip from "../../../Tooltip";
import FloatingLinkEditor from "../components/FloatingLinkEditor";
import { UseContentFeedEditorLogic } from "../logic";

/**
 * Styles
 */
const styleHeadingSelector = {
  container: css`
    position: relative;
  `,
  selectorButton: (theme: Theme) => css`
    margin-right: 20px;
    background: ${theme.background.colors.secondary};
    border-radius: 10px;
    padding: 0 10px 0 12px;
    height: 32px;

    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;

    svg {
      margin-left: 6px;
    }
  `,
  dropdown: css`
    position: absolute;
    top: calc(32px + 3px);
    left: -5px;
    z-index: 1;
    padding: 6px;
  `,
  dropdownButton: (active: boolean) => (theme: Theme) =>
    css`
      white-space: nowrap;
      padding: 0 10px;
      height: 32px;
      border-radius: 5px;
      width: 100%;
      text-align: left;

      ${active &&
      css`
        background: ${theme.background.colors.secondary};

        span {
          font-weight: 600;
        }
      `};

      @media (hover: hover) {
        &:hover {
          background: ${theme.background.colors.secondary};
        }
      }
    `,
};

const style = {
  container: (theme: Theme) => css`
    ${bottomMargin("7/20px")}
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;

    position: sticky;
    top: 74px;
    z-index: 2;

    &:after {
      content: " ";
      width: calc(100% + 40px);
      height: 56px;
      position: absolute;
      top: -10px;
      left: -20px;
      z-index: -1;
      border-radius: 10px;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6909357492997199) 9%,
        rgba(255, 255, 255, 1) 13%
      );
    }

    ${theme.mediaQueries.mediumDown} {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-content: flex-start;

      &:after {
        height: 100px;
        left: -16px;
        width: calc(100% + 16px * 2);
      }
    }
  `,
  typography: css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  `,
  actions: (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    ${theme.mediaQueries.mediumDown} {
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
  `,
  textStylesButton: (selected: boolean) => (theme: Theme) =>
    css`
      transition-property: background, opacity;
      transition-duration: 0.4s;
      transition-timing-function: ${theme.animations.easeOutQuart};

      width: 32px;
      height: 32px;
      margin-right: 6px;
      border-radius: 10px;

      ${selected &&
      css`
        background: ${theme.background.colors.secondary};
        opacity: 1;
      `};
      ${!selected &&
      css`
        opacity: 0.4;
      `};
    `,
  actionButton: (selected: boolean) => (theme: Theme) =>
    css`
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: flex-start;

      &:not(:first-of-type) {
        margin-left: 20px;
      }

      span {
        transition: background 0.6s ${theme.animations.easeOutQuart};

        margin-right: 8px;
        background: transparent;
        width: 20px;
        height: 20px;
        border: 1px solid ${theme.colorMap.utility.divider};
        border-radius: 4px;

        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;

        ${selected &&
        css`
          background: black;
        `}
      }
    `,
};

/**
 * ComponentS
 */
const CheckboxOption = ({
  label,
  tooltip,
  isSelected,
  toggle,
}: {
  label: string;
  tooltip?: string;
  isSelected: boolean;
  toggle: () => void;
}) => {
  if (tooltip) {
    return (
      <Tooltip
        padding="xsmall"
        content={
          <Text size="xsmall" as="span">
            {tooltip}
          </Text>
        }
      >
        <button css={style.actionButton(isSelected)} onClick={toggle}>
          <span>
            <CheckIcon />
          </span>
          {label}
        </button>
      </Tooltip>
    );
  }

  return (
    <button css={style.actionButton(isSelected)} onClick={toggle}>
      <span>
        <CheckIcon />
      </span>
      {label}
    </button>
  );
};

/**
 * Main Component
 */
interface ToolbarPluginProps {
  activeEditor: UseContentFeedEditorLogic["activeEditor"];
  blockType: UseContentFeedEditorLogic["blockType"];
  selectedElement: UseContentFeedEditorLogic["selectedElement"];
  isEditable: UseContentFeedEditorLogic["isEditable"];
  notifyMembers: UseContentFeedEditorLogic["notifyMembers"];
  pinPost: UseContentFeedEditorLogic["pinPost"];
}

//TODO: Substitute code with the separate components (BoldToggle, HeadingSelector, etc)
const ToolbarPlugin = ({
  activeEditor,
  blockType,
  selectedElement,
  isEditable,
  notifyMembers,
  pinPost,
}: ToolbarPluginProps) => {
  const ref = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);

  const formatLink = useCallback(() => {
    if (!selectedElement.isLink) {
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [activeEditor, selectedElement.isLink]);

  const formatBoldSelection = () => {
    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
  };

  const formatItalicSelection = () => {
    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
  };

  const formatBulletList = () => {
    if (blockType !== "bullet") {
      activeEditor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      activeEditor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      activeEditor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode(headingSize));
          setShowDropdown(false);
        }
      });
    }
  };

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      activeEditor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
          setShowDropdown(false);
        }
      });
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  useOnClickOutside(ref, () => setShowDropdown(false));

  const HeadingButtonLabel = () => {
    let label = "Paragraph";
    switch (blockType) {
      case "h1":
        label = "Large Heading";
        break;
      case "h2":
        label = "Medium Heading";
        break;
      case "h3":
        label = "Small Heading";
        break;
    }

    return <>{label}</>;
  };

  const HeadingTagsDropdown = () => {
    if (!showDropdown) return null;

    return (
      <CardContainer shadow="md" extraCss={styleHeadingSelector.dropdown}>
        <button
          css={styleHeadingSelector.dropdownButton(blockType === "h1")}
          onClick={() => formatHeading("h1")}
        >
          <Text as="span" size="small">
            Large Heading
          </Text>
        </button>
        <button
          css={styleHeadingSelector.dropdownButton(blockType === "h2")}
          onClick={() => formatHeading("h2")}
        >
          <Text as="span" size="small">
            Medium Heading
          </Text>
        </button>
        <button
          css={styleHeadingSelector.dropdownButton(blockType === "h3")}
          onClick={() => formatHeading("h3")}
        >
          <Text as="span" size="small">
            Small Heading
          </Text>
        </button>
        <button
          css={styleHeadingSelector.dropdownButton(blockType === "paragraph")}
          onClick={formatParagraph}
        >
          <Text as="span" size="small">
            Paragraph
          </Text>
        </button>
      </CardContainer>
    );
  };

  const HeadingSelector = () => {
    return (
      <div css={styleHeadingSelector.container} ref={ref}>
        <button
          css={styleHeadingSelector.selectorButton}
          onClick={handleDropdownToggle}
        >
          <Text as="span" size="small" bold>
            <HeadingButtonLabel />
          </Text>
          <ArrowIcon />
        </button>

        <HeadingTagsDropdown />
      </div>
    );
  };

  if (!isEditable) return null;

  return (
    <div css={style.container}>
      <div css={style.typography}>
        <HeadingSelector />
        <button
          css={style.textStylesButton(selectedElement.isBold)}
          onClick={formatBoldSelection}
          title={IS_APPLE ? "Bold (⌘B)" : "Bold (Ctrl+B)"}
          aria-label={`Format text as bold. Shortcut: ${
            IS_APPLE ? "⌘B" : "Ctrl+B"
          }`}
        >
          <BoldIcon />
        </button>
        <button
          css={style.textStylesButton(selectedElement.isItalic)}
          onClick={formatItalicSelection}
          title={IS_APPLE ? "Italic (⌘I)" : "Italic (Ctrl+I)"}
          aria-label={`Format text as italics. Shortcut: ${
            IS_APPLE ? "⌘I" : "Ctrl+I"
          }`}
        >
          <ItalicIcon />
        </button>
        <button
          css={style.textStylesButton(blockType === "bullet")}
          onClick={formatBulletList}
        >
          <ListIcon />
        </button>
        <button
          css={style.textStylesButton(selectedElement.isLink)}
          onClick={formatLink}
          aria-label="Insert Link"
        >
          <LinkIcon />
        </button>
        {selectedElement.isLink &&
          createPortal(
            <FloatingLinkEditor editor={activeEditor} />,
            document.body
          )}
      </div>
      <Text size="small" as="div" extraCss={style.actions}>
        <CheckboxOption
          tooltip="Send an email notification to all community members when posted."
          label="Notify Members"
          isSelected={notifyMembers.isSelected}
          toggle={notifyMembers.toggle}
        />

        <CheckboxOption
          label="Pin This Post"
          isSelected={pinPost.isSelected}
          toggle={pinPost.toggle}
        />
      </Text>
    </div>
  );
};

export default memo(ToolbarPlugin);
