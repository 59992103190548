import { css, Theme } from "@emotion/react";

import { textWithLink } from "styles/global/typography";

export const main = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

export const container = (theme: Theme) => css`
  width: 100%;
  max-width: 484px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  svg {
    margin-bottom: ${theme.verticalSpaces.small[14]};
    height: auto;
  }

  h1 {
    margin-bottom: ${theme.verticalSpaces.small[6]};
  }

  > div {
    margin-bottom: ${theme.verticalSpaces.small[11]};

    ${textWithLink}
    p {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;

      &:first-of-type {
        margin-block-start: 0;
      }
    }
  }

  > a {
    max-width: 307px;
  }

  ${theme.mediaQueries.smallOnly} {
    svg {
      max-width: 160px;
      margin-bottom: ${theme.verticalSpaces.small[11]};
    }

    h1 {
      margin-bottom: ${theme.verticalSpaces.small[6]};
    }

    > div {
      margin-bottom: ${theme.verticalSpaces.small[9]};
    }
  }
`;

export const CardContainer = css``;
