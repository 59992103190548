import { useEffect } from "react";

import { useReactiveVar } from "@apollo/client";

import { communityVar } from "apollo/reactive";
import { hideBannerMessage, showBannerMessage } from "apollo/reactive/banner";
import useSession from "hooks/useSession";

import { NotificationStatus } from "../apollo/graphql.generated";
import { useOnTokenTransferredSubscription } from "../utils/distribution/distribution.graphql.generated";

export const MINTING_IN_PROGRESS_BANNER_MESSAGE = "Minting in progress...";

const useUserPendingTokens = () => {
  const { id } = useReactiveVar(communityVar);
  const { user, refetchUser } = useSession();

  const hasPendingTokens =
    user?.pendingTokens?.some((token) => token.community.id === id) || false;

  useOnTokenTransferredSubscription({
    variables: {
      communityId: id,
      tokenIds: user?.pendingTokens.map((value) => value.tokenId) || [],
    },
    // Skip subscription if there is no pending tokens.
    skip: !hasPendingTokens,
    onSubscriptionData: (dataCallback) => {
      if (
        dataCallback.subscriptionData.data?.token.status ===
        NotificationStatus.SUCCESS
      ) {
        refetchUser();
      }
    },
  });

  useEffect(() => {
    if (hasPendingTokens) {
      showBannerMessage(MINTING_IN_PROGRESS_BANNER_MESSAGE);
    } else {
      hideBannerMessage(MINTING_IN_PROGRESS_BANNER_MESSAGE);
    }
  }, [hasPendingTokens]);

  return {
    hasPendingTokens,
  };
};

export default useUserPendingTokens;
export type UseUserPendingTokens = ReturnType<typeof useUserPendingTokens>;
