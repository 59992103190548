import React, {
  ChangeEvent,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";

import { CreatorCommunityMembersSort } from "apollo/graphql.generated";
import { CreatorCommunityMembersQuery } from "apollo/queries";
import { ReactComponent as ClearIcon } from "assets/icons/close-x-within-circle.svg";
import { ReactComponent as DiscordLogo } from "assets/icons/discord_logo_black.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import Button from "components/Button";
import CardContainer from "components/CardContainer";
import ErrorLinkContainer from "components/ErrorLinkContainer";
import SelectField from "components/Inputs/SelectField";
import TextArea from "components/Inputs/TextArea";
import { AppSEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import Pagination from "components/Pagination";
import Label from "components/Typography/Label";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE } from "config";
import { formatDateToUserLocale } from "lib/date-utils";
import { formatNumber } from "utils/formatting/number";
import { Maybe } from "utils/types";

import * as baseStyles from "../styles";

import { usePageLogic } from "./logic";
import * as styles from "./styles";

export const sortOptions = [
  {
    value: CreatorCommunityMembersSort.BY_DATE_REVERSE,
    label: "Date Joined (New to Old)",
  },
  {
    value: CreatorCommunityMembersSort.BY_DATE,
    label: "Date Joined (Old to New)",
  },
  {
    value: CreatorCommunityMembersSort.BY_TOKENS_AMOUNT_REVERSE,
    label: "Tokens (Most to Least)",
  },
  {
    value: CreatorCommunityMembersSort.BY_TOKENS_AMOUNT,
    label: "Tokens (Least to Most)",
  },
];

type SummaryCardTypes = {
  title: string;
  amount?: Maybe<number>;
};

const SummaryCard = ({ title, amount }: SummaryCardTypes) => {
  return (
    <CardContainer background="secondary" extraCss={styles.summaryCard}>
      <Label as="label">{title}</Label>
      <Title as="h6">
        {amount !== undefined && amount !== null
          ? formatNumber(amount)
          : "\u00A0"}
      </Title>
    </CardContainer>
  );
};

type MembersListTypes = {
  members: CreatorCommunityMembersQuery["creatorCommunityMembers"]["members"];
  resultsPerPage: number;
  selectedPage: number;
  totalResults: number;
  onPageChange: (page: number) => void;
  lastSearchedEmail: string;
};

const MembersList = ({
  members,
  resultsPerPage,
  selectedPage,
  totalResults,
  onPageChange,
  lastSearchedEmail,
}: MembersListTypes) => {
  return (
    <div css={styles.memberList}>
      {members.map((member, index) => {
        const background = index % 2 == 0 ? "secondary" : "primary";
        return (
          <CardContainer
            key={index}
            padding="large"
            background={background}
            extraCss={styles.membersListRow}
          >
            <div css={styles.memberInfo}>
              <Text bold>{member.email}</Text>
              {member.discordUserName && (
                <div css={styles.discordInfo}>
                  <DiscordLogo css={styles.discordIcon} />
                  <Text size="small">{member.discordUserName}</Text>
                </div>
              )}
              {member.blockchainAddress && (
                <Text
                  size="xsmall"
                  color="secondary"
                  extraCss={styles.blockchainAddressText}
                >
                  {member.blockchainAddress}
                </Text>
              )}
              <div css={styles.tokensInfo}>
                {member.tokensHeldNames?.map((tokenName, index) => {
                  return (
                    <CardContainer
                      border
                      background={background}
                      extraCss={styles.tokenInfo}
                      key={index}
                    >
                      <Text size="xsmall" bold color="secondary">
                        {tokenName}
                      </Text>
                    </CardContainer>
                  );
                })}
              </div>
            </div>
            {!!member.dateJoined && (
              <Text color="secondary" size="small" textAlign="right">
                Joined {formatDateToUserLocale(member.dateJoined)}
              </Text>
            )}
          </CardContainer>
        );
      })}
      {totalResults === 0 && (
        <CardContainer
          padding="large"
          background="secondary"
          extraCss={styles.noMembers}
        >
          <Text as="span" size="medium">
            No members found with the email&nbsp;
          </Text>
          <Text as="span" size="medium" bold>
            {lastSearchedEmail}
          </Text>
        </CardContainer>
      )}
      {totalResults !== 0 && (
        <Pagination
          selectedPage={selectedPage}
          resultsPerPage={resultsPerPage}
          totalResults={totalResults}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

const EditCommunityMembers = () => {
  const navigate = useNavigate();
  const searchRef = useRef<HTMLTextAreaElement>(null);
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  const [showSearchButton, setShowSearchButton] = useState<boolean>(false);

  const {
    isPageBlockedByFeatureFlag,
    membersAmount,
    currentMonthMembersAmount,
    members,
    isLoading,
    handleOnOrderChange,
    handleOnPageChange,
    page,
    pageSize,
    sort,
    handleOnSearch,
    resultsCount,
    lastSearchedEmail,
    error,
    setError,
  } = usePageLogic();

  // Protect route if feature flag is off.
  useEffect(() => {
    if (!isPageBlockedByFeatureFlag) return;
    navigate("/", { replace: true });
  }, [isPageBlockedByFeatureFlag]);

  const handleOnSearchClick = useCallback(async () => {
    const success = await handleOnSearch(searchRef?.current?.value);
    setShowClearButton(searchRef?.current?.value !== "");
    setShowSearchButton(!success);
  }, [searchRef]);

  const handleOnEnter = useCallback(
    async (event: KeyboardEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      await handleOnSearch(searchRef?.current?.value);
      setShowClearButton(searchRef?.current?.value !== "");
    },
    [searchRef]
  );

  const handleOnSearchFieldChange = useCallback(async () => {
    setError("");
    if (searchRef?.current?.value === "") {
      await handleOnClearClick();
    }
    setShowClearButton(searchRef?.current?.value !== "");
  }, [searchRef]);

  const handleOnSearchFieldFocus = useCallback(() => {
    setShowSearchButton(true);
  }, [showClearButton]);

  const handleOnSearchFieldBlur = useCallback(() => {
    setShowSearchButton(showClearButton);
  }, [showClearButton]);

  const handleOnClearClick = useCallback(async () => {
    await handleOnSearch("");
    if (searchRef && searchRef.current) searchRef.current.value = "";
    setShowClearButton(false);
    setShowSearchButton(false);
  }, [searchRef]);

  const handleOnOrderToggled = (event: ChangeEvent<HTMLSelectElement>) =>
    handleOnOrderChange(event.currentTarget.value);

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.EDITCOMMUNITY_MEMBERS} />

      <div css={baseStyles.layoutHeader}>
        <Title size="large">Members</Title>
      </div>

      <div>
        <div css={styles.summaryCards}>
          <SummaryCard title="Total Members" amount={membersAmount} />
          <SummaryCard
            title="New This Month"
            amount={currentMonthMembersAmount}
          />
        </div>
        <div css={styles.searchBar}>
          <TextArea
            wrap="soft"
            rows={1}
            onChange={handleOnSearchFieldChange}
            onKeyPress={(e) => e.key === "Enter" && handleOnEnter(e)}
            placeholderPrefix={<SearchIcon css={styles.searchIcon} />}
            placeholderPostfix={
              showClearButton && (
                <Button css={styles.clearButton} onClick={handleOnClearClick}>
                  <ClearIcon css={styles.clearIcon} />
                </Button>
              )
            }
            placeholder="Look Up by Email"
            additionalCss={styles.searchField}
            ref={searchRef}
            onFocus={handleOnSearchFieldFocus}
            onBlur={handleOnSearchFieldBlur}
          />
          {!showSearchButton && (
            <SelectField
              onChange={handleOnOrderToggled}
              defaultValue={sort}
              placeholderPrefix={
                <Text size="xsmall" color="secondary">
                  Sort By
                </Text>
              }
              extraCss={styles.sort}
              options={sortOptions}
            />
          )}
          {showSearchButton && (
            <Button
              size="small"
              extraCss={styles.searchButton}
              onClick={handleOnSearchClick}
              disabled={isLoading || !showClearButton}
            >
              Look Up
            </Button>
          )}
        </div>
        {error && (
          <div css={styles.error}>
            <ErrorLinkContainer onClose={() => setError("")}>
              <Text>{error}</Text>
            </ErrorLinkContainer>
          </div>
        )}

        {isLoading && <LoadingIcon css={styles.loadingIcon} />}
        {!isLoading && (
          <MembersList
            resultsPerPage={pageSize}
            selectedPage={page}
            totalResults={resultsCount || 0}
            members={members ?? []}
            onPageChange={handleOnPageChange}
            lastSearchedEmail={lastSearchedEmail}
          />
        )}
      </div>
    </>
  );
};

export default memo(EditCommunityMembers);
