import { css, Theme } from "@emotion/react";

export const errorContainer = (level: string) => (theme: Theme) =>
  css`
    border: 2px solid
      ${level === "error"
        ? theme.colorMap.destructive.default
        : theme.colorMap.accent.primary};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    flex: 1;
    width: 100%;

    margin-bottom: ${theme.verticalSpaces.large[4]};
  `;

export const errorBackground = (level: string) => (theme: Theme) =>
  css`
    padding: 16px;
    border-radius: 8px;
    display: flex;
    width: 100%;
    background-color: ${level === "error"
      ? theme.colorMap.destructive[50]
      : theme.colorMap.accent[50]};
  `;

export const infoText = (theme: Theme) => css`
  color: ${theme.colorMap.accent.primary};
`;

export const errorText = (theme: Theme) => css`
  color: ${theme.colorMap.destructive.default};
`;

export const errorTextUrl = (theme: Theme) => css`
  color: ${theme.colorMap.destructive.default};
  text-decoration: underline;
`;

export const infoTextLink = (theme: Theme) => css`
  color: ${theme.colorMap.accent.primary};
`;

export const metaMaskErrorText = (theme: Theme) => css`
  color: ${theme.colorMap.destructive.default};
`;

export const middleContainer = css`
  flex: 5;
  display: flex;
  align-items: center;
`;

export const leftContainer = css`
  flex: 5;
  display: flex;
  align-items: center;
  vertical-align: middle;
`;

export const rightContainer = (theme: Theme) => css`
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: center;

  @media (hover: hover) {
    cursor: pointer;

    svg:hover {
      fill: ${theme.colorMap.accent.default};
      width: 18px;
      height: 18px;
    }
  }
`;

export const leftAuthContainer = css`
  padding: 6px 16px 6px 0;
`;

export const infoIcon = (theme: Theme) => css`
  width: 20px;
  height: 20px;
  display: flex;
  margin: 2px 8px 0 0;
  path {
    fill: ${theme.colorMap.accent.primary};
  }
`;

export const errorIcon = (theme: Theme) => css`
  width: 20px;
  height: 20px;
  display: flex;
  margin: 2px 8px 0 0;
  path {
    fill: ${theme.colorMap.destructive.default};
  }
`;

export const closeIcon = (theme: Theme) => css`
  path {
    fill: ${theme.colorMap.destructive.default};
  }
`;

export const linkContainer = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
`;

export const navLink = (theme: Theme) => css`
  color: ${theme.colorMap.destructive.default};
  font-weight: bold;
`;
