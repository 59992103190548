import React, { FC, memo } from "react";

import { useAuthForm } from "hooks/useAuthForm";
import useSession from "hooks/useSession";

import * as styles from "./styles";

export type SwitchBetweenLoginAndCreateAccountProps = {
  viewSignIn: boolean;
  onSwitch: () => void;
};

const SwitchBetweenLoginAndCreateAccount: FC<
  SwitchBetweenLoginAndCreateAccountProps
> = ({ viewSignIn, onSwitch }) => {
  const { magicClear } = useSession();
  const { dispatch } = useAuthForm();

  const switchMessage = viewSignIn
    ? "Don't have an account yet?"
    : "Already have an account?";
  const switchLink = viewSignIn ? "Create an Account" : "Sign In";

  return (
    <div css={styles.linkContainer} data-cy="sign-in-create-account-switch">
      <p css={styles.linkText}>
        {switchMessage}{" "}
        <a
          css={styles.navLink}
          rel="noreferrer"
          onClick={() => {
            dispatch({ type: "clearError" });
            dispatch({ type: "isLoading", payload: false });
            onSwitch();
            // if user starts sign-in from magic, but then switches to create account
            // useMagicLink() in useSession will lock
            magicClear();
          }}
        >
          {switchLink}
        </a>
      </p>
    </div>
  );
};

export default memo(SwitchBetweenLoginAndCreateAccount);
