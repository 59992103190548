import { FC, memo, ComponentProps, ReactNode } from "react";

import { Interpolation, Theme } from "@emotion/react";

import {
  CommunityButtonSize,
  CommunityThemeButtonsColorsKeys,
} from "styles/theme/communityTheme.types";

import LoadingIcon from "../../LoadingIcon";

import * as styles from "./styles";

type ButtonProps = ComponentProps<"button">;

export type CommunityButtonProps = ButtonProps & {
  loading?: boolean;
  loadingType?: "replace" | "inline";
  target?: string;
  color?: CommunityThemeButtonsColorsKeys;
  size?: CommunityButtonSize;
  header?: ReactNode;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
};

const CommunityButton: FC<CommunityButtonProps> = ({
  onClick,
  disabled,
  loading,
  loadingType,
  children,
  header,
  size = "large",
  color = "onPageBackgroundBackgroundColor",
  extraCss,
  ...rest
}) => (
  <button
    onClick={!loading ? onClick : undefined}
    disabled={loading || disabled}
    css={[
      loading && styles.loading(loadingType),
      styles.base,
      styles.bySize(size, !!header),
      styles.byColor(color),
      extraCss,
    ]}
    {...rest}
  >
    <div css={styles.contentWrapper}>
      <div css={styles.textWrapper}>
        {header && <span css={styles.headerText}>{header}</span>}
        <span css={styles.buttonText}>{children}</span>
      </div>
      {loading && (
        <div>
          <LoadingIcon inheritColor css={styles.icon(loadingType)} />
        </div>
      )}
    </div>
  </button>
);

export default memo(CommunityButton);
