import { css, Theme } from "@emotion/react";

export type LinkColor = "inherit" | "accent";

export const link = (color: LinkColor) => (theme: Theme) =>
  css`
    color: ${color === "inherit" ? "inherit" : theme.colorMap.accent.primary};

    @media (hover: hover) {
      &:hover {
        text-decoration: underline currentColor;
      }
    }
  `;
