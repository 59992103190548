import React, { FC, memo } from "react";

import { Link } from "react-router-dom";

import { Community } from "apollo/reactive";
import { ReactComponent as CheckWithinCircle } from "assets/icons/check-within-circle.svg";
import ButtonLink from "components/ButtonLink";
import OrderPreview from "components/Checkout/OrderPreview";
import { CommunitySEO } from "components/Layout/SEO";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { COMMUNITY_PAGE_TITLE, COMMUNITY_URL } from "config";
import { Offer } from "containers/Checkout/types";

import * as styles from "./styles";

type Props =
  | {
      community: Community;
      offer: Offer;
      redirectUrl: string;
      waitingOffer: false;
    }
  | {
      community: Community;
      redirectUrl: string;
      waitingOffer: true;
    };

const SucceededStatus: FC<Props> = (props) => {
  const { community, waitingOffer, redirectUrl } = props;
  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.CHECKOUT_ORDER_COMPLETE} />

      <div css={styles.container}>
        <div css={styles.header}>
          <CheckWithinCircle />

          <Title size="large">Order Complete</Title>
        </div>
        <div css={styles.content}>
          <div css={styles.details}>
            <Title size="small">What{`’`}s Next?</Title>
            <Text as="div">
              <p>
                We’re now transferring this token to your wallet, where you can
                access it from now on. This should only take a couple minutes.
                We’ll send you can email when the you receive it.
              </p>
              {redirectUrl === COMMUNITY_URL.home && (
                <p>
                  This token also grants you access to the{" "}
                  <Link to={COMMUNITY_URL.home}>{community?.name}</Link>{" "}
                  community. Head there now to check it out.
                </p>
              )}
            </Text>

            <ButtonLink to={redirectUrl}>Go To {community?.name}</ButtonLink>
          </div>

          <div css={styles.orderPreview}>
            {waitingOffer ? (
              <OrderPreview loading={true} />
            ) : (
              <OrderPreview offer={props.offer} loading={false} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(SucceededStatus);
