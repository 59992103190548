import { css, Theme } from "@emotion/react";

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const content = (theme: Theme) => css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  > div:not(:last-child) {
    margin-bottom: 32px;

    ${theme.mediaQueries.smallOnly} {
      margin-bottom: 20px;
    }
  }
`;
