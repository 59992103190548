import { useEffect, useState } from "react";

import useCommunity from "apollo/hooks/useCommunity";
import { useGetAnonymousQuery } from "apollo/queries";
import { initVar, anonymousVar } from "apollo/reactive";
import { setCommunityVar } from "apollo/reactive/community";
import useSession from "hooks/useSession";
import { isAuthError } from "utils/errors";
import { existsMetaMaskPlugin } from "utils/metamask";

import useAppLocation from "./useAppLocation";

const useBootstrap = () => {
  const { isAppSlug, currentCommunitySlug } = useAppLocation();
  const {
    data,
    error: communityError,
    loading,
    refetch: refetchCommunity,
  } = useCommunity();
  const {
    isLoading: userIsLoading,
    logout,
    bootstrap: bootstrapUser,
  } = useSession();
  const { data: anonymousData, loading: anonymousLoading } =
    useGetAnonymousQuery();

  const [meLogicFinished, setMeLogicFinished] = useState<boolean>(false);
  const [communityLogicFinished, setCommunityLogicFinished] =
    useState<boolean>(false);
  const [anonymousLogicFinished, setAnonymousLogicFinished] =
    useState<boolean>(false);

  const [isBootstrapUserCalled, setBootstrapUserCalled] =
    useState<boolean>(false);

  // Handle Me logic.
  useEffect(() => {
    if (!isBootstrapUserCalled) {
      bootstrapUser();
      setBootstrapUserCalled(true);
    }

    // TODO: add an event listener for 'chainChanged'
    if (existsMetaMaskPlugin()) {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      (window as any).ethereum.on("accountsChanged", logout);
    }

    return () => {
      // remove the 'chainChanged' and 'accountsChanged' event listeners
      if (existsMetaMaskPlugin()) {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        (window as any).ethereum.removeListener("accountsChanged", logout);
      }
    };
  });

  useEffect(() => {
    if (isBootstrapUserCalled && !userIsLoading) {
      setMeLogicFinished(true);
    }
  }, [isBootstrapUserCalled, userIsLoading]);

  // Handle Anonymous logic.
  useEffect(() => {
    if (anonymousLoading) {
      return;
    }

    if (!anonymousData) {
      console.error("anonymousData didn't load");
      return;
    }

    anonymousVar(anonymousData.anonymous);
    setAnonymousLogicFinished(true);
  }, [anonymousData, anonymousLoading]);

  // Handle Community logic.
  useEffect(() => {
    if (isAppSlug) {
      setCommunityLogicFinished(true);
      return;
    }

    if (isAuthError(communityError?.graphQLErrors)) {
      refetchCommunity();
    }

    if (loading || data == null) {
      return;
    }

    setCommunityVar(data);
    setCommunityLogicFinished(true);
  }, [loading, communityError]);

  // Remove loading if all logics are finished.
  useEffect(() => {
    if (
      !communityLogicFinished ||
      !meLogicFinished ||
      !anonymousLogicFinished
    ) {
      return;
    }

    initVar(true);
  }, [
    communityLogicFinished,
    meLogicFinished,
    anonymousLogicFinished,
    userIsLoading,
  ]);

  return {
    isMainApp: isAppSlug,
    isCommunityApp: !isAppSlug,
    communityTheme: data?.community?.websiteTheme,
    currentCommunitySlug,
  };
};

export default useBootstrap;
export type UseBootstrap = ReturnType<typeof useBootstrap>;
