import React, { useEffect, useMemo } from "react";

import { css, Theme } from "@emotion/react";

import { AttachmentType } from "apollo/graphql.generated";
import { MeQuery } from "apollo/queries";
import { resolveBenefitToken } from "apollo/utils/community";
import CardContainer from "components/CardContainer";
import { CommunitySEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import CommunityCardContainer from "components/_Community/CardContainer";
import CommunityText from "components/_Community/Text";
import CommunityTitle from "components/_Community/Title";
import { COMMUNITY_PAGE_TITLE, SUPPORT_ARTICLES } from "config";
import BenefitCard from "containers/CommunityV2/Benefits/BenefitCard";
import useSession from "hooks/useSession";
import { logError } from "services/logger";
import { topMargin } from "styles/global/layout";

import { Post, TokenMappedMetadata, TokenMapProvider } from "./Post";
import {
  PostsByCommunityQuery,
  usePostsByCommunityQuery,
} from "./query.graphql.generated";

export interface ContentFeedProps {
  communitySlug: string;
  isCreator?: boolean;
}

const loadingIconStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const feedStyle = (theme: Theme) => css`
  > * {
    margin-top: ${theme.verticalSpaces.large[9]};

    ${theme.mediaQueries.smallOnly} {
      margin-top: ${theme.verticalSpaces.small[9]};
    }

    :first-of-type {
      margin-top: 0;
    }
  }
`;

const noPostsStyle = (isCommunity: boolean) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${isCommunity ? "96px 40px" : "40px"};
  text-align: center;
`;

const noPostsDescriptionStyle = css`
  ${topMargin("4/8px")}
`;

const communityNoPostsDescriptionStyle = css`
  ${topMargin("4/8px")}
`;

const learnMoreLinkStyle = css`
  color: inherit;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const createTokenMap = (
  data: PostsByCommunityQuery,
  user?: MeQuery["currentUser"]
) => {
  const posts = data.contentFeed?.posts;
  if (!posts) {
    return {};
  }
  const tokens = [
    ...(data?.community?.membershipTokens || []),
    ...(data?.community?.benefitTokens || []),
  ];

  const uniquePostedTokenIds = [
    ...Array.from(
      posts
        .filter((post) => post.attachment?.type === AttachmentType.TOKEN)
        .map((post) => post.attachment!.id)
    ),
  ];
  return uniquePostedTokenIds.reduce<TokenMappedMetadata>((acc, id) => {
    const token = tokens.find((token) => token.tokenId === id);
    const offer = data.community?.offers?.find(
      (offer) => offer.tokenId?.toString() === id && offer.active
    );
    if (!token) return acc;

    acc[id] = {
      id,
      total: token.supply ?? 0,
      available: token.creatorBalance ?? 0,
      checkoutId: offer?.id,
      price: offer?.price,
      currency: offer?.currency,
      crypto: offer?.crypto,
      name: token.name,
      description: token.description,
      uri: token.uri,
      type: token.tokenType,
      userHasIt:
        user?.tokens?.some(
          (userToken) =>
            userToken.community.id === data.community?.id &&
            userToken.tokenId.toString() === id &&
            (userToken.balance ?? 0) > 0
        ) || false,
      userHasPending:
        user?.pendingTokens?.some(
          (userToken) =>
            userToken.community.id === data.community?.id &&
            userToken.tokenId.toString() === id
        ) || false,
    };

    return acc;
  }, {});
};
export const ContentFeed = ({
  communitySlug,
  isCreator = false,
}: ContentFeedProps) => {
  const { user } = useSession();
  const { data, loading, error, refetch } = usePostsByCommunityQuery({
    variables: {
      slug: communitySlug,
    },
    notifyOnNetworkStatusChange: true,
  });

  const tokenMap = useMemo(() => {
    if (data) {
      return createTokenMap(data, user);
    }
    return {};
  }, [data, user, createTokenMap]);

  const handlePostPinned = () => refetch();

  const handlePostDeleted = handlePostPinned;

  const handleNewPostAdded = handlePostPinned;

  useEffect(() => {
    document.addEventListener("post:newPostAdded", handleNewPostAdded);
    document.addEventListener("post:pinned", handlePostPinned);
    document.addEventListener("post:deleted", handlePostDeleted);
    return () => {
      document.removeEventListener("post:newPostAdded", handleNewPostAdded);
      document.removeEventListener("post:pinned", handlePostPinned);
      document.removeEventListener("post:deleted", handlePostDeleted);
    };
  }, []);

  if (loading) {
    return (
      <div css={loadingIconStyle}>
        <LoadingIcon />
      </div>
    );
  }

  if (error) {
    logError("Content Feed Loading error - no posts");
  }

  const benefit = data?.contentFeed?.benefit;
  const benefitToken = resolveBenefitToken(benefit);

  if (!user || !benefit?.hasAccess) {
    return (
      <>
        <CommunitySEO title={COMMUNITY_PAGE_TITLE.POSTS} />

        <BenefitCard
          unlocked={false}
          unlockedByToken={benefitToken}
          header={
            <>
              <CommunityTitle
                color="onPrimaryBackgroundPrimaryColor"
                size="medium"
              >
                Posts
              </CommunityTitle>
            </>
          }
        />
      </>
    );
  }

  if (error || !data?.contentFeed?.posts?.length) {
    return (
      <>
        <CommunitySEO title={COMMUNITY_PAGE_TITLE.POSTS} />

        {isCreator && (
          <CardContainer background="secondary" extraCss={noPostsStyle(false)}>
            <Title size="small">You Haven’t Posted Yet</Title>
            <Text extraCss={communityNoPostsDescriptionStyle}>
              Once you post something to your community, it will appear here.{" "}
              <a
                href={SUPPORT_ARTICLES.CONTENT_FEED_POST_REQUIREMENTS}
                target="_blank"
                rel="noreferrer"
                css={learnMoreLinkStyle}
              >
                <Text as="span" bold>
                  Learn more
                </Text>
              </a>{" "}
              about what you can post.
            </Text>
          </CardContainer>
        )}
        {!isCreator && (
          <CommunityCardContainer extraCss={noPostsStyle(true)}>
            <CommunityText
              color="onPrimaryBackgroundPrimaryColor"
              size="large"
              bold
            >
              There Are No Posts in {data?.community?.name} Yet
            </CommunityText>
            <CommunityText
              color="onPrimaryBackgroundPrimaryColor"
              size="small"
              extraCss={noPostsDescriptionStyle}
            >
              Once {data?.community?.creatorAvatar.name} posts something, it
              will appear here.
            </CommunityText>
          </CommunityCardContainer>
        )}
      </>
    );
  }

  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.POSTS} />

      {!!benefit?.hasAccess && (
        <TokenMapProvider value={tokenMap}>
          <div css={feedStyle}>
            {data?.contentFeed?.posts?.map((post) => (
              <Post
                key={post.id}
                post={post}
                communitySlug={communitySlug}
                editableOption={isCreator}
                creatorAvatar={data?.community?.creatorAvatar}
              />
            ))}
          </div>
        </TokenMapProvider>
      )}
    </>
  );
};
