import { FC, memo } from "react";

import { useReactiveVar } from "@apollo/client";

import { communityVar } from "apollo/reactive";
import DefaultCommunityImage from "assets/images/default-community-avatar.png";
import CommunityAvatarSmall from "components/CommunityAvatarSmall";
import { ResizedImage } from "components/Image";
import CommunityTitle from "components/_Community/Title";

import * as styles from "./styles";

const CommunityHeader: FC = () => {
  const { name, imageUrl, creatorAvatar, bannerImageUrl } =
    useReactiveVar(communityVar);

  return (
    <div css={styles.header}>
      <div css={styles.bannerImage}>
        {bannerImageUrl && (
          <img
            src={bannerImageUrl}
            loading="lazy"
            aria-hidden
            alt={undefined}
          />
        )}
      </div>
      <ResizedImage
        css={styles.image}
        src={imageUrl || DefaultCommunityImage}
        alt={name || undefined}
        width={200}
      />
      <CommunityAvatarSmall
        extraCss={styles.communityAvatar}
        imageUrl={creatorAvatar?.imageUrl}
        name={creatorAvatar?.name}
        showVerified={false}
      />
      <CommunityTitle size="large" extraCss={styles.title}>
        {name}
      </CommunityTitle>
    </div>
  );
};

export default memo(CommunityHeader);
