import React, {
  forwardRef,
  memo,
  ComponentPropsWithRef,
  ReactNode,
} from "react";

import { Interpolation, Theme } from "@emotion/react";
import _uniqueId from "lodash/uniqueId";

import { ArrowDownBold as ArrowIcon } from "assets/icons.generated";
import Label from "components/Typography/Label";
import { Maybe } from "utils/types";

import Text from "../../Typography/Text";
import * as sharedStyles from "../sharedStyles";

import * as styles from "./styles";

type SelectInputProps = ComponentPropsWithRef<"select">;

export type SelectFieldProps = SelectInputProps & {
  label?: string;
  options: Array<Record<string, string>>;
  description?: Maybe<string>;
  error?: string | undefined;
  extraCss?: Interpolation<Theme>;
  placeholderPrefix?: ReactNode;
};

const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    {
      error,
      id,
      label,
      options,
      description,
      extraCss,
      placeholderPrefix,
      ...register
    }: SelectFieldProps,
    ref
  ) => {
    const htmlFor = _uniqueId("field-");

    return (
      <div css={[sharedStyles.baseFieldGroup, extraCss]}>
        {label && (
          <Label size="small" extraCss={sharedStyles.baseInputLabel}>
            {label}
          </Label>
        )}

        {description && (
          <Text size="small" extraCss={sharedStyles.baseInputDescription}>
            {description}
          </Text>
        )}

        <div
          css={[
            sharedStyles.baseInputContainer(error, false),
            styles.selectContainer,
          ]}
        >
          <div
            css={[
              sharedStyles.baseInput,
              placeholderPrefix ? styles.select : styles.textContainer,
            ]}
          >
            {placeholderPrefix && <span>{placeholderPrefix}</span>}
            <select
              ref={ref}
              id={id ?? htmlFor}
              {...register}
              css={[
                sharedStyles.baseInput,
                !placeholderPrefix ? styles.select : null,
              ]}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <ArrowIcon css={styles.arrowIcon} />
        </div>

        {error && <p css={sharedStyles.baseInputErrorText}>{error}</p>}
      </div>
    );
  }
);

export default memo(SelectField);
