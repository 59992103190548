import { PropsWithChildren, useEffect, useRef } from "react";

import { css } from "@emotion/react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import type { NodeKey } from "lexical";
import { CLICK_COMMAND, COMMAND_PRIORITY_LOW } from "lexical";

import AttachmentEditButton from "../components/AttachmentEditButton";
import AttachmentRemoveButton from "../components/AttachmentRemoveButton";

const styles = {
  nodeWrapper: (isSelected: boolean) => css`
    position: relative;

    white-space: normal;

    ${!isSelected &&
    css`
      .button-container {
        opacity: 0;
      }
    `}

    ${isSelected &&
    css`
      outline: 1px solid #005aeb;

      .button-container {
        opacity: 1;
      }
    `}
  `,
  buttonContainer: css`
    position: absolute;
    top: -16px;
    left: 0;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;
  `,
  composeLayer: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1;
  `,
};

interface EditorActionsHandlerProps {
  nodeKey: NodeKey;
  nodeCheck: (n: unknown) => boolean;
  onEditClickHandler?(): void;
}

const EditorActionsHandler = ({
  nodeKey,
  nodeCheck,
  onEditClickHandler,
  children,
}: PropsWithChildren<EditorActionsHandlerProps>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey);
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand<MouseEvent>(
      CLICK_COMMAND,
      (event) => {
        if (!ref.current) return false;

        if (ref.current.contains(event.target as HTMLDivElement)) {
          if (!event.shiftKey) {
            clearSelection();
          }
          setSelected(true);
          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor, ref, clearSelection, setSelected]);

  const isEditable = editor.isEditable();

  return (
    <div css={styles.nodeWrapper(isSelected)} ref={ref}>
      <div css={styles.buttonContainer} className="button-container">
        {isSelected && (
          <AttachmentRemoveButton
            isSelected={isSelected}
            setSelected={setSelected}
            nodeKey={nodeKey}
            nodeCheck={nodeCheck}
          />
        )}
        {isSelected && onEditClickHandler && (
          <AttachmentEditButton onEditClickHandler={onEditClickHandler} />
        )}
      </div>

      {isEditable && <span css={styles.composeLayer} />}
      {children}
    </div>
  );
};

export default EditorActionsHandler;
