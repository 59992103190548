import React, { FC, memo } from "react";

import { motion } from "framer-motion";

import { ReactComponent as InformationIcon } from "assets/icons/information-icon.svg";
import Button from "components/Button";
import CardContainer from "components/CardContainer";
import LoadingIcon from "components/LoadingIcon";
import Text from "components/Typography/Text";

import * as styles from "./styles";

export type SaveFormChangesCardProps = {
  isSubmitting: boolean;
};

const SaveFormChangesCard: FC<SaveFormChangesCardProps> = ({
  isSubmitting,
}) => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={{
        visible: {
          opacity: 1,
          translateY: 0,
          transition: {
            duration: 0.7,
            delay: 0.2,
            ease: [0.25, 1, 0.5, 1],
          },
        },
        hidden: {
          opacity: 0,
          translateY: 20,
          transition: {
            duration: 0.4,
            ease: [0.25, 1, 0.5, 1],
          },
        },
      }}
      css={styles.container}
    >
      <CardContainer shadow="lg" extraCss={styles.card}>
        {isSubmitting ? (
          <Text>
            <LoadingIcon />
            Saving your changes.
          </Text>
        ) : (
          <Text>
            <InformationIcon />
            Save your changes before leaving this page.
          </Text>
        )}
        {!isSubmitting && (
          <Button size="medium" fluid={false} type="submit">
            Save Changes
          </Button>
        )}
      </CardContainer>
    </motion.div>
  );
};

export default memo(SaveFormChangesCard);
