import { css, Theme } from "@emotion/react";

export const title = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[3]};
`;

export const mintInProgress = (theme: Theme) =>
  css`
    margin-right: ${theme.verticalSpaces.large[3]};
    width: 100%;

    ${theme.mediaQueries.mediumOnly} {
      width: 100%;
    }
    ${theme.mediaQueries.smallOnly} {
      width: 100%;
    }
  `;

export const baseContainer = (theme: Theme) =>
  css`
    margin-right: ${theme.verticalSpaces.large[3]};
    width: 100%;

    ${theme.mediaQueries.mediumOnly} {
      width: 100%;
    }
    ${theme.mediaQueries.smallOnly} {
      width: 100%;
    }
  `;

export const tokenImageContainer = css`
  width: auto;
  height: auto;
`;

export const tokenImage = css`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

export const emptyTokenContainer = (uri: string) => css`
  width: 396px;
  height: 396px;
  background: url(${uri}) 50% 50%;

  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const noTokenText = (theme: Theme) => css`
  padding: 40px;
  color: ${theme.text.colors.secondary};
`;
