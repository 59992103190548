import { css } from "@emotion/react";

export const container = () => css`
  margin-left: 8px;
  cursor: help;
`;

export const tooltipContent = () => css`
  line-height: 1.5;
  font-weight: 500;
`;
