import React, { FC, PropsWithChildren } from "react";

import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";

import * as styles from "./styles";

const NavLink: FC<PropsWithChildren<LinkProps>> = ({ to, children }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      to={to}
      css={[styles.navLinkBase, styles.navLinkSize, styles.navLink(!!match)]}
    >
      {children}
    </Link>
  );
};

const Container: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div css={styles.navContainer}>{children}</div>
);

export default {
  Container,
  Link: NavLink,
};
