import React, { FC, memo, PropsWithChildren } from "react";

import { Interpolation, Theme } from "@emotion/react";

import { StandardSizesKeys } from "styles/theme";
import { CommunityThemeTextColorsKeys } from "styles/theme/communityTheme.types";
import { TitleTag } from "styles/theme/theme.types";

import * as communityStyles from "./styles";

export type CommunityTitleProps = {
  color?: CommunityThemeTextColorsKeys;
  size?: StandardSizesKeys;
  as?: TitleTag;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
};

const CommunityTitle: FC<PropsWithChildren<CommunityTitleProps>> = ({
  children,
  size = "medium",
  color = "onPageBackgroundPrimaryColor",
  as = "h2",
  extraCss,
  ...rest
}) => {
  const Tag = as as keyof JSX.IntrinsicElements;
  return (
    <Tag
      css={[
        communityStyles.base,
        communityStyles.bySize(size),
        communityStyles.byColor(color),
        extraCss,
      ]}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default memo(CommunityTitle);
