import React, { FC, memo, useCallback, useEffect, useState } from "react";

import { Interpolation, Theme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import { Offer, Token, TokenStatus } from "apollo/graphql.generated";
import { ModalType, updateModalVar } from "apollo/reactive";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-default.svg";
import Button from "components/Button";
import CardContainer from "components/CardContainer";
import Label from "components/Typography/Label";
import Text from "components/Typography/Text";
import { APP_URL } from "config";
import useSession from "hooks/useSession";
import { formatCurrency } from "utils/formatting/currency";

import * as sharedStyles from "../Shared/styles";

import * as styles from "./styles";

export interface TokenStatusText {
  text: string;
  style: Interpolation<Theme>;
}

export interface Props {
  token: Token;
  offers: Offer[];
  slug: string;
  communityId: string;
  onUpdate: () => void;
  index: number;
}

const TokenDetail: FC<Props> = ({
  slug,
  token,
  offers,
  communityId,
  onUpdate,
  index,
}) => {
  const navigate = useNavigate();
  const { user } = useSession();
  const isPending = token.tokenStatus === TokenStatus.Pending;
  const [status, setStatus] = useState<TokenStatusText | null>(null);

  const showMakeOfferModal = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      const dataMap: Record<string, string | boolean> = {
        communityId: communityId || "",
        enabledStripe: user?.stripeAccount?.charges_enabled || false,
        tokenId: token.tokenId,
        tokenName: token.name || "",
      };

      updateModalVar({
        showModal: ModalType.MAKE_OFFER,
        data: dataMap,
        onClose: onUpdate,
      });
    },
    [token, communityId]
  );

  const handleOnClick = useCallback(() => {
    let url = APP_URL.editCommunity.manageMintedToken
      .replace(":communitySlug", slug)
      .replace(":tokenId", token.tokenId);
    if (isPending && token.taskId) {
      url = APP_URL.editCommunity.managePendingToken
        .replace(":communitySlug", slug)
        .replace(":taskId", token.taskId);
    }

    navigate(url);
  }, [token, isPending]);

  useEffect(() => {
    if (isPending) {
      return setStatus({ style: "", text: "Minting in Progress" });
    }

    if (token.creatorBalance === 0) {
      return setStatus({ style: styles.destructiveText, text: "Sold Out" });
    }

    if (offers.length === 0) {
      return setStatus(null);
    }

    const offer = offers[0] as Offer;

    if (!offer.active) {
      return setStatus({ style: "", text: "Availability Paused" });
    }

    const price = offer.price;
    const amountText =
      parseFloat(price) === 0
        ? "Free"
        : `${formatCurrency(price, offer.currency)}`;
    return setStatus({
      style: styles.activeOffersText,
      text: `Available for ${amountText}`,
    });
  }, [token, offers, isPending]);

  const isCollectibleToken = !token?.benefits || token.benefits?.length === 0;

  return (
    <CardContainer
      onClick={handleOnClick}
      background={index % 2 == 0 ? "secondary" : "primary"}
      extraCss={styles.link}
    >
      <div css={styles.tokenInfo}>
        <img css={styles.tokenImage} src={token.uri || ""} alt="Image" />
        <div css={styles.tokenDescription}>
          <Label size="small">
            {isCollectibleToken ? "Collectible" : token.tokenType} token
          </Label>
          <Text as="p" bold={true} size="large">
            {token.name}
          </Text>
          <Text as="p" size="small">
            {token.creatorBalance} / {token.supply} remaining
          </Text>
        </div>
      </div>
      <div css={styles.tokenStatus}>
        {status && (
          <Text as="p" size="small" color="secondary" extraCss={status.style}>
            {status.text}
          </Text>
        )}
        {!status && (
          <Button
            onClick={showMakeOfferModal}
            css={[sharedStyles.mintButton, styles.availableButton]}
          >
            Make Available
          </Button>
        )}
        <ArrowRight css={styles.arrow} />
      </div>
    </CardContainer>
  );
};

export default memo(TokenDetail);
