import { useReactiveVar } from "@apollo/client";

import { modalVar, resetModalVar } from "apollo/reactive";
import { IN_DEVELOPMENT } from "config";
import { useAuthForm } from "hooks/useAuthForm";
import useSession from "hooks/useSession";
import { logError } from "services/logger";
import { AuthErrorType } from "utils/errors/authErrors";

const useLogic = () => {
  const { loginWithWeb3, validateMetaMask } = useSession();
  const { dispatch } = useAuthForm();
  const { onClose } = useReactiveVar(modalVar);

  const signIn = async () => {
    const err = await validateMetaMask();
    if (err.type != AuthErrorType.NONE) {
      dispatch({ type: "setError", payload: err });
      dispatch({ type: "isLoading", payload: false });
      return;
    }

    try {
      dispatch({ type: "isLoading", payload: true });
      dispatch({ type: "setUseMetaMask", payload: true });
      await loginWithWeb3();
      dispatch({ type: "reset" });
      if (onClose) onClose();
      resetModalVar();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      await logError({ error, message: "login failed" });
      dispatch({ type: "setWeb3LoginError", payload: error });
      dispatch({ type: "isLoading", payload: false });
      IN_DEVELOPMENT && console.log("🚨 [ERROR][HANDLE_LOGIN]: ", error);
    }
  };

  return {
    signIn,
  };
};

export type UseLogic = ReturnType<typeof useLogic>;
export default useLogic;
