import { css } from "@emotion/react";

export const dividerContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const divider = (width: string) => css`
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
  width: ${width}%;
`;
