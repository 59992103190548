import React, {
  forwardRef,
  memo,
  ComponentPropsWithRef,
  ReactNode,
} from "react";

import { Interpolation, Theme } from "@emotion/react";
import _uniqueId from "lodash/uniqueId";

import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { OnBackgroundTextColorsKeys } from "styles/theme";

import * as styles from "./styles";

type InputProps = ComponentPropsWithRef<"input">;

export type TextFieldProps = InputProps & {
  labelOnBackgroundColor?: OnBackgroundTextColorsKeys;
  label?: string;
  labelType?: "text" | "title";
  description?: string | ReactNode;
  error?: string;
  extraCss?: Interpolation<Theme>;
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      error,
      id,
      label = "",
      labelType = "text",
      placeholder = "",
      description,
      labelOnBackgroundColor,
      type = "text",
      extraCss,
      ...rest
    }: TextFieldProps,
    ref
  ) => {
    const htmlFor = _uniqueId("field-");

    const renderTitle = () => {
      switch (labelType) {
        case "text":
          return (
            <Text size="medium" bold onBackgroundColor={labelOnBackgroundColor}>
              {label}
            </Text>
          );
        case "title":
          return (
            <Title size="xsmall" onBackgroundColor={labelOnBackgroundColor}>
              {label}
            </Title>
          );
      }
    };

    return (
      <div className={rest.className} css={[styles.fieldGroup, extraCss]}>
        <label htmlFor={id ?? htmlFor} css={styles.label}>
          {renderTitle()}
        </label>
        {description && (
          <Text size="small" extraCss={styles.description} innerHTML>
            {description}
          </Text>
        )}
        <input
          ref={ref}
          type={type}
          id={id ?? htmlFor}
          placeholder={placeholder}
          css={styles.input(error)}
          {...rest}
        />
        {error && <p css={styles.error}>{error}</p>}
      </div>
    );
  }
);

export default memo(TextField);
