import { css, Theme } from "@emotion/react";

export const title = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[8]};
`;

export const shopifyInfo = (theme: Theme) => css`
  display: grid;
  grid-column-gap: ${theme.layout.gridColumnWidth};
  grid-template-columns: ${theme.layout.gridColumns(9, 3)};
  margin-top: ${theme.verticalSpaces.large[4]};
  align-items: center;
`;
