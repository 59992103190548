import { css, Theme } from "@emotion/react";

export const linkContainer = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
`;

export const linkText = (theme: Theme) => css`
  font-size: ${theme.text.sizes.xsmall};
`;

export const navLink = (theme: Theme) => css`
  color: ${theme.text.colors.onBackground["primary"]};
  font-weight: bold;
  font-size: ${theme.text.sizes.xsmall};

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
