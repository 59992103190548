import React from "react";

import { useReactiveVar } from "@apollo/client";

import { communityVar } from "apollo/reactive";
import { CommunitySEO } from "components/Layout/SEO";
import { COMMUNITY_PAGE_TITLE } from "config";
import DiscordDetails from "containers/CommunityV3/DiscordDetails/DiscordDetails";

import * as styles from "./styles";

const DiscordPage = () => {
  const { slug, discordServer } = useReactiveVar(communityVar);

  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.DISCORD} />

      <div css={styles.layout}>
        {discordServer && (
          <DiscordDetails communitySlug={slug} discordServer={discordServer} />
        )}
      </div>
    </>
  );
};

export default DiscordPage;
