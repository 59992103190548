import React, { forwardRef, useState, ComponentPropsWithRef } from "react";

import { Interpolation, Theme } from "@emotion/react";

import { ReactComponent as NoImage } from "assets/icons/no-image.svg";
import Button from "components/Button";
import Text from "components/Typography/Text";
import NewTokenPreview from "containers/App/EditCommunity/Tokens/Shared/NewTokenPreview";
import VideoPreview from "containers/App/EditCommunity/Tokens/Shared/VideoPreview";
import { Maybe } from "utils/types";

import Title from "../../Typography/Title";

import useLogic from "./logic";
import * as styles from "./styles";

const ACTIVE_DRAG_DESCRIPTION = "Drop here ...";
const INACTIVE_DRAG_DESCRIPTION = "Drag and drop your token artwork here.";

type InputProps = ComponentPropsWithRef<"input">;

export type TokenFileUploadProps = InputProps & {
  title: string;
  description?: string;
  imageUri?: string;
  animationUri?: string;
  uploadDescription: string;
  setFile(f: Maybe<File>): void;
  validateFile(f: File): Promise<Maybe<string>>;
  hasRemove?: boolean;
  extraCss?: Interpolation<Theme>;
};

const TokenFileUpload = forwardRef<HTMLInputElement, TokenFileUploadProps>(
  (
    {
      title,
      description,
      imageUri = "",
      animationUri = "",
      uploadDescription,
      setFile,
      validateFile,
      hasRemove = true,
      extraCss,
      ...rest
    }: TokenFileUploadProps,
    ref
  ) => {
    const [error, setError] = useState<string>("");

    const sourceUrl = imageUri || animationUri;

    const onFileAcceptHandler = async (file: File) => {
      setError("");
      const error = await validateFile(file);

      if (error) {
        setError(error);
      } else {
        setFile(file);
      }
    };

    const onRemove = async () => {
      setError("");
      setFile(null);
    };

    const {
      dropZoneState: { getRootProps, getInputProps, isDragActive },
      handleChange,
      handleRemove,
    } = useLogic({
      sourceUrl,
      onFileAccept: onFileAcceptHandler,
      onRemove,
    });

    return (
      <div css={extraCss}>
        <Title size="xsmall">{title}</Title>
        <Text size="small">{description}</Text>
        <div {...getRootProps()} css={styles.dragContainer}>
          <div css={styles.tokenContainer}>
            {!sourceUrl && (
              <div css={styles.emptyTokenContainer(!!error)}>
                <NoImage css={styles.noImageIcon} />
                <Text bold extraCss={styles.dragDescription}>
                  {isDragActive
                    ? ACTIVE_DRAG_DESCRIPTION
                    : INACTIVE_DRAG_DESCRIPTION}
                </Text>
                <Text extraCss={styles.uploadDescription} color="secondary">
                  {uploadDescription}
                </Text>
              </div>
            )}
            {sourceUrl && (
              <>
                {animationUri ? (
                  <VideoPreview sourceUri={animationUri} />
                ) : imageUri ? (
                  <NewTokenPreview imageUri={imageUri} />
                ) : null}
                <div css={styles.controlButtons}>
                  {hasRemove && (
                    <Button
                      color="destructive"
                      css={styles.removeButton}
                      onClick={handleRemove}
                    >
                      Remove
                    </Button>
                  )}
                  <Button css={styles.changeButton} onClick={handleChange}>
                    Change
                  </Button>
                </div>
              </>
            )}
          </div>
          <input ref={ref} css={styles.input} {...rest} {...getInputProps()} />
          {error && <p css={styles.error}>{error}</p>}
        </div>
      </div>
    );
  }
);

export default TokenFileUpload;
