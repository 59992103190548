import { useEffect } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import type { LexicalCommand } from "lexical";
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";

import {
  Attachment as AttachmentBaseType,
  MediaMetadata,
} from "apollo/graphql.generated";

import { $createVideoNode, VideoNode } from "../nodes/VideoNode";

type CommandPayload = {
  attachment: AttachmentBaseType;
  fileName: string;
};

export const INSERT_VIDEO_COMMAND: LexicalCommand<CommandPayload> =
  createCommand();

export default function VideoAttachmentPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([VideoNode])) {
      throw new Error("VideoPlugin: VideoNode not registered on editor");
    }

    return editor.registerCommand<CommandPayload>(
      INSERT_VIDEO_COMMAND,
      (payload) => {
        const videoNode = $createVideoNode(
          payload.attachment?.metadata as MediaMetadata,
          payload.fileName
        );

        $insertNodeToNearestRoot(videoNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
