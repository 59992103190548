import { FC, ReactNode, useState, useCallback, useEffect } from "react";

import { Interpolation, Theme } from "@emotion/react";
import { AnimatePresence, motion } from "framer-motion";

import { ArrowDownBoldSmall as ArrowDown } from "assets/icons.generated";
import CommunityLabel from "components/_Community/Label";
import { TextSizesKeys } from "styles/theme";

import * as styles from "./styles";

export type Props = {
  label: string;
  children: ReactNode;
  size?: TextSizesKeys;
  /** Activate on hober, or on click */
  clickActivated?: boolean;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
  height?: number;
};

const animation = {
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const DropdownMenu: FC<Props> = ({
  label,
  children,
  size = "xsmall",
  clickActivated = false,
  extraCss,
  height,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [useHover, setUseHover] = useState<boolean>(!clickActivated);

  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);
  const toggleOpen = useCallback(() => setOpen(!isOpen), [isOpen]);

  useEffect(() => {
    setUseHover(!clickActivated);
  }, [clickActivated]);

  return (
    <div
      css={[styles.container, extraCss]}
      onMouseOver={useHover ? open : undefined}
      onMouseLeave={useHover ? close : undefined}
      onClick={toggleOpen}
    >
      <button css={styles.label}>
        <CommunityLabel
          as="span"
          size={size}
          color="onPageBackgroundPrimaryColor"
          bold={true}
        >
          {label}
        </CommunityLabel>
        <motion.div
          css={styles.arrowWrapper}
          animate={{ rotate: isOpen ? 180 : 0 }}
        >
          <ArrowDown css={styles.arrow} />
        </motion.div>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            css={[styles.menu, height ? styles.dropdownGap(height) : null]}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={animation}
            itemScope
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DropdownMenu;
