import { css, Theme } from "@emotion/react";

import { fullGrid, gridColumn, section, topMargin } from "styles/global/layout";

export const layout = css`
  ${section};
  ${fullGrid};
  ${topMargin("13/64px")};
`;

export const submitContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const title = css`
  ${topMargin("11/48px")}
  text-align: center;
`;

export const benefitIconContainer = (theme: Theme) => css`
  padding: ${theme.verticalSpaces.large[4]};
`;

export const tokenField = css`
  ${topMargin("10/40px")}
`;

export const errorContainer = (theme: Theme) => css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${theme.verticalSpaces.large[4]};
`;

export const submitWarning = css`
  text-align: center;
  margin: 0 20px;
  ${topMargin("5/12px")};
`;

export const benefitsContainer = css`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  ${topMargin("5/12px")}
`;

export const tokenDescriptionCol = (theme: Theme) => css`
  ${gridColumn([1, 8])};

  ${theme.mediaQueries.smallOnly} {
    order: 1;
  }
`;

export const tokenArtworkCol = (theme: Theme) => css`
  ${gridColumn([8, 13])};

  ${theme.mediaQueries.smallOnly} {
    order: 2;
  }
`;

export const submitCol = (theme: Theme) => css`
  ${gridColumn([1, 8])};

  ${theme.mediaQueries.smallOnly} {
    order: 3;
  }
`;
