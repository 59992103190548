import React, { memo } from "react";

import { css } from "@emotion/react";

export type NewTokenPreviewProps = {
  showTitle?: boolean;
  imageUri: string;
};

export const tokenImageStyles = css`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 20px;
  object-fit: contain;
`;

const NewTokenPreview = ({ imageUri }: NewTokenPreviewProps) => (
  <img css={tokenImageStyles} src={imageUri} alt="token image" />
);

export default memo(NewTokenPreview);
