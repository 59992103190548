import { memo } from "react";

import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import * as styles from "./styles";

const UserWithoutCommunities = () => {
  return (
    <CardContainer border={true} additionalCss={styles.container}>
      <Title additionalCss={styles.containerTitle} size="small">
        Memberships
      </Title>
      <Text>
        You don{`’`}t belong to any communities yet. Once you do, communities
        you belong to will appear here.
      </Text>
    </CardContainer>
  );
};

export default memo(UserWithoutCommunities);
