import React, { FC, memo, useCallback, useState } from "react";

import { isApolloError } from "@apollo/client";
import { useParams } from "react-router-dom";

import { Offer } from "apollo/graphql.generated";
import { useUpdateOfferMutation } from "apollo/queries";
import { ModalType, updateModalVar } from "apollo/reactive";
import { ReactComponent as EthSymbol } from "assets/icons/ether-symbol.svg";
import CardContainer from "components/CardContainer";
import CopyToClipboard from "components/CopyToClipboard";
import ErrorLinkContainer from "components/ErrorLinkContainer";
import QRCode from "components/QRCode";
import SwitchItem from "components/SwitchItem";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import useAppLocation from "hooks/useAppLocation";
import useSession from "hooks/useSession";
import { logError } from "services/logger";
import { Currency } from "utils/currency";
import {
  ApolloServerError,
  DefaultApolloServerError,
  parseApolloClientError,
} from "utils/errors/apolloErrors";
import { formatCurrency } from "utils/formatting/currency";

import Divider from "../../../Shared/Divider";
import { Token } from "../types";

import * as styles from "./styles";

export interface ManageTokenStatusProps {
  token?: Token;
  offer?: Partial<Offer>;
  communityId?: string;
  readonly?: boolean;
}

const PUBLIC_AVAILABILITY_DESCRIPTION =
  "Let anyone buy or claim this token from your community’s landing page and other public pages.";

const ManageTokenStatus: FC<ManageTokenStatusProps> = ({
  token,
  offer: initialOffer,
  communityId,
  readonly = false,
}) => {
  const { communitySlug = "", tokenId = "1" } = useParams();
  const { user } = useSession();

  const [error, setError] = useState<ApolloServerError | null>(null);
  const [offer, setOffer] = useState<Partial<Offer> | undefined>(initialOffer);
  const [onAboutLoading, setOnAboutLoading] = useState<boolean>(false);
  const [onPrivateLoading, setOnPrivateLoading] = useState<boolean>(false);

  const { getPurchaseAbsoluteURL } = useAppLocation();

  const [doUpdateOfferMutation] = useUpdateOfferMutation();

  const purchaseURL = getPurchaseAbsoluteURL(communitySlug, offer?.id || "");

  const handleSwitchChange = async (
    onAboutPage: boolean,
    onPrivatePage: boolean,
    onStartHandler: () => void,
    onFinishHandler: () => void
  ) => {
    try {
      onStartHandler();
      const result = await doUpdateOfferMutation({
        variables: {
          communityId: communityId || "",
          data: {
            onAboutPage,
            onPrivatePage,
          },
          offerId: offer?.id || "",
        },
      });
      if (!result.data || result.errors) {
        throw result?.errors ?? new Error("Failed to edit offer.");
      }
      setOffer(result.data.updateOffer);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = "pause offer failed";
      if (error instanceof Error && isApolloError(error)) {
        setError(parseApolloClientError(error));
      } else {
        setError(DefaultApolloServerError);
      }
      await logError({
        error,
        message: `[handleUpdateOffer] ${message}`,
      });
    } finally {
      onFinishHandler();
    }
  };

  const showMakeOfferModal = useCallback(
    (onAboutPage = false, onPrivatePage = false) => {
      const dataMap: Record<string, string | boolean> = {
        communityId: communityId || "",
        communitySlug: communitySlug || "",
        enabledStripe: user?.stripeAccount?.charges_enabled || false,
        tokenId: tokenId,
        tokenName: token?.name || "",
        onAboutPage,
        onPrivatePage,
      };

      updateModalVar({
        showModal: ModalType.MAKE_OFFER,
        data: dataMap,
      });
    },
    [communityId, token]
  );

  const showEditOfferModal = useCallback(() => {
    const dataMap: Record<string, string | boolean> = {
      offerId: offer?.id || "",
      communityId: communityId || "",
      communitySlug: communitySlug || "",
      enabledStripe: user?.stripeAccount?.charges_enabled || false,
      tokenId: tokenId,
      tokenName: token?.name || "",
      price: offer?.price || "",
      currency: offer?.currency || "",
    };

    updateModalVar({
      showModal: ModalType.EDIT_OFFER,
      data: dataMap,
    });
  }, [offer, communityId]);

  return (
    <>
      <div>
        <Title size="xsmall">Price</Title>
        {offer ? (
          <CardContainer border extraCss={styles.priceCardContainer}>
            <div css={styles.priceContainer}>
              <div>
                <div css={styles.currency}>
                  {offer.currency === Currency.WETH && <EthSymbol />}
                  <Text>
                    {formatCurrency(
                      offer.price || 0,
                      offer.currency || "",
                      true
                    )}
                  </Text>
                </div>
                {!!offer?.crypto?.inUSD && (
                  <Text size="small" color="secondary">
                    ~ {formatCurrency(offer.crypto.inUSD, "USD")}
                  </Text>
                )}
              </div>
              <button css={styles.editOfferButton} onClick={showEditOfferModal}>
                <Text size="small" bold>
                  Edit Price
                </Text>
              </button>
            </div>
          </CardContainer>
        ) : (
          <button
            css={styles.createOfferButton}
            onClick={() => showMakeOfferModal()}
            disabled={readonly}
          >
            <CardContainer border extraCss={styles.createOfferCardContainer}>
              <Text>
                <span css={styles.createOffer}>Set a Price</span>
              </Text>
            </CardContainer>
          </button>
        )}
      </div>
      {error && (
        <ErrorLinkContainer onClick={() => setError(null)}>
          <Text size="small">Something went wrong. Please try again.</Text>
        </ErrorLinkContainer>
      )}
      <div css={styles.spacedFields}>
        <Title size="xsmall">Public Availability</Title>
        <CardContainer border extraCss={styles.publicCardContainer}>
          {offer ? (
            <SwitchItem
              title="Make Available to Public"
              description={PUBLIC_AVAILABILITY_DESCRIPTION}
              onChange={() =>
                handleSwitchChange(
                  !offer?.onAboutPage,
                  !!offer?.onPrivatePage,
                  () => setOnAboutLoading(true),
                  () => setOnAboutLoading(false)
                )
              }
              isLoading={onAboutLoading}
              checked={!!offer?.onAboutPage}
            />
          ) : (
            <button
              css={styles.makeAvailableButton}
              onClick={() => showMakeOfferModal(true, false)}
              disabled={readonly}
            >
              <SwitchItem
                title="Make Available to Public"
                description={PUBLIC_AVAILABILITY_DESCRIPTION}
                disabled
              />
            </button>
          )}
        </CardContainer>
      </div>
      <div css={styles.spacedFields}>
        <Title size="xsmall">Private Sale Page</Title>
        <Text size="small">
          Create a private page where people can buy this token using a link or
          QR code you give them.
        </Text>
        <CardContainer border extraCss={styles.createSaleCardContainer}>
          {offer ? (
            <SwitchItem
              title="Create a Private Sale Page"
              onChange={() =>
                handleSwitchChange(
                  !!offer?.onAboutPage,
                  !offer?.onPrivatePage,
                  () => setOnPrivateLoading(true),
                  () => setOnPrivateLoading(false)
                )
              }
              isLoading={onPrivateLoading}
              checked={!!offer?.onPrivatePage}
            />
          ) : (
            <button
              css={styles.createSaleButton}
              onClick={() => showMakeOfferModal(false, true)}
              disabled={readonly}
            >
              <SwitchItem title="Create a Private Sale Page" disabled />
            </button>
          )}
          {offer?.onPrivatePage && (
            <>
              <div css={styles.purchaseDivider}>
                <Divider width={"100"} />
              </div>
              <div css={styles.purchaseDetails}>
                <Text extraCss={styles.purchaseUrl}>{purchaseURL}</Text>
                <div css={styles.purchaseActions}>
                  <CopyToClipboard
                    extraCss={styles.purchaseClipboard}
                    toCopy={purchaseURL}
                    baseText={
                      <Text size="small" bold>
                        Copy Link
                      </Text>
                    }
                    copiedText={
                      <Text size="small" bold>
                        Copied!
                      </Text>
                    }
                  />
                  <QRCode extraCss={styles.purchaseQrCode} link={purchaseURL}>
                    <Text size="small" bold>
                      QR Code
                    </Text>
                  </QRCode>
                </div>
              </div>
            </>
          )}
        </CardContainer>
      </div>
    </>
  );
};

export default memo(ManageTokenStatus);
