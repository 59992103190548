import { css } from "@emotion/react";

import { defaultTheme } from "../theme";

export type SpaceUnit =
  | "1/2px"
  | "2/4px"
  | "3/6px"
  | "4/8px"
  | "5/12px"
  | "6/16px"
  | "7/20px"
  | "8/24px"
  | "9/32px"
  | "10/40px"
  | "11/48px"
  | "12/46px"
  | "13/64px"
  | "14/80px"
  | "15/96px"
  | "16/120px"
  | "17/160px";

export const spaceUnitsConvert = {
  "1/2px": 0,
  "2/4px": 1,
  "3/6px": 2,
  "4/8px": 3,
  "5/12px": 4,
  "6/16px": 5,
  "7/20px": 6,
  "8/24px": 7,
  "9/32px": 8,
  "10/40px": 9,
  "11/48px": 10,
  "12/46px": 11,
  "13/64px": 12,
  "14/80px": 13,
  "15/96px": 14,
  "16/120px": 15,
  "17/160px": 16,
};

export const section = css`
  width: 100%;
  margin: 0 auto;
  max-width: calc(
    ${defaultTheme.layout.grid.maxWidth} +
      ${defaultTheme.layout.grid.largeScreen.margin} * 2
  );
  padding-left: ${defaultTheme.layout.grid.largeScreen.margin};
  padding-right: ${defaultTheme.layout.grid.largeScreen.margin};

  ${defaultTheme.mediaQueries.mediumOnly} {
    max-width: calc(
      ${defaultTheme.layout.grid.maxWidth} +
        ${defaultTheme.layout.grid.mediumScreen.margin} * 2
    );

    padding-left: ${defaultTheme.layout.grid.mediumScreen.margin};
    padding-right: ${defaultTheme.layout.grid.mediumScreen.margin};
  }

  ${defaultTheme.mediaQueries.smallOnly} {
    max-width: 100%;
    padding-left: ${defaultTheme.layout.grid.smallScreen.margin};
    padding-right: ${defaultTheme.layout.grid.smallScreen.margin};
  }
`;

export const fullGrid = css`
  display: grid;
  gap: ${defaultTheme.layout.grid.largeScreen.gap};
  grid-template-columns: repeat(12, 1fr);

  ${defaultTheme.mediaQueries.mediumOnly} {
    gap: ${defaultTheme.layout.grid.mediumScreen.gap};
  }

  ${defaultTheme.mediaQueries.smallOnly} {
    grid-template-columns: repeat(4, 1fr);
    gap: ${defaultTheme.layout.grid.smallScreen.gap};
  }
`;

/**
 * Utility to fill the default grid columns.
 *
 * examples:
 * 2 large column with no difference for medium size and small default
 * ${gridColumn([1, 7])};
 * ${gridColumn([7, 13])};
 *
 * centered column with large and tablet size differences
 * ${gridColumn([4, 10], [2, 12])
 * @param largeRange range from 1 to 13 for large screen sizes
 * @param mediumRange range from 1 to 13 for medium screen sizes
 * @param smallRange range from 1 to 5 for small screen sizes, with a default of filling the whole grid width
 */
export const gridColumn = (
  largeRange: number[],
  mediumRange?: number[],
  smallRange: number[] = [1, 5]
) =>
  css`
    ${largeRange?.length === 2 &&
    css`
      grid-column-start: ${largeRange[0]};
      grid-column-end: ${largeRange[1]};
    `}

    ${mediumRange?.length === 2 &&
    css`
      ${defaultTheme.mediaQueries.mediumOnly} {
        grid-column-start: ${mediumRange[0]};
        grid-column-end: ${mediumRange[1]};
      }
    `}

      ${smallRange?.length === 2 &&
    css`
      ${defaultTheme.mediaQueries.smallOnly} {
        grid-column-start: ${smallRange[0]};
        grid-column-end: ${smallRange[1]};
      }
    `}
  `;

export const topMargin = (space: SpaceUnit) =>
  css`
    margin-top: ${defaultTheme.verticalSpaces.large[spaceUnitsConvert[space]]};

    ${defaultTheme.mediaQueries.mediumOnly} {
      margin-top: ${defaultTheme.verticalSpaces.medium[
        spaceUnitsConvert[space]
      ]};
    }

    ${defaultTheme.mediaQueries.smallOnly} {
      margin-top: ${defaultTheme.verticalSpaces.small[
        spaceUnitsConvert[space]
      ]};
    }
  `;
export const bottomMargin = (space: SpaceUnit) =>
  css`
    margin-bottom: ${defaultTheme.verticalSpaces.large[
      spaceUnitsConvert[space]
    ]};

    ${defaultTheme.mediaQueries.mediumOnly} {
      margin-bottom: ${defaultTheme.verticalSpaces.medium[
        spaceUnitsConvert[space]
      ]};
    }

    ${defaultTheme.mediaQueries.smallOnly} {
      margin-bottom: ${defaultTheme.verticalSpaces.small[
        spaceUnitsConvert[space]
      ]};
    }
  `;
export const topPadding = (space: SpaceUnit) =>
  css`
    padding-top: ${defaultTheme.verticalSpaces.large[spaceUnitsConvert[space]]};

    ${defaultTheme.mediaQueries.mediumOnly} {
      padding-top: ${defaultTheme.verticalSpaces.medium[
        spaceUnitsConvert[space]
      ]};
    }

    ${defaultTheme.mediaQueries.smallOnly} {
      padding-top: ${defaultTheme.verticalSpaces.small[
        spaceUnitsConvert[space]
      ]};
    }
  `;
export const bottomPadding = (space: SpaceUnit) =>
  css`
    padding-bottom: ${defaultTheme.verticalSpaces.large[
      spaceUnitsConvert[space]
    ]};

    ${defaultTheme.mediaQueries.mediumOnly} {
      padding-bottom: ${defaultTheme.verticalSpaces.medium[
        spaceUnitsConvert[space]
      ]};
    }

    ${defaultTheme.mediaQueries.smallOnly} {
      padding-bottom: ${defaultTheme.verticalSpaces.small[
        spaceUnitsConvert[space]
      ]};
    }
  `;
