import { css, Theme } from "@emotion/react";

import {
  bottomMargin,
  fullGrid,
  section,
  topMargin,
} from "styles/global/layout";

export const layout = css`
  max-width: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const container = css`
  max-width: 100%;
  padding-top: 0;
`;

export const content = css`
  ${section};
  ${fullGrid};
`;

export const contentWrapper = (theme: Theme) => css`
  grid-column-start: 3;
  grid-column-end: 11;

  ${theme.mediaQueries.mediumOnly} {
    grid-column-start: 2;
    grid-column-end: 12;
  }

  ${theme.mediaQueries.smallOnly} {
    grid-column: 1 / span 4;
  }
`;

export const banner = (theme: Theme) => css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;

  ${theme.mediaQueries.smallOnly} {
    height: 200px;
  }

  img {
    height: 260px;
    width: auto;

    ${theme.mediaQueries.smallOnly} {
      height: 150px;
    }
  }
`;

export const introText = (theme: Theme) => css`
  text-align: left;
  ${topMargin("10/40px")};
  ${bottomMargin("11/48px")};

  * {
    font-size: 20px;
    line-height: 30px;
  }

  ${theme.mediaQueries.smallOnly} {
    * {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const form = (theme: Theme) => css`
  input,
  textarea {
    font-size: 20px;
    line-height: 30px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.45);
    }

    ${theme.mediaQueries.smallOnly} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  p[class$="counter"] {
    color: rgba(13, 13, 13, 0.6);
  }
`;

export const loadingWrapper = css`
  position: fixed;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`;

export const receivedCard = css`
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  border: 1px solid rgba(2, 2, 2, 0.1);
  background: #ffffff;
`;
