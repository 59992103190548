import React, { FC, memo } from "react";

import { CreatedCommunityBySlugQuery } from "apollo/queries";
import Title from "components/Typography/Title";

import CommunityBannerUpdate from "./CommunityBannerUpdate/CommunityBannerUpdate";
import CommunityDescriptionUpdate from "./CommunityDescriptionUpdate/CommunityDescriptionUpdate";
import CommunityLogoUpdate from "./CommunityLogoUpdate/CommunityLogoUpdate";
import CommunityNameUpdate from "./CommunityNameUpdate/CommunityNameUpdate";
import CommunitySlugUpdate from "./CommunitySlugUpdate/CommunitySlugUpdate";
import CreatorAvatarUpdate from "./CreatorAvatarUpdate/CreatorAvatarUpdate";
import CreatorNameUpdate from "./CreatorNameUpdate/CreatorNameUpdate";
import * as styles from "./styles";

export interface Props {
  communityQuery?: CreatedCommunityBySlugQuery;
  onSetDescription: (description: string) => void;
  onSetAvatar: (avatar: string) => void;
  onSetLogo: (logo: string) => void;
  onSetBanner?: (banner: string) => void;
}

const CommunityInformation: FC<Props> = ({
  communityQuery,
  onSetDescription,
  onSetAvatar,
  onSetLogo,
  onSetBanner,
}) => {
  const community = communityQuery?.createdCommunityBySlug;
  return (
    <div id="communityInformation">
      <Title size="medium">Community Information</Title>
      <ul css={styles.fields}>
        <li>
          <CreatorNameUpdate
            communityId={community?.id as string}
            creatorNameValue={community?.creatorAvatar?.name || ""}
          />
        </li>

        <li id="creatorAvatar">
          <CreatorAvatarUpdate
            communityId={community?.id as string}
            creatorAvatarValue={community?.creatorAvatar?.imageUrl || ""}
            onSetAvatar={onSetAvatar}
          />
        </li>

        <li>
          <CommunityNameUpdate
            communityId={community?.id as string}
            communityNameValue={community?.name || ""}
          />
        </li>

        <li>
          <CommunityDescriptionUpdate
            communityId={community?.id as string}
            communityDescriptionValue={community?.description || ""}
            id="description"
            onSetDescription={onSetDescription}
          />
        </li>
        <li id="communityLogo">
          <CommunityLogoUpdate
            communityId={community?.id as string}
            communityLogoValue={community?.imageUrl || ""}
            onSetLogo={onSetLogo}
          />
        </li>

        <li id="communityBanner">
          <CommunityBannerUpdate
            communityId={community?.id as string}
            communityBannerValue={community?.bannerImageUrl || ""}
            onSetBanner={onSetBanner}
          />
        </li>
        <li>
          <CommunitySlugUpdate
            communityId={community?.id as string}
            communitySlugValue={community?.slug || ""}
          />
        </li>
      </ul>
    </div>
  );
};

export default memo(CommunityInformation);
