import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import { AllUserFlagVariationsFragmentDoc } from "./flagVariations.graphql.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type UserFieldsFragment = {
  __typename: "User";
  id: string;
  authMethod: string;
  personalInformation: {
    __typename: "PersonalInformation";
    email: string | null;
    phoneNumber: string | null;
    phone: {
      __typename: "PhoneNumber";
      number: string;
      isVerified: boolean;
    } | null;
  };
  blockchainAccount: {
    __typename: "EOA";
    publicAddress: string;
    blockchain: string | null;
  };
  discord: {
    __typename: "DiscordLinkedAccountData";
    user: {
      __typename: "DiscordLinkedAccountUserInfo";
      id: string | null;
      username: string | null;
      discriminator: string | null;
    } | null;
  } | null;
  terms: {
    __typename: "TermsRecord";
    version: number | null;
    family: string | null;
  } | null;
  creatorTerms: {
    __typename: "TermsRecord";
    version: number | null;
    family: string | null;
  } | null;
  flagVariations: {
    __typename: "UserFlagVariations";
    paymentsEnableFiatOffers: boolean;
    cryptoByoWalletAuth: boolean;
    onboardingSquadCreatorTermsOfService: boolean;
    scaffoldBecomeCreatorCta: boolean;
    scaffoldEnableCreatorDashboard: boolean;
    scaffoldEnableAccountEmailPreferencesPage: boolean;
    scaffoldEnableAccountTransactionHistoryPage: boolean;
    scaffoldEnableAccountWalletPage: boolean;
    scaffoldEnableMagiclinkPrivateKeyExport: boolean;
    paymentsStripeConnectOnboarding: boolean;
    creatorDashboardCreateOneCommunityPerUser: boolean;
    creatorDashboardDisableCreateCommunity: boolean;
    creatorDashboardRequirePhoneValidation: boolean;
    creatorDashboardMembersTab: boolean;
    creatorDashboardThemeTab: boolean;
    creatorDashboardIncomeTab: boolean;
    incomeAllowWithdraw: boolean;
    incomeShowWEthEarnings: boolean;
    incomeShowUsdEarnings: boolean;
    enableCryptoPayments: boolean;
    creatorDashboardCustomBenefitTokens: boolean;
    creatorDashboardEnableMintTokenLimit: boolean;
    creatorDashboardVideoAssetOnToken: boolean;
    shopifyOnboardWithBuyButton: boolean;
    creatorDashboardShowDiscordServerConfigPainel: boolean;
    enableDeployDetectionAndForcePageReload: boolean;
    emergencyStopClaimToken: boolean;
    emergencyStopDeployContract: boolean;
    emergencyStopShowContentFeedPosts: boolean;
    emergencyStopNewContentFeedPosts: boolean;
    emergencyStopMintNewTokens: boolean;
    emergencyStopSendTokensToAll: boolean;
    accountsRequireCaptchaForCreation: boolean;
    contentFeedEnableNewPostEditor: boolean;
    emergencyAlertFrontendMessage: string;
    releasePartyAccessFeature: string;
    releasePartyUpdateUserProfile: string;
    discordSyncBenefitsButton: boolean;
    enableResizedImages: boolean;
    segmentEnableInCommunities: boolean;
    enableMintingNonTransferable: boolean;
  };
  stripeAccount: {
    __typename: "StripeAccount";
    id: string;
    charges_enabled: boolean;
  } | null;
  emailPreference: {
    __typename: "EmailPreference";
    marketing: boolean;
    community: boolean;
  };
  profile: {
    __typename: "Profile";
    username: string | null;
    profileImageUrl: string | null;
  } | null;
};

export type GetAnonymousQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAnonymousQuery = {
  anonymous: {
    __typename: "Anonymous";
    flagVariations: {
      __typename: "UserFlagVariations";
      paymentsEnableFiatOffers: boolean;
      cryptoByoWalletAuth: boolean;
      onboardingSquadCreatorTermsOfService: boolean;
      scaffoldBecomeCreatorCta: boolean;
      scaffoldEnableCreatorDashboard: boolean;
      scaffoldEnableAccountEmailPreferencesPage: boolean;
      scaffoldEnableAccountTransactionHistoryPage: boolean;
      scaffoldEnableAccountWalletPage: boolean;
      scaffoldEnableMagiclinkPrivateKeyExport: boolean;
      paymentsStripeConnectOnboarding: boolean;
      creatorDashboardCreateOneCommunityPerUser: boolean;
      creatorDashboardDisableCreateCommunity: boolean;
      creatorDashboardRequirePhoneValidation: boolean;
      creatorDashboardMembersTab: boolean;
      creatorDashboardThemeTab: boolean;
      creatorDashboardIncomeTab: boolean;
      incomeAllowWithdraw: boolean;
      incomeShowWEthEarnings: boolean;
      incomeShowUsdEarnings: boolean;
      enableCryptoPayments: boolean;
      creatorDashboardCustomBenefitTokens: boolean;
      creatorDashboardEnableMintTokenLimit: boolean;
      creatorDashboardVideoAssetOnToken: boolean;
      shopifyOnboardWithBuyButton: boolean;
      creatorDashboardShowDiscordServerConfigPainel: boolean;
      enableDeployDetectionAndForcePageReload: boolean;
      emergencyStopClaimToken: boolean;
      emergencyStopDeployContract: boolean;
      emergencyStopShowContentFeedPosts: boolean;
      emergencyStopNewContentFeedPosts: boolean;
      emergencyStopMintNewTokens: boolean;
      emergencyStopSendTokensToAll: boolean;
      accountsRequireCaptchaForCreation: boolean;
      contentFeedEnableNewPostEditor: boolean;
      emergencyAlertFrontendMessage: string;
      releasePartyAccessFeature: string;
      releasePartyUpdateUserProfile: string;
      discordSyncBenefitsButton: boolean;
      enableResizedImages: boolean;
      segmentEnableInCommunities: boolean;
      enableMintingNonTransferable: boolean;
    };
  };
};

export type CreatedCommunitiesFieldsFragment = {
  __typename: "User";
  createdCommunities: Array<{
    __typename: "CommunityIdentifier";
    id: string;
    name: string;
    slug: string;
    creatorAvatar: {
      __typename: "CreatorAvatar";
      name: string | null;
      imageUrl: string | null;
    };
  }>;
};

export type UserTokensFragment = {
  __typename: "User";
  tokens: Array<{
    __typename: "UserToken";
    tokenId: number;
    name: string;
    balance: number | null;
    supply: number | null;
    imageUrl: string;
    tokenType: Types.TokenType;
    benefits: Array<{
      __typename: "TokenBenefit";
      type: Types.BenefitType;
      customName: string | null;
      releasePartyId: string | null;
    }> | null;
    community: {
      __typename: "CommunityIdentifier";
      id: string;
      slug: string;
      name: string;
      creatorAvatar: {
        __typename: "CreatorAvatar";
        imageUrl: string | null;
        name: string | null;
      };
    };
  }>;
  pendingTokens: Array<{
    __typename: "UserToken";
    tokenId: number;
    community: { __typename: "CommunityIdentifier"; id: string; slug: string };
  }>;
};

export type MeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeQuery = {
  currentUser: {
    __typename: "User";
    id: string;
    authMethod: string;
    personalInformation: {
      __typename: "PersonalInformation";
      email: string | null;
      phoneNumber: string | null;
      phone: {
        __typename: "PhoneNumber";
        number: string;
        isVerified: boolean;
      } | null;
    };
    blockchainAccount: {
      __typename: "EOA";
      publicAddress: string;
      blockchain: string | null;
    };
    discord: {
      __typename: "DiscordLinkedAccountData";
      user: {
        __typename: "DiscordLinkedAccountUserInfo";
        id: string | null;
        username: string | null;
        discriminator: string | null;
      } | null;
    } | null;
    terms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    creatorTerms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    flagVariations: {
      __typename: "UserFlagVariations";
      paymentsEnableFiatOffers: boolean;
      cryptoByoWalletAuth: boolean;
      onboardingSquadCreatorTermsOfService: boolean;
      scaffoldBecomeCreatorCta: boolean;
      scaffoldEnableCreatorDashboard: boolean;
      scaffoldEnableAccountEmailPreferencesPage: boolean;
      scaffoldEnableAccountTransactionHistoryPage: boolean;
      scaffoldEnableAccountWalletPage: boolean;
      scaffoldEnableMagiclinkPrivateKeyExport: boolean;
      paymentsStripeConnectOnboarding: boolean;
      creatorDashboardCreateOneCommunityPerUser: boolean;
      creatorDashboardDisableCreateCommunity: boolean;
      creatorDashboardRequirePhoneValidation: boolean;
      creatorDashboardMembersTab: boolean;
      creatorDashboardThemeTab: boolean;
      creatorDashboardIncomeTab: boolean;
      incomeAllowWithdraw: boolean;
      incomeShowWEthEarnings: boolean;
      incomeShowUsdEarnings: boolean;
      enableCryptoPayments: boolean;
      creatorDashboardCustomBenefitTokens: boolean;
      creatorDashboardEnableMintTokenLimit: boolean;
      creatorDashboardVideoAssetOnToken: boolean;
      shopifyOnboardWithBuyButton: boolean;
      creatorDashboardShowDiscordServerConfigPainel: boolean;
      enableDeployDetectionAndForcePageReload: boolean;
      emergencyStopClaimToken: boolean;
      emergencyStopDeployContract: boolean;
      emergencyStopShowContentFeedPosts: boolean;
      emergencyStopNewContentFeedPosts: boolean;
      emergencyStopMintNewTokens: boolean;
      emergencyStopSendTokensToAll: boolean;
      accountsRequireCaptchaForCreation: boolean;
      contentFeedEnableNewPostEditor: boolean;
      emergencyAlertFrontendMessage: string;
      releasePartyAccessFeature: string;
      releasePartyUpdateUserProfile: string;
      discordSyncBenefitsButton: boolean;
      enableResizedImages: boolean;
      segmentEnableInCommunities: boolean;
      enableMintingNonTransferable: boolean;
    };
    stripeAccount: {
      __typename: "StripeAccount";
      id: string;
      charges_enabled: boolean;
    } | null;
    emailPreference: {
      __typename: "EmailPreference";
      marketing: boolean;
      community: boolean;
    };
    profile: {
      __typename: "Profile";
      username: string | null;
      profileImageUrl: string | null;
    } | null;
    tokens: Array<{
      __typename: "UserToken";
      tokenId: number;
      name: string;
      balance: number | null;
      supply: number | null;
      imageUrl: string;
      tokenType: Types.TokenType;
      benefits: Array<{
        __typename: "TokenBenefit";
        type: Types.BenefitType;
        customName: string | null;
        releasePartyId: string | null;
      }> | null;
      community: {
        __typename: "CommunityIdentifier";
        id: string;
        slug: string;
        name: string;
        creatorAvatar: {
          __typename: "CreatorAvatar";
          imageUrl: string | null;
          name: string | null;
        };
      };
    }>;
    pendingTokens: Array<{
      __typename: "UserToken";
      tokenId: number;
      community: {
        __typename: "CommunityIdentifier";
        id: string;
        slug: string;
      };
    }>;
    createdCommunities: Array<{
      __typename: "CommunityIdentifier";
      id: string;
      name: string;
      slug: string;
      creatorAvatar: {
        __typename: "CreatorAvatar";
        name: string | null;
        imageUrl: string | null;
      };
    }>;
  };
};

export type AuthWithMagicLinkMutationVariables = Types.Exact<{
  didToken: Types.Scalars["String"];
}>;

export type AuthWithMagicLinkMutation = {
  authWithMagicLink: { __typename: "Auth"; jwt: string };
};

export type RefreshAccessTokenMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type RefreshAccessTokenMutation = {
  refreshAccessToken: { __typename: "Auth"; jwt: string };
};

export type UpdateCurrentUserEmailMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
}>;

export type UpdateCurrentUserEmailMutation = {
  updateCurrentUserEmail: {
    __typename: "User";
    id: string;
    authMethod: string;
    personalInformation: {
      __typename: "PersonalInformation";
      email: string | null;
      phoneNumber: string | null;
      phone: {
        __typename: "PhoneNumber";
        number: string;
        isVerified: boolean;
      } | null;
    };
    blockchainAccount: {
      __typename: "EOA";
      publicAddress: string;
      blockchain: string | null;
    };
    discord: {
      __typename: "DiscordLinkedAccountData";
      user: {
        __typename: "DiscordLinkedAccountUserInfo";
        id: string | null;
        username: string | null;
        discriminator: string | null;
      } | null;
    } | null;
    terms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    creatorTerms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    flagVariations: {
      __typename: "UserFlagVariations";
      paymentsEnableFiatOffers: boolean;
      cryptoByoWalletAuth: boolean;
      onboardingSquadCreatorTermsOfService: boolean;
      scaffoldBecomeCreatorCta: boolean;
      scaffoldEnableCreatorDashboard: boolean;
      scaffoldEnableAccountEmailPreferencesPage: boolean;
      scaffoldEnableAccountTransactionHistoryPage: boolean;
      scaffoldEnableAccountWalletPage: boolean;
      scaffoldEnableMagiclinkPrivateKeyExport: boolean;
      paymentsStripeConnectOnboarding: boolean;
      creatorDashboardCreateOneCommunityPerUser: boolean;
      creatorDashboardDisableCreateCommunity: boolean;
      creatorDashboardRequirePhoneValidation: boolean;
      creatorDashboardMembersTab: boolean;
      creatorDashboardThemeTab: boolean;
      creatorDashboardIncomeTab: boolean;
      incomeAllowWithdraw: boolean;
      incomeShowWEthEarnings: boolean;
      incomeShowUsdEarnings: boolean;
      enableCryptoPayments: boolean;
      creatorDashboardCustomBenefitTokens: boolean;
      creatorDashboardEnableMintTokenLimit: boolean;
      creatorDashboardVideoAssetOnToken: boolean;
      shopifyOnboardWithBuyButton: boolean;
      creatorDashboardShowDiscordServerConfigPainel: boolean;
      enableDeployDetectionAndForcePageReload: boolean;
      emergencyStopClaimToken: boolean;
      emergencyStopDeployContract: boolean;
      emergencyStopShowContentFeedPosts: boolean;
      emergencyStopNewContentFeedPosts: boolean;
      emergencyStopMintNewTokens: boolean;
      emergencyStopSendTokensToAll: boolean;
      accountsRequireCaptchaForCreation: boolean;
      contentFeedEnableNewPostEditor: boolean;
      emergencyAlertFrontendMessage: string;
      releasePartyAccessFeature: string;
      releasePartyUpdateUserProfile: string;
      discordSyncBenefitsButton: boolean;
      enableResizedImages: boolean;
      segmentEnableInCommunities: boolean;
      enableMintingNonTransferable: boolean;
    };
    stripeAccount: {
      __typename: "StripeAccount";
      id: string;
      charges_enabled: boolean;
    } | null;
    emailPreference: {
      __typename: "EmailPreference";
      marketing: boolean;
      community: boolean;
    };
    profile: {
      __typename: "Profile";
      username: string | null;
      profileImageUrl: string | null;
    } | null;
  };
};

export type UpdateEmailPreferenceMutationVariables = Types.Exact<{
  emailPreference: Types.UpdateEmailPreferenceInput;
}>;

export type UpdateEmailPreferenceMutation = {
  updateEmailPreference: {
    __typename: "User";
    id: string;
    authMethod: string;
    personalInformation: {
      __typename: "PersonalInformation";
      email: string | null;
      phoneNumber: string | null;
      phone: {
        __typename: "PhoneNumber";
        number: string;
        isVerified: boolean;
      } | null;
    };
    blockchainAccount: {
      __typename: "EOA";
      publicAddress: string;
      blockchain: string | null;
    };
    discord: {
      __typename: "DiscordLinkedAccountData";
      user: {
        __typename: "DiscordLinkedAccountUserInfo";
        id: string | null;
        username: string | null;
        discriminator: string | null;
      } | null;
    } | null;
    terms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    creatorTerms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    flagVariations: {
      __typename: "UserFlagVariations";
      paymentsEnableFiatOffers: boolean;
      cryptoByoWalletAuth: boolean;
      onboardingSquadCreatorTermsOfService: boolean;
      scaffoldBecomeCreatorCta: boolean;
      scaffoldEnableCreatorDashboard: boolean;
      scaffoldEnableAccountEmailPreferencesPage: boolean;
      scaffoldEnableAccountTransactionHistoryPage: boolean;
      scaffoldEnableAccountWalletPage: boolean;
      scaffoldEnableMagiclinkPrivateKeyExport: boolean;
      paymentsStripeConnectOnboarding: boolean;
      creatorDashboardCreateOneCommunityPerUser: boolean;
      creatorDashboardDisableCreateCommunity: boolean;
      creatorDashboardRequirePhoneValidation: boolean;
      creatorDashboardMembersTab: boolean;
      creatorDashboardThemeTab: boolean;
      creatorDashboardIncomeTab: boolean;
      incomeAllowWithdraw: boolean;
      incomeShowWEthEarnings: boolean;
      incomeShowUsdEarnings: boolean;
      enableCryptoPayments: boolean;
      creatorDashboardCustomBenefitTokens: boolean;
      creatorDashboardEnableMintTokenLimit: boolean;
      creatorDashboardVideoAssetOnToken: boolean;
      shopifyOnboardWithBuyButton: boolean;
      creatorDashboardShowDiscordServerConfigPainel: boolean;
      enableDeployDetectionAndForcePageReload: boolean;
      emergencyStopClaimToken: boolean;
      emergencyStopDeployContract: boolean;
      emergencyStopShowContentFeedPosts: boolean;
      emergencyStopNewContentFeedPosts: boolean;
      emergencyStopMintNewTokens: boolean;
      emergencyStopSendTokensToAll: boolean;
      accountsRequireCaptchaForCreation: boolean;
      contentFeedEnableNewPostEditor: boolean;
      emergencyAlertFrontendMessage: string;
      releasePartyAccessFeature: string;
      releasePartyUpdateUserProfile: string;
      discordSyncBenefitsButton: boolean;
      enableResizedImages: boolean;
      segmentEnableInCommunities: boolean;
      enableMintingNonTransferable: boolean;
    };
    stripeAccount: {
      __typename: "StripeAccount";
      id: string;
      charges_enabled: boolean;
    } | null;
    emailPreference: {
      __typename: "EmailPreference";
      marketing: boolean;
      community: boolean;
    };
    profile: {
      __typename: "Profile";
      username: string | null;
      profileImageUrl: string | null;
    } | null;
  };
};

export type UpdatePhoneNumberMutationVariables = Types.Exact<{
  phone: Types.UpdatePhoneNumberInput;
}>;

export type UpdatePhoneNumberMutation = {
  updatePhoneNumber: {
    __typename: "User";
    id: string;
    authMethod: string;
    personalInformation: {
      __typename: "PersonalInformation";
      email: string | null;
      phoneNumber: string | null;
      phone: {
        __typename: "PhoneNumber";
        number: string;
        isVerified: boolean;
      } | null;
    };
    blockchainAccount: {
      __typename: "EOA";
      publicAddress: string;
      blockchain: string | null;
    };
    discord: {
      __typename: "DiscordLinkedAccountData";
      user: {
        __typename: "DiscordLinkedAccountUserInfo";
        id: string | null;
        username: string | null;
        discriminator: string | null;
      } | null;
    } | null;
    terms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    creatorTerms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    flagVariations: {
      __typename: "UserFlagVariations";
      paymentsEnableFiatOffers: boolean;
      cryptoByoWalletAuth: boolean;
      onboardingSquadCreatorTermsOfService: boolean;
      scaffoldBecomeCreatorCta: boolean;
      scaffoldEnableCreatorDashboard: boolean;
      scaffoldEnableAccountEmailPreferencesPage: boolean;
      scaffoldEnableAccountTransactionHistoryPage: boolean;
      scaffoldEnableAccountWalletPage: boolean;
      scaffoldEnableMagiclinkPrivateKeyExport: boolean;
      paymentsStripeConnectOnboarding: boolean;
      creatorDashboardCreateOneCommunityPerUser: boolean;
      creatorDashboardDisableCreateCommunity: boolean;
      creatorDashboardRequirePhoneValidation: boolean;
      creatorDashboardMembersTab: boolean;
      creatorDashboardThemeTab: boolean;
      creatorDashboardIncomeTab: boolean;
      incomeAllowWithdraw: boolean;
      incomeShowWEthEarnings: boolean;
      incomeShowUsdEarnings: boolean;
      enableCryptoPayments: boolean;
      creatorDashboardCustomBenefitTokens: boolean;
      creatorDashboardEnableMintTokenLimit: boolean;
      creatorDashboardVideoAssetOnToken: boolean;
      shopifyOnboardWithBuyButton: boolean;
      creatorDashboardShowDiscordServerConfigPainel: boolean;
      enableDeployDetectionAndForcePageReload: boolean;
      emergencyStopClaimToken: boolean;
      emergencyStopDeployContract: boolean;
      emergencyStopShowContentFeedPosts: boolean;
      emergencyStopNewContentFeedPosts: boolean;
      emergencyStopMintNewTokens: boolean;
      emergencyStopSendTokensToAll: boolean;
      accountsRequireCaptchaForCreation: boolean;
      contentFeedEnableNewPostEditor: boolean;
      emergencyAlertFrontendMessage: string;
      releasePartyAccessFeature: string;
      releasePartyUpdateUserProfile: string;
      discordSyncBenefitsButton: boolean;
      enableResizedImages: boolean;
      segmentEnableInCommunities: boolean;
      enableMintingNonTransferable: boolean;
    };
    stripeAccount: {
      __typename: "StripeAccount";
      id: string;
      charges_enabled: boolean;
    } | null;
    emailPreference: {
      __typename: "EmailPreference";
      marketing: boolean;
      community: boolean;
    };
    profile: {
      __typename: "Profile";
      username: string | null;
      profileImageUrl: string | null;
    } | null;
  };
};

export type VerifyPhoneNumberMutationVariables = Types.Exact<{
  verifyPhone: Types.VerifyPhoneNumberInput;
}>;

export type VerifyPhoneNumberMutation = {
  verifyPhoneNumber: {
    __typename: "User";
    id: string;
    authMethod: string;
    personalInformation: {
      __typename: "PersonalInformation";
      email: string | null;
      phoneNumber: string | null;
      phone: {
        __typename: "PhoneNumber";
        number: string;
        isVerified: boolean;
      } | null;
    };
    blockchainAccount: {
      __typename: "EOA";
      publicAddress: string;
      blockchain: string | null;
    };
    discord: {
      __typename: "DiscordLinkedAccountData";
      user: {
        __typename: "DiscordLinkedAccountUserInfo";
        id: string | null;
        username: string | null;
        discriminator: string | null;
      } | null;
    } | null;
    terms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    creatorTerms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    flagVariations: {
      __typename: "UserFlagVariations";
      paymentsEnableFiatOffers: boolean;
      cryptoByoWalletAuth: boolean;
      onboardingSquadCreatorTermsOfService: boolean;
      scaffoldBecomeCreatorCta: boolean;
      scaffoldEnableCreatorDashboard: boolean;
      scaffoldEnableAccountEmailPreferencesPage: boolean;
      scaffoldEnableAccountTransactionHistoryPage: boolean;
      scaffoldEnableAccountWalletPage: boolean;
      scaffoldEnableMagiclinkPrivateKeyExport: boolean;
      paymentsStripeConnectOnboarding: boolean;
      creatorDashboardCreateOneCommunityPerUser: boolean;
      creatorDashboardDisableCreateCommunity: boolean;
      creatorDashboardRequirePhoneValidation: boolean;
      creatorDashboardMembersTab: boolean;
      creatorDashboardThemeTab: boolean;
      creatorDashboardIncomeTab: boolean;
      incomeAllowWithdraw: boolean;
      incomeShowWEthEarnings: boolean;
      incomeShowUsdEarnings: boolean;
      enableCryptoPayments: boolean;
      creatorDashboardCustomBenefitTokens: boolean;
      creatorDashboardEnableMintTokenLimit: boolean;
      creatorDashboardVideoAssetOnToken: boolean;
      shopifyOnboardWithBuyButton: boolean;
      creatorDashboardShowDiscordServerConfigPainel: boolean;
      enableDeployDetectionAndForcePageReload: boolean;
      emergencyStopClaimToken: boolean;
      emergencyStopDeployContract: boolean;
      emergencyStopShowContentFeedPosts: boolean;
      emergencyStopNewContentFeedPosts: boolean;
      emergencyStopMintNewTokens: boolean;
      emergencyStopSendTokensToAll: boolean;
      accountsRequireCaptchaForCreation: boolean;
      contentFeedEnableNewPostEditor: boolean;
      emergencyAlertFrontendMessage: string;
      releasePartyAccessFeature: string;
      releasePartyUpdateUserProfile: string;
      discordSyncBenefitsButton: boolean;
      enableResizedImages: boolean;
      segmentEnableInCommunities: boolean;
      enableMintingNonTransferable: boolean;
    };
    stripeAccount: {
      __typename: "StripeAccount";
      id: string;
      charges_enabled: boolean;
    } | null;
    emailPreference: {
      __typename: "EmailPreference";
      marketing: boolean;
      community: boolean;
    };
    profile: {
      __typename: "Profile";
      username: string | null;
      profileImageUrl: string | null;
    } | null;
  };
};

export type UpdateProfileMutationVariables = Types.Exact<{
  profile: Types.UpdateProfileInput;
}>;

export type UpdateProfileMutation = {
  updateProfile: {
    __typename: "User";
    id: string;
    authMethod: string;
    personalInformation: {
      __typename: "PersonalInformation";
      email: string | null;
      phoneNumber: string | null;
      phone: {
        __typename: "PhoneNumber";
        number: string;
        isVerified: boolean;
      } | null;
    };
    blockchainAccount: {
      __typename: "EOA";
      publicAddress: string;
      blockchain: string | null;
    };
    discord: {
      __typename: "DiscordLinkedAccountData";
      user: {
        __typename: "DiscordLinkedAccountUserInfo";
        id: string | null;
        username: string | null;
        discriminator: string | null;
      } | null;
    } | null;
    terms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    creatorTerms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    flagVariations: {
      __typename: "UserFlagVariations";
      paymentsEnableFiatOffers: boolean;
      cryptoByoWalletAuth: boolean;
      onboardingSquadCreatorTermsOfService: boolean;
      scaffoldBecomeCreatorCta: boolean;
      scaffoldEnableCreatorDashboard: boolean;
      scaffoldEnableAccountEmailPreferencesPage: boolean;
      scaffoldEnableAccountTransactionHistoryPage: boolean;
      scaffoldEnableAccountWalletPage: boolean;
      scaffoldEnableMagiclinkPrivateKeyExport: boolean;
      paymentsStripeConnectOnboarding: boolean;
      creatorDashboardCreateOneCommunityPerUser: boolean;
      creatorDashboardDisableCreateCommunity: boolean;
      creatorDashboardRequirePhoneValidation: boolean;
      creatorDashboardMembersTab: boolean;
      creatorDashboardThemeTab: boolean;
      creatorDashboardIncomeTab: boolean;
      incomeAllowWithdraw: boolean;
      incomeShowWEthEarnings: boolean;
      incomeShowUsdEarnings: boolean;
      enableCryptoPayments: boolean;
      creatorDashboardCustomBenefitTokens: boolean;
      creatorDashboardEnableMintTokenLimit: boolean;
      creatorDashboardVideoAssetOnToken: boolean;
      shopifyOnboardWithBuyButton: boolean;
      creatorDashboardShowDiscordServerConfigPainel: boolean;
      enableDeployDetectionAndForcePageReload: boolean;
      emergencyStopClaimToken: boolean;
      emergencyStopDeployContract: boolean;
      emergencyStopShowContentFeedPosts: boolean;
      emergencyStopNewContentFeedPosts: boolean;
      emergencyStopMintNewTokens: boolean;
      emergencyStopSendTokensToAll: boolean;
      accountsRequireCaptchaForCreation: boolean;
      contentFeedEnableNewPostEditor: boolean;
      emergencyAlertFrontendMessage: string;
      releasePartyAccessFeature: string;
      releasePartyUpdateUserProfile: string;
      discordSyncBenefitsButton: boolean;
      enableResizedImages: boolean;
      segmentEnableInCommunities: boolean;
      enableMintingNonTransferable: boolean;
    };
    stripeAccount: {
      __typename: "StripeAccount";
      id: string;
      charges_enabled: boolean;
    } | null;
    emailPreference: {
      __typename: "EmailPreference";
      marketing: boolean;
      community: boolean;
    };
    profile: {
      __typename: "Profile";
      username: string | null;
      profileImageUrl: string | null;
    } | null;
  };
};

export type UploadUserProfileImageMutationVariables = Types.Exact<{
  mime: Types.Scalars["String"];
  asset: Types.Scalars["Upload"];
}>;

export type UploadUserProfileImageMutation = {
  uploadProfileImage: {
    __typename: "User";
    id: string;
    authMethod: string;
    personalInformation: {
      __typename: "PersonalInformation";
      email: string | null;
      phoneNumber: string | null;
      phone: {
        __typename: "PhoneNumber";
        number: string;
        isVerified: boolean;
      } | null;
    };
    blockchainAccount: {
      __typename: "EOA";
      publicAddress: string;
      blockchain: string | null;
    };
    discord: {
      __typename: "DiscordLinkedAccountData";
      user: {
        __typename: "DiscordLinkedAccountUserInfo";
        id: string | null;
        username: string | null;
        discriminator: string | null;
      } | null;
    } | null;
    terms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    creatorTerms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    flagVariations: {
      __typename: "UserFlagVariations";
      paymentsEnableFiatOffers: boolean;
      cryptoByoWalletAuth: boolean;
      onboardingSquadCreatorTermsOfService: boolean;
      scaffoldBecomeCreatorCta: boolean;
      scaffoldEnableCreatorDashboard: boolean;
      scaffoldEnableAccountEmailPreferencesPage: boolean;
      scaffoldEnableAccountTransactionHistoryPage: boolean;
      scaffoldEnableAccountWalletPage: boolean;
      scaffoldEnableMagiclinkPrivateKeyExport: boolean;
      paymentsStripeConnectOnboarding: boolean;
      creatorDashboardCreateOneCommunityPerUser: boolean;
      creatorDashboardDisableCreateCommunity: boolean;
      creatorDashboardRequirePhoneValidation: boolean;
      creatorDashboardMembersTab: boolean;
      creatorDashboardThemeTab: boolean;
      creatorDashboardIncomeTab: boolean;
      incomeAllowWithdraw: boolean;
      incomeShowWEthEarnings: boolean;
      incomeShowUsdEarnings: boolean;
      enableCryptoPayments: boolean;
      creatorDashboardCustomBenefitTokens: boolean;
      creatorDashboardEnableMintTokenLimit: boolean;
      creatorDashboardVideoAssetOnToken: boolean;
      shopifyOnboardWithBuyButton: boolean;
      creatorDashboardShowDiscordServerConfigPainel: boolean;
      enableDeployDetectionAndForcePageReload: boolean;
      emergencyStopClaimToken: boolean;
      emergencyStopDeployContract: boolean;
      emergencyStopShowContentFeedPosts: boolean;
      emergencyStopNewContentFeedPosts: boolean;
      emergencyStopMintNewTokens: boolean;
      emergencyStopSendTokensToAll: boolean;
      accountsRequireCaptchaForCreation: boolean;
      contentFeedEnableNewPostEditor: boolean;
      emergencyAlertFrontendMessage: string;
      releasePartyAccessFeature: string;
      releasePartyUpdateUserProfile: string;
      discordSyncBenefitsButton: boolean;
      enableResizedImages: boolean;
      segmentEnableInCommunities: boolean;
      enableMintingNonTransferable: boolean;
    };
    stripeAccount: {
      __typename: "StripeAccount";
      id: string;
      charges_enabled: boolean;
    } | null;
    emailPreference: {
      __typename: "EmailPreference";
      marketing: boolean;
      community: boolean;
    };
    profile: {
      __typename: "Profile";
      username: string | null;
      profileImageUrl: string | null;
    } | null;
  };
};

export type DeleteUserProfileImageMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type DeleteUserProfileImageMutation = {
  deleteProfileImage: {
    __typename: "User";
    id: string;
    authMethod: string;
    personalInformation: {
      __typename: "PersonalInformation";
      email: string | null;
      phoneNumber: string | null;
      phone: {
        __typename: "PhoneNumber";
        number: string;
        isVerified: boolean;
      } | null;
    };
    blockchainAccount: {
      __typename: "EOA";
      publicAddress: string;
      blockchain: string | null;
    };
    discord: {
      __typename: "DiscordLinkedAccountData";
      user: {
        __typename: "DiscordLinkedAccountUserInfo";
        id: string | null;
        username: string | null;
        discriminator: string | null;
      } | null;
    } | null;
    terms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    creatorTerms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    flagVariations: {
      __typename: "UserFlagVariations";
      paymentsEnableFiatOffers: boolean;
      cryptoByoWalletAuth: boolean;
      onboardingSquadCreatorTermsOfService: boolean;
      scaffoldBecomeCreatorCta: boolean;
      scaffoldEnableCreatorDashboard: boolean;
      scaffoldEnableAccountEmailPreferencesPage: boolean;
      scaffoldEnableAccountTransactionHistoryPage: boolean;
      scaffoldEnableAccountWalletPage: boolean;
      scaffoldEnableMagiclinkPrivateKeyExport: boolean;
      paymentsStripeConnectOnboarding: boolean;
      creatorDashboardCreateOneCommunityPerUser: boolean;
      creatorDashboardDisableCreateCommunity: boolean;
      creatorDashboardRequirePhoneValidation: boolean;
      creatorDashboardMembersTab: boolean;
      creatorDashboardThemeTab: boolean;
      creatorDashboardIncomeTab: boolean;
      incomeAllowWithdraw: boolean;
      incomeShowWEthEarnings: boolean;
      incomeShowUsdEarnings: boolean;
      enableCryptoPayments: boolean;
      creatorDashboardCustomBenefitTokens: boolean;
      creatorDashboardEnableMintTokenLimit: boolean;
      creatorDashboardVideoAssetOnToken: boolean;
      shopifyOnboardWithBuyButton: boolean;
      creatorDashboardShowDiscordServerConfigPainel: boolean;
      enableDeployDetectionAndForcePageReload: boolean;
      emergencyStopClaimToken: boolean;
      emergencyStopDeployContract: boolean;
      emergencyStopShowContentFeedPosts: boolean;
      emergencyStopNewContentFeedPosts: boolean;
      emergencyStopMintNewTokens: boolean;
      emergencyStopSendTokensToAll: boolean;
      accountsRequireCaptchaForCreation: boolean;
      contentFeedEnableNewPostEditor: boolean;
      emergencyAlertFrontendMessage: string;
      releasePartyAccessFeature: string;
      releasePartyUpdateUserProfile: string;
      discordSyncBenefitsButton: boolean;
      enableResizedImages: boolean;
      segmentEnableInCommunities: boolean;
      enableMintingNonTransferable: boolean;
    };
    stripeAccount: {
      __typename: "StripeAccount";
      id: string;
      charges_enabled: boolean;
    } | null;
    emailPreference: {
      __typename: "EmailPreference";
      marketing: boolean;
      community: boolean;
    };
    profile: {
      __typename: "Profile";
      username: string | null;
      profileImageUrl: string | null;
    } | null;
  };
};

export type AcceptCurrentUserTermsMutationVariables = Types.Exact<{
  terms: Types.AcceptTermsInput;
}>;

export type AcceptCurrentUserTermsMutation = {
  acceptCurrentUserTerms: {
    __typename: "User";
    terms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
    creatorTerms: {
      __typename: "TermsRecord";
      version: number | null;
      family: string | null;
    } | null;
  };
};

export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    authMethod
    personalInformation {
      email
      phoneNumber
      phone {
        number
        isVerified
      }
    }
    blockchainAccount {
      publicAddress
      blockchain
    }
    discord {
      user {
        id
        username
        discriminator
      }
    }
    terms {
      version
      family
    }
    creatorTerms {
      version
      family
    }
    flagVariations {
      ...AllUserFlagVariations
    }
    stripeAccount {
      id
      charges_enabled
    }
    emailPreference {
      marketing
      community
    }
    profile {
      username
      profileImageUrl
    }
  }
  ${AllUserFlagVariationsFragmentDoc}
`;
export const CreatedCommunitiesFieldsFragmentDoc = gql`
  fragment createdCommunitiesFields on User {
    createdCommunities {
      id
      name
      slug
      creatorAvatar {
        name
        imageUrl
      }
    }
  }
`;
export const UserTokensFragmentDoc = gql`
  fragment UserTokens on User {
    tokens {
      tokenId
      name
      balance
      supply
      imageUrl
      tokenType
      benefits {
        type
        customName
        releasePartyId
      }
      community {
        id
        slug
        name
        creatorAvatar {
          imageUrl
          name
        }
      }
    }
    pendingTokens {
      tokenId
      community {
        id
        slug
      }
    }
  }
`;
export const GetAnonymousDocument = gql`
  query GetAnonymous {
    anonymous {
      flagVariations {
        ...AllUserFlagVariations
      }
    }
  }
  ${AllUserFlagVariationsFragmentDoc}
`;

/**
 * __useGetAnonymousQuery__
 *
 * To run a query within a React component, call `useGetAnonymousQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnonymousQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnonymousQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnonymousQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAnonymousQuery,
    GetAnonymousQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnonymousQuery, GetAnonymousQueryVariables>(
    GetAnonymousDocument,
    options
  );
}
export function useGetAnonymousLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnonymousQuery,
    GetAnonymousQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnonymousQuery, GetAnonymousQueryVariables>(
    GetAnonymousDocument,
    options
  );
}
export type GetAnonymousQueryHookResult = ReturnType<
  typeof useGetAnonymousQuery
>;
export type GetAnonymousLazyQueryHookResult = ReturnType<
  typeof useGetAnonymousLazyQuery
>;
export type GetAnonymousQueryResult = Apollo.QueryResult<
  GetAnonymousQuery,
  GetAnonymousQueryVariables
>;
export const MeDocument = gql`
  query Me {
    currentUser {
      ...UserFields
      ...UserTokens
      ...createdCommunitiesFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${UserTokensFragmentDoc}
  ${CreatedCommunitiesFieldsFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AuthWithMagicLinkDocument = gql`
  mutation AuthWithMagicLink($didToken: String!) {
    authWithMagicLink(didToken: $didToken) {
      jwt
    }
  }
`;
export type AuthWithMagicLinkMutationFn = Apollo.MutationFunction<
  AuthWithMagicLinkMutation,
  AuthWithMagicLinkMutationVariables
>;

/**
 * __useAuthWithMagicLinkMutation__
 *
 * To run a mutation, you first call `useAuthWithMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthWithMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authWithMagicLinkMutation, { data, loading, error }] = useAuthWithMagicLinkMutation({
 *   variables: {
 *      didToken: // value for 'didToken'
 *   },
 * });
 */
export function useAuthWithMagicLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthWithMagicLinkMutation,
    AuthWithMagicLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthWithMagicLinkMutation,
    AuthWithMagicLinkMutationVariables
  >(AuthWithMagicLinkDocument, options);
}
export type AuthWithMagicLinkMutationHookResult = ReturnType<
  typeof useAuthWithMagicLinkMutation
>;
export type AuthWithMagicLinkMutationResult =
  Apollo.MutationResult<AuthWithMagicLinkMutation>;
export type AuthWithMagicLinkMutationOptions = Apollo.BaseMutationOptions<
  AuthWithMagicLinkMutation,
  AuthWithMagicLinkMutationVariables
>;
export const RefreshAccessTokenDocument = gql`
  mutation RefreshAccessToken {
    refreshAccessToken {
      jwt
    }
  }
`;
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>;

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshAccessTokenMutation,
    RefreshAccessTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshAccessTokenMutation,
    RefreshAccessTokenMutationVariables
  >(RefreshAccessTokenDocument, options);
}
export type RefreshAccessTokenMutationHookResult = ReturnType<
  typeof useRefreshAccessTokenMutation
>;
export type RefreshAccessTokenMutationResult =
  Apollo.MutationResult<RefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>;
export const UpdateCurrentUserEmailDocument = gql`
  mutation UpdateCurrentUserEmail($email: String!) {
    updateCurrentUserEmail(email: $email) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UpdateCurrentUserEmailMutationFn = Apollo.MutationFunction<
  UpdateCurrentUserEmailMutation,
  UpdateCurrentUserEmailMutationVariables
>;

/**
 * __useUpdateCurrentUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserEmailMutation, { data, loading, error }] = useUpdateCurrentUserEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateCurrentUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserEmailMutation,
    UpdateCurrentUserEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserEmailMutation,
    UpdateCurrentUserEmailMutationVariables
  >(UpdateCurrentUserEmailDocument, options);
}
export type UpdateCurrentUserEmailMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserEmailMutation
>;
export type UpdateCurrentUserEmailMutationResult =
  Apollo.MutationResult<UpdateCurrentUserEmailMutation>;
export type UpdateCurrentUserEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentUserEmailMutation,
  UpdateCurrentUserEmailMutationVariables
>;
export const UpdateEmailPreferenceDocument = gql`
  mutation UpdateEmailPreference(
    $emailPreference: UpdateEmailPreferenceInput!
  ) {
    updateEmailPreference(emailPreference: $emailPreference) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UpdateEmailPreferenceMutationFn = Apollo.MutationFunction<
  UpdateEmailPreferenceMutation,
  UpdateEmailPreferenceMutationVariables
>;

/**
 * __useUpdateEmailPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateEmailPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailPreferenceMutation, { data, loading, error }] = useUpdateEmailPreferenceMutation({
 *   variables: {
 *      emailPreference: // value for 'emailPreference'
 *   },
 * });
 */
export function useUpdateEmailPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailPreferenceMutation,
    UpdateEmailPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmailPreferenceMutation,
    UpdateEmailPreferenceMutationVariables
  >(UpdateEmailPreferenceDocument, options);
}
export type UpdateEmailPreferenceMutationHookResult = ReturnType<
  typeof useUpdateEmailPreferenceMutation
>;
export type UpdateEmailPreferenceMutationResult =
  Apollo.MutationResult<UpdateEmailPreferenceMutation>;
export type UpdateEmailPreferenceMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailPreferenceMutation,
  UpdateEmailPreferenceMutationVariables
>;
export const UpdatePhoneNumberDocument = gql`
  mutation UpdatePhoneNumber($phone: UpdatePhoneNumberInput!) {
    updatePhoneNumber(phone: $phone) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UpdatePhoneNumberMutationFn = Apollo.MutationFunction<
  UpdatePhoneNumberMutation,
  UpdatePhoneNumberMutationVariables
>;

/**
 * __useUpdatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberMutation, { data, loading, error }] = useUpdatePhoneNumberMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdatePhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePhoneNumberMutation,
    UpdatePhoneNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePhoneNumberMutation,
    UpdatePhoneNumberMutationVariables
  >(UpdatePhoneNumberDocument, options);
}
export type UpdatePhoneNumberMutationHookResult = ReturnType<
  typeof useUpdatePhoneNumberMutation
>;
export type UpdatePhoneNumberMutationResult =
  Apollo.MutationResult<UpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  UpdatePhoneNumberMutation,
  UpdatePhoneNumberMutationVariables
>;
export const VerifyPhoneNumberDocument = gql`
  mutation VerifyPhoneNumber($verifyPhone: VerifyPhoneNumberInput!) {
    verifyPhoneNumber(verifyPhone: $verifyPhone) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type VerifyPhoneNumberMutationFn = Apollo.MutationFunction<
  VerifyPhoneNumberMutation,
  VerifyPhoneNumberMutationVariables
>;

/**
 * __useVerifyPhoneNumberMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneNumberMutation, { data, loading, error }] = useVerifyPhoneNumberMutation({
 *   variables: {
 *      verifyPhone: // value for 'verifyPhone'
 *   },
 * });
 */
export function useVerifyPhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyPhoneNumberMutation,
    VerifyPhoneNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyPhoneNumberMutation,
    VerifyPhoneNumberMutationVariables
  >(VerifyPhoneNumberDocument, options);
}
export type VerifyPhoneNumberMutationHookResult = ReturnType<
  typeof useVerifyPhoneNumberMutation
>;
export type VerifyPhoneNumberMutationResult =
  Apollo.MutationResult<VerifyPhoneNumberMutation>;
export type VerifyPhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  VerifyPhoneNumberMutation,
  VerifyPhoneNumberMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($profile: UpdateProfileInput!) {
    updateProfile(profile: $profile) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult =
  Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const UploadUserProfileImageDocument = gql`
  mutation UploadUserProfileImage($mime: String!, $asset: Upload!) {
    uploadProfileImage(mime: $mime, asset: $asset) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UploadUserProfileImageMutationFn = Apollo.MutationFunction<
  UploadUserProfileImageMutation,
  UploadUserProfileImageMutationVariables
>;

/**
 * __useUploadUserProfileImageMutation__
 *
 * To run a mutation, you first call `useUploadUserProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserProfileImageMutation, { data, loading, error }] = useUploadUserProfileImageMutation({
 *   variables: {
 *      mime: // value for 'mime'
 *      asset: // value for 'asset'
 *   },
 * });
 */
export function useUploadUserProfileImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadUserProfileImageMutation,
    UploadUserProfileImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadUserProfileImageMutation,
    UploadUserProfileImageMutationVariables
  >(UploadUserProfileImageDocument, options);
}
export type UploadUserProfileImageMutationHookResult = ReturnType<
  typeof useUploadUserProfileImageMutation
>;
export type UploadUserProfileImageMutationResult =
  Apollo.MutationResult<UploadUserProfileImageMutation>;
export type UploadUserProfileImageMutationOptions = Apollo.BaseMutationOptions<
  UploadUserProfileImageMutation,
  UploadUserProfileImageMutationVariables
>;
export const DeleteUserProfileImageDocument = gql`
  mutation DeleteUserProfileImage {
    deleteProfileImage {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type DeleteUserProfileImageMutationFn = Apollo.MutationFunction<
  DeleteUserProfileImageMutation,
  DeleteUserProfileImageMutationVariables
>;

/**
 * __useDeleteUserProfileImageMutation__
 *
 * To run a mutation, you first call `useDeleteUserProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserProfileImageMutation, { data, loading, error }] = useDeleteUserProfileImageMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserProfileImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserProfileImageMutation,
    DeleteUserProfileImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserProfileImageMutation,
    DeleteUserProfileImageMutationVariables
  >(DeleteUserProfileImageDocument, options);
}
export type DeleteUserProfileImageMutationHookResult = ReturnType<
  typeof useDeleteUserProfileImageMutation
>;
export type DeleteUserProfileImageMutationResult =
  Apollo.MutationResult<DeleteUserProfileImageMutation>;
export type DeleteUserProfileImageMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserProfileImageMutation,
  DeleteUserProfileImageMutationVariables
>;
export const AcceptCurrentUserTermsDocument = gql`
  mutation AcceptCurrentUserTerms($terms: AcceptTermsInput!) {
    acceptCurrentUserTerms(terms: $terms) {
      terms {
        version
        family
      }
      creatorTerms {
        version
        family
      }
    }
  }
`;
export type AcceptCurrentUserTermsMutationFn = Apollo.MutationFunction<
  AcceptCurrentUserTermsMutation,
  AcceptCurrentUserTermsMutationVariables
>;

/**
 * __useAcceptCurrentUserTermsMutation__
 *
 * To run a mutation, you first call `useAcceptCurrentUserTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCurrentUserTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCurrentUserTermsMutation, { data, loading, error }] = useAcceptCurrentUserTermsMutation({
 *   variables: {
 *      terms: // value for 'terms'
 *   },
 * });
 */
export function useAcceptCurrentUserTermsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptCurrentUserTermsMutation,
    AcceptCurrentUserTermsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptCurrentUserTermsMutation,
    AcceptCurrentUserTermsMutationVariables
  >(AcceptCurrentUserTermsDocument, options);
}
export type AcceptCurrentUserTermsMutationHookResult = ReturnType<
  typeof useAcceptCurrentUserTermsMutation
>;
export type AcceptCurrentUserTermsMutationResult =
  Apollo.MutationResult<AcceptCurrentUserTermsMutation>;
export type AcceptCurrentUserTermsMutationOptions = Apollo.BaseMutationOptions<
  AcceptCurrentUserTermsMutation,
  AcceptCurrentUserTermsMutationVariables
>;
