import React from "react";

import { css, Theme } from "@emotion/react";
import { BigNumber, ethers } from "ethers";

import Text from "components/Typography/Text";
import { Dollars } from "components/money";
import {
  currencyCode,
  formatCurrency,
  roundupAmount,
} from "utils/formatting/currency";
import { Maybe } from "utils/types";

const styles = {
  incomeRowCss: (theme: Theme) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${theme.divider};

    :last-child {
      border: none;
    }
  `,
  rowTitle: css`
    display: flex;
    align-items: center;
  `,
  amountColumn: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
  `,
};

type CryptoIncomeItem = {
  title: string;
  coin: string;
  amount: string;
  inUSD?: Maybe<number>;
  bold?: boolean;
};

type CryptoIncomeProps = {
  items: CryptoIncomeItem[];
};

const CryptoIncomeRow: React.FC<CryptoIncomeItem> = ({
  title,
  coin,
  amount,
  inUSD,
  bold,
}) => (
  <div css={styles.incomeRowCss}>
    <div css={styles.rowTitle}>
      <Text bold={bold}>{title}</Text>
    </div>
    <div css={styles.amountColumn}>
      <Text bold>
        {roundupAmount(amount)} {currencyCode(coin)}
      </Text>
      {inUSD ? (
        <Text color="secondary" as="div">
          ≈ <Dollars amount={inUSD} />
        </Text>
      ) : null}
    </div>
  </div>
);

const withSummaryWETH = (items: CryptoIncomeItem[], title: string) => {
  if (items.length < 2) {
    return items;
  }

  const { amount, inUSD } = items.reduce(
    (sum, item) => {
      return {
        amount: sum.amount.add(ethers.utils.parseEther(item.amount)),
        inUSD: sum.inUSD + (item.inUSD || 0),
      };
    },
    {
      amount: BigNumber.from(0),
      inUSD: 0,
    }
  );
  const total = {
    title,
    coin: items[0].coin,
    amount: ethers.utils.formatEther(amount),
    inUSD,
    bold: true,
  };
  return [...items, total];
};

export const EthIncomeSection: React.FC<CryptoIncomeProps> = ({ items }) => {
  const rows = withSummaryWETH(items, "Total ETH Earnings").map(
    (item, index) => <CryptoIncomeRow key={index} {...item} />
  );
  return <>{rows}</>;
};

export const OtherCryptoIncomeSection: React.FC<CryptoIncomeProps> = ({
  items,
}) => {
  const rows = items.map((item, index) => (
    <CryptoIncomeRow key={index} {...item} />
  ));
  return <>{rows}</>;
};

type FiatIncomeItem = {
  amount: number;
  currency: string;
};

const CurrencyDisplay = ({
  amount,
  currency,
}: {
  amount: number;
  currency: string;
}) => {
  const value = formatCurrency(amount, currency);
  return (
    <>
      {value} {currencyCode(currency)}
    </>
  );
};

export const FiatIncomeRow: React.FC<
  React.PropsWithChildren<FiatIncomeItem>
> = ({ children, amount, currency }) => (
  <div css={styles.incomeRowCss}>
    <div css={styles.rowTitle}>{children}</div>
    <div css={styles.amountColumn}>
      <Text bold as="div">
        <CurrencyDisplay amount={amount} currency={currency} />
      </Text>
    </div>
  </div>
);
