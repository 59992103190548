import React, { FC, memo, PropsWithChildren } from "react";

import * as styles from "./styles";

export type BackgroundProps = {
  color: "primary" | "secondary" | "community";
  show?: boolean;
  imageUrl?: string;
};

const Background: FC<PropsWithChildren<BackgroundProps>> = ({
  children,
  color = "primary",
  show = false,
  imageUrl,
}) =>
  show ? (
    <div css={styles.container(color, imageUrl)}>{children}</div>
  ) : (
    <>{children}</>
  );

export default memo(Background);
