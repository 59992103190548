import { css } from "@emotion/react";

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: inherit;
`;

export const image = css`
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  margin-bottom: 6px;
  width: 80px;
  height: 80px;
`;

export const title = css`
  margin: 0;
  position: relative;
  text-align: center;
`;

export const icon = css`
  margin-top: -2px;
  display: inline-block;

  path {
    fill: currentColor;
  }
`;
