import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type SyncStripeAccountMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SyncStripeAccountMutation = {
  syncStripeAccount: {
    __typename: "StripeAccount";
    id: string;
    charges_enabled: boolean;
  };
};

export type GenerateOnboardingLinkQueryVariables = Types.Exact<{
  communityId: Types.Scalars["String"];
}>;

export type GenerateOnboardingLinkQuery = {
  generateOnboardingLink: { __typename: "OnboardingLink"; url: string };
};

export type GenerateDashboardLinkQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GenerateDashboardLinkQuery = {
  generateDashboardLink: { __typename: "DashboardLink"; url: string };
};

export const SyncStripeAccountDocument = gql`
  mutation syncStripeAccount {
    syncStripeAccount {
      id
      charges_enabled
    }
  }
`;
export type SyncStripeAccountMutationFn = Apollo.MutationFunction<
  SyncStripeAccountMutation,
  SyncStripeAccountMutationVariables
>;

/**
 * __useSyncStripeAccountMutation__
 *
 * To run a mutation, you first call `useSyncStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncStripeAccountMutation, { data, loading, error }] = useSyncStripeAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncStripeAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncStripeAccountMutation,
    SyncStripeAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncStripeAccountMutation,
    SyncStripeAccountMutationVariables
  >(SyncStripeAccountDocument, options);
}
export type SyncStripeAccountMutationHookResult = ReturnType<
  typeof useSyncStripeAccountMutation
>;
export type SyncStripeAccountMutationResult =
  Apollo.MutationResult<SyncStripeAccountMutation>;
export type SyncStripeAccountMutationOptions = Apollo.BaseMutationOptions<
  SyncStripeAccountMutation,
  SyncStripeAccountMutationVariables
>;
export const GenerateOnboardingLinkDocument = gql`
  query generateOnboardingLink($communityId: String!) {
    generateOnboardingLink(communityId: $communityId) {
      url
    }
  }
`;

/**
 * __useGenerateOnboardingLinkQuery__
 *
 * To run a query within a React component, call `useGenerateOnboardingLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateOnboardingLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateOnboardingLinkQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGenerateOnboardingLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GenerateOnboardingLinkQuery,
    GenerateOnboardingLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GenerateOnboardingLinkQuery,
    GenerateOnboardingLinkQueryVariables
  >(GenerateOnboardingLinkDocument, options);
}
export function useGenerateOnboardingLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateOnboardingLinkQuery,
    GenerateOnboardingLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GenerateOnboardingLinkQuery,
    GenerateOnboardingLinkQueryVariables
  >(GenerateOnboardingLinkDocument, options);
}
export type GenerateOnboardingLinkQueryHookResult = ReturnType<
  typeof useGenerateOnboardingLinkQuery
>;
export type GenerateOnboardingLinkLazyQueryHookResult = ReturnType<
  typeof useGenerateOnboardingLinkLazyQuery
>;
export type GenerateOnboardingLinkQueryResult = Apollo.QueryResult<
  GenerateOnboardingLinkQuery,
  GenerateOnboardingLinkQueryVariables
>;
export const GenerateDashboardLinkDocument = gql`
  query generateDashboardLink {
    generateDashboardLink {
      url
    }
  }
`;

/**
 * __useGenerateDashboardLinkQuery__
 *
 * To run a query within a React component, call `useGenerateDashboardLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateDashboardLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateDashboardLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateDashboardLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GenerateDashboardLinkQuery,
    GenerateDashboardLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GenerateDashboardLinkQuery,
    GenerateDashboardLinkQueryVariables
  >(GenerateDashboardLinkDocument, options);
}
export function useGenerateDashboardLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateDashboardLinkQuery,
    GenerateDashboardLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GenerateDashboardLinkQuery,
    GenerateDashboardLinkQueryVariables
  >(GenerateDashboardLinkDocument, options);
}
export type GenerateDashboardLinkQueryHookResult = ReturnType<
  typeof useGenerateDashboardLinkQuery
>;
export type GenerateDashboardLinkLazyQueryHookResult = ReturnType<
  typeof useGenerateDashboardLinkLazyQuery
>;
export type GenerateDashboardLinkQueryResult = Apollo.QueryResult<
  GenerateDashboardLinkQuery,
  GenerateDashboardLinkQueryVariables
>;
