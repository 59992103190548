import React, { FC, Fragment } from "react";

import { Helmet } from "react-helmet-async";

import { BuyButton } from "apollo/graphql.generated";
import Title from "components/Typography/Title";

import * as styles from "./styles";

export interface Props {
  v2Layout?: boolean;
  buyButtons: BuyButton[];
}

export const ShopifyBuyButtons: FC<Props> = ({ buyButtons, v2Layout }) => {
  return (
    <>
      {!v2Layout && (
        <div css={styles.header}>
          <Title onBackgroundColor="primary">Shop</Title>
        </div>
      )}

      {buyButtons.map((buyButton, i) => (
        <Fragment key={i}>
          <div css={styles.productWrapper2}>
            <div id={buyButton.id}></div>
          </div>
          <Helmet>
            <script type="text/javascript">{buyButton.script}</script>
          </Helmet>
        </Fragment>
      ))}
    </>
  );
};
