import { css, Theme } from "@emotion/react";

import BackgroundNoiseImage from "../../assets/images/background-grain.png";

import { BackgroundProps as ComponentProps } from "./";

export const container =
  (color: ComponentProps["color"], imageUrl: ComponentProps["imageUrl"]) =>
  (theme: Theme) => {
    const bgImage = imageUrl
      ? css`
          background-image: url(${BackgroundNoiseImage}), url(${imageUrl});
          background-size: 400px 400px, cover;
          background-position-x: left, center;
          background-position-y: top, center;
          background-attachment: fixed;
        `
      : null;

    const bgColor =
      color !== "community"
        ? css`
            background-color: ${theme.background.colors[color]};
          `
        : css`
            background-color: ${theme.community.pageBackground.backgroundColor};
          `;

    return css`
      display: flex;
      flex-direction: column;
      flex: 1;

      ${bgColor}
      ${bgImage}
    `;
  };
