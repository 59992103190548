import { css, Theme } from "@emotion/react";

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const tokenImageContainer = css``;

export const tokenImageWrapper = css`
  position: relative;
`;

export const tokenImage = css`
  width: 100%;
  //FIXME: Drop shadow is compiled with wrong values causing iOS to make the element invisible.
  //filter: drop-shadow(0px 8px 96px rgba(0, 0, 0, 0.06));
`;

export const expandButtonContainer = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.6s ${theme.animations.easeOutQuart};
  margin-top: ${theme.verticalSpaces.large[4]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[4]};
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const expandButton = css`
  padding: 0 6px 6px 6px;
`;

export const expandTag = css`
  display: flex;

  svg {
    path {
      fill: currentColor;
    }

    :first-of-type {
      margin-right: 6px;
    }
  }
`;

export const expandIcon = css`
  width: 12px;
  height: 12px;
`;

export const emptyTokenWrapper = (theme: Theme) => css`
  width: 100%;
  max-width: 500px;
  max-height: 500px;

  ${theme.mediaQueries.smallOnly} {
    margin: 0 auto;
  }
`;

export const emptyToken = css`
  background-color: white;
  border: 1px dashed #000000;
  border-radius: 8px;

  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
`;

export const transparentBackground = css`
  background-color: inherit;
`;

export const emptyTokenText = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
