import React, { memo, FC, useMemo } from "react";

import { useReactiveVar } from "@apollo/client";

import { communityVar } from "apollo/reactive";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow-tilted-accented.svg";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { ANALYTICS_EVENT, EXTERNAL_URL, IN_DEVELOPMENT } from "config";
import useSegmentEvents from "hooks/useSegmentEvents";
import { maskAddress } from "utils/content";
import { Maybe } from "utils/types";

import * as styles from "./styles";

export type Props = {
  contractAddress?: Maybe<string>;
  tokenId?: Maybe<string>;
  tokenStandard?: Maybe<string>;
  disableOpenSeaLink?: boolean;
  showTitle?: boolean;
};

const TokenDetails: FC<Props> = ({
  contractAddress,
  tokenId,
  tokenStandard = "ERC-1155",
  disableOpenSeaLink = false,
  showTitle = true,
}) => {
  const { trackEvent } = useSegmentEvents();

  const communityData = useReactiveVar(communityVar);

  const OpenSeaLink = useMemo(() => {
    if (!contractAddress) return;

    // cannot view a local-dev community (local hardhat network) on opensea
    // default to testnet since local-dev frontend work often points to the staging env
    const baseUrl = IN_DEVELOPMENT
      ? EXTERNAL_URL.OPENSEA_TESTNET + "/mumbai"
      : EXTERNAL_URL.OPENSEA + "/matic";
    const link = `${baseUrl}/${contractAddress}/${tokenId}`;
    return (
      <a
        title={contractAddress}
        href={link}
        css={styles.link(disableOpenSeaLink)}
        target="_blank"
        rel="noreferrer"
        onClick={(event) => {
          if (disableOpenSeaLink) {
            return event.preventDefault();
          }

          const properties = {
            blockchain_public_address: contractAddress,
            token_id: tokenId,
          };

          trackEvent(ANALYTICS_EVENT.OPENSEA_LINK_OPEN, properties);
        }}
      >
        View on OpenSea
      </a>
    );
  }, [contractAddress]);

  const ContractAddress = useMemo(() => {
    if (!contractAddress) return;

    const reducedAddress = maskAddress(contractAddress);

    const link = `${EXTERNAL_URL.POLYGONSCAN}/${contractAddress}`;
    return (
      <a
        title={contractAddress}
        href={link}
        css={styles.link(false)}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          const properties = {
            blockchain_public_address: contractAddress,
          };

          trackEvent(ANALYTICS_EVENT.POLYGONSCAN_LINK_OPEN, properties);
        }}
      >
        {reducedAddress}
      </a>
    );
  }, [contractAddress]);

  return (
    <div>
      {showTitle && <Title size="xsmall">Details</Title>}

      <Text as="div" additionalCss={styles.tokenDetailsList}>
        <ul>
          <li>
            <span>OpenSea</span> <span>{OpenSeaLink}</span>{" "}
            <span css={styles.arrow}>
              <ArrowIcon css={styles.arrowIcon(disableOpenSeaLink)} />
            </span>
          </li>
          <li>
            <span>Contract Address</span> <span>{ContractAddress}</span>{" "}
            <span css={styles.arrow}>
              <ArrowIcon css={styles.arrowIcon(false)} />
            </span>
          </li>
          <li>
            <span>Token Standard</span> <span>{tokenStandard}</span>
          </li>
          <li>
            <span>Blockchain</span> <span>{communityData.networkName}</span>
          </li>
        </ul>
      </Text>
    </div>
  );
};

export default memo(TokenDetails);
