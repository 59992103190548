import React, { FC, memo, useCallback } from "react";

import Anchor from "components/Anchor";
import CardContainer from "components/CardContainer";
import CopyToClipboard from "components/CopyToClipboard";
import Text from "components/Typography/Text";
import { ANALYTICS_EVENT, EXTERNAL_URL } from "config";
import useSegmentEvents from "hooks/useSegmentEvents";
import { maskAddress } from "utils/content";

import * as styles from "./styles";

export interface Props {
  contractAddress: string;
}

const WithContract: FC<Props> = ({ contractAddress }) => {
  const { trackEvent } = useSegmentEvents();

  const handleOnClick = useCallback(() => {
    const properties = {
      blockchain_public_address: contractAddress,
    };
    trackEvent(ANALYTICS_EVENT.POLYGONSCAN_LINK_OPEN, properties);
  }, [contractAddress]);

  return (
    <CardContainer border background={"primary"} extraCss={styles.container}>
      <Text>{maskAddress(contractAddress)}</Text>
      <Text as="span" size="small" bold extraCss={styles.actions}>
        <CopyToClipboard
          extraCss={styles.copyAddressButton}
          toCopy={contractAddress}
        />

        <Anchor
          href={`${EXTERNAL_URL.POLYGONSCAN}/${contractAddress}`}
          target="_blank"
          rel="noreferrer"
          title={contractAddress}
          onClick={handleOnClick}
        >
          View on Polygonscan
        </Anchor>
      </Text>
    </CardContainer>
  );
};

export default memo(WithContract);
