import React, { FC, memo, useCallback, useEffect, useState } from "react";

import { useUploadCommunityLogoImageMutation } from "apollo/queries";
import ImageUpload from "components/CommunityCreator/ImageUpload";
import LoadingIcon from "components/LoadingIcon";
import { logError } from "services/logger";

export interface Props {
  communityId: string;
  communityLogoValue: string;
  onSetLogo: (logo: string) => void;
}

const CreatorAvatarUpdate: FC<Props> = ({
  communityId,
  communityLogoValue,
  onSetLogo,
}) => {
  const [doUploadCommunityLogoImageMutation] =
    useUploadCommunityLogoImageMutation();
  const [communityLogo, setCommunityLogo] =
    useState<string>(communityLogoValue);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setCommunityLogo(communityLogoValue);
  }, [communityLogoValue]);

  const handleFileAccept = useCallback(
    async (file: File) => {
      try {
        setLoading(true);
        setError("");
        const response = await doUploadCommunityLogoImageMutation({
          variables: {
            asset: file,
            communityId: communityId,
            mime: file.type,
          },
        });
        const logo = response.data?.uploadCommunityLogoImage?.imageUrl || "";
        setCommunityLogo(logo);
      } catch (error) {
        const message = "Upload failed";
        setError(message);
        await logError({
          error,
          message: `[handleCreatorAvatarChange] ${message}`,
        });
      } finally {
        setLoading(false);
      }
    },
    [communityId]
  );

  const handleFileReject = useCallback(async (error: string) => {
    setLoading(false);
    setError(error);
  }, []);

  useEffect(() => {
    onSetLogo(communityLogo);
  }, [communityLogo]);

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <ImageUpload
      id="communityLogoImage"
      label="Community Logo"
      labelType="title"
      dragActiveDescription="Drop here ..."
      primaryDescription="Drag and drop your community logo here"
      secondaryDescription={`This image acts as the logo for your community. \nUse a PNG or JPG file, recommended dimensions are 400px by 400px.`}
      onFileAccept={handleFileAccept}
      onFileReject={handleFileReject}
      hasRemove={false}
      error={error}
      imageUrl={communityLogo}
      maxSize={2}
    />
  );
};

export default memo(CreatorAvatarUpdate);
