import React, { FC, memo, MouseEventHandler, PropsWithChildren } from "react";

import { Interpolation, Theme } from "@emotion/react";

import { ComponentColors } from "styles/theme";
import { CommunityThemeBackgroundColorsKeys } from "styles/theme/communityTheme.types";
import {
  CardContainerBorderRadiusSizesKeys,
  CardContainerPaddingSizesKeys,
  ShadowsSizes,
} from "styles/theme/theme.types";

import * as styles from "./styles";

export type Background = keyof Pick<ComponentColors, "primary" | "secondary">;

export type CommunityCardContainerProps = {
  background?: CommunityThemeBackgroundColorsKeys;
  shadow?: ShadowsSizes;
  borderRadius?: CardContainerBorderRadiusSizesKeys;
  padding?: CardContainerPaddingSizesKeys;
  as?: "div" | "article";
  onClick?: MouseEventHandler<HTMLDivElement>;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
};

const CommunityCardContainer: FC<
  PropsWithChildren<CommunityCardContainerProps>
> = ({
  background = "primaryBackground",
  as = "div",
  borderRadius = "medium",
  padding = "medium",
  shadow,
  onClick,
  extraCss,
  children,
}) => {
  const Tag = as as NonNullable<CommunityCardContainerProps["as"]>;

  return (
    <Tag
      onClick={onClick}
      css={[
        styles.container(background, borderRadius, padding, shadow),
        extraCss,
      ]}
    >
      {children}
    </Tag>
  );
};

export default memo(CommunityCardContainer);
