import { css, Theme } from "@emotion/react";

import { bottomMargin, topMargin } from "styles/global/layout";

export const leftContainer = css`
  ${topMargin("11/48px")};
`;

export const rightContainer = css`
  ${topMargin("11/48px")};
`;

export const icon = (theme: Theme) => css`
  margin: ${theme.verticalSpaces.large[3]};
`;

export const linkButton = (theme: Theme) => css`
  font-weight: ${theme.text.fontWeights.bold};
  background-color: transparent;
  border: none;
  white-space: nowrap;
  text-align: center;
  padding: 0;
  margin-right: ${theme.verticalSpaces.large[5]};
`;
export const spacedFields = css`
  ${bottomMargin("10/40px")};
`;

export const sendToken = (theme: Theme) => css`
  color: ${theme.text.colors.primary};
  margin: 0 auto;
  ${topMargin("4/8px")}
  ${linkButton(theme)}
  align-self: center;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100% !important;
`;

export const loadingIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const designLayout = css`
  margin: 0 auto;
`;

export const statusBoxIcon = css`
  width: 32px;
  height: 32px;
`;
