import React, { ChangeEvent as ReactChangeEvent, memo } from "react";

import _uniqueId from "lodash/uniqueId";

import { ReactComponent as CheckIcon } from "assets/icons/check-within-circle.svg";
import Label from "components/Typography/Label";
import Text from "components/Typography/Text";

import * as styles from "./styles";
import { RadioButtonGroupProps } from "./types";

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  label,
  inputs,
  selected,
  setSelected,
}: RadioButtonGroupProps) => {
  const handleChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    setSelected(event.currentTarget.value);
  };

  return (
    <div css={styles.fieldGroup}>
      <Label size="small" additionalCss={styles.titleLabel}>
        {label}
      </Label>
      <div css={styles.container}>
        {inputs.map((input) => {
          const id = input.id ? input.id : _uniqueId("field-");
          return (
            <div key={id}>
              <input
                checked={selected == input.value}
                onChange={handleChange}
                type="radio"
                id={id}
                css={styles.defaultInput}
                {...input}
              />
              <label htmlFor={id} css={styles.label}>
                <Text as="span">{input.label}</Text>
                <div css={styles.inputIcon}>
                  <CheckIcon />
                </div>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(RadioButtonGroup);
