import { v4 } from "uuid";

import { IN_DEVELOPMENT, SARDINE_CLIENT_ID } from "config";

import { logError } from "./logger";
import {
  CollectionContextInterface,
  Nullable,
  Environment,
} from "./types/sardine";

const isDev = IN_DEVELOPMENT;
const environment: Environment = isDev ? "development" : "production";
export let sardineSdkLoadError: undefined | boolean = undefined;

const context = {
  clientId: SARDINE_CLIENT_ID as string,
  flow: location.pathname,
  environment: environment,
  parentElement: document.body,
  partnerId: "highlight",
};

let sdkLoaded = false;
const eventsQueue: Array<{
  type: "anonymous" | "loggedIn";
  userId?: string;
}> = [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let sardineContext: Nullable<CollectionContextInterface>;

export const setUpSardine = () => {
  if (
    typeof window === "undefined" ||
    !SARDINE_CLIENT_ID ||
    SARDINE_CLIENT_ID === "undefined"
  ) {
    IN_DEVELOPMENT && console.log(`⚪️ Sardine.ai not started.`);
    return;
  }

  try {
    IN_DEVELOPMENT &&
      console.log(`🟢 Sardine.ai inited. Key: ${SARDINE_CLIENT_ID}`);

    const sardineHost = isDev ? "api.dev.sardine.ai" : "api.sardine.ai";

    const loader = document.createElement("script");
    loader.type = "text/javascript";
    loader.async = true;
    loader.src = `https://${sardineHost}/assets/loader.min.js`;
    loader.onload = () => {
      IN_DEVELOPMENT && console.log(`🟢 Sardine.ai sdk loaded.`);

      sardineSdkLoadError = false;

      if (eventsQueue && !sdkLoaded) {
        sdkLoaded = true;
        for (const eventTrigger of eventsQueue) {
          if (eventTrigger.type === "loggedIn") {
            setSardineLoggedInUserContext(eventTrigger.userId!);
          } else {
            setSardineAnonymousUserContext();
          }
        }
      }
    };

    loader.onerror = () => {
      sardineSdkLoadError = true;
    };

    const s = document.getElementsByTagName("script")[0];
    s.parentNode!.insertBefore(loader, s);
  } catch (error) {
    logError({ error, message: "setup Sardine failed" });
  }
};

export const getSessionKey = () => {
  const sessionKey = v4();
  return sessionKey;
};

export const setSardineAnonymousUserContext = () => {
  if (!window._Sardine) {
    eventsQueue.push({ type: "anonymous" });
    return;
  }

  const sessionKey = getSessionKey();

  if (sardineContext) {
    sardineContext.updateConfig({
      flow: location.pathname,
      userIdHash: "",
      sessionKey,
    });
    IN_DEVELOPMENT && console.log(`🟡 Sardine.ai Anonymous context updated.`);
  } else {
    sardineContext = window._Sardine.createContext({
      ...context,
      sessionKey,
    });
    IN_DEVELOPMENT && console.log(`🟡 Sardine.ai Anonymous context created.`);
  }
};

export const setSardineLoggedInUserContext = (userId: string) => {
  if (!window._Sardine) {
    eventsQueue.push({ type: "loggedIn", userId });
    return;
  }

  if (sardineContext) {
    sardineContext.updateConfig({
      flow: location.pathname,
      userIdHash: userId,
    });
    IN_DEVELOPMENT &&
      console.log(`🟡 Sardine.ai logged in user context updated.`);
  } else {
    const sessionKey = getSessionKey();

    sardineContext = window._Sardine.createContext({
      ...context,
      userIdHash: userId,
      sessionKey,
    });
    IN_DEVELOPMENT &&
      console.log(`🟡 Sardine.ai logged in user context created.`);
  }
};
