/**
 * NOTE: when connecting to a testnet, TEST API keys must be used from the Magic dashboard (live API keys for eth mainnet)
 * there was an issue with using "rpc-mumbai.matic.today"
 * tracked here: https://githubmemory.com/repo/magiclabs/magic-js/issues/189
 * ^^ we will use a custom url
 */

import { ethers } from "ethers";
import { Magic } from "magic-sdk";

import { envConfig } from "../config";

const customNodeOptions = {
  rpcUrl: envConfig.polygon.networkUrl,
  chainId: envConfig.polygon.chainId,
};

/* Read more about test mode: https://magic.link/docs/introduction/test-mode */
const m = new Magic(process.env.REACT_APP_MAGIC_LINK_PUBLIC_KEY as string, {
  network: customNodeOptions,
  testMode: false,
});

m.network = "matic";

export const ethersProvider = new ethers.providers.Web3Provider(
  // TODO: look for fix to this via magic: https://githubmemory.com/repo/magiclabs/magic-js/issues/126. Recent issue.
  // For this reason, we pretty much have to ignore typing here
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  m.rpcProvider as any
);

export default m;
