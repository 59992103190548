import { ComponentProps, FC, memo } from "react";

import { Interpolation, Theme } from "@emotion/react";

import * as styles from "./BaseAnchor/styles";
import { LinkColor } from "./BaseAnchor/styles";

export type AnchorProps = {
  color?: LinkColor;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
} & ComponentProps<"a">;

const SystemAnchor: FC<AnchorProps> = ({
  children,
  color = "inherit",
  extraCss,
  ...rest
}) => (
  <a css={[styles.link(color), extraCss]} {...rest}>
    {children}
  </a>
);

export default memo(SystemAnchor);
