import { FC, memo } from "react";

import { layoutContext } from "apollo/reactive/layout";
import { NewAppButton } from "components/Button";
import CommunityAvatarSmall from "components/CommunityAvatarSmall";
import { MessageBanner } from "components/MessageBanner";
import CommunityText from "components/_Community/Text";
import { CardContainerByTheme } from "containers/CommunityV3/ContentFeed/Post";
import useSession from "hooks/useSession";

import * as styles from "./styles";
import { Props as CommentEditorProps } from "./types";
import { useLogic } from "./useLogic";

const CommentEditor: FC<CommentEditorProps> = ({
  communityId,
  releasePartyId,
  afterSubmit,
}) => {
  const { user } = useSession();
  const { theme } = layoutContext();
  const { handleSubmit, register, isSubmitting, setError, error } = useLogic({
    communityId,
    releasePartyId,
    afterSubmit,
  });

  const username = user?.personalInformation.email;
  const userAvatar = user?.profile?.profileImageUrl;
  return (
    <div css={styles.container}>
      <div css={styles.commenterAvatarContainer}>
        <div css={styles.commenterAvatar}>
          <CommunityAvatarSmall size="xlarge" imageUrl={userAvatar} />
        </div>
      </div>
      <div css={styles.middleContainer}>
        <div>
          <CommunityText bold>{username}</CommunityText>
        </div>
        <div css={styles.commentContainer}>
          <CardContainerByTheme theme={theme ?? "system"}>
            <form onSubmit={handleSubmit} id="newPostForm">
              <textarea
                css={styles.textarea}
                rows={4}
                placeholder="Leave a comment..."
                required={true}
                {...register("text")}
              />
            </form>
            <div css={styles.buttonContainer}>
              <NewAppButton
                loading={isSubmitting}
                color="primary"
                type="submit"
                form="newPostForm"
              >
                Post
              </NewAppButton>
            </div>
            <MessageBanner onClose={() => setError("")} message={error} />
          </CardContainerByTheme>
        </div>
      </div>
      <div css={styles.commenterEmptyAvatar} />
    </div>
  );
};

export default memo(CommentEditor);
