import React, { memo, useCallback, useEffect } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  COMMAND_PRIORITY_LOW,
  KEY_DELETE_COMMAND,
  NodeKey,
} from "lexical";

import { CrossSmall as CloseIcon } from "assets/icons.generated";

import AttachmentFloatingButton from "./AttachmentFloatingButton";

interface AttachmentRemoveButtonProps {
  isSelected: boolean;
  setSelected: (s: boolean) => void;
  nodeKey: NodeKey;
  nodeCheck: (n: unknown) => boolean;
}

const AttachmentRemoveButton = ({
  isSelected,
  setSelected,
  nodeKey,
  nodeCheck,
}: AttachmentRemoveButtonProps) => {
  const [editor] = useLexicalComposerContext();

  const onDelete = useCallback(
    (payload: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        payload.preventDefault();
        const node = $getNodeByKey(nodeKey);
        if (nodeCheck(node) && node) {
          node.remove();
        }
        setSelected(false);
      }
      return false;
    },
    [isSelected, nodeKey]
  );

  useEffect(() => {
    return editor.registerCommand(
      KEY_DELETE_COMMAND,
      onDelete,
      COMMAND_PRIORITY_LOW
    );
  }, [editor, onDelete]);

  const handleRemove = () => {
    editor.dispatchCommand(
      KEY_DELETE_COMMAND,
      new KeyboardEvent("keydown", {
        key: "Escape",
        code: "Escape",
      })
    );
  };

  if (!editor.isEditable()) return null;

  return (
    <AttachmentFloatingButton
      icon={<CloseIcon />}
      color="destructive"
      onClick={handleRemove}
    >
      Remove
    </AttachmentFloatingButton>
  );
};

export default memo(AttachmentRemoveButton);
