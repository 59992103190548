import React, { ComponentPropsWithRef, forwardRef, memo } from "react";

import { Interpolation, Theme } from "@emotion/react";

type VideoProps = ComponentPropsWithRef<"video">;

type TokenAnimationProps = VideoProps & {
  posterUri?: string;
  sourceUri: string;
  // Don't use it to rewrite to component base styles.
  extraCss?: Interpolation<Theme>;
};

const TokenAnimation = forwardRef<HTMLVideoElement, TokenAnimationProps>(
  ({ posterUri, sourceUri, extraCss, ...rest }: TokenAnimationProps, ref) => (
    <video
      key={sourceUri} // Hack to rerender when source url changes
      ref={ref}
      css={extraCss}
      poster={posterUri}
      controls
      loop
      playsInline
      controlsList="nodownload"
      {...rest}
    >
      <source src={sourceUri} />
      Your browser does not support video.
    </video>
  )
);

export default memo(TokenAnimation);
