import { createContext, useContext } from "react";

import { TokenType } from "apollo/graphql.generated";

type CryptoPrice = {
  inUSD?: number | null;
};
export type TokenMappedMetadata = {
  [key: string]: {
    id: string;
    total: number;
    available: number;
    checkoutId?: string;
    price?: string | null;
    currency?: string | null;
    crypto?: CryptoPrice | null;
    name?: string | null;
    description?: string | null;
    uri?: string | null;
    type?: TokenType;
    userHasIt: boolean;
    userHasPending: boolean;
  };
};

const TokenMapContext = createContext<TokenMappedMetadata>({});

export const TokenMapProvider = TokenMapContext.Provider;
export const useTokenMap = () => useContext(TokenMapContext);
