import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";

import { ANALYTICS_EVENT, IN_DEVELOPMENT, SEGMENT_WRITE_KEY } from "config";
import { logError } from "services/logger";

let segment: Analytics | undefined;

export type UserIdentify = {
  email: string;
  memberships: string[]; // array of community slugs
  blockchain_public_address: string;
  blockchain: string;
};

export const setUpSegment = async () => {
  if (
    typeof window === "undefined" ||
    !SEGMENT_WRITE_KEY ||
    SEGMENT_WRITE_KEY === "undefined"
  ) {
    IN_DEVELOPMENT && console.log(`⚪️ Segment.io not inited.`);
    return;
  }

  try {
    const [response] = await AnalyticsBrowser.load({
      writeKey: SEGMENT_WRITE_KEY,
    });

    IN_DEVELOPMENT &&
      console.log(`🟢 Segment.io Inited. Key: ${SEGMENT_WRITE_KEY}`);

    segment = response;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    await logError({ error, message: "setup Segment failed" });
  }
};

export const trackEvent = async (
  eventName: ANALYTICS_EVENT,
  properties?: Record<string, unknown>
) => {
  if (typeof window !== "undefined" && SEGMENT_WRITE_KEY) {
    if (!segment) {
      return;
    }

    if (segment) {
      if (properties) {
        segment?.track(eventName, properties);

        IN_DEVELOPMENT &&
          console.log(
            `🟡 Segment - Sending Event: ${eventName} · Properties\n`,
            properties
          );
      } else {
        segment?.track(eventName);

        IN_DEVELOPMENT &&
          console.log(`🟡 Segment - Sending Event: ${eventName} `);
      }
    }
  }
};

export const pageEvent = async (
  category: string,
  name: string,
  props?: Record<string, unknown>
) => {
  if (typeof window !== "undefined" && SEGMENT_WRITE_KEY) {
    if (!segment) {
      return;
    }

    if (segment) {
      segment?.page(category, name, props);

      IN_DEVELOPMENT &&
        console.log(`🟡 Segment - Sending Page Event`, {
          category,
          name,
          props,
        });
    }
  }
};

export const identifyEvent = async (
  userID: string,
  properties: Partial<UserIdentify>
) => {
  if (typeof window !== "undefined" && SEGMENT_WRITE_KEY) {
    if (!segment) {
      return;
    }

    if (segment) {
      IN_DEVELOPMENT &&
        console.log(
          `🟡 Segment - Sending Identify Event: ${userID} · Properties\n`,
          properties
        );

      return segment.identify(userID, properties);
    }
  }
};

export const reset = () => {
  if (segment) {
    IN_DEVELOPMENT && console.log("🟡 Segment - Reset");

    segment.reset();
  }
};
