import React, { FC, memo } from "react";

import CommunityLabel from "../../../components/_Community/Label";

import * as styles from "./styles";
import { Props } from "./types";

const DiscordStatusContainer: FC<Props> = ({ members, activeNow }) => {
  return members || activeNow ? (
    <div css={styles.statusContainer}>
      {members ? (
        <CommunityLabel as="span" size="xsmall">
          {members} Members
        </CommunityLabel>
      ) : null}
      {members && activeNow ? <span>&#183;</span> : null}
      {activeNow ? (
        <CommunityLabel as="span" size="xsmall">
          {activeNow} Active Now
        </CommunityLabel>
      ) : null}
    </div>
  ) : null;
};

export default memo(DiscordStatusContainer);
