import React, { memo, useState, useEffect } from "react";

import { Global } from "@emotion/react";

import CheckoutForm from "components/Checkout/Form/index";
import OrderPreview from "components/Checkout/OrderPreview";
import StripeElements from "components/Checkout/StripeElements";
import Layout from "components/Layout";
import { CommunitySEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import Title from "components/Typography/Title";
import { COMMUNITY_PAGE_TITLE } from "config";

import BackButton from "./BackButton";
import EmptyCart from "./EmptyCart";
import ErrorState from "./ErrorState";
import { useCheckoutLogic, useTokensVerify } from "./logic";
import * as styles from "./styles";

const Checkout = () => {
  const [isLoading, setLoading] = useState(true);

  const { offer, isCartEmpty, isOrderPreviewLoading } = useCheckoutLogic();

  const { verifyTokens, errorMessage } = useTokensVerify();

  useEffect(() => {
    verifyTokens().then(() => {
      setLoading(false);
    });
  }, [verifyTokens]);

  if (isLoading) {
    return <LoadingIcon />;
  }

  if (isCartEmpty) {
    return <EmptyCart />;
  }
  if (errorMessage) {
    return <ErrorState message={errorMessage} />;
  }

  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.CHECKOUT} />

      <Global styles={styles.checkoutInitiateGlobalStyles} />

      <Layout css={styles.container}>
        <div css={styles.header}>
          <BackButton />
          <Title size="large">Checkout</Title>
        </div>
        <div css={styles.content}>
          <div css={styles.checkout}>
            <StripeElements>
              <CheckoutForm onSubmit={verifyTokens} />
            </StripeElements>
          </div>

          <div css={styles.order}>
            {isOrderPreviewLoading ? (
              <OrderPreview loading={true} />
            ) : (
              <OrderPreview offer={offer!} loading={false} />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default memo(Checkout);
