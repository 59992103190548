import { css, Theme } from "@emotion/react";

export const arrow = (theme: Theme) => css`
  color: ${theme.text.colors.primary};
  width: 16px;
  height: 16px;
`;
export const link = (theme: Theme) => css`
  display: flex;
  cursor: pointer;
  width: 100%;
  padding: ${theme.verticalSpaces.large[6]};

  transition-property: transform;
  transition-duration: 0.4s;
  transition-timing-function: ${theme.animations.easeOutQuart};

  @media (hover: hover) {
    &:hover {
      transform: scale(1.02);
    }
  }
`;

export const tokenInfo = css`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
`;

export const tokenStatus = (theme: Theme) => css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 22px;

  ${theme.mediaQueries.smallOnly} {
    display: none;
  }
`;

export const tokenImage = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80px;
  height: 80px;
`;

export const tokenDescription = css`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

export const activeOffersText = (theme: Theme) => css`
  color: ${theme.colorMap.affirmative.default};
`;

export const destructiveText = (theme: Theme) => css`
  color: ${theme.colorMap.destructive.default};
`;

export const availableButton = css`
  min-height: 36px;
  height: auto;
  padding: 0 8px !important;

  span {
    font-size: 14px;
  }
`;
