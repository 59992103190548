import { css, Theme } from "@emotion/react";

import { variables } from "styles/variables";

export const signInOrCreateAccountGlobalStyles = css`
  body {
    background-color: ${variables.colors.gray1};
  }
`;

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CardContainer = (theme: Theme) => css`
  padding: 32px 40px;
  max-width: 576px;
  width: 100%;
  margin-top: 20px;

  ${theme.mediaQueries.smallOnly} {
    padding: 24px;
  }
`;
