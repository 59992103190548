import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const claimPage = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: ${theme.verticalSpaces.large[4]} 0;

  ${theme.mediaQueries.smallOnly} {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }
`;

export const textContent = css`
  margin-bottom: 0 !important;
`;

export const divider = css``;

export const removeButtonContainer = (theme: Theme) => css`
  padding: 0;
  @media (hover: hover) {
    cursor: pointer;

    svg:hover {
      fill: ${theme.colorMap.accent.default};
    }
  }

  ${theme.mediaQueries.smallOnly} {
    position: absolute;
    top: 16px;
    right: 0;
  }
`;

export const removeButton = (theme: Theme) => css`
  width: 12px;
  height: 12px;
  fill: ${theme.buttons.primary.backgroundColor};
  padding: 0;
`;

export const buttons = (theme: Theme) => css`
  align-self: center;
  justify-content: center;
  margin-left: auto;
  display: flex;
  align-items: center;

  ${theme.mediaQueries.smallOnly} {
    width: 100%;
    justify-content: flex-start;
    ${topMargin("6/16px")}
  }
`;

export const button = (theme: Theme) => css`
  color: ${theme.text.colors.primary};
  background-color: transparent;
  border: none;
  white-space: nowrap;
  text-align: center;
  padding: 0;
  margin-right: 16px;

  ${theme.mediaQueries.smallOnly} {
    width: auto;
  }
`;
