import React, { Dispatch, FC, SetStateAction } from "react";

import { ExpandArrows } from "assets/icons.generated";
import Label from "components/Typography/Label";
import Text from "components/Typography/Text";
import { ANALYTICS_EVENT } from "config";
import { trackEvent } from "services/segment";
import { Maybe } from "utils/types";

import * as styles from "./styles";

export type Props = {
  imageUrl?: Maybe<string>;
  alt?: string;
  transparentBackground?: boolean;
  setShowTokenDetailsModal?: Dispatch<SetStateAction<boolean>> | null;
};

const TokenImage: FC<Props> = ({
  imageUrl,
  transparentBackground = true,
  alt = "Token image",
  setShowTokenDetailsModal,
}) => {
  const handleTokenDetailsModalVisibility = () => {
    if (setShowTokenDetailsModal) {
      setShowTokenDetailsModal(true);
      trackEvent(ANALYTICS_EVENT.TOKEN_DETAILS_VIEWED);
    }
  };

  return (
    <div css={styles.container}>
      {imageUrl ? (
        <div>
          <div css={styles.tokenImageWrapper}>
            <img css={styles.tokenImage} src={imageUrl} alt={alt} />
          </div>
        </div>
      ) : (
        <div css={styles.emptyTokenWrapper}>
          <div
            css={[
              styles.emptyToken,
              transparentBackground && styles.transparentBackground,
            ]}
          >
            <div css={styles.emptyTokenText}>
              <Text size="small" bold>
                Token
              </Text>
            </div>
          </div>
        </div>
      )}
      {setShowTokenDetailsModal && (
        <div css={styles.expandButtonContainer}>
          <button
            css={styles.expandButton}
            onClick={handleTokenDetailsModalVisibility}
          >
            <Label as="span" size="small">
              <div css={styles.expandTag}>
                <ExpandArrows css={styles.expandIcon} />
                <span>Expand</span>
              </div>
            </Label>
          </button>
        </div>
      )}
    </div>
  );
};

export default TokenImage;
