import React, { memo } from "react";

import { Link } from "react-router-dom";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-default.svg";
import CommunityLabel from "components/_Community/Label";
import CommunityText from "components/_Community/Text";
import CommunityTitle from "components/_Community/Title";
import { COMMUNITY_URL } from "config";

import * as styles from "./styles";

export type TokenTooltipProps = {
  communityName: string;
  tokenName: string;
  imageUrl: string;
  tokenId: number;
};

const TokenTooltipContent = ({
  communityName,
  tokenName,
  imageUrl,
  tokenId,
}: TokenTooltipProps) => {
  const tokenDetailsUrl = COMMUNITY_URL.tokenDetail.replace(
    ":tokenId",
    tokenId.toString()
  );

  return (
    <div css={styles.tooltip}>
      <div css={styles.header}>
        <CommunityTitle size="xsmall">Your Membership</CommunityTitle>
        <CommunityText as="div" size="small" extraCss={styles.description}>
          {`You own one ${communityName} membership token. This token grants you access to the ${communityName} on Highlight.`}
        </CommunityText>
      </div>
      <div css={styles.token}>
        <Link to={tokenDetailsUrl} css={styles.link}>
          <div css={styles.tokenDetails}>
            <div css={styles.tokenImageWrapper}>
              <img
                css={styles.tokenImage}
                src={imageUrl}
                alt="Membership Token"
              />
            </div>
            <div css={styles.tokenDescription}>
              <CommunityLabel
                color="onPageBackgroundPrimaryColor"
                size="xsmall"
              >
                MEMBERSHIP TOKEN
              </CommunityLabel>
              <CommunityText as="div" bold size="large">
                {tokenName}
              </CommunityText>
              <CommunityText as="div" size="small">
                You Own This
              </CommunityText>
            </div>
          </div>
          <CommunityText as="span" extraCss={styles.arrowWrapper}>
            <ArrowRight css={styles.arrow} />
          </CommunityText>
        </Link>
      </div>
    </div>
  );
};

export default memo(TokenTooltipContent);
