import { css } from "@emotion/react";

export const benefitDescription = css`
  display: flex;
  svg {
    margin-right: 8px;
    path {
      fill: currentColor;
    }
  }
`;
