import React, { memo, useCallback, useEffect, useState, FC } from "react";

import { Link, useParams } from "react-router-dom";

import { ModalType, updateModalVar } from "apollo/reactive";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left-default.svg";
import { ReactComponent as HighlightBrandDark } from "assets/icons/brand-highlight-dark.svg";
import { ReactComponent as HighlightBrandLight } from "assets/icons/brand-highlight-light.svg";
import { ReactComponent as HighlightBrandSmallLight } from "assets/icons/brand-highlight-small-light.svg";
import { ReactComponent as HighlightBrandSmall } from "assets/icons/brand-highlight-small.svg";
import { ReactComponent as CloseX } from "assets/icons/close-x.svg";
import DefaultCommunityImage from "assets/images/default-community-avatar.png";
import Button from "components/Button";
import ButtonLink from "components/ButtonLink";
import Text from "components/Typography/Text";
import { useUserProfileDropdownLogic } from "components/UserProfileDropdown/logic";
import { FEATURE_FLAG } from "config";
import { useAuthForm } from "hooks/useAuthForm";
import useFeatureFlag from "hooks/useFeatureFlag";
import useSession from "hooks/useSession";

import { layoutContext } from "../../apollo/reactive/layout";
import CommunityButton from "../_Community/Button";

import * as styles from "./styles";

export type LayoutType =
  | "default"
  | "community"
  | "creatorCommunity"
  | "action"
  | "account"
  | "signin"
  | "createNewCommunity"
  | "tokenDetail";

export type NavBarProps = {
  layout: LayoutType;
  title?: string;
  subtitle?: string;
  cancelRedirect?: string;
  cancelCallback?(): void;
  page?: number;
  prevCallback?: () => void;
};

export type ActionProps = {
  title?: string;
  subtitle?: string;
  cancelRedirect: string;
  cancelCallback?(): void;
  page?: number;
  prevCallback?: () => void;
};

/** Inner Components */

type BrandProps = {
  redirectHome?: boolean;
  lightContent?: boolean;
};

const Brand: FC<BrandProps> = ({ redirectHome, lightContent }) =>
  redirectHome ? (
    <div css={styles.brand}>
      <Link to="/">
        {lightContent ? (
          <>
            <HighlightBrandLight css={styles.logoLarge} />
            <HighlightBrandSmallLight css={styles.logoSmall} />
          </>
        ) : (
          <>
            <HighlightBrandDark css={styles.logoLarge} />
            <HighlightBrandSmall css={styles.logoSmall} />
          </>
        )}
      </Link>
    </div>
  ) : (
    <div css={styles.brand}>
      {lightContent ? (
        <>
          <HighlightBrandLight css={styles.logoLarge} />
          <HighlightBrandSmallLight css={styles.logoSmall} />
        </>
      ) : (
        <>
          <HighlightBrandDark css={styles.logoLarge} />
          <HighlightBrandSmall css={styles.logoSmall} />
        </>
      )}
    </div>
  );

export type CancelButtonProps = {
  redirect?: string;
  cancelCallback?(): void;
};

const CancelButton: FC<CancelButtonProps> = ({ redirect, cancelCallback }) => {
  return (
    <div css={styles.cancelButtonContainer}>
      {redirect ? (
        <ButtonLink extraCss={styles.cancelButton} to={redirect}>
          <CloseX css={styles.cancelIcon} />
        </ButtonLink>
      ) : (
        <button onClick={cancelCallback}>
          <CloseX css={styles.cancelIcon} />
        </button>
      )}
    </div>
  );
};

export type PrevButtonProps = {
  prevCallback?: () => void;
};

const PrevButton: FC<PrevButtonProps> = ({ prevCallback }) => {
  return (
    <div css={styles.cancelButtonContainer}>
      <Button extraCss={styles.cancelButton} onClick={prevCallback}>
        <LeftArrow css={styles.cancelIcon} />
      </Button>
    </div>
  );
};

export type NavProps = {
  layout?: LayoutType;
};

const Nav: FC<NavProps> = ({ layout = "default" }) => {
  const { theme } = layoutContext();
  const { handleDropDownToggle } = useUserProfileDropdownLogic();
  const { user, magicClear } = useSession();
  const enableCryptoByoWallet = useFeatureFlag(
    FEATURE_FLAG.ENABLE_CRYPTO_BYO_WALLET
  );
  const { dispatch } = useAuthForm();

  const resetForm = () => dispatch({ type: "reset" });

  const handleShowOldLoginModal = () =>
    updateModalVar({
      showModal: ModalType.OLD_SIGN_IN_OR_CREATE_ACCOUNT,
      showView: "login",
    });

  const handleShowSigninModal = () => {
    // if user starts sign-in from magic, but then switches to create account
    // useMagicLink() in useSession will lock
    resetForm();
    magicClear();
    updateModalVar({
      showModal: ModalType.SIGN_IN_ACCOUNT,
      showView: "login",
    });
  };

  const handleShowCreateModal = () => {
    // if user starts sign-in from magic, but then switches to create account
    // useMagicLink() in useSession will lock
    resetForm();
    magicClear();
    updateModalVar({
      showModal: ModalType.CREATE_ACCOUNT,
      showView: "login",
    });
  };

  const buttonColor = (layout: LayoutType) => {
    if (layout == "creatorCommunity" || layout == "action") {
      return "secondary";
    }
    if (layout == "createNewCommunity") {
      return "secondary";
    }
    return "primary";
  };

  const navButton = (
    handleClick: React.MouseEventHandler<HTMLButtonElement>,
    text: string,
    buttonId: string
  ) => {
    if (theme === "community" && layout !== "tokenDetail") {
      return (
        <CommunityButton
          id={`main-navigation-${buttonId}-button`}
          size="small"
          color="onPageBackgroundBackgroundColor"
          onClick={handleClick}
          css={styles.myAccountButton}
        >
          {text}
        </CommunityButton>
      );
    }

    return (
      <Button
        id={`main-navigation-${buttonId}-button`}
        size="small"
        color={buttonColor(layout)}
        onClick={handleClick}
        css={styles.myAccountButton}
      >
        {text}
      </Button>
    );
  };

  return (
    <nav css={styles.nav} role="navigation" aria-label="Main">
      {user ? (
        navButton(
          handleDropDownToggle,
          user.personalInformation.email ?? "",
          "account"
        )
      ) : enableCryptoByoWallet ? (
        <>
          {navButton(handleShowCreateModal, "Create Account", "create-account")}
          {navButton(handleShowSigninModal, "Sign In", "sign-in")}
        </>
      ) : (
        navButton(
          handleShowOldLoginModal,
          "Sign In or Create Account",
          "sign-in"
        )
      )}
    </nav>
  );
};

const CreatorCommunity = () => {
  const { user } = useSession();
  const { createdCommunities } = user ?? {};
  const { communitySlug } = useParams();

  if (!createdCommunities) {
    return <Brand />;
  }

  const community = createdCommunities.find(
    (community) => community.slug === communitySlug
  );

  return (
    <div css={styles.creatorCommunity}>
      <div css={styles.creatorCommunityMedia}>
        <img
          src={community?.creatorAvatar?.imageUrl || DefaultCommunityImage}
          alt="Creator Avatar"
        />
      </div>

      <div css={styles.creatorCommunityDetails}>
        <Text inverted as="p" bold>
          <Text inverted size="small" as="small">
            {community?.creatorAvatar.name}
          </Text>
          {community?.name}
        </Text>
      </div>
    </div>
  );
};

const Action: FC<ActionProps> = ({
  title,
  subtitle,
  cancelRedirect,
  cancelCallback,
  page = 0,
  prevCallback,
}) => {
  return (
    <>
      {page > 0 ? <PrevButton prevCallback={prevCallback} /> : null}
      <div css={styles.actionTitleContainer}>
        <div css={styles.actionTitle}>
          <Text as="p">
            {title}
            <Text size="small" as="small">
              {subtitle}
            </Text>
          </Text>
        </div>
      </div>
      <CancelButton redirect={cancelRedirect} cancelCallback={cancelCallback} />
    </>
  );
};

/** Main Component */

const NavBar: FC<NavBarProps> = ({
  layout,
  title,
  subtitle,
  cancelRedirect,
  cancelCallback,
  page,
  prevCallback,
}) => {
  const { theme } = layoutContext();

  const [onScroll, setOnScroll] = useState<boolean>(false);

  const handleNavigation = useCallback(
    (e: Event) => {
      const window = e.currentTarget as Window;
      if (window.scrollY > 0 && !onScroll) {
        setOnScroll(true);
      } else if (window.scrollY <= 0 && onScroll) {
        setOnScroll(false);
      }
    },
    [onScroll]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const Content = () => {
    switch (layout) {
      case "community":
        return <Nav />;
      case "signin":
        return <Brand lightContent />;
      case "createNewCommunity":
        return (
          <>
            <Brand redirectHome lightContent />
            <Nav layout={layout} />
          </>
        );
      case "creatorCommunity":
        return (
          <>
            <CreatorCommunity />
            <Nav layout={layout} />
          </>
        );
      case "action":
        return (
          <Action
            title={title}
            subtitle={subtitle}
            cancelRedirect={cancelRedirect ?? ""}
            cancelCallback={cancelCallback}
            page={page}
            prevCallback={prevCallback}
          />
        );
      case "account":
      case "tokenDetail":
        return (
          <>
            <Brand redirectHome />
            <Nav layout={layout} />
          </>
        );
      default:
        return (
          <>
            <Brand redirectHome />
            <Nav />
          </>
        );
    }
  };

  return (
    <div
      css={[
        styles.container(onScroll, theme ?? "system", layout),
        styles.containerLayout(layout),
        styles.layoutColor(layout),
      ]}
    >
      <Content />
    </div>
  );
};

export default memo(NavBar);
