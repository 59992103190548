export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcceptTermsInput = {
  family: Scalars["String"];
  version: Scalars["Int"];
};

export type Account = {
  createdAt: Scalars["String"];
  evmAddresses: Array<EvmAddress>;
  id: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type AllowedMethods = {
  allowedMethods: Array<Scalars["String"]>;
};

export type AnalyticsInput = {
  eventDetails: Scalars["String"];
  eventType: EventType;
};

export type Anonymous = {
  flagVariations: UserFlagVariations;
};

/** App Features */
export enum AppFeature {
  TokenGate = "TokenGate",
  TokenMint = "TokenMint",
}

export type Attachment = {
  id: Scalars["String"];
  metadata?: Maybe<AttachmentMetadata>;
  type: AttachmentType;
};

export type AttachmentInput = {
  id: Scalars["String"];
  metadata?: Maybe<MediaMetadataInput>;
  type: AttachmentType;
};

export type AttachmentMetadata = MediaMetadata | TokenMetadata;

/** Type of Post Attachment. */
export enum AttachmentType {
  MEDIA = "MEDIA",
  TOKEN = "TOKEN",
}

export type Attribute = {
  key: Scalars["String"];
  value: Array<Scalars["String"]>;
};

export type AttributeInput = {
  key: Scalars["String"];
  value: Array<Scalars["String"]>;
};

export type Auth = {
  jwt: Scalars["String"];
  user: User;
};

/** Theme background type */
export enum BackgroundType {
  Gradient = "Gradient",
  Solid = "Solid",
}

export type BasePostReaction = {
  count?: Maybe<Scalars["Float"]>;
  reaction?: Maybe<Scalars["String"]>;
  userHasReacted?: Maybe<Scalars["Boolean"]>;
};

export type Benefit = {
  /** If the User has access or not to that benefit */
  hasAccess: Scalars["Boolean"];
  /** If the Community has enabled a given benefit */
  hasBenefit: Scalars["Boolean"];
  /** Tokens that were minted for the Community and can unlock this benefit */
  unlockableBy?: Maybe<Array<Token>>;
  /** Tokens that the User owns, and are currently unlocking this benefit */
  unlockedBy?: Maybe<Array<UserToken>>;
};

/** Type of Benefit for the Token. */
export enum BenefitType {
  CONTENT_FEED = "CONTENT_FEED",
  CUSTOM = "CUSTOM",
  DISCORD = "DISCORD",
  RELEASE_PARTY = "RELEASE_PARTY",
  SHOPIFY = "SHOPIFY",
}

export type BlockchainTaskRef = {
  taskId: Scalars["String"];
};

export type BlockchainTaskRefNullable = {
  taskId?: Maybe<Scalars["String"]>;
};

export type BlockchainTaskToken = {
  animationUri?: Maybe<Scalars["String"]>;
  benefits?: Maybe<Array<TokenBenefit>>;
  communityAddress: Scalars["String"];
  /** Number of tokens available for sale or distribution */
  creatorBalance?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
  mintedAsNonTransferable: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  /** Total number of tokens minted */
  supply?: Maybe<Scalars["Float"]>;
  taskId: Scalars["String"];
  tokenId?: Maybe<Scalars["ID"]>;
  tokenStatus: TokenStatus;
  tokenType: TokenType;
  uri?: Maybe<Scalars["String"]>;
};

/** Border type options for the community theme */
export enum BorderType {
  Pill = "Pill",
  Rounded = "Rounded",
  Straight = "Straight",
}

export type BuyButton = {
  id: Scalars["String"];
  script: Scalars["String"];
};

/** Type of Marketplace to Buy on */
export enum BuyMarketplace {
  OpenSea = "OpenSea",
}

export type Chain = {
  chainId: Scalars["Float"];
  contracts: Array<SystemContract>;
  enabled: Scalars["Boolean"];
  id: Scalars["String"];
  name: Scalars["String"];
  network: NetworkType;
  networkUrl: Scalars["String"];
  supportedFeatures: Array<AppFeature>;
  supportedPaymentCurrencies: Array<Currency>;
  testnet: Scalars["Boolean"];
};

export type Challenge = {
  messageToSign: Scalars["String"];
};

export type CheckoutInitiateResponse = {
  level?: Maybe<Scalars["String"]>;
  stripe: CheckoutStripe;
};

export type CheckoutItemsInput = {
  id: Scalars["String"];
};

export type CheckoutStripe = {
  paymentIntent?: Maybe<Scalars["String"]>;
};

export type Claim = {
  address: Scalars["String"];
  claimExpiryTimestamp: Scalars["String"];
  claimer: Scalars["String"];
  currency: Scalars["String"];
  id: Scalars["String"];
  maxClaimablePerUser: Scalars["Float"];
  maxClaimableViaVector: Scalars["Float"];
  numTokensToMint: Scalars["Float"];
  paymentRecipient: Scalars["String"];
  pricePerToken: Scalars["String"];
  vectorId: Scalars["String"];
};

export type ClaimObject = {
  claim: Claim;
  contract: ContractEntity;
  vector: MintVector;
};

export type ClaimToken = {
  contestId: Scalars["String"];
};

export type ClaimTokenInput = {
  claimableTokens: Scalars["Float"];
  name: Scalars["String"];
};

export type Collection = {
  accountId?: Maybe<Scalars["String"]>;
  address: Scalars["String"];
  chainId: Scalars["Float"];
  creatorAddresses?: Maybe<Array<EvmAddress>>;
  creatorEns?: Maybe<Scalars["String"]>;
  editions?: Maybe<Array<EditionToken>>;
  id: Scalars["String"];
  mintVectors: MintVector;
  name: Scalars["String"];
  onChainMetadata?: Maybe<Scalars["String"]>;
  openSeaCollectionUrl?: Maybe<Scalars["String"]>;
  status: CollectionStatus;
  symbol: Scalars["String"];
  type: CollectionContractType;
  usingOpenseaBlocklist?: Maybe<Scalars["Boolean"]>;
};

export type CollectionAttributes = {
  attributes: Array<Attribute>;
  slug: Scalars["String"];
};

export type CollectionBuyButton = {
  collectionComponentDivId: Scalars["String"];
  collectionId: Scalars["String"];
  domain: Scalars["String"];
  storefrontAccessToken: Scalars["String"];
};

/** Contract type to deploy */
export enum CollectionContractType {
  Editions = "Editions",
  EditionsSingle = "EditionsSingle",
  General = "General",
}

export type CollectionDetails = {
  chain?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  slug: Scalars["String"];
  standard?: Maybe<CollectionStandardType>;
};

export type CollectionNft = {
  balance: Scalars["String"];
  contract: Scalars["String"];
  id: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  standard?: Maybe<NftStandardType>;
};

/** Collection Standard Type */
export enum CollectionStandardType {
  OPENSEA = "OPENSEA",
}

/** Status of the collection's deployment */
export enum CollectionStatus {
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
  LIVE = "LIVE",
}

export type Comment = {
  communityId: Scalars["String"];
  entity: EntityId;
  id: Scalars["String"];
  profileImageUrl?: Maybe<Scalars["String"]>;
  reactions?: Maybe<Array<BasePostReaction>>;
  text?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type Community = {
  bannerImageUrl?: Maybe<Scalars["String"]>;
  benefitTokens?: Maybe<Array<Token>>;
  blockchainTaskToken: BlockchainTaskToken;
  claimPages: Array<Contest>;
  contactEmail?: Maybe<Scalars["String"]>;
  contentFeed: ContentFeed;
  contests: Array<Contest>;
  contract: Contract;
  creatorAvatar: CreatorAvatar;
  creatorId: Scalars["String"];
  creatorStripeBalance?: Maybe<StripeBalance>;
  creatorWethBalance?: Maybe<CryptoCurrency>;
  description?: Maybe<Scalars["String"]>;
  discord: DiscordData;
  discordServer?: Maybe<DiscordServer>;
  discordStatistics?: Maybe<DiscordStatistics>;
  flagVariations: CommunityFlagVariations;
  id: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  /** @deprecated The field 'tokens' should be used instead, as now multiple membership tokens are supported.  */
  membershipToken?: Maybe<Token>;
  membershipTokens?: Maybe<Array<Token>>;
  name: Scalars["String"];
  offers: Array<Offer>;
  owners?: Maybe<Array<Scalars["String"]>>;
  polygonContract?: Maybe<Contract>;
  royalties: Array<Royalty>;
  royaltySplitAddress?: Maybe<Scalars["String"]>;
  secondaryEarnings: Array<CryptoCurrency>;
  shop: ShopifyData;
  slug: Scalars["String"];
  status?: Maybe<CommunityStatus>;
  token: Token;
  tokens: Array<Token>;
  websiteTheme: WebsiteTheme;
};

export type CommunityBlockchainTaskTokenArgs = {
  taskId: Scalars["String"];
};

export type CommunityClaimPagesArgs = {
  tokenId: Scalars["Int"];
};

export type CommunityContestsArgs = {
  active?: Maybe<Scalars["Boolean"]>;
};

export type CommunityMembershipTokenArgs = {
  tokenId: Scalars["Int"];
  useChain: Scalars["Boolean"];
};

export type CommunityOffersArgs = {
  active?: Maybe<Scalars["Boolean"]>;
  onAboutPage?: Maybe<Scalars["Boolean"]>;
};

export type CommunityTokenArgs = {
  tokenId: Scalars["Int"];
  useChain?: Maybe<Scalars["Boolean"]>;
};

export type CommunityTokensArgs = {
  type: Scalars["String"];
  useChain?: Maybe<Scalars["Boolean"]>;
};

export type CommunityFlagVariations = {
  hideMembershipTokenReferences: Scalars["Boolean"];
};

export type CommunityIdentifier = {
  creatorAvatar: CreatorAvatar;
  id: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type CommunityNotification = {
  communityId: Scalars["String"];
  status: NotificationStatus;
  type: CommunityNotificationType;
};

/** Community Notification Type */
export enum CommunityNotificationType {
  CONTRACT = "CONTRACT",
}

/** The status of the community. */
export enum CommunityStatus {
  FROZEN = "FROZEN",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING_FIX = "PENDING_FIX",
  PREVIEW = "PREVIEW",
  PUBLIC = "PUBLIC",
}

export type Condition = {
  amount?: Maybe<Scalars["Float"]>;
  autoBuyButton?: Maybe<BuyMarketplace>;
  buyButtonUrl?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  data?: Maybe<GenericConditionData>;
  required?: Maybe<Scalars["Boolean"]>;
  selector?: Maybe<Scalars["String"]>;
  type?: Maybe<ConditionalType>;
};

export type ConditionInput = {
  amount?: Maybe<Scalars["Float"]>;
  autoBuyButton?: Maybe<BuyMarketplace>;
  buyButtonUrl?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  data?: Maybe<GenericConditionDataInput>;
  required?: Maybe<Scalars["Boolean"]>;
  selector?: Maybe<Scalars["String"]>;
  type?: Maybe<ConditionalType>;
};

export type ConditionResult = {
  amount?: Maybe<Scalars["Float"]>;
  autoBuyButton?: Maybe<BuyMarketplace>;
  buyButtonUrl?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  data?: Maybe<GenericConditionData>;
  passed: Scalars["Boolean"];
  required?: Maybe<Scalars["Boolean"]>;
  selector?: Maybe<Scalars["String"]>;
  type?: Maybe<ConditionalType>;
  userAmount?: Maybe<Scalars["Float"]>;
};

/** Type of Conditional */
export enum ConditionalType {
  ALLOWLIST = "ALLOWLIST",
  ATTRIBUTE = "ATTRIBUTE",
  COLLECTION = "COLLECTION",
  CURRENCY = "CURRENCY",
  NFT = "NFT",
}

export type ContentFeed = {
  benefit: Benefit;
  communityId: Scalars["String"];
  creatorAvatar: CreatorAvatar;
  posts?: Maybe<Array<Post>>;
};

export type Contest = {
  active: Scalars["Boolean"];
  autoWin?: Maybe<Scalars["Boolean"]>;
  communityId?: Maybe<Scalars["String"]>;
  contestPage: ContestPage;
  currentUserContestEntry?: Maybe<ContestEntry>;
  endAt?: Maybe<Scalars["String"]>;
  hasUserApplied?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  isPrivate?: Maybe<Scalars["Boolean"]>;
  showQuestion?: Maybe<Scalars["Boolean"]>;
  startAt: Scalars["String"];
  tokenIds: Array<Scalars["Float"]>;
  tokens: Array<Token>;
  tokensMetadata: TokensMetadata;
};

export type ContestEntry = {
  blockchainTransferId?: Maybe<Scalars["String"]>;
  contestId: Scalars["String"];
  decision?: Maybe<Decision>;
  id: Scalars["String"];
  user: ContestEntryUser;
  userSubmission: UserSubmission;
};

export type ContestEntryBody = {
  formFieldAnswer?: Maybe<Scalars["String"]>;
};

export type ContestEntryUser = {
  email: Scalars["String"];
  id: Scalars["String"];
};

export type ContestPage = {
  description: Scalars["String"];
  formFieldLabel?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type Contract = {
  contractAddress?: Maybe<Scalars["String"]>;
  contractType?: Maybe<ContractType>;
};

export type ContractAttributes = {
  attributes: Array<Attribute>;
  contract: Scalars["String"];
};

export type ContractDetails = {
  contract: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  network: Scalars["String"];
  openSeaCollectionUrl?: Maybe<Scalars["String"]>;
  standard?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
};

export type ContractEntity = {
  address: Scalars["String"];
  args: Scalars["String"];
  chainId: Scalars["Float"];
  method: Scalars["String"];
  value: Scalars["String"];
};

export type ContractNft = {
  attributes?: Maybe<Array<Attribute>>;
  backgroundColor?: Maybe<Scalars["String"]>;
  balance: Scalars["String"];
  contract: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  externalUrl?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  standard?: Maybe<Scalars["String"]>;
  uri?: Maybe<Scalars["String"]>;
};

/** The type of contract. */
export enum ContractType {
  Community = "Community",
  TokenManager = "TokenManager",
}

/** Theme Corners shape */
export enum CornersShape {
  Rounded = "Rounded",
  Square = "Square",
}

export type CreateCommunityInput = {
  creatorName: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type CreateEditionCollectionInput = {
  animationUrl?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  defaultTokenManager: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  imageUrl: Scalars["String"];
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  royaltyPercentageBPS: Scalars["String"];
  royaltyRecipientAddress: Scalars["String"];
  size?: Maybe<Scalars["Float"]>;
  symbol: Scalars["String"];
  usingOpenseaBlocklist?: Maybe<Scalars["Boolean"]>;
};

export type CreateEditionCollectionOutput = {
  collection: Collection;
  contract: ContractEntity;
};

export type CreateEvm721MintTransactionInput = {
  claimId: Scalars["String"];
  collectionId: Scalars["String"];
  editionId: Scalars["Int"];
  mintVectorId: Scalars["String"];
  nftContractAddress: Scalars["String"];
  serializedTx: Scalars["String"];
};

export type CreateEvmDeployTransactionInput = {
  collectionId: Scalars["String"];
  collectionType: Scalars["String"];
  serializedTx: Scalars["String"];
};

export type CreateGeneralCollectionInput = {
  baseUrl: Scalars["String"];
  chainId: Scalars["Float"];
  defaultTokenManager: Scalars["String"];
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  royaltyPercentageBPS: Scalars["String"];
  royaltyRecipientAddress: Scalars["String"];
  symbol: Scalars["String"];
  usingOpenseaBlocklist?: Maybe<Scalars["Boolean"]>;
};

export type CreateGeneralCollectionOutput = {
  collection: Collection;
  contract: ContractEntity;
};

export type CreateMintVectorInput = {
  collectionId?: Maybe<Scalars["String"]>;
  currency: Scalars["String"];
  editionId?: Maybe<Scalars["Float"]>;
  end?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
  maxPerTxn: Scalars["Float"];
  maxPerUser: Scalars["Float"];
  maxPerVector: Scalars["Float"];
  price: Scalars["String"];
  start: Scalars["String"];
};

export type CreateNewPostInput = {
  attachments?: Maybe<Array<AttachmentInput>>;
  communitySlug: Scalars["String"];
  notifyMembers: Scalars["Boolean"];
  pinned: Scalars["Boolean"];
  text?: Maybe<Scalars["String"]>;
};

export type CreateOfferInput = {
  communityId: Scalars["String"];
  currency: Scalars["String"];
  name: Scalars["String"];
  onAboutPage: Scalars["Boolean"];
  onPrivatePage: Scalars["Boolean"];
  price: Scalars["String"];
  tokenIds: Array<Scalars["Float"]>;
};

export type CreatePostInput = {
  attachment?: Maybe<AttachmentInput>;
  communitySlug: Scalars["String"];
  pinned: Scalars["Boolean"];
  text?: Maybe<Scalars["String"]>;
};

export type CreateReleasePartyCommentInput = {
  communityId: Scalars["String"];
  entityId: Scalars["String"];
  text: Scalars["String"];
};

export type CreateSingleEditionCollectionAndMintVectorInput = {
  collection: CreateEditionCollectionInput;
  mintVector: CreateMintVectorInput;
};

export type CreatorAvatar = {
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type CreatorCommunityMember = {
  blockchainAddress?: Maybe<Scalars["String"]>;
  dateJoined?: Maybe<Scalars["Float"]>;
  discordUserName?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  tokensHeldNames?: Maybe<Array<Scalars["String"]>>;
};

export type CreatorCommunityMembers = {
  amount: Scalars["Float"];
  currentMonthAmount: Scalars["Float"];
  members: Array<CreatorCommunityMember>;
  resultsCount: Scalars["Float"];
};

/** Sort value for Creator Community Members. */
export enum CreatorCommunityMembersSort {
  BY_DATE = "BY_DATE",
  BY_DATE_REVERSE = "BY_DATE_REVERSE",
  BY_TOKENS_AMOUNT = "BY_TOKENS_AMOUNT",
  BY_TOKENS_AMOUNT_REVERSE = "BY_TOKENS_AMOUNT_REVERSE",
}

export type CryptoCurrency = {
  coin: Scalars["String"];
  inUSD?: Maybe<Scalars["Float"]>;
  value: Scalars["String"];
};

export type CryptoOrderDraft = {
  amount: Scalars["String"];
  currency: Scalars["String"];
  currencyContract: Scalars["String"];
  offerId: Scalars["String"];
  transferToCreatorData: Scalars["String"];
  transferToPlatformData: Scalars["String"];
};

export type CryptoOrderInput = {
  nonce: Scalars["String"];
  offerId: Scalars["String"];
  purchaseToCreatorMetaTxPacket: MetaTxPacket;
  purchaseToPlatformMetaTxPacket: MetaTxPacket;
  recipientAddress: Scalars["String"];
};

export type Currency = {
  address: Scalars["String"];
  decimals: Scalars["Float"];
};

export type CurrencyConversion = {
  value: Scalars["String"];
};

export type CurrencyDetails = {
  contract: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  standard?: Maybe<CurrencyStandardType>;
  symbol?: Maybe<Scalars["String"]>;
};

/** Currency Standard Type */
export enum CurrencyStandardType {
  ERC20 = "ERC20",
  NATIVE = "NATIVE",
}

export type DashboardLink = {
  created: Scalars["Float"];
  object: Scalars["String"];
  url: Scalars["String"];
};

export type Decision = {
  decidedAt: Scalars["String"];
  result: Scalars["Float"];
};

export type DeleteCollectionBuyButtonResult = {
  communityId: Scalars["String"];
  success: Scalars["Boolean"];
};

export type DiscordData = {
  benefit: Benefit;
};

export type DiscordLinkedAccountData = {
  user?: Maybe<DiscordLinkedAccountUserInfo>;
};

export type DiscordLinkedAccountUserInfo = {
  avatar?: Maybe<Scalars["String"]>;
  discriminator?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type DiscordServer = {
  clientId?: Maybe<Scalars["String"]>;
  roleId?: Maybe<Scalars["String"]>;
  serverId?: Maybe<Scalars["String"]>;
};

export type DiscordStatistics = {
  activeCount: Scalars["Float"];
  members: Scalars["Float"];
};

export type Eoa = {
  blockchain?: Maybe<Scalars["String"]>;
  publicAddress: Scalars["String"];
};

export type EditionToken = {
  animation?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  image: Scalars["String"];
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  onChainAnimation?: Maybe<Scalars["String"]>;
  onChainImage: Scalars["String"];
  onChainMetadata?: Maybe<Scalars["String"]>;
  remainingSupply?: Maybe<Scalars["Float"]>;
  royalty?: Maybe<V1Royalty>;
  size: Scalars["Float"];
};

export type EditorContent = {
  state: Scalars["String"];
};

export type EditorContentInput = {
  state: Scalars["String"];
};

export type EmailPreference = {
  community: Scalars["Boolean"];
  marketing: Scalars["Boolean"];
};

export type EmitResult = {
  ok: Scalars["Boolean"];
};

export type EntityId = {
  id: Scalars["String"];
  type: EntityType;
};

/** Type of entity or object the associated entity-id uniquely identifies. */
export enum EntityType {
  POST = "POST",
  RELEASE = "RELEASE",
}

/** Event Type */
export enum EventType {
  CompleteSignedMessage = "CompleteSignedMessage",
  ConnectWallet = "ConnectWallet",
  CreateGateEditor_EditContent = "CreateGateEditor_EditContent",
  CreateGateEditor_EditTheme = "CreateGateEditor_EditTheme",
  CreateGateEditor_ImportToken = "CreateGateEditor_ImportToken",
  CreateGateEditor_PublishClick = "CreateGateEditor_PublishClick",
  CreateGateEditor_SetConditions = "CreateGateEditor_SetConditions",
  CreatedGate = "CreatedGate",
  CreatedGate_HasAudioContentType = "CreatedGate_HasAudioContentType",
  CreatedGate_HasEmbedContentType = "CreatedGate_HasEmbedContentType",
  CreatedGate_HasHTMLContentType = "CreatedGate_HasHTMLContentType",
  CreatedGate_HasImageContentType = "CreatedGate_HasImageContentType",
  CreatedGate_HasTextContentType = "CreatedGate_HasTextContentType",
  CreatedGate_HasVideoContentType = "CreatedGate_HasVideoContentType",
  DeployCollection_ArweaveUploadComplete = "DeployCollection_ArweaveUploadComplete",
  DeployCollection_ArweaveUploadFailed = "DeployCollection_ArweaveUploadFailed",
  DeployCollection_CompletedSuccessfully = "DeployCollection_CompletedSuccessfully",
  DeployCollection_Deploy_Button_Clicked = "DeployCollection_Deploy_Button_Clicked",
  DeployCollection_MediaSelectedAudioType = "DeployCollection_MediaSelectedAudioType",
  DeployCollection_MediaSelectedImageType = "DeployCollection_MediaSelectedImageType",
  DeployCollection_MediaSelectedVideoType = "DeployCollection_MediaSelectedVideoType",
  DeployCollection_TransactionSigned = "DeployCollection_TransactionSigned",
  DeployCollection_Viewed = "DeployCollection_Viewed",
  DeployCollection_Web2ObjectsCreatedSuccessfully = "DeployCollection_Web2ObjectsCreatedSuccessfully",
  DeployCollection_Web2ObjectsFailedtoCreate = "DeployCollection_Web2ObjectsFailedtoCreate",
  EditToken_ChangeGateModalOpened = "EditToken_ChangeGateModalOpened",
  EditToken_ChangeGateSaved = "EditToken_ChangeGateSaved",
  EditToken_EditSaleModalOpened = "EditToken_EditSaleModalOpened",
  EditToken_EditSaleSaved = "EditToken_EditSaleSaved",
  EditToken_PauseSale = "EditToken_PauseSale",
  EditToken_UnpauseSale = "EditToken_UnpauseSale",
  EditToken_Viewed = "EditToken_Viewed",
  ExistingGateEditor = "ExistingGateEditor",
  TokenSalePage_MintButtonClicked = "TokenSalePage_MintButtonClicked",
  TokenSalePage_TransactionFailed = "TokenSalePage_TransactionFailed",
  TokenSalePage_TransactionSigned = "TokenSalePage_TransactionSigned",
  TokenSalePage_TransactionSucceeded = "TokenSalePage_TransactionSucceeded",
  TokenSalePage_Viewed = "TokenSalePage_Viewed",
  ViewGatedPage_Locked = "ViewGatedPage_Locked",
  ViewGatedPage_Unlocked = "ViewGatedPage_Unlocked",
}

export type Evm721MintMetadata = {
  claimId?: Maybe<Scalars["String"]>;
  collectionId?: Maybe<Scalars["String"]>;
  editionId?: Maybe<Scalars["Int"]>;
  mintVectorId?: Maybe<Scalars["String"]>;
  nftContractAddress: Scalars["String"];
};

export type EvmAddress = {
  address: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  nonce: Scalars["Float"];
};

export type EvmDeployCollectionMetadata = {
  collectionId: Scalars["String"];
  collectionType: Scalars["String"];
};

export type EvmTx = {
  chainId: Scalars["String"];
  createdAt: Scalars["String"];
  evm721MintMetadata?: Maybe<Evm721MintMetadata>;
  evmDeployCollectionMetadata?: Maybe<EvmDeployCollectionMetadata>;
  id: Scalars["String"];
  status: TransactionStatus;
  txHash: Scalars["String"];
  type: TransactionType;
  updatedAt: Scalars["String"];
};

export type FeedbackInput = {
  description: Scalars["String"];
  url: Scalars["String"];
};

export type Gate = {
  buyButtonURL?: Maybe<Scalars["String"]>;
  conditions?: Maybe<Array<Condition>>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lockedMessage?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type GateContract = {
  address: Scalars["String"];
  contractType: Scalars["String"];
  decimals?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  platform: Scalars["String"];
  symbol?: Maybe<Scalars["String"]>;
  tokens: Array<GateToken>;
};

export type GateCreateInput = {
  buyButtonURL?: Maybe<Scalars["String"]>;
  conditions: Array<ConditionInput>;
  lockedMessage?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type GateDeleteResult = {
  deletedCount: Scalars["Int"];
};

export type GateToken = {
  address: Scalars["String"];
  contractType: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  platform: Scalars["String"];
  symbol?: Maybe<Scalars["String"]>;
  tokenId?: Maybe<Scalars["String"]>;
};

export type GateUpdateInput = {
  buyButtonURL?: Maybe<Scalars["String"]>;
  conditions: Array<ConditionInput>;
  lockedMessage?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type GatedMintVector = IGatedEntity & {
  chainId?: Maybe<Scalars["Float"]>;
  collectionId?: Maybe<Scalars["String"]>;
  currency: Scalars["String"];
  editionId?: Maybe<Scalars["Float"]>;
  editionName: Scalars["String"];
  end?: Maybe<Scalars["String"]>;
  gate: Gate;
  gateId: Scalars["String"];
  id?: Maybe<Scalars["String"]>;
  maxPerTxn: Scalars["Float"];
  maxPerUser: Scalars["Float"];
  maxPerVector: Scalars["Float"];
  mintVectorStats?: Maybe<MintVectorStats>;
  paused?: Maybe<Scalars["Boolean"]>;
  price: Scalars["String"];
  start: Scalars["String"];
  stats?: Maybe<MintVectorStats>;
  userGateAccess?: Maybe<UnlockGateResult>;
};

export type GatedMintVectorMintVectorStatsArgs = {
  getEarned: Scalars["Boolean"];
  getUserStats: Scalars["Boolean"];
};

export type GatedMintVectorUserGateAccessArgs = {
  fullResult: Scalars["Boolean"];
};

export type GatedPage = IGatedEntity & {
  created: Scalars["String"];
  gate: Gate;
  gateId: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  sections: Array<PageSection>;
  slug: Scalars["String"];
  status: PageStatus;
  templateId?: Maybe<Scalars["String"]>;
  theme?: Maybe<PageTheme>;
};

export type GenericConditionData = {
  address?: Maybe<Scalars["String"]>;
  allowlist?: Maybe<Array<Scalars["String"]>>;
  allowlistLength?: Maybe<Scalars["Float"]>;
  allowlistRaw?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<Attribute>>;
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  network?: Maybe<NetworkType>;
  standard?: Maybe<Scalars["String"]>;
  tokenId?: Maybe<Scalars["String"]>;
};

export type GenericConditionDataInput = {
  address?: Maybe<Scalars["String"]>;
  allowlist?: Maybe<Array<Scalars["String"]>>;
  allowlistLength?: Maybe<Scalars["Float"]>;
  allowlistRaw?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<AttributeInput>>;
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  network?: Maybe<NetworkType>;
  standard?: Maybe<Scalars["String"]>;
  tokenId?: Maybe<Scalars["String"]>;
};

export type GetDiscordLinkUrlResponse = {
  linkUrl: Scalars["String"];
};

export type GetPageUploadUrlRequest = {
  mime: PageSupportedMediaMime;
  type: PageSupportedMediaType;
};

export type GetUploadUrlForContractUriRequest = {
  mime: SupportedMediaMime;
};

export type GetUploadUrlRequest = {
  communitySlug: Scalars["String"];
  mime: SupportedMediaMime;
  type: SupportedMediaType;
};

export type HighlightGuide = {
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  id: Scalars["String"];
  link: Scalars["String"];
  title: Scalars["String"];
};

export type HighlightTip = {
  content: Scalars["String"];
  enabled: Scalars["Boolean"];
  event: Scalars["String"];
  id: Scalars["String"];
  link: Scalars["String"];
};

export type HostedMediaResource = {
  id: Scalars["String"];
  metadata?: Maybe<HostedMediaResourceMetadata>;
  type: SupportedMediaType;
};

export type HostedMediaResourceMetadata = {
  mime: SupportedMediaMime;
  publicUrl?: Maybe<Scalars["String"]>;
  type: SupportedMediaType;
  url?: Maybe<Scalars["String"]>;
};

export type IGatedEntity = {
  gate: Gate;
  gateId: Scalars["String"];
};

export type IdentitiesAuth = {
  account: Account;
  jwt: Scalars["String"];
};

export type IdentitiesNonce = {
  nonce: Scalars["String"];
};

export type Identity = {
  account?: Maybe<PublicAccount>;
  flagVariations: UserFlagVariations;
};

export type InputThemeButton = {
  borderType?: Maybe<BorderType>;
  onPageBackgroundBackgroundColor?: Maybe<Scalars["String"]>;
  onPageBackgroundBorderColor?: Maybe<Scalars["String"]>;
  onPageBackgroundBorderWidth?: Maybe<Scalars["String"]>;
  onPageBackgroundTextColor?: Maybe<Scalars["String"]>;
  onPrimaryBackgroundBackgroundColor?: Maybe<Scalars["String"]>;
  onPrimaryBackgroundBorderColor?: Maybe<Scalars["String"]>;
  onPrimaryBackgroundBorderWidth?: Maybe<Scalars["String"]>;
  onPrimaryBackgroundTextColor?: Maybe<Scalars["String"]>;
  textFontFamily?: Maybe<Scalars["String"]>;
  textFontWeight?: Maybe<Scalars["String"]>;
  textLetterSpacing?: Maybe<Scalars["String"]>;
  textTextTransform?: Maybe<Scalars["String"]>;
};

export type InputThemeGlobal = {
  containerBorderType?: Maybe<Scalars["String"]>;
};

export type InputThemeLabel = {
  fontWeight?: Maybe<Scalars["String"]>;
  letterSpacing?: Maybe<Scalars["String"]>;
};

export type InputThemePageBackground = {
  backgroundColor?: Maybe<Scalars["String"]>;
};

export type InputThemePrimaryBackground = {
  backgroundColor?: Maybe<Scalars["String"]>;
};

export type InputThemeText = {
  boldFontWeight?: Maybe<Scalars["String"]>;
  fontFamily?: Maybe<Scalars["String"]>;
  fontWeight?: Maybe<Scalars["String"]>;
  letterSpacing?: Maybe<Scalars["String"]>;
  onPageBackgroundPrimaryColor?: Maybe<Scalars["String"]>;
  onPrimaryBackgroundPrimaryColor?: Maybe<Scalars["String"]>;
  textTransform?: Maybe<Scalars["String"]>;
};

export type InputThemeTextInput = {
  backgroundColor?: Maybe<Scalars["String"]>;
  borderColor?: Maybe<Scalars["String"]>;
  borderType: BorderType;
  borderWidth?: Maybe<Scalars["String"]>;
  primaryTextColor?: Maybe<Scalars["String"]>;
};

export type InputThemeTitle = {
  fontFamily?: Maybe<Scalars["String"]>;
  fontWeight?: Maybe<Scalars["String"]>;
  letterSpacing?: Maybe<Scalars["String"]>;
  textTransform?: Maybe<Scalars["String"]>;
};

export type InputWebsiteTheme = {
  button?: Maybe<InputThemeButton>;
  global?: Maybe<InputThemeGlobal>;
  label?: Maybe<InputThemeLabel>;
  pageBackground?: Maybe<InputThemePageBackground>;
  primaryBackground?: Maybe<InputThemePrimaryBackground>;
  text?: Maybe<InputThemeText>;
  textInput?: Maybe<InputThemeTextInput>;
  title?: Maybe<InputThemeTitle>;
};

export type LinkDiscordAccountInput = {
  authCode: Scalars["String"];
  joinServers?: Maybe<Scalars["Boolean"]>;
};

export type LinkDiscordAccountResponse = {
  authMethod: Scalars["String"];
  blockchainAccount: Eoa;
  createdAt: Scalars["String"];
  createdCommunities: Array<CommunityIdentifier>;
  creatorTerms?: Maybe<TermsRecord>;
  discord?: Maybe<DiscordLinkedAccountData>;
  emailPreference: EmailPreference;
  flagVariations: UserFlagVariations;
  id: Scalars["String"];
  isMember?: Maybe<Scalars["Boolean"]>;
  magicLinkId: Scalars["String"];
  pendingTokens: Array<UserToken>;
  personalInformation: PersonalInformation;
  profile?: Maybe<Profile>;
  stripeAccount?: Maybe<StripeAccount>;
  syncDetails?: Maybe<SyncBenefitsResponse>;
  syncSuccessful?: Maybe<Scalars["Boolean"]>;
  terms?: Maybe<TermsRecord>;
  tokens: Array<UserToken>;
  updatedAt: Scalars["String"];
};

export type LinkDiscordAccountResponseIsMemberArgs = {
  slug: Scalars["String"];
};

export type LinkDiscordAccountResponseTokensArgs = {
  slug?: Maybe<Scalars["String"]>;
};

export type MediaMetadata = {
  mime: SupportedMediaMime;
  publicUrl?: Maybe<Scalars["String"]>;
  type: SupportedMediaType;
  url?: Maybe<Scalars["String"]>;
};

export type MediaMetadataInput = {
  mime: SupportedMediaMime;
  publicUrl?: Maybe<Scalars["String"]>;
  type: SupportedMediaType;
  url?: Maybe<Scalars["String"]>;
};

export type MetaTxPacket = {
  functionSignature: Scalars["String"];
  sigR: Scalars["String"];
  sigS: Scalars["String"];
  sigV: Scalars["Int"];
};

export type MintAttributes = {
  onChain: Scalars["Boolean"];
  params: Scalars["String"];
};

export type MintAttributesInput = {
  onChain: Scalars["Boolean"];
  params: Scalars["String"];
};

export type MintClaimInput = {
  numTokensToMint: Scalars["Float"];
};

export type MintContent = {
  attributes: MintAttributes;
  id?: Maybe<Scalars["String"]>;
  type: MintIdType;
};

export type MintContentInput = {
  attributes: MintAttributesInput;
  id?: Maybe<Scalars["String"]>;
  type: MintIdType;
};

/** Mint ID Type */
export enum MintIdType {
  ContractAddress = "ContractAddress",
  Vector = "Vector",
}

export type MintNewTokenInput = {
  benefits?: Maybe<Array<TokenBenefitType>>;
  description: Scalars["String"];
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  quantity: Scalars["Float"];
  releasePartyId?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type MintVector = {
  chainId?: Maybe<Scalars["Float"]>;
  collectionId?: Maybe<Scalars["String"]>;
  currency: Scalars["String"];
  editionId?: Maybe<Scalars["Float"]>;
  end?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  maxPerTxn: Scalars["Float"];
  maxPerUser: Scalars["Float"];
  maxPerVector: Scalars["Float"];
  mintVectorStats?: Maybe<MintVectorStats>;
  paused?: Maybe<Scalars["Boolean"]>;
  price: Scalars["String"];
  start: Scalars["String"];
  stats?: Maybe<MintVectorStats>;
  userGateAccess?: Maybe<UnlockGateResult>;
};

export type MintVectorMintVectorStatsArgs = {
  getEarned: Scalars["Boolean"];
  getUserStats: Scalars["Boolean"];
};

export type MintVectorUserGateAccessArgs = {
  fullResult: Scalars["Boolean"];
};

export type MintVectorStats = {
  claimedByCurrentUser?: Maybe<Scalars["Float"]>;
  earned?: Maybe<Scalars["String"]>;
  sold: Scalars["Float"];
  status: MintVectorStatus;
  total: Scalars["Float"];
};

/** Status of the Mint Vector */
export enum MintVectorStatus {
  Ended = "Ended",
  InProgress = "InProgress",
  NotStarted = "NotStarted",
}

export type Mutation = {
  acceptCurrentUserTerms: User;
  /** @deprecated use logInWithMagicLink or createUserWithMagicLink */
  authWithMagicLink: Auth;
  checkoutCompleted?: Maybe<Scalars["Boolean"]>;
  claimFreeOffer: BlockchainTaskRef;
  claimMint: ClaimObject;
  completeConnectWithSignedMessage: IdentitiesAuth;
  completeCreateUserWithSignedMessage: Auth;
  completeLoginWithSignedMessage: Auth;
  createCommunity: Community;
  createEvm721MintTransaction: EvmTx;
  createEvmDeployTransaction: EvmTx;
  createFeedback: Scalars["Boolean"];
  createGeneralCollection: CreateGeneralCollectionOutput;
  createMintVector: MintVector;
  createNewComment: Comment;
  createNewPost: Post;
  createOffer: Offer;
  createPost: Post;
  /** @deprecated The create edition and mint-vector endpoint will be used by the frontend, for better UX */
  createSingleEditionCollection: CreateEditionCollectionOutput;
  createSingleEditionCollectionAndMintVector: CreateEditionCollectionOutput;
  createUserWithMagicLink: Auth;
  cryptoOrderSend: BlockchainTaskRef;
  deleteComment: Comment;
  /** Delete banner image for a community. */
  deleteCommunityBannerImage: Community;
  /** Delete logo image for a community. */
  deleteCommunityLogoImage: Community;
  /** Delete creator avatar image for a community. */
  deleteCreatorAvatarImage: Community;
  deleteDiscordInfo: Community;
  /** Delete post */
  deletePost: Scalars["Boolean"];
  /** Delete the profile image for a user. */
  deleteProfileImage: User;
  deleteShopifyCollectionBuyButton: DeleteCollectionBuyButtonResult;
  disableClaimTokenContest: Scalars["Boolean"];
  emitAnalyticsEvent: EmitResult;
  gateCreate: Gate;
  gateDelete: GateDeleteResult;
  gateUpdate: Gate;
  generateClaimTokenContest: ClaimToken;
  getPageUploadUrl: PageAttachment;
  getUploadUrl: Attachment;
  getUploadUrlForContractUri: HostedMediaResource;
  insertCurrentUserToContest: ContestEntry;
  launchCommunity: Community;
  linkDiscordUser: LinkDiscordAccountResponse;
  logInWithMagicLink: Auth;
  /** @deprecated The create transaction endpoint will handle this case */
  markDeploying: Collection;
  /** @deprecated The update transactions endpoint will handle this case */
  markFinished: Collection;
  mintNewToken: BlockchainTaskRef;
  pageCreate: Page;
  pageDelete: PageDeleteResult;
  pageUpdate: Page;
  prepareForMint: BlockchainTaskRefNullable;
  refreshAccessToken: Auth;
  sendTokens: SendToken;
  syncStripeAccount: StripeAccount;
  syncUserBenefits: SyncBenefitsResponse;
  unlinkDiscordUser: UnlinkDiscordAccountResponse;
  updateCommentReaction: Comment;
  /** Update theme styles for a community. */
  updateCreatedCommunityTheme: WebsiteTheme;
  updateCurrentUserEmail: User;
  updateDiscordInfo: Community;
  updateEmailPreference: User;
  updateEvm721MintTx: EvmTx;
  updateEvmDeployTx: EvmTx;
  updateGeneralInfo: Community;
  updateMintVector: MintVector;
  updateOffer: Offer;
  updatePhoneNumber: User;
  updatePostContent: Post;
  /** Update Post Pin */
  updatePostPin: Post;
  updatePostReaction: Post;
  updateProfile: User;
  updateShopifyCollectionBuyButton: UpdateCollectionBuyButtonResult;
  updateUsername: User;
  /** Upload banner image for a community. */
  uploadCommunityBannerImage: Community;
  /** Upload logo image for a community. */
  uploadCommunityLogoImage: Community;
  /** Upload creator avatar image for a community. */
  uploadCreatorAvatarImage: Community;
  /** Upload profile image for a user. */
  uploadProfileImage: User;
  verifyPhoneNumber: User;
  withdrawCreatorRoyalties: BlockchainTaskRef;
};

export type MutationAcceptCurrentUserTermsArgs = {
  terms: AcceptTermsInput;
};

export type MutationAuthWithMagicLinkArgs = {
  didToken: Scalars["String"];
};

export type MutationCheckoutCompletedArgs = {
  paymentIntent: Scalars["String"];
  paymentIntentClientSecret: Scalars["String"];
  redirectStatus: Scalars["String"];
};

export type MutationClaimFreeOfferArgs = {
  offerId: Scalars["String"];
};

export type MutationClaimMintArgs = {
  data: MintClaimInput;
  vectorId: Scalars["String"];
};

export type MutationCompleteConnectWithSignedMessageArgs = {
  message: Scalars["String"];
  signature: Scalars["String"];
};

export type MutationCompleteCreateUserWithSignedMessageArgs = {
  email: Scalars["String"];
  publicAddress: Scalars["String"];
  signature: Scalars["String"];
};

export type MutationCompleteLoginWithSignedMessageArgs = {
  publicAddress: Scalars["String"];
  signature: Scalars["String"];
};

export type MutationCreateCommunityArgs = {
  data: CreateCommunityInput;
};

export type MutationCreateEvm721MintTransactionArgs = {
  input?: Maybe<CreateEvm721MintTransactionInput>;
  mintVectorId?: Maybe<Scalars["String"]>;
  serializedTx?: Maybe<Scalars["String"]>;
};

export type MutationCreateEvmDeployTransactionArgs = {
  data: CreateEvmDeployTransactionInput;
};

export type MutationCreateFeedbackArgs = {
  feedback: FeedbackInput;
};

export type MutationCreateGeneralCollectionArgs = {
  data: CreateGeneralCollectionInput;
};

export type MutationCreateMintVectorArgs = {
  data: CreateMintVectorInput;
};

export type MutationCreateNewCommentArgs = {
  data: CreateReleasePartyCommentInput;
};

export type MutationCreateNewPostArgs = {
  data: CreateNewPostInput;
};

export type MutationCreateOfferArgs = {
  data: CreateOfferInput;
};

export type MutationCreatePostArgs = {
  data: CreatePostInput;
};

export type MutationCreateSingleEditionCollectionArgs = {
  data: CreateEditionCollectionInput;
};

export type MutationCreateSingleEditionCollectionAndMintVectorArgs = {
  data: CreateSingleEditionCollectionAndMintVectorInput;
};

export type MutationCreateUserWithMagicLinkArgs = {
  didToken: Scalars["String"];
};

export type MutationCryptoOrderSendArgs = {
  input: CryptoOrderInput;
};

export type MutationDeleteCommentArgs = {
  commentId: Scalars["String"];
};

export type MutationDeleteCommunityBannerImageArgs = {
  id: Scalars["String"];
};

export type MutationDeleteCommunityLogoImageArgs = {
  id: Scalars["String"];
};

export type MutationDeleteCreatorAvatarImageArgs = {
  id: Scalars["String"];
};

export type MutationDeleteDiscordInfoArgs = {
  id: Scalars["String"];
};

export type MutationDeletePostArgs = {
  communitySlug: Scalars["String"];
  id: Scalars["String"];
};

export type MutationDeleteShopifyCollectionBuyButtonArgs = {
  communityId: Scalars["String"];
};

export type MutationDisableClaimTokenContestArgs = {
  contestId: Scalars["String"];
  id: Scalars["String"];
};

export type MutationEmitAnalyticsEventArgs = {
  event: AnalyticsInput;
};

export type MutationGateCreateArgs = {
  input: GateCreateInput;
};

export type MutationGateDeleteArgs = {
  id: Scalars["String"];
};

export type MutationGateUpdateArgs = {
  id: Scalars["String"];
  input: GateUpdateInput;
};

export type MutationGenerateClaimTokenContestArgs = {
  communityId: Scalars["String"];
  data: ClaimTokenInput;
  id: Scalars["String"];
};

export type MutationGetPageUploadUrlArgs = {
  data: GetPageUploadUrlRequest;
};

export type MutationGetUploadUrlArgs = {
  data: GetUploadUrlRequest;
};

export type MutationGetUploadUrlForContractUriArgs = {
  data: GetUploadUrlForContractUriRequest;
};

export type MutationInsertCurrentUserToContestArgs = {
  contestEntry: ContestEntryBody;
  contestId: Scalars["String"];
};

export type MutationLaunchCommunityArgs = {
  id: Scalars["String"];
};

export type MutationLinkDiscordUserArgs = {
  input: LinkDiscordAccountInput;
};

export type MutationLogInWithMagicLinkArgs = {
  didToken: Scalars["String"];
};

export type MutationMarkDeployingArgs = {
  collectionId: Scalars["String"];
};

export type MutationMarkFinishedArgs = {
  collectionId: Scalars["String"];
};

export type MutationMintNewTokenArgs = {
  animation?: Maybe<Scalars["Upload"]>;
  asset: Scalars["Upload"];
  data: MintNewTokenInput;
  id: Scalars["String"];
};

export type MutationPageCreateArgs = {
  input: PageCreateInput;
};

export type MutationPageDeleteArgs = {
  id: Scalars["String"];
};

export type MutationPageUpdateArgs = {
  id: Scalars["String"];
  input: PageUpdateInput;
};

export type MutationPrepareForMintArgs = {
  id: Scalars["String"];
};

export type MutationSendTokensArgs = {
  communityId: Scalars["String"];
  tokenId: Scalars["String"];
};

export type MutationUpdateCommentReactionArgs = {
  commentId: Scalars["String"];
  data: UpdatePostReactionInput;
};

export type MutationUpdateCreatedCommunityThemeArgs = {
  communityId: Scalars["String"];
  theme: InputWebsiteTheme;
};

export type MutationUpdateCurrentUserEmailArgs = {
  email: Scalars["String"];
};

export type MutationUpdateDiscordInfoArgs = {
  data: UpdateDiscordInfoInput;
  id: Scalars["String"];
};

export type MutationUpdateEmailPreferenceArgs = {
  emailPreference: UpdateEmailPreferenceInput;
};

export type MutationUpdateEvm721MintTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmDeployTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateGeneralInfoArgs = {
  data: UpdateGeneralInfoInput;
  id: Scalars["String"];
};

export type MutationUpdateMintVectorArgs = {
  data: UpdateMintVectorInput;
};

export type MutationUpdateOfferArgs = {
  communityId: Scalars["String"];
  data: UpdateOfferInput;
  id: Scalars["String"];
};

export type MutationUpdatePhoneNumberArgs = {
  phone: UpdatePhoneNumberInput;
};

export type MutationUpdatePostContentArgs = {
  data: UpdatePostContentInput;
  id: Scalars["String"];
};

export type MutationUpdatePostPinArgs = {
  data: UpdatePostPinInput;
  id: Scalars["String"];
};

export type MutationUpdatePostReactionArgs = {
  data: UpdatePostReactionInput;
  id: Scalars["String"];
};

export type MutationUpdateProfileArgs = {
  profile: UpdateProfileInput;
};

export type MutationUpdateShopifyCollectionBuyButtonArgs = {
  communityId: Scalars["String"];
  input: UpdateCollectionBuyButtonInput;
};

export type MutationUpdateUsernameArgs = {
  username: Scalars["String"];
};

export type MutationUploadCommunityBannerImageArgs = {
  asset: Scalars["Upload"];
  id: Scalars["String"];
  mime: Scalars["String"];
};

export type MutationUploadCommunityLogoImageArgs = {
  asset: Scalars["Upload"];
  id: Scalars["String"];
  mime: Scalars["String"];
};

export type MutationUploadCreatorAvatarImageArgs = {
  asset: Scalars["Upload"];
  id: Scalars["String"];
  mime: Scalars["String"];
};

export type MutationUploadProfileImageArgs = {
  asset: Scalars["Upload"];
  mime: Scalars["String"];
};

export type MutationVerifyPhoneNumberArgs = {
  verifyPhone: VerifyPhoneNumberInput;
};

export type MutationWithdrawCreatorRoyaltiesArgs = {
  communityId: Scalars["String"];
  currency: Scalars["String"];
};

export type NftMetadata = {
  attributes?: Maybe<Array<TokenAttribute>>;
  backgroundColor?: Maybe<Scalars["String"]>;
  contractAddress: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  externalUrl?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  standard?: Maybe<Scalars["String"]>;
  tokenId: Scalars["String"];
  tokenUri?: Maybe<Scalars["String"]>;
};

/** NFT Standard Type */
export enum NftStandardType {
  ERC721 = "ERC721",
  ERC1155 = "ERC1155",
}

/** Blockchain Network Types */
export enum NetworkType {
  ARBITRUM = "ARBITRUM",
  ARBITRUM_GOERLI = "ARBITRUM_GOERLI",
  ARBITRUM_RINKEBY = "ARBITRUM_RINKEBY",
  ETHEREUM = "ETHEREUM",
  FOUNDRY = "FOUNDRY",
  GOERLI = "GOERLI",
  HARDHAT = "HARDHAT",
  KOVAN = "KOVAN",
  LOCALHOST = "LOCALHOST",
  MUMBAI = "MUMBAI",
  OPTIMISM = "OPTIMISM",
  OPTIMISM_GOERLI = "OPTIMISM_GOERLI",
  OPTIMISM_KOVAN = "OPTIMISM_KOVAN",
  POLYGON = "POLYGON",
  RINKEBY = "RINKEBY",
  ROPSTEN = "ROPSTEN",
  SEPOLIA = "SEPOLIA",
}

/** Notification Status */
export enum NotificationStatus {
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

export type Offer = {
  active: Scalars["Boolean"];
  communityId: Scalars["String"];
  /** Used for crypto offers only. */
  crypto?: Maybe<CryptoCurrency>;
  currency: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  onAboutPage?: Maybe<Scalars["Boolean"]>;
  onPartyPage?: Maybe<Scalars["Boolean"]>;
  onPrivatePage?: Maybe<Scalars["Boolean"]>;
  price: Scalars["String"];
  /**
   * Field resolver not implemented yet.
   * @deprecated Field for backward compatibility only.
   */
  token?: Maybe<Token>;
  /** @deprecated Field for backward compatibility only. */
  tokenId?: Maybe<Scalars["Float"]>;
  tokenIds: Array<Scalars["Float"]>;
  /** Field resolver not implemented yet. */
  tokens?: Maybe<Array<Token>>;
};

export type Onboarding = {
  guide: Array<HighlightGuide>;
  id: Scalars["String"];
  tips: Array<HighlightTip>;
};

export type OnboardingLink = {
  created_at: Scalars["Float"];
  expires_at: Scalars["Float"];
  object: Scalars["String"];
  url: Scalars["String"];
};

export type Page = {
  created: Scalars["String"];
  gate?: Maybe<Gate>;
  gateId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  sections: Array<PageSection>;
  slug: Scalars["String"];
  status: PageStatus;
  templateId?: Maybe<Scalars["String"]>;
  theme?: Maybe<PageTheme>;
};

export type PageAttachment = {
  id: Scalars["String"];
  metadata?: Maybe<PageMediaMetadata>;
  type: PageAttachmentType;
};

/** Type of Page Attachment. */
export enum PageAttachmentType {
  MEDIA = "MEDIA",
}

export type PageContent = {
  editorContent?: Maybe<EditorContent>;
  mintContent?: Maybe<MintContent>;
};

export type PageContentInput = {
  editorContent?: Maybe<EditorContentInput>;
  mintContent?: Maybe<MintContentInput>;
};

export type PageCreateInput = {
  gateId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  sections: Array<PageSectionInput>;
  status?: Maybe<PageStatus>;
  templateId?: Maybe<Scalars["String"]>;
  theme?: Maybe<PageThemeInput>;
};

export type PageDeleteResult = {
  deletedCount: Scalars["Int"];
};

export type PageMediaMetadata = {
  mime: PageSupportedMediaMime;
  publicUrl?: Maybe<Scalars["String"]>;
  type: PageSupportedMediaType;
  url?: Maybe<Scalars["String"]>;
};

export type PageSection = {
  content: PageContent;
  gateId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  type: SectionType;
};

export type PageSectionInput = {
  content: PageContentInput;
  gateId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  type: SectionType;
};

/** Page status */
export enum PageStatus {
  Draft = "Draft",
  Public = "Public",
}

/** Supported Page Media Mime(s) */
export enum PageSupportedMediaMime {
  GIF = "GIF",
  JPEG = "JPEG",
  MP3 = "MP3",
  MP4 = "MP4",
  PNG = "PNG",
  WAV = "WAV",
  WEBM = "WEBM",
}

/** Page Supported Media Types. */
export enum PageSupportedMediaType {
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export type PageTheme = {
  background: PageThemeBackground;
  corners: PageThemeCorners;
  text: PageThemeText;
  title: PageThemeTitle;
};

export type PageThemeBackground = {
  bottomColor?: Maybe<Scalars["String"]>;
  color: Scalars["String"];
  type: BackgroundType;
};

export type PageThemeBackgroundInput = {
  bottomColor?: Maybe<Scalars["String"]>;
  color: Scalars["String"];
  type: BackgroundType;
};

export type PageThemeCorners = {
  shape: CornersShape;
};

export type PageThemeCornersInput = {
  shape: CornersShape;
};

export type PageThemeInput = {
  background: PageThemeBackgroundInput;
  corners: PageThemeCornersInput;
  text: PageThemeTextInput;
  title: PageThemeTitleInput;
};

export type PageThemeText = {
  color: Scalars["String"];
  fontFamily: Scalars["String"];
};

export type PageThemeTextInput = {
  color: Scalars["String"];
  fontFamily: Scalars["String"];
};

export type PageThemeTitle = {
  fontFamily: Scalars["String"];
};

export type PageThemeTitleInput = {
  fontFamily: Scalars["String"];
};

export type PageUpdateInput = {
  gateId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  sections?: Maybe<Array<PageSectionInput>>;
  status?: Maybe<PageStatus>;
  templateId?: Maybe<Scalars["String"]>;
  theme?: Maybe<PageThemeInput>;
};

export type PendingWithdrawal = {
  currency: Scalars["String"];
  taskId: Scalars["String"];
};

export type PersonalInformation = {
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<PhoneNumber>;
  /** @deprecated Replaced by phone field. */
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type PhoneNumber = {
  isVerified: Scalars["Boolean"];
  number: Scalars["String"];
};

export type Post = {
  attachment?: Maybe<Attachment>;
  attachments?: Maybe<Array<Attachment>>;
  id: Scalars["String"];
  pinned: Scalars["Boolean"];
  reactions?: Maybe<Array<BasePostReaction>>;
  text?: Maybe<Scalars["String"]>;
};

export type Profile = {
  aboutMe?: Maybe<Scalars["String"]>;
  profileImageUrl?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type PublicAccount = {
  ensName?: Maybe<Scalars["String"]>;
  evmAddresses: Array<Scalars["String"]>;
  id: Scalars["String"];
};

export type PublicPage = {
  creator?: Maybe<PublicAccount>;
  creatorId: Scalars["String"];
  gate?: Maybe<Gate>;
  gateId?: Maybe<Scalars["String"]>;
  isLocked: Scalars["Boolean"];
  lockedMessage?: Maybe<Scalars["String"]>;
  page?: Maybe<Page>;
  theme?: Maybe<PageTheme>;
  unlockGateResult?: Maybe<UnlockGateResult>;
};

export type Query = {
  anonymous: Anonymous;
  checkGateAccess: UnlockGateResult;
  checkoutInitiated: CheckoutInitiateResponse;
  collectionAttributes: CollectionAttributes;
  collectionDetails: CollectionDetails;
  collectionNft: CollectionNft;
  collectionNfts: Array<CollectionNft>;
  /** Find a community by either id or slug. If no arguments are passed, an error is thrown. If both arguments are used, 'id' has precedence over 'slug'. */
  community?: Maybe<Community>;
  /** Get feed for a community by slug. */
  contentFeed?: Maybe<ContentFeed>;
  contest: Contest;
  contestEntries: Array<ContestEntry>;
  contestEntry: ContestEntry;
  contests: Array<Contest>;
  contract?: Maybe<GateContract>;
  contractAttributes: ContractAttributes;
  contractDetails: ContractDetails;
  contractNft: ContractNft;
  contractNfts: Array<ContractNft>;
  conversion?: Maybe<CurrencyConversion>;
  /** Find a community from a creator by slug. */
  createdCommunityBySlug?: Maybe<Community>;
  /** Get creator community members. */
  creatorCommunityMembers: CreatorCommunityMembers;
  cryptoOrderDraft: CryptoOrderDraft;
  currencyDetails: CurrencyDetails;
  currentUser: User;
  gate: Gate;
  gateToken?: Maybe<GateToken>;
  gatedEntities: Array<IGatedEntity>;
  gates: Array<Gate>;
  generateDashboardLink: DashboardLink;
  generateOnboardingLink: OnboardingLink;
  getAllComments: Array<Comment>;
  getCollection: Collection;
  getCollectionByUser: Array<Collection>;
  getDiscordLinkUrl: GetDiscordLinkUrlResponse;
  getMintVector: MintVector;
  getMintVectorsByCollection: Array<MintVector>;
  getOnboardingConfig: Onboarding;
  getPendingEvmTransactions: Array<EvmTx>;
  getPublicCollection: Collection;
  getRecentEvmTransactions: Array<EvmTx>;
  getSecretMessage: Scalars["String"];
  getSupportedChains: Array<Chain>;
  getToken: Token;
  identity: Identity;
  offer: Offer;
  page: Page;
  pageView?: Maybe<PublicPage>;
  pages: Array<Page>;
  pendingWithdrawals: Array<PendingWithdrawal>;
  releaseParty: ReleaseParty;
  startConnectWithNonce: IdentitiesNonce;
  startCreateUserWithSignedMessage: Challenge;
  startLoginWithSignedMessage: Challenge;
  startMagicLinkFlow: AllowedMethods;
  tokenMetadata?: Maybe<NftMetadata>;
};

export type QueryCheckGateAccessArgs = {
  fullResult: Scalars["Boolean"];
  gateId: Scalars["String"];
};

export type QueryCheckoutInitiatedArgs = {
  items: Array<CheckoutItemsInput>;
  sessionKey: Scalars["String"];
};

export type QueryCollectionAttributesArgs = {
  slug: Scalars["String"];
};

export type QueryCollectionDetailsArgs = {
  slug: Scalars["String"];
};

export type QueryCollectionNftArgs = {
  id: Scalars["String"];
  slug: Scalars["String"];
};

export type QueryCollectionNftsArgs = {
  slug: Scalars["String"];
};

export type QueryCommunityArgs = {
  id?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type QueryContentFeedArgs = {
  communitySlug?: Maybe<Scalars["String"]>;
};

export type QueryContestArgs = {
  id: Scalars["String"];
};

export type QueryContestEntriesArgs = {
  contestId: Scalars["String"];
};

export type QueryContestEntryArgs = {
  contestId: Scalars["String"];
  id: Scalars["String"];
};

export type QueryContractArgs = {
  contractAddress: Scalars["String"];
  platform: Scalars["String"];
};

export type QueryContractAttributesArgs = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type QueryContractDetailsArgs = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type QueryContractNftArgs = {
  address: Scalars["String"];
  id: Scalars["String"];
  network: Scalars["String"];
};

export type QueryContractNftsArgs = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type QueryConversionArgs = {
  from: Scalars["String"];
  to: Scalars["String"];
};

export type QueryCreatedCommunityBySlugArgs = {
  slug: Scalars["String"];
};

export type QueryCreatorCommunityMembersArgs = {
  communityId: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  sort: CreatorCommunityMembersSort;
};

export type QueryCryptoOrderDraftArgs = {
  offerId: Scalars["String"];
};

export type QueryCurrencyDetailsArgs = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type QueryGateArgs = {
  id: Scalars["String"];
};

export type QueryGateTokenArgs = {
  conditionType?: Maybe<Scalars["String"]>;
  contractAddress: Scalars["String"];
  platform: Scalars["String"];
  tokenId?: Maybe<Scalars["String"]>;
};

export type QueryGatedEntitiesArgs = {
  gateId: Scalars["String"];
};

export type QueryGenerateOnboardingLinkArgs = {
  communityId: Scalars["String"];
};

export type QueryGetAllCommentsArgs = {
  releasePartyId: Scalars["String"];
};

export type QueryGetCollectionArgs = {
  collectionId: Scalars["String"];
};

export type QueryGetMintVectorArgs = {
  vectorId: Scalars["String"];
};

export type QueryGetMintVectorsByCollectionArgs = {
  collectionId: Scalars["String"];
};

export type QueryGetPublicCollectionArgs = {
  collectionId: Scalars["String"];
};

export type QueryGetRecentEvmTransactionsArgs = {
  hours: Scalars["Int"];
};

export type QueryGetSecretMessageArgs = {
  releasePartyId: Scalars["String"];
};

export type QueryGetTokenArgs = {
  communityAddress: Scalars["String"];
  tokenId: Scalars["Int"];
};

export type QueryOfferArgs = {
  id: Scalars["String"];
  onPrivatePage?: Maybe<Scalars["Boolean"]>;
};

export type QueryPageArgs = {
  id: Scalars["String"];
};

export type QueryPageViewArgs = {
  slug: Scalars["String"];
};

export type QueryPendingWithdrawalsArgs = {
  communityId: Scalars["String"];
};

export type QueryReleasePartyArgs = {
  communityId: Scalars["String"];
  slug: Scalars["String"];
};

export type QueryStartCreateUserWithSignedMessageArgs = {
  publicAddress: Scalars["String"];
};

export type QueryStartLoginWithSignedMessageArgs = {
  publicAddress: Scalars["String"];
};

export type QueryStartMagicLinkFlowArgs = {
  email: Scalars["String"];
};

export type QueryTokenMetadataArgs = {
  contractAddress: Scalars["String"];
  platform: Scalars["String"];
  tokenId: Scalars["String"];
};

export type ReleaseParty = {
  baseImageUrl: Scalars["String"];
  comments: Array<Comment>;
  community?: Maybe<Community>;
  communityId: Scalars["String"];
  description: Scalars["String"];
  embeddedContent: Scalars["String"];
  end: Scalars["String"];
  hasSecretMessage: Scalars["Boolean"];
  id: Scalars["String"];
  isActive: Scalars["Boolean"];
  name: Scalars["String"];
  notificationId?: Maybe<Scalars["String"]>;
  offerIds: Array<Scalars["String"]>;
  offers: Array<Offer>;
  secretMessage: Scalars["String"];
  slug: Scalars["String"];
  start: Scalars["String"];
};

export type Royalty = {
  allowToWithdraw: Scalars["Boolean"];
  balance: CryptoCurrency;
};

/** Section Types */
export enum SectionType {
  EditorData = "EditorData",
  MintButton = "MintButton",
}

export type SendToken = {
  availableTokenBalance: Scalars["Float"];
  communityId: Scalars["String"];
  communityMembersCount: Scalars["Float"];
  sent: Scalars["Boolean"];
  tokenId: Scalars["String"];
};

export type ShopifyData = {
  benefit: Benefit;
  buyButtons: Array<BuyButton>;
  collectionBuyButton?: Maybe<CollectionBuyButton>;
};

export type StripeAccount = {
  charges_enabled: Scalars["Boolean"];
  id: Scalars["String"];
};

export type StripeBalance = {
  /** @deprecated use `total` */
  amount?: Maybe<Scalars["Float"]>;
  currency: Scalars["String"];
  processing: Scalars["Float"];
  total: Scalars["Float"];
};

export type Subscription = {
  community: CommunityNotification;
  token: TokenNotification;
};

export type SubscriptionCommunityArgs = {
  communityId: Scalars["String"];
  type: CommunityNotificationType;
};

export type SubscriptionTokenArgs = {
  communityId: Scalars["String"];
  taskId?: Maybe<Scalars["String"]>;
  tokenIds?: Maybe<Array<Scalars["Float"]>>;
  type: TokenNotificationType;
};

/** Supported Media Mime(s) */
export enum SupportedMediaMime {
  GIF = "GIF",
  JPEG = "JPEG",
  MP3 = "MP3",
  MP4 = "MP4",
  PNG = "PNG",
  WAV = "WAV",
  WEBM = "WEBM",
}

/** Supported Media Types. */
export enum SupportedMediaType {
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export type SyncBenefitsResponse = {
  failed: Scalars["Float"];
  successful: Scalars["Float"];
  total: Scalars["Float"];
};

export type SystemContract = {
  address: Scalars["String"];
  chainId: Scalars["Float"];
  id: Scalars["String"];
  type: SystemContractType;
};

/** Contract type */
export enum SystemContractType {
  CollectionFactory = "CollectionFactory",
  ConsensualNonTransferableTokenManager = "ConsensualNonTransferableTokenManager",
  EditionsMetadataRenderer = "EditionsMetadataRenderer",
  MintManager = "MintManager",
  NonTransferableTokenManager = "NonTransferableTokenManager",
  PermissionsRegistry = "PermissionsRegistry",
}

export type TermsRecord = {
  acceptedAt?: Maybe<Scalars["String"]>;
  family?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

export type ThemeButton = {
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  backgroundColor?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderColor?: Maybe<Scalars["String"]>;
  borderType: BorderType;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderWidth?: Maybe<Scalars["String"]>;
  onPageBackgroundBackgroundColor: Scalars["String"];
  onPageBackgroundBorderColor: Scalars["String"];
  onPageBackgroundBorderWidth: Scalars["String"];
  onPageBackgroundTextColor: Scalars["String"];
  onPrimaryBackgroundBackgroundColor: Scalars["String"];
  onPrimaryBackgroundBorderColor: Scalars["String"];
  onPrimaryBackgroundBorderWidth: Scalars["String"];
  onPrimaryBackgroundTextColor: Scalars["String"];
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  textColor?: Maybe<Scalars["String"]>;
  textFontFamily: Scalars["String"];
  textFontWeight: Scalars["String"];
  textLetterSpacing: Scalars["String"];
  textTextTransform: Scalars["String"];
};

export type ThemeGlobal = {
  containerBorderType: Scalars["String"];
};

export type ThemeLabel = {
  fontWeight: Scalars["String"];
  letterSpacing: Scalars["String"];
};

export type ThemeModal = {
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderType?: Maybe<BorderType>;
};

export type ThemePageBackground = {
  backgroundColor: Scalars["String"];
};

export type ThemePrimaryBackground = {
  backgroundColor: Scalars["String"];
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  backgroundImageUrl?: Maybe<Scalars["String"]>;
};

export type ThemeSecondaryBackground = {
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  backgroundColor?: Maybe<Scalars["String"]>;
};

export type ThemeSecondaryButton = {
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  backgroundColor?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderColor?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderType?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderWidth?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  textColor?: Maybe<Scalars["String"]>;
};

export type ThemeText = {
  boldFontWeight: Scalars["String"];
  fontFamily: Scalars["String"];
  fontWeight: Scalars["String"];
  letterSpacing: Scalars["String"];
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  onBackgroundPrimaryColor?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  onBackgroundSecondaryColor?: Maybe<Scalars["String"]>;
  onPageBackgroundPrimaryColor: Scalars["String"];
  onPrimaryBackgroundPrimaryColor: Scalars["String"];
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  onSecondaryBackgroundPrimaryColor?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  onSecondaryBackgroundSecondaryColor?: Maybe<Scalars["String"]>;
  textTransform: Scalars["String"];
};

export type ThemeTextInput = {
  backgroundColor: Scalars["String"];
  borderColor: Scalars["String"];
  borderType: BorderType;
  borderWidth: Scalars["String"];
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  placeholderTextColor?: Maybe<Scalars["String"]>;
  primaryTextColor: Scalars["String"];
};

export type ThemeTitle = {
  fontFamily: Scalars["String"];
  fontWeight: Scalars["String"];
  letterSpacing: Scalars["String"];
  textTransform: Scalars["String"];
};

export type Token = {
  animationUri?: Maybe<Scalars["String"]>;
  /** Is user balance of token if used under user context. Is supply if used under any other context. Default to supply. */
  balanceOrSupply?: Maybe<Scalars["String"]>;
  benefits?: Maybe<Array<TokenBenefit>>;
  communityAddress: Scalars["String"];
  /** Number of tokens available for sale or distribution */
  creatorBalance?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
  mintedAsNonTransferable?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  /** Total number of tokens minted */
  supply?: Maybe<Scalars["Float"]>;
  taskId?: Maybe<Scalars["String"]>;
  tokenId: Scalars["ID"];
  tokenManagerAddress?: Maybe<Scalars["String"]>;
  tokenStatus?: Maybe<TokenStatus>;
  tokenType: TokenType;
  uri?: Maybe<Scalars["String"]>;
};

export type TokenUriArgs = {
  useChain: Scalars["Boolean"];
};

export type TokenAttribute = {
  traitType: Scalars["String"];
  value: Scalars["String"];
};

export type TokenBenefit = {
  customName?: Maybe<Scalars["String"]>;
  releasePartyId?: Maybe<Scalars["String"]>;
  type: BenefitType;
};

export type TokenBenefitType = {
  customName?: Maybe<Scalars["String"]>;
  releasePartyId?: Maybe<Scalars["String"]>;
  type: BenefitType;
};

export type TokenMetadata = {
  /** Contest ID if the token can be claimed (have a valid open contest) */
  claimable?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
};

export type TokenNotification = {
  communityId: Scalars["String"];
  status: NotificationStatus;
  taskId?: Maybe<Scalars["String"]>;
  tokenIds: Array<Scalars["Float"]>;
  type: TokenNotificationType;
};

/** Token Notification Type */
export enum TokenNotificationType {
  MINT = "MINT",
  TRANSFER = "TRANSFER",
}

/** Availability status of the Token. */
export enum TokenStatus {
  Minted = "Minted",
  Pending = "Pending",
}

/** Token type */
export enum TokenType {
  All = "All",
  Benefit = "Benefit",
  Collectible = "Collectible",
  Membership = "Membership",
}

export type TokensMetadata = {
  maxQuantity?: Maybe<Scalars["Float"]>;
  quantity: Scalars["Float"];
  totalGrantedQuantity?: Maybe<Scalars["Float"]>;
};

/** what is the status of the onchain transaction */
export enum TransactionStatus {
  MINED = "MINED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/** type of highlight onchain action associated with this transaction */
export enum TransactionType {
  EVM_721_MINT = "EVM_721_MINT",
  EVM_DEPLOY_COLLECTION = "EVM_DEPLOY_COLLECTION",
}

export type UnlinkDiscordAccountResponse = {
  authMethod: Scalars["String"];
  blockchainAccount: Eoa;
  createdAt: Scalars["String"];
  createdCommunities: Array<CommunityIdentifier>;
  creatorTerms?: Maybe<TermsRecord>;
  discord?: Maybe<DiscordLinkedAccountData>;
  emailPreference: EmailPreference;
  flagVariations: UserFlagVariations;
  id: Scalars["String"];
  isMember?: Maybe<Scalars["Boolean"]>;
  magicLinkId: Scalars["String"];
  pendingTokens: Array<UserToken>;
  personalInformation: PersonalInformation;
  profile?: Maybe<Profile>;
  stripeAccount?: Maybe<StripeAccount>;
  terms?: Maybe<TermsRecord>;
  tokens: Array<UserToken>;
  updatedAt: Scalars["String"];
};

export type UnlinkDiscordAccountResponseIsMemberArgs = {
  slug: Scalars["String"];
};

export type UnlinkDiscordAccountResponseTokensArgs = {
  slug?: Maybe<Scalars["String"]>;
};

export type UnlockGateResult = {
  conditionResults?: Maybe<Array<ConditionResult>>;
  passed: Scalars["Boolean"];
  type?: Maybe<Scalars["String"]>;
};

/** Type of update Action post reactions. */
export enum UpdateActionType {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export type UpdateCollectionBuyButtonInput = {
  collectionComponentDivId: Scalars["String"];
  collectionId: Scalars["String"];
  domain: Scalars["String"];
  storefrontAccessToken: Scalars["String"];
};

export type UpdateCollectionBuyButtonResult = {
  collectionComponentDivId: Scalars["String"];
  collectionId: Scalars["String"];
  communityId: Scalars["String"];
  domain: Scalars["String"];
  storefrontAccessToken: Scalars["String"];
};

export type UpdateDiscordInfoInput = {
  roleId: Scalars["String"];
  serverId: Scalars["String"];
  skipValidation?: Maybe<Scalars["Boolean"]>;
};

export type UpdateEmailPreferenceInput = {
  community: Scalars["Boolean"];
  marketing: Scalars["Boolean"];
};

export type UpdateGeneralInfoInput = {
  contactEmail?: Maybe<Scalars["String"]>;
  creatorName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type UpdateMintVectorInput = {
  currency?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
  maxPerTxn?: Maybe<Scalars["Float"]>;
  maxPerUser?: Maybe<Scalars["Float"]>;
  maxPerVector?: Maybe<Scalars["Float"]>;
  mintVectorId: Scalars["String"];
  paused?: Maybe<Scalars["Boolean"]>;
  price?: Maybe<Scalars["String"]>;
  start?: Maybe<Scalars["String"]>;
};

export type UpdateOfferInput = {
  currency?: Maybe<Scalars["String"]>;
  onAboutPage?: Maybe<Scalars["Boolean"]>;
  onPrivatePage?: Maybe<Scalars["Boolean"]>;
  price?: Maybe<Scalars["String"]>;
};

export type UpdatePhoneNumberInput = {
  number: Scalars["String"];
};

export type UpdatePostContentInput = {
  attachments?: Maybe<Array<AttachmentInput>>;
  communitySlug: Scalars["String"];
  text: Scalars["String"];
};

export type UpdatePostPinInput = {
  communitySlug: Scalars["String"];
  type: UpdateActionType;
};

export type UpdatePostReactionInput = {
  communitySlug: Scalars["String"];
  reaction: Scalars["String"];
  type: UpdateActionType;
};

export type UpdateProfileInput = {
  aboutMe?: Maybe<Scalars["String"]>;
  username: Scalars["String"];
};

export type User = {
  authMethod: Scalars["String"];
  blockchainAccount: Eoa;
  createdAt: Scalars["String"];
  createdCommunities: Array<CommunityIdentifier>;
  creatorTerms?: Maybe<TermsRecord>;
  discord?: Maybe<DiscordLinkedAccountData>;
  emailPreference: EmailPreference;
  flagVariations: UserFlagVariations;
  id: Scalars["String"];
  isMember?: Maybe<Scalars["Boolean"]>;
  magicLinkId: Scalars["String"];
  pendingTokens: Array<UserToken>;
  personalInformation: PersonalInformation;
  profile?: Maybe<Profile>;
  stripeAccount?: Maybe<StripeAccount>;
  terms?: Maybe<TermsRecord>;
  tokens: Array<UserToken>;
  updatedAt: Scalars["String"];
};

export type UserIsMemberArgs = {
  slug: Scalars["String"];
};

export type UserTokensArgs = {
  slug?: Maybe<Scalars["String"]>;
};

export type UserFlagVariations = {
  accountsRequireCaptchaForCreation: Scalars["Boolean"];
  capitolMintingNewUx: Scalars["Boolean"];
  collections721mEnableFeature: Scalars["Boolean"];
  contentFeedEnableNewPostEditor: Scalars["Boolean"];
  contractsCollections721mCreate: Scalars["Boolean"];
  creatorDashboardCreateOneCommunityPerUser: Scalars["Boolean"];
  creatorDashboardCustomBenefitTokens: Scalars["Boolean"];
  creatorDashboardDisableCreateCommunity: Scalars["Boolean"];
  creatorDashboardEnableMintTokenLimit: Scalars["Boolean"];
  creatorDashboardIncomeTab: Scalars["Boolean"];
  creatorDashboardMembersTab: Scalars["Boolean"];
  creatorDashboardRequirePhoneValidation: Scalars["Boolean"];
  creatorDashboardShowDiscordServerConfigPainel: Scalars["Boolean"];
  creatorDashboardThemeTab: Scalars["Boolean"];
  creatorDashboardVideoAssetOnToken: Scalars["Boolean"];
  cryptoByoWalletAuth: Scalars["Boolean"];
  discordSyncBenefitsButton: Scalars["Boolean"];
  embedsShowModal: Scalars["Boolean"];
  emergencyAlertFrontendMessage: Scalars["String"];
  emergencyStopClaimToken: Scalars["Boolean"];
  emergencyStopDeployContract: Scalars["Boolean"];
  emergencyStopMintNewTokens: Scalars["Boolean"];
  emergencyStopNewContentFeedPosts: Scalars["Boolean"];
  emergencyStopSendTokensToAll: Scalars["Boolean"];
  emergencyStopShowContentFeedPosts: Scalars["Boolean"];
  enableCryptoPayments: Scalars["Boolean"];
  enableDeployDetectionAndForcePageReload: Scalars["Boolean"];
  enableMintingNonTransferable: Scalars["Boolean"];
  enableResizedImages: Scalars["Boolean"];
  gateByTokenAttribute: Scalars["Boolean"];
  gatedMints: Scalars["Boolean"];
  gatesAppShowOperationStatusHeader: Scalars["Boolean"];
  gatesDisablePageCreate: Scalars["Boolean"];
  gatesDisableTokenTestnets: Scalars["Boolean"];
  gatesEditorDisableHTMLPlugin: Scalars["Boolean"];
  gatesEnableS3Upload: Scalars["Boolean"];
  gatesStopPageView: Scalars["Boolean"];
  incomeAllowWithdraw: Scalars["Boolean"];
  incomeShowUsdEarnings: Scalars["Boolean"];
  incomeShowWEthEarnings: Scalars["Boolean"];
  onboardingSquadCreatorTermsOfService: Scalars["Boolean"];
  paymentsEnableFiatOffers: Scalars["Boolean"];
  paymentsStripeConnectOnboarding: Scalars["Boolean"];
  releasePartyAccessFeature: Scalars["String"];
  releasePartyUpdateUserProfile: Scalars["String"];
  scaffoldBecomeCreatorCta: Scalars["Boolean"];
  scaffoldEnableAccountEmailPreferencesPage: Scalars["Boolean"];
  scaffoldEnableAccountTransactionHistoryPage: Scalars["Boolean"];
  scaffoldEnableAccountWalletPage: Scalars["Boolean"];
  scaffoldEnableCreatorDashboard: Scalars["Boolean"];
  scaffoldEnableMagiclinkPrivateKeyExport: Scalars["Boolean"];
  segmentEnableInCommunities: Scalars["Boolean"];
  shopifyOnboardWithBuyButton: Scalars["Boolean"];
  showLockedPreviewToggleInTools: Scalars["Boolean"];
};

export type UserSubmission = {
  formFieldAnswer?: Maybe<Scalars["String"]>;
  submittedAt: Scalars["String"];
};

export type UserToken = {
  /** User balance */
  balance?: Maybe<Scalars["Float"]>;
  benefits?: Maybe<Array<TokenBenefit>>;
  community: CommunityIdentifier;
  description?: Maybe<Scalars["String"]>;
  imageUrl: Scalars["String"];
  name: Scalars["String"];
  /** Total number of tokens minted */
  supply?: Maybe<Scalars["Float"]>;
  tokenId: Scalars["Int"];
  tokenType: TokenType;
};

export type V1Royalty = {
  royaltyPercentageString?: Maybe<Scalars["String"]>;
  royaltyRecipient?: Maybe<Scalars["String"]>;
};

export type VerifyPhoneNumberInput = {
  code: Scalars["String"];
};

export type WebsiteTheme = {
  button: ThemeButton;
  global: ThemeGlobal;
  label: ThemeLabel;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  modal?: Maybe<ThemeModal>;
  pageBackground: ThemePageBackground;
  primaryBackground: ThemePrimaryBackground;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  secondaryBackground?: Maybe<ThemeSecondaryBackground>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  secondaryButton?: Maybe<ThemeSecondaryButton>;
  text: ThemeText;
  textInput: ThemeTextInput;
  title: ThemeTitle;
};
