import React, { FC } from "react";

import { ReactComponent as QuestionMarkIcon } from "assets/icons/question-mark-icon.svg";
import Tooltip from "components/Tooltip";
import Text from "components/Typography/Text";

import * as styles from "./styles";

export type Props = {
  content: string;
};

const HelpIconTooltip: FC<Props> = ({ content }) => {
  return (
    <Tooltip
      content={
        <Text size="small" as="p" additionalCss={styles.tooltipContent}>
          {content}
        </Text>
      }
    >
      <button css={styles.container}>
        <QuestionMarkIcon />
      </button>
    </Tooltip>
  );
};
export default HelpIconTooltip;
