import { css, Theme } from "@emotion/react";

export const modalHeader = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[8]};
`;

export const modalButtons = (theme: Theme) => css`
  display: flex;
  flex-direction: row;

  button {
    height: 64px;
    width: 236px;
    left: 0;
    top: 0;
    border-radius: 99px;
    padding: 0 ${theme.verticalSpaces.large[5]};
  }

  ${theme.mediaQueries.smallOnly} {
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
    justify-content: center;

    button {
      width: 100%;
      margin: 0;
    }
`;

export const confirmDeleteModal = css`
  text-align: center;
`;

export const deleteConfirmBtn = css`
  margin-left: 20px;
`;

export const deleteConfirmText = (theme: Theme) => css`
  color: ${theme.invertedText.colors.primary};
`;

export const errorContainer = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[3]};
`;

export const errorMessage = (theme: Theme) => css`
  text-align: center;
  color: ${theme.colorMap.destructive.default};
`;
