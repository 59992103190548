import React, { FC, memo, PropsWithChildren } from "react";

import { Interpolation, Theme } from "@emotion/react";

import CommunityText, { CommunityTextProps } from "../Text";

export type CommunityLabelProps = {
  size?: CommunityTextProps["size"];
  color?: CommunityTextProps["color"];
  as?: CommunityTextProps["as"];
  bold?: boolean;
  uppercase?: boolean;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
};

const CommunityLabel: FC<PropsWithChildren<CommunityLabelProps>> = ({
  extraCss,
  size = "medium",
  color = "onPrimaryBackgroundPrimaryColor",
  as = "div",
  bold = true,
  uppercase = true,
  children,
}) => (
  <CommunityText
    extraCss={extraCss}
    size={size}
    color={color}
    as={as}
    uppercase={uppercase}
    bold={bold}
  >
    {children}
  </CommunityText>
);

export default memo(CommunityLabel);
