import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  position: sticky;
  bottom: 30px;
  width: 100%;

  ${theme.mediaQueries.smallOnly} {
    bottom: 20px;
  }
`;

export const card = (theme: Theme) => css`
  display: flex;
  align-items: center;
  z-index: 5;
  min-height: 80px;

  p {
    flex-grow: 1;

    svg {
      margin-right: 12px;
      width: 20px;
      height: auto;
    }
  }

  ${theme.mediaQueries.smallOnly} {
    flex-direction: column;
    min-height: auto;

    p {
      letter-spacing: -0.03em;
      text-align: center;

      svg {
        display: block;
        margin: 0 auto;
      }
    }

    button {
      width: 100%;
      margin-top: 12px;
    }
  }
`;
