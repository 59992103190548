import React, { memo } from "react";

import { css, Theme } from "@emotion/react";

import {
  Attachment as AttachmentBaseType,
  AttachmentType,
  SupportedMediaType,
} from "apollo/graphql.generated";
import {
  ModalPostEditorTokenSelectData,
  ModalType,
  resetModalVar,
  updateModalVar,
} from "apollo/reactive";
import {
  PostEditorAudio as AudioIcon,
  PostEditorImage as ImageIcon,
  PostEditorNft as TokenIcon,
  PostEditorVideo as VideoIcon,
} from "assets/icons.generated";
import { topMargin } from "styles/global/layout";

import Button from "../../../Button";
import Tooltip from "../../../Tooltip";
import Text from "../../../Typography/Text";
import { UseContentFeedEditorLogic } from "../logic";

import { INSERT_AUDIO_COMMAND } from "./AudioAttachmentPlugin";
import { INSERT_IMAGE_COMMAND } from "./ImageAttachmentPlugin";
import { INSERT_TOKEN_COMMAND } from "./TokenAttachmentPlugin";
import { INSERT_VIDEO_COMMAND } from "./VideoAttachmentPlugin";

/**
 * Styles
 */

export const style = {
  container: (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    ${topMargin("7/20px")}

    position: sticky;
    bottom: 10px;
    z-index: 2;

    &:after {
      content: " ";
      width: calc(100% + 40px);
      height: 70px;
      position: absolute;
      top: -10px;
      left: -20px;
      z-index: -1;
      border-radius: 10px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6909357492997199) 9%,
        rgba(255, 255, 255, 1) 13%
      );
    }

    ${theme.mediaQueries.mediumDown} {
      &:after {
        left: -16px;
        width: calc(100% + 16px * 2);
      }
    }
  `,
  attachments: (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    input[type="file"] {
      display: none;
    }

    button,
    label {
      width: 48px;
      height: 48px;
      margin-right: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          background: ${theme.background.colors.secondary};
        }
      }

      ${theme.mediaQueries.mediumDown} {
        margin-right: 6px;
        width: 40px;
        height: 40px;
      }
    }
  `,
  publish: css`
    button {
      min-width: 115px;
    }
  `,
};

/**
 * Main Component
 */
interface ActionsPluginProps {
  activeEditor: UseContentFeedEditorLogic["activeEditor"];
  isEditable: UseContentFeedEditorLogic["isEditable"];
  handleFileSelected: UseContentFeedEditorLogic["handleFileSelected"];
  publishPost: UseContentFeedEditorLogic["publishPost"];
  isSubmitting: boolean;
}

const AttachmentManager = ({
  activeEditor,
  isEditable,
  handleFileSelected,
  publishPost,
  isSubmitting,
}: ActionsPluginProps) => {
  const insertImage = (attachment: AttachmentBaseType, fileName: string) => {
    activeEditor.focus();
    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, {
      attachment,
      fileName,
    });
  };

  const insertVideo = (attachment: AttachmentBaseType, fileName: string) => {
    activeEditor.focus();
    activeEditor.dispatchCommand(INSERT_VIDEO_COMMAND, {
      attachment,
      fileName,
    });
  };

  const insertAudio = (attachment: AttachmentBaseType, fileName: string) => {
    activeEditor.focus();
    activeEditor.dispatchCommand(INSERT_AUDIO_COMMAND, {
      attachment,
      fileName,
    });
  };

  const insertToken = (tokenID: string) => {
    activeEditor.focus();
    activeEditor.dispatchCommand(INSERT_TOKEN_COMMAND, tokenID);
    resetModalVar();
  };

  const showTokenSelectorModal = () => {
    updateModalVar<ModalPostEditorTokenSelectData>({
      showModal: ModalType.POST_EDITOR_TOKEN_SELECT,
      data: {
        insertToken,
      },
    });
  };

  if (!isEditable) return null;

  return (
    <div css={style.container}>
      <div css={style.attachments}>
        <Tooltip
          padding="xsmall"
          content={
            <Text size="xsmall" as="span">
              Image
            </Text>
          }
        >
          <label htmlFor="LexicalEditorImageFileUpload">
            <input
              type="file"
              accept="image/*"
              id="LexicalEditorImageFileUpload"
              onChange={handleFileSelected(
                SupportedMediaType.IMAGE,
                AttachmentType.MEDIA,
                insertImage
              )}
            />
            <ImageIcon />
          </label>
        </Tooltip>

        <Tooltip
          padding="xsmall"
          content={
            <Text size="xsmall" as="span">
              Audio
            </Text>
          }
        >
          <label htmlFor="LexicalEditorAudioFileUpload">
            <input
              type="file"
              accept="audio/*"
              id="LexicalEditorAudioFileUpload"
              onChange={handleFileSelected(
                SupportedMediaType.AUDIO,
                AttachmentType.MEDIA,
                insertAudio
              )}
            />
            <AudioIcon />
          </label>
        </Tooltip>

        <Tooltip
          padding="xsmall"
          content={
            <Text size="xsmall" as="span">
              Video
            </Text>
          }
        >
          <label htmlFor="LexicalEditorVideoFileUpload">
            <input
              type="file"
              accept="video/*"
              id="LexicalEditorVideoFileUpload"
              onChange={handleFileSelected(
                SupportedMediaType.VIDEO,
                AttachmentType.MEDIA,
                insertVideo
              )}
            />
            <VideoIcon />
          </label>
        </Tooltip>

        <Tooltip
          padding="xsmall"
          content={
            <Text size="xsmall" as="span">
              Token
            </Text>
          }
        >
          <button onClick={showTokenSelectorModal}>
            <TokenIcon />
          </button>
        </Tooltip>
      </div>

      <div css={style.publish}>
        <Button size="medium" onClick={publishPost} loading={isSubmitting}>
          Post
        </Button>
      </div>
    </div>
  );
};

export default memo(AttachmentManager);
