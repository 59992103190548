import { memo, Dispatch, SetStateAction } from "react";

import { MessageBanner } from "../../MessageBanner";

export type ErrorBannerProps = {
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  isEditable: boolean;
};

const ErrorBanner = ({ error, setError, isEditable }: ErrorBannerProps) => {
  if (!isEditable) return null;

  return <MessageBanner onClose={() => setError("")} message={error} />;
};

export default memo(ErrorBanner);
