import { GetIdTokenConfiguration } from "magic-sdk";

import { IN_DEVELOPMENT, MAGIC_LINK_AUTH_SESSION_LENGTH } from "config";
import { logError } from "services/logger";

import magic from "./magicClient";

export enum MagicLinkErrorCode {
  MissingAuthHeader = "ERROR_MISSING_AUTH_HEADER",
  TokenExpired = "ERROR_DIDT_EXPIRED",
  TokenCannotBeUsedYet = "ERROR_DIDT_CANNOT_BE_USED_YET",
  IncorrectSignerAddress = "ERROR_INCORRECT_SIGNER_ADDR",
  FailedRecoveryProof = "ERROR_FAILED_RECOVERING_PROOF",
  ApiKeyMissing = "ERROR_SECRET_API_KEY_MISSING",
  MalformedTokenError = "ERROR_MALFORMED_TOKEN",
  ServiceError = "SERVICE_ERROR",
  ExpectedBearerString = "EXPECTED_BEARER_STRING",
  RequestNotAuthorizedForAdminAuth = "REQUEST_NOT_AUTHORIZED_FOR_ADMIN_AUTH",
}

export const InvalidAccessTokenErrorCode = "InvalidAccessToken";
export const UserAlreadyExistsErrorCode = "USER_ALREADY_EXISTS";
export const NoSuchUserErrorCode = "USER_DOES_NOT_EXIST";

export const isUserLoggedIn = async () => {
  try {
    return await magic.user.isLoggedIn();
  } catch (error) {
    await logError({ error, message: "isLoggedIn failed" });
    throw error;
  }
};

export const logout = async () => {
  try {
    const result = await magic.user.logout();
    IN_DEVELOPMENT &&
      console.log("🟡 magic.user.logout succeeded", {
        isLoggedIn: await magic.user.isLoggedIn(),
      });
    return result;
  } catch (error) {
    await logError({ error, message: "magic.user.logout failed" });
    throw error;
  }
};

export const getUserIdToken = async (
  configuration?: GetIdTokenConfiguration
) => {
  try {
    if (!configuration) {
      const lifespan = MAGIC_LINK_AUTH_SESSION_LENGTH * 24 * 60 * 60 * 1000;
      configuration = { lifespan };
    }

    return await magic.user.getIdToken(configuration);
  } catch (error) {
    await logError({ error, message: "getIdToken failed" });
    throw error;
  }
};

export const getUserMetadata = async () => {
  try {
    return await magic.user.getMetadata();
  } catch (error) {
    await logError({ error, message: "getMetadata failed" });
    throw error;
  }
};

export const updateUserEmail = async (email: string) => {
  try {
    return await magic.user.updateEmail({ email, showUI: false });
  } catch (error) {
    await logError({ error, message: "updateEmail failed" });
    throw error;
  }
};

export const loginWithMagicLink = async ({
  email,
  showUI = false,
}: {
  email: string;
  showUI?: boolean;
}) => {
  const redirectURI = document.location.href.split("?")[0];
  return await magic.auth.loginWithMagicLink({ email, showUI, redirectURI });
};

export const loginWithMagicLinkCredential = async () => {
  try {
    return await magic.auth.loginWithCredential();
  } catch (error) {
    await logError({
      error,
      message: "magic.link > loginWithCredential failed",
    });
    throw error;
  }
};
