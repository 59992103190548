import React, { FC } from "react";

import CommunityCardContainer from "components/_Community/CardContainer";
import BenefitBase from "containers/CommunityV2/Benefits/BenefitBase";

import * as styles from "./styles";
import { Props } from "./types";

const BenefitCard: FC<React.PropsWithChildren<Props>> = ({
  header,
  unlocked,
  unlockedByToken,
  communityContractAddress,
  additionalCss,
  children,
}) => {
  return (
    <CommunityCardContainer
      shadow="lg"
      extraCss={[styles.card, additionalCss]}
      padding="large"
    >
      <BenefitBase
        header={header}
        unlocked={unlocked}
        unlockedByToken={unlockedByToken}
        communityContractAddress={communityContractAddress}
      >
        {children}
      </BenefitBase>
    </CommunityCardContainer>
  );
};

export default BenefitCard;
