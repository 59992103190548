import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const cardContainer = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[10]};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  background-color: ${theme.textInput.backgroundColor};
  border: 1px solid;
  border-color: ${theme.divider};
  border-radius: 10px;

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.large[5]};
  }
`;

export const smallBlueButton = (theme: Theme) => css`
  font-size: ${theme.text.sizes.small};
  color: ${theme.colorMap.accent.default};
  font-weight: ${theme.text.fontWeights.bold};

  &:disabled {
    color: ${theme.text.colors.secondary};
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const fields = (theme: Theme) => css`
  list-style: none;
  margin: 40px 0 0 0;
  padding: 0;

  li {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    label {
      display: block;
      margin-bottom: 8px;
    }

    div {
      display: block;
      padding: 20px 16px;
      background-color: ${theme.textInput.backgroundColor};
      color: ${theme.textInput.textColor};
      border: ${theme.textInput.border.width} ${theme.textInput.border.style}
        ${theme.textInput.border.color};
      border-radius: ${theme.textInput.border.radius};
      position: relative;

      p {
        line-height: 1.2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;
        color: inherit;
      }

      button,
      a {
        position: absolute;
        top: calc(50%);
        transform: translateY(-50%);
        right: 20px;
      }
    }
  }
`;

export const buttonContainer = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[8]};
  padding: 20px;
  display: flex;
  align-items: center;
`;

export const contactUsContainer = css`
  ${topMargin("4/8px")}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const contactUsButton = css`
  ${topMargin("9/32px")}
  padding-left: 76px;
  padding-right: 76px;
`;

export const accountLinkedInput = css`
  ${topMargin("5/12px")}
`;

export const titleWithTooltip = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
