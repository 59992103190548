import React, { memo } from "react";

import { CreatedCommunitiesFieldsFragment } from "apollo/queries";
import CardContainer from "components/CardContainer";
import ManageCommunityCardLink from "components/ManageCommunityCardLink";
import Title from "components/Typography/Title";

import * as styles from "./styles";

type CreatorCommunitiesProps = {
  communities?: CreatedCommunitiesFieldsFragment["createdCommunities"];
};

const ManageCommunities = ({ communities }: CreatorCommunitiesProps) => {
  if (!communities) return null;

  return (
    <CardContainer border={true} additionalCss={styles.container}>
      <Title additionalCss={styles.containerTitle} size="small">
        Creator
      </Title>
      <div css={styles.list}>
        {communities.map((community, key) => (
          <ManageCommunityCardLink community={community} key={key} />
        ))}
      </div>
    </CardContainer>
  );
};

export default memo(ManageCommunities);
