import React, { FC, memo, useCallback, useState } from "react";

import { string as yupString } from "yup";

import { useGenerateClaimTokenContestMutation } from "apollo/queries";
import Button from "components/Button";
import Text from "components/Typography/Text";
import InputComponent from "containers/App/EditCommunity/Tokens/Shared/InputComponent";
import * as sharedStyles from "containers/App/EditCommunity/Tokens/Shared/styles";

import * as styles from "./styles";

export interface Props {
  communityId: string;
  tokenId: string;
  onContestCreated: (link: string) => void;
}

const GenerateClaimPage: FC<Props> = ({
  communityId,
  tokenId,
  onContestCreated,
}) => {
  const [recipients, setRecipients] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [doGenerateClaimTokenContestMutation, { loading }] =
    useGenerateClaimTokenContestMutation();

  const validationSchema = yupString()
    .trim()
    .matches(/^\d+$/, "Number of Recipients should have digits only")
    .not(["0"], "Number of Recipients must be greater than 0")
    .required("Number of Recipients is required");

  const handleOnClick = useCallback(async () => {
    setError("");

    try {
      const recipientsToSend = await validationSchema.validate(recipients);

      const result = await doGenerateClaimTokenContestMutation({
        variables: {
          tokenId: tokenId,
          communityId: communityId,
          data: {
            claimableTokens: +recipientsToSend,
            name: "",
          },
        },
      });
      onContestCreated(result.data?.generateClaimTokenContest.contestId || "");

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
      return;
    }
  }, [recipients, tokenId, communityId]);

  return (
    <div css={styles.container}>
      <Text as="p" size="medium">
        To help you send this token to people not in your community, we&apos;ll
        generate a page where people can claim this token for free. You can then
        send a link to this page to people you want to be able to claim this
        token.
      </Text>
      <InputComponent
        type="text-field"
        title="NUMBER OF RECIPIENTS"
        description="Choose how many people should be able to claim this token from the page we generate"
        placeholder="0"
        value={recipients}
        setValue={setRecipients}
      />
      <div css={[sharedStyles.mintButtonContainer, styles.buttonContainer]}>
        <Button
          onClick={handleOnClick}
          additionalCss={sharedStyles.mintButton}
          loading={loading}
        >
          Generate Claim Page Link
        </Button>
        {error && (
          <Text size="medium" additionalCss={styles.errorMessage}>
            {error}
          </Text>
        )}
      </div>
    </div>
  );
};

export default memo(GenerateClaimPage);
