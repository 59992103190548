import React, { memo, useEffect } from "react";

import { useParams } from "react-router-dom";

import ButtonLink from "components/ButtonLink";
import Layout from "components/Layout";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { APP_URL } from "config";
import useAppLocation from "hooks/useAppLocation";
import useSession from "hooks/useSession";

import TokenPreview from "../../Shared/TokenPreview";

import * as styles from "./styles";
import { Props } from "./types";

const MintingInProgress = ({
  imageUri,
  tokenName,
  tokenType,
  clearForm,
}: Props) => {
  const { getEditCommunityAbsoluteURL } = useAppLocation();
  const { user } = useSession();
  const { communitySlug } = useParams();

  useEffect(() => {
    return function cleanup() {
      clearForm();
    };
  }, []);

  const DoneButton = () => {
    return (
      <div css={styles.doneButtonContainer}>
        <ButtonLink
          additionalCss={styles.doneButton}
          to={getEditCommunityAbsoluteURL(
            communitySlug ?? "app",
            APP_URL.editCommunity.tokens
          )}
        >
          Return to Dashboard
        </ButtonLink>
      </div>
    );
  };

  return (
    <div>
      <Title size="large" additionalCss={styles.title}>
        Minting In Progress
      </Title>

      <Layout
        css={styles.layout}
        backgroundShow={true}
        backgroundColor="primary"
        showHeader={false}
        showFooter={false}
      >
        <TokenPreview showTitle={false} onLeft={true} imageUri={imageUri} />
        <div css={styles.textContainer}>
          <Text>
            We&apos;ve started minting your {tokenName} {tokenType} Token. This
            usually takes a few minutes, but could take a few hours depending on
            network conditions.
          </Text>
          <br />
          <Text>
            We&apos;ll send you an email at {user?.personalInformation?.email}{" "}
            as soon as the token has finished minting and is ready to launch.
          </Text>
        </div>
      </Layout>

      <DoneButton />
    </div>
  );
};

export default memo(MintingInProgress);
