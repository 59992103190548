import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const title = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[8]};
`;

export const fieldCard = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;

  ${topMargin("5/12px")}
`;

export const dot = (enabled: boolean) => (theme: Theme) =>
  css`
    width: 8px;
    height: 8px;
    background-color: ${!enabled
      ? theme.background.colors.quaternary
      : theme.colorMap.affirmative.primary};
    margin-right: 12px;
    border-radius: 50%;
  `;

export const fieldContainerText = css`
  margin-right: 16px;
  flex-grow: 1;
`;

export const buttonsGroup = css`
  display: flex;

  button:not(:last-child) {
    margin-right: 16px;
  }
`;

export const button = (theme: Theme) => css`
  width: auto;
  span {
    font-size: ${theme.text.sizes.small};
  }
`;
