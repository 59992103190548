import { Dispatch, SetStateAction, useCallback } from "react";

import { useApolloClient, useReactiveVar } from "@apollo/client";

import {
  StartMagicLinkFlowDocument,
  StartMagicLinkFlowQuery,
} from "apollo/queries";
import { modalVar, resetModalVar } from "apollo/reactive";
import { IN_DEVELOPMENT } from "config";
import { useAuthForm } from "hooks/useAuthForm";
import useSession from "hooks/useSession";
import { logError } from "services/logger";
import { AuthErrorType } from "utils/errors/authErrors";
import { getMetamaskAddress } from "utils/metamask";

const useLogic = ({
  setPolygonWalletAddress,
}: {
  polygonWalletAddress: string;
  setPolygonWalletAddress: Dispatch<SetStateAction<string>>;
}) => {
  const { createUserWithMagic, createUserWithWeb3, validateMetaMask } =
    useSession();
  const { dispatch } = useAuthForm();
  const apolloClient = useApolloClient();
  const { onClose } = useReactiveVar(modalVar);

  const onSelectWallet: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    const err = await validateMetaMask();
    if (err.type == AuthErrorType.NONE) {
      const addr = await getMetamaskAddress();
      setPolygonWalletAddress(addr);
      dispatch({ type: "clearError" });
    } else {
      dispatch({ type: "setError", payload: err });
      setPolygonWalletAddress("");
    }
  };

  const onSubmit = useCallback(async (email: string, useWeb3: boolean) => {
    try {
      dispatch({ type: "isLoading", payload: true });
      const startQuery = await apolloClient.query<StartMagicLinkFlowQuery>({
        query: StartMagicLinkFlowDocument,
        variables: {
          email,
        },
      });
      if (startQuery.error) {
        throw startQuery.error;
      }
      const { allowedMethods } = startQuery.data.startMagicLinkFlow;
      if (useWeb3) {
        if (!allowedMethods.includes("SIGNED_MESSAGE_CREATE_USER")) {
          if (allowedMethods.includes("MAGIC_LINK_LOGIN")) {
            throw {
              type: AuthErrorType.REQUIRE_MAGIC_LINK,
              message: AuthErrorType.REQUIRE_MAGIC_LINK as string,
            };
          } else if (allowedMethods.includes("SIGNED_MESSAGE_CREATE_USER")) {
            throw {
              type: AuthErrorType.GRAPHQL_LOGIN_NO_SUCH_USER_METAMASK,
              message:
                AuthErrorType.GRAPHQL_LOGIN_NO_SUCH_USER_METAMASK as string,
            };
          } else {
            throw new Error(
              "Unexpected allowedMethods returned for startMagicLinkFlow"
            );
          }
        }
        await createUserWithWeb3(email);
      } else {
        if (!allowedMethods.includes("MAGIC_LINK_CREATE_USER")) {
          if (allowedMethods.includes("MAGIC_LINK_LOGIN")) {
            throw {
              type: AuthErrorType.GRAPHQL_CREATE_EXISTING_USER_MAGIC,
              message:
                AuthErrorType.GRAPHQL_CREATE_EXISTING_USER_MAGIC as string,
            };
          } else if (allowedMethods.includes("SIGNED_MESSAGE_LOGIN")) {
            throw {
              type: AuthErrorType.REQUIRE_METAMASK,
              message: AuthErrorType.REQUIRE_METAMASK as string,
            };
          } else {
            throw new Error(
              "Unexpected allowedMethods returned for startMagicLinkFlow"
            );
          }
        }
        await createUserWithMagic(email, {
          magicLinkRedirect: true,
        });
      }
      dispatch({ type: "reset" });
      if (onClose) onClose();
      resetModalVar();
    } catch (error) {
      await logError({ error, message: "login failed" });
      if (useWeb3) {
        dispatch({ type: "setWeb3CreateError", payload: error });
      } else {
        dispatch({ type: "setCreateError", payload: error });
      }
      dispatch({ type: "isLoading", payload: false });
      IN_DEVELOPMENT && console.log("🚨 [ERROR][HANDLE_CREATE_USER]: ", error);
      return null;
    }
  }, []);

  return {
    onSubmit,
    onSelectWallet,
  };
};

export type UseLogic = ReturnType<typeof useLogic>;
export default useLogic;
