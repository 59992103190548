import React, { FC, memo, ComponentPropsWithRef } from "react";

import { Interpolation, Theme } from "@emotion/react";

import * as styles from "./styles";

type InputProps = ComponentPropsWithRef<"input">;

export type ImageBorder = "round" | "square";

export type ImageCardPreviewProps = InputProps & {
  imageUrl: string;
  imageBorder?: ImageBorder;
  // Don't use it to rewrite to component base styles.
  extraCss?: Interpolation<Theme>;
};

const ImageCardPreview: FC<ImageCardPreviewProps> = ({
  imageUrl,
  imageBorder = "round",
  extraCss,
}) => {
  return (
    <div css={[styles.imagePreviewContainer, extraCss]}>
      <div css={styles.imageContainer}>
        <img css={styles.image(imageBorder)} src={imageUrl} alt="Image" />
      </div>
    </div>
  );
};

export default memo(ImageCardPreview);
