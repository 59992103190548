import {
  useCommunityOffersQuery,
  CommunityOffersDocument,
  CommunityOffersQuery,
} from "apollo/queries";
import useAppLocation from "hooks/useAppLocation";
import { apolloClient } from "services/apolloClient";

const useCommunityOffers = () => {
  const { currentCommunitySlug } = useAppLocation();

  const { ...rest } = useCommunityOffersQuery({
    variables: {
      slug: currentCommunitySlug,
    },
  });

  return {
    ...rest,
  };
};

type QueryCommunity = NonNullable<CommunityOffersQuery["community"]>;

export const fetchCommunityOffers = async (slug: string) => {
  const result = await apolloClient.query({
    query: CommunityOffersDocument,
    variables: {
      slug,
    },
    fetchPolicy: "network-only",
  });
  const { offers } = result.data.community as QueryCommunity;
  return offers;
};

export default useCommunityOffers;
export type UseCommunity = ReturnType<typeof useCommunityOffers>;
