import React, { FC, memo } from "react";

import Label from "components/Typography/Label";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import useCommunityFeatureFlag from "hooks/useCommunityFeatureFlag";

import { TokenDetail } from "../types";

import * as styles from "./styles";

export type Props = {
  token?: TokenDetail;
  isMember?: boolean;
};

const TokenTitle: FC<Props> = ({ token, isMember }) => {
  const hideMembershipTokenReferences = useCommunityFeatureFlag(
    "hideMembershipTokenReferences"
  );
  const formatNumber = (number: number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  const nonTransferable = () => {
    return <>&#183; Non-transferable</>;
  };

  return (
    <div css={styles.container}>
      {!hideMembershipTokenReferences && (
        <Label color="primary" size="medium">
          {token?.tokenType} token
        </Label>
      )}
      <Title size="large" onBackgroundColor="primary">
        {token?.name}
      </Title>

      {token?.supply && (
        <div css={styles.tag}>
          {!!isMember && (
            <Text>
              You Own This &#183; Edition of {formatNumber(token?.supply)}{" "}
              {token?.mintedAsNonTransferable && nonTransferable()}
            </Text>
          )}
          {!isMember && (
            <Text>
              {token?.creatorBalance} / {token?.supply} Remaining{" "}
              {token?.mintedAsNonTransferable && nonTransferable()}
            </Text>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(TokenTitle);
