import { css, Theme } from "@emotion/react";

import { topMargin } from "../../../styles/global/layout";

export const form = (theme: Theme) => css`
  width: 100%;
  max-width: 484px;
  margin-top: 0;
  margin-bottom: ${theme.verticalSpaces.large[9]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.small[9]};
  }
`;

export const contestRules = css`
  ${topMargin("6/16px")};
  text-align: center;
  font-size: 12px;
  line-height: 18px;

  a {
    color: inherit;
    text-decoration: underline;

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const loadingWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const button = css`
  text-transform: none;
`;
