import React, { memo, ReactNode, useEffect } from "react";

import { NavLink, Outlet, useNavigate, useOutlet } from "react-router-dom";

import { ReactComponent as SettingsIcon } from "assets/icons/account-settings.svg";
import { ReactComponent as EmailPreferencesIcon } from "assets/icons/email-preferences.svg";
import Layout from "components/Layout";
import SidebarNav from "components/SidebarNav";
import { APP_URL, FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";

import * as styles from "./styles";

export type Props = {
  children: ReactNode;
};

const checkActive = ({ isActive }: { isActive: boolean }) =>
  isActive ? "active" : "";

const AccountLayout = () => {
  const showAccountWalletPage = useFeatureFlag(
    FEATURE_FLAG.ENABLE_ACCOUNT_WALLET_PAGE
  );
  const showAccountTransactionHistoryPage = useFeatureFlag(
    FEATURE_FLAG.ENABLE_ACCOUNT_TRANSACTION_HISTORY_PAGE
  );
  const showAccountEmailPreferencesPage = useFeatureFlag(
    FEATURE_FLAG.ENABLE_ACCOUNT_EMAIL_PREFERENCES_PAGE
  );

  const outlet = useOutlet();
  const navigate = useNavigate();

  // Force general url open if user access base url.
  useEffect(() => {
    if (!outlet) {
      navigate(APP_URL.account.settings, { replace: true });
      return;
    }
  }, [outlet]);

  const showAccountSettingsButton =
    showAccountWalletPage ||
    showAccountTransactionHistoryPage ||
    showAccountEmailPreferencesPage;

  return (
    <Layout
      css={styles.container}
      backgroundShow={true}
      backgroundColor="primary"
      navLayout="account"
    >
      <div css={styles.sidebar}>
        <SidebarNav>
          {showAccountSettingsButton && (
            <NavLink to={APP_URL.account.settings} className={checkActive} end>
              <SettingsIcon /> Account Settings
            </NavLink>
          )}

          {showAccountWalletPage && (
            <NavLink to={APP_URL.account.wallet} className={checkActive}>
              Wallet
            </NavLink>
          )}

          {showAccountTransactionHistoryPage && (
            <NavLink
              to={APP_URL.account.transactionHistory}
              className={checkActive}
            >
              Transaction History
            </NavLink>
          )}

          {showAccountEmailPreferencesPage && (
            <NavLink
              to={APP_URL.account.emailPreferences}
              className={checkActive}
            >
              <EmailPreferencesIcon />
              Email Preferences
            </NavLink>
          )}
        </SidebarNav>
      </div>

      <div css={styles.content}>
        <div css={styles.contentWrapper}>
          <Outlet />
        </div>
      </div>
    </Layout>
  );
};

export default memo(AccountLayout);
