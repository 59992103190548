import { css, Theme } from "@emotion/react";

export const selectContainer = css`
  @media (hover: hover) {
    &:hover {
      svg {
        transform: translateY(3px);
      }
    }
  }
`;

export const select = css`
  padding: 8px 0 8px 16px;
  display: block;
  min-height: 64px;
  background-color: transparent;
`;

export const arrowIcon = (theme: Theme) => css`
  position: absolute;
  right: 16px;
  max-width: 12px;
  transition: transform 0.6s ${theme.animations.easeOutQuart};
`;

export const textContainer = css`
  flex-direction: column;
`;
