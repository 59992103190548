import { css } from "@emotion/react";

export const container = css`
  padding-top: 20px;
`;

export const content = css`
  width: 100%;

  > div:not(:last-child) {
    margin-bottom: 32px;
  }
`;
