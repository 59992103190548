import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const tokenDescription = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[3]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[3]};
  }
`;

type LayoutType = "communityDescription" | "tokenDescription" | "tokenCards";

const layoutColumnConfig: {
  [key in LayoutType]: { small: number; medium: number; large: number };
} = {
  communityDescription: {
    small: 12,
    medium: 10,
    large: 8,
  },
  tokenDescription: {
    small: 10,
    medium: 10,
    large: 10,
  },
  tokenCards: {
    small: 12,
    medium: 12,
    large: 12,
  },
};

export const layout = (layoutType: LayoutType) => (theme: Theme) =>
  css`
    display: grid;
    grid-template-columns: ${theme.layout.gridColumns(
      layoutColumnConfig[layoutType].large
    )};
    justify-content: center;
    margin: 0 auto;

    ${theme.mediaQueries.mediumOnly} {
      grid-template-columns: ${theme.layout.gridColumns(
        layoutColumnConfig[layoutType].medium
      )};
    }

    ${theme.mediaQueries.smallOnly} {
      grid-template-columns: ${theme.layout.gridColumns(
        layoutColumnConfig[layoutType].small
      )};
    }
  `;

export const communitySection = css`
  text-align: center;
`;

export const tokenSection = (theme: Theme) => css`
  text-align: center;
  margin-top: ${theme.verticalSpaces.large[10]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[10]};
  }
`;

export const loadingIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const distributionCardsContainer = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  ${topMargin("12/46px")}

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[11]};
  }
`;

export const presLineBreaks = css`
  white-space: pre-wrap;
`;
