import React, { memo } from "react";

import { useReactiveVar } from "@apollo/client";

import { ModalType, modalVar } from "apollo/reactive/modal";

import Text from "../../Typography/Text";
import Title from "../../Typography/Title";
import baseStyles from "../ModalBody/styles.module.scss";
import ReactModalBase from "../ReactModalBase";

const TokenQuantityModal: React.FC = () => {
  const { showModal } = useReactiveVar(modalVar);

  return (
    <ReactModalBase
      id="token-quantity-learn-more"
      className={`${baseStyles.modal} ${baseStyles.modalLarge}`}
      overlayClassName={baseStyles.modalOverlay}
      isOpen={showModal === ModalType.TOKEN_QUANTITY_LEARN_MORE}
      closeTimeoutMS={800}
      ariaHideApp={false}
    >
      <Title size="small">How Many Tokens Should I Mint?</Title>
      <Text as="div">
        Note that once you mint this token you will not be able to create more
        of this token. However, you can mint a new token with similar benefits.
        Consider the following when choosing how many tokens to mint:
        <ul>
          <li>
            If you mint a new token that is identical to an existing one, it may
            devalue the existing token.
          </li>
          <li>
            Think about how you could make new tokens different than existing
            ones. For example, add different artwork or a new membership tier
            with different benefits.
          </li>
          <li>
            If you mint additional membership tokens, consider rewarding your
            current members in some way.
          </li>
        </ul>
      </Text>
    </ReactModalBase>
  );
};

export default memo(TokenQuantityModal);
