import { GraphQLError } from "graphql/error";
import { RPCError, RPCErrorCode } from "magic-sdk";

import { LOGIN_ERRORS, LOGIN_WITH_MAGICLINK_ERRORS } from "config";
import { InvalidAccessTokenErrorCode } from "services/auth";

// TODO: refactor LogInError => KnownServerErrorResponse = https://highlightxyz.atlassian.net/browse/HIGHLIGHT-2516
export type LogInError = {
  title: string;
  message: string;
};

export const DefaultLoginError = {
  title: LOGIN_ERRORS.GENERIC_TITLE,
  message: LOGIN_ERRORS.GENERIC_DESCRIPTION,
};

export const parseLogInWithMagicLinkError = (error: RPCError): LogInError => {
  const defaultError = DefaultLoginError;

  switch (error.code) {
    case RPCErrorCode.MagicLinkExpired:
      return {
        title: LOGIN_WITH_MAGICLINK_ERRORS.GENERIC_TITLE,
        message: LOGIN_WITH_MAGICLINK_ERRORS.LINK_EXPIRED_DESCRIPTION,
      };

    case RPCErrorCode.UpdateEmailFailed:
      return {
        title: LOGIN_WITH_MAGICLINK_ERRORS.GENERIC_TITLE,
        message: LOGIN_WITH_MAGICLINK_ERRORS.UPDATE_EMAIL_FAILED_DESCRIPTION,
      };

    case RPCErrorCode.InvalidParams:
      return {
        title: LOGIN_WITH_MAGICLINK_ERRORS.GENERIC_TITLE,
        message:
          error.rawMessage?.replace("Invalid params: ", "") ??
          LOGIN_WITH_MAGICLINK_ERRORS.GENERIC_DESCRIPTION,
      };

    default:
      return defaultError;
  }
};

export const isAuthError = (
  graphQLErrors: readonly GraphQLError[] | undefined
) => {
  if (!graphQLErrors) return false;

  const errors = graphQLErrors.find(
    ({ extensions }) => extensions.code === InvalidAccessTokenErrorCode
  );

  return !!errors;
};

export const findErrorByStatus = (errors: GraphQLError[], status: number) =>
  errors.find(({ extensions }) => extensions.status === status);

export const getGraphQLErrorCodes = (
  graphQLErrors: GraphQLError[] | undefined
) => {
  if (!graphQLErrors) return [];

  return graphQLErrors.map(({ extensions }) => extensions.code);
};
