import { css, Theme } from "@emotion/react";

import { textMediumStyles } from "styles/global/typography";
import { parseBorderType } from "styles/theme/communityTheme";

export const fieldGroup = css`
  margin-bottom: 32px;
`;

export const label = css`
  display: block;
  margin-bottom: 8px;
`;

const applyBorder =
  (borderColor: string, borderWidth: string, error: string | undefined) =>
  (theme: Theme) => {
    if (error) {
      return css`
        border-style: solid;
        border-width: 2px;
        border-color: ${theme.colorMap.destructive.default};
        border-radius: ${parseBorderType(
          theme.community.textInput.borderType,
          true
        )};
      `;
    }
    if (borderColor === "none" || borderColor === "") return;
    if (borderWidth === "none" || borderWidth === "") return;

    return css`
      border-style: solid;
      border-width: ${borderWidth};
      border-color: ${theme.community.textInput.borderColor};
      border-radius: ${parseBorderType(
        theme.community.textInput.borderType,
        true
      )};
    `;
  };

export const input = (error: string | undefined) => (theme: Theme) =>
  css`
    display: block;
    appearance: none;
    width: 100%;
    outline: none;
    padding: 16px;
    margin: 0;
    resize: none;
    background-color: ${theme.community.textInput.backgroundColor};
    color: ${theme.community.textInput.primaryTextColor};
    font-family: ${theme.community.text.fontFamily};

    ${textMediumStyles(theme)}
    ${applyBorder(
      theme.community.textInput.borderColor,
      theme.community.textInput.borderWidth,
      error
    )(theme)}
    &::placeholder {
      opacity: initial; //Remove if we stop using sanitize css
      color: ${theme.community.textInput.placeholderTextColor};
    }

    ${theme.mediaQueries.mediumUp} {
      padding: 20px 16px;
    }
  `;

export const error = (theme: Theme) => css`
  ${textMediumStyles(theme)}
  color: ${theme.colorMap.destructive.default};
  padding: 0;
  margin: 8px 0 0 0;
`;

export const inputWrapper = css`
  position: relative;
  display: block;
`;

export const counter = css`
  margin: 0;
  padding: 0;
  position: absolute;
  right: 16px;
  bottom: 12px;
  z-index: 1;
  pointer-events: none;
`;
