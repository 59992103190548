import { useState } from "react";

import { useForm } from "react-hook-form";

import { CreateReleasePartyCommentInput } from "apollo/graphql.generated";
import { useCreateNewCommentMutation } from "apollo/queries";
import { logError } from "services/logger";

type FormType = {
  text: string;
};

export const useLogic = ({
  communityId,
  releasePartyId,
  afterSubmit,
}: {
  communityId: string;
  releasePartyId: string;
  afterSubmit: () => Promise<void>;
}) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormType>();
  const [error, setError] = useState<string>("");
  const [createComment] = useCreateNewCommentMutation();

  const onSubmit = async (values: FormType) => {
    try {
      if (!values.text || values.text.length === 0) {
        setError("Please enter some text.");
        return false;
      }

      const createCommentData: CreateReleasePartyCommentInput = {
        communityId,
        entityId: releasePartyId,
        text: values.text,
      };
      await createComment({
        variables: {
          data: createCommentData,
        },
      });

      setError("");
      await afterSubmit();
    } catch (e) {
      setError("An unexpected error occurred, please try again.");
      await logError({
        e,
        message: "[handleCreateComment] create comment failed",
      });
    }
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    register,
    isSubmitting,
    error,
    setError,
  };
};
