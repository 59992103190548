import { css, Theme } from "@emotion/react";

export const fields = (theme: Theme) => css`
  list-style: none;
  margin-top: 40px;
  padding: 0;

  ul {
    padding: 0;
  }

  li {
    display: block;
    padding: 20px 16px;
    background-color: ${theme.textInput.backgroundColor};
    color: ${theme.textInput.textColor};
    border: ${theme.textInput.border.width} ${theme.textInput.border.style}
      ${theme.textInput.border.color};
    border-radius: ${theme.textInput.border.radius};
    position: relative;

    &:not(:last-child) {
      margin-bottom: ${theme.verticalSpaces.large[3]};
    }

    label {
      display: block;
      margin-bottom: 8px;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const errorContainer = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[4]};
`;
