import React, { FC, memo } from "react";

import { Interpolation, Theme } from "@emotion/react";

import { ResizedImage } from "components/Image";
import Text from "components/Typography/Text";
import { Maybe } from "utils/types";

import { layoutContext } from "../../apollo/reactive/layout";
import DefaultCreatorImage from "../../assets/images/default-creator-avatar.png";
import { ThemeType } from "../../styles/theme/theme.types";
import CommunityText from "../_Community/Text";

import * as styles from "./styles";

export type CommunityAvatarSmallSize = "small" | "medium" | "xlarge";

export type Props = {
  overwriteTheme?: ThemeType;
  imageUrl?: Maybe<string>;
  name?: Maybe<string>;
  size?: CommunityAvatarSmallSize;
  showAvatar?: boolean;
  showVerified?: boolean;
  bold?: boolean;
  insideCardContainer?: boolean;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
  /**
   * @deprecated Use "extraCss" instead.
   */
  additionalCss?: Interpolation<Theme>;
};

const CommunityAvatarSmall: FC<Props> = ({
  size = "medium",
  showAvatar = true,
  showVerified = true,
  insideCardContainer = false,
  name,
  imageUrl,
  overwriteTheme,
  bold = true,
  additionalCss,
  extraCss,
}) => {
  const layout = layoutContext();
  const theme = overwriteTheme ? overwriteTheme : layout.theme ?? "system";

  const TitleContent = () => (
    <>
      <span>{name}</span>
      {showVerified && null}
    </>
  );

  return (
    <div css={[styles.container, additionalCss, extraCss]}>
      {showAvatar && (
        <ResizedImage
          css={styles.image(size)}
          src={imageUrl || DefaultCreatorImage}
          alt={name || ""}
          width={styles.getImageWidth(size)}
        />
      )}
      {theme === "community" ? (
        <CommunityText
          color={
            insideCardContainer
              ? "onPrimaryBackgroundPrimaryColor"
              : "onPageBackgroundPrimaryColor"
          }
          size={size}
          extraCss={styles.name(size)}
          bold={bold}
        >
          <TitleContent />
        </CommunityText>
      ) : (
        <Text
          onBackgroundColor="primary"
          size={size}
          extraCss={styles.name(size)}
          bold={bold}
        >
          <TitleContent />
        </Text>
      )}
    </div>
  );
};

export default memo(CommunityAvatarSmall);
