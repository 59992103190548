import React, { ComponentProps, memo, useEffect } from "react";

import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import {
  InitialEditorStateType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode } from "@lexical/rich-text";

import { CreatedCommunityFeedBySlugQuery } from "containers/App/EditCommunity/Posts/createPosts.graphql.generated";

import { Attachment } from "../../../apollo/graphql.generated";
import useAppLocation from "../../../hooks/useAppLocation";
import Text from "../../Typography/Text";

import ErrorBanner from "./ErrorBanner";
import TextEditorTokenSelectModal from "./components/TokenSelectModal";
import useContentFeedEditorLogic, { convertOldEditorContent } from "./logic";
import { AudioNode } from "./nodes/AudioNode";
import { ImageNode } from "./nodes/ImageNode";
import { TokenNode } from "./nodes/TokenNode";
import { VideoNode } from "./nodes/VideoNode";
import AttachmentManager from "./plugins/AttachmentManager";
import AudioAttachmentPlugin from "./plugins/AudioAttachmentPlugin";
import AutoFocusPlugin from "./plugins/AutoFocusPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ImageAttachmentPlugin from "./plugins/ImageAttachmentPlugin";
import TokenAttachmentPlugin from "./plugins/TokenAttachmentPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import VideoAttachmentPlugin from "./plugins/VideoAttachmentPlugin";
import * as styles from "./styles";
import editorTheme from "./theme";

/**
 * Inner Component
 */
export type EditorComposerProps = {
  community: ContentFeedEditorProps["community"];
  initialIsEditable: boolean;
};

const Placeholder = () => {
  return (
    <Text as="span" color="secondary" extraCss={styles.editorPlaceholder}>
      Write something to your community...
    </Text>
  );
};

const EditorComposer = ({
  community,
  initialIsEditable,
}: EditorComposerProps) => {
  const { isAppSlug } = useAppLocation();

  const {
    activeEditor,
    isEditable,
    setIsEditable,
    handleFileSelected,
    publishPost,
    blockType,
    selectedElement,
    notifyMembers,
    pinPost,
    error,
    setError,
    isSubmitting,
  } = useContentFeedEditorLogic({
    communitySlug: community?.slug ?? null,
    initialIsEditable: initialIsEditable,
  });

  useEffect(() => {
    setIsEditable(initialIsEditable);
  }, [initialIsEditable]);

  return (
    <>
      <div css={isEditable ? styles.editorContainer : null}>
        {isEditable && (
          <ToolbarPlugin
            activeEditor={activeEditor}
            blockType={blockType}
            selectedElement={selectedElement}
            isEditable={isEditable}
            notifyMembers={notifyMembers}
            pinPost={pinPost}
          />
        )}

        <div css={styles.editorContent}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                css={styles.editorInput(isEditable, isAppSlug)}
              />
            }
            placeholder={isEditable ? <Placeholder /> : ""}
          />
          <AutoLinkPlugin />
          <LinkPlugin />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ClearEditorPlugin />
          <ListPlugin />

          {/* Attachments */}
          <AudioAttachmentPlugin />
          <VideoAttachmentPlugin />
          <TokenAttachmentPlugin />
          <ImageAttachmentPlugin />
        </div>

        {isEditable && (
          <>
            <AttachmentManager
              activeEditor={activeEditor}
              isEditable={isEditable}
              handleFileSelected={handleFileSelected}
              publishPost={publishPost}
              isSubmitting={isSubmitting}
            />
            <ErrorBanner
              error={error}
              setError={setError}
              isEditable={isEditable}
            />
          </>
        )}
      </div>
      {/*<TreeViewPlugin />*/}
    </>
  );
};

/**
 * Main Component
 */
export type ContentFeedEditorProps = {
  editable?: boolean;
  tokens?: NonNullable<
    CreatedCommunityFeedBySlugQuery["createdCommunityBySlug"]
  >["tokens"];
  community?: CreatedCommunityFeedBySlugQuery["createdCommunityBySlug"];
  initialEditorState?: InitialEditorStateType;
  attachments: Attachment | null;
};

type LexicalComposerInitialConfig = ComponentProps<
  typeof LexicalComposer
>["initialConfig"];

const TextEditor = ({
  tokens,
  community,
  editable = false,
  initialEditorState,
  attachments = null,
}: ContentFeedEditorProps) => {
  let editorState: InitialEditorStateType | undefined = undefined;

  if (initialEditorState) {
    try {
      const editorObject = JSON.parse(initialEditorState as string);

      if (editorObject.root.children.length !== 0) {
        editorState = initialEditorState;
      }
    } catch (e) {
      editorState = convertOldEditorContent(
        initialEditorState as string,
        attachments
      );
    }
  }

  const initialConfig: LexicalComposerInitialConfig = {
    namespace: "TextEditor",
    theme: editorTheme,
    editorState,
    onError(error: unknown) {
      console.error(error);
    },
    nodes: [
      HeadingNode,
      AutoLinkNode,
      ListNode,
      ListItemNode,
      LinkNode,
      AudioNode,
      VideoNode,
      TokenNode,
      ImageNode,
    ],
    editable,
  };

  return (
    <>
      <LexicalComposer initialConfig={initialConfig}>
        <EditorComposer community={community} initialIsEditable={editable} />
      </LexicalComposer>

      {tokens && editable && (
        <TextEditorTokenSelectModal
          tokens={tokens}
          offers={community?.offers || []}
          claimPages={community?.contests || []}
          communitySlug={community?.slug}
        />
      )}
    </>
  );
};

export default memo(TextEditor);
