import React, { memo } from "react";

import { HashLink } from "react-router-hash-link";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-default.svg";
import { ReactComponent as CheckCircle } from "assets/icons/check-within-circle.svg";
import Text from "components/Typography/Text";
import { scrollWithOffset } from "utils/links";

import * as styles from "./styles";
import { Props } from "./types";

const ChecklistLink = ({
  primaryText = "",
  secondaryText = "",
  to = "",
  checked = false,
  offset = 0,
}: Props) => {
  return (
    <HashLink
      smooth
      to={to}
      css={styles.link}
      scroll={scrollWithOffset(offset)}
    >
      <div css={styles.checklistLeft}>
        <Text as="p" bold={true} size="medium">
          {primaryText}
        </Text>
        <Text as="p" size="medium" color="secondary">
          {secondaryText}
        </Text>
      </div>
      {!checked && <ArrowRight css={styles.checklistRight} />}
      {checked && <CheckCircle css={styles.check} />}
    </HashLink>
  );
};

export default memo(ChecklistLink);
