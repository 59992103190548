import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  padding: ${theme.modal.padding.medium};
  width: 100%;
  max-width: 572px;

  ${theme.mediaQueries.mediumOnly} {
    max-width: 510px;
  }

  ${theme.mediaQueries.smallOnly} {
    padding: ${theme.modal.padding.small};
  }
`;

export const icon = (theme: Theme) => css`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${theme.verticalSpaces.large[5]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.small[5]};
  }
`;

export const title = (theme: Theme) => css`
  text-align: center;
  margin-bottom: ${theme.verticalSpaces.large[3]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.small[3]};
  }
`;

export const text = css`
  text-align: center;
`;
