import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const container = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;

  ${theme.mediaQueries.smallOnly} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const copyAddressButton = css`
  margin-right: 20px;
`;

export const actions = (theme: Theme) => css`
  ${theme.mediaQueries.smallOnly} {
    ${topMargin("6/16px")}
  }
`;
