import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type OfferQueryVariables = Types.Exact<{
  offerId: Types.Scalars["String"];
  onPrivatePage: Types.Maybe<Types.Scalars["Boolean"]>;
}>;

export type OfferQuery = {
  offer: {
    __typename: "Offer";
    id: string;
    communityId: string;
    tokenIds: Array<number>;
    price: string;
    currency: string;
    active: boolean;
    onAboutPage: boolean | null;
    onPrivatePage: boolean | null;
    crypto: { __typename: "CryptoCurrency"; inUSD: number | null } | null;
  };
};

export type CurrencyConversionQueryVariables = Types.Exact<{
  from: Types.Scalars["String"];
  to: Types.Scalars["String"];
}>;

export type CurrencyConversionQuery = {
  conversion: { __typename: "CurrencyConversion"; value: string } | null;
};

export const OfferDocument = gql`
  query Offer($offerId: String!, $onPrivatePage: Boolean) {
    offer(id: $offerId, onPrivatePage: $onPrivatePage) {
      id
      communityId
      tokenIds
      price
      currency
      crypto {
        inUSD
      }
      active
      onAboutPage
      onPrivatePage
    }
  }
`;

/**
 * __useOfferQuery__
 *
 * To run a query within a React component, call `useOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      onPrivatePage: // value for 'onPrivatePage'
 *   },
 * });
 */
export function useOfferQuery(
  baseOptions: Apollo.QueryHookOptions<OfferQuery, OfferQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferQuery, OfferQueryVariables>(
    OfferDocument,
    options
  );
}
export function useOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OfferQuery, OfferQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferQuery, OfferQueryVariables>(
    OfferDocument,
    options
  );
}
export type OfferQueryHookResult = ReturnType<typeof useOfferQuery>;
export type OfferLazyQueryHookResult = ReturnType<typeof useOfferLazyQuery>;
export type OfferQueryResult = Apollo.QueryResult<
  OfferQuery,
  OfferQueryVariables
>;
export const CurrencyConversionDocument = gql`
  query CurrencyConversion($from: String!, $to: String!) {
    conversion(from: $from, to: $to) {
      value
    }
  }
`;

/**
 * __useCurrencyConversionQuery__
 *
 * To run a query within a React component, call `useCurrencyConversionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrencyConversionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrencyConversionQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useCurrencyConversionQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrencyConversionQuery,
    CurrencyConversionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrencyConversionQuery,
    CurrencyConversionQueryVariables
  >(CurrencyConversionDocument, options);
}
export function useCurrencyConversionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrencyConversionQuery,
    CurrencyConversionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrencyConversionQuery,
    CurrencyConversionQueryVariables
  >(CurrencyConversionDocument, options);
}
export type CurrencyConversionQueryHookResult = ReturnType<
  typeof useCurrencyConversionQuery
>;
export type CurrencyConversionLazyQueryHookResult = ReturnType<
  typeof useCurrencyConversionLazyQuery
>;
export type CurrencyConversionQueryResult = Apollo.QueryResult<
  CurrencyConversionQuery,
  CurrencyConversionQueryVariables
>;
