import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const cardContainer = (isBundle: boolean) => (theme: Theme) =>
  css`
    display: flex;
    flex-direction: column;
    padding: ${isBundle ? "20px 40px 40px 40px" : "20px 24px 24px 24px"};
    max-width: ${isBundle ? "484px" : "308px"};
    min-height: ${isBundle ? "484px" : "308px"};

    ${theme.mediaQueries.smallOnly} {
      max-width: 100%;
    }
  `;

export const cardTitle = css`
  text-align: center;
`;

export const imageFirstCardTitle = css`
  ${topMargin("7/20px")}
`;

export const cardSubtitle = css`
  text-align: center;
  ${topMargin("1/2px")}
`;

export const cardImageContainer = (isBundle: boolean) => css`
  max-width: ${isBundle ? "484px" : "308px"};
  min-height: ${isBundle ? "484px" : "308px"};
  padding: ${isBundle ? "0 48px" : undefined};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const titleFirstCardImageContainer = (isBundle: boolean) => css`
  padding: ${isBundle ? "0 48px" : undefined};
  ${topMargin("7/20px")}
`;

export const imageFirstCardImageContainer = css`
  margin: -14px -18px 0px -18px;
`;

export const availableOnLabel = css`
  opacity: 0.6;
  ${topMargin("7/20px")}
`;

export const cardImage = css`
  border-radius: 4px;
  max-width: 100%;
  max-height: 100%;
`;

export const benefitContainer = (isBundle: boolean) => css`
  height: 100%;
  ${topMargin(isBundle ? "9/32px" : "8/24px")}
`;

export const benefitListContainer = css`
  ${topMargin("5/12px")}
`;

export const includedTokensContainer = css`
  ${topMargin("8/24px")}
`;

export const includedTokens = css`
  > * {
    ${topMargin("5/12px")}
  }
`;

export const distributionButton = css`
  ${topMargin("8/24px")}

  svg {
    width: 32px;
    height: 32px;
    margin: -6px 0 -6px 0;
  }
`;
