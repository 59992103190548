import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[7]};
  margin-bottom: ${theme.verticalSpaces.large[12]};
  display: grid;
  grid-column-gap: ${theme.layout.gridColumnWidth};
  grid-template-columns: ${theme.layout.gridColumns(9, 3)};
`;

export const copyButton = (theme: Theme) => css`
  margin-left: auto;
  align-self: center;
  justify-content: center;
  font-weight: ${theme.text.fontWeights.bold};
  background-color: transparent;
  border: none;
  text-align: center;
  padding: 0;
  max-width: 90px;
  color: ${theme.text.colors.primary};
`;

export const url = (theme: Theme) => css`
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
  color: ${theme.text.colors.primary};
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
