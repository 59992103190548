import { css, Theme } from "@emotion/react";

import { bottomMargin } from "styles/global/layout";

import * as systemTextStyles from "../../Typography/Text/styles";
import * as systemTitleStyles from "../../Typography/Title/styles";
import * as communityTextStyles from "../../_Community/Text/styles";
import * as communityTitleStyles from "../../_Community/Title/styles";

/* Styles */

export const editorContainer = (theme: Theme) =>
  css`
    position: relative;
    display: flex;
    padding: 20px;
    flex-direction: column;
    margin: 4em 0;
    background: ${theme.background.colors.primary};
    border: 1px solid ${theme.colorMap.utility.divider};
    border-radius: 10px;

    ${theme.mediaQueries.mediumDown} {
      margin: 20px 0 60px 0;
      padding: 16px;
    }
  `;

export const editorContent = css`
  position: relative;
`;

export const editorInput =
  (isEditable: boolean, isAppSlug: boolean) => (theme: Theme) =>
    css`
      min-height: ${isEditable ? "140px" : "0px"};
      box-shadow: none;

      :focus-visible {
        outline: none;
      }

      > * {
        display: block;
        margin-block: 1.25rem;

        &:first-of-type {
          margin-block-start: 0;
        }

        &:last-child {
          margin-block-end: 0;
        }
      }

      // Headings
      > h1 {
        ${isAppSlug
          ? css`
              ${systemTitleStyles.base(theme)};
              ${systemTitleStyles.byColor("primary", false)(theme)};
              font-weight: 700;
            `
          : css`
              ${communityTitleStyles.base(theme)}
              ${communityTitleStyles.bySize("small")(theme)}
            ${communityTitleStyles.byColor("onPrimaryBackgroundPrimaryColor")(
                theme
              )}
            `}
      }

      > h2 {
        ${isAppSlug
          ? css`
              ${systemTitleStyles.base(theme)};
              ${systemTitleStyles.byColor("primary", false)(theme)};
              font-weight: 700;
            `
          : css`
              ${communityTitleStyles.base(theme)}
              ${communityTitleStyles.bySize("xsmall")(theme)}
            ${communityTitleStyles.byColor("onPrimaryBackgroundPrimaryColor")(
                theme
              )}
            `}
      }

      > h3 {
        ${isAppSlug
          ? css`
              ${systemTextStyles.bySize("large", true, false)(theme)}
              ${systemTextStyles.byColor("primary", false)(theme)}
            font-weight: 600;
            `
          : css`
              ${communityTextStyles.base(false, false)(theme)}
              ${communityTextStyles.bySize("large")(theme)}
            ${communityTextStyles.byColor("onPrimaryBackgroundPrimaryColor")(
                theme
              )}
            `}
      }

      // Paragraphs
      > p,
      > ul,
      > strong,
      > b {
        a {
          color: inherit;
          text-decoration: underline;
        }

        ${isAppSlug
          ? css`
              ${systemTextStyles.bySize("medium", false, false)(theme)}
              ${systemTextStyles.byColor("primary", false)(theme)}
            `
          : css`
              ${communityTextStyles.base(false, false)(theme)}
              ${communityTextStyles.bySize("medium")(theme)}
            ${communityTextStyles.byColor("onPrimaryBackgroundPrimaryColor")(
                theme
              )}
            `}
      }

      // Inline Styles
      .editor-text-bold {
        font-weight: bold;
      }

      .editor-text-italic {
        font-style: italic;
      }

      &[contenteditable="true"] {
        > h1,
        > h2,
        > h3,
        > p {
          cursor: text;
        }
      }
    `;

export const editorPlaceholder = css`
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  left: 0;
  user-select: none;
  display: inline-block;
  pointer-events: none;
`;

/* Toolbar */

export const toolbarContainer = css`
  ${bottomMargin("7/20px")}
`;

export const toolbarTypography = css`
  ${bottomMargin("7/20px")}
`;

export const toolbarActions = css`
  ${bottomMargin("7/20px")}
`;
