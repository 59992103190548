import React, { memo, useEffect, useRef, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import { Global } from "@emotion/react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

import { MeDocument, useCheckoutCompletedMutation } from "apollo/queries";
import {
  communityVar,
  ModalType,
  resetCartVar,
  updateModalVar,
} from "apollo/reactive";
import Layout from "components/Layout";
import Text from "components/Typography/Text";
import { ANALYTICS_EVENT, COMMUNITY_URL, DEFAULT_ERROR_MESSAGE } from "config";
import { useCheckoutLogic } from "containers/Checkout/logic";
import { logError } from "services/logger";
import { trackEvent } from "services/segment";
import { stripeConstructor } from "services/stripe";
import { formatCurrency } from "utils/formatting/currency";

import FailedStatus from "./FailedStatus";
import SucceededStatus from "./SucceededStatus";
import * as styles from "./styles";

const Page = () => {
  const navigate = useNavigate();
  const { offer, isCartEmpty, redirectUrl } = useCheckoutLogic();
  const offerRef = useRef(offer);
  const [waitingOffer, setWaitingOffer] = useState<boolean>(true);

  const stripe = useStripe();

  const [view, setView] = useState<string>("");
  const [savedRedirectUrl, setSavedRedirectUrl] = useState<string>(
    COMMUNITY_URL.home
  );

  const community = useReactiveVar(communityVar);
  const [completeCheckout] = useCheckoutCompletedMutation({
    refetchQueries: [MeDocument],
  });

  useEffect(() => {
    if (isCartEmpty) {
      navigate(COMMUNITY_URL.home);
      return;
    }
  }, []);

  useEffect(() => {
    if (!offer) {
      return;
    }
    offerRef.current = offer;
    setWaitingOffer(false);
  }, [offer]);

  useEffect(() => {
    if (!stripe || !offer || waitingOffer) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      navigate(COMMUNITY_URL.notFound);
      return;
    }

    stripe.retrievePaymentIntent(clientSecret!).then(({ paymentIntent }) => {
      const { id, client_secret, status } = paymentIntent!;
      let eventName;
      switch (status) {
        case "succeeded":
          setView("succeeded");
          eventName = ANALYTICS_EVENT.CHECKOUT_COMPLETED_SUCCESSFULLY;
          break;
        default:
          setView("failed");
          eventName = ANALYTICS_EVENT.CHECKOUT_COMPLETED_FAILURE;
          break;
      }

      trackEvent(eventName, {
        payment: {
          intent: id,
          clientSecret: client_secret as string,
          status: status,
        },
        community: {
          name: community.name,
        },
        offer: {
          id: offer.id,
          price: formatCurrency(offer.price, offer.currency),
        },
        tokens: (offer.tokens || []).map((offerToken) => ({
          communityAddress: offerToken?.communityAddress,
          id: offerToken?.tokenId,
          name: offerToken?.name,
          supply: offerToken?.supply,
          creatorBalance: offerToken?.creatorBalance,
        })),
      });
      setSavedRedirectUrl(redirectUrl ?? COMMUNITY_URL.home);
      resetCartVar();
      completeCheckout({
        variables: {
          paymentIntent: id,
          paymentIntentClientSecret: client_secret as string,
          redirectStatus: status,
        },
        fetchPolicy: "network-only",
      }).catch((error) => {
        updateModalVar({ showModal: ModalType.GENERIC_ERROR });

        logError({
          error,
          message: "[Stripe][Stripe Elements][Mutation Error] Error.",
        });
      });
    });
  }, [stripe, offer, waitingOffer]);

  if (view === "") {
    return null;
  }

  return (
    <>
      {view === "succeeded" ? (
        <SucceededStatus
          community={community}
          offer={offerRef.current!}
          waitingOffer={false}
          redirectUrl={savedRedirectUrl}
        />
      ) : (
        <FailedStatus redirectUrl={savedRedirectUrl} />
      )}
    </>
  );
};

const CheckoutComplete = () => {
  if (!stripeConstructor) {
    const errorMsg = `[ERROR][ENV VARS]: You have to configure the env var "REACT_APP_STRIPE_PUBLISHABLE_KEY" to use these features.`;
    logError({ error: new Error(errorMsg), message: errorMsg });
    return <Text as="p">{DEFAULT_ERROR_MESSAGE}</Text>;
  }

  return (
    <>
      <Global styles={styles.checkoutCompleteGlobalStyles} />

      <Layout css={styles.container}>
        <Elements stripe={stripeConstructor}>
          <Page />
        </Elements>
      </Layout>
    </>
  );
};

export default memo(CheckoutComplete);
