import { css, Theme } from "@emotion/react";

import { ReactionsProp, SingleReactionProps } from "./types";

export const reactionListStyle = css`
  padding: 0;
  margin: 0.5em 0 0;
  list-style: none;
  display: flex;
  gap: 0.5em;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  li:last-child {
    position: relative;
  }
`;

export const getPickerCss = (up = false, left = false) =>
  css`
    position: absolute;
    ${up ? "bottom" : "top"}: 22px;
    ${left ? "right" : "left"}: 22px;
    z-index: 1;
  `;

export const reactionButton =
  (pageTheme: ReactionsProp["theme"]) => (theme: Theme) =>
    css`
      transition: opacity 0.6s ${theme.animations.easeOutQuart};
      color: ${pageTheme === "system"
        ? theme.text.colors.primary
        : theme.community.text.onPrimaryBackgroundPrimaryColor};

      @media (hover: hover) {
        &:hover {
          opacity: 0.6;
        }
      }
    `;

export const reactionStyle =
  (selected: boolean, pageTheme: SingleReactionProps["theme"]) =>
  (theme: Theme) => {
    let backgroundColors;

    if (!selected) {
      backgroundColors =
        pageTheme === "system"
          ? css`
              background-color: ${theme.background.colors.tertiary};
              color: ${theme.text.colors.primary};
            `
          : css`
              background-color: ${theme.community.secondaryBackground
                .backgroundColor};
              color: ${theme.community.text.onPrimaryBackgroundPrimaryColor};
            `;
    } else {
      backgroundColors =
        pageTheme === "system"
          ? css`
              background-color: ${theme.buttons.primary.backgroundColor};
              color: ${theme.buttons.primary.textColor};
            `
          : css`
              background-color: ${theme.community.button
                .onPrimaryBackgroundBackgroundColor};
              color: ${theme.community.button.onPrimaryBackgroundTextColor};
            `;
    }

    return css`
      display: flex;
      align-items: center;
      padding: 4px 12px;
      min-height: 32px;
      border-radius: 10px;

      ${backgroundColors}
      span {
        margin-right: 2px;
      }
    `;
  };
