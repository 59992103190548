import React, { FC, memo, useCallback, useEffect, useState } from "react";

import {
  useDeleteCommunityBannerImageMutation,
  useUploadCommunityBannerImageMutation,
} from "apollo/queries";
import BannerImageUpload from "components/CommunityCreator/BannerImageUpload";
import LoadingIcon from "components/LoadingIcon";
import { logError } from "services/logger";

export interface Props {
  communityId: string;
  communityBannerValue: string;
  onSetBanner?: (banner: string) => void;
}

const CreatorAvatarUpdate: FC<Props> = ({
  communityId,
  communityBannerValue,
  onSetBanner,
}) => {
  const [doUploadCommunityBannerImageMutation] =
    useUploadCommunityBannerImageMutation();
  const [doDeleteCommunityBannerImageMutation] =
    useDeleteCommunityBannerImageMutation();

  const [communityBanner, setCommunityBanner] =
    useState<string>(communityBannerValue);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setCommunityBanner(communityBannerValue);
  }, [communityBannerValue]);

  const handleFileAccept = useCallback(
    async (file: File) => {
      try {
        setLoading(true);
        setError("");
        const response = await doUploadCommunityBannerImageMutation({
          variables: {
            asset: file,
            communityId: communityId,
            mime: file.type,
          },
        });
        const banner =
          response.data?.uploadCommunityBannerImage?.bannerImageUrl || "";
        setCommunityBanner(banner);
      } catch (error) {
        const message = "Upload failed";
        setError(message);
        await logError({
          error,
          message: `[handleCreatorAvatarChange] ${message}`,
        });
      } finally {
        setLoading(false);
      }
    },
    [communityId]
  );

  const handleFileReject = useCallback(async (error: string) => {
    setLoading(false);
    setError(error);
  }, []);

  const handleRemove = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      const response = await doDeleteCommunityBannerImageMutation({
        variables: {
          communityId: communityId,
        },
      });
      const banner =
        response.data?.deleteCommunityBannerImage?.bannerImageUrl || "";
      setCommunityBanner(banner);
    } catch (error) {
      const message = "Delete failed";
      setError(message);
      await logError({
        error,
        message: `[handleRemoveCommunityBanner] ${message}`,
      });
    } finally {
      setLoading(false);
    }
  }, [communityId]);

  useEffect(() => {
    if (onSetBanner) {
      onSetBanner(communityBanner);
    }
  }, [communityBanner]);

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <BannerImageUpload
      id="communityBannerImage"
      label="Community Banner"
      labelType="title"
      dragActiveDescription="Drop here ..."
      primaryDescription="Drag and drop your community banner here"
      secondaryDescription={`This banner image appears at the top of your community. \nUse a PNG or JPG file, recommended dimensions are 2880px by 720px.`}
      error={error}
      imageUrl={communityBanner}
      onFileAccept={handleFileAccept}
      onFileReject={handleFileReject}
      onRemove={handleRemove}
      maxSize={5}
    />
  );
};

export default memo(CreatorAvatarUpdate);
