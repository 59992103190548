import { css, Theme } from "@emotion/react";

export const container = css`
  display: flex;
`;

export const descriptionContainer = css`
  margin-left: 12px;
`;

export const description = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[1]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.small[1]};
  }
`;

export const image = css`
  width: 88px;
  height: 88px;
`;
