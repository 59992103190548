import { css, Theme } from "@emotion/react";

export const layout = (theme: Theme) =>
  css`
    display: grid;
    grid-template-columns: ${theme.layout.gridColumns(8)};
    justify-content: center;
    margin: 0 auto;

    ${theme.mediaQueries.mediumOnly} {
      grid-template-columns: ${theme.layout.gridColumns(10)};
    }

    ${theme.mediaQueries.smallOnly} {
      grid-template-columns: ${theme.layout.gridColumns(12)};
    }
  `;
