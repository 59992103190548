import React, { FC, memo, useEffect } from "react";

import { useReactiveVar } from "@apollo/client";

import {
  ModalCheckYourEmail,
  ModalType,
  modalVar,
  resetModalVar,
} from "apollo/reactive/modal";
import { ReactComponent as ErrorIcon } from "assets/icons/error.svg";
import LoadingIcon from "components/LoadingIcon";
import Title from "components/Typography/Title";
import { LogInError } from "utils/errors";

import baseStyles from "../ModalBody/styles.module.scss";
import ReactModalBase from "../ReactModalBase";

import styles from "./styles.module.scss";

export type Props = {
  logInError: LogInError | null;
};

const CheckYourEmailModal: FC<Props> = ({ logInError }) => {
  const { showModal, showView, data } = useReactiveVar(
    modalVar as ModalCheckYourEmail
  );

  const canClose = showView === "error";
  useEffect(() => {
    if (showView === "completed") {
      resetModalVar();
    }
  }, [showView]);

  return (
    <ReactModalBase
      id="check-your-email-modal"
      className={`${baseStyles.modal} ${baseStyles.modalLarge}`}
      overlayClassName={baseStyles.modalOverlay}
      isOpen={showModal === ModalType.CHECK_YOUR_EMAIL_MODAL}
      closeTimeoutMS={800}
      ariaHideApp={false}
      canClose={canClose}
    >
      <div className={styles.modalBody}>
        {showView === "error" && (
          <>
            <ErrorIcon className={styles.icon} />
            <p className={styles.title}>{logInError?.title}</p>
            <p>{logInError?.message}</p>
          </>
        )}
        {showView === "check" && (
          <>
            <Title size="medium" extraCss={styles.title}>
              Check Your Email
            </Title>
            <p className={styles.description}>
              We emailed a link to <strong>{data?.loginEmail ?? ""}</strong>
              <br />
              Click the link to sign in or create an account.
            </p>
            <LoadingIcon overwriteTheme="system" className={styles.loading} />
          </>
        )}
      </div>
    </ReactModalBase>
  );
};

export default memo(CheckYourEmailModal);
