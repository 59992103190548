import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  padding: ${theme.modal.padding.medium};
  width: 100%;
  max-width: 572px;

  ${theme.mediaQueries.mediumOnly} {
    max-width: 510px;
  }

  ${theme.mediaQueries.smallOnly} {
    padding: ${theme.modal.padding.small};
  }
`;

export const icon = (theme: Theme) => css`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${theme.verticalSpaces.large[5]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.small[5]};
  }
`;

export const title = (theme: Theme) => css`
  text-align: center;
  margin-bottom: ${theme.verticalSpaces.large[3]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.small[3]};
  }
`;

export const text = css`
  text-align: center;
`;

export const actions = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  ${theme.mediaQueries.smallOnly} {
    gap: 8px;
  }
`;

export const actionsButton = (theme: Theme) => css`
  transition-property: transform, background-color;
  transition-duration: 0.4s;
  transition-timing-function: ${theme.animations.easeOutQuart};

  padding: 20px;
  text-align: center;
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  svg {
    margin-right: 8px;
  }

  &:active {
    transform: scale(0.97);
  }

  ${theme.mediaQueries.smallOnly} {
    padding: 12px;
  }
`;

export const twitterButton = (theme: Theme) => css`
  background-color: ${theme.colorMap.vendor.twitterBlue};

  @media (hover: hover) {
    &:hover {
      background-color: darken(${theme.colorMap.vendor.twitterBlue}, 10%);
    }
  }
`;

export const facebookButton = (theme: Theme) => css`
  background-color: ${theme.colorMap.vendor.facebookBlue};

  @media (hover: hover) {
    &:hover {
      background-color: darken(${theme.colorMap.vendor.facebookBlue}, 10%);
    }
  }
`;
