import { GraphQLError } from "graphql/error";
import { RPCError, RPCErrorCode } from "magic-sdk";

import { LOGIN_WITH_MAGICLINK_ERRORS, LOGIN_ERRORS } from "config";
import { UserAlreadyExistsErrorCode, NoSuchUserErrorCode } from "services/auth";

export enum AuthErrorType {
  NONE = "None",
  BACKEND_ERROR = "An Error Occurred",
  METAMASK_GENERAL_ERROR = "MetaMask Error",
  METAMASK_PROCESSING_REQUEST = "Already processing eth_requestAccounts. Please wait.",
  METAMASK_MISSING_PLUGIN = "Install MetaMask for your browser",
  METAMASK_NOT_SIGNED_IN = "Log into your MetaMask extension",
  METAMASK_NOT_POLYGON_NETWORK = "Switch your wallet to the polygon network",
  METAMASK_NOT_TESTNET_NETWORK = "Switch your wallet to the testnet network",
  METAMASK_NOT_LOCAL_NETWORK = "Switch your wallet to the local network",
  METAMASK_USER_DECLINED_SIGNATURE = "To complete sign-in, you need to sign a request in the MetaMask plugin to prove your identity. Please try again.",
  GRAPHQL_CREATE_EXISTING_USER_MAGIC = "Email is already in use by a different account.",
  GRAPHQL_CREATE_EXISTING_USER_METAMASK = "Email or wallet address is already in use by a different account.",
  GRAPHQL_LOGIN_NO_SUCH_USER_MAGIC = "This email is not associated with a Highlight account.",
  GRAPHQL_LOGIN_NO_SUCH_USER_METAMASK = "This wallet address is not associated with a Highlight account.",
  REQUIRE_METAMASK = "Please sign in with Metamask to use this account.",
  REQUIRE_MAGIC_LINK = "Please sign in with your email to use this account.",
}

const metaMaskErrorCodes: Record<string, AuthErrorType | undefined> = {
  "4001": AuthErrorType.METAMASK_USER_DECLINED_SIGNATURE,
  "-32002": AuthErrorType.METAMASK_PROCESSING_REQUEST,
};

export const isMetaMaskError = (type: AuthErrorType) => {
  return [
    AuthErrorType.METAMASK_GENERAL_ERROR,
    AuthErrorType.METAMASK_MISSING_PLUGIN,
    AuthErrorType.METAMASK_NOT_SIGNED_IN,
    AuthErrorType.METAMASK_NOT_POLYGON_NETWORK,
    AuthErrorType.METAMASK_NOT_TESTNET_NETWORK,
    AuthErrorType.METAMASK_NOT_LOCAL_NETWORK,
    AuthErrorType.METAMASK_USER_DECLINED_SIGNATURE,
    AuthErrorType.METAMASK_PROCESSING_REQUEST,
  ].includes(type);
};

export type LogInError = {
  type: AuthErrorType;
  message: string;
};

export const EmptyLoginError = {
  type: AuthErrorType.NONE,
  message: "",
};

export const DefaultLoginError: LogInError = {
  type: AuthErrorType.BACKEND_ERROR,
  message: LOGIN_ERRORS.GENERIC_DESCRIPTION,
};

export const DefaultMetaMaskError = {
  type: AuthErrorType.METAMASK_GENERAL_ERROR,
  message: LOGIN_ERRORS.GENERIC_DESCRIPTION,
};

export const hasGraphqlErrors = (graphQLErrors: GraphQLError[] | undefined) => {
  return graphQLErrors;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
const isMetaMaskErrorResponse = (error: any) => {
  return error.code.toString() in metaMaskErrorCodes;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const parseMetaMaskResponse = (error: any): LogInError => {
  if (isMetaMaskErrorResponse(error)) {
    const key = error?.code.toString() ?? "";
    const type = metaMaskErrorCodes[key];
    if (!type) return DefaultLoginError;
    return {
      type: type,
      message: type,
    };
  }

  return error;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
const isLogInError = (e: any): boolean => {
  if (
    Object.values(AuthErrorType).includes(e?.type as unknown as AuthErrorType)
  ) {
    return true;
  }

  return false;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const parseLoginUserError = (e: any, isWeb3: boolean): LogInError => {
  if (isLogInError(e)) {
    return e;
  }
  let error = DefaultLoginError;
  if (hasGraphqlErrors(e.graphQLErrors)) {
    error = parseLoginGraphqlError(e.graphQLErrors, e.message, isWeb3);
  } else if (isWeb3 && isMetaMaskErrorResponse(e)) {
    error = parseMetaMaskResponse(e);
  } else if (!isWeb3) {
    error = parseLogInWithMagicLinkError(e);
  }

  return error;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const parseCreateUserError = (
  e: any,
  isWeb3: boolean
): LogInError | null => {
  if (isLogInError(e)) {
    return e;
  }
  let error = null;
  if (hasGraphqlErrors(e.graphQLErrors)) {
    error = parseCreateUserGraphqlError(e.graphQLErrors, e.message, isWeb3);
  } else if (isWeb3 && isMetaMaskErrorResponse(e)) {
    error = parseMetaMaskResponse(e);
  } else if (!isWeb3) {
    error = parseLogInWithMagicLinkError(e);
  }

  return error;
};

const parseLoginGraphqlError = (
  graphQLErrors: GraphQLError[],
  message: string | undefined,
  isWeb3: boolean
): LogInError => {
  const noSuchUserErrors = graphQLErrors.find(
    ({ extensions }) => extensions.code === NoSuchUserErrorCode
  );
  if (noSuchUserErrors) {
    return {
      type: isWeb3
        ? AuthErrorType.GRAPHQL_LOGIN_NO_SUCH_USER_METAMASK
        : AuthErrorType.GRAPHQL_LOGIN_NO_SUCH_USER_MAGIC,
      message:
        message ?? "Use an email or metamask account which is already on",
    };
  }
  return DefaultLoginError;
};

const parseCreateUserGraphqlError = (
  graphQLErrors: GraphQLError[],
  message: string | undefined,
  isWeb3: boolean
): LogInError => {
  const userExistsErrors = graphQLErrors.find(
    ({ extensions }) => extensions.code === UserAlreadyExistsErrorCode
  );
  if (userExistsErrors) {
    return {
      type: isWeb3
        ? AuthErrorType.GRAPHQL_CREATE_EXISTING_USER_METAMASK
        : AuthErrorType.GRAPHQL_CREATE_EXISTING_USER_MAGIC,
      message: message ?? "Use a different email",
    };
  }

  return DefaultLoginError;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const parseLogInWithMagicLinkError = (error: any): LogInError => {
  if (error instanceof RPCError) {
    switch (error.code) {
      case RPCErrorCode.MagicLinkExpired:
        return {
          type: AuthErrorType.BACKEND_ERROR,
          message: LOGIN_WITH_MAGICLINK_ERRORS.LINK_EXPIRED_DESCRIPTION,
        };

      case RPCErrorCode.UpdateEmailFailed:
        return {
          type: AuthErrorType.BACKEND_ERROR,
          message: LOGIN_WITH_MAGICLINK_ERRORS.UPDATE_EMAIL_FAILED_DESCRIPTION,
        };

      case RPCErrorCode.InvalidParams:
        return {
          type: AuthErrorType.BACKEND_ERROR,
          message:
            error.rawMessage?.replace("Invalid params: ", "") ??
            LOGIN_WITH_MAGICLINK_ERRORS.GENERIC_DESCRIPTION,
        };

      default:
        return DefaultLoginError;
    }
  } else {
    return DefaultLoginError;
  }
};
