import React from "react";

import { css } from "@emotion/react";

import { AttachmentMetadata, MediaMetadata } from "apollo/graphql.generated";
import { MimeTypes } from "config";

/**
 * Styles
 */
const styles = {
  nodeWrapper: css`
    position: relative;
  `,
  video: css`
    max-width: 100%;
  `,
};

/**
 * Helpers
 */
const isMediaMetadata = (
  toBeDefined?: AttachmentMetadata | null
): toBeDefined is MediaMetadata => {
  if (!toBeDefined) return false;
  return (toBeDefined as MediaMetadata).mime !== undefined;
};

/**
 * Main Component
 */
export interface VideoAttachmentProps {
  attachment: AttachmentMetadata;
}

const VideoAttachment = ({ attachment }: VideoAttachmentProps) => {
  if (!isMediaMetadata(attachment) || !attachment.url) {
    return null;
  }

  return (
    <video controls controlsList="nodownload" css={styles.video}>
      <source src={attachment?.url} type={MimeTypes[attachment.mime]} />
      Your browser does not support video.
    </video>
  );
};

export default VideoAttachment;
