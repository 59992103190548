import useSession from "hooks/useSession";

import { APP_SUBDOMAIN, APP_URL, FEATURE_FLAG } from "../config";

import useAppLocation from "./useAppLocation";
import useFeatureFlag from "./useFeatureFlag";

const useAllowCreateCommunity = () => {
  const { user } = useSession();
  const { getCommunityURLBySlug } = useAppLocation();

  const allowOneCommunityPerUser = useFeatureFlag(
    FEATURE_FLAG.CREATE_ONE_COMMUNITY_PER_USER
  );

  const requirePhoneValidation = useFeatureFlag(
    FEATURE_FLAG.REQUIRE_PHONE_VALIDATION
  );

  const disableCreateCommunity = useFeatureFlag(
    FEATURE_FLAG.DISABLE_CREATE_COMMUNITY
  );

  const hasOneCreatedCommunity = user?.createdCommunities.length !== 0;
  const isPhoneVerified = !!user?.personalInformation.phone?.isVerified;
  return {
    allowCreateCommunity:
      !disableCreateCommunity &&
      (!allowOneCommunityPerUser || !hasOneCreatedCommunity) &&
      (!requirePhoneValidation || isPhoneVerified),
    requirePhoneValidation,
    createCommunityAbsoluteUrl: `${getCommunityURLBySlug(APP_SUBDOMAIN)}${
      APP_URL.newCommunity
    }`,
  };
};

export default useAllowCreateCommunity;
export type UseAllowCreateCommunity = ReturnType<
  typeof useAllowCreateCommunity
>;
