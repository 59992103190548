import React, { memo, useCallback, useState } from "react";

import { isApolloError } from "@apollo/client";
import { useParams } from "react-router-dom";

import useTokenDetails from "apollo/hooks/useTokenDetails";
import { useSendTokensMutation } from "apollo/queries/token.graphql.generated";
import RadioButtonGroup from "components/CommunityCreator/RadioButtonGroup";
import { RadioInput } from "components/CommunityCreator/RadioButtonGroup/types";
import ErrorLinkContainer from "components/ErrorLinkContainer";
import Layout from "components/Layout";
import { AppSEO } from "components/Layout/SEO";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE, APP_URL } from "config";
import TokenPreview from "containers/App/EditCommunity/Tokens/Shared/TokenPreview";
import * as sharedStyles from "containers/App/EditCommunity/Tokens/Shared/styles";
import useAppLocation from "hooks/useAppLocation";
import useSession from "hooks/useSession";
import { logError } from "services/logger";
import {
  ApolloServerError,
  DefaultApolloServerError,
  parseApolloClientError,
} from "utils/errors/apolloErrors";

import ClaimPage from "./SendOusideCommunity/ClaimPage";
import GenerateClaimPage from "./SendOusideCommunity/GenerateClaimPage";
import SendToCommunity from "./SendToCommunity";
import * as styles from "./styles";

enum RadioButtons {
  SEND_TO_COMMUNITY,
  SEND_OUTSIDE_COMMUNITY,
}

const SEND_TOKEN_DEFAULT_TEXT = "Send Token";
const SEND_TOKEN_SENDING_TEXT = "Sending...";
const SEND_TOKEN_SENT_TEXT = "Sent";
const SEND_TOKEN_FAILED_TEXT = "Failed to send";

const SendToken = () => {
  const { communitySlug = "", tokenId = -1 } = useParams();
  const { user } = useSession();
  const { createdCommunities } = user ?? {};
  const { getEditCommunityAbsoluteURL } = useAppLocation();
  const [error, setError] = useState<ApolloServerError | null>(null);
  const [selected, setSelected] = useState<RadioButtons | null>(null);
  const [contestId, setContestId] = useState<string>("");
  const [sendToCommunityText, setSendToCommunityText] = useState<string>(
    SEND_TOKEN_DEFAULT_TEXT
  );

  const { data: tokenDetailsData } = useTokenDetails(tokenId);

  const communityId =
    createdCommunities?.find((community) => community.slug === communitySlug)
      ?.id || "";

  const [sendTokensToMembersMutation] = useSendTokensMutation({
    fetchPolicy: "network-only",
    variables: {
      communityId,
      tokenId: tokenDetailsData?.community?.token.tokenId || "",
    },
  });

  const handleSendToMembersClick = useCallback(async () => {
    setSendToCommunityText(SEND_TOKEN_SENDING_TEXT);
    try {
      await sendTokensToMembersMutation();
      setSendToCommunityText(SEND_TOKEN_SENT_TEXT);
    } catch (error) {
      if (error instanceof Error && isApolloError(error)) {
        setError(parseApolloClientError(error));
      } else {
        setError(DefaultApolloServerError);
      }
      logError(error);
      setSendToCommunityText(SEND_TOKEN_FAILED_TEXT);
    }
  }, []);

  const radioInputs: RadioInput[] = [
    {
      value: RadioButtons.SEND_TO_COMMUNITY,
      label: "All current members of your community",
    },
    {
      value: RadioButtons.SEND_OUTSIDE_COMMUNITY,
      label: "People not in your community",
    },
  ];

  const subtitle =
    `${tokenDetailsData?.community?.token.name} Token`.toUpperCase();
  const redirectUrl = getEditCommunityAbsoluteURL(
    communitySlug ?? "app",
    APP_URL.editCommunity.tokens
  );

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.EDITCOMMUNITY_TOKENS_SEND} />

      <Layout
        css={sharedStyles.designLayout}
        backgroundShow={true}
        backgroundColor="primary"
        navLayout="action"
        title="SEND TOKEN"
        subtitle={subtitle}
        page={0}
        cancelRedirect={redirectUrl}
      >
        <div>
          <Title size="large" additionalCss={styles.title}>
            Send Token
          </Title>
          {!contestId && (
            <div>
              <RadioButtonGroup
                inputs={radioInputs}
                label="Send To"
                selected={selected}
                setSelected={setSelected}
              />
              {error ? (
                <ErrorLinkContainer onClose={() => setError(null)}>
                  <div css={styles.errorContainer}>
                    <Text bold>{error.type}</Text>
                    <Text>{error.message}</Text>
                  </div>
                </ErrorLinkContainer>
              ) : null}
              {selected == RadioButtons.SEND_TO_COMMUNITY && (
                <SendToCommunity
                  handleClick={handleSendToMembersClick}
                  text={sendToCommunityText}
                />
              )}
              {selected == RadioButtons.SEND_OUTSIDE_COMMUNITY && (
                <GenerateClaimPage
                  tokenId={tokenDetailsData?.community?.token.tokenId || ""}
                  communityId={tokenDetailsData?.community?.id || ""}
                  onContestCreated={(contestId) => setContestId(contestId)}
                />
              )}
            </div>
          )}
          {contestId && (
            <ClaimPage communitySlug={communitySlug} contestId={contestId} />
          )}
        </div>
        <div css={styles.rightContainer}>
          <TokenPreview
            showTitle={false}
            imageUri={tokenDetailsData?.community?.token.uri || ""}
          />
        </div>
      </Layout>
    </>
  );
};

export default memo(SendToken);
