import React, { memo, useEffect } from "react";

import { useParams, useSearchParams } from "react-router-dom";

import { Contest, Offer } from "apollo/graphql.generated";
import { useMintedTokenDetailsQuery } from "apollo/queries";

import ManageToken from "../ManageToken";

const ManageMintedToken = () => {
  const { communitySlug = "", tokenId = "1" } = useParams();
  const [searchParams] = useSearchParams();
  const { data, refetch, loading } = useMintedTokenDetailsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      tokenId: +tokenId,
      slug: communitySlug as string,
      useChain: false,
    },
  });

  const showCompleteModal = searchParams.has("complete");

  const offers: Partial<Offer>[] =
    (data?.createdCommunityBySlug?.offers as unknown as Partial<Offer>[]) || [];
  const offer = getOfferByTokenId(offers as Offer[], tokenId)[0];

  const claimPages: Partial<Contest>[] =
    (data?.createdCommunityBySlug
      ?.claimPages as unknown as Partial<Contest>[]) || [];

  const handleEvent = () => refetch();
  useEffect(() => {
    document.addEventListener("manageMintedToken:claimPageAdded", handleEvent);
    document.addEventListener(
      "manageMintedToken:claimPageDeleted",
      handleEvent
    );
    document.addEventListener("manageMintedToken:createOffer", handleEvent);
    document.addEventListener("manageMintedToken:updateOffer", handleEvent);
    document.addEventListener(
      "manageMintedToken:sendTokenToMembers",
      handleEvent
    );
    return () => {
      document.removeEventListener(
        "manageMintedToken:claimPageAdded",
        handleEvent
      );
      document.removeEventListener(
        "manageMintedToken:claimPageDeleted",
        handleEvent
      );
      document.removeEventListener(
        "manageMintedToken:createOffer",
        handleEvent
      );
      document.removeEventListener(
        "manageMintedToken:updateOffer",
        handleEvent
      );
      document.removeEventListener(
        "manageMintedToken:sendTokenToMembers",
        handleEvent
      );
    };
  }, []);

  const token = data?.createdCommunityBySlug?.token;
  const communityId = data?.createdCommunityBySlug?.id;

  return (
    <ManageToken
      isPending={false}
      showCompleteModal={showCompleteModal}
      loading={loading}
      token={token}
      offer={offer}
      communityId={communityId}
      contests={claimPages}
    />
  );
};

export default memo(ManageMintedToken);

export const getOfferByTokenId = (offers: Offer[], tokenId: string): Offer[] =>
  offers.filter(
    (offer: Offer) =>
      offer.tokenIds.filter((id) => id.toString() === tokenId).length > 0
  );
