import { css, Theme } from "@emotion/react";

export const fields = (theme: Theme) => css`
  list-style: none;
  margin: 40px 0 0 0;
  padding: 0;

  li:not(:last-child) {
    margin-bottom: 40px;
  }

  ${theme.mediaQueries.smallOnly} {
    margin-top: 16px;

    li:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;
