import React, { FC, useEffect } from "react";

import { resolveBenefitToken } from "apollo/utils/community";
import { CommunitySEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import CommunityTitle from "components/_Community/Title";
import { COMMUNITY_PAGE_TITLE } from "config";
import BenefitCard from "containers/CommunityV2/Benefits/BenefitCard";
import {
  ShopDetailsDocument,
  useShopDetailsQuery,
} from "containers/CommunityV3/ShopDetails/query.graphql.generated";
import { ShopifyBuyButtons } from "containers/Shopify";
import useSession from "hooks/useSession";
import { apolloClient } from "services/apolloClient";

import * as styles from "./styles";
import { Props } from "./types";

const ShopDetails: FC<Props> = ({ communitySlug }) => {
  const { user } = useSession();
  const { data, loading } = useShopDetailsQuery({
    variables: { slug: communitySlug },
  });

  useEffect(() => {
    if (user) {
      apolloClient.refetchQueries({
        include: [ShopDetailsDocument],
      });
    }
  }, [user]);

  if (loading) {
    return (
      <div css={styles.loadingIcon}>
        <LoadingIcon />
      </div>
    );
  }

  const benefit = data?.community?.shop.benefit;
  const benefitToken = resolveBenefitToken(benefit);

  return (
    <>
      <CommunitySEO title={COMMUNITY_PAGE_TITLE.SHOP} />

      <BenefitCard
        unlocked={!!benefit?.hasAccess}
        unlockedByToken={benefitToken}
        communityContractAddress={
          data?.community?.polygonContract?.contractAddress
        }
        header={
          <>
            <CommunityTitle
              color="onPrimaryBackgroundPrimaryColor"
              size="medium"
            >
              Shop
            </CommunityTitle>
          </>
        }
      >
        <div>
          {data?.community?.shop?.buyButtons?.length ? (
            <div>
              <ShopifyBuyButtons
                buyButtons={data?.community?.shop?.buyButtons}
                v2Layout
              />
            </div>
          ) : null}
        </div>
      </BenefitCard>
    </>
  );
};

export default ShopDetails;
