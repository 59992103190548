import { css, Theme } from "@emotion/react";

export const inputContainer = css`
  padding-right: 8px;
  padding-left: 8px;
`;

export const input = css`
  padding-left: 16px;
`;

/* Picker Styles */

export const picker = (bgColor: string) => (theme: Theme) =>
  css`
    position: relative;
    width: 48px;
    height: 48px;
    border: 0.5px solid rgba(2, 2, 2, 0.1);
    border-radius: calc(${theme.textInput.border.radius} / 2);
    background-color: ${bgColor};
    cursor: pointer;
  `;

export const pickerDropDown = (theme: Theme) => css`
  position: absolute;
  z-index: 10;
  top: 51px;
  left: -100px;
  box-shadow: ${theme.shadows["lg"]} !important;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  outline: 1px solid rgba(0, 0, 0, 0.02);

  i {
    position: absolute;
    top: 1px;
    left: calc(50% - 18px);
    z-index: 2;

    &:before,
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 10px;
      position: absolute;
      top: -9px;
      left: 0;
    }

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.1);
    }

    &:after {
      border-color: transparent transparent rgb(255, 255, 255);
    }
  }

  ${theme.mediaQueries.smallOnly} {
    left: -8px;

    i {
      left: 18px;
    }
  }
`;

export const chromePicker = css`
  box-shadow: none !important;

  // Picker
  > div:nth-of-type(1) {
    height: 200px;
    border: 0.5px solid rgba(2, 2, 2, 0.1);
    border-radius: 0;

    // Selector
    > div > div > div:nth-of-type(2) > div {
      width: 24px !important;
      height: 24px !important;
      border-radius: 50% !important;
      box-shadow: rgb(255 255 255) 0 0 0 2px inset !important;
      transform: translate(-12px, -12px) !important;
    }
  }

  // Saturation
  > div:nth-child(2) {
    margin-top: 16px;
    padding: 0 !important;

    // Slider
    > div:first-of-type {
      // Color
      > div:nth-child(1) {
        position: absolute;
        bottom: 24px;
        right: 24px;
        width: 32px !important;
        height: 32px !important;
        border-radius: 4px !important;
        overflow: hidden;

        > div {
          width: 100% !important;
          height: 100% !important;
          border-radius: 0 !important;

          div {
            width: 32px;
            height: 32px;
            border-radius: 0 !important;
          }
        }
      }

      // Slider
      > div:nth-child(2) {
        border-radius: 8px !important;

        > div:nth-child(1) {
          border-radius: 8px !important;
          height: 6px !important;

          > div > div {
            border-radius: 8px !important;
          }

          > div > div > div > div {
            width: 16px !important;
            height: 16px !important;
            border: 2px solid #ffffff;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
            border-radius: 50% !important;
            transform: translate(-8px, -5px) !important;
          }
        }
      }
    }

    // Controls
    > div:last-of-type {
      input {
        padding: 12px 16px !important;
        border-radius: 8px !important;
        font-size: 16px !important;
        line-height: 24px !important;
        text-align: left !important;
        height: auto !important;
      }

      label {
        display: none !important;
      }

      > div:nth-child(2) {
        display: none;
      }
    }
  }
`;

export const chromePickerToggles = css`
  display: none;
`;

export const sliderPicker = css`
  margin-bottom: 20px;
  padding-right: 5px;
`;

export const twitterPicker = css`
  padding: 0;
  box-shadow: none !important;
  width: 252px !important;

  > div {
    &:nth-of-type(3) {
      padding: 0 !important;
    }

    > span > div {
      outline: 1px solid #e8e8e8 !important;
    }
  }
`;
