import React, { memo } from "react";

import { EXTERNAL_URL } from "config";

import styles from "./style.module.scss";

const AgreeToPrivacyAndToS = () => (
  <div className={styles.container}>
    <p className={styles.text}>
      By creating an account, you agree to the{" "}
      <a
        href={EXTERNAL_URL.TERMS_AND_CONDITIONS}
        target={"_blank"}
        rel="noreferrer"
      >
        Terms of Service
      </a>{" "}
      and{" "}
      <a href={EXTERNAL_URL.PRIVACY_POLICY} target={"_blank"} rel="noreferrer">
        Privacy Policy
      </a>
      .
    </p>
  </div>
);
export default memo(AgreeToPrivacyAndToS);
