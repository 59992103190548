import React, {
  FC,
  memo,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useRef,
} from "react";

import { Interpolation, Theme } from "@emotion/react";
import { QRCodeCanvas } from "qrcode.react";

type QRCodeProps = {
  link: string;
  extraCss?: Interpolation<Theme>;
};

const QRCode: FC<PropsWithChildren<QRCodeProps>> = ({
  link,
  extraCss,
  children,
}) => {
  const qrCodeDivRef = useRef<HTMLDivElement>(null);

  const handleDownloadQRCodeClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (!qrCodeDivRef || !qrCodeDivRef.current) return;
      const canvas = qrCodeDivRef.current
        .firstElementChild as HTMLCanvasElement;
      event.currentTarget.href = canvas.toDataURL("image/png");
      event.currentTarget.download = "QR.png";
    },
    [qrCodeDivRef?.current]
  );

  return (
    <>
      <div hidden ref={qrCodeDivRef}>
        <QRCodeCanvas size={96} value={link} />
      </div>
      <a css={extraCss} onClick={handleDownloadQRCodeClick}>
        {children}
      </a>
    </>
  );
};

export default memo(QRCode);
