import { css } from "@emotion/react";

import { topMargin } from "styles/global/layout";

export const countdownSection = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 100%;

  ${topMargin("9/32px")}
`;

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 20px;
  p {
    color: white;
  }
`;

export const countdownTimeValue = css`
  color: white;
`;

export const countdownUnits = css`
  color: white;
`;
