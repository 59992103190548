import { css } from "@emotion/react";

import { bottomMargin, topMargin } from "styles/global/layout";

export const description = css`
  ${bottomMargin("5/12px")};
`;

export const container = css`
  ${topMargin("14/80px")}
`;

export const title = css`
  ${bottomMargin("9/32px")}
`;

export const section = css`
  ${topMargin("10/40px")};
`;
