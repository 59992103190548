import React, { memo, PropsWithChildren } from "react";

import ButtonModalClose from "components/ButtonModalClose";

import styles from "./styles.module.scss";
import { Props } from "./types";

// TODO(gates-app): use emotion
// const styles = {
//   modalContent: "",
// };

const ModalBody: React.FC<PropsWithChildren<Props>> = ({
  children,
  canClose = true,
  handleClose,
}) => {
  return (
    <div className={styles.modalContent}>
      {canClose && <ButtonModalClose onClick={handleClose} />}
      {children}
    </div>
  );
};

export default memo(ModalBody);
