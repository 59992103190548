import { Currency } from "../currency";

/**
 * Formats based on currency and amount. It uses the currency
 * to dynamically decide the amount fraction digits (e.g. 2 for USD and 0 for JPY).
 * It uses the default locale of the user agent.
 *
 * Example of formatting:
 * - 2499 amount + USD currency = $24.99
 * - 2499 amount + JPY currency = ¥2,499
 * @param currency ISO 4217 currency code
 * @param amount value in the currency's smallest unit
 * @param postfixCurrency if the currency should be shown after the value
 */
export const formatCurrency = (
  amount: string | number,
  currency: string,
  postfixCurrency?: boolean
) => {
  if (currency === Currency.WETH) {
    return `${amount} ${currencyCode(Currency.WETH)}`;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });

  const fiatAmount = typeof amount === "number" ? amount : parseInt(amount);

  const fractionAmount =
    fiatAmount / 10 ** formatter.resolvedOptions().minimumFractionDigits;

  return `${formatter.format(fractionAmount)}${
    postfixCurrency ? ` ${currency}` : ""
  }`;
};

const currenciesMapping: Record<string, string> = {
  WETH: "ETH",
};
export const currencyCode = (currency?: string | null) => {
  if (!currency) {
    return "";
  }
  return currenciesMapping[currency] ?? currency;
};

// rounds up to 4 decimals
export const roundupAmount = (value: string | number) => {
  const min = 1e4;
  return Math.round(+value * min) / min;
};
