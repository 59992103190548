import { memo } from "react";

import { Link } from "react-router-dom";

import { ReactComponent as BackArrowIcon } from "assets/icons/back-arrow.svg";
import Text from "components/Typography/Text";
import { COMMUNITY_URL } from "config";

import * as styles from "./styles";

const BackButton = () => (
  <Link css={styles.container} to={COMMUNITY_URL.home}>
    <BackArrowIcon />{" "}
    <Text as="span" size="medium" bold={true}>
      Back
    </Text>
  </Link>
);

export default memo(BackButton);
