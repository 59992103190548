import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
export type TokenDataFragment = {
  __typename: "Token";
  tokenId: string;
  name: string | null;
  description: string | null;
  uri: string | null;
  animationUri: string | null;
  tokenType: Types.TokenType;
  supply: number | null;
  creatorBalance: number | null;
  benefits: Array<{
    __typename: "TokenBenefit";
    type: Types.BenefitType;
    customName: string | null;
    releasePartyId: string | null;
  }> | null;
};

export type OfferDataFragment = {
  __typename: "Offer";
  id: string;
  name: string;
  tokenId: number | null;
  price: string;
  currency: string;
  onAboutPage: boolean | null;
  crypto: {
    __typename: "CryptoCurrency";
    coin: string;
    value: string;
    inUSD: number | null;
  } | null;
  tokens: Array<{
    __typename: "Token";
    tokenId: string;
    name: string | null;
    description: string | null;
    uri: string | null;
    animationUri: string | null;
    tokenType: Types.TokenType;
    supply: number | null;
    creatorBalance: number | null;
    benefits: Array<{
      __typename: "TokenBenefit";
      type: Types.BenefitType;
      customName: string | null;
      releasePartyId: string | null;
    }> | null;
  }> | null;
};

export const TokenDataFragmentDoc = gql`
  fragment tokenData on Token {
    tokenId
    name
    description
    uri(useChain: false)
    animationUri
    tokenType
    supply
    creatorBalance
    benefits {
      type
      customName
      releasePartyId
    }
  }
`;
export const OfferDataFragmentDoc = gql`
  fragment offerData on Offer {
    id
    name
    tokenId
    price
    currency
    crypto {
      coin
      value
      inUSD
    }
    onAboutPage
    tokens {
      ...tokenData
    }
  }
  ${TokenDataFragmentDoc}
`;
