import React, { memo } from "react";

import { css } from "@emotion/react";

import TokenAnimation from "components/TokenAnimation";

const videoContainerStyle = css`
  display: flex;
  align-items: center;
  aspect-ratio: 1;
`;

export const videoStyle = css`
  width: 100%;
`;

type VideoPreviewProps = {
  posterUri?: string;
  sourceUri: string;
};

const VideoPreview = ({ posterUri, sourceUri }: VideoPreviewProps) => (
  <div css={videoContainerStyle}>
    <TokenAnimation
      sourceUri={sourceUri}
      posterUri={posterUri}
      extraCss={videoStyle}
      autoPlay
    />
  </div>
);

export default memo(VideoPreview);
