import { makeVar } from "@apollo/client";

export type UserProfileDropdown = {
  isOpen: boolean;
};

const initialState: UserProfileDropdown = {
  isOpen: false,
};

export const userProfileDropdownVar =
  makeVar<UserProfileDropdown>(initialState);

export const resetUserProfileDropdownVar = () => {
  userProfileDropdownVar(initialState);
};

export const setUserProfileDropdownVar = (
  data: Partial<UserProfileDropdown>
) => {
  userProfileDropdownVar({ ...initialState, ...data });
};

export const updateUserProfileDropdownVar = (
  data: Partial<UserProfileDropdown>
) => {
  userProfileDropdownVar({ ...userProfileDropdownVar(), ...data });
};
