import { css } from "@emotion/react";

import {
  fullGrid,
  section,
  gridColumn,
  bottomMargin,
} from "styles/global/layout";

export const gridSection = css`
  ${section};
  ${fullGrid};
  ${bottomMargin("13/64px")};
`;

export const centeredGridText = css`
  text-align: center;
  ${gridColumn([4, 10], [2, 12])};
`;

export const membershipColumn = css`
  ${gridColumn([1, 7])};
`;

export const collectibleColumn = css`
  ${gridColumn([7, 13])};
`;

export const supportLink = css`
  color: inherit;
`;
