import { css, Theme } from "@emotion/react";

/** Main Component */

export const container = (theme: Theme) => css`
  padding: 24px 20px 20px;
  max-width: 576px;
  width: 100%;

  ${theme.mediaQueries.smallOnly} {
    padding: 12px 10px 10px;
  }
`;

export const containerTitle = (theme: Theme) => css`
  padding-left: 20px;
  margin-bottom: 8px;

  ${theme.mediaQueries.smallOnly} {
    padding-left: 10px;
  }
`;

export const list = css``;

/** Card Component */

export const card = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  position: relative;

  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.background.colors.secondary};
  }

  @media (hover: hover) {
    &:hover {
      span {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
`;

export const cardMedia = css`
  img {
    height: 88px;
    width: 88px;
    display: block;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const cardContent = css`
  display: block;
  margin-left: 20px;
  p {
    small {
      text-transform: uppercase;
      display: block;
    }
  }
`;

export const cardIcon = (theme: Theme) => css`
  transition: all 0.6s ${theme.animations.easeOutQuart};

  position: absolute;
  right: 0;
  top: calc(50% - 16px / 2);
  opacity: 0;
  transform: translateX(-15px);
`;
