import { css, Theme } from "@emotion/react";

import { bottomMargin, topMargin } from "styles/global/layout";

export const statusContainer = (theme: Theme) => css`
  ${topMargin("5/12px")};
  ${bottomMargin("10/40px")};
  padding: 0 ${theme.verticalSpaces.large[6]};
`;

export const addNewClaimPage = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: ${theme.verticalSpaces.large[4]} 0;

  @media (hover: hover) {
    cursor: pointer;
  }
`;

export const claimPageIconContainer = (theme: Theme) => css`
  padding: ${theme.verticalSpaces.large[4]} 0;
  margin-right: 12px;
`;

export const plusIcon = (readonly: boolean) => (theme: Theme) =>
  css`
    flex: 1;
    display: flex;
    width: 16px;
    height: 16px;
    fill: ${readonly
      ? theme.colorMap.accent.secondary
      : theme.colorMap.accent.default};
  `;

export const claimPageText = (readonly: boolean) => (theme: Theme) =>
  css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${readonly
      ? theme.colorMap.accent.secondary
      : theme.colorMap.accent.default};
  `;
