import { css, Theme } from "@emotion/react";

export const container = (theme: Theme) => css`
  padding: 24px 24px 14px 24px;
  padding-bottom: 14px;
  min-height: 224px;

  ${theme.mediaQueries.mediumDown} {
    padding: 20px 20px 12px 20px;
    min-height: 184px;
  }
`;

export const containerLoading = css`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const title = (theme: Theme) => css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 14px;
  margin-bottom: 24px;
  margin-top: -10px;

  ${theme.mediaQueries.mediumDown} {
    font-size: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
`;

// TODO(james): find correct values for spacing between divs at different screen sizes
export const token = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 14px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const tokenImage = (theme: Theme) => css`
  width: 120px;
  margin-right: 16px;

  img {
    width: 100%;
    height: auto;
  }

  ${theme.mediaQueries.mediumDown} {
    width: 96px;
  }
`;

export const tokenDetails = css`
  h3 {
    margin: 4px 0;
  }
  > p {
    text-transform: uppercase;
    font-weight: 600;
  }
`;

export const totals = (theme: Theme) => css`
  margin-top: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${theme.mediaQueries.mediumDown} {
    margin-top: 20px;
  }
`;

export const totalsDetails = css`
  overflow: hidden;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;

      margin: 0;
      padding: 16px 0;

      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      span {
        &:nth-of-type(1)  {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-grow: 1;
          padding-right: 16px;
        }
        &:nth-of-type(2)  {
          width: 100px;
          text-align: right;
        }
      }
    }
  }
`;

export const totalsPreview = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding-top: 14px;

  button {
    display: flex;
    align-items: center;
    align-content: center;

    span {
    }

    div {
      margin-left: 8px;

      svg {
      }
    }
  }

  > p {
    flex-grow: 1;
    text-align: right;

    small {
      opacity: 0.6;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }

  ${theme.mediaQueries.mediumDown} {
    padding-top: 10px;
  }
`;
