import { useRef } from "react";

import { Navigate } from "react-router-dom";

import CardContainer from "components/CardContainer";
import FullscreenLoader from "components/FullscreenLoader";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { COMMUNITY_URL } from "config";
import useAppLocation from "hooks/useAppLocation";
import { stateBase64toObject } from "utils/discord";

import * as styles from "./styles";

const getParams = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    code: params.get("code"),
    state: params.get("state"),
    error: params.get("error"),
    errorDescription: params.get("error_description"),
  };
};

const DiscordLinkCallback = () => {
  const sentRef = useRef<boolean>(false);
  const openerRef = useRef<Window>(window.opener);
  const { isAppSlug, isAppUrl } = useAppLocation();

  if (!openerRef.current) {
    window.close();
  }

  const urlParams = getParams();

  if (!urlParams.state) {
    return <Navigate to={COMMUNITY_URL.internalError} />;
  }

  const parsedState = stateBase64toObject(urlParams.state);
  const communityUrlRedirect =
    parsedState.postAuthRedirectUri +
    window.location.pathname +
    window.location.search;

  // so we don't have to whitelist all community slug urls for discord,
  // redirect to app when not on an app url
  if (isAppSlug && !isAppUrl(parsedState.postAuthRedirectUri)) {
    window.location.assign(communityUrlRedirect);
    return null;
  }

  if (!sentRef.current) {
    sentRef.current = true;
    openerRef.current.postMessage(urlParams, location.origin);
  }

  return !sentRef.current ? (
    <FullscreenLoader />
  ) : (
    <CardContainer shadow="sm" additionalCss={styles.container}>
      <Title>You can close this!</Title>
      <Text>
        If this tab does not close automatically in a few seconds, please close
        it manually.
      </Text>
    </CardContainer>
  );
};

export default DiscordLinkCallback;
