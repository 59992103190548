import React, { useCallback, useEffect, useState } from "react";

import { css } from "@emotion/react";
import { useNavigate, useParams } from "react-router-dom";

import { TokenType } from "apollo/graphql.generated";
import useCreatedCommunity from "apollo/hooks/useCreatedCommunity";
import {
  MintNewTokenMutation,
  usePrepareForMintMutation,
} from "apollo/queries";
import { ModalType, updateModalVar } from "apollo/reactive";
import Layout from "components/Layout";
import { AppSEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import { APP_SUBDOMAIN, APP_URL, SYSTEM_PAGE_TITLE } from "config";
import useAppLocation from "hooks/useAppLocation";

import MintingInProgress from "../Mint/MintingInProgress";
import * as styles from "../styles";

import DesignToken from "./DesignToken";
import ReviewToken from "./ReviewToken";
import SelectTokenType from "./SelectTokenType";
import { DesignTokenType, MintForm } from "./types";
import { PAGE_TITLE_MAP } from "./utils";

const defaultForm: MintForm = {
  tokenName: "",
  tokenDescription: "",
  tokenType: null,
  benefits: [],
  customBenefits: [],
  tokenFileUri: "",
  tokenFile: null,
  animationFileUri: "",
  animationFile: null,
  tokenCount: 100,
  nonTransferable: false,
};

export const layoutStyle = css`
  max-width: 100%;
  width: 100%;
`;

const NewMintToken = () => {
  const { getEditCommunityAbsoluteURL } = useAppLocation();
  const navigate = useNavigate();
  const { communitySlug } = useParams();
  const { data, missingCommunityInformation, isDeployingContract, loading } =
    useCreatedCommunity(communitySlug as string, {
      networkOnly: true,
    });
  const community = data?.createdCommunityBySlug;
  const [doPrepareForMint] = usePrepareForMintMutation();

  const [form, setForm] = useState<MintForm>(defaultForm);
  const [page, setPage] = useState<number>(0);

  const redirectUrl = getEditCommunityAbsoluteURL(
    communitySlug ?? "app",
    APP_URL.editCommunity.tokens
  );

  const onSelect = (tokenType: DesignTokenType) => {
    setForm({ ...form, tokenType: tokenType });
    setPage(1);
  };

  const onContinue = () => {
    setPage(page + 1);
  };

  const onTokenMintSuccess = useCallback(
    (res: MintNewTokenMutation) => {
      if (communitySlug) {
        navigate(
          APP_URL.editCommunity.managePendingToken
            .replace(":communitySlug", communitySlug)
            .replace(":taskId", res.mintNewToken.taskId)
        );
      }
    },
    [communitySlug]
  );

  const onDesignTokenLoad = useCallback(async () => {
    await doPrepareForMint({
      variables: {
        communityId: community?.id ?? "",
      },
    });
  }, [community]);

  const handleExitDesignTokenEvent = () => navigate(redirectUrl);

  useEffect(() => {
    document.addEventListener(
      "mintToken:exitDesignToken",
      handleExitDesignTokenEvent
    );
    return () => {
      document.removeEventListener(
        "mintToken:exitDesignToken",
        handleExitDesignTokenEvent
      );
    };
  }, []);

  const pages = [
    () => (
      <SelectTokenType
        clearForm={() => setForm(defaultForm)}
        onClick={onSelect}
      />
    ),
    () => (
      <DesignToken
        onClick={onContinue}
        form={form}
        setForm={setForm}
        onLoad={onDesignTokenLoad}
      />
    ),
    () => (
      <ReviewToken
        communityId={data?.createdCommunityBySlug?.id ?? ""}
        form={form}
        onTokenMintSuccess={onTokenMintSuccess}
      />
    ),
    () => (
      <MintingInProgress
        tokenName={form.tokenName}
        tokenType={form.tokenType || TokenType.Membership}
        imageUri={form.tokenFileUri}
        clearForm={() => setForm(defaultForm)}
      />
    ),
  ];

  if (loading) {
    return (
      <div css={styles.loadingIcon}>
        <LoadingIcon />
      </div>
    );
  }

  if (missingCommunityInformation || isDeployingContract) {
    navigate(
      getEditCommunityAbsoluteURL(
        communitySlug ?? APP_SUBDOMAIN,
        APP_URL.editCommunity.tokens
      )
    );
  }
  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.EDITCOMMUNITY_TOKENS_MINT} />

      {/*need root level layout for action-navbar*/}
      <Layout
        css={layoutStyle}
        backgroundShow={true}
        backgroundColor="primary"
        navLayout="action"
        showHeader={page !== 3}
        title={`Mint a ${
          form.tokenType ? PAGE_TITLE_MAP[form.tokenType] + " " : ""
        }Token`}
        subtitle=""
        page={page}
        prevCallback={() => {
          if (page > 0) setPage(page - 1);
        }}
        cancelRedirect={page !== 2 ? redirectUrl : undefined}
        cancelCallback={
          page === 2
            ? () => {
                updateModalVar({
                  showModal: ModalType.CONFIRM_EXIT_TOKEN_DESIGN_PAGE,
                });
              }
            : undefined
        }
      >
        {pages[page]()}
      </Layout>
    </>
  );
};

export default NewMintToken;
