import * as Types from "../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type ShopDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
}>;

export type ShopDetailsQuery = {
  community: {
    __typename: "Community";
    id: string;
    polygonContract: {
      __typename: "Contract";
      contractAddress: string | null;
    } | null;
    shop: {
      __typename: "ShopifyData";
      buyButtons: Array<{
        __typename: "BuyButton";
        id: string;
        script: string;
      }>;
      benefit: {
        __typename: "Benefit";
        hasBenefit: boolean;
        hasAccess: boolean;
        unlockedBy: Array<{
          __typename: "UserToken";
          tokenId: number;
          name: string;
          imageUrl: string;
          balance: number | null;
          tokenType: Types.TokenType;
        }> | null;
        unlockableBy: Array<{
          __typename: "Token";
          tokenId: string;
          name: string | null;
          uri: string | null;
          supply: number | null;
          communityAddress: string;
          tokenType: Types.TokenType;
        }> | null;
      };
    };
  } | null;
};

export const ShopDetailsDocument = gql`
  query ShopDetails($slug: String!) {
    community(slug: $slug) {
      id
      polygonContract {
        contractAddress
      }
      shop {
        buyButtons {
          id
          script
        }
        benefit {
          hasBenefit
          hasAccess
          unlockedBy {
            tokenId
            name
            imageUrl
            balance
            tokenType
          }
          unlockableBy {
            tokenId
            name
            uri(useChain: false)
            supply
            communityAddress
            tokenType
          }
        }
      }
    }
  }
`;

/**
 * __useShopDetailsQuery__
 *
 * To run a query within a React component, call `useShopDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useShopDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShopDetailsQuery,
    ShopDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShopDetailsQuery, ShopDetailsQueryVariables>(
    ShopDetailsDocument,
    options
  );
}
export function useShopDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopDetailsQuery,
    ShopDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShopDetailsQuery, ShopDetailsQueryVariables>(
    ShopDetailsDocument,
    options
  );
}
export type ShopDetailsQueryHookResult = ReturnType<typeof useShopDetailsQuery>;
export type ShopDetailsLazyQueryHookResult = ReturnType<
  typeof useShopDetailsLazyQuery
>;
export type ShopDetailsQueryResult = Apollo.QueryResult<
  ShopDetailsQuery,
  ShopDetailsQueryVariables
>;
