import { useReactiveVar, makeVar } from "@apollo/client";
import { providers } from "ethers";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import { TokenType } from "apollo/graphql.generated";
import {
  MeQuery,
  CommunityDocument,
  StartCreateUserWithSignedMessageDocument,
  StartLoginWithSignedMessageDocument,
  useCompleteCreateUserWithSignedMessageMutation,
  useCompleteLoginWithSignedMessageMutation,
  useLogInWithMagicLinkMutation,
  useCreateUserWithMagicLinkMutation,
  useMeQuery,
  useAuthWithMagicLinkMutation,
  useRefreshAccessTokenMutation,
} from "apollo/queries";
import { ModalType, updateModalVar } from "apollo/reactive";
import { resetBannerVar } from "apollo/reactive/banner";
import {
  ANALYTICS_EVENT,
  IN_DEVELOPMENT,
  STORAGE_KEYS,
  JWT_EXPIRATION_DAYS,
  COMMUNITY_URL,
} from "config";
import { apolloClient } from "services/apolloClient";
import { logError } from "services/logger";
import magic from "services/magicClient";
import {
  setSardineLoggedInUserContext,
  setSardineAnonymousUserContext,
} from "services/sardine";
import {
  trackEvent as segmentTrackEvent,
  identifyEvent as segmentIdentifyEvent,
  reset as segmentReset,
  UserIdentify,
} from "services/segment";
import {
  removeDeprecatedDidToken,
  getDeprecatedDidToken,
  getDomain,
} from "utils/cookie";
import { AuthErrorType, LogInError } from "utils/errors/authErrors";
import {
  existsMetaMaskPlugin,
  isSignedIntoMetaMask,
  onCorrectNetwork,
  getMetamaskAddress,
  NetworkType,
} from "utils/metamask";
import { getMagicCredentialUrlParam } from "utils/routes";

const jwtVar = makeVar<string | undefined>(
  Cookies.get(STORAGE_KEYS.jwtAccessToken)
);

type MagicState = {
  magicIsLoggedIn: boolean;
  magicDidToken?: string;
};

const MAGIC_LOGGED_OUT_STATE = {
  magicIsLoggedIn: false,
  magicDidToken: undefined,
};

const magicStateVar = makeVar<MagicState>(MAGIC_LOGGED_OUT_STATE);
const magicLoadingVar = makeVar<boolean>(false);

// there are 3 possible states (INIT, USER, ANONYMOUS) and 4 possible
// transitions:
// INIT -> USER
// INIT -> ANONYMOUS
// USER <-> ANONYMOUS
const userStateVar = makeVar<"INIT" | "USER" | "ANONYMOUS">("INIT");

// handles the LOGGED_IN -> LOGGED_OUT transition
const transitionToAnonymous = async (): Promise<boolean> => {
  // to only run side effects once
  // remove jwt, can be ANON but have old, invalid jwt in cookie storage, clear it out
  if (userStateVar() === "ANONYMOUS") {
    Cookies.remove(STORAGE_KEYS.jwtAccessToken, {
      domain: getDomain(),
    });
    jwtVar(undefined);
    return false;
  }

  IN_DEVELOPMENT && console.log("resetSession");
  // only do destructive actions if we're going from logged in -> logged out
  // going from INIT -> ANONYMOUS just requires sardine and segment setup (below)
  let loggedOut = false;
  if (userStateVar() === "USER") {
    IN_DEVELOPMENT &&
      console.log("resetSession -- running destructive effects");
    await magic.user.logout();
    magicStateVar(MAGIC_LOGGED_OUT_STATE);
    magicLoadingVar(false);

    Cookies.remove(STORAGE_KEYS.jwtAccessToken, {
      domain: getDomain(),
    });
    jwtVar(undefined);

    // Only reset the store after clearing all auth data so that refetch
    // requests do not include the access token.
    await apolloClient.resetStore();
    loggedOut = true;
  }

  setSardineAnonymousUserContext();
  segmentReset();

  userStateVar("ANONYMOUS");
  resetBannerVar();
  return loggedOut;
};

// this feels kind of hacky to export but it's needed in the apolloClient which
// doesn't have access to hooks
export const resetSession = async (): Promise<boolean> => {
  return transitionToAnonymous();
};

// implementation method that can be called from transitionToLoggedIn or by
// a wrapper method in useSession that populates user from the current value
const _identifyEvent = (
  user: MeQuery["currentUser"] | null,
  updatedProperties?: Partial<UserIdentify>
) => {
  const { id, blockchainAccount, personalInformation, tokens } = user ?? {};

  if (!id) return;

  const memberships = (tokens ?? [])
    .filter((x) => x.tokenType === TokenType.Membership)
    .map((x) => x.community.slug);

  const properties: UserIdentify = {
    email: personalInformation?.email as string,
    memberships,
    blockchain_public_address: blockchainAccount?.publicAddress
      ? blockchainAccount?.publicAddress
      : "undefined",
    blockchain: blockchainAccount?.blockchain
      ? blockchainAccount?.blockchain
      : "undefined",
    ...(updatedProperties ?? {}),
  };

  segmentIdentifyEvent(id, properties);
};

const transitionToLoggedIn = (
  currentUser: NonNullable<MeQuery["currentUser"]>
) => {
  // to only run side effects once
  if (userStateVar() === "USER") return;

  IN_DEVELOPMENT && console.log("transitionToLoggedIn");

  setSardineLoggedInUserContext(currentUser.id);
  _identifyEvent(currentUser);

  userStateVar("USER");
};

/**
 * useJwtAccessToken is a hook for setting and retrieving the JWT access token
 * that is sent in the X-Access-Token header in requests to web-graphql for
 * authentication. It ensures that the React application's view of the token
 * stays in sync with the cookie -- the cookie is the authoritative source
 * of the header in the Apollo client.
 *
 * This hook is only used internally by the useSession hook. It shouldn't be
 * used standalone in components.
 */
const useJwtAccessToken = () => {
  const expires = JWT_EXPIRATION_DAYS;
  const jwt = useReactiveVar(jwtVar);

  return {
    jwt,
    set: (t: string) => {
      // update the stored cookie before updating app state
      // to ensure the proper value wins
      Cookies.set(STORAGE_KEYS.jwtAccessToken, t, {
        expires,
        domain: getDomain(),
      });
      jwtVar(t);
    },
    clear: () => {
      // update the stored cookie before updating app state
      // to ensure the proper value wins
      Cookies.remove(STORAGE_KEYS.jwtAccessToken, {
        domain: getDomain(),
      });
      jwtVar(undefined);
    },
  };
};

/**
 * useMggicLink is a hook that keeps the application view of Magic.link
 * authentication with the 3rd party library, cookies, and service. It keeps
 * the current authentication state and provides methods to bootstrap at
 * app startup, login, or logout.
 *
 * This hook is only used internally by the useSession hook. It shouldn't be
 * used standalone in components.
 */
const useMagicLink = () => {
  const state = useReactiveVar(magicStateVar);
  const isLoading = useReactiveVar(magicLoadingVar);

  const bootstrap = async (): Promise<MagicState> => {
    if (magicLoadingVar()) {
      const error = new Error(
        "useMagicLink attempting to reenter logic block while another is running"
      );
      logError(error);
      throw error;
    }
    const newState: MagicState = MAGIC_LOGGED_OUT_STATE;
    magicLoadingVar(true);
    try {
      const magicCredential = getMagicCredentialUrlParam();
      if (magicCredential) {
        const didTokenFromCredential = await magic.auth.loginWithCredential();
        if (didTokenFromCredential) {
          newState.magicDidToken = didTokenFromCredential;
          newState.magicIsLoggedIn = true;
        }
      } else if (getDeprecatedDidToken()) {
        // Try to use the didToken stored in the cookie to login
        // this is only relevant for the didToken -> JWT migration
        IN_DEVELOPMENT &&
          console.log("retrieving didToken stored in old cookie");
        newState.magicIsLoggedIn = true;
        newState.magicDidToken = await magic.user.getIdToken();
      } else {
        newState.magicIsLoggedIn = await magic.user.isLoggedIn();
        if (newState.magicIsLoggedIn) {
          newState.magicDidToken = await magic.user.getIdToken();
        }
      }
      return newState;
    } catch (error) {
      logError({ error, message: "useMagicLink/bootstrap error" });
      magic.user.logout();
      Object.assign(newState, MAGIC_LOGGED_OUT_STATE);
      throw error;
    } finally {
      magicLoadingVar(false);
      magicStateVar(newState);
    }
  };

  const login = async (email: string, { magicLinkRedirect = false } = {}) => {
    if (magicLoadingVar()) {
      const error = new Error(
        "useMagicLink attempting to reenter logic block while another is running"
      );
      logError(error);
      throw error;
    }
    magicLoadingVar(true);
    let newState: MagicState = MAGIC_LOGGED_OUT_STATE;

    try {
      const redirectURI = magicLinkRedirect
        ? document.location.href.split("?")[0]
        : undefined;
      const didToken = await magic.auth.loginWithMagicLink({
        email,
        redirectURI,
        showUI: false,
      });
      if (didToken) {
        newState = {
          magicIsLoggedIn: true,
          magicDidToken: didToken,
        };
      }

      magicStateVar(MAGIC_LOGGED_OUT_STATE);
      return newState;
    } catch (error) {
      logError({ error, message: "useMagicLink/loginWithMagicLink error" });
      throw error;
    } finally {
      magicLoadingVar(false);
    }
  };

  const logout = async () => {
    if (magicLoadingVar()) {
      const error = new Error(
        "useMagicLink attempting to reenter logic block while another is running"
      );
      logError(error);
      throw error;
    }
    magicLoadingVar(true);
    try {
      await magic.user.logout();
    } catch (error) {
      logError({ error, message: "useMagicLink/logout error" });
    } finally {
      magicStateVar(MAGIC_LOGGED_OUT_STATE);
      magicLoadingVar(false);
    }
    return MAGIC_LOGGED_OUT_STATE;
  };

  const clearLoading = () => {
    magicLoadingVar(false);
  };

  return {
    state,
    isLoading,
    clearLoading,
    bootstrap,
    login,
    logout,
  };
};

/**
 * useSession is a single hook that is designed to modularize all aspects of
 * authentication and current user data in one place. It decouples the methods
 * of authentication (currently JWT + magic.link, soon to include wallet signing
 * and potentially impersonation tools) from the application logic which only
 * needs to know whether the session is signed in as a user, anonymous, or in a
 * loading or error state.
 *
 * In addition to the data fields, methods are provided to bootstrap auth from
 * stored cookies or to authenticate via user input.
 *
 * useSession replaced useMe and useAuth.
 */
const useSession = () => {
  // Use state and actions from internal hooks
  const { jwt, set: setJwt, clear: clearJwt } = useJwtAccessToken();
  const {
    state: { magicIsLoggedIn },
    isLoading: magicIsLoading,
    clearLoading: magicClear,
    bootstrap: magicBootstrap,
    logout: magicLogout,
    login: magicLogin,
  } = useMagicLink();

  const navigate = useNavigate();

  const [
    authWithDidToken,
    { loading: authWithDidTokenLoading, error: authWithDidTokenError },
  ] = useAuthWithMagicLinkMutation({
    onCompleted: (data) => {
      if (data?.authWithMagicLink?.jwt) {
        setJwt(data?.authWithMagicLink?.jwt);
      }
    },
    onError: (error) => {
      logError({ error, message: "authWithMagicLink apollo error" });
      clearJwt();
    },
    refetchQueries: [CommunityDocument],
  });

  const [logInWithMagicLink] = useLogInWithMagicLinkMutation({
    onCompleted: (data) => {
      if (data?.logInWithMagicLink?.jwt) {
        setJwt(data?.logInWithMagicLink?.jwt);
      }
    },
    onError: (error) => {
      logError({
        error,
        message: "logInWithMagicLink apollo error",
      });
      clearJwt();
    },
    refetchQueries: [CommunityDocument],
  });

  const [createUserWithMagicLink] = useCreateUserWithMagicLinkMutation({
    onCompleted: (data) => {
      if (data?.createUserWithMagicLink?.jwt) {
        setJwt(data?.createUserWithMagicLink?.jwt);
      }
    },
    onError: (error) => {
      logError({
        error,
        message: "createUserWithMagicLink apollo error",
      });
      navigate(COMMUNITY_URL.signin);
    },
    refetchQueries: [CommunityDocument],
  });

  const [completeSignedMessageLogin] =
    useCompleteLoginWithSignedMessageMutation({
      onCompleted: (data) => {
        // logout if switch metamask accounts - handled in useBootstrap()
        if (data?.completeLoginWithSignedMessage?.jwt) {
          setJwt(data?.completeLoginWithSignedMessage?.jwt);
        }
      },
      onError: (error) => {
        logError({ error, message: "completeSignedMessageLogin apollo error" });
        clearJwt();
      },
      refetchQueries: [CommunityDocument],
    });

  const [completeSignedMessageCreateUser] =
    useCompleteCreateUserWithSignedMessageMutation({
      onCompleted: (data) => {
        // logout if switch metamask accounts - handled in useBootstrap()
        if (data?.completeCreateUserWithSignedMessage?.jwt) {
          setJwt(data?.completeCreateUserWithSignedMessage?.jwt);
        }
      },
      onError: (error) => {
        logError({
          error,
          message: "completeSignedMessageCreateUser apollo error",
        });
        clearJwt();
      },
      refetchQueries: [CommunityDocument],
    });

  const [refreshAccessToken] = useRefreshAccessTokenMutation({
    onCompleted: (data) => {
      if (data?.refreshAccessToken?.jwt) {
        setJwt(data?.refreshAccessToken?.jwt);
      }
    },
    onError: (error) => {
      logError({ error, message: "refreshAccessToken apollo error" });
      // don't clear token, the old one might be good still
      // clearJwt();
    },
    refetchQueries: [CommunityDocument],
  });

  const userQuery = useMeQuery({
    skip: !jwt,
    onCompleted: (data) => {
      if (!data?.currentUser) {
        logError({ message: `meQuery.currentUser is null` });
        return;
      }

      // Note: this hook will run in every component that calls `useSession`
      // even if Apollo is just loading data from the cache. That is why
      // transitionToLoggedIn and resetSession use a separate state variable
      // to ensure that transition side effects don't run more than once.
      //
      // We could move some of this logic into a Context Provider so that it
      // is only called a single time. Right now data is shared across components
      // through Apollo but the hook code runs multiple times which is error-
      // prone.
      transitionToLoggedIn(data.currentUser);
    },
  });
  const {
    data: me,
    loading: meLoading,
    error: meError,
    refetch: refetchUser,
  } = userQuery;
  const user = me?.currentUser;

  const identifyEvent = (updatedProperties?: Partial<UserIdentify>) => {
    return _identifyEvent(user ?? null, updatedProperties);
  };

  const jwtFromDidToken = async (didToken: string) => {
    try {
      const result = await authWithDidToken({
        variables: { didToken },
      });
      const jwt = result?.data?.authWithMagicLink?.jwt;

      if (!jwt) {
        return null;
      }

      // It should only be used a single time to attempt login. The first time,
      // we get a JWT from the server this is removed.
      removeDeprecatedDidToken();

      return jwt;
    } catch (error) {
      logError({ error, message: "error in useSession/jwtFromDidToken" });
    }
    return null;
  };

  const jwtFromMagicSignInOrUp = async (
    didToken: string,
    createNewAccount: boolean
  ) => {
    try {
      let jwt = "";
      if (createNewAccount) {
        const result = await createUserWithMagicLink({
          variables: { didToken },
        });

        if (!result.data || result.errors) {
          throw (
            result?.errors ??
            new Error("failed to create new account with magic link")
          );
        }
        jwt = result?.data?.createUserWithMagicLink?.jwt ?? "";
      } else {
        const result = await logInWithMagicLink({
          variables: { didToken },
        });
        if (!result.data || result.errors) {
          throw (
            result?.errors ??
            new Error("failed to sign into an account with magic link")
          );
        }
        jwt = result?.data?.logInWithMagicLink?.jwt ?? "";
      }

      if (!jwt) {
        return null;
      }

      // It should only be used a single time to attempt login. The first time,
      // we get a JWT from the server this is removed.
      removeDeprecatedDidToken();

      return jwt;
    } catch (error) {
      logError({
        error,
        message: "error in useSession/jwtFromMagicSignInOrUp",
      });
      throw error;
    }
  };

  const jwtFromMagicSignIn = async (didToken: string) =>
    jwtFromMagicSignInOrUp(didToken, false);
  const jwtFromMagicSignUp = async (didToken: string) =>
    jwtFromMagicSignInOrUp(didToken, true);

  const refreshJwt = async () => {
    try {
      const result = await refreshAccessToken();
      const newJwt = result?.data?.refreshAccessToken?.jwt;

      if (!newJwt) {
        return null;
      }

      return newJwt;
    } catch (error) {
      logError({ error, message: "error in useSession/refreshAccessToken" });
    }
    return null;
  };

  const bootstrap = async () => {
    try {
      if (jwt) {
        IN_DEVELOPMENT && console.log("using stored JWT from cookie", jwt);
        const newJwt = await refreshJwt();
        IN_DEVELOPMENT && console.log("refreshed JWT", newJwt);
        return;
      }

      const magicState = await magicBootstrap();

      if (magicState.magicDidToken) {
        IN_DEVELOPMENT &&
          console.log("authing with didToken to get jwt", {
            didToken: magicState.magicDidToken,
          });
        // Setting JWT will cause the currentUser query to no longer be skipped
        // and so the currentUser/me query will load.
        jwtFromDidToken(magicState.magicDidToken);
        /*
        if (enableCryptoByoWallet) {
          jwtFromMagicSignIn(magicState.magicDidToken);
        } else {
          jwtFromDidToken(magicState.magicDidToken);
        }
        */
      } else {
        IN_DEVELOPMENT &&
          console.log(
            "couldn't bootstrap auth from JWT or magic; assuming anonymous session"
          );
        resetSession();
      }
    } catch (error) {
      logError({ error, message: "useSession/bootstrap error" });
    }
  };

  const authWithMagicLink = async (
    email: string,
    { magicLinkRedirect = false } = {}
  ): Promise<MeQuery["currentUser"] | null> => {
    try {
      if (magicIsLoggedIn) {
        await magicLogout();
      }
      const magicState = await magicLogin(email, { magicLinkRedirect });
      if (!magicState.magicDidToken) {
        return null;
      }
      const jwt = await jwtFromDidToken(magicState.magicDidToken);
      if (!jwt) {
        return null;
      }
      const userQueryResult = await refetchUser();
      return userQueryResult?.data?.currentUser;
    } catch (e) {
      updateModalVar({ showModal: ModalType.GENERIC_ERROR });
      await magic.user.logout();
      clearJwt();
      logError({ error, message: "useSession/authWithMagicLink error" });
      throw e;
    }
  };

  const loginWithMagic = async (
    email: string,
    { magicLinkRedirect = false } = {}
  ): Promise<MeQuery["currentUser"] | null> => {
    try {
      if (magicIsLoggedIn) {
        await magicLogout();
      }
      const magicState = await magicLogin(email, { magicLinkRedirect });
      if (!magicState.magicDidToken) {
        return null;
      }
      const jwt = await jwtFromMagicSignIn(magicState.magicDidToken);
      if (!jwt) {
        return null;
      }
      const userQueryResult = await refetchUser();
      return userQueryResult?.data?.currentUser;
    } catch (e) {
      await magic.user.logout();
      clearJwt();
      logError({ error, message: "useSession/authWithMagicLink error" });
      throw e;
    }
  };

  const createUserWithMagic = async (
    email: string,
    { magicLinkRedirect = false } = {}
  ): Promise<MeQuery["currentUser"] | null> => {
    try {
      if (magicIsLoggedIn) {
        await magicLogout();
      }
      const magicState = await magicLogin(email, { magicLinkRedirect });
      if (!magicState.magicDidToken) {
        return null;
      }
      const jwt = await jwtFromMagicSignUp(magicState.magicDidToken);
      if (!jwt) {
        return null;
      }
      const userQueryResult = await refetchUser();
      return userQueryResult?.data?.currentUser;
    } catch (e) {
      await magic.user.logout();
      clearJwt();
      logError({ error, message: "useSession/authWithMagicLink error" });
      throw e;
    }
  };

  const connectMetamask = async () => {
    // A Web3Provider wraps a standard Web3 provider, which is
    // what MetaMask injects as window.ethereum into each page
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const provider = new providers.Web3Provider((window as any).ethereum);

    // The MetaMask plugin also allows signing transactions to
    // send ether and pay to change state within the blockchain.
    // For this, you need the account signer...
    return provider.getSigner();
  };

  const loginWithWeb3 = async () => {
    try {
      if (user) {
        await logout();
      }

      const signer = await connectMetamask();
      const publicAddress = await signer.getAddress();

      const startResult = await apolloClient.query({
        query: StartLoginWithSignedMessageDocument,
        variables: {
          publicAddress,
        },
        fetchPolicy: "network-only",
      });

      if (!startResult.data || startResult.error) {
        throw (
          startResult.error ?? new Error("failed StartLoginWithSignedMessage")
        );
      }

      const messageToSign =
        startResult.data.startLoginWithSignedMessage.messageToSign;

      const signature = await signer.signMessage(messageToSign);

      const completeResult = await completeSignedMessageLogin({
        variables: {
          publicAddress,
          signature,
        },
      });
      if (!completeResult.data || completeResult.errors) {
        throw (
          completeResult?.errors ??
          new Error("failed StartLoginWithSignedMessage")
        );
      }
    } catch (e) {
      logError({ error, message: "useSession/loginWithWeb3 error" });
      await resetSession();
      throw e;
    }
  };

  const createUserWithWeb3 = async (email: string) => {
    try {
      if (user) {
        await logout();
      }

      const signer = await connectMetamask();
      const publicAddress = await signer.getAddress();

      const startResult = await apolloClient.query({
        query: StartCreateUserWithSignedMessageDocument,
        variables: {
          publicAddress,
        },
        fetchPolicy: "network-only",
      });

      if (!startResult.data || startResult.error) {
        throw (
          startResult.error ??
          new Error("failed StartCreateUserWithSignedMessage")
        );
      }

      const messageToSign =
        startResult.data.startCreateUserWithSignedMessage.messageToSign;

      const signature = await signer.signMessage(messageToSign);

      const completeResult = await completeSignedMessageCreateUser({
        variables: {
          publicAddress,
          signature,
          email,
        },
      });
      if (!completeResult.data || completeResult.errors) {
        throw (
          completeResult?.errors ??
          new Error("failed CompleteCreateUserWithSignedMessage")
        );
      }
    } catch (e) {
      logError({ error, message: "useSession/createUserWithWeb3 error" });
      await resetSession();
      throw e;
    }
  };

  const logout = async (redirectTo?: string) => {
    try {
      if (!user) {
        return;
      }

      segmentTrackEvent(ANALYTICS_EVENT.USER_SIGN_OUT);

      await resetSession();

      redirectTo && navigate(redirectTo);
    } catch (error) {
      logError({ error, message: "useSession/logout error" });
    }
  };

  const isLoading =
    !!magicIsLoading || !!authWithDidTokenLoading || !!meLoading;
  const isUser = !!user;
  const error = authWithDidTokenError ?? meError;
  const isError = !!error;
  const isLoggedIn = !!user;
  const isAnonymous = !isLoggedIn;

  const isCommunityMember = (identity: string | undefined) =>
    (user?.tokens ?? []).some(
      (token) =>
        token.tokenType === TokenType.Membership &&
        (token.community.id === identity ||
          token.community.slug === identity) &&
        (token.balance ?? 0) > 0
    );

  const validateMetaMask = async () => {
    let errorType = AuthErrorType.NONE; // : LogInError = EmptyLoginError;
    if (!existsMetaMaskPlugin()) {
      errorType = AuthErrorType.METAMASK_MISSING_PLUGIN;
    } else if (!(await isSignedIntoMetaMask())) {
      errorType = AuthErrorType.METAMASK_NOT_SIGNED_IN;
    } else {
      const correctNetwork = await onCorrectNetwork();
      if (!correctNetwork.ok) {
        switch (correctNetwork.network) {
          case NetworkType.LOCAL:
            errorType = AuthErrorType.METAMASK_NOT_LOCAL_NETWORK;
            break;
          case NetworkType.TESTNET:
            errorType = AuthErrorType.METAMASK_NOT_TESTNET_NETWORK;
            break;
          case NetworkType.POLYGON:
          default:
            errorType = AuthErrorType.METAMASK_NOT_POLYGON_NETWORK;
        }
      } else {
        const addr = await getMetamaskAddress();
        if (!addr) {
          errorType = AuthErrorType.METAMASK_GENERAL_ERROR;
        }
      }
    }

    return { type: errorType, message: errorType } as LogInError;
  };

  const result = {
    isLoading,
    jwtAccessToken: jwt,
    user,
    refetchUser,
    userQuery,
    isUser,
    isAnonymous,
    error,
    isError,
    bootstrap,
    authWithMagicLink,
    logout,
    isCommunityMember,
    identifyEvent,
    loginWithMagic,
    createUserWithMagic,
    loginWithWeb3,
    createUserWithWeb3,
    magicClear,
    validateMetaMask,
  };
  return result;
};

export default useSession;
export type UseSession = ReturnType<typeof useSession>;
export type CurrentUser = NonNullable<MeQuery["currentUser"]>;
