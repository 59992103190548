import React, { memo, FC } from "react";

import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right-default.svg";
import MetamaskFoxImage from "assets/icons/metamask.svg";
import Button from "components/Button";
import CardContainer from "components/CardContainer";

import useLogic from "./logic";
import * as styles from "./styles";

const MetaMaskSignIn: FC = () => {
  const { signIn } = useLogic();
  return (
    <Button extraCss={styles.button}>
      <CardContainer
        border={true}
        additionalCss={styles.container}
        onClick={() => signIn()}
      >
        <div css={styles.leftAuthContainer}>
          <img
            css={styles.image}
            src={MetamaskFoxImage}
            alt={"metamask icon"}
          />
        </div>
        <div css={styles.middleAuthContainer}>
          <a css={styles.navLink} rel="noreferrer">
            Sign In With MetaMask
          </a>
        </div>
        <div css={styles.rightAuthContainer}>
          <ArrowRightIcon />
        </div>
      </CardContainer>
    </Button>
  );
};

export default memo(MetaMaskSignIn);
