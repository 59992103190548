import { useCallback } from "react";

import { useApolloClient, useReactiveVar } from "@apollo/client";

import {
  StartMagicLinkFlowDocument,
  StartMagicLinkFlowQuery,
} from "apollo/queries";
import { modalVar, resetModalVar } from "apollo/reactive";
import { IN_DEVELOPMENT } from "config";
import { useAuthForm } from "hooks/useAuthForm";
import useSession from "hooks/useSession";
import { logError } from "services/logger";
import { AuthErrorType } from "utils/errors/authErrors";

const useLogic = () => {
  const { loginWithMagic } = useSession();
  const { dispatch } = useAuthForm();
  const apolloClient = useApolloClient();
  const { onClose } = useReactiveVar(modalVar);

  const onSubmit = useCallback(async (email: string) => {
    try {
      dispatch({ type: "isLoading", payload: true });
      const startQuery = await apolloClient.query<StartMagicLinkFlowQuery>({
        query: StartMagicLinkFlowDocument,
        variables: {
          email,
        },
      });
      if (startQuery.error) {
        throw startQuery.error;
      }
      const { allowedMethods } = startQuery.data.startMagicLinkFlow;
      if (!allowedMethods.includes("MAGIC_LINK_LOGIN")) {
        if (allowedMethods.includes("SIGNED_MESSAGE_LOGIN")) {
          throw {
            type: AuthErrorType.REQUIRE_METAMASK,
            message: AuthErrorType.REQUIRE_METAMASK as string,
          };
        } else if (allowedMethods.includes("MAGIC_LINK_CREATE_USER")) {
          throw {
            type: AuthErrorType.GRAPHQL_LOGIN_NO_SUCH_USER_MAGIC,
            message: AuthErrorType.GRAPHQL_LOGIN_NO_SUCH_USER_MAGIC as string,
          };
        } else {
          throw new Error(
            "Unexpected allowedMethods returned for startMagicLinkFlow"
          );
        }
      }

      await loginWithMagic(email, { magicLinkRedirect: true });
      dispatch({ type: "reset" });
      if (onClose) onClose();
      resetModalVar();
    } catch (error) {
      await logError({ error, message: "login failed" });
      dispatch({ type: "setLoginError", payload: error });
      dispatch({ type: "isLoading", payload: false });
      IN_DEVELOPMENT && console.log("🚨 [ERROR][HANDLE_LOGIN]: ", error);
      return null;
    }
  }, []);

  return {
    onSubmit,
  };
};

export type UseLogic = ReturnType<typeof useLogic>;
export default useLogic;
