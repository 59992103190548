import { css, Theme } from "@emotion/react";

import { textStyles } from "styles/global/typography";

/* Styles */

export const containerWrapper = css``;

export const container = (theme: Theme) => css`
  display: flex;
  width: 100%;
  padding: 100px 24px 24px 24px;
  margin: 0;

  a,
  p {
    ${textStyles("xsmall", false)(theme)}
  }

  ${theme.mediaQueries.smallOnly} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 14px;
    padding-top: 60px;
  }
`;

export const copyright = css``;

export const copyrightText = css`
  padding: 0;
  margin: 0;
`;

export const nav = (theme: Theme) => css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: 8px;

    > * {
      margin-right: 20px;
    }
  }
`;

export const navLink = css`
  color: inherit;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
