import { css, Theme } from "@emotion/react";

import { bottomMargin } from "styles/global/layout";

export const container = (theme: Theme) => css`
  text-align: center;
  padding: 32px;
  border-color: ${theme.colorMap.accent.primary};
  background: ${theme.colorMap.accent.tertiary};
  opacity: 0.8;
`;

export const textColorAccentPrimary = (theme: Theme) => css`
  color: ${theme.colorMap.accent.primary};
  ${bottomMargin("4/8px")};
`;

export const icon = (theme: Theme) => css`
  align-self: center;
  justify-content: center;
  margin: 0 auto;
  color: ${theme.colorMap.accent.primary};
`;

export const rightCloseIcon = css`
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin-left: 0;

  @media (hover: hover) {
    cursor: pointer;
  }
`;

export const closeIcon = (theme: Theme) => css`
  width: 24px;
  height: 24px;
  margin-top: -24px;
  margin-right: -24px;
  path {
    fill: ${theme.colorMap.accent.primary};
  }
  @media (hover: hover) {
    cursor: pointer;
  }
`;

export const topRow = css`
  display: flex;
`;
