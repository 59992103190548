import React, { memo, ReactNode } from "react";

import { css, Theme } from "@emotion/react";

import { ReactComponent as AddIcon } from "assets/icons/plus-sign.svg";

import InputGroupContainer from "../InputGroupContainer";

import OptionItem from "./OptionItem";

export type Option = {
  name: string;
  icon?: ReactNode;
};

type OptionsListProps = {
  addButtonText: string;
  options: Option[];
  addBenefitHandler(): void;
  removeBenefitHandler(option: Option): void;
};

const containerStyles = css`
  padding: 0 16px;
`;

const addButtonStyles = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 20px 0;
  width: 100%;
  color: ${theme.colorMap.accent.primary};
`;

const addIconStyles = css`
  fill: currentColor;
`;

const OptionsList = ({
  addButtonText,
  options,
  addBenefitHandler,
  removeBenefitHandler,
}: OptionsListProps) => {
  return (
    <InputGroupContainer extraCss={containerStyles}>
      {options.map((option) => (
        <OptionItem
          key={option.name}
          handleOnRemove={removeBenefitHandler}
          option={option}
        />
      ))}
      <button css={addButtonStyles} onClick={addBenefitHandler}>
        <AddIcon css={addIconStyles} />
        <span>{addButtonText}</span>
      </button>
    </InputGroupContainer>
  );
};

export default memo(OptionsList);
