import React, { memo, FC, PropsWithChildren } from "react";

import {
  CloseX,
  Error as ErrorIcon,
  InformationIcon as InfoIcon,
} from "assets/icons.generated";
import CardContainer from "components/CardContainer";

import * as styles from "./styles";
import { Props } from "./types";

const ErrorLinkContainer: FC<PropsWithChildren<Props>> = ({
  level = "error",
  canClose = true,
  onClick,
  onClose,
  children,
  additionalCss,
}) => {
  return (
    <CardContainer
      onClick={onClick}
      extraCss={[styles.errorContainer(level), additionalCss]}
    >
      <div css={styles.errorBackground(level)}>
        {level === "error" && <ErrorIcon css={styles.errorIcon} />}
        {level === "info" && <InfoIcon css={styles.infoIcon} />}
        <div css={styles.middleContainer}>{children}</div>
        {canClose && (
          <div onClick={onClose} css={styles.rightContainer}>
            <CloseX css={styles.closeIcon} />
          </div>
        )}
      </div>
    </CardContainer>
  );
};

export default memo(ErrorLinkContainer);
