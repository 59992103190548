import { css, Theme } from "@emotion/react";

export const errorText = (theme: Theme) => css`
  color: ${theme.colorMap.destructive[500]};
`;

export const errorTextUrl = (theme: Theme) => css`
  color: ${theme.colorMap.destructive[500]};
  text-decoration: underline;
`;

export const metaMaskErrorText = (theme: Theme) => css`
  color: ${theme.colorMap.destructive[500]};
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }
`;

export const leftContainer = css`
  flex: 5;
  display: flex;
  align-items: center;
  vertical-align: middle;
`;

export const leftAuthContainer = css`
  padding: 6px 16px 6px 0;
`;

export const linkContainer = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
`;

export const navLink = (theme: Theme) => css`
  color: ${theme.colorMap.destructive[500]};
  font-weight: bold;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
