import { useReactiveVar } from "@apollo/client";

import { CommunityQuery } from "apollo/queries";
import { communityVar } from "apollo/reactive";

type CommunityFlagVariations = NonNullable<
  CommunityQuery["community"]
>["flagVariations"];

type AllCommunityFlagVariations = {
  [K in keyof CommunityFlagVariations]: CommunityFlagVariations[K];
};

type AllowedCommunityFlags = Omit<AllCommunityFlagVariations, "__typename">;

/**
 * useCommunityFeatureFlag takes the name of a feature flag and returns the active
 * variation for that flag.
 *
 * Update AllCommunityFlagVariations in flagVariations.graphql to use in this hook.
 */
const useCommunityFeatureFlag = (
  flag: keyof AllowedCommunityFlags
): boolean => {
  const community = useReactiveVar(communityVar);

  const variations = community.flagVariations;
  return variations ? variations[flag] : false;
};

export default useCommunityFeatureFlag;

export type UseCommunityFeatureFlag = ReturnType<
  typeof useCommunityFeatureFlag
>;
