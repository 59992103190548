export const simpleDateTime = (
  time: Date,
  uppercase: boolean,
  atText: "@" | "at" = "@"
) => {
  const monthName = getMonthName(time);
  return `${uppercase ? monthName.toUpperCase() : monthName} ${getDayNumber(
    time
  )} ${atText} ${getHourMinTz(time)}`;
};

export const getMonthName = (time: Date) => {
  return time.toLocaleString("default", {
    month: "long",
  });
};

export const getDayNumber = (time: Date) => {
  return time.toLocaleString("default", {
    day: "numeric",
  });
};

export const getHourMinTz = (time: Date) => {
  // toLocaleString() returns UTC instead of GMT, manually add GMT
  return (
    time.toLocaleString("default", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    }) + " GMT"
  );
};
