import { useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import { useLocation } from "react-router-dom";

import { ANALYTICS_EVENT } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";
import useSession from "hooks/useSession";
import {
  trackEvent as segmentTrackEvent,
  pageEvent,
  UserIdentify,
  setUpSegment,
} from "services/segment";

import { initVar } from "../apollo/reactive/init";

const useSegmentEvents = () => {
  const { identifyEvent } = useSession();

  const trackEvent = async (
    eventName: ANALYTICS_EVENT,
    properties?: Record<string, unknown>,
    userProperties?: Partial<UserIdentify>
  ) => {
    if (userProperties) {
      identifyEvent(userProperties);
    }
    return segmentTrackEvent(eventName, properties);
  };

  return {
    identifyEvent,
    pageEvent,
    trackEvent,
  };
};

export const useSegmentTracker = ({
  isCommunityApp,
}: {
  isCommunityApp: boolean;
}) => {
  const location = useLocation();
  const [segmentReady, setSegmentReady] = useState(false);

  const isInitializing = useReactiveVar(initVar);
  const enableSegment = useFeatureFlag("segmentEnableInCommunities");

  useEffect(() => {
    if (isInitializing || !enableSegment) {
      return;
    }
    const setup = async () => {
      await setUpSegment();
      setSegmentReady(true);
    };
    setup();
  }, [isInitializing, enableSegment]);

  // TODO: ideally identify should be called from here and not from useSession

  useEffect(() => {
    if (!segmentReady) {
      return;
    }
    const { pathname } = location;
    let slug = window.location.host.split(".")[0];
    let category = isCommunityApp ? "community" : "app";

    let page = pathname.replace(/^\//, "").replace("/", "-");
    const pageProps: Record<string, string> = {};
    if (!isCommunityApp) {
      const editMatch = pathname.match(/\/edit-community\/([\w-]+)\/([\w-]+)/);

      if (editMatch) {
        category = "edit-community";
        [, slug, page] = editMatch;
      }
    } else {
      const contestMatch = pathname.match(/\/contest\/(\w+)/);

      if (contestMatch) {
        page = "contest";
        pageProps.contest_id = contestMatch[1];
      }
    }

    const name = `${category} :${page}`;

    pageEvent(category, name, {
      community_slug: slug,
      ...pageProps,
    });
  }, [segmentReady, location, isCommunityApp]);
};

export default useSegmentEvents;
export type UseSegmentEvents = ReturnType<typeof useSegmentEvents>;
