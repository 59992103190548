import { FC, memo } from "react";

import { Link } from "react-router-dom";

import * as styles from "./BaseButtonLink/styles";
import { RouterLinkProps } from "./BaseButtonLink/types";

const CommunityButtonLink: FC<RouterLinkProps> = ({
  children,
  className = "",
  size = "large",
  color = "onPageBackgroundBackgroundColor",
  to = "#",
  header,
  extraCss,
  ...rest
}) => (
  <Link
    className={className}
    css={[
      styles.base,
      styles.bySize(size, !!header),
      styles.byColor(color),
      extraCss,
    ]}
    to={to}
    {...rest}
  >
    <div css={styles.textWrapper}>
      {header && <span css={styles.headerText}>{header}</span>}
      <span css={[styles.buttonText]}>{children}</span>
    </div>
  </Link>
);

export default memo(CommunityButtonLink);
