import React, { memo, ReactNode, useState, useEffect } from "react";

import { css } from "@emotion/react";

import InputGroupContainer from "../InputGroupContainer";

import CheckBoxItem from "./CheckboxItem";

export type CheckboxItem = {
  name: string;
  icon?: ReactNode;
  permanentItemText?: string;
};

type CheckBoxListProps = {
  items: CheckboxItem[];
  initialSelectedNames?: string[];
  handleOnChange(items: CheckboxItem[]): void;
};

const containerStyles = css`
  padding: 0 16px;
`;

const CheckBoxList = ({
  items,
  initialSelectedNames = [],
  handleOnChange,
}: CheckBoxListProps) => {
  const [selectedItems, setSelectedItems] = useState<CheckboxItem[]>([
    ...new Set([
      ...items.filter((item) => item.permanentItemText),
      ...items.filter((item) => initialSelectedNames.includes(item.name)),
    ]),
  ]);

  useEffect(() => {
    handleOnChange(selectedItems);
  }, [selectedItems]);

  const handleCheckboxChange = (item: CheckboxItem) => {
    const foundIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem.name === item.name
    );
    const updatedItems = [...selectedItems];

    if (foundIndex > -1) {
      updatedItems.splice(foundIndex, 1);
    } else {
      updatedItems.push(item);
    }

    setSelectedItems(updatedItems);
  };

  return (
    <InputGroupContainer extraCss={containerStyles}>
      {items.map((item) => (
        <CheckBoxItem
          key={item.name}
          handleOnChange={() => handleCheckboxChange(item)}
          item={item}
          checked={
            !!selectedItems.find((selected) => selected.name === item.name)
          }
          grouped
        />
      ))}
    </InputGroupContainer>
  );
};

export default memo(CheckBoxList);
