import { useCallback } from "react";

import { useReactiveVar } from "@apollo/client";

import {
  userProfileDropdownVar,
  updateUserProfileDropdownVar,
} from "apollo/reactive";

export const useUserProfileDropdownLogic = () => {
  const { isOpen } = useReactiveVar(userProfileDropdownVar);

  const handleDropDownClose = useCallback(() => {
    setTimeout(() => {
      updateUserProfileDropdownVar({ isOpen: false });
    }, 150);
  }, []);

  const handleDropDownToggle = () => {
    updateUserProfileDropdownVar({ isOpen: !isOpen });
  };

  return {
    isSidebarOpen: isOpen,
    handleDropDownClose,
    handleDropDownToggle,
  };
};

export type UseUserProfileDropdownLogic = ReturnType<
  typeof useUserProfileDropdownLogic
>;
