import { css, Theme } from "@emotion/react";

export const navLink = (theme: Theme) => css`
  color: ${theme.text.colors.onBackground["primary"]};
  font-weight: bold;
`;

export const button = (theme: Theme) => css`
  padding: 0;
  background: transparent;
  margin-bottom: ${theme.verticalSpaces.large[10]};
  border-radius: 0;
`;

export const container = (theme: Theme) => css`
  padding: 16px 16px 16px 20px;
  border-radius: 99px;
  max-width: 576px;
  height: 100%;
  width: 100%;
  background: ${theme.background.colors.primary};
  display: flex;
  align-items: center;
`;

export const rightAuthContainer = css`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  color: black;
`;

export const leftAuthContainer = css`
  padding: 0 12px 0 0;
`;

export const middleAuthContainer = css`
  flex: 9;
  text-align: left;
`;

export const rightAuthIcon = css`
  flex: 1;
`;

export const image = css`
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  width: 32px;
  height: 32px;
`;
