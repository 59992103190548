import { TokenType } from "../graphql.generated";
import { useCreatedCommunityBySlugQuery } from "../queries/communities.graphql.generated";

type Options = {
  networkOnly?: boolean;
};
const useCreatedCommunity = (
  communitySlug: string | undefined,
  { networkOnly }: Options = {}
) => {
  const { loading, data, ...rest } = useCreatedCommunityBySlugQuery({
    fetchPolicy: networkOnly ? "network-only" : "cache-first",
    variables: {
      slug: communitySlug as string,
      useChain: false,
      type: TokenType.Membership,
      active: true,
    },
  });
  const community = data?.createdCommunityBySlug;

  const isDeployingContract =
    !loading && !community?.polygonContract?.contractAddress;

  const hasAvatar = !!community?.creatorAvatar.imageUrl;
  const hasDescription = !!community?.description;
  const hasLogo = !!community?.imageUrl;
  const missingCommunityInformation =
    !loading && (!hasAvatar || !hasDescription || !hasLogo);

  return {
    ...rest,
    data,
    loading,
    isDeployingContract,
    missingCommunityInformation,
  };
};

export default useCreatedCommunity;
export type UseCreatedCommunity = ReturnType<typeof useCreatedCommunity>;
