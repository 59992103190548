import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { object as yupObject, string as yupString } from "yup";

import {
  CreatedCommunityThemeBySlugDocument,
  useCreatedCommunityThemeBySlugQuery,
  useUpdateCreatedCommunityThemeMutation,
} from "apollo/queries";
import { ModalType, updateModalVar } from "apollo/reactive";
import { FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";
import { logError } from "services/logger";
import { omitTypenameDeep } from "utils/objects";

import { EditCommunityThemeForm } from "./EditCommunityTheme";

export const usePageLogic = () => {
  const { communitySlug } = useParams();

  const { loading, data, error } = useCreatedCommunityThemeBySlugQuery({
    variables: {
      slug: communitySlug!,
    },
  });

  const isError = !!error;
  const isNotFound = !loading && data?.createdCommunityBySlug === null;
  const communityStyles = data?.createdCommunityBySlug?.websiteTheme;
  const communityId = data?.createdCommunityBySlug?.id;

  const isFeatureFlagEnable = useFeatureFlag(
    FEATURE_FLAG.ENABLE_CREATOR_DASHBOARD_THEME_TAB
  );

  return {
    isPageBlockedByFeatureFlag: !isFeatureFlagEnable,
    isError,
    isLoading: loading,
    isNotFound,
    communityStyles,
    communityId,
  };
};

export const useFormLogic = (
  communityId: string,
  defaultValues: EditCommunityThemeForm["defaultValues"]
) => {
  const [showSaveCard, setShowSaveCard] = useState<boolean>(false);

  const [doUpdateCreatedCommunityThemeMutation] =
    useUpdateCreatedCommunityThemeMutation();

  const validationSchema = yupObject({
    pageBackground: yupObject({
      backgroundColor: yupString().trim().required("This field is required"),
    }),
    primaryBackground: yupObject({
      backgroundColor: yupString().trim().required("This field is required"),
    }),
    text: yupObject({
      onPageBackgroundPrimaryColor: yupString()
        .trim()
        .required("This field is required"),
      onPrimaryBackgroundPrimaryColor: yupString()
        .trim()
        .required("This field is required"),
    }),
    textInput: yupObject({
      backgroundColor: yupString().trim().required("This field is required"),
      primaryTextColor: yupString().trim().required("This field is required"),
    }),
    button: yupObject({
      onPageBackgroundBackgroundColor: yupString()
        .trim()
        .required("This field is required"),
      onPageBackgroundTextColor: yupString()
        .trim()
        .required("This field is required"),
      onPageBackgroundBorderColor: yupString()
        .trim()
        .required("This field is required"),
      onPrimaryBackgroundBackgroundColor: yupString()
        .trim()
        .required("This field is required"),
      onPrimaryBackgroundTextColor: yupString()
        .trim()
        .required("This field is required"),
      onPrimaryBackgroundBorderColor: yupString()
        .trim()
        .required("This field is required"),
    }),
  }).required();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState,
  } = useForm<EditCommunityThemeForm["defaultValues"]>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (formState.isDirty) setShowSaveCard(true);
  }, [formState.isDirty]);

  const onSubmit: SubmitHandler<
    EditCommunityThemeForm["defaultValues"]
  > = async (data) => {
    try {
      const inputData =
        omitTypenameDeep<Partial<EditCommunityThemeForm["defaultValues"]>>(
          data
        );

      const updatedTheme = await doUpdateCreatedCommunityThemeMutation({
        refetchQueries: [CreatedCommunityThemeBySlugDocument],
        variables: {
          theme: {
            ...inputData,
          },
          communityId: communityId,
        },
      });

      reset(updatedTheme.data?.updateCreatedCommunityTheme);
    } catch (error) {
      await logError({
        error,
        message: `[doUpdateCreatedCommunityThemeMutation] "Update failed"`,
      });
      updateModalVar({
        showModal: ModalType.GENERIC_ERROR,
        showView: null,
      });
    } finally {
      setShowSaveCard(false);
    }
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    register,
    watch,
    setValue,
    getValues,
    formState,
    showSaveCard,
  };
};

// Types

export type UsePageLogic = ReturnType<typeof usePageLogic>;
export type UseFormLogic = ReturnType<typeof useFormLogic>;
