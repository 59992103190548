import React, { ComponentProps, FC, memo, PropsWithChildren } from "react";

import { css, Interpolation, Theme } from "@emotion/react";

import LoadingIcon from "components/LoadingIcon";
import Switch from "components/Switch";
import Text from "components/Typography/Text";

type InputProps = ComponentProps<"input">;

export type SwitchContainerProps = {
  title: string;
  description?: string;
  isLoading?: boolean;
  disabled?: InputProps["disabled"];
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
} & ComponentProps<"input">;

const containerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const switchContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 32px;
`;

const SwitchItem: FC<PropsWithChildren<SwitchContainerProps>> = ({
  title,
  description,
  isLoading,
  extraCss,
  disabled,
  ...rest
}) => (
  <div css={extraCss}>
    <div css={containerStyle}>
      <div>
        <Text bold color={disabled ? "secondary" : "primary"}>
          {title}
        </Text>
        {description && (
          <Text size="small" color="secondary">
            {description}
          </Text>
        )}
      </div>
      <div css={switchContainerStyle}>
        {isLoading ? <LoadingIcon /> : <Switch disabled={disabled} {...rest} />}
      </div>
    </div>
  </div>
);

export default memo(SwitchItem);
