import React, { memo } from "react";

import EmptyImage from "assets/icons/file-drop-area.svg";
import Text from "components/Typography/Text";

import VideoPreview from "../VideoPreview";

import * as styles from "./styles";
import { Props } from "./types";

const TokenPreview = ({
  showTitle = true,
  onLeft = false,
  imageUri,
  animationUri,
}: Props) => {
  const NoImage = () => {
    return (
      <div css={styles.emptyTokenContainer(EmptyImage)}>
        <Text extraCss={styles.noTokenText}>
          A preview of your token will appear here after you upload artwork
        </Text>
      </div>
    );
  };
  const styleContainer = onLeft ? styles.mintInProgress : styles.baseContainer;
  return (
    <div>
      {showTitle && (
        <Text size="small" bold extraCss={styles.title}>
          TOKEN PREVIEW
        </Text>
      )}
      <div css={styleContainer}>
        {animationUri ? (
          <VideoPreview posterUri={imageUri} sourceUri={animationUri} />
        ) : imageUri ? (
          <img css={styles.tokenImage} src={imageUri || ""} alt="token image" />
        ) : (
          <NoImage />
        )}
      </div>
    </div>
  );
};

export default memo(TokenPreview);
