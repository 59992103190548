import React, { memo } from "react";

import { useReactiveVar } from "@apollo/client";

import { communityVar } from "apollo/reactive";
import CommunityAvatarSmall from "components/CommunityAvatarSmall";

import CommunityButton from "../../_Community/Button";
import CommunityLabel from "../../_Community/Label";
import CommunityText from "../../_Community/Text";

import * as styles from "./styles";
import { TokenTooltipProps } from "./types";

const TokenTooltipContent = ({
  name,
  imageUrl,
  supply,
  setShowTokenDetailsModal,
  showDetails,
  viewDetailsOnClickHandler,
}: TokenTooltipProps) => {
  const communityData = useReactiveVar(communityVar);

  return (
    <div css={styles.tooltip}>
      <div css={styles.tooltipMedia}>
        {imageUrl && <img alt={name} src={imageUrl} />}
      </div>

      <div css={styles.tooltipContent}>
        <CommunityAvatarSmall
          imageUrl={communityData?.creatorAvatar?.imageUrl}
          name={communityData?.creatorAvatar?.name}
          size="small"
          showAvatar={false}
        />
        <CommunityText bold>
          {name}
          <CommunityLabel
            as="small"
            size="small"
            color="onPageBackgroundPrimaryColor"
          >
            {showDetails ? `Owned by You` : `Edition of ${supply}`}
          </CommunityLabel>
        </CommunityText>
      </div>

      {showDetails && (
        <div css={styles.tooltipCTA}>
          <CommunityButton
            size="small"
            css={styles.tooltipContentButton}
            onClick={() => {
              setShowTokenDetailsModal(true);
              viewDetailsOnClickHandler();
            }}
          >
            View Details
          </CommunityButton>
        </div>
      )}
    </div>
  );
};

export default memo(TokenTooltipContent);
