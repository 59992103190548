import React, { memo } from "react";

import Button from "components/Button";
import * as sharedStyles from "containers/App/EditCommunity/Tokens/Shared/styles";

interface Props {
  handleClick: () => void;
  text: string;
}
const SEND_TOKEN_DEFAULT_TEXT = "Send Token";
const SendToken = ({ handleClick, text }: Props) => {
  return (
    <div css={sharedStyles.mintButtonContainer}>
      <Button
        onClick={handleClick}
        additionalCss={sharedStyles.mintButton}
        disabled={text !== SEND_TOKEN_DEFAULT_TEXT}
      >
        {text}
      </Button>
    </div>
  );
};

export default memo(SendToken);
