import { css, Theme } from "@emotion/react";

export const modal = (theme: Theme) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${theme.zLayers.tokenDetails};

  display: flex;
  justify-content: center;
  align-content: flex-start;

  background: rgba(0, 0, 0, 0.92);
  color: white;
  overflow-y: auto;
  outline: 1px solid blue;

  > button {
    &::after {
      background-color: white;
    }
    svg {
      circle {
        fill: black;
      }
      path {
        fill: white;
      }
    }
  }
`;

export const container = css`
  max-width: 572px;
  width: 100%;
`;

export const content = css`
  padding: 40px 20px;
`;

// Header

export const header = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const headerCreatorAvatar = css`
  p {
    color: inherit;
  }
  svg path {
    fill: white;
  }
`;

export const headerTitle = css`
  margin-top: 8px;
  color: inherit;
`;

export const headerTokenTag = css`
  margin-top: 8px;
  margin-bottom: 0;
`;

// Token Details
export const tokenImage = css`
  margin: 48px 0;
`;

// Token Details

export const tokenDetails = css`
  color: inherit;
  margin-top: 52px;
`;

export const tokenDetailsListTitle = css`
  color: inherit;
  margin-bottom: 12px;
`;

export const tokenDetailsList = css`
  color: inherit;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      padding: 12px 0;

      border-top: 0.5px solid rgba(255, 255, 255, 0.1);

      a {
        color: inherit;
        text-decoration: underline;

        @media (hover: hover) {
          &:hover {
            text-decoration: none;
          }
        }
      }

      span {
        &:nth-of-type(1) {
          opacity: 0.5;
          flex-grow: 1;
        }
        &:nth-of-type(2) {
        }
      }
    }
  }
`;
