import { css, Theme } from "@emotion/react";

import {
  baseBackgroundTextColor,
  baseTextColor,
  innerHTML,
  textStyles,
} from "styles/global/typography";

import { SystemTextProps } from "./index";

export const bySize =
  (
    size: NonNullable<SystemTextProps["size"]>,
    bold: NonNullable<SystemTextProps["bold"]>,
    uppercase: NonNullable<SystemTextProps["uppercase"]>
  ) =>
  (theme: Theme) =>
    css`
      ${textStyles(size, bold)(theme)}
      ${uppercase ? "text-transform: uppercase" : null}
    `;

export const byColor = (
  color: NonNullable<SystemTextProps["color"]>,
  inverted: NonNullable<SystemTextProps["inverted"]>
) => baseTextColor(color, inverted);

export const byBackgroundTextColor = (
  color: NonNullable<SystemTextProps["onBackgroundColor"]>
) => baseBackgroundTextColor(color);

export const byInnerHTML = innerHTML("system");

export const textAlign = (textAlign: SystemTextProps["textAlign"]) => css`
  text-align: ${textAlign};
`;

export const presLineBreaks = css`
  white-space: pre-wrap;
`;
