import { useEffect, useState } from "react";

import { useGetAnonymousQuery } from "apollo/queries";
import { anonymousVar } from "apollo/reactive";
import { hideBannerMessage, showBannerMessage } from "apollo/reactive/banner";
import { FEATURE_FLAG } from "config";
import useSession from "hooks/useSession";
import useStringFeatureFlag from "hooks/useStringFeatureFlag";
import { logError } from "services/logger";

const useEmergencyFlags = () => {
  const { refetchUser, isUser, isAnonymous } = useSession();
  const { refetch: refetchAnon } = useGetAnonymousQuery({
    skip: !isAnonymous,
    onCompleted: (data) => {
      if (!data?.anonymous) {
        logError({ message: `anonQuery.anonymous is null` });
      } else {
        anonymousVar(data.anonymous);
      }
    },
  });

  const emergencyMessage = useStringFeatureFlag(
    FEATURE_FLAG.EMERGENCY_ALERT
  ) as string;
  const [init, setInit] = useState(false);

  const displayEmergencyBanner = (bannerMessage: string, show: boolean) => {
    if (!bannerMessage) return;

    if (show) {
      showBannerMessage(bannerMessage, true, true, true);
    } else {
      hideBannerMessage(bannerMessage);
    }
  };

  const refetchEmergencyFlags = () => {
    if (isUser) refetchUser();
    if (isAnonymous) refetchAnon();
  };

  // clear out the old banner message, if it exists
  useEffect(() => {
    if (!init) return;

    displayEmergencyBanner(emergencyMessage, true);
    return () => {
      displayEmergencyBanner(emergencyMessage, false);
    };
  }, [init, emergencyMessage]);

  // check every minute if we are having polygon network issues
  useEffect(() => {
    const interval = setInterval(() => refetchEmergencyFlags(), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const showGlobalBanner = () => {
    setInit(true);
  };

  return {
    showGlobalBanner,
  };
};

export default useEmergencyFlags;
export type UseEmergencyFlags = ReturnType<typeof useEmergencyFlags>;
