import React, { memo, useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Token, TokenStatus, TokenType } from "apollo/graphql.generated";
import useCreatedCommunity from "apollo/hooks/useCreatedCommunity";
import { useCreatedCommunityTokensBySlugQuery } from "apollo/queries";
import ButtonLink from "components/ButtonLink";
import CardContainer from "components/CardContainer";
import { AppSEO } from "components/Layout/SEO";
import LoadingIcon from "components/LoadingIcon";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import {
  SYSTEM_PAGE_TITLE,
  APP_SUBDOMAIN,
  APP_URL,
  EXTERNAL_URL,
  MAXIMUM_TOKEN_LIMIT,
  FEATURE_FLAG,
} from "config";
import useAppLocation from "hooks/useAppLocation";
import useFeatureFlag from "hooks/useFeatureFlag";

import * as baseStyles from "../styles";

import { getOfferByTokenId } from "./NewManageToken/ManageMintedToken";
import TokenDetail from "./TokenDetail";
import MissingCommunityInformation from "./missingCommunityInformation";
import * as styles from "./styles";

const EditCommunityTokens = () => {
  const { getEditCommunityURL } = useAppLocation();
  const { communitySlug } = useParams();
  const [tokenLimitReached, setTokenLimitReached] = useState<boolean>(false);
  const enableMintTokenLimit = useFeatureFlag(
    FEATURE_FLAG.ENABLE_MINT_TOKEN_LIMIT
  );

  const {
    isDeployingContract,
    missingCommunityInformation,
    loading: communityLoading,
  } = useCreatedCommunity(communitySlug, { networkOnly: true });
  const { data, loading, refetch } = useCreatedCommunityTokensBySlugQuery({
    fetchPolicy: "network-only",

    variables: {
      slug: communitySlug as string,
      useChain: false,
      type: TokenType.All,
    },
  });

  const tokens: Token[] = data?.createdCommunityBySlug?.tokens || [];
  const offers = data?.createdCommunityBySlug?.offers || [];

  const hasPendingTokens = tokens.some(
    (token) => token.tokenStatus === TokenStatus.Pending
  );
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (hasPendingTokens) {
      interval = setInterval(() => refetch(), 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [hasPendingTokens, refetch]);

  useEffect(() => {
    setTokenLimitReached(
      tokens.length >= MAXIMUM_TOKEN_LIMIT && enableMintTokenLimit
    );
  }, [tokens, enableMintTokenLimit]);

  const handleOnUpdateItem = useCallback(() => {
    refetch();
  }, [refetch]);

  const NoTokens = () => {
    if (tokens.length > 0) return null;

    if (missingCommunityInformation) {
      return (
        <MissingCommunityInformation communitySlug={communitySlug as string} />
      );
    }

    if (isDeployingContract) {
      return (
        <CardContainer
          background="secondary"
          extraCss={styles.noTokensContainer}
        >
          <Title extraCss={styles.noTokensTitle} size="large">
            We’re Deploying Your Blockchain Contract...
          </Title>
          <Text>
            This should just take a few minutes. As soon as we’re done, you’ll
            be able to mint your first token.
          </Text>
        </CardContainer>
      );
    }

    return (
      <CardContainer background="secondary" extraCss={styles.noTokensContainer}>
        <Title extraCss={styles.noTokensTitle} size="small">
          You Haven&apos;t Minted Any Tokens Yet
        </Title>
        <Text>
          Get started by minting a membership token for your community. This is
          what people will need to access your community.
        </Text>
        <div css={styles.buttonContainer}>{mintNewTokenButton(false)}</div>
      </CardContainer>
    );
  };
  const mintNewTokenButton = (isHeader: boolean) => (
    <ButtonLink
      size="medium"
      fluid={!isHeader}
      extraCss={isHeader ? undefined : styles.bodyMintNewTokenButton}
      disable={tokenLimitReached}
      to={getEditCommunityURL(
        communitySlug ?? APP_SUBDOMAIN,
        APP_URL.editCommunity.mintTokens
      )}
    >
      Mint Token
    </ButtonLink>
  );

  if (loading || communityLoading) {
    return (
      <div css={styles.loadingIcon}>
        <LoadingIcon />
      </div>
    );
  }

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.EDITCOMMUNITY_TOKENS} />

      <div css={[styles.titleRow, baseStyles.layoutHeader]}>
        <div css={styles.titleButtonRow}>
          <Title size="large" extraCss={styles.title}>
            Tokens
          </Title>
          {!isDeployingContract &&
            !missingCommunityInformation &&
            mintNewTokenButton(true)}
        </div>
        {tokenLimitReached && (
          <div css={styles.limitReachTextContainer}>
            <Text
              extraCss={styles.limitReachText}
              size="xsmall"
              color="secondary"
            >
              {MAXIMUM_TOKEN_LIMIT} token maximum reached.{" "}
              <a href={EXTERNAL_URL.SUPPORT} target="_blank" rel="noreferrer">
                Contact support
              </a>{" "}
              for help.
            </Text>
          </div>
        )}
      </div>

      <NoTokens />
      <div css={styles.list}>
        {tokens.map((token, index) => (
          <TokenDetail
            key={`${token.tokenStatus}-${token.tokenId}`}
            token={token}
            slug={communitySlug || ""}
            communityId={data?.createdCommunityBySlug?.id || ""}
            offers={getOfferByTokenId(offers, token.tokenId)}
            onUpdate={handleOnUpdateItem}
            index={index}
          />
        ))}
      </div>
    </>
  );
};

export default memo(EditCommunityTokens);
