import React, { FC, memo } from "react";

import { useReactiveVar } from "@apollo/client";

import { ModalType, modalVar, resetModalVar } from "apollo/reactive";
import baseStyles from "components/Modals/ModalBody/styles.module.scss";
import ReactModalBase from "components/Modals/ReactModalBase";
import DeleteClaimPage from "containers/App/EditCommunity/Tokens/NewManageToken/ManageToken/ClaimPages/DeleteClaimPage";

const ConfirmDeleteClaimPageModal: FC = () => {
  const { showModal, data } = useReactiveVar(modalVar);

  const handleClose = () => {
    resetModalVar();
  };

  if (!data) {
    return <></>;
  }

  const contestId = data["contestId"] as string;
  const communityId = data["communityId"] as string;
  const claimPageName = data["claimPageName"] as string;

  return (
    <ReactModalBase
      className={`${baseStyles.modal} ${baseStyles.modalLarge}`}
      overlayClassName={baseStyles.modalOverlay}
      isOpen={showModal === ModalType.CONFIRM_DELETE_CLAIM_PAGE}
      closeTimeoutMS={800}
      ariaHideApp={false}
    >
      <DeleteClaimPage
        contestId={contestId}
        communityId={communityId}
        claimPageName={claimPageName}
        handleClose={handleClose}
      />
    </ReactModalBase>
  );
};

export default memo(ConfirmDeleteClaimPageModal);
