import { css, Theme } from "@emotion/react";

import { SystemCardContainerProps } from "./index";

export const containerStyles =
  (
    background: NonNullable<SystemCardContainerProps["background"]>,
    paletteBackground: SystemCardContainerProps["backgroundShade"],
    padding: NonNullable<SystemCardContainerProps["padding"]>,
    flushHorizontal: SystemCardContainerProps["flushHorizontal"],
    shadow?: SystemCardContainerProps["shadow"]
  ) =>
  (theme: Theme) =>
    css`
      padding: ${typeof padding === "number"
        ? `${padding}px`
        : theme.cardContainer.padding[padding]};
      ${flushHorizontal && `padding-left: 0`};
      ${flushHorizontal && `padding-right: 0`};
      background-color: ${paletteBackground
        ? theme.colorMap[paletteBackground.color][paletteBackground.shade]
        : theme.background.colors[background]};
      ${shadow && `box-shadow: ${theme.shadows[shadow]};`};
    `;

export const borderStyle =
  (
    border: SystemCardContainerProps["border"],
    borderRadius: SystemCardContainerProps["borderRadius"],
    borderColor: SystemCardContainerProps["borderColor"],
    borderWidth: SystemCardContainerProps["borderWidth"]
  ) =>
  (theme: Theme) => {
    return css`
      border-radius: ${theme.cardContainer.borderRadius[
        borderRadius || "medium"
      ]};
      ${optionalBorderStyle(border, borderColor, borderWidth)(theme)}
    `;
  };

const optionalBorderStyle =
  (
    border: SystemCardContainerProps["border"],
    borderColor: SystemCardContainerProps["borderColor"],
    borderWidth: SystemCardContainerProps["borderWidth"]
  ) =>
  (theme: Theme) => {
    if (border || borderWidth || borderColor) {
      return css`
        border-style: solid;
        border-width: ${borderWidth ? `${borderWidth}px` : "1px"};
        border-color: ${!borderColor || borderColor === "divider"
          ? theme.divider
          : theme.colorMap[borderColor].primary};
      `;
    }
  };
