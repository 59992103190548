import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { TokenBenefit, TokenType } from "apollo/graphql.generated";
import { ModalType, updateModalVar } from "apollo/reactive";
import Button from "components/Button";
import ImageUpload from "components/CommunityCreator/ImageUpload";
import TokenFileUpload from "components/CommunityCreator/TokenFileUpload";
import Layout from "components/Layout";
import TokenQuantityModal from "components/Modals/TokenQuantityModal";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";
import {
  getImageResolution,
  SUPPORTED_IMAGE_TYPES,
  SUPPORTED_MEDIA_TYPES,
} from "utils/fileImage";
import { Maybe } from "utils/types";

import InputComponent from "../../Shared/InputComponent";
import { MintForm } from "../types";
import { PAGE_TITLE_MAP } from "../utils";

import * as sharedStyles from "./../../Shared/styles";
import * as styles from "./styles";

export type DesignTokenProps = {
  onClick: () => void;
  onLoad: () => void;
  form: MintForm;
  setForm: Dispatch<SetStateAction<MintForm>>;
};

const MEMBERSHIP_NAME_DETAILS =
  "Name this membership token. For example, it could be a membership tier (Gold, Silver) or edition (Genesis Edition, Season 2).";
const COLLECTIBLE_NAME_DETAILS = "Name this collectible token.";
const DESCRIPTION_DETAILS =
  "Describe this token and its benefits. This description will appear most places people see your token, including on your About page and other services like OpenSea.";
const COMMUNITY_ACCESS_DETAILS =
  "Add additional custom benefits for people who own this token.";
const UPLOAD_DESCRIPTION =
  "Use a PNG, JPEG, MP4 or WEBM. We recommend a size of at least 2000x2000 pixels for images.";

const UPLOAD_IMAGE_DESCRIPTION =
  "Use a PNG or JPEG. We recommend a size of at least 2000x2000 pixels";

//TODO: Refactor component to be a react-hook-form
const DesignToken = ({ onClick, form, setForm, onLoad }: DesignTokenProps) => {
  const [previewError, setPreviewError] = useState<string>("");

  const enableVideoAsset = useFeatureFlag(
    FEATURE_FLAG.ENABLE_VIDEO_ASSET_ON_TOKEN
  );

  const enableMintingNonTransferable = useFeatureFlag(
    FEATURE_FLAG.ENABLE_MINTING_NON_TRANSFERABLE
  );

  const setName = (name: string) => {
    setForm({ ...form, tokenName: name });
  };

  const setDescription = (description: string) => {
    setForm({ ...form, tokenDescription: description });
  };

  const setMainFile = (file: Maybe<File>) => {
    if (file) {
      const tempUri = URL.createObjectURL(file);
      if (file.type.match(/video.*/)) {
        if (enableVideoAsset) {
          setForm({
            ...form,
            tokenFile: null,
            tokenFileUri: "",
            animationFile: file,
            animationFileUri: tempUri,
          });
        }
      } else {
        setForm({
          ...form,
          tokenFile: file,
          tokenFileUri: tempUri,
          animationFile: null,
          animationFileUri: "",
        });
      }
    } else {
      setForm({
        ...form,
        tokenFile: null,
        tokenFileUri: "",
        animationFile: null,
        animationFileUri: "",
      });
    }
  };

  const setPreviewFile = async (file: File) => {
    setPreviewError("");
    const tempUri = URL.createObjectURL(file);
    setForm({ ...form, tokenFile: file, tokenFileUri: tempUri });
  };

  const removePreviewFile = async () => {
    setPreviewError("");
    setForm({ ...form, tokenFile: null, tokenFileUri: "" });
  };

  const setBenefits = (benefits: TokenBenefit[]) => {
    setForm({ ...form, benefits });
  };

  const setCustomBenefits = (benefits: TokenBenefit[]) => {
    setForm({ ...form, customBenefits: benefits });
  };

  const setCount = (count: number) => {
    setForm({ ...form, tokenCount: count });
  };

  const setNonTransferability = (nonTransferable: boolean) => {
    setForm({ ...form, nonTransferable });
  };

  const minSize = 400;
  const maxImageMegabytesSize = 2;
  const maxVideoMegabytesSize = 50;

  const validateFile = async (file: File) => {
    if (enableVideoAsset) {
      if (!SUPPORTED_MEDIA_TYPES.includes(file.type)) {
        return "File must have either a PNG, JPEG, MP4 or WEBM format.";
      }
    } else if (!SUPPORTED_IMAGE_TYPES.includes(file.type)) {
      return "File must have either a PNG or JPEG format.";
    }

    const isImage = file?.type.match(/image.*/);

    if (isImage) {
      const { width, height } = await getImageResolution(file);

      if (width < minSize || height < minSize) {
        return `The resolution must be at least ${minSize}x${minSize}. Current resolution is ${width}x${height}.`;
      }
    }

    const maxSizeInBytes =
      (isImage ? maxImageMegabytesSize : maxVideoMegabytesSize) * 1000000;
    if (file.size > maxSizeInBytes) {
      return `The file size cannot be greater than ${
        isImage ? maxImageMegabytesSize : maxVideoMegabytesSize
      }MB.`;
    }

    return null;
  };

  const handlePreviewFileReject = useCallback(async (error: string) => {
    setPreviewError(error);
  }, []);

  useEffect(() => {
    onLoad();
  }, []);

  const LearnMoreButton = () => {
    return (
      <button
        onClick={() => {
          updateModalVar({
            showModal: ModalType.TOKEN_QUANTITY_LEARN_MORE,
          });
        }}
      >
        <Text as="span" extraCss={styles.learnMore}>
          Learn more
        </Text>
      </button>
    );
  };

  const TokenNumberDetails = () => {
    return (
      <div>
        Choose how many tokens you want to mint. {<LearnMoreButton />} about
        things to consider when choosing a number of tokens.
      </div>
    );
  };

  const ContinueButton = () => {
    return (
      <div css={styles.mintButtonContainer}>
        <Button
          disabled={
            !(
              form.tokenName &&
              form.tokenDescription &&
              form.tokenCount &&
              form.tokenFileUri
            )
          }
          onClick={onClick}
          extraCss={sharedStyles.mintButton}
        >
          Continue
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Title size="large" extraCss={styles.title}>
        Design Your {form.tokenType ? PAGE_TITLE_MAP[form.tokenType] : ""} Token
      </Title>

      <Layout
        css={styles.layout}
        backgroundShow={true}
        backgroundColor="primary"
        showHeader={false}
        showFooter={false}
      >
        <div css={styles.tokenDescriptionCol}>
          <InputComponent
            type="text-field"
            title="Token Name"
            description={
              form.tokenType === TokenType.Membership
                ? MEMBERSHIP_NAME_DETAILS
                : COLLECTIBLE_NAME_DETAILS
            }
            maxLen={36}
            placeholder="Token Name"
            value={form.tokenName}
            setValue={setName}
          />
          {form.tokenType !== TokenType.Collectible && (
            <InputComponent
              type="checkbox-list"
              title="Community Access"
              description={COMMUNITY_ACCESS_DETAILS}
              value={form.benefits}
              setValue={setBenefits}
            />
          )}
          {form.tokenType !== TokenType.Collectible && (
            <InputComponent
              type="options-list"
              title="Custom Benefits"
              description={COMMUNITY_ACCESS_DETAILS}
              value={form.customBenefits}
              setValue={setCustomBenefits}
            />
          )}
          <InputComponent
            type="text-area"
            title="Token Description"
            description={DESCRIPTION_DETAILS}
            maxLen={720}
            placeholder="Describe This Token"
            value={form.tokenDescription}
            setValue={setDescription}
          />
          <InputComponent
            type="number-field"
            title="Number Of Tokens"
            description={<TokenNumberDetails />}
            placeholder="How many tokens do you want to mint?"
            min={1}
            max={10000}
            value={form.tokenCount}
            setValue={setCount}
            extraCss={styles.lastInput}
          />
          {enableMintingNonTransferable && (
            <InputComponent
              type="switch-field"
              title="Advanced Options"
              advancedOptionsDescription="Make this token non-transferable"
              value={form.nonTransferable}
              setValue={setNonTransferability}
              marginBottom={false}
            />
          )}
        </div>
        <div css={styles.tokenArtworkCol}>
          <TokenFileUpload
            title={enableVideoAsset ? "Token Image or Video" : "Token Artwork"}
            imageUri={form.tokenFileUri}
            animationUri={form.animationFileUri}
            uploadDescription={
              enableVideoAsset ? UPLOAD_DESCRIPTION : UPLOAD_IMAGE_DESCRIPTION
            }
            setFile={setMainFile}
            validateFile={validateFile}
          />
          {form.animationFileUri && (
            <ImageUpload
              label="Preview Image"
              labelType="title"
              dragActiveDescription="Drop here ..."
              primaryDescription="Add Preview Image"
              secondaryDescription="Provide an image (PNG or JPG) to show in some areas of the app."
              onFileAccept={setPreviewFile}
              onFileReject={handlePreviewFileReject}
              hasRemove
              onRemove={removePreviewFile}
              error={previewError}
              imageUrl={form.tokenFileUri}
              maxSize={2}
              minWidth={minSize}
              minHeight={minSize}
              imageBorder="square"
              extraCss={styles.previewImage}
            />
          )}
        </div>
        <div css={styles.continueButton}>
          <ContinueButton />
        </div>
      </Layout>
      <TokenQuantityModal />
    </div>
  );
};

export default memo(DesignToken);
