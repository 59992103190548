import { css, Theme } from "@emotion/react";

import { fullGrid, gridColumn, section, topMargin } from "styles/global/layout";

export const layout = css`
  ${section};
  ${fullGrid};
  ${topMargin("13/64px")};
`;

export const learnMore = (theme: Theme) => css`
  color: ${theme.colorMap.accent.primary};

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const mintButtonContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const continueButton = (theme: Theme) => css`
  ${gridColumn([1, 8])};

  ${theme.mediaQueries.smallOnly} {
    order: 3;
  }
`;

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const title = css`
  ${topMargin("11/48px")};
  text-align: center;
`;

export const tokenDescriptionCol = (theme: Theme) => css`
  ${gridColumn([1, 8])};

  ${theme.mediaQueries.smallOnly} {
    order: 1;
  }
`;

export const lastInput = css`
  margin-bottom: 0;
`;

export const tokenArtworkCol = (theme: Theme) => css`
  ${gridColumn([8, 13])};

  ${theme.mediaQueries.smallOnly} {
    order: 2;
  }
`;

export const previewImage = css`
  ${topMargin("10/40px")};
`;
