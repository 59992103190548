import { css, Theme } from "@emotion/react";

import { topMargin } from "styles/global/layout";
import { parseBorderType } from "styles/theme/communityTheme";

export const tooltip = css`
  display: flex;
  flex-direction: column;
`;

export const header = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const description = css`
  ${topMargin("2/4px")}
  text-align: center;
`;

export const token = css`
  ${topMargin("6/16px")}
`;

export const link = css`
  display: flex;
`;

export const tokenDetails = css`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
`;

export const tokenImageWrapper = css`
  width: 96px;
  height: 96px;
`;

export const tokenImage = (theme: Theme) => css`
  width: 96px;
  height: 100%;
  object-fit: contain;
  box-shadow: ${theme.shadows["sm"]};
  border-radius: ${parseBorderType(theme.community.global.containerBorderType)};
`;

export const tokenDescription = css`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const arrowWrapper = css`
  display: flex;
  align-items: center;
`;

export const arrow = css`
  width: 12px;
  height: 12px;

  path {
    fill: currentColor;
  }
`;
