import React, { FC } from "react";

import { FEATURE_FLAG } from "config";
import ReleaseParty from "containers/CommunityV3/ReleaseParty";
import NotFound from "containers/Error/NotFound";
import useFeatureFlag from "hooks/useFeatureFlag";

const ReleasePartySwitch: FC = () => {
  const EnableReleaseParties = useFeatureFlag(
    FEATURE_FLAG.RELEASE_PARTY_FEATURE_AVAILABLE
  );

  if (!EnableReleaseParties) {
    return <NotFound />;
  }

  return <ReleaseParty />;
};

export default ReleasePartySwitch;
