import React, { FC, memo, PropsWithChildren, HTMLProps } from "react";

import { Interpolation, Theme, css } from "@emotion/react";

import Text from "components/Typography/Text";
import {
  LabelSizesKeys,
  OnBackgroundTextColorsKeys,
  TextColorKeys,
} from "styles/theme";
import { TextTag } from "styles/theme/theme.types";

export type LabelProps = {
  size?: LabelSizesKeys;
  color?: TextColorKeys;
  onBackgroundColor?: OnBackgroundTextColorsKeys;
  inverted?: boolean;
  uppercase?: boolean;
  as?: TextTag;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
  /**
   * @deprecated Use "extraCss" instead.
   */
  additionalCss?: Interpolation<Theme>;
} & Pick<HTMLProps<HTMLLabelElement>, "htmlFor">;

const base = (theme: Theme) =>
  css`
    font-family: ${theme.label.fontFamily};
    font-weight: ${theme.label.fontWeight};
    letter-spacing: ${theme.label.letterSpacing};
  `;

const Label: FC<PropsWithChildren<LabelProps>> = ({
  size = "medium",
  color = "primary",
  onBackgroundColor,
  inverted = false,
  uppercase = true,
  as = "div",
  extraCss,
  additionalCss,
  children,
  ...rest
}) => (
  <Text
    extraCss={[base, additionalCss, extraCss]}
    size={size}
    color={color}
    onBackgroundColor={onBackgroundColor}
    inverted={inverted}
    as={as}
    uppercase={uppercase}
    bold
    {...rest}
  >
    {children}
  </Text>
);

export default memo(Label);
