import { memo, FC, ReactNode } from "react";

import { Elements } from "@stripe/react-stripe-js";

import Button from "components/Button";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { EXTERNAL_URL } from "config";

import useStripeElementsLogic from "./logic";
import * as styles from "./styles";

export type Props = {
  children: ReactNode;
};

const StripeElements: FC<Props> = ({ children }) => {
  const refreshPage = () => window.location.reload();

  const { view, stripeElementsOptions, stripeInit } = useStripeElementsLogic();

  switch (view) {
    case "ready":
      return (
        <Elements options={stripeElementsOptions} stripe={stripeInit}>
          {children}
        </Elements>
      );

    case "apiError":
      return (
        <div css={styles.errorWrapper}>
          <Title size="small">Something Went Wrong</Title>
          <Text as="p">
            Unfortunately, an error occurred while processing your order. Please
            try again later.
          </Text>
        </div>
      );

    case "paymentIntentError":
      return (
        <div css={styles.errorWrapper}>
          <Title size="small">Something Went Wrong</Title>
          <Text as="p">
            Unfortunately an error occurred while processing your order. Please
            try again.
          </Text>
          <Button onClick={refreshPage}>Try Again</Button>
        </div>
      );

    case "emptyCart":
      return null;

    case "sardineSDKNotLoaded":
      return (
        <div css={styles.errorWrapper}>
          <Title size="small">Something Went Wrong</Title>
          <Text as="p">
            Our sytem detected some irregular activity and we’re unable to
            process your order at this time.
          </Text>
          <Text as="p">
            Please disable your ad blocker or VPN and{" "}
            <button onClick={refreshPage}>try again</button>. If the issue
            persists please{" "}
            <a href={EXTERNAL_URL.SUPPORT} target="_blank" rel="noreferrer">
              contact support
            </a>
            .
          </Text>
        </div>
      );

    case "sardineLevelIssue":
      return (
        <div css={styles.errorWrapper}>
          <Title size="small">Something Went Wrong</Title>
          <Text as="p">
            Our sytem detected some irregular activity and we’re unable to
            process your order at this time. Please{" "}
            <button onClick={refreshPage}>try again</button> or{" "}
            <a href={EXTERNAL_URL.SUPPORT} target="_blank" rel="noreferrer">
              contact support
            </a>
            .
          </Text>
        </div>
      );

    case "loading":
    default:
      return null;
  }
};

export default memo(StripeElements);
