import React, { FC, memo } from "react";

import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";

const WithNoContract: FC = () => {
  return (
    <CardContainer border={true} background={"primary"}>
      <Text as="p" color="secondary">
        Your contract is being created...
      </Text>
    </CardContainer>
  );
};

export default memo(WithNoContract);
