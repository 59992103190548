import React, { memo, FC } from "react";

import ErrorLinkContainer from "components/ErrorLinkContainer";
import Text from "components/Typography/Text";
import { useAuthForm } from "hooks/useAuthForm";
import { AuthErrorType } from "utils/errors/authErrors";
import { switchToCorrectChainNetwork } from "utils/metamask";

import * as styles from "./styles";
import { Props as AuthErrorViewProps } from "./types";

const AuthErrorView: FC<AuthErrorViewProps> = ({
  additionalCss,
  onClick,
  onNoSuchUserResolve,
  onExistingUserResolve,
}) => {
  const {
    state: { authError },
    dispatch,
  } = useAuthForm();

  const METAMASK_DOWNLOAD_URL = "https://metamask.io/download/";

  const onClose = () => dispatch({ type: "clearError" });
  const onSwitchChain = async () => {
    const switched = await switchToCorrectChainNetwork();
    if (switched) dispatch({ type: "clearError" });
  };

  type UrlErrorProps = { baseText: string; url: string; urlText: string };
  const UrlError: FC<UrlErrorProps> = ({ baseText, url, urlText }) => {
    return (
      <>
        <Text additionalCss={styles.errorText} size="small">
          {baseText}
          <Text additionalCss={styles.errorText} size="small" bold>
            <a
              href={url}
              target={"_blank"}
              rel="noreferrer"
              css={styles.metaMaskErrorText}
            >
              {urlText}
            </a>
          </Text>
        </Text>
      </>
    );
  };

  type SimpleErrorProps = { text: string };
  const SimpleError: FC<SimpleErrorProps> = ({ text }) => {
    return (
      <>
        <Text additionalCss={styles.errorText} size="small">
          {text}
        </Text>
      </>
    );
  };

  type NoSuchUserErrorProps = { text: string };
  const NoSuchUserError: FC<NoSuchUserErrorProps> = ({ text }) => {
    return (
      <>
        <Text additionalCss={styles.errorText} size="small">
          {text}
          <Text additionalCss={styles.errorTextUrl} size="small" bold>
            <a
              css={styles.navLink}
              rel="noreferrer"
              onClick={onNoSuchUserResolve}
            >
              Create an account first.
            </a>
          </Text>
        </Text>
      </>
    );
  };

  type ExistingUserErrorProps = { text: string };
  const ExistingUserError: FC<ExistingUserErrorProps> = ({ text }) => {
    return (
      <>
        <Text additionalCss={styles.errorText} size="small">
          {text}
          <Text additionalCss={styles.errorTextUrl} size="small" bold>
            <a
              css={styles.navLink}
              rel="noreferrer"
              onClick={onExistingUserResolve}
            >
              Sign into an account.
            </a>
          </Text>
        </Text>
      </>
    );
  };

  type SwitchNetworkErrorProps = { chain: string };
  const SwitchNetworkError: FC<SwitchNetworkErrorProps> = ({ chain }) => {
    return (
      <>
        <Text additionalCss={styles.errorText} size="small">
          {`Switch MetaMask to the ${chain} network and try again.`}
          <Text additionalCss={styles.errorTextUrl} size="small" bold>
            <a css={styles.navLink} rel="noreferrer" onClick={onSwitchChain}>
              {`Use ${chain} on Metamask`}
            </a>
          </Text>
        </Text>
      </>
    );
  };

  const MetaMaskMissingPlugInError = () => {
    return (
      <UrlError
        baseText={"Install MetaMask for your browser."}
        url={METAMASK_DOWNLOAD_URL}
        urlText={"Download browser plugin"}
      />
    );
  };

  if (!authError) return null;
  return (
    <ErrorLinkContainer
      onClick={onClick}
      onClose={onClose}
      additionalCss={additionalCss}
    >
      {authError.type == AuthErrorType.METAMASK_NOT_POLYGON_NETWORK && (
        <SwitchNetworkError chain={"Polygon"} />
      )}
      {authError.type == AuthErrorType.METAMASK_NOT_TESTNET_NETWORK && (
        <SwitchNetworkError chain={"Mumbai"} />
      )}
      {authError.type == AuthErrorType.METAMASK_NOT_LOCAL_NETWORK && (
        <SwitchNetworkError chain={"Local"} />
      )}
      {authError.type == AuthErrorType.METAMASK_MISSING_PLUGIN && (
        <MetaMaskMissingPlugInError />
      )}
      {(authError.type == AuthErrorType.GRAPHQL_LOGIN_NO_SUCH_USER_MAGIC ||
        authError.type ==
          AuthErrorType.GRAPHQL_LOGIN_NO_SUCH_USER_METAMASK) && (
        <NoSuchUserError text={authError.type} />
      )}
      {(authError.type == AuthErrorType.GRAPHQL_CREATE_EXISTING_USER_MAGIC ||
        authError.type ==
          AuthErrorType.GRAPHQL_CREATE_EXISTING_USER_METAMASK) && (
        <ExistingUserError text={authError.type} />
      )}
      {(authError.type == AuthErrorType.METAMASK_USER_DECLINED_SIGNATURE ||
        authError.type == AuthErrorType.METAMASK_NOT_SIGNED_IN ||
        authError.type == AuthErrorType.METAMASK_GENERAL_ERROR ||
        authError.type == AuthErrorType.BACKEND_ERROR ||
        authError.type == AuthErrorType.REQUIRE_MAGIC_LINK ||
        authError.type == AuthErrorType.REQUIRE_METAMASK) && (
        <SimpleError text={authError.type} />
      )}

      {!authError.type && <SimpleError text={"An Error Occurred"} />}
    </ErrorLinkContainer>
  );
};

export default memo(AuthErrorView);
