import { FC, memo } from "react";

import * as styles from "../BaseButtonLink/styles";
import { AnchorProps } from "../BaseButtonLink/types";

const CommunityButtonExternalLink: FC<AnchorProps> = ({
  children,
  className = "",
  size = "large",
  color = "onPageBackgroundBackgroundColor",
  header,
  extraCss,
  ...rest
}) => (
  <a
    className={className}
    css={[
      styles.base,
      styles.bySize(size, !!header),
      styles.byColor(color),
      extraCss,
    ]}
    {...rest}
  >
    <div css={styles.textWrapper}>
      {header && <span css={styles.headerText}>{header}</span>}
      <span css={[styles.buttonText]}>{children}</span>
    </div>
  </a>
);

export default memo(CommunityButtonExternalLink);
