import { css, Theme } from "@emotion/react";

export const label = (grouped: boolean, disabled: boolean) => (theme: Theme) =>
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    ${grouped &&
    css`
      :not(:first-of-type) {
        border-top: 1px solid ${theme.divider};
      }
    `}
    ${!disabled &&
    css`
      cursor: pointer;
    `}
  `;

export const labelTextContainer = css`
  > svg {
    width: 20px;
    height: 20px;
  }
`;

export const labelText = css`
  margin-left: 12px;
`;

export const input = (checkCircleUrl: string) => (theme: Theme) =>
  css`
    appearance: none;
    border: 1px solid ${theme.divider};
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    place-content: center;

    ::before {
      content: "";
      width: 24px;
      height: 24px;
      opacity: 0;
      transition: 120ms opacity ease-in-out;
      background: url(${checkCircleUrl});
      background-size: 24px;
    }

    :checked::before {
      opacity: 1;
    }
  `;
