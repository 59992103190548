import { css, Theme } from "@emotion/react";

import { CreatorAvatar } from "apollo/graphql.generated";
import { VerifiedCreator as VerifiedIcon } from "assets/icons.generated";

import DefaultCommunityImage from "../../../../assets/images/default-community-avatar.png";

const creatorStyle = (theme: Theme) => css`
  display: flex;
  align-items: center;
  margin-right: 5px;
  flex-shrink: 0;
  img {
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    width: 24px;
    height: 24px;
  }

  span {
    font-family: ${theme.text.fontFamily.primary};
    font-size: ${theme.text.sizes.medium};
    margin: 0 6px;
  }
`;

export type CreatorAvatarProps = {
  avatar?: CreatorAvatar;
  showVerified?: boolean;
};

export const CreatorTitle = ({
  avatar,
  showVerified = true,
}: CreatorAvatarProps) => {
  return (
    <h3 css={creatorStyle}>
      <img
        src={avatar?.imageUrl || DefaultCommunityImage}
        alt={avatar?.name || ""}
      />
      <span>{avatar?.name}</span>
      {showVerified && <VerifiedIcon />}
    </h3>
  );
};
