import {
  Contest,
  Offer,
  TokenBenefit,
  TokenType,
} from "apollo/graphql.generated";
import {
  UserTokensFragment,
  OfferDataFragment,
  TokenDataFragment,
} from "apollo/queries";
import { Maybe } from "utils/types";

export type OfferMethod = Pick<
  Offer,
  "id" | "currency" | "price" | "crypto"
> & {
  kind: "Offer";
};
export type ContestMethod = Pick<Contest, "id"> & {
  kind: "Contest";
};
export type DistributionMethod = OfferMethod | ContestMethod;

export type DistributableToken = {
  id: string;
  total: number;
  available: number;
  name?: Maybe<string>;
  description?: Maybe<string>;
  animationUri?: Maybe<string>;
  uri?: Maybe<string>;
  type: TokenType;
  benefits: TokenBenefit[];
  userHasIt: boolean;
  userHasPending: boolean;
};

export type TimedAvailability = {
  start: Date;
  end: Date;
};

export type Distribution = {
  distributionMethod: DistributionMethod;
  tokens: DistributableToken[];
  timedAvailability?: TimedAvailability;
  redirectUrl?: string;
};

const toDistributableTokens = (
  tokens: TokenDataFragment[],
  userTokens?: UserTokensFragment,
  communityId?: string
): DistributableToken[] =>
  tokens.map((token) => ({
    id: token.tokenId,
    total: token.supply ?? 0,
    available: token.creatorBalance ?? 0,
    name: token.name,
    description: token.description,
    uri: token.uri,
    animationUri: token.animationUri,
    type: token.tokenType,
    benefits: token.benefits ?? [],
    userHasIt:
      userTokens?.tokens.some(
        (userToken) =>
          userToken.community.id === communityId &&
          userToken.tokenId.toString() === token.tokenId &&
          (userToken.balance ?? 0) > 0
      ) || false,
    userHasPending:
      userTokens?.pendingTokens.some(
        (userToken) =>
          userToken.community.id === communityId &&
          userToken.tokenId.toString() === token.tokenId
      ) || false,
  }));

const toOfferDistribution = (
  offers: OfferDataFragment[],
  userTokens?: UserTokensFragment,
  communityId?: string,
  timedAvailability?: TimedAvailability,
  redirectUrl?: string
): Distribution[] =>
  offers.map((offer) => ({
    distributionMethod: {
      ...offer,
      kind: "Offer",
    },
    tokens: toDistributableTokens(offer.tokens || [], userTokens, communityId),
    timedAvailability,
    redirectUrl,
  }));

const findMainToken = (tokens: DistributableToken[]) =>
  tokens.find((token) => token.type === TokenType.Membership) || tokens[0];

export { toOfferDistribution, findMainToken };
