import { css, Theme } from "@emotion/react";

export const container = css``;

export const modalContainer = css`
  padding: 0 40px 40px 40px;
`;

export const icon = css`
  width: 32px;
  height: auto;
  margin: 0 auto 12px;
  display: block;
  path {
    fill: black;
  }
`;

export const title = css`
  text-align: center;
  margin: 0 0 32px 0;
`;

export const checkEmailTitle = css`
  text-align: center;
  margin: 0 0 8px 0;
`;

export const description = css`
  text-align: center;
  margin: 0 0 32px 0;
  line-height: 24px;

  strong {
    font-weight: 600;
  }
`;

export const loading = (theme: Theme) => css`
  margin: 0 auto;
  margin-bottom: ${theme.verticalSpaces.large[5]};
  display: block;
`;

export const emailInput = css``;

export const emailInputContainer = (theme: Theme) => css`
  margin-bottom: ${theme.verticalSpaces.large[7]};

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: ${theme.verticalSpaces.large[5]};
  }
`;

export const linkContainer = css`
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
`;

export const navLink = (theme: Theme) => css`
  color: ${theme.text.colors.onBackground["primary"]};
  font-weight: bold;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const button = css``;

export const errorContainer = (theme: Theme) => css`
  margin-top: ${theme.verticalSpaces.large[5]};

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${theme.verticalSpaces.large[3]};
  }
`;
