import React, { FC, memo, useCallback, useEffect, useState } from "react";

import { useUploadCreatorAvatarImageMutation } from "apollo/queries";
import ImageUpload from "components/CommunityCreator/ImageUpload";
import LoadingIcon from "components/LoadingIcon";
import { logError } from "services/logger";

export interface Props {
  communityId: string;
  creatorAvatarValue: string;
  onSetAvatar: (avatar: string) => void;
}

const CreatorAvatarUpdate: FC<Props> = ({
  communityId,
  creatorAvatarValue,
  onSetAvatar,
}) => {
  const [doUploadCreatorAvatarImage] = useUploadCreatorAvatarImageMutation();
  const [creatorAvatar, setCreatorAvatar] =
    useState<string>(creatorAvatarValue);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setCreatorAvatar(creatorAvatarValue);
  }, [creatorAvatarValue]);

  const handleFileAccept = useCallback(
    async (file: File) => {
      try {
        setLoading(true);
        setError("");
        const response = await doUploadCreatorAvatarImage({
          variables: {
            asset: file,
            communityId: communityId,
            mime: file.type,
          },
        });
        const avatar =
          response.data?.uploadCreatorAvatarImage.creatorAvatar?.imageUrl || "";
        setCreatorAvatar(avatar);
      } catch (error) {
        const message = "Upload failed";
        setError(message);
        await logError({
          error,
          message: `[handleCreatorAvatarChange] ${message}`,
        });
      } finally {
        setLoading(false);
      }
    },
    [communityId]
  );

  const handleFileReject = useCallback(async (error: string) => {
    setLoading(false);
    setError(error);
  }, []);

  useEffect(() => {
    onSetAvatar(creatorAvatar);
  }, [creatorAvatar]);

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <ImageUpload
      error={error}
      id="creatorAvatarImage"
      label="Creator Avatar"
      labelType="title"
      dragActiveDescription="Drop here ..."
      primaryDescription="Drag and Drop your Creator Avatar here"
      secondaryDescription={`This image represents you, your band, organization, etc. \nUse a PNG or JPG file, recommended dimensions are 400px by 400px.`}
      onFileAccept={handleFileAccept}
      onFileReject={handleFileReject}
      hasRemove={false}
      imageUrl={creatorAvatar}
      maxSize={2}
    />
  );
};

export default memo(CreatorAvatarUpdate);
