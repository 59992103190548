import { memo } from "react";

import { Interpolation, Theme } from "@emotion/react";
import xss from "xss";

import { TextTag } from "styles/theme/theme.types";

export type SafeInnerHTMLProps = {
  html: string;
  as?: TextTag;
  css?: Interpolation<Theme>;
};

export const SafeInnerHTML = memo(
  ({ html, as = "div", ...props }: SafeInnerHTMLProps) => {
    const Tag = as as keyof JSX.IntrinsicElements;

    return <Tag {...props} dangerouslySetInnerHTML={{ __html: xss(html) }} />;
  }
);
