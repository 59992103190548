import { BenefitType, TokenType } from "apollo/graphql.generated";
import { ReactComponent as GenericLogo } from "assets/icons/benefit-generic.svg";
import { ReactComponent as DiscordLogo } from "assets/icons/discord_logo_black.svg";
import { ReactComponent as MembershipKey } from "assets/icons/membership-key.svg";
import { ReactComponent as ContentFeedAccess } from "assets/icons/posts.svg";
import { ReactComponent as ShopLogo } from "assets/icons/shop-logo.svg";
import { COMMUNITY_URL } from "config";

import * as styles from "./styles";

export const getBenefitTitle = (
  benefitType: TokenType | BenefitType,
  creatorName = ""
) => {
  switch (benefitType) {
    case TokenType.Membership:
      return `Access to the ${creatorName} community`;
    case BenefitType.DISCORD:
      return `Access to the ${creatorName} Discord`;
    case BenefitType.SHOPIFY:
      return `Access to the ${creatorName} Shop`;
    case BenefitType.CONTENT_FEED:
      return `Exclusive audio, video, and behind-the-scenes content`;
    case BenefitType.CUSTOM:
      return `Custom benefit`;
    default:
      return "Unknown benefit";
  }
};

export const getBenefitDescription = (
  benefitType: TokenType | BenefitType,
  customBenefitName?: string | null
) => {
  switch (benefitType) {
    case TokenType.Membership:
      return "Token owners will be able to access this community";
    case BenefitType.DISCORD:
      return `People who own this token will be able to access your linked Discord server`;
    case BenefitType.SHOPIFY:
      return `People who own this token will be able to access to your shop`;
    case BenefitType.CONTENT_FEED:
      return `You’ll post audio, video, or behind-the-scenes content in the community`;
    case BenefitType.CUSTOM:
      return customBenefitName || "";
    default:
      return "Unknown benefit";
  }
};

export const getBenefitDescriptionDetailed = (
  benefitType: TokenType | BenefitType,
  creatorName = "",
  customBenefitName?: string | null
) => {
  switch (benefitType) {
    case TokenType.Membership:
      return `Token owners will be able to access ${creatorName} community including anything you post`;
    case BenefitType.DISCORD:
      return `Access to the ${creatorName} Discord`;
    case BenefitType.SHOPIFY:
      return `Access to the ${creatorName} Shop`;
    case BenefitType.CONTENT_FEED:
      return `Exclusive audio, video, and behind-the-scenes content`;
    case BenefitType.RELEASE_PARTY:
      return "Can write comments, and see the creator's secret message";
    case BenefitType.CUSTOM:
      return customBenefitName || "";
    default:
      return "Unknown benefit";
  }
};

export const getIcon = (benefitType: TokenType | BenefitType) => {
  switch (benefitType) {
    case TokenType.Membership:
      return <MembershipKey css={styles.icon} />;
    case BenefitType.DISCORD:
      return <DiscordLogo css={styles.icon} />;
    case BenefitType.SHOPIFY:
      return <ShopLogo css={styles.icon} />;
    case BenefitType.CONTENT_FEED:
      return <ContentFeedAccess css={styles.icon} />;
    case BenefitType.RELEASE_PARTY:
    case BenefitType.CUSTOM:
      return <GenericLogo css={styles.icon} />;
    default:
      return null;
  }
};

export const getBenefitShortTitle = (
  benefitType: TokenType | BenefitType,
  customBenefitName?: string | null
) => {
  switch (benefitType) {
    case BenefitType.DISCORD:
      return "Discord Access";
    case BenefitType.SHOPIFY:
      return "Shop Access";
    case BenefitType.CONTENT_FEED:
      return "See All Posts";
    case BenefitType.CUSTOM:
      return customBenefitName;
    case BenefitType.RELEASE_PARTY:
      return "Release party";
    default:
      return "Unknown benefit";
  }
};

export const getBenefitCommunityLink = (benefitType: BenefitType) => {
  switch (benefitType) {
    case BenefitType.DISCORD:
      return COMMUNITY_URL.discord;
    case BenefitType.SHOPIFY:
      return COMMUNITY_URL.shop;
    case BenefitType.CONTENT_FEED:
      return COMMUNITY_URL.posts;
    default:
      return null;
  }
};
