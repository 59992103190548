import { useReactiveVar } from "@apollo/client";

import type { GetAnonymousQuery } from "apollo/queries";
import { anonymousVar } from "apollo/reactive";
import useSession, { CurrentUser } from "hooks/useSession";

type AnonymousUserFlagVariations =
  GetAnonymousQuery["anonymous"]["flagVariations"];
type CurrentUserFlagVariations = CurrentUser["flagVariations"];

/**
 * only allow the hook to accept keys that are present in FlagVariation queries
 * @see https://stackoverflow.com/questions/47375916/typescript-how-to-create-type-with-common-properties-of-two-types/47379147
 */
type AllUserFlagVariations = {
  [K in keyof AnonymousUserFlagVariations & keyof CurrentUserFlagVariations]:
    | AnonymousUserFlagVariations[K]
    | CurrentUserFlagVariations[K];
};

type AllowedUserFlags = Omit<AllUserFlagVariations, "__typename">;

/**
 * useFeatureFlag takes the name of a feature flag and returns the active
 * variation for that flag. If the user is not logged in, the anonymous evaluation
 * is used, otherwise it is evaluated based on the current user's id and email.
 *
 * Update AllUserFlagVariations in flagVariations.graphql to use in this hook.
 */
const useFeatureFlag = (flag: keyof AllowedUserFlags): boolean => {
  const { user } = useSession();
  const anonymous = useReactiveVar(anonymousVar);

  /**
   * anonymous can be null, since we may be using this hook in other hooks
   * this means at app start, we have a short window of time where we default to false for feature flgs
   * before we finish fetching the anonymous query
   *
   * since pages don't run until useBootstrap() is finished, this shouldn't be an issue unless we try to use
   * feature flags during the bootstrap process
   */
  const variations = user?.flagVariations ?? anonymous?.flagVariations;
  if (!variations) return false;
  return variations[flag] as boolean;
};

export default useFeatureFlag;
export type UseFeatureFlag = ReturnType<typeof useFeatureFlag>;
