import { css, Theme } from "@emotion/react";

export const header = css`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const headerTitle = css`
  display: flex;
  align-items: center;

  > p {
    margin-left: 5px;
  }
`;

export const headerOptions = css`
  position: relative;
  display: flex;
  font-size: 14px; // overwrite font-size for not being part of the theme
  line-height: 1;
`;

export const postText = css`
  white-space: pre-line;
`;

export const editStyles = css`
  flex-grow: 1;
  text-align: right;
  margin-left: 10px;

  svg:not(:first-of-type) {
    margin-left: 2px;
  }
`;

export const optionsContainer = css`
  position: absolute;
  width: 240px;
  right: 0;
  z-index: 5;
  top: 20px;

  > div {
    border-radius: 8px;
  }

  button {
    p {
      margin: 13px 0;
    }
  }
`;

export const optionsCard = css`
  padding: 4px;
`;

export const optionButton = (theme: Theme) =>
  css`
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      background-color: ${theme.background.colors.secondary};
    }

    width: 100%;
    border-radius: 8px;

    svg {
      margin: 0 10px;
    }
  `;

export const dangerButton = (theme: Theme) => css`
  p {
    color: ${theme.colorMap.destructive.default};
  }
`;

export const modalHeader = css`
  margin-bottom: 35px;
`;

export const modalButtons = css`
  display: flex;
  flex-direction: row;

  button {
    height: 64px;
    width: 236px;
    left: 0px;
    top: 0px;
    border-radius: 99px;
    padding: 0px, 16px, 0px, 16px;
  }
`;

export const confirmDeletePostModal = css`
  text-align: center;
`;

export const deletePostConfirmBtn = (theme: Theme) => css`
  margin-left: 20px;
  background-color: ${theme.colorMap.destructive.default};
`;
export const deletePostConfirmText = (theme: Theme) => css`
  color: ${theme.invertedText.colors.primary};
`;
