import "emoji-mart/css/emoji-mart.css";
import React, { useCallback, useRef, useState } from "react";

import { css, Theme } from "@emotion/react";
import { CSSInterpolation } from "@emotion/serialize";
import { BaseEmoji, Emoji, Picker } from "emoji-mart";

import { UpdateActionType } from "apollo/graphql.generated";
import { AddReaction as AddReactionIcon } from "assets/icons.generated";
import useSession from "hooks/useSession";
import { logError } from "services/logger";
import { ThemeType } from "styles/theme/theme.types";
import useOnClickOutside from "utils/hooks/useOnClickOutside";
import { Maybe } from "utils/types";

import { useUpdatePostReactionMutation } from "../query.graphql.generated";

// TODO: remove types when GraphQL defines them (import from generated)
export enum ReactionType {
  STAR = "STAR",
  FIRE = "FIRE",
  SUNGLASS = "SUNGLASS",
}

export type Reaction = {
  reaction: ReactionType;
  count: number;
  userHasReacted: boolean;
};
// end of TODO

const reactionListStyle = css`
  padding: 0;
  margin: 0.5em 0 0;
  list-style: none;
  display: flex;
  gap: 0.5em;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  li:last-child {
    position: relative;
  }
`;

const getPickerCss = (up = false, left = false) =>
  css`
    position: absolute;
    ${up ? "bottom" : "top"}: 22px;
    ${left ? "right" : "left"}: 22px;
    z-index: 1;
  `;

const reactionButton = (pageTheme: ReactionsProp["theme"]) => (theme: Theme) =>
  css`
    transition: opacity 0.6s ${theme.animations.easeOutQuart};
    color: ${pageTheme === "system"
      ? theme.text.colors.primary
      : theme.community.text.onPrimaryBackgroundPrimaryColor};

    @media (hover: hover) {
      &:hover {
        opacity: 0.6;
      }
    }
  `;

export type BasePostReaction = {
  count: number;
  reaction: string;
  userHasReacted: boolean;
};

export type ReactionsProp = {
  theme: ThemeType;
  reactions?: Maybe<BasePostReaction[]>;
  communitySlug: string;
  postId: string;
};

export const Reactions = (props: ReactionsProp) => {
  const { user } = useSession();
  type User = typeof user;
  const ref = useRef(null);
  const [pickerCss, setPickerCss] = useState<CSSInterpolation>(null);

  const closePicker = () => {
    setPickerCss(null);
  };

  useOnClickOutside(ref, closePicker);
  const [updatePostReactionMutation] = useUpdatePostReactionMutation();
  const [reactions, setReactions] = useState<BasePostReaction[]>(
    [...(props.reactions || [])].sort((a, b) =>
      a.reaction.localeCompare(b.reaction)
    )
  );

  const handleReactionAdd = useCallback(async (data: BaseEmoji, user: User) => {
    await handleReactionUpdate(data.id, UpdateActionType.ADD, user);
  }, []);

  const getUpdatedReactions = (
    prevReactions: BasePostReaction[],
    id: string,
    type: UpdateActionType,
    user: User
  ): BasePostReaction[] => {
    if (!user) return prevReactions;

    const existingReaction = prevReactions.find(
      (reaction) => reaction.reaction === id
    );

    const newReactions = prevReactions.filter(
      (reaction) => reaction !== existingReaction
    );
    if (type === UpdateActionType.ADD) {
      newReactions.push({
        reaction: id,
        count: (existingReaction?.count || 0) + 1,
        userHasReacted: true,
      });
    }

    if (type === UpdateActionType.REMOVE) {
      if (existingReaction && existingReaction.count > 1) {
        newReactions.push({
          reaction: id,
          count: existingReaction.count - 1,
          userHasReacted: false,
        });
      }
    }

    return newReactions.sort((a, b) => a.reaction.localeCompare(b.reaction));
  };

  const handleReactionClick = useCallback(
    async (reaction: BasePostReaction, user: User) => {
      if (reaction.userHasReacted) {
        await handleReactionUpdate(
          reaction.reaction,
          UpdateActionType.REMOVE,
          user
        );
      } else {
        await handleReactionUpdate(
          reaction.reaction,
          UpdateActionType.ADD,
          user
        );
      }
    },
    []
  );

  const handleReactionUpdate = async (
    id: string,
    type: UpdateActionType,
    user: User
  ) => {
    setReactions((prevState) => getUpdatedReactions(prevState, id, type, user));
    closePicker();
    try {
      await updatePostReactionMutation({
        variables: {
          data: {
            communitySlug: props.communitySlug,
            reaction: id,
            type: type,
          },
          updatePostReactionId: props.postId,
        },
      });
    } catch (e) {
      await logError({
        e,
        message: "[handleUpdatePostReaction] update reaction failed",
      });
    }
  };

  const toggleReactionPicker = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (pickerCss) {
      closePicker();
      return;
    }

    if (visualViewport) {
      const onSecondHalfY = event.clientY > visualViewport.height / 2;
      const onSecondHalfX = event.clientX > visualViewport.width / 2;

      setPickerCss(getPickerCss(onSecondHalfY, onSecondHalfX));
    }
  };

  return (
    <>
      <ul css={reactionListStyle}>
        {reactions &&
          reactions
            .filter((reaction) => reaction.count > 0)
            .map((reaction) => (
              <li key={`${props.postId}${reaction.reaction}`}>
                <SingleReaction
                  onClick={() => handleReactionClick(reaction, user)}
                  theme={props.theme ?? "system"}
                  hasUserReacted={!!user && reaction.userHasReacted}
                  reaction={reaction}
                  key={reaction.reaction}
                />
              </li>
            ))}
        <li>
          <button
            css={reactionButton(props.theme)}
            onClick={toggleReactionPicker}
          >
            <AddReactionIcon />
          </button>
          {pickerCss && (
            <div ref={ref} css={pickerCss}>
              <Picker
                showPreview={false}
                showSkinTones={false}
                title={""}
                emoji={""}
                onClick={(data: BaseEmoji) => handleReactionAdd(data, user)}
              />
            </div>
          )}
        </li>
      </ul>
    </>
  );
};

const reactionStyle =
  (selected: boolean, pageTheme: SingleReactionProps["theme"]) =>
  (theme: Theme) => {
    let backgroundColors;

    if (!selected) {
      backgroundColors =
        pageTheme === "system"
          ? css`
              background-color: ${theme.background.colors.tertiary};
              color: ${theme.text.colors.primary};
            `
          : css`
              background-color: ${theme.community.secondaryBackground
                .backgroundColor};
              color: ${theme.community.text.onPrimaryBackgroundPrimaryColor};
            `;
    } else {
      backgroundColors =
        pageTheme === "system"
          ? css`
              background-color: ${theme.buttons.primary.backgroundColor};
              color: ${theme.buttons.primary.textColor};
            `
          : css`
              background-color: ${theme.community.button
                .onPrimaryBackgroundBackgroundColor};
              color: ${theme.community.button.onPrimaryBackgroundTextColor};
            `;
    }

    return css`
      display: flex;
      align-items: center;
      padding: 4px 12px;
      min-height: 32px;
      border-radius: 10px;

      ${backgroundColors}
      span {
        margin-right: 2px;
      }
    `;
  };

type SingleReactionProps = {
  theme: ThemeType;
  reaction: BasePostReaction;
  hasUserReacted: boolean;
  onClick: () => void;
};

const SingleReaction = ({
  theme,
  reaction,
  hasUserReacted,
  onClick,
}: SingleReactionProps) => {
  return (
    <button css={reactionStyle(hasUserReacted, theme)} onClick={onClick}>
      <Emoji emoji={reaction.reaction} size={22} />
      <span>{reaction.count}</span>
    </button>
  );
};
