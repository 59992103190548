import { useEffect } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import type { LexicalCommand } from "lexical";
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";

import {
  Attachment as AttachmentBaseType,
  MediaMetadata,
} from "apollo/graphql.generated";

import { $createImageNode, ImageNode } from "../nodes/ImageNode";

type CommandPayload = {
  attachment: AttachmentBaseType;
  fileName: string;
};

export const INSERT_IMAGE_COMMAND: LexicalCommand<CommandPayload> =
  createCommand();

export default function ImageAttachmentPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagePlugin: ImageNode not registered on editor");
    }

    return editor.registerCommand<CommandPayload>(
      INSERT_IMAGE_COMMAND,
      (payload) => {
        const imageNode = $createImageNode(
          payload.attachment?.metadata as MediaMetadata,
          payload.fileName
        );

        $insertNodeToNearestRoot(imageNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
