import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type ContestsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ContestsQuery = {
  contests: Array<{
    __typename: "Contest";
    id: string;
    startAt: string;
    endAt: string | null;
    contestPage: {
      __typename: "ContestPage";
      description: string;
      title: string;
    };
  }>;
};

export type ContestQueryVariables = Types.Exact<{
  contestId: Types.Scalars["String"];
}>;

export type ContestQuery = {
  contest: {
    __typename: "Contest";
    id: string;
    communityId: string | null;
    startAt: string;
    endAt: string | null;
    active: boolean;
    hasUserApplied: boolean | null;
    showQuestion: boolean | null;
    isPrivate: boolean | null;
    autoWin: boolean | null;
    tokenIds: Array<number>;
    contestPage: {
      __typename: "ContestPage";
      title: string;
      description: string;
      formFieldLabel: string | null;
      placeholder: string | null;
    };
  };
};

export type InsertCurrentUserToContestMutationVariables = Types.Exact<{
  contestEntry: Types.ContestEntryBody;
  contestId: Types.Scalars["String"];
}>;

export type InsertCurrentUserToContestMutation = {
  insertCurrentUserToContest: {
    __typename: "ContestEntry";
    userSubmission: {
      __typename: "UserSubmission";
      formFieldAnswer: string | null;
      submittedAt: string;
    };
  };
};

export const ContestsDocument = gql`
  query Contests {
    contests {
      id
      startAt
      endAt
      contestPage {
        description
        title
      }
    }
  }
`;

/**
 * __useContestsQuery__
 *
 * To run a query within a React component, call `useContestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContestsQuery(
  baseOptions?: Apollo.QueryHookOptions<ContestsQuery, ContestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContestsQuery, ContestsQueryVariables>(
    ContestsDocument,
    options
  );
}
export function useContestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContestsQuery,
    ContestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContestsQuery, ContestsQueryVariables>(
    ContestsDocument,
    options
  );
}
export type ContestsQueryHookResult = ReturnType<typeof useContestsQuery>;
export type ContestsLazyQueryHookResult = ReturnType<
  typeof useContestsLazyQuery
>;
export type ContestsQueryResult = Apollo.QueryResult<
  ContestsQuery,
  ContestsQueryVariables
>;
export const ContestDocument = gql`
  query Contest($contestId: String!) {
    contest(id: $contestId) {
      id
      communityId
      startAt
      endAt
      active
      hasUserApplied
      showQuestion
      isPrivate
      autoWin
      tokenIds
      contestPage {
        title
        description
        formFieldLabel
        placeholder
      }
    }
  }
`;

/**
 * __useContestQuery__
 *
 * To run a query within a React component, call `useContestQuery` and pass it any options that fit your needs.
 * When your component renders, `useContestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContestQuery({
 *   variables: {
 *      contestId: // value for 'contestId'
 *   },
 * });
 */
export function useContestQuery(
  baseOptions: Apollo.QueryHookOptions<ContestQuery, ContestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContestQuery, ContestQueryVariables>(
    ContestDocument,
    options
  );
}
export function useContestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContestQuery, ContestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContestQuery, ContestQueryVariables>(
    ContestDocument,
    options
  );
}
export type ContestQueryHookResult = ReturnType<typeof useContestQuery>;
export type ContestLazyQueryHookResult = ReturnType<typeof useContestLazyQuery>;
export type ContestQueryResult = Apollo.QueryResult<
  ContestQuery,
  ContestQueryVariables
>;
export const InsertCurrentUserToContestDocument = gql`
  mutation InsertCurrentUserToContest(
    $contestEntry: ContestEntryBody!
    $contestId: String!
  ) {
    insertCurrentUserToContest(
      contestEntry: $contestEntry
      contestId: $contestId
    ) {
      userSubmission {
        formFieldAnswer
        submittedAt
      }
    }
  }
`;
export type InsertCurrentUserToContestMutationFn = Apollo.MutationFunction<
  InsertCurrentUserToContestMutation,
  InsertCurrentUserToContestMutationVariables
>;

/**
 * __useInsertCurrentUserToContestMutation__
 *
 * To run a mutation, you first call `useInsertCurrentUserToContestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCurrentUserToContestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCurrentUserToContestMutation, { data, loading, error }] = useInsertCurrentUserToContestMutation({
 *   variables: {
 *      contestEntry: // value for 'contestEntry'
 *      contestId: // value for 'contestId'
 *   },
 * });
 */
export function useInsertCurrentUserToContestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCurrentUserToContestMutation,
    InsertCurrentUserToContestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertCurrentUserToContestMutation,
    InsertCurrentUserToContestMutationVariables
  >(InsertCurrentUserToContestDocument, options);
}
export type InsertCurrentUserToContestMutationHookResult = ReturnType<
  typeof useInsertCurrentUserToContestMutation
>;
export type InsertCurrentUserToContestMutationResult =
  Apollo.MutationResult<InsertCurrentUserToContestMutation>;
export type InsertCurrentUserToContestMutationOptions =
  Apollo.BaseMutationOptions<
    InsertCurrentUserToContestMutation,
    InsertCurrentUserToContestMutationVariables
  >;
