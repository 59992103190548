import { css, Theme } from "@emotion/react";

import { textWithLink } from "styles/global/typography";
import { variables } from "styles/variables";

export const checkoutCompleteGlobalStyles = css`
  body {
    background-color: ${variables.colors.gray1};
  }
`;

export const container = (theme: Theme) => css`
  max-width: 834px;
  display: flex;

  ${theme.mediaQueries.mediumDown} {
    margin-bottom: 40px;
    max-width: 450px;
  }
`;

export const containerLoading = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const header = (theme: Theme) => css`
  margin-bottom: 64px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    margin-bottom: 18px;
    max-height: 32px;

    path {
      fill: ${theme.colorMap.accent.default};
    }
  }

  ${theme.mediaQueries.smallOnly} {
    margin-bottom: 48px;
  }
`;

export const content = (theme: Theme) => css`
  display: grid;
  grid-template: "details orderPreview";
  grid-template-columns: 1fr 396px;
  gap: 44px;

  ${theme.mediaQueries.mediumDown} {
    grid-template:
      "orderPreview"
      "details";
    grid-template-columns: 1fr;
    gap: 64px;
  }

  ${theme.mediaQueries.smallOnly} {
    gap: 48px;
  }
`;

export const orderPreview = css`
  grid-area: orderPreview;
`;

export const details = css`
  grid-area: details;

  h3 {
    margin-bottom: 16px;
  }

  div {
    ${textWithLink}
  }

  a {
    margin-top: 32px;
  }
`;
