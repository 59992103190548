import React, { memo } from "react";

import ButtonLink from "components/ButtonLink";
import Layout from "components/Layout";
import { AppSEO } from "components/Layout/SEO";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE, COMMUNITY_URL, EXTERNAL_URL } from "config";

import * as styles from "./styles";

const NotFound = () => (
  <>
    <AppSEO title={SYSTEM_PAGE_TITLE.PAGE_NOT_FOUND} />

    <Layout css={styles.container}>
      <div css={styles.content}>
        <Title size="large" extraCss={styles.title}>
          This Page Isn&rsquo;t Available
        </Title>
        <Text as="p">
          The link may be broken, or the page may have been removed. Check to
          see if the link you&rsquo;re trying to open is correct or visit the{" "}
          <a href={EXTERNAL_URL.SUPPORT}>Help Center</a>.
        </Text>
        <ButtonLink to={COMMUNITY_URL.home}>Go Back</ButtonLink>
      </div>
    </Layout>
  </>
);

export default memo(NotFound);
