import { css, Theme } from "@emotion/react";

import { communityTextColor, innerHTML } from "styles/global/typography";

import { CommunityTextProps } from "./index";

export const base =
  (
    bold: NonNullable<CommunityTextProps["bold"]>,
    uppercase: NonNullable<CommunityTextProps["uppercase"]>
  ) =>
  (theme: Theme) =>
    css`
      font-family: ${theme.community.text.fontFamily};
      text-transform: ${theme.community.text.textTransform};
      letter-spacing: ${theme.community.text.letterSpacing};
      font-weight: ${bold
        ? theme.community.text.boldFontWeight
        : theme.community.text.fontWeight};

      ${uppercase ? "text-transform: uppercase" : null}
    `;

export const bySize =
  (size: NonNullable<CommunityTextProps["size"]>) => (theme: Theme) =>
    css`
      font-size: ${theme.text.sizes[size]};
      line-height: ${theme.text.lineHeights[size]};
    `;

export const byColor = (color: NonNullable<CommunityTextProps["color"]>) =>
  communityTextColor(color);

export const byInnerHTML = innerHTML("community");
