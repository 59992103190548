import * as Types from "../graphql.generated";

import { gql } from "@apollo/client";
import { OfferDataFragmentDoc } from "./commonFragments.graphql.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type CommentDataFragment = {
  __typename: "Comment";
  id: string;
  communityId: string;
  text: string | null;
  userId: string | null;
  username: string | null;
  profileImageUrl: string | null;
  reactions: Array<{
    __typename: "BasePostReaction";
    reaction: string | null;
    count: number | null;
    userHasReacted: boolean | null;
  }> | null;
};

export type ReleasePartyQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
  communityId: Types.Scalars["String"];
}>;

export type ReleasePartyQuery = {
  releaseParty: {
    __typename: "ReleaseParty";
    id: string;
    communityId: string;
    name: string;
    description: string;
    start: string;
    end: string;
    baseImageUrl: string;
    offerIds: Array<string>;
    embeddedContent: string;
    hasSecretMessage: boolean;
    community: {
      __typename: "Community";
      id: string;
      slug: string;
      polygonContract: {
        __typename: "Contract";
        contractAddress: string | null;
      } | null;
    } | null;
    offers: Array<{
      __typename: "Offer";
      id: string;
      name: string;
      tokenId: number | null;
      price: string;
      currency: string;
      onAboutPage: boolean | null;
      crypto: {
        __typename: "CryptoCurrency";
        coin: string;
        value: string;
        inUSD: number | null;
      } | null;
      tokens: Array<{
        __typename: "Token";
        tokenId: string;
        name: string | null;
        description: string | null;
        uri: string | null;
        animationUri: string | null;
        tokenType: Types.TokenType;
        supply: number | null;
        creatorBalance: number | null;
        benefits: Array<{
          __typename: "TokenBenefit";
          type: Types.BenefitType;
          customName: string | null;
          releasePartyId: string | null;
        }> | null;
      }> | null;
    }>;
    comments: Array<{
      __typename: "Comment";
      id: string;
      communityId: string;
      text: string | null;
      userId: string | null;
      username: string | null;
      profileImageUrl: string | null;
      reactions: Array<{
        __typename: "BasePostReaction";
        reaction: string | null;
        count: number | null;
        userHasReacted: boolean | null;
      }> | null;
    }>;
  };
};

export type SecretMessageQueryVariables = Types.Exact<{
  releasePartyId: Types.Scalars["String"];
}>;

export type SecretMessageQuery = { getSecretMessage: string };

export type CommentsQueryVariables = Types.Exact<{
  releasePartyId: Types.Scalars["String"];
}>;

export type CommentsQuery = {
  getAllComments: Array<{
    __typename: "Comment";
    id: string;
    communityId: string;
    text: string | null;
    userId: string | null;
    username: string | null;
    profileImageUrl: string | null;
    reactions: Array<{
      __typename: "BasePostReaction";
      reaction: string | null;
      count: number | null;
      userHasReacted: boolean | null;
    }> | null;
  }>;
};

export type CreateNewCommentMutationVariables = Types.Exact<{
  data: Types.CreateReleasePartyCommentInput;
}>;

export type CreateNewCommentMutation = {
  createNewComment: { __typename: "Comment"; id: string };
};

export type DeleteCommentMutationVariables = Types.Exact<{
  commentId: Types.Scalars["String"];
}>;

export type DeleteCommentMutation = {
  deleteComment: { __typename: "Comment"; id: string };
};

export type UpdateCommentReactionMutationVariables = Types.Exact<{
  commentId: Types.Scalars["String"];
  data: Types.UpdatePostReactionInput;
}>;

export type UpdateCommentReactionMutation = {
  updateCommentReaction: { __typename: "Comment"; id: string };
};

export const CommentDataFragmentDoc = gql`
  fragment commentData on Comment {
    id
    communityId
    text
    userId
    username
    profileImageUrl
    reactions {
      reaction
      count
      userHasReacted
    }
  }
`;
export const ReleasePartyDocument = gql`
  query ReleaseParty($slug: String!, $communityId: String!) {
    releaseParty(slug: $slug, communityId: $communityId) {
      id
      communityId
      name
      description
      start
      end
      baseImageUrl
      offerIds
      embeddedContent
      hasSecretMessage
      community {
        id
        slug
        polygonContract {
          contractAddress
        }
      }
      offers {
        ...offerData
      }
      comments {
        ...commentData
      }
    }
  }
  ${OfferDataFragmentDoc}
  ${CommentDataFragmentDoc}
`;

/**
 * __useReleasePartyQuery__
 *
 * To run a query within a React component, call `useReleasePartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasePartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasePartyQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useReleasePartyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReleasePartyQuery,
    ReleasePartyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReleasePartyQuery, ReleasePartyQueryVariables>(
    ReleasePartyDocument,
    options
  );
}
export function useReleasePartyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleasePartyQuery,
    ReleasePartyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReleasePartyQuery, ReleasePartyQueryVariables>(
    ReleasePartyDocument,
    options
  );
}
export type ReleasePartyQueryHookResult = ReturnType<
  typeof useReleasePartyQuery
>;
export type ReleasePartyLazyQueryHookResult = ReturnType<
  typeof useReleasePartyLazyQuery
>;
export type ReleasePartyQueryResult = Apollo.QueryResult<
  ReleasePartyQuery,
  ReleasePartyQueryVariables
>;
export const SecretMessageDocument = gql`
  query SecretMessage($releasePartyId: String!) {
    getSecretMessage(releasePartyId: $releasePartyId)
  }
`;

/**
 * __useSecretMessageQuery__
 *
 * To run a query within a React component, call `useSecretMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecretMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecretMessageQuery({
 *   variables: {
 *      releasePartyId: // value for 'releasePartyId'
 *   },
 * });
 */
export function useSecretMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    SecretMessageQuery,
    SecretMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SecretMessageQuery, SecretMessageQueryVariables>(
    SecretMessageDocument,
    options
  );
}
export function useSecretMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecretMessageQuery,
    SecretMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SecretMessageQuery, SecretMessageQueryVariables>(
    SecretMessageDocument,
    options
  );
}
export type SecretMessageQueryHookResult = ReturnType<
  typeof useSecretMessageQuery
>;
export type SecretMessageLazyQueryHookResult = ReturnType<
  typeof useSecretMessageLazyQuery
>;
export type SecretMessageQueryResult = Apollo.QueryResult<
  SecretMessageQuery,
  SecretMessageQueryVariables
>;
export const CommentsDocument = gql`
  query Comments($releasePartyId: String!) {
    getAllComments(releasePartyId: $releasePartyId) {
      ...commentData
    }
  }
  ${CommentDataFragmentDoc}
`;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      releasePartyId: // value for 'releasePartyId'
 *   },
 * });
 */
export function useCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(
    CommentsDocument,
    options
  );
}
export function useCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommentsQuery,
    CommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(
    CommentsDocument,
    options
  );
}
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<
  typeof useCommentsLazyQuery
>;
export type CommentsQueryResult = Apollo.QueryResult<
  CommentsQuery,
  CommentsQueryVariables
>;
export const CreateNewCommentDocument = gql`
  mutation CreateNewComment($data: CreateReleasePartyCommentInput!) {
    createNewComment(data: $data) {
      id
    }
  }
`;
export type CreateNewCommentMutationFn = Apollo.MutationFunction<
  CreateNewCommentMutation,
  CreateNewCommentMutationVariables
>;

/**
 * __useCreateNewCommentMutation__
 *
 * To run a mutation, you first call `useCreateNewCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewCommentMutation, { data, loading, error }] = useCreateNewCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNewCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewCommentMutation,
    CreateNewCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNewCommentMutation,
    CreateNewCommentMutationVariables
  >(CreateNewCommentDocument, options);
}
export type CreateNewCommentMutationHookResult = ReturnType<
  typeof useCreateNewCommentMutation
>;
export type CreateNewCommentMutationResult =
  Apollo.MutationResult<CreateNewCommentMutation>;
export type CreateNewCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateNewCommentMutation,
  CreateNewCommentMutationVariables
>;
export const DeleteCommentDocument = gql`
  mutation DeleteComment($commentId: String!) {
    deleteComment(commentId: $commentId) {
      id
    }
  }
`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >(DeleteCommentDocument, options);
}
export type DeleteCommentMutationHookResult = ReturnType<
  typeof useDeleteCommentMutation
>;
export type DeleteCommentMutationResult =
  Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const UpdateCommentReactionDocument = gql`
  mutation UpdateCommentReaction(
    $commentId: String!
    $data: UpdatePostReactionInput!
  ) {
    updateCommentReaction(commentId: $commentId, data: $data) {
      id
    }
  }
`;
export type UpdateCommentReactionMutationFn = Apollo.MutationFunction<
  UpdateCommentReactionMutation,
  UpdateCommentReactionMutationVariables
>;

/**
 * __useUpdateCommentReactionMutation__
 *
 * To run a mutation, you first call `useUpdateCommentReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentReactionMutation, { data, loading, error }] = useUpdateCommentReactionMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommentReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommentReactionMutation,
    UpdateCommentReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCommentReactionMutation,
    UpdateCommentReactionMutationVariables
  >(UpdateCommentReactionDocument, options);
}
export type UpdateCommentReactionMutationHookResult = ReturnType<
  typeof useUpdateCommentReactionMutation
>;
export type UpdateCommentReactionMutationResult =
  Apollo.MutationResult<UpdateCommentReactionMutation>;
export type UpdateCommentReactionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentReactionMutation,
  UpdateCommentReactionMutationVariables
>;
