import React, { memo } from "react";

import { ReactComponent as ArrowRightDefault } from "assets/icons/arrow-right-default.svg";
import DefaultCommunityImage from "assets/images/default-community-avatar.png";
import { ResizedImage } from "components/Image";
import Text from "components/Typography/Text";
import useAppLocation from "hooks/useAppLocation";
import { CurrentUser } from "hooks/useSession";

import * as styles from "./styles";

type AvatarProps = {
  src: string;
  size: "regular" | "small";
};
export const AvatarImage: React.FC<AvatarProps> = ({ src, size }) => (
  <ResizedImage src={src} width={size === "regular" ? 86 : 56} />
);

export type UserCommunityCardLinkProps = {
  community: CurrentUser["tokens"][number]["community"];
  size?: "small" | "regular";
};

const UserCommunityCardLink = ({
  community,
  size = "regular",
}: UserCommunityCardLinkProps) => {
  const { getCommunityURLBySlug } = useAppLocation();

  return (
    <a
      href={getCommunityURLBySlug(community?.slug)}
      css={styles.container(size)}
    >
      <div css={styles.media(size)}>
        <AvatarImage
          src={community?.creatorAvatar?.imageUrl || DefaultCommunityImage}
          size={size}
        />
      </div>

      <div css={styles.content(size)}>
        <Text as="p" size={size === "regular" ? "medium" : "small"}>
          {community?.creatorAvatar?.name}
        </Text>
        <Text as="p" size={size === "regular" ? "large" : "medium"} bold={true}>
          {community.name}
        </Text>
        <span css={styles.icon(size)}>
          <ArrowRightDefault />
        </span>
      </div>
    </a>
  );
};

export default memo(UserCommunityCardLink);
