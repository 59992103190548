import React, { FC, memo } from "react";

import { TokenBenefit, BenefitType } from "apollo/graphql.generated";
import CardContainer from "components/CardContainer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import { Token } from "../types";

import AddedBenefit from "./AddedBenefit";
import * as styles from "./styles";

export interface Props {
  token?: Token;
}
const Benefits: FC<Props> = ({ token }) => {
  const isCollectibleToken = !token?.benefits || token.benefits.length === 0;
  const customBenefits = token?.benefits?.filter(
    (benefit) => benefit.type === BenefitType.CUSTOM
  );
  return (
    <>
      {!isCollectibleToken && (
        <div css={styles.spacedFields}>
          <Title size="xsmall">Community Benefits</Title>
          <Text>
            People who own this token can access these sections of your
            community.
          </Text>
          <CardContainer additionalCss={styles.benefitsContainer}>
            {token?.benefits
              ?.filter((benefit) => benefit.type !== BenefitType.CUSTOM)
              .map((tokenBenefit: TokenBenefit, i: number) => (
                <AddedBenefit key={i} type={tokenBenefit.type} />
              ))}
          </CardContainer>
        </div>
      )}
      {customBenefits && customBenefits.length > 0 && (
        <div>
          <Title size="xsmall">Additional Benefits</Title>
          <CardContainer additionalCss={styles.benefitsContainer}>
            {token?.benefits
              ?.filter((benefit) => benefit.type === BenefitType.CUSTOM)
              .map((tokenBenefit: TokenBenefit, i: number) => (
                <AddedBenefit
                  key={i}
                  type={tokenBenefit.type}
                  customBenefitName={tokenBenefit.customName}
                />
              ))}
          </CardContainer>
        </div>
      )}
    </>
  );
};

export default memo(Benefits);
