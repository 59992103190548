import { useState } from "react";

import { ReactComponent as InformationIcon } from "assets/icons/information-icon.svg";
import Tooltip from "components/Tooltip";
import Text from "components/Typography/Text";

type TooltipBaseProps = {
  placement?: "top" | "bottom" | "auto";
};

const TooltipBase: React.FC<React.PropsWithChildren<TooltipBaseProps>> = ({
  children,
  placement = "top",
}) => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <Tooltip
      skin="dark"
      visible={visible}
      onClickOutside={hide}
      maxWidth={308}
      interactive
      content={<Text>{children}</Text>}
      placement={placement}
    >
      <button
        onClick={visible ? hide : show}
        onMouseOver={show}
        onMouseLeave={hide}
      >
        <InformationIcon width="16px" height="16px" />
      </button>
    </Tooltip>
  );
};

export const EthEarningsTooltip = () => (
  <TooltipBase>
    This section shows your all-time total earnings from payments in ETH, broken
    down into earnings from primary sales and earnings from secondary sales
    royalties. Note that these totals include funds that have not yet been
    withdrawn to your wallet.
  </TooltipBase>
);

export const UsdEarningsTooltip = () => (
  <TooltipBase>
    This section shows your all-time total earnings from payments in USD. Note
    that these totals include funds that have not yet been paid out to you by
    Stripe.{" "}
  </TooltipBase>
);

export const OtherCyptoEarningsTooltip = () => (
  <TooltipBase>
    This section shows your all-time total earnings from secondary sales
    royalties in cryptocurrencies other than ETH. Note that these totals include
    funds that have not yet been withdrawn to your wallet.
  </TooltipBase>
);
