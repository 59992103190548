import { FC, memo, ReactNode, useEffect, useState } from "react";

import { css, Interpolation, Theme } from "@emotion/react";

type CopyToClipboardProps = {
  toCopy: string;
  baseText?: ReactNode;
  copiedText?: ReactNode;
  extraCss?: Interpolation<Theme>;
};

const copyAddressButton = (theme: Theme) => css`
  &:disabled {
    color: ${theme.text.colors.secondary};
    pointer-events: none;
  }
`;

const CopyToClipboard: FC<CopyToClipboardProps> = ({
  toCopy,
  baseText,
  copiedText,
  extraCss,
}) => {
  const [addressWasCopied, setAddressWasCopied] = useState<boolean>(false);

  const copyAddress = () => {
    navigator.clipboard.writeText(toCopy);
    setAddressWasCopied(true);
  };

  useEffect(() => {
    if (!addressWasCopied) return;

    const timer = setTimeout(() => {
      setAddressWasCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [addressWasCopied]);

  return (
    <button
      css={[copyAddressButton, extraCss]}
      onClick={copyAddress}
      disabled={addressWasCopied}
    >
      {addressWasCopied ? copiedText || "Copied!" : baseText || "Copy"}
    </button>
  );
};

export default memo(CopyToClipboard);
