import { ChangeEvent, memo } from "react";

import CardContainer from "components/CardContainer";
import Switch from "components/Switch";
import Text from "components/Typography/Text";

import * as styles from "./styles";

export type SwitchFieldText = {
  description?: string;
  value: boolean;
  setValue: (value: boolean) => void;
};

const SwitchField = ({ description, value, setValue }: SwitchFieldText) => {
  const handleSwitchFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked);
  };

  return (
    <CardContainer
      border={true}
      background={"primary"}
      extraCss={styles.fieldCard}
    >
      <Text as="p" size="medium" extraCss={styles.fieldContainerText}>
        {description}
      </Text>
      <Switch onChange={handleSwitchFieldOnChange} checked={value} />
    </CardContainer>
  );
};

export default memo(SwitchField);
