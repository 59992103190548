import * as Types from "../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type DiscordDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
}>;

export type DiscordDetailsQuery = {
  community: {
    __typename: "Community";
    id: string;
    polygonContract: {
      __typename: "Contract";
      contractAddress: string | null;
    } | null;
    discord: {
      __typename: "DiscordData";
      benefit: {
        __typename: "Benefit";
        hasBenefit: boolean;
        hasAccess: boolean;
        unlockedBy: Array<{
          __typename: "UserToken";
          tokenId: number;
          name: string;
          imageUrl: string;
          balance: number | null;
          tokenType: Types.TokenType;
        }> | null;
        unlockableBy: Array<{
          __typename: "Token";
          tokenId: string;
          name: string | null;
          uri: string | null;
          supply: number | null;
          communityAddress: string;
          tokenType: Types.TokenType;
        }> | null;
      };
    };
    discordStatistics: {
      __typename: "DiscordStatistics";
      members: number;
      activeCount: number;
    } | null;
  } | null;
};

export const DiscordDetailsDocument = gql`
  query DiscordDetails($slug: String!) {
    community(slug: $slug) {
      id
      polygonContract {
        contractAddress
      }
      discord {
        benefit {
          hasBenefit
          hasAccess
          unlockedBy {
            tokenId
            name
            imageUrl
            balance
            tokenType
          }
          unlockableBy {
            tokenId
            name
            uri(useChain: false)
            supply
            communityAddress
            tokenType
          }
        }
      }
      discordStatistics {
        members
        activeCount
      }
    }
  }
`;

/**
 * __useDiscordDetailsQuery__
 *
 * To run a query within a React component, call `useDiscordDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscordDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscordDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDiscordDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DiscordDetailsQuery,
    DiscordDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DiscordDetailsQuery, DiscordDetailsQueryVariables>(
    DiscordDetailsDocument,
    options
  );
}
export function useDiscordDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscordDetailsQuery,
    DiscordDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DiscordDetailsQuery, DiscordDetailsQueryVariables>(
    DiscordDetailsDocument,
    options
  );
}
export type DiscordDetailsQueryHookResult = ReturnType<
  typeof useDiscordDetailsQuery
>;
export type DiscordDetailsLazyQueryHookResult = ReturnType<
  typeof useDiscordDetailsLazyQuery
>;
export type DiscordDetailsQueryResult = Apollo.QueryResult<
  DiscordDetailsQuery,
  DiscordDetailsQueryVariables
>;
