import { FC, memo, PropsWithChildren, HTMLProps } from "react";

import { Interpolation, Theme } from "@emotion/react";
import { Property } from "csstype";

import {
  TextSizesKeys,
  OnBackgroundTextColorsKeys,
  TextColorKeys,
} from "styles/theme";
import { TextTag } from "styles/theme/theme.types";

import { SafeInnerHTML } from "../../SafeInnerHTML";

import * as styles from "./styles";

export type SystemTextProps = {
  size?: TextSizesKeys;
  color?: TextColorKeys;
  as?: TextTag;
  onBackgroundColor?: OnBackgroundTextColorsKeys;
  inverted?: boolean;
  bold?: boolean;
  uppercase?: boolean;
  textAlign?: Property.TextAlign;
  innerHTML?: boolean;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
  /**
   * @deprecated Use "extraCss" instead.
   */
  additionalCss?: Interpolation<Theme>;
} & Pick<HTMLProps<HTMLParagraphElement>, "htmlFor">;

const SystemText: FC<PropsWithChildren<SystemTextProps>> = ({
  additionalCss,
  extraCss,
  size = "medium",
  color = "primary",
  as = "p",
  innerHTML = false,
  onBackgroundColor,
  inverted = false,
  bold = false,
  uppercase = false,
  textAlign,
  children,
  ...rest
}) => {
  const Tag = as as keyof JSX.IntrinsicElements;

  return (
    <Tag
      css={[
        styles.bySize(size, bold, uppercase),
        styles.byColor(color, inverted),
        onBackgroundColor && styles.byBackgroundTextColor(onBackgroundColor),
        innerHTML && styles.byInnerHTML,
        textAlign && styles.textAlign(textAlign),
        additionalCss,
        extraCss,
      ]}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export const RichText: React.FC<{ text?: string | null }> = ({ text }) => {
  if (!text) {
    return null;
  }

  if (text.match(/\<\b/)) {
    return <SafeInnerHTML as="span" html={text} />;
  }

  return <div css={styles.presLineBreaks}>{text}</div>;
};

export default memo(SystemText);
