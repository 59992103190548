import { css } from "@emotion/react";

export const tooltip = css`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 16px;
`;

export const tooltipMedia = css`
  img {
    width: 80px;
    height: 80px;
  }
`;

export const tooltipContent = css`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  p {
    margin-top: 2px;
    line-height: 1.3;
  }

  small {
    margin-top: 4px;
    display: block;
  }
`;

export const tooltipContentButton = css``;

export const tooltipCTA = css`
  grid-column-start: span 2;
`;
