import { css, Theme } from "@emotion/react";

import { communityTextColor } from "styles/global/typography";

import { CommunityTitleProps } from "./index";

export const base = (theme: Theme) =>
  css`
    font-family: ${theme.community.title.fontFamily};
    font-weight: ${theme.community.title.fontWeight};
    text-transform: ${theme.community.title.textTransform};
    letter-spacing: ${theme.community.title.letterSpacing};
  `;

export const bySize =
  (size: NonNullable<CommunityTitleProps["size"]>) => (theme: Theme) =>
    css`
      font-size: ${theme.title.sizes[size]};
      line-height: ${theme.title.lineHeights[size]};

      ${theme.mediaQueries.smallOnly} {
        font-size: ${theme.title.sizes.smallScreen[size]};
        line-height: ${theme.title.lineHeights.smallScreen[size]};
      }
    `;

export const byColor = (color: NonNullable<CommunityTitleProps["color"]>) =>
  communityTextColor(color);
