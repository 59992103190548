import cloneDeepWith from "lodash/cloneDeepWith";

export const omitTypenameDeep = <T>(variables: T): T =>
  cloneDeepWith(variables, (value) => {
    if (value?.__typename) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, ...valWithoutTypename } = value;
      return omitTypenameDeep(valWithoutTypename);
    }
    return undefined;
  });
