import React, { FC, memo } from "react";

import { useReactiveVar } from "@apollo/client";
import { useMeasure } from "@react-hookz/web/esm";

import { useSecretMessageQuery } from "apollo/queries";
import { communityVar } from "apollo/reactive";
import { ReactComponent as LockedIcon } from "assets/icons/locked.svg";
import { ReactComponent as UnlockedIcon } from "assets/icons/unlocked.svg";
import DefaultCommunityImage from "assets/images/default-community-avatar.png";
import CardContainer from "components/CardContainer";
import CommunityAvatarSmall from "components/CommunityAvatarSmall";
import { RichText } from "components/Typography/Text";
import { UnSafeInnerHTML } from "components/UnSafeInnerHTML";
import CommunityText from "components/_Community/Text";
import CommunityTitle from "components/_Community/Title";
import { simpleDateTime } from "utils/formatting/time";

import CountdownTimer from "../CountdownTimer";
import { PartyState } from "../types";

import * as styles from "./styles";
import { Props as ReleasePartyHeaderProps } from "./types";

const ReleasePartyHeader: FC<ReleasePartyHeaderProps> = ({
  partySlug,
  partyName,
  partyImageUrl,
  partyId,
  startTime,
  description,
  partyState,
  hasReleaseTokenBenefit,
  embeddedContent = "",
  hasSecretMessage,
}) => {
  // dynamically set the relative size of the background image
  // with the css grid structure used by the root Layout, no easy way
  // to make a background image on a container/row stretch across the whole page
  const [measurements, ref] = useMeasure<HTMLDivElement>();
  const { creatorAvatar } = useReactiveVar(communityVar);

  const startTimeDisplay = simpleDateTime(startTime, true);
  const partyLayout =
    partyState === PartyState.PRE_PARTY
      ? styles.headerCountdownLayout
      : styles.headerReleasedLayout;
  // TODO: fetch on user click to unlock
  const { data } = useSecretMessageQuery({
    variables: { releasePartyId: partyId },
  });
  const secretMessage = data?.getSecretMessage ?? "";
  return (
    <div css={styles.header}>
      <div css={styles.bannerImage(measurements?.height ?? 0)}>
        {partyImageUrl && (
          <img src={partyImageUrl} loading="lazy" aria-hidden alt={undefined} />
        )}
      </div>
      <div css={styles.bannerImageBlur(measurements?.height ?? 0)} />
      <div css={[styles.headerLayout, partyLayout]} ref={ref}>
        {partyState === PartyState.PRE_PARTY && (
          <div css={styles.headerContainer}>
            <img
              css={styles.image}
              src={partyImageUrl || DefaultCommunityImage}
              alt={partyName || undefined}
            />
          </div>
        )}
        <div css={styles.headerContainer}>
          <CommunityAvatarSmall
            extraCss={styles.communityAvatar}
            imageUrl={creatorAvatar?.imageUrl}
            name={creatorAvatar?.name}
            bold={false}
          />
          <CommunityTitle
            size="medium"
            extraCss={styles.title}
            color="onPrimaryBackgroundSecondaryColor"
          >
            {partyName}
          </CommunityTitle>

          {partyState === PartyState.PRE_PARTY && (
            <>
              <div css={styles.timeSection}>
                <CommunityText> {startTimeDisplay}</CommunityText>
              </div>

              <div css={styles.descriptionSection}>
                <CommunityText>
                  <RichText text={description} />
                </CommunityText>
              </div>

              <CountdownTimer time={startTime} />
            </>
          )}
          {(partyState === PartyState.PARTY ||
            partyState === PartyState.AFTER_PARTY) && (
            <>
              <div css={styles.timeSection}>
                <CommunityText>
                  {"RELEASED "} {startTimeDisplay}
                </CommunityText>
              </div>

              <div css={styles.descriptionSection}>
                {/* TODO - https://highlightxyz.atlassian.net/browse/HIGHLIGHT-2492 review string sanitization for unsafe content, need to escape escaped chars, otherwise html won't work (404's) */}
                <UnSafeInnerHTML
                  html={embeddedContent.replaceAll('\\"', '"')}
                />
              </div>

              {hasReleaseTokenBenefit && hasSecretMessage && (
                <CardContainer border extraCss={styles.secretMessageSection}>
                  <div css={styles.secretMessageRow}>
                    <div css={styles.unlockedIcon}>
                      <UnlockedIcon />
                    </div>
                    <CommunityText extraCss={styles.unlockedText}>
                      {`Message unlocked by your ${partySlug} release party token`}
                    </CommunityText>
                  </div>
                  <div css={styles.secretMessageRow}>
                    <div css={styles.lockedIcon}></div>

                    <CommunityAvatarSmall
                      extraCss={styles.communityAvatar}
                      imageUrl={creatorAvatar?.imageUrl}
                    />
                    <CommunityText extraCss={styles.secretMessageText}>
                      {secretMessage}
                    </CommunityText>
                  </div>
                </CardContainer>
              )}
              {!hasReleaseTokenBenefit && (
                <CardContainer extraCss={styles.secretMessageSection}>
                  <div css={styles.secretMessageRow}>
                    <div css={styles.lockedIcon}>
                      <LockedIcon />
                    </div>
                    <CommunityText extraCss={styles.lockedText}>
                      {`Claim or buy a collectible token to unlock a message from ${creatorAvatar?.name}`}
                    </CommunityText>
                  </div>
                </CardContainer>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ReleasePartyHeader);
