import React, {
  forwardRef,
  memo,
  ComponentPropsWithRef,
  useState,
  useRef,
  useEffect,
} from "react";

import { ColorChangeHandler, ChromePicker } from "react-color";
import { UseFormSetValue } from "react-hook-form";

import { WebsiteThemeFragment } from "apollo/queries";
import Label from "components/Typography/Label";
import Text from "components/Typography/Text";
import useOnClickOutside from "utils/hooks/useOnClickOutside";
import { Maybe } from "utils/types";

import * as sharedStyles from "../sharedStyles";

import * as styles from "./styles";

type InputProps = ComponentPropsWithRef<"input">;

export type PickerProps = {
  color: string;
};

export type ColorPickerProps = InputProps & {
  value: string;
  label: string;
  description?: Maybe<string>;
  placeholder?: Maybe<string>;
  error?: string | undefined;
  setValue: UseFormSetValue<WebsiteThemeFragment>;
  formName:
    | "pageBackground.backgroundColor"
    | "primaryBackground.backgroundColor"
    | "text.onPageBackgroundPrimaryColor"
    | "text.onPrimaryBackgroundPrimaryColor"
    | "button.onPageBackgroundBorderColor"
    | "button.onPageBackgroundBackgroundColor"
    | "button.onPageBackgroundTextColor"
    | "button.onPrimaryBackgroundBackgroundColor"
    | "button.onPrimaryBackgroundTextColor"
    | "button.onPrimaryBackgroundBorderColor";
};

const ColorPicker = forwardRef<HTMLInputElement, ColorPickerProps>(
  (
    {
      value,
      label,
      description,
      placeholder = "#------",
      error,
      setValue,
      formName,
    }: ColorPickerProps,
    ref
  ) => {
    const [sliderPickerColor, setSliderPickerColor] = useState<string>(value);

    const inputWrapperRef = useRef(null);

    const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);

    const formatValue = (value: string) =>
      value.includes("#") || value === "" ? value : `#${value}`;

    const handlePickerPopupOpen = () => setIsPickerOpen(true);
    const handlePickerPopupClose = () => setIsPickerOpen(false);

    const handleSliderPickerChange: ColorChangeHandler = (color) => {
      let newValue = color.hex;
      newValue = formatValue(newValue);
      setSliderPickerColor(newValue);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = e.currentTarget.value;
      newValue = formatValue(newValue);
      setSliderPickerColor(newValue);
    };

    useOnClickOutside(inputWrapperRef, handlePickerPopupClose);

    useEffect(() => {
      const timer = setTimeout(() => {
        setValue(formName, sliderPickerColor, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }, 100);
      return () => clearTimeout(timer);
    }, [sliderPickerColor]);

    return (
      <div css={sharedStyles.baseFieldGroup}>
        {label && (
          <Label size="small" extraCss={sharedStyles.baseInputLabel}>
            {label}
          </Label>
        )}

        {description && (
          <Text size="small" extraCss={sharedStyles.baseInputDescription}>
            {description}
          </Text>
        )}

        <div
          css={[sharedStyles.baseInputContainer(error), styles.inputContainer]}
          onClick={handlePickerPopupOpen}
          ref={inputWrapperRef}
        >
          <div css={styles.picker(sliderPickerColor)}>
            {isPickerOpen && (
              <div css={styles.pickerDropDown}>
                <i />
                <ChromePicker
                  css={styles.chromePicker}
                  color={sliderPickerColor}
                  onChange={handleSliderPickerChange}
                  disableAlpha={true}
                />
              </div>
            )}
          </div>

          <input
            value={sliderPickerColor}
            ref={ref}
            type="text"
            placeholder={placeholder}
            css={[sharedStyles.baseInput, styles.input]}
            onChange={handleInputChange}
            onBlur={handleInputChange}
          />
        </div>

        {error && <p css={sharedStyles.baseInputErrorText}>{error}</p>}
      </div>
    );
  }
);

export default memo(ColorPicker);
