import React, { memo, useMemo } from "react";

import { css } from "@emotion/react";
import { useParams } from "react-router-dom";

import { TokenType } from "apollo/graphql.generated";
import TextEditor from "components/ContentFeed/TextEditor";
import { AppSEO } from "components/Layout/SEO";
import Title from "components/Typography/Title";
import { FEATURE_FLAG, SYSTEM_PAGE_TITLE } from "config";
import { ContentFeed } from "containers/CommunityV3/ContentFeed/Feed";
import {
  TokenMappedMetadata,
  TokenMapProvider,
} from "containers/CommunityV3/ContentFeed/Post/TokenMapContext";
import useFeatureFlag from "hooks/useFeatureFlag";

import { CreatePost } from "./CreatePost";
import {
  CreatedCommunityFeedBySlugQuery,
  CreatePostMutation,
  useCreatedCommunityFeedBySlugQuery,
} from "./createPosts.graphql.generated";

const containerStyle = css`
  width: 100%;
`;

const createTokenMap = (
  data: CreatedCommunityFeedBySlugQuery["createdCommunityBySlug"]
) => {
  if (!data) {
    return {};
  }
  return data.tokens.reduce<TokenMappedMetadata>((acc, token) => {
    const offer = data.offers?.find(
      (offer) =>
        (offer.tokens || []).map((t) => t.tokenId).indexOf(token.tokenId) >
          -1 && offer.active
    );
    if (!token) return acc;

    acc[token.tokenId] = {
      id: token.tokenId,
      total: token.supply ?? 0,
      available: token.creatorBalance ?? 0,
      checkoutId: offer?.id,
      price: offer?.price,
      currency: offer?.currency,
      name: token.name,
      description: token.description,
      uri: token.uri,
      type: token.tokenType,
      userHasIt: false,
      userHasPending: false,
    };

    return acc;
  }, {});
};

const EditCommunityPosts = () => {
  const { communitySlug } = useParams();
  const enableNewPostEditor = useFeatureFlag(
    FEATURE_FLAG.CONTENT_FEED_ENABLE_NEW_POST_EDITOR
  );

  const { data, loading } = useCreatedCommunityFeedBySlugQuery({
    fetchPolicy: "network-only",
    variables: {
      slug: communitySlug as string,
      useChain: false,
      type: TokenType.All,
      activeContests: true,
    },
  });

  const community = data?.createdCommunityBySlug;

  const tokenMap = useMemo(() => {
    if (community) {
      return createTokenMap(community);
    }
    return {};
  }, [community]);

  if (!community || loading) return null;

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.EDITCOMMUNITY_POSTS} />

      <div css={containerStyle}>
        <Title size="large">Posts</Title>
        {data?.createdCommunityBySlug?.id && (
          <>
            {enableNewPostEditor ? (
              <TokenMapProvider value={tokenMap}>
                <TextEditor
                  community={community}
                  tokens={community.tokens}
                  editable={true}
                  attachments={null}
                />
              </TokenMapProvider>
            ) : (
              <CreatePost
                slug={communitySlug ?? ""}
                community={data.createdCommunityBySlug}
                onAdd={(post) => {
                  document.dispatchEvent(
                    new CustomEvent<CreatePostMutation["createPost"]>(
                      "post:newPostAdded",
                      { detail: post }
                    )
                  );
                }}
              />
            )}

            <ContentFeed
              communitySlug={data.createdCommunityBySlug.slug}
              isCreator
            />
          </>
        )}
      </div>
    </>
  );
};

export default memo(EditCommunityPosts);
