import React, { forwardRef, memo } from "react";

import _uniqueId from "lodash/uniqueId";

import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

import * as styles from "./styles";
import { Props } from "./types";

const TextFieldWithSuffix = forwardRef<HTMLInputElement, Props>(
  (
    {
      error,
      id,
      label = "",
      labelType = "text",
      placeholder = "",
      suffix = "",
      ...rest
    }: Props,
    ref
  ) => {
    const htmlFor = _uniqueId("field-");

    const renderTitle = () => {
      switch (labelType) {
        case "text":
          return (
            <Text size="medium" bold extraCss={styles.label}>
              {label}
            </Text>
          );
        case "title":
          return (
            <Title size="xsmall" extraCss={styles.label}>
              {label}
            </Title>
          );
      }
    };

    return (
      <div className={rest.className} css={styles.fieldGroup}>
        <label htmlFor={id ?? htmlFor} css={styles.label}>
          {renderTitle()}
        </label>
        <div id={id ?? htmlFor} css={styles.inputContainer(error)}>
          <input
            ref={ref}
            type="text"
            placeholder={placeholder}
            css={styles.input}
            {...rest}
          />
          <div css={styles.suffix}>
            <span css={styles.suffixInner}>{suffix}</span>
          </div>
        </div>
        {error && <p css={styles.error}>{error}</p>}
      </div>
    );
  }
);

export default memo(TextFieldWithSuffix);
