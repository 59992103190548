import { ReactNode, useEffect, useState, useMemo } from "react";

import { useReactiveVar } from "@apollo/client";
import { StripeElementsOptions, Appearance } from "@stripe/stripe-js";
import { useMediaQuery } from "react-responsive";

import { CheckoutItemsInput } from "apollo/graphql.generated";
import { useCheckoutInitiatedLazyQuery } from "apollo/queries";
import { cartStore, updateCartPaymentIntentVar } from "apollo/reactive";
import { IN_DEVELOPMENT } from "config";
import useSession from "hooks/useSession";
import { logError } from "services/logger";
import { sardineSdkLoadError } from "services/sardine";
import { stripeConstructor } from "services/stripe";
import { defaultTheme } from "styles/theme";

export type Props = {
  children: ReactNode;
};

type ContainerViews =
  | "loading"
  | "ready"
  | "apiError"
  | "paymentIntentError"
  | "emptyCart"
  | "sardineLevelIssue"
  | "sardineSDKNotLoaded";

const stripeFormStyleSmallScreens: Appearance & { labels: string } = {
  theme: "flat",
  labels: "floating",
  variables: {
    borderRadius: "8px",
    spacingGridRow: "8px",
    spacingGridColumn: "8px",
    spacingUnit: "3px",
    colorIconTabSelected: defaultTheme.colorMap.accent.default,
  },

  rules: {
    ".Tab": {
      backgroundColor: "white",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      color: "black",
      fontSize: "16px",
      lineHeight: "24px",
      padding: "23px 16px 12px 16px",
      fontWeight: "400",
    },
    ".Tab--selected": {
      backgroundColor: defaultTheme.colorMap.accent[50],
      border: `2px solid ${defaultTheme.colorMap.accent.default}`,
      color: defaultTheme.colorMap.accent.default,
    },
    ".Tab--selected:hover": {
      color: defaultTheme.colorMap.accent.default,
    },
    ".Input": {
      backgroundColor: "white",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      color: "black",
    },
    ".Error": {
      color: defaultTheme.colorMap.destructive[500],
      paddingTop: "4px",
    },
  },
};

const stripeFormStyleMediumUpScreens: Appearance & { labels: string } = {
  theme: "flat",
  labels: "floating",
  variables: {
    borderRadius: "8px",
    spacingGridRow: "16px",
    spacingGridColumn: "16px",
    colorIconTabSelected: defaultTheme.colorMap.accent.default,
  },

  rules: {
    ".Tab": {
      backgroundColor: "white",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      color: "black",
      fontSize: "16px",
      lineHeight: "24px",
      padding: "23px 16px 12px 16px",
      fontWeight: "400",
    },
    ".Tab--selected": {
      backgroundColor: defaultTheme.colorMap.accent[50],
      border: `2px solid ${defaultTheme.colorMap.accent.default}`,
      color: defaultTheme.colorMap.accent.default,
    },
    ".Tab--selected:hover": {
      color: defaultTheme.colorMap.accent.default,
    },
    ".Input": {
      backgroundColor: "white",
      border: "1px solid rgba(17, 5, 5, 0.1)",
      color: "black",
    },
    ".Error": {
      color: defaultTheme.colorMap.destructive[500],
      paddingTop: "4px",
    },
  },
};

const useStripeElementsLogic = () => {
  const { user } = useSession();

  // Helps
  const isMobile = useMediaQuery({
    maxWidth: defaultTheme.breakpoints.sm,
  });

  // Configs
  const [view, setView] = useState<ContainerViews>("loading");

  // Sardine
  const sessionKey = window._Sardine?.defaultContext?.config?.sessionKey;
  const isHighlightDeveloper =
    user && user.personalInformation.email?.endsWith("@highlight.xyz");

  // Checkout
  const cart = useReactiveVar(cartStore);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [initiateCheckout] = useCheckoutInitiatedLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const paymentIntent = data?.checkoutInitiated?.stripe?.paymentIntent;
      const riskLevel = data?.checkoutInitiated?.level;

      // Sardine SDK has not started, show error message and log error for analysis.
      if (!riskLevel) {
        setView("sardineSDKNotLoaded");

        const errorMsg = `[Checkout][Sardine][API Response] Sardine level not detected.`;
        logError({
          error: new Error(errorMsg),
          message: errorMsg,
        });

        return;
      }

      // Sardine rick level validations.
      if (
        riskLevel &&
        ["very_high", "high"].includes(riskLevel) &&
        !isHighlightDeveloper
      ) {
        setView("sardineLevelIssue");

        const errorMsg = `[Checkout][Sardine][API Response][Risk Level: ${riskLevel}] A very high level of risk was detected.`;
        logError({
          error: new Error(errorMsg),
          message: errorMsg,
        });

        return;
      }

      // Stripe validations.
      if (!paymentIntent) {
        setView("paymentIntentError");

        const errorMsg = `[Checkout][Stripe][API Response] Unable to get stripe paymentIntent at checkout init page.`;
        logError({
          error: new Error(errorMsg),
          message: errorMsg,
        });

        return;
      }

      // Output.
      updateCartPaymentIntentVar(paymentIntent);
      setClientSecret(paymentIntent);

      setView("ready");

      IN_DEVELOPMENT &&
        console.log(`🟡 Checkout Initiate`, {
          riskLevel,
          paymentIntent,
          sessionKey: window._Sardine?.defaultContext?.config?.sessionKey,
        });
    },
    onError: (error) => {
      const errorMsg = `[Checkout][Stripe Elements] Mutation Error.`;
      logError({
        error: error,
        message: errorMsg,
      });
      setView("apiError");
    },
  });

  // Stripe

  const stripeOptions: StripeElementsOptions = {
    clientSecret,
    appearance: isMobile
      ? stripeFormStyleSmallScreens
      : stripeFormStyleMediumUpScreens,
  };

  // Effects

  useEffect(() => {
    if (!stripeConstructor) {
      setView("loading");
    } else if (!cart?.items) {
      setView("emptyCart");
    } else {
      let sessionKey = "";
      if (
        !sardineSdkLoadError &&
        window._Sardine &&
        window._Sardine.defaultContext?.config?.sessionKey
      ) {
        sessionKey = window._Sardine?.defaultContext?.config?.sessionKey;
      } else {
        const errorMsg = `[Checkout][Sardine] Unable to load sardine sdk at checkout init page.`;
        logError({
          error: new Error(errorMsg),
          message: errorMsg,
        });
      }

      const items = cart.items.map<CheckoutItemsInput>((item) => ({
        id: item.id,
      }));

      initiateCheckout({
        variables: {
          sessionKey,
          items: items,
        },
      });
    }
  }, [cart.items, sessionKey, stripeConstructor, sardineSdkLoadError]);

  // Logic

  const stripeElementsOptions = useMemo(() => {
    return stripeOptions;
  }, [clientSecret, isMobile]);

  const stripeInit = useMemo(() => {
    return stripeConstructor;
  }, [stripeConstructor]);

  return {
    view,
    stripeElementsOptions,
    stripeInit,
  };
};

export type UseStripeElementsLogic = ReturnType<typeof useStripeElementsLogic>;

export default useStripeElementsLogic;
