import React, { memo, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { AppSEO } from "components/Layout/SEO";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { SYSTEM_PAGE_TITLE, FEATURE_FLAG } from "config";
import useFeatureFlag from "hooks/useFeatureFlag";

const MyAccountTransactionHistory = () => {
  const navigate = useNavigate();
  const showPage = useFeatureFlag(FEATURE_FLAG.ENABLE_ACCOUNT_WALLET_PAGE);

  // Protect route if feature flag is off.
  useEffect(() => {
    if (showPage) return;
    navigate("/", { replace: true });
  }, [showPage]);

  return (
    <>
      <AppSEO title={SYSTEM_PAGE_TITLE.ACCOUNT_TRANSACTION_HISTORY} />

      <Title size="large">Transaction History</Title>
      <Text as="p">Page under development</Text>
    </>
  );
};

export default memo(MyAccountTransactionHistory);
