import React, { useState } from "react";

import { Interpolation, Theme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import { MeDocument } from "apollo/queries";
import {
  addCartItemsVar,
  ModalType,
  updateModalVar,
  updateRedirectUrlVar,
} from "apollo/reactive";
import { hideBannerMessage, showBannerMessage } from "apollo/reactive/banner";
import { setOfferVar } from "apollo/reactive/offer";
import { ANALYTICS_EVENT, COMMUNITY_URL } from "config";
import useSession from "hooks/useSession";
import { MINTING_IN_PROGRESS_BANNER_MESSAGE } from "hooks/useUserPendingTokens";
import { logError } from "services/logger";
import { trackEvent } from "services/segment";
import { useClaimFreeOfferMutation } from "utils/distribution/distribution.graphql.generated";
import {
  ContestMethod,
  DistributableToken,
  Distribution,
  DistributionMethod,
  OfferMethod,
  findMainToken,
  TimedAvailability,
} from "utils/distribution/distributionMethod";
import { formatCurrency } from "utils/formatting/currency";
import { buildOpenSeaTokenUrl, trackOpenSeaLinkOpen } from "utils/opensea";
import { Maybe } from "utils/types";

import CommunityButton from "../_Community/Button";
import CommunityButtonLink from "../_Community/ButtonLink";
import CommunityButtonExternalLink from "../_Community/ButtonLink/ButtonExternalLink";

import { CryptoPrice } from "./CryptoPrice";

const isOffer = (
  distributionMethod: DistributionMethod
): distributionMethod is OfferMethod => distributionMethod.kind === "Offer";

const isContest = (
  distributionMethod: DistributionMethod,
  hasTimingWindow: boolean
): distributionMethod is ContestMethod =>
  !isOffer(distributionMethod) && !hasTimingWindow;

const isAvailable = (tokens: DistributableToken[]) =>
  tokens.every((token) => token.available > 0);

type DistributionButtonProps = {
  distribution: Distribution;
  /** Don't use it to rewrite to component base styles. */
  extraCss?: Interpolation<Theme>;
  communityName: string;
  contractAddress?: Maybe<string>;
  timedAvailability?: TimedAvailability;
};

const DistributionButton = ({
  distribution: { distributionMethod, tokens, redirectUrl },
  extraCss,
  communityName,
  contractAddress,
  timedAvailability,
}: DistributionButtonProps) => {
  const navigate = useNavigate();
  const { user } = useSession();
  const [isLoading, setLoading] = useState(false);

  const mainToken = findMainToken(tokens);
  const hasTimingWindow = !!timedAvailability?.end;
  const timingWindowClosed = !timedAvailability
    ? false
    : timedAvailability?.end < new Date();
  //TODO: Handle bundle details
  const tokenDetailUrl = COMMUNITY_URL.tokenDetail.replace(
    ":tokenId",
    mainToken.id
  );
  const openSeaUrl = contractAddress
    ? buildOpenSeaTokenUrl(contractAddress, mainToken.id)
    : null;

  const [claimFreeOffer, { loading: claimLoading }] = useClaimFreeOfferMutation(
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
      refetchQueries: [MeDocument],
    }
  );

  const buyButtonHandler =
    (offer: OfferMethod, tokens: DistributableToken[]) => () => {
      addCartItemsVar({
        id: offer.id,
      });
      updateRedirectUrlVar(redirectUrl);

      trackEvent(ANALYTICS_EVENT.CART_ADDED_FIATOFFER, {
        community: {
          name: communityName,
        },
        offer: {
          id: offer.id,
          price: formatCurrency(offer.price, offer.currency),
        },
        tokens: tokens.map((token) => ({
          id: token.id,
          name: token.name,
          supply: token.total,
          creatorBalance: token.available,
        })),
      });

      navigate(COMMUNITY_URL.checkout);
    };

  const cryptoPaymentHandler = async () => {
    if (!user) {
      updateModalVar({
        showModal: ModalType.SIGN_IN_ACCOUNT,
        showView: "login",
      });
      return;
    }
    if (!isOffer(distributionMethod)) {
      return;
    }

    setLoading(true);
    setOfferVar({
      offer: distributionMethod,
      tokens,
    });
    updateModalVar({
      showModal: ModalType.CRYPTO_PAYMENT,
      onClose: () => {
        setLoading(false);
      },
    });
  };

  const claimOfferButtonHandler = (offer: OfferMethod) => async () => {
    if (user) {
      try {
        // Global hook useUserPendingTokens will hide the message when minting finishes.
        // Call necessary only to show banner instantly.
        showBannerMessage(MINTING_IN_PROGRESS_BANNER_MESSAGE);
        await claimFreeOffer({
          variables: { offerId: offer.id },
        });
      } catch (error) {
        hideBannerMessage(MINTING_IN_PROGRESS_BANNER_MESSAGE);
        await logError({
          error,
          message: "[claimOfferButtonHandler] failed claiming free offer",
        });
      }
    } else {
      updateModalVar({
        showModal: ModalType.CREATE_ACCOUNT,
        showView: "login",
      });
    }
  };

  if (tokens.some((token) => token.userHasPending) || claimLoading) {
    return (
      <CommunityButton
        color="onPrimaryBackgroundBackgroundColor"
        extraCss={extraCss}
        loading
        loadingType="inline"
      >
        Minting in progress{tokens.length > 1 && " for part of bundle"}
      </CommunityButton>
    );
  }

  if (tokens.some((token) => token.userHasIt)) {
    return (
      <CommunityButtonLink
        color="onPrimaryBackgroundBackgroundColor"
        extraCss={extraCss}
        header={`You Own ${tokens.length > 1 ? "Part" : "This"}`}
        to={tokenDetailUrl}
      >
        View Token
      </CommunityButtonLink>
    );
  }

  const notSoldOut = isAvailable(tokens);
  if (isContest(distributionMethod, hasTimingWindow)) {
    if (notSoldOut) {
      return (
        <CommunityButton
          color="onPrimaryBackgroundBackgroundColor"
          extraCss={extraCss}
          onClick={() => navigate(`/contest/${distributionMethod.id}`)}
        >
          Claim Token
        </CommunityButton>
      );
    } else {
      return (
        <CommunityButton
          color="onPrimaryBackgroundBackgroundColor"
          extraCss={extraCss}
          disabled
        >
          Unavailable
        </CommunityButton>
      );
    }
  }

  const isCryptoOffer = distributionMethod.currency === "WETH";

  if (!timingWindowClosed && notSoldOut) {
    if (parseFloat(distributionMethod.price) === 0) {
      return (
        <CommunityButton
          color="onPrimaryBackgroundBackgroundColor"
          extraCss={extraCss}
          onClick={claimOfferButtonHandler(distributionMethod)}
        >
          Claim Token
        </CommunityButton>
      );
    }
    if (isCryptoOffer) {
      return (
        <CommunityButton
          color="onPrimaryBackgroundBackgroundColor"
          extraCss={extraCss}
          loading={isLoading}
          onClick={() => cryptoPaymentHandler()}
        >
          <CryptoPrice {...distributionMethod} /> · Buy
        </CommunityButton>
      );
    }
    return (
      <CommunityButton
        color="onPrimaryBackgroundBackgroundColor"
        extraCss={extraCss}
        onClick={buyButtonHandler(distributionMethod, tokens)}
      >
        {formatCurrency(distributionMethod.price, distributionMethod.currency)}{" "}
        · Buy
      </CommunityButton>
    );
  }

  return openSeaUrl ? (
    <CommunityButtonExternalLink
      color="onPrimaryBackgroundBackgroundColor"
      extraCss={extraCss}
      header={hasTimingWindow && notSoldOut ? "" : "Sold Out"}
      href={openSeaUrl}
      target="_blank"
      rel="noreferrer"
      onClick={
        contractAddress
          ? () => trackOpenSeaLinkOpen(contractAddress, mainToken.id)
          : undefined
      }
    >
      View on OpenSea
    </CommunityButtonExternalLink>
  ) : (
    <CommunityButtonLink
      color="onPrimaryBackgroundBackgroundColor"
      extraCss={extraCss}
      header={hasTimingWindow && notSoldOut ? "" : "Sold Out"}
      to={tokenDetailUrl}
    >
      View Token
    </CommunityButtonLink>
  );
};

export default DistributionButton;
