import { useEffect } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import type { LexicalCommand } from "lexical";
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";

import {
  Attachment as AttachmentBaseType,
  MediaMetadata,
} from "apollo/graphql.generated";

type CommandPayload = {
  attachment: AttachmentBaseType;
  fileName: string;
};

import { $createAudioNode, AudioNode } from "../nodes/AudioNode";

export const INSERT_AUDIO_COMMAND: LexicalCommand<CommandPayload> =
  createCommand();

export default function AudioAttachmentPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([AudioNode])) {
      throw new Error("AudioPlugin: AudioNode not registered on editor");
    }

    return editor.registerCommand<CommandPayload>(
      INSERT_AUDIO_COMMAND,
      (payload) => {
        const audioNode = $createAudioNode(
          payload.attachment?.metadata as MediaMetadata,
          payload.fileName
        );

        $insertNodeToNearestRoot(audioNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
