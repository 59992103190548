import React, { memo } from "react";

import { useReactiveVar } from "@apollo/client";

import { ModalType, modalVar } from "apollo/reactive/modal";
import { ReactComponent as ErrorIcon } from "assets/icons/error.svg";
import { LOGIN_WITH_MAGICLINK_ERRORS } from "config";

import baseStyles from "../ModalBody/styles.module.scss";
import ReactModalBase from "../ReactModalBase";

import styles from "./styles.module.scss";

const GenericError = () => {
  const { showModal, data } = useReactiveVar(modalVar);

  return (
    <ReactModalBase
      id="generic-error-modal"
      className={`${baseStyles.modal} ${baseStyles.modalLarge}`}
      overlayClassName={baseStyles.modalOverlay}
      isOpen={showModal === ModalType.GENERIC_ERROR}
      closeTimeoutMS={800}
      ariaHideApp={false}
    >
      <div className={styles.modalBody}>
        <>
          <ErrorIcon className={styles.icon} />
          <p className={styles.title}>
            {LOGIN_WITH_MAGICLINK_ERRORS.GENERIC_TITLE}
          </p>
          <p>
            {data?.message || LOGIN_WITH_MAGICLINK_ERRORS.GENERIC_DESCRIPTION}
          </p>
        </>
      </div>
    </ReactModalBase>
  );
};

export default memo(GenericError);
