import React, { FC, memo, MouseEvent, useCallback } from "react";

import { QRCodeCanvas } from "qrcode.react";

import { ModalType, updateModalVar } from "apollo/reactive";
import { ReactComponent as CloseX } from "assets/icons/close-x.svg";
import Button from "components/Button";
import Text from "components/Typography/Text";

import Divider from "../../../../Shared/Divider";
import FieldProperty from "../../../../Shared/FieldProperty";
import { ClaimPage } from "../index";

import * as styles from "./styles";

export interface Props {
  claimPage: ClaimPage;
  index: number;
  linkCopied: boolean;
  onCopyLink: (index: number) => void;
}

const AddedClaimPage: FC<Props> = ({
  claimPage,
  index,
  linkCopied,
  onCopyLink,
}) => {
  const showDeleteClaimPageModal = useCallback(() => {
    const dataMap: Record<string, string> = {
      communityId: claimPage.communityId,
      contestId: claimPage.id,
      claimPageName: claimPage.name,
    };

    updateModalVar({
      showModal: ModalType.CONFIRM_DELETE_CLAIM_PAGE,
      data: dataMap,
    });
  }, [claimPage]);

  const handleDownloadQRCode =
    (claimPage: ClaimPage) => (event: MouseEvent<HTMLAnchorElement>) => {
      if (!claimPage.qrCodeRef) return;
      event.currentTarget.href = claimPage.qrCodeRef.toDataURL("image/png");
      event.currentTarget.download = "QR.png";
    };

  const handleCopyLink = useCallback(
    (claimPage: ClaimPage) => () => {
      navigator.clipboard.writeText(claimPage.link);
      onCopyLink(index);
    },
    [index]
  );

  return (
    <>
      <div key={claimPage.id} css={styles.claimPage}>
        <FieldProperty
          title={claimPage.name}
          value={`${claimPage.claimsRemaining} Claim${
            claimPage.claimsRemaining === 1 ? "" : "s"
          } Remaining`}
          boldTitle
          textColor="secondary"
          additionalCss={styles.textContent}
        />
        <div css={styles.buttons}>
          <Button extraCss={styles.button} onClick={handleCopyLink(claimPage)}>
            <Text bold size="small">
              {linkCopied ? "Copied!" : "Copy Link"}
            </Text>
          </Button>
          <div
            hidden
            ref={(el) =>
              (claimPage.qrCodeRef = el?.firstElementChild as HTMLCanvasElement)
            }
          >
            <QRCodeCanvas size={96} value={claimPage.link} />
          </div>
          <a css={styles.button} onClick={handleDownloadQRCode(claimPage)}>
            <Text bold size="small">
              QR Code
            </Text>
          </a>
          <div
            onClick={showDeleteClaimPageModal}
            css={styles.removeButtonContainer}
          >
            <CloseX css={styles.removeButton} />
          </div>
        </div>
      </div>
      <div css={styles.divider}>
        <Divider width={"100"} />
      </div>
    </>
  );
};

export default memo(AddedClaimPage);
