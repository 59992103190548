import { useCommunityDetailsV3Query } from "apollo/queries";
import useAppLocation from "hooks/useAppLocation";

const useCommunityDetailsV3 = () => {
  const { currentCommunitySlug } = useAppLocation();

  const { ...rest } = useCommunityDetailsV3Query({
    variables: {
      slug: currentCommunitySlug,
      useChain: false,
    },
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });

  return {
    ...rest,
  };
};

export default useCommunityDetailsV3;
export type UseCommunityDetailsV3 = ReturnType<typeof useCommunityDetailsV3>;
